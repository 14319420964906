import React from "react";
import { useState } from "react";

import { Check } from "@chef/icons/large";
import { ButtonCircle } from "../components";

const ListItem = ({ children, number, clickable, id, ...props }) => {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
  };

  if (!clickable) {
    return (
      <div className="flex text-left" id={id}>
        <div className="flex items-center justify-center w-10 h-10 p-4 border-2 rounded-full border-primary">
          {number}
        </div>
        <li className="ml-4" {...props}>
          {children}
        </li>
      </div>
    );
  }

  return (
    <div className="flex text-left">
      <ButtonCircle
        onClick={handleClick}
        Icon={() => (
          <span className="inline-block p-4">
            {checked && <Check className="text-white" />}
            {!checked && (
              <span className="inline-block p-4 text-black tabular-nums">
                {number}
              </span>
            )}
          </span>
        )}
        primary={checked}
        outlined={!checked}
      />
      <li className="ml-4" {...props}>
        {children}
      </li>
    </div>
  );
};

export default ListItem;
