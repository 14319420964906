import { format, formatDistanceToNowStrict, isToday } from "date-fns";

import { language, locale, TIME } from "@chef/constants";
import { capitalize, decapitalize } from "@chef/utils/string";

type DateFnsFormatOptions = Parameters<typeof format>[2];
type DateFnsFormatDistanceToNowOptions = Parameters<
  typeof formatDistanceToNowStrict
>[1];

export const formatDate = (
  date: number | Date | string,
  _format = TIME.DEFAULT_DATE_FORMAT as string,
  options?: { lowerCase?: boolean } & DateFnsFormatOptions,
) => {
  const { lowerCase, ...dateFnsOptions } = options || {};

  const result = format(new Date(date), _format, {
    locale,
    ...dateFnsOptions,
  });

  if (lowerCase) {
    return decapitalize(result);
  }

  return capitalize(result);
};

export const formatDistanceToNow = (
  date: number | Date | string,
  options?: DateFnsFormatDistanceToNowOptions,
) => {
  const result = formatDistanceToNowStrict(new Date(date), {
    locale,
    ...options,
  });

  return result;
};

export const formatRealtimeEstimateDate = (args: {
  confirmedDeliveryTime: string;
  estimatedTimeTo: string;
  estimatedTimeFrom: string;
  deliveryDate: string;
  showTimePrefix?: boolean;
}) => {
  const {
    confirmedDeliveryTime,
    estimatedTimeTo,
    estimatedTimeFrom,
    deliveryDate,
    showTimePrefix = false,
  } = args;

  const isDeliveryToday = isToday(new Date(deliveryDate));

  const formatTimeFromTo = `${formatDate(
    new Date(estimatedTimeFrom),
    "HH:mm",
  )} - ${formatDate(estimatedTimeTo, "HH:mm")}`;

  if (isDeliveryToday && !confirmedDeliveryTime) {
    return `${intl.TODAY} ${formatTimeFromTo}`;
  }

  if (confirmedDeliveryTime) {
    return `${formatDate(
      new Date(confirmedDeliveryTime),
      intl.DATE_FORMAT,
    )}, ${formatDate(confirmedDeliveryTime, "HH:mm")}`;
  }

  return `${formatDate(new Date(deliveryDate), intl.DATE_FORMAT)}, ${
    showTimePrefix ? intl.TIME_FORMAT : ""
  } ${formatTimeFromTo}`;
};

const intl = (
  {
    no: {
      TODAY: "i dag",
      DATE_FORMAT: "d. MMM",
      TIME_FORMAT: "kl.",
    },
    se: {
      TODAY: "idag",
      DATE_FORMAT: "d. MMM",
      TIME_FORMAT: "kl.",
    },
    dk: {
      TODAY: "i dag",
      DATE_FORMAT: "d MMM",
      TIME_FORMAT: "kl.",
    },
  } as const
)[language];
