import { useEffect, useState } from "react";
import { useTrack } from "@chef/feature-tracking";
import { isEmptyArray } from "@chef/utils/array";

import {
  useFavoriteRecipeMutation,
  useMeQuery,
  useUnfavoriteRecipeMutation,
} from "../features";
import { useRecipesAgreementFavoritesQuery } from "../graphql/generated";

interface UseFavorites {
  /**
   * @description If true, the favorites will not be updated in local state until false
   */
  sticky?: boolean;
}

type Favorite = {
  recipeId: number;
  mainRecipeId: number | null;
};

type RecipeLike = {
  recipeId: number;
  mainRecipeId: number | null;
  recipeName: string;
};

const _isFavorited = (f: Favorite | RecipeLike, id: number) => {
  return f.mainRecipeId === id || f.recipeId === id;
};

export const useFavorites = ({ sticky }: UseFavorites = {}) => {
  const { data: meQuery } = useMeQuery();

  const track = useTrack();

  const [handleFavorite] = useFavoriteRecipeMutation();
  const [handleUnfavorite] = useUnfavoriteRecipeMutation();

  const enableFavorites = !!meQuery;

  const { data: recipesAgreementFavoritesQuery } =
    useRecipesAgreementFavoritesQuery(
      {
        pageNumber: 1,
        resultsPerPage: 10_000,
      },
      { skip: !enableFavorites },
    );

  const _favorites =
    recipesAgreementFavoritesQuery?.recipesAgreementFavorites.recipeFavorites ||
    [];

  const [favorites, setFavorites] = useState<Favorite[]>(_favorites);

  const hasFavorites = !(isEmptyArray(_favorites) && isEmptyArray(favorites));

  useEffect(() => {
    if (hasFavorites && !sticky) {
      setFavorites(_favorites);
    }
  }, [_favorites, setFavorites]);

  useEffect(() => {
    if (!sticky) {
      setFavorites(_favorites);
    }
  }, [sticky, setFavorites]);

  const toggleFavorite = (recipe: RecipeLike) => {
    const isFavorited = _favorites.some(
      (f) =>
        f.mainRecipeId === recipe.mainRecipeId ||
        f.recipeId === recipe.recipeId,
    );

    if (!isFavorited) {
      handleFavorite({
        recipeId: recipe.recipeId,
        mainRecipeId: recipe.mainRecipeId,
        recipeFavoriteTypeId: 1,
      });

      track("recipeFavorited", {
        affiliation: "Frontend process",
        recipeId: recipe.recipeId,
        recipeName: recipe.recipeName,
        mainRecipeId: recipe.mainRecipeId,
        type: "favorite",
      });
    } else {
      handleUnfavorite({
        recipeId: recipe.recipeId,
        mainRecipeId: recipe.mainRecipeId,
        recipeFavoriteTypeId: 1,
      });

      track("recipeUnfavorited", {
        affiliation: "Frontend process",
        recipeId: recipe.recipeId,
        recipeName: recipe.recipeName,
        mainRecipeId: recipe.mainRecipeId,
        type: "favorite",
      });
    }
  };

  const isFavorited = (recipe: RecipeLike, bypass?: boolean) => {
    const id = recipe.mainRecipeId || recipe.recipeId;

    // If bypass is true, we check the server state instead of the local state
    if (bypass) {
      return _favorites.some((f) => _isFavorited(f, id));
    }

    return favorites.some((f) => _isFavorited(f, id));
  };

  return {
    favorites,
    isFavorited,
    enableFavorites,
    toggleFavorite,
  };
};
