import React from "react";
import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import clsx from "clsx";

interface Option {
  value: string | number;
  label: React.ReactNode;
  disabled?: boolean;
}

interface RadioButtonGridProps {
  options: Option[];
  value: string | number;
  onChange: (value: string | number) => void;
}

export const RadioGroup = ({
  options = [],
  value = "",
  onChange = () => null,
}: RadioButtonGridProps) => {
  return (
    <HeadlessRadioGroup value={value} onChange={onChange} className="space-y-1">
      {options.map((option, index) => {
        return (
          <HeadlessRadioGroup.Option
            key={index}
            value={option.value}
            disabled={option.disabled}
            className="w-full text-left a11y-focus:focus-ring"
          >
            {({ checked, disabled }) => (
              <HeadlessRadioGroup.Description
                as="div"
                className={clsx(
                  "flex items-center rounded-sm  border-2 px-4 cursor-pointer",
                  checked
                    ? "th-amk:bg-grey-3 th-amk:border-primary bg-grey-2 border-grey-1"
                    : "border-transparent",
                  disabled && "bg-grey-2 text-grey-1 line-through",
                )}
              >
                <div
                  className={clsx(
                    "w-3 h-3 mr-4 rounded-full ring-1 ring-grey-1 ring-offset-2 flex-shrink-0",
                    checked ? "bg-black" : "bg-white",
                  )}
                />
                <span>{option.label}</span>
              </HeadlessRadioGroup.Description>
            )}
          </HeadlessRadioGroup.Option>
        );
      })}
    </HeadlessRadioGroup>
  );
};
