import * as React from "react";
import { SVGProps } from "react";
const Repeat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2002 4C20.7004 4 25.2002 8.49976 25.2002 14C25.2002 19.5002 20.7004 24 15.2002 24C9.69995 24 5.2002 19.5002 5.2002 14V12"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 15.2L5.2 11L9.4 15.2"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Repeat;
