import { IDeviationBasket } from "@chef/state-management";
import { Summary } from "../shared/Summary";
import { useFinancesForDeviation } from "./useFinancesForDeviation";

interface DeviationSummaryProps {
  deviation: IDeviationBasket;
}

export const DeviationSummary = ({ deviation }: DeviationSummaryProps) => {
  const { finances } = useFinancesForDeviation({ deviation });

  return <Summary finalSum={finances.total} />;
};
