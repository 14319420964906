import { useState } from "react";

import { Currency, Disclosure, Hr } from "@chef/components";

import { useLoyalty } from "@chef/feature-flags";

import { Chevron } from "./Chevron";
import { intl } from "./DeliveryFee.Intl";

interface DeliveryFeeProps {
  customerFee: number;
  hasFreeShipping: boolean;
}

const FreeDeliveryFee = () => {
  const [expanded, setExpanded] = useState(false);

  const { data: loyaltyData } = useLoyalty();

  const currentLevelLabel = loyaltyData?.currentLevelLabel.toLocaleLowerCase();

  return (
    <Disclosure
      open={expanded}
      onClick={() => setExpanded(!expanded)}
      HeaderRow={() => (
        <div className="flex justify-between grow">
          <div className="flex items-center">
            <p>
              {intl.DELIVERY} ({currentLevelLabel})
            </p>

            <Chevron className={expanded ? "rotate-180" : undefined} />
          </div>

          <Currency className="text-discount">0</Currency>
        </div>
      )}
    >
      {intl.WE_OFFER_FREE_SHIPPING_TO_GOLD_MEMBERS}
    </Disclosure>
  );
};

export const DeliveryFee = ({
  customerFee,
  hasFreeShipping,
}: DeliveryFeeProps) => {
  return (
    <>
      <Hr className="my-4" />

      {hasFreeShipping ? (
        <FreeDeliveryFee />
      ) : (
        <div className="flex justify-between">
          <p>{intl.DELIVERY}</p>

          <Currency>{customerFee}</Currency>
        </div>
      )}
    </>
  );
};
