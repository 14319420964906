import * as React from "react";
import { SVGProps } from "react";
const Breakfast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9995 18L16.9993 14C18.5283 13.5941 18.5226 11.8107 17.4995 11C16.4764 10.1882 14.7656 10 13.4994 10C12.2331 10 10.5223 10.1893 9.4992 11C8.47609 11.8118 8.47038 13.5941 9.9993 14L9.9995 18H16.9995Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.49951 18V7.50001L2.99951 4.5M5.49951 18L1 18V7.50001L2.99951 4.5M5.49951 18L6.99951 18M2.99951 4.5L2.99945 2L8.49945 2V4.5M2.99951 4.5L8.49945 4.5M8.49945 4.5L10.9995 7.50001V8"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Breakfast;
