import * as React from "react";
import { SVGProps } from "react";
const GlassFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10 15"
    fill="none"
    {...props}
  >
    <path
      d="M2.33333 13.5H7.66667M5 9.5V13.5M5 9.5C7.20933 9.5 9 8.17467 9 6.16667M5 9.5C2.79067 9.5 1 8.17467 1 6.16667M9 6.16667L8.33333 1.5H1.66667L1 6.16667M9 6.16667C8.42301 6.59941 7.72123 6.83333 7 6.83333C6.27877 6.83333 5.57699 6.59941 5 6.16667C4.42301 5.73393 3.72123 5.5 3 5.5C2.27877 5.5 1.57699 5.73393 1 6.16667"
      stroke="#285C4D"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default GlassFull;
