import { IAddDiscount } from "../features/discount/types";

type GetDiscountPrice = {
  variationPrice: number;
  couponAmount?: number;
  couponDetails?: IAddDiscount;
  type?: "logged-in" | "signup";
};

export const getDiscountPrice = ({
  variationPrice,
  couponAmount = 0,
  couponDetails,
  type = "logged-in",
}: GetDiscountPrice) => {
  let discountAmount = 0;

  if (type === "logged-in") {
    discountAmount = variationPrice * (couponAmount / 100);
  } else if (couponDetails?.amount) {
    if (couponDetails.amountType === 1 || couponDetails.type === 1) {
      discountAmount = (variationPrice * couponDetails.amount) / 100;
    } else if (couponDetails.amountType === 2 || couponDetails.type === 2) {
      discountAmount = couponDetails.amount;
    }
  }

  const flexiblePriceWithDiscount = variationPrice - discountAmount;

  const isPriceDiscounted =
    type === "logged-in"
      ? discountAmount > 0
      : !!(couponDetails?.amount && discountAmount !== 0);

  return {
    flexiblePriceWithDiscount,
    isPriceDiscounted,
  };
};
