import * as React from "react";
import { SVGProps } from "react";
const EyeClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 7C1.895 8.43201 2.86883 9.65411 3.89809 10.6667M23 7C22.105 8.43192 21.1315 9.65414 20.1023 10.6667M20.1023 10.6667L23 13.8462M20.1023 10.6667C18.5232 12.2202 16.8132 13.28 15.0556 13.8462M3.89809 10.6667L1 13.8462M3.89809 10.6667C5.47721 12.2202 7.18722 13.28 8.94492 13.8462M15.0556 13.8462L16.2778 18M15.0556 13.8462C13.0396 14.4956 10.9609 14.4956 8.94492 13.8462M8.94492 13.8462L7.72222 18"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EyeClosed;
