import * as React from "react";
import { SVGProps } from "react";
const Prize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 96 120"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_32130_321526)">
      <path
        d="M48.8534 120.018C62.7446 120.018 74.0057 118.892 74.0057 117.504C74.0057 116.115 62.7446 114.989 48.8534 114.989C34.9622 114.989 23.7012 116.115 23.7012 117.504C23.7012 118.892 34.9622 120.018 48.8534 120.018Z"
        fill="#DFDBE4"
      />
      <path
        d="M72.4725 3.21218C74.0571 2.93559 75.5914 3.99165 75.8681 5.60088C76.1447 7.18497 75.0883 8.71878 73.4786 8.99536C71.894 9.27195 70.3597 8.21589 70.083 6.60666C69.8064 5.02257 70.8628 3.48877 72.4725 3.21218Z"
        fill="#614B79"
      />
      <path
        d="M66.5756 15.8652C66.4499 15.1863 66.9026 14.5325 67.6069 14.4068C68.286 14.2811 68.9399 14.7337 69.0657 15.4377C69.1915 16.1166 68.7387 16.7704 68.0345 16.8961C67.3554 17.0218 66.7014 16.5692 66.5756 15.8652Z"
        fill="#614B79"
      />
      <path
        d="M13.513 20.2731C13.5884 18.9907 14.6951 18.0101 15.9779 18.1107C17.2606 18.1861 18.2416 19.2925 18.141 20.5748C18.0655 21.8572 16.9588 22.8378 15.6761 22.7372C14.3933 22.6618 13.4124 21.5554 13.513 20.2731Z"
        fill="#614B79"
      />
      <path
        d="M56.7886 21.7112C49.3687 22.365 46.2247 39.8905 45.2186 47.4087C45.0928 48.2636 45.445 49.1185 46.0989 49.6717C46.6271 50.0991 47.2811 50.3003 47.935 50.25C48.1111 50.25 48.2872 50.1997 48.4884 50.1494C55.7071 47.8613 72.4585 41.7261 71.8045 34.2834C71.1506 26.6898 64.4098 21.0575 56.8138 21.7112H56.7886ZM55.3298 32.9759C58.172 32.7244 60.6621 34.8365 60.9387 37.4264C60.5112 38.583 56.6377 40.7706 52.0097 42.6061C53.0158 37.7281 54.4495 33.5542 55.3298 32.9759Z"
        fill="#614B79"
      />
      <path
        d="M50.1043 46.9913C47.8154 39.7749 41.6783 23.0288 34.2332 23.6825C26.6372 24.3363 21.0031 31.075 21.6571 38.6685C22.3111 46.0861 39.8422 49.2291 47.3627 50.2349C47.5388 50.2349 47.74 50.26 47.916 50.2349C48.57 50.1846 49.1988 49.8577 49.6264 49.3548C50.1797 48.7011 50.3558 47.7959 50.1043 46.9661V46.9913ZM32.9505 40.1269C32.6989 37.2856 34.8117 34.7963 37.4276 34.5197C38.5846 34.9472 40.7728 38.8194 42.6089 43.4459C37.7294 42.4402 33.5541 41.0069 32.9756 40.1269H32.9505Z"
        fill="#614B79"
      />
      <path
        d="M40.1877 48.415C39.8355 44.2662 42.9041 40.5952 47.0542 40.2431C51.2044 39.8911 54.8766 42.9587 55.2287 47.1075L40.1877 48.415Z"
        fill="#614B79"
      />
      <path
        d="M78.6934 45.0576L16.668 50.4887L25.8234 99.7715C26.0498 101.054 27.2319 101.934 28.5147 101.808L75.9518 97.6594C77.2346 97.5588 78.2406 96.4776 78.2406 95.1701L78.6934 45.0324V45.0576Z"
        fill="#F4E5CE"
      />
      <path
        d="M13.3809 53.501L15.6194 66.6766L82.5747 60.818L82.4741 47.4664C82.4741 46.0081 81.1913 44.8514 79.7325 44.9771L15.6446 50.5843C14.1857 50.71 13.1294 52.0678 13.3809 53.501Z"
        fill="#F4E5CE"
      />
      <path
        d="M55.2253 47.0797L40.1914 48.3945L44.7401 100.37L59.7741 99.0555L55.2253 47.0797Z"
        fill="#AE172D"
      />
      <path
        d="M78.5181 69.1778L78.5936 61.1316L19.6367 66.2862L21.1207 74.2066L78.5181 69.1778Z"
        fill="#614B79"
      />
      <path
        d="M55.2253 47.112L40.1914 48.4269L44.7401 100.403L59.7741 99.0878L55.2253 47.112Z"
        fill="#614B79"
      />
    </g>
    <defs>
      <clipPath id="clip0_32130_321526">
        <rect width={95} height={120} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default Prize;
