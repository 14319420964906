import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { authApi, showNotification } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      PASSWORD_UPDATED: "Ditt passord er lagret!",
    },

    se: {
      PASSWORD_UPDATED: "Ditt lösenord är ändrat!",
    },

    dk: {
      PASSWORD_UPDATED: "Dit kodeord er ændret!",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      authApi.endpoints.updatePassword.matchFulfilled,
      authApi.endpoints.updatePassword.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.PASSWORD_UPDATED,
        });
      }
    },
  });
};
