import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { ADDON_SUBSCRIPTIONS, language } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

import {
  billingApi,
  showNotification,
  UpdateBillingAgreementAddonsArgs,
} from "../features";
import { BillingAgreement, RootState } from "..";

const intl = (
  {
    no: {
      ACTIVATED: "Innmeldt til Råtass",
      DEACTIVATED: "Utmeldt fra Råtass",
      PHYSICAL_RECIPES_TURNED_ON: "Oppskriftsark sendes med leveringen din",
      PHYSICAL_RECIPES_TURNED_OFF: "Oppskriftsark sendes ikke med din levering",
    },

    se: {
      ACTIVATED: "NOT_ELIGIBLE",
      DEACTIVATED: "NOT_ELIGIBLE",
      PHYSICAL_RECIPES_TURNED_ON: "Receptblad skickas med din leverans",
      PHYSICAL_RECIPES_TURNED_OFF: "Receptblad skickas inte med din leverans",
    },

    dk: {
      ACTIVATED: "NOT_ELIGIBLE",
      DEACTIVATED: "NOT_ELIGIBLE",
      PHYSICAL_RECIPES_TURNED_ON: "Opskrifter sendes med i din måltidskasse",
      PHYSICAL_RECIPES_TURNED_OFF:
        "Der bliver ikke sendt opskrifter med i din måltidskasse",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.updateBillingAgreementAddons.matchFulfilled,
      billingApi.endpoints.updateBillingAgreementAddons.matchRejected,
    ),
    effect: async (action, api) => {
      try {
        const payload = action["meta"].arg
          .originalArgs as UpdateBillingAgreementAddonsArgs[];

        const originalState = api.getOriginalState() as RootState;

        // Find diff in billing agreement addons
        const originalBilling = originalState.api.queries["billing(undefined)"]
          ?.data as { billing: BillingAgreement } | undefined;

        if (!originalBilling || !payload) {
          return;
        }

        for (const newAddon of payload) {
          const existingAddon = originalBilling.billing.addonSubscriptions.find(
            (p) =>
              isEqualStrings(p.subscriptionId, newAddon.addonSubscriptionId),
          );

          if (existingAddon) {
            const isDiff = newAddon.active !== existingAddon.active;

            const isRaatass = isEqualStrings(
              newAddon.addonSubscriptionId,
              ADDON_SUBSCRIPTIONS.RAATASS,
            );

            const isDigitalRecipes = isEqualStrings(
              newAddon.addonSubscriptionId,
              ADDON_SUBSCRIPTIONS.DIGITAL_RECIPES,
            );

            if (isDiff && isRaatass) {
              showNotification({
                type: "success",
                message: newAddon.active ? intl.ACTIVATED : intl.DEACTIVATED,
              });
            }

            if (isDiff && isDigitalRecipes) {
              showNotification({
                type: "success",
                message: newAddon.active
                  ? intl.PHYSICAL_RECIPES_TURNED_OFF
                  : intl.PHYSICAL_RECIPES_TURNED_ON,
              });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
};
