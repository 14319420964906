import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import { companyId } from "@chef/constants";

import { client, query } from "../../graphql/baseApi";
import { getLoginCookieData } from "../../util";
import {
  IAppleLoginArgs,
  ICreateTelephoneTokenArgs,
  ICreateTelephoneTokenResponse,
  IFacebookLoginArgs,
  ILoginArgs,
  ILoginResponse,
  IMeResponse,
  IRecoverPasswordArgs,
  IRecoverPasswordCallbackArgs,
  IRegistrerUserArgs,
  IUpdatePasswordArgs,
  IValidateTelephoneTokenArgs,
  ISignicatUserDataResponse,
  IIntercomUserHashResponse,
} from "./types";
import { featureFlagsApi } from "../featureFlags";

const endpointsToCallOnLogin: ActionCreatorWithoutPayload<string>[] = [];
export const addResetEndpointOnLogin = (
  endpoint: ActionCreatorWithoutPayload<string>,
) => {
  endpointsToCallOnLogin.push(endpoint);
};

const onAuthQueryStarted = async (
  _: unknown,
  { queryFulfilled, dispatch }: { queryFulfilled: any; dispatch: any },
) => {
  try {
    const { success, message } = (await queryFulfilled).data as ILoginResponse;
    if (!success) {
      return;
    }
    if (!message) {
      return;
    }
  } catch (err) {
    return;
  }

  const data = getLoginCookieData();
  if (!data) {
    return;
  }

  for (const endpoint of endpointsToCallOnLogin) {
    dispatch(endpoint());
  }

  dispatch(featureFlagsApi.util.invalidateTags(["featureFlags"]));

  client.setHeader("Authorization", `Bearer ${data.token}`);
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: query,
  tagTypes: ["auth"],
  endpoints: (builder) => ({
    me: builder.query<IMeResponse | null, void>({
      providesTags: ["auth"],
      queryFn: () => {
        const data = getLoginCookieData();

        if (!data) {
          return { data: null };
        }

        return {
          data: data,
        };
      },
    }),

    logout: builder.mutation<null, void>({
      queryFn: async (arg, { dispatch }) => {
        for (const endpoint of endpointsToCallOnLogin) {
          dispatch(endpoint());
        }

        // TODO: remove after we go live with new app
        if (typeof window !== "undefined" && window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('{"type":"Logout"}');
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = "/api/auth/logout";

        // fix TS error
        return { data: null };
      },
    }),

    login: builder.mutation<ILoginResponse, ILoginArgs>({
      invalidatesTags: ["auth"],
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...body, companyId },
      }),
      onQueryStarted: onAuthQueryStarted,
    }),

    createTelephoneToken: builder.mutation<
      ICreateTelephoneTokenResponse,
      ICreateTelephoneTokenArgs
    >({
      query: (args) => ({
        url: "/auth/createTelephoneToken",
        method: "POST",
        body: { ...args, companyId },
      }),
    }),

    validateTelephoneToken: builder.mutation<
      ILoginResponse,
      IValidateTelephoneTokenArgs
    >({
      invalidatesTags: ["auth"],
      query: (body) => ({
        url: "/auth/validateTelephoneToken",
        method: "POST",
        body: { ...body, companyId },
      }),
      onQueryStarted: onAuthQueryStarted,
    }),

    appleLogin: builder.mutation<ILoginResponse, IAppleLoginArgs>({
      invalidatesTags: ["auth"],
      query: (body) => ({
        url: "/auth/appleLogin",
        method: "POST",
        body: { ...body, companyId },
      }),
      onQueryStarted: onAuthQueryStarted,
    }),

    facebookLogin: builder.mutation<ILoginResponse, IFacebookLoginArgs>({
      invalidatesTags: ["auth"],
      query: (body) => ({
        url: "/auth/facebookLogin",
        method: "POST",
        body: { ...body, companyId },
      }),
      onQueryStarted: onAuthQueryStarted,
    }),

    updatePassword: builder.mutation<null, IUpdatePasswordArgs>({
      query: (body) => ({
        url: "/auth/updatePassword",
        method: "POST",
        // oldPassword is just for testing
        body: { ...body, oldPassword: "asdfsa" },
      }),
    }),

    registrerUser: builder.mutation<ILoginResponse, IRegistrerUserArgs>({
      invalidatesTags: ["auth"],
      query: (args) => ({
        url: "/user/registrer",
        method: "POST",
        body: { ...args, company: companyId },
      }),
      onQueryStarted: onAuthQueryStarted,
    }),

    recoverPassword: builder.mutation<void, IRecoverPasswordArgs>({
      query: (args) => ({
        url: "/auth/recoverPassword",
        method: "POST",
        body: { ...args, CompanyId: companyId },
      }),
    }),

    // https://gg-developer.azure-api.net/cms-frontend-int-pub/api/PasswordRecovery/RecoverPasswordCallback
    recoverPasswordCallback: builder.mutation<
      void,
      IRecoverPasswordCallbackArgs
    >({
      query: ({ token, password }) => ({
        url: "/auth/recoverPasswordCallback",
        method: "POST",
        body: { Token: token, Password: password },
      }),
    }),

    getSignicatUserData: builder.query<ISignicatUserDataResponse, string>({
      query: (id) => ({
        url: `/auth/signicat/sessions/${id}`,
        method: "GET",
      }),
    }),

    getIntercomUserHash: builder.query<IIntercomUserHashResponse, void>({
      query: () => ({
        url: "/auth/intercom/hash",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useLogoutMutation,
  useLoginMutation,
  useAppleLoginMutation,
  useFacebookLoginMutation,
  useCreateTelephoneTokenMutation,
  useValidateTelephoneTokenMutation,
  useUpdatePasswordMutation,
  useRegistrerUserMutation,
  useRecoverPasswordMutation,
  useRecoverPasswordCallbackMutation,
  useGetSignicatUserDataQuery,
  useLazyGetSignicatUserDataQuery,
  useGetIntercomUserHashQuery,
  useLazyGetIntercomUserHashQuery,
} = authApi;
