import { useEffect, useMemo } from "react";
import { datadogRum } from "@datadog/browser-rum";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { isBrowser } from "@chef/utils/runtime";
import { getLoginCookieData, selectIsRecording } from "@chef/state-management";

import { initDatadogRum } from "./init";

type Context = {
  startRecording: () => void;
  stopRecording: () => void;
};

export const Datadog = () => {
  useEffect(initDatadogRum, []);

  const record = useSelector(selectIsRecording);

  const userId = isBrowser() && getUserId();

  const state: Context = useMemo(
    () => ({
      startRecording: () => {
        datadogRum.startSessionReplayRecording();
      },

      stopRecording: () => {
        datadogRum.stopSessionReplayRecording();
      },
    }),
    [],
  );

  // start/stop recording based on user
  useEffect(() => {
    if (userId) {
      state.startRecording();
    } else if (record) {
      state.startRecording();
    } else {
      state.stopRecording();
    }
  }, [userId, state, record]);

  // set identifying information when user logs in
  useEffect(() => {
    if (userId) {
      datadogRum.setUser({ id: userId, agreementId: userId });
    }
  }, [userId]);

  return null;
};

const getUserId = () => {
  const data = getLoginCookieData();
  if (data) {
    return data.agreementId.toString();
  }

  const token = typeof localStorage !== "undefined" && localStorage["token"];
  if (!token) {
    return;
  }

  let jwt: { AgreementId?: string } = {};

  try {
    jwt = jwtDecode(token);
  } catch {
    return null;
  }

  return jwt.AgreementId;
};
