import { useEffect, useRef, useState } from "react";

interface UseScrollDirection {
  direction: "up" | "down" | "none";
  isAtTop: boolean;
  isAtBottom: boolean;
}

export const useScrollDirection = (threshold = 0): UseScrollDirection => {
  const [scrollDirection, setScrollDirection] =
    useState<UseScrollDirection["direction"]>("none");
  const [isAtTop, setIsAtTop] = useState<boolean>(true);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const atTop = scrollY <= threshold;
      const atBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - threshold;

      setIsAtTop(atTop);
      setIsAtBottom(atBottom);

      if (Math.abs(scrollY - prevScrollY.current) >= threshold) {
        const newScrollDirection =
          scrollY > prevScrollY.current ? "down" : "up";
        setScrollDirection(newScrollDirection);
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDirection, threshold]);

  return { direction: scrollDirection, isAtTop, isAtBottom };
};
