import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      YOU_HAVE_COMPLETED_WITH_ALLERGEN:
        "Din meny kan inneholde oppskrifter som ikke er tilpasset dine preferanser og allergener.",
    },
    se: {
      YOU_HAVE_COMPLETED_WITH_ALLERGEN:
        "Din meny kan innehålla recept som inte matchar dina preferenser och allergener.",
    },
    dk: {
      YOU_HAVE_COMPLETED_WITH_ALLERGEN:
        "Din menu kan indeholde opskrifter, der ikke matcher dine valgte præferencer og allergener.",
    },
  } as const
)[language];
