import * as React from "react";
import { SVGProps } from "react";
const User = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 21C8.58537 19.1875 11.1219 18 14 18C16.8537 18 19.4146 19.1875 21 21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 12C17.25 13.7949 15.7949 15.25 14 15.25C12.2051 15.25 10.75 13.7949 10.75 12C10.75 10.2051 12.2051 8.75 14 8.75C15.7949 8.75 17.25 10.2051 17.25 12Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);
export default User;
