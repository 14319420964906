import * as React from "react";
import { SVGProps } from "react";
const More = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={2.22222} cy={10.0001} r={2.22222} fill="currentColor" />
    <circle cx={9.99957} cy={10.0001} r={2.22222} fill="currentColor" />
    <circle cx={17.7779} cy={10.0001} r={2.22222} fill="currentColor" />
  </svg>
);
export default More;
