import * as React from "react";
import { SVGProps } from "react";
const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.2932 21.7072C20.6838 22.0977 21.3169 22.0977 21.7075 21.7072C22.098 21.3167 22.098 20.6835 21.7075 20.293L20.2932 21.7072ZM21.7075 20.293L15.3438 13.9294L13.9296 15.3436L20.2932 21.7072L21.7075 20.293Z"
      fill="currentColor"
    />
    <path
      d="M9.18182 17.3636C13.7005 17.3636 17.3636 13.7005 17.3636 9.18182C17.3636 4.66312 13.7005 1 9.18182 1C4.66312 1 1 4.66312 1 9.18182C1 13.7005 4.66312 17.3636 9.18182 17.3636Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Search;
