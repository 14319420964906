import React from "react";
import { Button, Card, Hr } from "../components";

const intl = {
  no: {
    SHOW_ORDER: "Vis ordre",
    EXPECTED_DELIVERY_DATE: "Forventet levering",
    DELIVERY_INFO: "Leveringsopplysninger",
    ADDRESS: "Adresse",
    DOOR_CODE: "Inngangskode",
    INSTRUCTIONS_TO_DRIVER: "Beskjed til sjåfør",
  },
  se: {
    SHOW_ORDER: "Visa order",
    EXPECTED_DELIVERY_DATE: "Förväntad leverans",
    DELIVERY_INFO: "Leveransinformation",
    ADDRESS: "Adress",
    DOOR_CODE: "Portkod",
    INSTRUCTIONS_TO_DRIVER: "Instruktioner till chauffören",
  },
  dk: {
    SHOW_ORDER: "Vis ordre",
    EXPECTED_DELIVERY_DATE: "Forventet leveringstidspunkt",
    DELIVERY_INFO: "Leveringsoplysninger",
    ADDRESS: "Adresse",
    DOOR_CODE: "Dørkode",
    INSTRUCTIONS_TO_DRIVER: "Besked til chauffør",
  },
};
const GeneratedDeliveryCard = ({
  language = "no",
  expectedDeliveryDate = "",
  address = "",
  doorCode = "",
  instructionsToDriver = "",
  className = "",
  LinkComponent = ({ children }) => <>{children}</>,
  ...props
}) => {
  const {
    EXPECTED_DELIVERY_DATE,
    DELIVERY_INFO,
    ADDRESS,
    DOOR_CODE,
    INSTRUCTIONS_TO_DRIVER,
    SHOW_ORDER,
  } = intl[language];

  return (
    <Card className={className} {...props}>
      <strong className="text-sm">{EXPECTED_DELIVERY_DATE}</strong>
      <div className="p-4 mt-2 bg-grey-3">
        <strong className="text-lg">{expectedDeliveryDate}</strong>
      </div>

      <LinkComponent>
        <Button primary className="mt-6 ">
          {SHOW_ORDER}
        </Button>
      </LinkComponent>

      <div className="mt-8 text-sm">{DELIVERY_INFO}</div>
      <Hr light className="my-3" />
      <div className="text-xs">{ADDRESS}</div>
      <div className="mt-0.5">{address}</div>
      <Hr light className="my-3" />
      <div className="text-xs">{DOOR_CODE}</div>
      <div className="mt-0.5">{doorCode}</div>
      <Hr light className="my-3" />
      <div className="text-xs">{INSTRUCTIONS_TO_DRIVER}</div>
      <div className="mt-0.5">{instructionsToDriver}</div>
    </Card>
  );
};

export default GeneratedDeliveryCard;
