import { ChangeEvent, forwardRef, HTMLAttributes } from "react";
import clsx from "clsx";

import SubText from "../../internals/SubText";

export interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  maxLength?: number;
  label?: string;
  error?: string;
  warning?: string;
  helperText?: string;
  className?: string;
  resizeable?: boolean;
  rows?: number;
  valid?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      placeholder = "",
      maxLength = 0,
      disabled = false,
      resizeable = false,
      error = "",
      warning = "",
      helperText = "",
      name = "",
      label = "",
      value = "",
      rows = 2,
      onChange = () => null,
      className = "",
      valid: _,
      ...props
    },
    ref,
  ) => {
    return (
      <div>
        <label htmlFor={name} className="sr-only">
          {label}
        </label>

        <textarea
          rows={rows}
          ref={ref}
          placeholder={placeholder || label}
          disabled={disabled}
          id={name}
          name={name}
          wrap="hard"
          onChange={onChange}
          maxLength={maxLength > 0 ? maxLength : undefined}
          className={clsx(
            "bg-white rounded a11y-focus-within:focus-ring w-full p-2 focus:outline-none align-bottom",
            !resizeable && "resize-none",
            disabled && "cursor-not-allowed border-grey-2",
            error ? "border-error border-2" : "border-1.5",
            className,
          )}
          value={value}
          {...props}
        />
        <SubText
          htmlFor={name}
          error={error}
          warning={warning}
          helperText={helperText}
          maxLength={maxLength}
          value={value}
        />
      </div>
    );
  },
);
