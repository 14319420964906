import * as Cookies from "js-cookie";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { config } from "@chef/constants";
import { parseFlagsCookie } from "@chef/helpers/edge/flags";

import { query } from "../graphql/baseApi";

export const loadFeatureFlagsFromCookies = () => {
  const cookieValue = Cookies.get(config.featureFlags.cookieName);
  if (!cookieValue) {
    return null;
  }

  const featureFlags = parseFlagsCookie(cookieValue);

  return featureFlags.reduce(
    (acc: Record<string, (typeof featureFlags)[number]>, featureFlag) => {
      if (!featureFlag.name) {
        return acc;
      }

      acc[featureFlag.name] = featureFlag;

      return acc;
    },
    {},
  );
};

export const featureFlagsApi = createApi({
  reducerPath: "featureFlagsApi",
  tagTypes: ["featureFlags"],
  baseQuery: query,
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<
      ReturnType<typeof loadFeatureFlagsFromCookies>,
      void
    >({
      providesTags: ["featureFlags"],
      queryFn: () => {
        const featureFlags = loadFeatureFlagsFromCookies();

        if (!featureFlags) {
          return { data: {} };
        }

        return {
          data: featureFlags,
        };
      },
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagsApi;
