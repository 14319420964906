import { useMeQuery } from "@chef/state-management";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";

const LOGO_HREF_LOGGED_OUT = "/";
const LOGO_HREF_LOGGED_IN = "/app/start";

const LogoImage = ({ className }) => (
  <>
    <div className={clsx("hidden md:block", className || "w-36")}>
      <Image
        src="https://ggfrontendassets.azureedge.net/publicassets/retnemt/retnemt-logo.svg"
        alt="RetNemt logo"
        width="148"
        height="32"
        layout="responsive"
      />
    </div>
    <div className={clsx("md:hidden", className || "w-10")}>
      <Image
        src="https://ggfrontendassets.azureedge.net/publicassets/retnemt/retnemt-icon.svg"
        alt="RetNemt logo"
        width="32"
        height="32"
        layout="responsive"
      />
    </div>
  </>
);

const Logo = ({ clickable = true, className = "" }) => {
  const { data: user } = useMeQuery();
  const isLoggedIn = Boolean(user);

  if (!clickable) {
    return <LogoImage className={className} />;
  }

  return (
    <Link href={isLoggedIn ? LOGO_HREF_LOGGED_IN : LOGO_HREF_LOGGED_OUT}>
      <LogoImage className={className} />
    </Link>
  );
};

export default Logo;
