import clsx from "clsx";

import { Saw } from "@chef/icons/small";

import { BrandSpecificCoin } from "./BrandSpecificCoin";

interface PointsNoteProps {
  className?: string;
  children: React.ReactNode;
  hasSaw?: boolean;
  style?: React.CSSProperties;
}

export const PointsNote = ({
  className,
  children,
  hasSaw,
  style,
}: PointsNoteProps) => {
  return (
    <div className={clsx(className, "flex items-center")} style={style}>
      <div className="relative flex items-center justify-center">
        {hasSaw && <Saw className="absolute w-10 h-10 text-white" />}
        <BrandSpecificCoin />
      </div>
      {children}
    </div>
  );
};
