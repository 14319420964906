import { language } from "@chef/constants";

export const applyGenitiveCase = (name: string) => {
  const isException =
    name.endsWith("s") || name.endsWith("x") || name.endsWith("z");

  if (isException) {
    if (language !== "se") {
      return `${name}'`;
    } else {
      return name;
    }
  } else {
    return `${name}s`;
  }
};
