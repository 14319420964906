import * as React from "react";
import { SVGProps } from "react";
const Dinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.86634 5.3628C9.73814 5.16951 9.66719 4.53009 9.66099 4.30161C9.65479 4.07312 9.71356 3.84728 9.8311 3.64795C9.94864 3.44862 10.1206 3.28321 10.3288 3.1692C10.5369 3.05518 10.7736 2.99679 11.0138 3.0002C11.2539 3.00361 11.4887 3.0687 11.6932 3.18858C11.8977 3.30845 12.0644 3.47867 12.1756 3.68125C12.2868 3.88383 12.3385 4.11123 12.3251 4.33944C12.3118 4.56766 12.2339 5.20496 12.0997 5.39452M2.33301 14.2125V13.5781C2.33301 11.3909 3.2461 9.29321 4.87142 7.74659C6.49673 6.19996 8.70113 5.33108 10.9997 5.33108C13.2982 5.33108 15.5026 6.19996 17.1279 7.74659C18.7532 9.29321 19.6663 11.3909 19.6663 13.5781V14.2125H2.33301Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3333 14.2126L19.6667 15.7986C19.5809 16.0723 19.4051 16.3125 19.165 16.4838C18.925 16.6551 18.6334 16.7485 18.3333 16.7502H3.66667C3.1 16.7502 2.6 16.3379 2.33333 15.7986L1.66667 14.2126M1 14.2126H21H1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Dinner;
