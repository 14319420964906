import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CONSENT_OPTIONS: "Mine samtykker",
      CONSENTS_DESCRIPTION:
        "For å gi deg en tilpasset opplevelse, anvender vi følgende:",
      READ_MORE: "Les mer",
      DATA_PROCESSING_LABEL: "Databehandling",
      DATA_PROCESSING_DESCRIPTION:
        "Tillat oss å behandle dine data for å motta skreddersydde menyforslag.",
      ALLERGY_LABEL: "Lagre allergener",
      ALLERGY_DESCRIPTION: "Tillat oss å lagre dine allergener i vår database.",
      NOTE_THAT_YOUR_CHANGE_IMPLY: "Merk at dine endringer vil medfølge:",
      WE_CANNNOT_SAVE_YOUR_ALLERGEN:
        "Vi lagrer ikke opplysninger om dine allergener",
      YOU_CAN_BE_RECOMMENDED_DINNERS_WITH_ALLERGENS:
        "Du kan motta menyforslag som inneholder dine allergener",
      WE_CANNOT_BASE_RECOMMENDATIONS_ON_HISTORY:
        "Vi kan ikke basere menyforslag på dine smakspreferanser eller tidligere valg",
      YOU_WILL_RECIEVE_CHEFS_CHOICE: "Du får kokkenes favoritter",
      GENERIC_ERROR_MESSAGE: "Noe gikk galt, kontakt support",
      SAVE: "Lagre endring",
      CANCEL: "Avbryt",
    },
    se: {
      CONSENT_OPTIONS: "Mina medgivanden",
      CONSENTS_DESCRIPTION: "För att göra tjänsten mer personlig för dig:",
      READ_MORE: "Läs mer",
      DATA_PROCESSING_LABEL: "Databehandling",
      DATA_PROCESSING_DESCRIPTION:
        "Tillåt oss att behandla dina data för att ge mer exakta middagsrekommendationer.",
      ALLERGY_LABEL: "Spara allergener",
      ALLERGY_DESCRIPTION:
        "Tillåt oss att lagra dina allergener i vår databas.",
      NOTE_THAT_YOUR_CHANGE_IMPLY: "Observera att dina ändringar innebär:",
      WE_CANNNOT_SAVE_YOUR_ALLERGEN:
        "Vi kommer inte kunna lagra dina allergener",
      YOU_CAN_BE_RECOMMENDED_DINNERS_WITH_ALLERGENS:
        "Du kan bli rekommenderad middagar som innehåller dina allergener",
      WE_CANNOT_BASE_RECOMMENDATIONS_ON_HISTORY:
        "Vi kan inte basera middagsrekommendationer på vad du gillar och tidigare har valt",
      YOU_WILL_RECIEVE_CHEFS_CHOICE: "Du får kockens favoriter",
      GENERIC_ERROR_MESSAGE: "Något gick fel, försök igen senare",
      SAVE: "Spara ändring",
      CANCEL: "Avbryt",
    },
    dk: {
      CONSENT_OPTIONS: "Mine tilladelser",
      CONSENTS_DESCRIPTION:
        "Angiv, hvordan vi må bruge dine data til at forbedre din madoplevelse.",
      READ_MORE: "Læs mere",
      DATA_PROCESSING_LABEL: "Databehandling",
      DATA_PROCESSING_DESCRIPTION:
        "Tillad os at behandle dine data for at modtage skræddersyede menuforslag.",
      ALLERGY_LABEL: "Gem allergener",
      ALLERGY_DESCRIPTION:
        "Tillad os at gemme dine oplysninger om allergener i vores database.",
      NOTE_THAT_YOUR_CHANGE_IMPLY:
        "Bemærk, at dine ændringer vil medføre følgende:",
      WE_CANNNOT_SAVE_YOUR_ALLERGEN:
        "Vi gemmer ikke dine oplysninger om allergener.",
      YOU_CAN_BE_RECOMMENDED_DINNERS_WITH_ALLERGENS:
        "Du kan modtage menuforslag, der indeholder allergener.",
      WE_CANNOT_BASE_RECOMMENDATIONS_ON_HISTORY:
        "Vi kan ikke sammensætte menuforslag baseret på dine præferencer og tidligere valg.",
      YOU_WILL_RECIEVE_CHEFS_CHOICE:
        "Du modtager menuforslag fra kategorien 'Favoritter' (Vores mest populære og bedst bedømte retter).",
      GENERIC_ERROR_MESSAGE: "Noget gik galt, kontakt support",
      SAVE: "Gem ændring",
      CANCEL: "Annuller",
    },
  } as const
)[language];
