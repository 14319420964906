const isNode = () =>
  typeof process !== "undefined" &&
  process.versions != null &&
  process.versions.node != null;

const isBrowser = () =>
  typeof window !== "undefined" && typeof window.document !== "undefined";

/**
 * https://github.com/atomantic/is-ua-webview
 * @param ua User agent string
 * @returns {boolean} true if the user agent is a web view
 */
const isWebView = (ua: string) => {
  const rules = [
    // if it says it's a webview, let's go with that
    "WebView",
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
    // old chrome android webview agent
    "Linux; U; Android",
  ];
  const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");

  return !!ua.match(webviewRegExp);
};

/**
 * https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
 * @param ua User agent string
 * @returns {boolean} true if the user agent is a iOS webview
 */
const isIOSWebView = (ua: string) => {
  const rules = [
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
  ];
  const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");

  return !!ua.match(webviewRegExp);
};

/**
 * Determines the mobile operating system.
 * Stolen from https://stackoverflow.com/a/21742107
 */
export const getMobileOperatingSystem = () => {
  if (typeof window == "undefined") {
    return "unknown";
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export { isNode, isBrowser, isWebView, isIOSWebView };
