import * as React from "react";
import { SVGProps } from "react";
const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4698 17.5304C16.7627 17.8233 17.2375 17.8233 17.5304 17.5304C17.8233 17.2375 17.8233 16.7627 17.5304 16.4698L16.4698 17.5304ZM17.5304 16.4698L12.4395 11.3788L11.3788 12.4395L16.4698 17.5304L17.5304 16.4698Z"
      fill="currentColor"
    />
    <path
      d="M7.54545 14.0909C11.1604 14.0909 14.0909 11.1604 14.0909 7.54545C14.0909 3.9305 11.1604 1 7.54545 1C3.9305 1 1 3.9305 1 7.54545C1 11.1604 3.9305 14.0909 7.54545 14.0909Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Search;
