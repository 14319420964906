import { BRAND_NAME } from "@chef/constants";
import { Whisk } from "@chef/icons/symbol";
import { GLChef } from "@chef/icons/large";

export const intl = {
  AMK: { TITLE: "Tips fra kokken:" },
  GL: { TITLE: "Tips fra kokken:" },
  LMK: { TITLE: "Tips från kocken:" },
  RN: { TITLE: "Tip fra kokken:" },
}[BRAND_NAME];

export const brand = (
  {
    AMK: { ChefIcon: Whisk },
    GL: { ChefIcon: GLChef },
    LMK: { ChefIcon: Whisk },
    RN: { ChefIcon: Whisk },
  } as const
)[BRAND_NAME];
