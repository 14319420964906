import { useEffect, useState } from "react";

import { isBrowser, isWebView as isWebViewHelper } from "@chef/utils/runtime";

export const useEnvironment = () => {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    setIsWebView(
      isWebViewHelper(
        typeof navigator !== "undefined" ? navigator.userAgent : "",
      ),
    );
  }, []);

  return {
    isNode: false,
    isBrowser: isBrowser(),
    isWebView,
  };
};
