import * as React from "react";
import { SVGProps } from "react";
const Locked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.96582 7.53334V4.03334C4.96582 3.22885 5.2854 2.4573 5.85426 1.88844C6.42313 1.31958 7.19467 1 7.99916 1C8.80365 1 9.57519 1.31958 10.1441 1.88844C10.7129 2.4573 11.0325 3.22885 11.0325 4.03334V7.53334"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99955 10.1001V12.4335M2.86621 7.53345H13.1329V15.0001H2.86621V7.53345Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Locked;
