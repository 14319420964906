import React, { useCallback } from "react";
import clsx from "clsx";

import { Check } from "@chef/icons/large";

import { Card, Button, Currency, ButtonCircle } from "../components";
import { Language, PropsOf } from "../../types";
import AverageDishRating from "./AverageDishRating";

const intl = {
  no: {
    ADD: "Legg til",
    MAX_DISHES_CHOSEN: "Maks antall valgt",
    MAX_DISHES_CHOSEN_LINK: "Se dine valg",
    ADDED_TO_CART: "Lagt i handlekurven",
  },
  se: {
    ADD: "Lägg till",
    MAX_DISHES_CHOSEN: "Max antal valda",
    MAX_DISHES_CHOSEN_LINK: "Se dina val",
    ADDED_TO_CART: "Tillagd i varukorgen",
  },
  dk: {
    ADD: "Tilføj",
    MAX_DISHES_CHOSEN: "Max antal valgt",
    MAX_DISHES_CHOSEN_LINK: "Se dine valg",
    ADDED_TO_CART: "Tilføjet til indkøbskurven",
  },
};

interface MealCardProps extends PropsOf<typeof Card> {
  language: Language;
  text?: string;
  price?: number;
  tags?: React.ReactElement[] | React.ReactElement;
  averageRating?: number;
  numberOfRatings?: number;
  CampaignTag?: (
    props: React.HTMLAttributes<HTMLDivElement>,
  ) => React.ReactElement | null;
  ImageTag?: React.ReactElement;
  Image?:
    | ((
        props: React.HTMLAttributes<HTMLImageElement>,
      ) => React.ReactElement | null)
    | React.MemoExoticComponent<() => JSX.Element>;
  addons?: React.ReactNode;
  onAddRemoveProduct?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onShowBasket?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  borderless?: boolean;
  hideFooter?: boolean;
  disabled?: boolean;
  inBasket?: boolean;
  compact?: boolean;
  hideAddRemoveProductButton?: boolean;
  className?: string;
  innerClassName?: string;
  descriptionBlockStyle?: string;
  showAverageRating?: boolean;
  id?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const MealCard = ({
  language = "no",
  as = "div",
  href,
  onClick,
  text = "",
  price = 0,
  tags = [],
  averageRating,
  numberOfRatings,
  CampaignTag = () => null,
  ImageTag,
  Image = () => null,
  addons = [],
  onAddRemoveProduct = () => null,
  onShowBasket = () => null,
  borderless = false,
  hideFooter = false,
  disabled = false,
  inBasket = false,
  compact = false,
  hideAddRemoveProductButton = false,
  className,
  id,
  innerClassName,
  descriptionBlockStyle,
  showAverageRating,
  ...props
}: MealCardProps) => {
  const { ADD, MAX_DISHES_CHOSEN, MAX_DISHES_CHOSEN_LINK, ADDED_TO_CART } =
    intl[language];

  const hasNegativePrice = price < 0;

  let InnerType: keyof React.ReactHTML = "div";

  if (onClick) {
    InnerType = "button";
  }
  if (href) {
    InnerType = "a";
  }

  const ImageGenerated = useCallback(() => <Image />, []);

  return (
    <Card
      className={clsx("flex flex-col", className)}
      noPadding
      noShadow={borderless}
      as={as}
      {...props}
    >
      <div
        className={clsx(
          "flex flex-col h-full text-left relative",
          innerClassName,
        )}
      >
        <InnerType
          className={clsx(
            "relative w-full flex-grow",
            disabled && "opacity-50",
          )}
          href={href}
          onClick={onClick}
        >
          <CampaignTag className="absolute z-10 top-2 left-2 md:top-4 md:left-4" />

          <ImageGenerated />

          {ImageTag}
        </InnerType>

        <div
          className={clsx(
            "flex flex-col justify-between h-full w-full",
            {
              "py-2 md:py-4": borderless && !compact,
              "p-2 md:p-4": !borderless && !compact,
              "p-1 md:p-1": compact && !borderless,
              "py-1 md:py-1": compact && borderless,
            },
            descriptionBlockStyle,
          )}
          id={id}
        >
          <div>
            {tags && <div className="flex flex-wrap gap-2 mb-2">{tags}</div>}
            {showAverageRating && (
              <div className="flex items-center gap-1 mb-2 text-xs text-grey-1">
                <AverageDishRating
                  language={language}
                  averageRating={averageRating}
                  numberOfRatings={numberOfRatings}
                />
              </div>
            )}

            <InnerType href={href} onClick={onClick}>
              <div className="text-left line-clamp-2">{text}</div>
            </InnerType>
          </div>

          {!hideFooter && (
            <div className="flex items-center justify-between pt-2">
              {disabled ? (
                <div className="text-sm leading-4">
                  <div className="text-grey-1">{MAX_DISHES_CHOSEN}</div>

                  <button onClick={onShowBasket} className="underline">
                    {MAX_DISHES_CHOSEN_LINK}
                  </button>
                </div>
              ) : (
                <div>
                  <Currency
                    language={language}
                    prefix={hasNegativePrice ? "-" : "+"}
                    className="mt-2 text-xs md:text-sm"
                    hideIfZero
                  >
                    {price}
                  </Currency>
                </div>
              )}

              {inBasket && (
                <ButtonCircle
                  small
                  primary
                  onClick={onAddRemoveProduct}
                  Icon={Check}
                  className="shrink-0"
                  id={`${id}-circle-btn`}
                  name={ADDED_TO_CART}
                />
              )}

              {!hideAddRemoveProductButton && !inBasket && !disabled && (
                <Button
                  tiny
                  outlined
                  onClick={onAddRemoveProduct}
                  className="!px-3"
                  id={`${id}-add-btn`}
                >
                  {ADD}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div>{addons}</div>
    </Card>
  );
};

export default MealCard;
