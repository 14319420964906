import { api as generatedApi } from "./generated";
import { listenToHydrate } from "./baseApi";

// this is used to create customly cached graph queries, useful for more advanced stuff

const hydratedDataFromServer: {
  [endpoint: string]: {
    args: any;
    data: any;
  }[];
} = {};

listenToHydrate((data) => {
  for (const query in data.queries || {}) {
    // parse queryName
    const queryName = query.slice(0, query.indexOf("("));

    // parse arguments used to fetch this data
    const argsAsString = query.slice(
      query.indexOf("(") + 1,
      query.lastIndexOf(")"),
    );

    // JSON.parse can't parse "undefined", so check for it here
    let queryArgs: any = undefined;
    if (argsAsString !== "undefined") {
      queryArgs = JSON.parse(argsAsString);
    }

    if (!hydratedDataFromServer[queryName]) {
      hydratedDataFromServer[queryName] = [];
    }

    hydratedDataFromServer[queryName].push({
      args: queryArgs,
      data: data.queries[query].data,
    });
  }
});

interface ICacheInterface {
  [cacheKey: string]: object;
}

export interface ICustomQueryInterface<Data, Args> {
  queryName: keyof typeof generatedApi.endpoints;
  queryDocument: string;

  createDataFromCache: (args: Args, cache: ICacheInterface) => Data | null;
  createObjectCacheValue: (args: Args, data: Data) => ICacheInterface;
}

export const createCustomCacheForQueries = (
  customQueries: ICustomQueryInterface<any, any>[],
): typeof generatedApi => {
  return generatedApi.injectEndpoints({
    endpoints: (builder) => {
      const newQueries: any = {};

      for (const {
        queryName,
        queryDocument,
        createDataFromCache,
        createObjectCacheValue,
      } of customQueries) {
        let customQueryCache: ICacheInterface = {};

        newQueries[queryName] = builder.query({
          queryFn: async (args, api, extraOptions, baseQuery) => {
            // first before we attempt to do any queries, check if we have sever hydrated data
            const hydratedData = hydratedDataFromServer[queryName];
            if (hydratedData) {
              for (const { args, data } of hydratedData) {
                const cacheToAdd = createObjectCacheValue(args, data);

                customQueryCache = {
                  ...customQueryCache,
                  ...cacheToAdd,
                };
              }

              // remove hydrated data
              delete hydratedDataFromServer[queryName];
            }

            // forced will be true on cache invalidation and re-running the query
            if (api.forced) {
              // invalidate cache
              customQueryCache = {};
            }

            // try to get the cached data
            const cachedData = createDataFromCache(args, customQueryCache);
            if (cachedData !== null) {
              return {
                data: cachedData,
              };
            }

            // fetch the data
            const result = await baseQuery(
              {
                document: queryDocument,
                variables: args,
              },
              // not correctly typed -- it needs these two
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              api,
              extraOptions,
            );

            // emulate "onCacheEntryAdded" since RTK doesn't call it immediately after finishing the call
            // NOTE: might want to support "promise" cache in the future if required
            if (result?.data) {
              const cacheToAdd = createObjectCacheValue(args, result.data);

              customQueryCache = {
                ...customQueryCache,
                ...cacheToAdd,
              };
            }

            return result as any;
          },

          onCacheEntryAdded: async (args, api) => {
            try {
              const { data } = await api.cacheDataLoaded;

              const cacheToAdd = createObjectCacheValue(args, data);

              customQueryCache = {
                ...customQueryCache,
                ...cacheToAdd,
              };
            } catch (err) {
              // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
              // in which case `cacheDataLoaded` will throw
            }

            await api.cacheEntryRemoved;

            // clean up cache for query
            customQueryCache = {};
          },
        });
      }

      return newQueries;
    },

    overrideExisting: true,

    // converting to any here, since we're _technically_ changing this, but we're not really in the eyes of typescript
  }) as any;
};
