import { BASE_64_1X1_GREY } from "@chef/constants";

export class DummyImageUrlBuilderShim {
  constructor() {
    return this;
  }

  withOptions() {
    return this;
  }
  image() {
    return this;
  }
  dataset() {
    return this;
  }
  projectId() {
    return this;
  }
  bg() {
    return this;
  }
  dpr() {
    return this;
  }
  width() {
    return this;
  }
  height() {
    return this;
  }
  focalPoint() {
    return this;
  }
  maxWidth() {
    return this;
  }
  minWidth() {
    return this;
  }
  maxHeight() {
    return this;
  }
  minHeight() {
    return this;
  }
  size() {
    return this;
  }
  blur() {
    return this;
  }
  sharpen() {
    return this;
  }
  rect() {
    return this;
  }
  format() {
    return this;
  }
  invert() {
    return this;
  }
  orientation() {
    return this;
  }
  quality() {
    return this;
  }
  forceDownload() {
    return this;
  }
  flipHorizontal() {
    return this;
  }
  flipVertical() {
    return this;
  }
  ignoreImageParams() {
    return this;
  }
  fit() {
    return this;
  }
  crop() {
    return this;
  }
  saturation() {
    return this;
  }
  auto() {
    return this;
  }
  pad() {
    return this;
  }
  url() {
    return BASE_64_1X1_GREY;
  }
  toString() {
    return BASE_64_1X1_GREY;
  }
}
