import { showNewIntercomMessageWithEmailFallback } from "@chef/utils/intercom";
import { config } from "@chef/constants";

const sendIntercomMessage = ({
  prepopulatedMessage,
  subject,
}: {
  prepopulatedMessage: string;
  subject: string;
}) => {
  showNewIntercomMessageWithEmailFallback({
    prepopulatedMessage,
    subject,
    email: config.supportEmail,
  });
};

export { sendIntercomMessage };
