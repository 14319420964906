import Personnummer from "personnummer";

/**
 * Returns whether the provided birth number is valid.
 *
 * Shamelessly stolen from https://github.com/marjak93/birth-number-validator
 *
 * Rules of validation:
 * - Must be 11 digits
 * - Digit 1 and 2 (d) must be between 01-31 or 41-71 inclusive
 * - Digit 3 and 4 (m) must be between 01-12 or 41-52 inclusive
 * - Digit 5 and 6 (å) must be between 00-99 inclusive
 * - Digit 7-9 (i) must be between 000-999 inclusive
 * - Digit 10 and 11 (k) is a checksum of the 9 previous digits
 */
export const validateNorwegianSSN = (input: string): boolean => {
  if (typeof input !== "string") {
    console.debug("input is not a string");
    return false;
  }

  if (input.length !== 11) {
    console.debug("input has invalid length");
    return false;
  }

  // Verify birth number only contains valid characters
  const segments = input.match(
    /([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{3})([0-9]{2})/,
  );

  if (!segments) {
    console.debug("birth number has invalid characters");
    return false;
  }

  // Destructure segments into own variables
  const [, d, m, å, i, k] = segments;

  // d segment check
  if (!((+d >= 1 && +d <= 31) || (+d >= 41 && +d <= 71))) {
    console.debug(`d = ${d} does not fit constraints`);
    return false;
  }

  // m segment check
  if (!((+m >= 1 && +m <= 12) || (+m >= 41 && +m <= 52))) {
    console.debug(`m = ${m} does not fit constraints`);
    return false;
  }

  // k segment check
  const checksum = calculateChecksum(d, m, å, i);
  if (checksum !== k) {
    console.debug(`k = ${k} does not match checksum = ${checksum}`);
    return false;
  }

  console.debug("birth number is valid!");

  return true;
};

const calculateChecksum = (
  d: string,
  m: string,
  å: string,
  i: string,
): string => {
  const [d1, d2] = d;
  const [m1, m2] = m;
  const [å1, å2] = å;
  const [i1, i2, i3] = i;

  let k1 = String(
    11 -
      ((3 * +d1 +
        7 * +d2 +
        6 * +m1 +
        1 * +m2 +
        8 * +å1 +
        9 * +å2 +
        4 * +i1 +
        5 * +i2 +
        2 * +i3) %
        11),
  );

  let k2 = String(
    11 -
      ((5 * +d1 +
        4 * +d2 +
        3 * +m1 +
        2 * +m2 +
        7 * +å1 +
        6 * +å2 +
        5 * +i1 +
        4 * +i2 +
        3 * +i3 +
        2 * +k1) %
        11),
  );

  if (k1 === "11") {
    k1 = "0";
  }
  if (k2 === "11") {
    k2 = "0";
  }

  return k1 + k2;
};

export const validateSwedishSSN = (input: string): boolean => {
  return Personnummer.valid(input);
};

export const getAgeFromSwedishSSN = (input: string): number => {
  if (!input || !Personnummer.valid(input)) {
    return 0;
  }
  const personnummerInstance = Personnummer.parse(input);
  return personnummerInstance.getAge();
};
