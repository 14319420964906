import React from "react";
import clsx from "clsx";

import { Currency } from "../Currency";

import { Language } from "../../../types";

interface PriceTagProps {
  className?: string;
  priceBeforeDiscount?: number | string;
  isPercentage?: boolean;
  prefix?: string;
  isDiscounted?: boolean;
  isHighlighted?: boolean;
  hideIfZero?: boolean;
  emphasized?: boolean;
  children: React.ReactNode;
  language: Language;
  id?: string;
}

export const PriceTag = ({
  className,
  priceBeforeDiscount,
  prefix,
  isPercentage,
  isDiscounted = false,
  isHighlighted = false,
  hideIfZero = false,
  emphasized = false,
  children,
  language,
  id,
}: PriceTagProps) => {
  if (hideIfZero && !children) {
    return null;
  }

  return (
    <div className={clsx("flex", className)} id={id}>
      {priceBeforeDiscount && (
        <span className="mr-2 line-through text-grey-1">
          {isPercentage ? (
            <span>
              {prefix}
              {+priceBeforeDiscount}%
            </span>
          ) : (
            <Currency language={language} prefix={prefix}>
              {+priceBeforeDiscount}
            </Currency>
          )}
        </span>
      )}
      <span
        className={clsx(
          isDiscounted && "text-discount",
          isHighlighted &&
            "flex items-center bg-highlight text-xs rounded-px px-1.5 h-6 max-w-max",
        )}
      >
        {isPercentage ? (
          <span>
            {prefix}
            {children}%
          </span>
        ) : (
          <Currency language={language} prefix={prefix} emphasized={emphasized}>
            {children}
          </Currency>
        )}
      </span>
    </div>
  );
};
