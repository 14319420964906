import { createApi } from "@reduxjs/toolkit/query/react";
import { query } from "../../graphql/baseApi";

interface IGetCioAttributesResponse {
  customer: {
    id: string;
    attributes: Record<string, any>;
    unsubscribed: boolean;
  };
}

export const cioApi = createApi({
  reducerPath: "cio",
  baseQuery: query,
  tagTypes: ["contactPreferences"],
  endpoints: (builder) => ({
    getCioAttributes: builder.query<IGetCioAttributesResponse, void>({
      query: () => {
        return "/cio/attributes";
      },
      providesTags: ["contactPreferences"],
    }),
    setCioAttribute: builder.mutation<
      IGetCioAttributesResponse,
      { key: string; value: any }
    >({
      query: ({ key, value }) => ({
        url: "/cio/attributes",
        method: "PUT",
        body: { [key]: value },
      }),
      invalidatesTags: ["contactPreferences"],
    }),
  }),
});

export const { useGetCioAttributesQuery, useSetCioAttributeMutation } = cioApi;
