import * as React from "react";
import { SVGProps } from "react";
const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2460_1143)">
      <path
        d="M21 3.89305C20.2271 4.21429 19.3978 4.43216 18.5264 4.52942C19.4161 4.02968 20.0972 3.23702 20.42 2.2954C19.5854 2.75819 18.6642 3.09424 17.6826 3.27641C16.8966 2.48989 15.7785 2 14.5384 2C12.1593 2 10.2303 3.80937 10.2303 6.03969C10.2303 6.35601 10.2683 6.66496 10.3418 6.96039C6.76201 6.79174 3.58768 5.18301 1.46314 2.7385C1.09176 3.33425 0.880492 4.02842 0.880492 4.76943C0.880492 6.1714 1.64161 7.40841 2.7964 8.13213C2.0904 8.10999 1.42639 7.92782 0.84508 7.62502V7.67549C0.84508 9.63256 2.33056 11.2659 4.30024 11.6376C3.93936 11.7287 3.55882 11.7792 3.16514 11.7792C2.88696 11.7792 2.61794 11.7534 2.35417 11.7041C2.90267 13.3104 4.49315 14.4785 6.37756 14.5105C4.90388 15.5937 3.04573 16.2374 1.02749 16.2374C0.679756 16.2374 0.337236 16.2177 0 16.182C1.90671 17.3304 4.17036 18 6.60327 18C14.528 18 18.8597 11.8432 18.8597 6.50374L18.8453 5.98063C19.6917 5.41439 20.4239 4.70297 21 3.89305Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2460_1143">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Twitter;
