import * as React from "react";
import { SVGProps } from "react";
const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 24.5L10.3292 24.8354C10.4581 25.0933 10.7234 25.2544 11.0117 25.2499C11.3 25.2454 11.5602 25.0761 11.681 24.8143L11 24.5ZM10 22.5L10.6708 22.1646C10.5196 21.8621 10.1846 21.6982 9.85291 21.7646L10 22.5ZM7.5 23L6.82918 22.6646C6.70108 22.9208 6.7301 23.2276 6.90397 23.4552C7.07784 23.6829 7.3662 23.7916 7.64709 23.7354L7.5 23ZM11.6708 24.1646L10.6708 22.1646L9.32918 22.8354L10.3292 24.8354L11.6708 24.1646ZM9.85291 21.7646L7.35291 22.2646L7.64709 23.7354L10.1471 23.2354L9.85291 21.7646ZM8.17082 23.3354L10.6708 18.3354L9.32918 17.6646L6.82918 22.6646L8.17082 23.3354ZM13.319 17.6857L10.319 24.1857L11.681 24.8143L14.681 18.3143L13.319 17.6857Z"
      fill="currentColor"
    />
    <path
      d="M15.5 24.5L16.1708 24.8354C16.0464 25.0843 15.7944 25.2438 15.5163 25.2498C15.2381 25.2559 14.9795 25.1074 14.8444 24.8642L15.5 24.5ZM16.5 22.5L15.8292 22.1646C15.9804 21.8621 16.3154 21.6982 16.6471 21.7646L16.5 22.5ZM19 23L19.6708 22.6646C19.7989 22.9208 19.7699 23.2276 19.596 23.4552C19.4222 23.6829 19.1338 23.7916 18.8529 23.7354L19 23ZM14.8292 24.1646L15.8292 22.1646L17.1708 22.8354L16.1708 24.8354L14.8292 24.1646ZM16.6471 21.7646L19.1471 22.2646L18.8529 23.7354L16.3529 23.2354L16.6471 21.7646ZM18.3292 23.3354L15.8292 18.3354L17.1708 17.6646L19.6708 22.6646L18.3292 23.3354ZM13.6556 19.6358L16.1556 24.1358L14.8444 24.8642L12.3444 20.3642L13.6556 19.6358Z"
      fill="currentColor"
    />
    <path
      d="M20.25 11C20.25 15.0041 17.0041 18.25 13 18.25C8.99594 18.25 5.75 15.0041 5.75 11C5.75 6.99594 8.99594 3.75 13 3.75C17.0041 3.75 20.25 6.99594 20.25 11Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33301 11.3667L11.7775 13.9334L16.6663 8.80005"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default Success;
