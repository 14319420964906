/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";

export const useOutlineListener = () => {
  useEffect(() => {
    try {
      const domEvents = "addEventListener" in document;
      let previousAction: string;

      const addListener = (type: any, callback: any) => {
        if (domEvents) {
          document.addEventListener(type, callback);
        } else {
          (document as any).attachEvent(`on${type}`, callback);
        }
      };

      addListener("mousedown", () => {
        if (previousAction === "mousedown") {
          return;
        }
        document.body.classList.remove("body--a11y");
        previousAction = "mousedown";
      });

      addListener("keydown", () => {
        if (previousAction === "keydown") {
          return;
        }
        document.body.classList.add("body--a11y");
        previousAction = "keydown";
      });
    } catch (error) {
      // Oh well
    }
  }, []);
};
