import * as React from "react";
import { SVGProps } from "react";
const InfoOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={9} cy={9} r={8.5} stroke="currentColor" />
    <path
      d="M8.02263 5.41145C8.02263 5.1316 8.12016 4.89414 8.31521 4.69909C8.51451 4.49979 8.75408 4.40015 9.03394 4.40015C9.30956 4.40015 9.54489 4.49979 9.73995 4.69909C9.93924 4.89414 10.0389 5.1316 10.0389 5.41145C10.0389 5.69131 9.93924 5.93089 9.73995 6.13018C9.54489 6.32523 9.30956 6.42276 9.03394 6.42276C8.75408 6.42276 8.51451 6.32523 8.31521 6.13018C8.12016 5.93089 8.02263 5.69131 8.02263 5.41145ZM7.4502 13.0885C7.67917 13.0503 7.83606 12.9931 7.92087 12.9168C8.04808 12.8023 8.11168 12.5966 8.11168 12.2998V8.47082C8.11168 8.20792 8.06716 8.02983 7.97811 7.93654C7.88906 7.83902 7.71309 7.76693 7.4502 7.72029V7.40227H9.94984V12.3507C9.94984 12.6348 9.99224 12.8171 10.0771 12.8977C10.1619 12.9782 10.3209 13.0418 10.5541 13.0885V13.4001H7.4502V13.0885Z"
      fill="currentColor"
    />
  </svg>
);
export default InfoOutlined;
