import { language } from "./misc";

export const ERROR_CODES = (
  {
    no: {
      // AUTH
      100: { error: "AUTH_MISSING", userMessage: "feilmelding 100" },
      101: { error: "AUTH_EXPIRED", userMessage: "feilmelding 101" },
      102: { error: "INVALID_AUTH", userMessage: "feilmelding 102" },
      103: {
        error: "INVALID_CREDENTIALS",
        userMessage: "feilmelding 103: Feil brukernavn eller passord",
      },

      // ERRORS
      200: { error: "BAD_INPUTS", userMessage: "feilmelding 200" },
      201: { error: "USER_NOT_FOUND", userMessage: "feilmelding 201" },
      202: { error: "ADDRESS_NOT_FOUND", userMessage: "feilmelding 202" },
      203: {
        error: "BILLING_AGREEMENT_NOT_FOUND",
        userMessage: "feilmelding 203",
      },
      204: {
        error: "EMAIL_NOT_FOUND",
        userMessage: "feilmelding 204: Kontakt kundeservice",
      },
      205: {
        error: "MENU_NOT_FOUND",
        userMessage: "feilmelding: 205: Finner ikke valgt meny",
      },
      206: {
        error: "EXTRA_NOT_FOUND",
        userMessage: "feilmelding 206: Kontakt kundeservice",
      },
      207: {
        error: "MISSING_QUANTITY",
        userMessage: "feilmelding: 207: Kontakt kundeservice",
      },
      208: {
        error: "MISSING_ADDRESS",
        userMessage: "feilmelding 208:  Kontakt kundeservice",
      },
      209: {
        error: "CUSTOMER_ALREADY_EXISTS",
        userMessage: "feilmelding: 209: Kontakt kundeservice",
      },

      210: {
        error: "CUSTOMER_ALREADY_EXISTS",
        userMessage: "feilmelding: 205: Finner ikke valgt meny",
      },
      212: {
        error: "PRODUCT_LIST_EMPTY",
        userMessage: "feilmelding: 205: Kontakt kundeservice",
      },
      211: {
        error: "EMAIL_RECOVERY_FAILED",
        userMessage: "feilmelding: 211: Kontakt kundeservice",
      },
      222: {
        error: "FAILED_TO_SAVE_IN_REGISTER_INFO",
        userMessage: "feilmelding: 222: Kontakt kundeservice",
      },
      223: {
        error: "BASKET_NOT_FOUND",
        userMessage: "feilmelding: 223: Kontakt kundeservice",
      },
      224: {
        error: "BASKET_IS_EMPTY",
        userMessage: "feilmelding: 224: Kontakt kundeservice",
      },

      // LOGIC
      300: { error: "INVALID_POSTALCODE", userMessage: "feilmelding 300" },
      304: { error: "INVALID_TIMEBLOCK", userMessage: "feilmelding 304" },

      306: {
        error: "USER_NOT_PASSED_INVOICE_REQUIREMENTS",
        userMessage: "feilmelding 306: Ta kontakt med kundeservice.",
      },

      311: {
        error: "INVALID_COUPON",
        userMessage: "feilmelding 311: Kunne ikke legge til rabattkode",
      },
      312: { error: "COUPON_ALREADY_USED", userMessage: "feilmelding 312" },
      313: {
        error: "COUPON_EXPIRED",
        userMessage: "feilmelding 313: rabattkoden er ikke gyldig",
      },
      314: { error: "COUPON_NOT_ACTIVE", userMessage: "feilmelding 314" },
      315: {
        error: "COUPON_NOT_VALID_DIRECT_ORDER",
        userMessage:
          "feilmelding 315: Denne rabattkoden er ikke gyldig for denne type kjøp",
      },

      305: { error: "INVALID_PAYEX_INFO", userMessage: "feilmelding 305" },

      307: { error: "ACCOUNT_IS_NOT_ACTIVE", userMessage: "feilmelding 307" },
      308: {
        error: "EMAIL_ALREADY_IN_USE",
        userMessage: "feilmelding 308: Denne e-posten er allerede i bruk",
      },
      309: {
        error: "DEVIATION_IS_FROM_PAST",
        userMessage: "feilmelding 309: Du prøver å endre en tidligere uke",
      },
      310: {
        error: "INVALID_PAYMENTMETHOD",
        userMessage: "feilmelding 310: Kontakt kundeservice",
      },

      330: {
        error: "INVALID_TOKEN_PWD_RECOVERY",
        userMessage: "feilmelding 330",
      },
      331: {
        error: "INVALID_CHANGE_PASSWORD_REQUEST",
        userMessage: "feilmelding 331",
      },

      360: {
        error: "DUPLICATE_DISCOUNT_CODE_ON_USER",
        userMessage: "feilmelding 360: Du har allerede denne rabattkoden",
      },

      429: {
        error: "RATE_LIMIT_EXCEEDED",
        userMessage:
          "Feilmelding 429: For mange forsøk, prøv igjen om 1 minutt",
      },

      500: { error: "POSTAL_CODE_NOT_FOUND", userMessage: "feilmelding 500" },
      501: {
        error: "POSTAL_CODE_HAS_NO_TIMEBLOCKS",
        userMessage: "feilmelding 501",
      },
      502: {
        error: "POSTAL_CODE_NOT_VALID_3RD_PARTY",
        userMessage: "feilmelding 502",
      },
      503: { error: "POSTAL_CODE_IS_POBOX", userMessage: "feilmelding 503" },
      504: {
        error: "POSTAL_CODE_SERVICE_IS_UNAVAILABLE",
        userMessage: "feilmelding 504",
      },

      600: { error: "EXCEPTION_THROW", userMessage: "feilmelding 600" },

      700: { error: "FILE_DOES_NOT_EXIST", userMessage: "feilmelding 700" },
      701: { error: "AVIDA_FILE_UPLOAD", userMessage: "feilmelding 701" },
      800: { error: "ORDER_NOT_FOUND", userMessage: "feilmelding 800" },
      900: { error: "INVALID_API", userMessage: "feilmelding 900" },

      999: { error: "GENERIC_ERROR", userMessage: "feilmelding 999" },
    },

    dk: {
      100: { error: "AUTH_MISSING", userMessage: "Fejlmelding 100" },
      101: { error: "AUTH_EXPIRED", userMessage: "Fejlmelding 101" },
      102: { error: "INVALID_AUTH", userMessage: "Fejlmelding 102" },
      103: {
        error: "INVALID_CREDENTIALS",
        userMessage: "Fejlmelding 103: Feil E-mail eller password.",
      },

      // ERRORS
      200: { error: "BAD_INPUTS", userMessage: "Fejlmelding 200" },
      201: { error: "USER_NOT_FOUND", userMessage: "Fejlmelding 201" },
      202: { error: "ADDRESS_NOT_FOUND", userMessage: "Fejlmelding 202" },
      203: {
        error: "BILLING_AGREEMENT_NOT_FOUND",
        userMessage: "Fejlmelding 203",
      },
      204: {
        error: "EMAIL_NOT_FOUND",
        userMessage: "Fejlmelding 204: Kontakt kundeservice",
      },
      205: {
        error: "MENU_NOT_FOUND",
        userMessage: "Fejlmelding: 205: Finner ikke den valgte menu",
      },
      206: {
        error: "EXTRA_NOT_FOUND",
        userMessage: "Fejlmelding 206: Kontakt kundeservice",
      },
      207: {
        error: "MISSING_QUANTITY",
        userMessage: "Fejlmelding: 207: Kontakt kundeservice",
      },
      208: {
        error: "MISSING_ADDRESS",
        userMessage: "Fejlmelding 208:  Kontakt kundeservice",
      },
      209: {
        error: "CUSTOMER_ALREADY_EXISTS",
        userMessage: "Fejlmelding: 209: Kontakt kundeservice",
      },

      210: {
        error: "CUSTOMER_ALREADY_EXISTS",
        userMessage: "Fejlmelding: 205: Finner ikke valgt meny",
      },
      212: {
        error: "PRODUCT_LIST_EMPTY",
        userMessage: "Fejlmelding: 205: Kontakt kundeservice",
      },
      211: {
        error: "EMAIL_RECOVERY_FAILED",
        userMessage: "Fejlmelding: 211: Kontakt kundeservice",
      },
      222: {
        error: "FAILED_TO_SAVE_IN_REGISTER_INFO",
        userMessage: "Fejlmelding: 222: Kontakt kundeservice",
      },
      223: {
        error: "BASKET_NOT_FOUND",
        userMessage: "Fejlmelding: 223: Kontakt kundeservice",
      },
      224: {
        error: "BASKET_IS_EMPTY",
        userMessage: "Fejlmelding: 224: Kontakt kundeservice",
      },

      // LOGIC
      300: { error: "INVALID_POSTALCODE", userMessage: "Fejlmelding 300" },
      304: { error: "INVALID_TIMEBLOCK", userMessage: "Fejlmelding 304" },

      311: {
        error: "INVALID_COUPON",
        userMessage: "Fejlmelding 311: Rabatkoden er ikke gyldig",
      },
      312: { error: "COUPON_ALREADY_USED", userMessage: "Fejlmelding 312" },
      313: {
        error: "COUPON_EXPIRED",
        userMessage: "Fejlmelding 313: rabattkoden er ikke gyldig",
      },
      314: { error: "COUPON_NOT_ACTIVE", userMessage: "Fejlmelding 314" },
      315: {
        error: "COUPON_NOT_VALID_DIRECT_ORDER",
        userMessage:
          "Fejlmelding 315: rabatkoden er ikke gyldig til denne type køb",
      },

      305: { error: "INVALID_PAYEX_INFO", userMessage: "Fejlmelding 305" },
      306: { error: "INVALID_INVOICE_INFO", userMessage: "Fejlmelding 306" },

      307: { error: "ACCOUNT_IS_NOT_ACTIVE", userMessage: "Fejlmelding 307" },
      308: {
        error: "EMAIL_ALREADY_IN_USE",
        userMessage: "Fejlmelding 308: Denne e-mail er allerede i brug",
      },
      309: {
        error: "DEVIATION_IS_FROM_PAST",
        userMessage:
          "Fejlmelding 309: Du forsøger at ændre en uge, der er passeret",
      },
      310: {
        error: "INVALID_PAYMENTMETHOD",
        userMessage:
          "Fejlmelding 310: Betalingsmetoden er ugyldig, kontakt venligst kundeservice",
      },

      330: {
        error: "INVALID_TOKEN_PWD_RECOVERY",
        userMessage: "Fejlmelding 330",
      },
      331: {
        error: "INVALID_CHANGE_PASSWORD_REQUEST",
        userMessage: "Fejlmelding 331",
      },

      360: {
        error: "DUPLICATE_DISCOUNT_CODE_ON_USER",
        userMessage: "Fejlmelding 360: Du har allerede denne rabatkode",
      },

      429: {
        error: "RATE_LIMIT_EXCEEDED",
        userMessage: "Fejlmelding 429: For mange forsøg, prøv igen om et minut",
      },

      500: { error: "POSTAL_CODE_NOT_FOUND", userMessage: "Fejlmelding 500" },
      501: {
        error: "POSTAL_CODE_HAS_NO_TIMEBLOCKS",
        userMessage: "Fejlmelding 501",
      },
      502: {
        error: "POSTAL_CODE_NOT_VALID_3RD_PARTY",
        userMessage: "Fejlmelding 502",
      },
      503: { error: "POSTAL_CODE_IS_POBOX", userMessage: "Fejlmelding 503" },
      504: {
        error: "POSTAL_CODE_SERVICE_IS_UNAVAILABLE",
        userMessage: "Fejlmelding 504",
      },

      600: { error: "EXCEPTION_THROW", userMessage: "Fejlmelding 600" },

      700: { error: "FILE_DOES_NOT_EXIST", userMessage: "Fejlmelding 700" },
      701: { error: "AVIDA_FILE_UPLOAD", userMessage: "Fejlmelding 701" },
      800: { error: "ORDER_NOT_FOUND", userMessage: "Fejlmelding 800" },
      900: { error: "INVALID_API", userMessage: "Fejlmelding 900" },

      999: { error: "GENERIC_ERROR", userMessage: "Fejlmelding 999" },
    },

    se: {
      // AUTH
      100: {
        error: "AUTH_MISSING",
        userMessage: "Autentiseringen saknas, (felkod:100)",
        errorCode: 100,
      },
      101: {
        error: "AUTH_EXPIRED",
        userMessage: "Autentiseringen har gått ut, (felkod:101)",
        errorCode: 101,
      },
      102: {
        error: "INVALID_AUTH",
        userMessage: "Autentiseringen felaktig, (felkod:102)",
        errorCode: 102,
      },
      103: {
        error: "INVALID_CREDENTIALS",
        userMessage: "Felaktigt användarnamn eller lösenord, (felkod:103)",
        errorCode: 103,
      },

      // ERRORS
      200: {
        error: "GIFT_CARD_NOT_FOUND",
        userMessage: "Felaktig input, (felkod:200)",
        errorCode: 200,
      },
      201: {
        error: "USER_NOT_FOUND",
        userMessage: "Användare hittades inte, (felkod:201)",
        errorCode: 201,
      },
      202: {
        error: "ADDRESS_NOT_FOUND",
        userMessage: "Adress hittades inte, (felkod:202)",
        errorCode: 202,
      },
      203: {
        error: "BILLING_AGREEMENT_NOT_FOUND",
        userMessage: "Avtal hittades ej, (felkod:203)",
        errorCode: 203,
      },
      204: {
        error: "EMAIL_NOT_FOUND",
        userMessage: "Ogiltig e-postadress, (felkod:204)",
        errorCode: 204,
      },
      205: {
        error: "MENU_NOT_FOUND",
        userMessage: "Vald meny finns inte, (felkod:205)",
        errorCode: 205,
      },
      206: {
        error: "EXTRA_NOT_FOUND",
        userMessage: "Extra hittades inte, (felkod:206)",
        errorCode: 206,
      },
      207: {
        error: "MISSING_QUANTITY",
        userMessage: "Felaktig kvantitet angiven, (felkod:207)",
        erroCode: 207,
      },
      208: {
        error: "MISSING_ADDRESS",
        userMessage: "Leveransadress saknas, (felkod:208)",
        errorCode: 208,
      },
      209: {
        error: "CUSTOMER_ALREADY_EXISTS",
        userMessage: "Kundnen existerar redan, (felkod:209)",
        errorCode: 209,
      },

      210: {
        error: "CUSTOMER_ALREADY_EXISTS",
        userMessage: "Kundnen existerar redan, (felkod:210)",
        errorCode: 210,
      },
      211: {
        error: "EMAIL_RECOVERY_FAILED",
        userMessage: "Lösenordsåterställningen misslyckades, (felkod:211)",
        errorCode: 211,
      },
      212: {
        error: "PRODUCT_LIST_EMPTY",
        userMessage: "Produktlistan är tom, (felkod:212)",
        errorCode: 212,
      },

      222: {
        error: "FAILED_TO_SAVE_IN_REGISTER_INFO",
        userMessage: "Kunde ej uppdatera information, (felkod:222)",
        errorCode: 222,
      },
      223: {
        error: "BASKET_NOT_FOUND",
        userMessage: "Varukorgen hittades ej, (felkod:223)",
        errorCode: 223,
      },
      224: {
        error: "BASKET_IS_EMPTY",
        userMessage: "Varukorgen är tom, (felkod:224)",
        errorCode: 224,
      },

      // LOGIC
      300: {
        error: "INVALID_POSTALCODE",
        userMessage: "Fel postkod, (felkod:300)",
        errorCode: 300,
      },
      304: {
        error: "INVALID_TIMEBLOCK",
        userMessage: "Fel med leveranstid, (felkod:304)",
        errorCode: 304,
      },

      305: {
        error: "INVALID_PAYEX_INFO",
        userMessage: "Fel från vår betalningsleverantör, (felkod:305)",
        errorCode: 305,
      },
      306: {
        error: "INVALID_INVOICE_INFO",
        userMessage: "Fel faktura information, (felkod:306)",
        errorCode: 306,
      },

      307: {
        error: "ACCOUNT_IS_NOT_ACTIVE",
        userMessage: "Konto är inaktivt, (felkod:307)",
        errorCode: 307,
      },
      308: {
        error: "EMAIL_ALREADY_IN_USE",
        userMessage:
          "Den angivna e-postadress är redan registrerad, (felkod:308)",
        errorCode: 308,
      },
      309: {
        error: "DEVIATION_IS_FROM_PAST",
        userMessage:
          "Den här leveransen är redan passerad och kan ej redigeras, (felkod:309)",
        errorCode: 309,
      },
      310: {
        error: "INVALID_PAYMENTMETHOD",
        userMessage: "Fel vid betalning, (felkod:310)",
        errorCode: 310,
      },
      311: {
        error: "INVALID_COUPON",
        userMessage: "Felaktig rabattkod, (felkod:311)",
        errorCode: 311,
      },
      312: {
        error: "COUPON_ALREADY_USED",
        userMessage: "Rabatten redan tillagd, (felkod:312)",
        errorCode: 312,
      },
      313: {
        error: "COUPON_EXPIRED",
        userMessage: "Rabattkoden är inte längre giltlig, (felkod:313)",
        errorCode: 313,
      },
      314: {
        error: "COUPON_NOT_ACTIVE",
        userMessage: "Rabattkod har gått ut, (felkod:314)",
        errorCode: 314,
      },
      315: {
        error: "COUPON_NOT_VALID_DIRECT_ORDER",
        userMessage:
          "Rabattkoden är inte giltig för den här typen av kö, (felkod:315)",
        errorCode: 315,
      },

      330: {
        error: "INVALID_TOKEN_PWD_RECOVERY",
        userMessage: "Felaktig lösenordsåterställning, (felkod:330)",
        errorCode: 330,
      },
      331: {
        error: "INVALID_CHANGE_PASSWORD_REQUEST",
        userMessage: "Felaktig byte av lösenord, (felkod:331)",
        errorCode: 331,
      },
      360: {
        error: "COUPON_ALREADY_USED",
        userMessage: "Rabatten redan tillagd, (felkod:312)",
        errorCode: 312,
      },

      429: {
        error: "RATE_LIMIT_EXCEEDED",
        userMessage: "För många försök. Försök igen om 1 minut. (felkod:429)",
        erroCode: 429,
      },

      500: {
        error: "POSTAL_CODE_NOT_FOUND",
        userMessage: "Postnummer hittades inte, (felkod:500)",
        errorCode: 500,
      },
      501: {
        error: "POSTAL_CODE_HAS_NO_TIMEBLOCKS",
        userMessage:
          "Vi levererar inta till ditt detta postnummer, (felkod:501)",
        errorCode: 501,
      },
      502: {
        error: "POSTAL_CODE_NOT_VALID_3RD_PARTY",
        userMessage: "Postnummeret är inte giltligt, (felkod:502)",
        errorCode: 502,
      },
      503: {
        error: "POSTAL_CODE_IS_POBOX",
        userMessage: "Postnummeret är inte giltligt, (felkod:503)",
        errorCode: 503,
      },
      504: {
        error: "POSTAL_CODE_SERVICE_IS_UNAVAILABLE",
        userMessage: "Postnummerskontrollen är inte giltlig, (felkod:504)",
        errorCode: 504,
      },

      600: {
        error: "EXCEPTION_THROW",
        userMessage: "Generellt fel, (felkod:600)",
        errorCode: 600,
      },

      700: {
        error: "FILE_DOES_NOT_EXIST",
        userMessage: "Filen existerar inte, (felkod:700)",
        errorCode: 700,
      },
      701: {
        error: "AVIDA_FILE_UPLOAD",
        userMessage: "Filen laddas upp, (felkod:701)",
        errorCode: 701,
      },
      800: {
        error: "ORDER_NOT_FOUND",
        userMessage: "Ordern hittades inte, (felkod:800)",
        errorCode: 800,
      },
      900: {
        error: "INVALID_API",
        userMessage: "Felaktigt API, (felkod:900)",
        errorCode: 900,
      },

      999: {
        error: "GENERIC_ERROR",
        userMessage: "Generellt fel, (felkod:999)",
        errorCode: 999,
      },
    },
  } as const
)[language];

export type ErrorCode = keyof typeof ERROR_CODES;
