import clsx from "clsx";

import { PlusMinusIcon } from "@chef/components";
import { BillingQuery, PreferencesQuery } from "@chef/state-management";
import { stripEmojis } from "@chef/utils/string";

type Preference =
  | BillingQuery["billing"]["preferences"][number]
  | PreferencesQuery["preferences"][number];

interface PreferencesListBoxProps {
  preferences: Preference[];
  type?: "concept" | "taste";
  className?: string;
}

export const PreferencesListBox = ({
  preferences,
  type = "concept",
  className,
}: PreferencesListBoxProps) => {
  const isConceptPreference = type === "concept";

  return (
    <div
      className={clsx(
        className,
        type === "concept"
          ? "bg-informationBG border-information"
          : "bg-errorBG border-error",
        "p-4 border-t-2 rounded-sm text-sm",
      )}
    >
      <ul className="list-inside">
        {preferences?.map((p) => (
          <li key={p.preferenceId} className="flex items-center gap-1">
            <PlusMinusIcon
              className={clsx(
                isConceptPreference ? "text-information" : "text-error",
                "w-5 h-5",
              )}
              isPlus={isConceptPreference}
            />{" "}
            <strong>{stripEmojis(p.preferenceName)}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
};
