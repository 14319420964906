import * as React from "react";
import { SVGProps } from "react";
const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.0395 19.5912V22C20.0395 22.5523 19.5918 23 19.0395 23H7C6.44771 23 6 22.5523 6 22V5.62061"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M7.66667 5.62061H6C6 8.77796 6.64972 16.35 11.9605 19.5912H26C20.6893 16.35 20.0395 8.77796 20.0395 5.62061H18.4011"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1807 4C11.0846 4 11.8191 4.72647 11.8191 5.62059C11.8191 6.5147 11.0846 7.24117 10.1807 7.24117"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.249 4C15.1529 4 15.8873 4.72647 15.8873 5.62059C15.8873 6.5147 15.1529 7.24117 14.249 7.24117"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Calendar;
