import { createApi } from "@reduxjs/toolkit/query/react";

import type { RecursivePartial } from "@chef/types/utils";
import type { SignupSession } from "@chef/types/payment";

import { query } from "../../graphql/baseApi";

type IGetSignupSessionResponse = RecursivePartial<SignupSession>;

type IUpdateSignupSessionArgs = RecursivePartial<SignupSession>;

type IUpdateSignupSessionResponse = {
  redirectUri: string;
  errors: string[];
};

export const signupSessionApi = createApi({
  reducerPath: "signupSession",
  baseQuery: query,
  tagTypes: ["signupSession"],
  endpoints: (builder) => ({
    getSignupSession: builder.query<IGetSignupSessionResponse, void>({
      query: () => ({
        url: `/signup/session`,
        method: "GET",
      }),
      providesTags: ["signupSession"],
    }),
    updateSignupSession: builder.mutation<
      IUpdateSignupSessionResponse,
      IUpdateSignupSessionArgs
    >({
      query: (body) => ({
        url: "/signup/session",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["signupSession"],
    }),
  }),
});

export const { useGetSignupSessionQuery, useUpdateSignupSessionMutation } =
  signupSessionApi;
