import * as React from "react";
import { SVGProps } from "react";
const Squares = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_12989_197223)">
      <path
        d="M4.08333 8.66667H1.75V1H9.41667V3.33333"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.08334 3.3335H11.75V11.0002H4.08334V3.3335Z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12989_197223">
        <rect width={12} height={12} fill="white" transform="translate(0.75)" />
      </clipPath>
    </defs>
  </svg>
);
export default Squares;
