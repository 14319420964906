import React from "react";
import clsx from "clsx";
import ListItem from "../../internals/ListItem";

interface OrderedListProps {
  className?: string;
  clickable?: boolean;
  children: React.ReactNode;
  id?: string;
}

export const OrderedList = ({
  className,
  children,
  clickable = false,
  id,
}: OrderedListProps) => {
  return (
    <ol className={clsx("space-y-6", className)} id={id}>
      {React.Children.map(children, (c, index) => (
        <ListItem
          id={`${id}_${index}`}
          number={index + 1}
          key={index}
          clickable={clickable}
        >
          {c}
        </ListItem>
      ))}
    </ol>
  );
};
