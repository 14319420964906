import { language } from ".";

export const FILTER_CATEGORIES = (
  {
    no: {
      ALL_CATEGORIES_SLUG: "alle",
      ALL_CATEGORIES: "Alle",

      YOUR_PICKS_SLUG: "dine-valg",
      YOUR_PICKS: "Dine valg",

      OFFERS_SLUG: "tilbud",
      OFFERS: "Tilbud",

      RECOMMENDATIONS_SLUG: "anbefalinger",
      RECOMMENDATIONS: "Anbefalt for deg",

      FAVORITES_SLUG: "mine-favoritter",
      FAVORITES: "Mine favoritter",
    },

    se: {
      ALL_CATEGORIES_SLUG: "alla",
      ALL_CATEGORIES: "Alla",

      YOUR_PICKS_SLUG: "dina-val",
      YOUR_PICKS: "Dina val",

      OFFERS_SLUG: "tilbud",
      OFFERS: "Tilbud",

      RECOMMENDATIONS_SLUG: "rekommenderas",
      RECOMMENDATIONS: "Rekommenderas för dig",

      FAVORITES_SLUG: "mina-favoriter",
      FAVORITES: "Mina favoriter",
    },

    dk: {
      ALL_CATEGORIES_SLUG: "alle",
      ALL_CATEGORIES: "Alle",

      YOUR_PICKS_SLUG: "dine-valg",
      YOUR_PICKS: "Dine valg",

      OFFERS_SLUG: "tilbud",
      OFFERS: "Tilbud",

      RECOMMENDATIONS_SLUG: "anbefalinger",
      RECOMMENDATIONS: "Anbefalet til dig",

      FAVORITES_SLUG: "mine-favoritter",
      FAVORITES: "Mine favoritter",
    },
  } as const
)[language];
