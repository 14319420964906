import * as React from "react";
import { SVGProps } from "react";
const Points = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_32916_371205)">
      <path
        d="M0.834961 13.4604C1.99753 12.2871 4.37639 10.2859 6.19506 10.1407C7.86026 10.0073 8.81948 10.4115 9.00748 10.5253C9.50628 10.8353 11.8966 10.7175 12.8674 10.7136C13.5695 10.7097 14.2525 11.1335 14.1566 12.0242C14.053 12.9934 12.687 12.8993 12.1384 12.9189C11.1139 12.9581 10.2813 12.8953 9.25688 12.9346"
        stroke="#231F20"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M14.2479 11.4615C15.0959 10.8611 16.669 9.45241 17.2253 8.94622C18.3649 7.90637 19.6004 9.41317 19.0171 10.0881C17.6205 11.7126 14.7697 15.0088 13.9371 15.3109C13.1045 15.6131 8.17033 15.4993 6.52048 15.4993C5.77613 15.4993 3.5776 17.5201 2.46875 18.3285"
        stroke="#231F20"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M10.3578 10.5414C9.96647 10.043 9.73242 9.41126 9.73242 8.72064C9.73242 7.11573 11.0024 5.81689 12.5717 5.81689C14.141 5.81689 15.411 7.11573 15.411 8.72064C15.411 9.70556 14.9314 10.5728 14.2024 11.0986"
        stroke="#231F20"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M9.01816 5.02787C9.92511 5.02787 10.6603 4.27595 10.6603 3.34841C10.6603 2.42087 9.92511 1.66895 9.01816 1.66895C8.11121 1.66895 7.37598 2.42087 7.37598 3.34841C7.37598 4.27595 8.11121 5.02787 9.01816 5.02787Z"
        stroke="#231F20"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </g>
    <defs>
      <clipPath id="clip0_32916_371205">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Points;
