import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";

import { Check } from "@chef/icons/large";

interface Option<T> {
  value: T;
  label: React.ReactNode;
  disabled?: boolean;
}

interface RadioButtonGridProps<T> {
  options: Option<T>[];
  value: T;
  onChange: (value: T) => void;
  name?: string;
}

export const RadioButtonGrid = <T,>({
  options = [],
  value,
  onChange = () => null,
  name = "",
}: RadioButtonGridProps<T>) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className={clsx("grid grid-cols-2 gap-2 md:gap-4")}>
        {options.map((option, index) => {
          return (
            <RadioGroup.Option
              as="button"
              key={index}
              value={option.value}
              disabled={option.disabled}
              data-testid={option.label}
              className="a11y-focus:focus-ring"
            >
              {({ checked, disabled }) => (
                <div id={`${name}-${option.value}`}>
                  <RadioGroup.Description
                    as="div"
                    className={clsx(
                      "flex items-center justify-center rounded-sm h-14 md:h-18",
                      checked && "bg-secondary text-white",
                      disabled && "bg-grey-3 text-grey-1 line-through",
                      !checked &&
                        !disabled &&
                        "border-1.5 bg-white border-grey-2",
                    )}
                  >
                    {checked && <Check className="mr-2 text-xs" />}{" "}
                    {option.label}
                  </RadioGroup.Description>
                </div>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
};
