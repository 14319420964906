import Cookies from "js-cookie";

import {
  showNotification,
  useGetSanityStatusQuery,
} from "@chef/state-management";
import { useEffectOnce } from "@chef/hooks";

import DebugBar from "./DebugBar";

interface DebugProviderProps {
  children: React.ReactNode;
}

const DebugProvider = ({ children }: DebugProviderProps) => {
  const { data: sanityStatus } = useGetSanityStatusQuery();

  const isDebugging = process.env.APP_ENV !== "production";
  const hasDisabledPreviewNotification =
    Cookies.get("hide-preview-notification") === "true";

  const shouldShowPreviewNotification = !!(
    sanityStatus?.isPreview && !hasDisabledPreviewNotification
  );

  useEffectOnce(() => {
    return; // TODO: Remove this when we have a better solution for preview notifications.

    showNotification({
      type: "warn",
      title: "Draft mode enabled.",
      links: [
        {
          label: "Exit draft mode",
          href: `/api/sanity/exit-preview?redirect=${window.location.pathname}`,
          external: true,
        },
        {
          label: "Don't show this warning again",
          href: `/api/sanity/disable-preview-notification?redirect=${window.location.pathname}`,
          external: true,
        },
      ],
      autoClose: false,
    });
  }, shouldShowPreviewNotification);

  if (isDebugging) {
    return (
      <>
        <DebugBar />
        {children}
      </>
    );
  }

  return children;
};

export default DebugProvider;
