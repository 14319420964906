import clsx from "clsx";

import { Easter, SantaHat, NewYear, Dinner } from "@chef/icons/large";

interface PromotionIconProps {
  campaignIcon: string;
  className?: string;
}

export const PromotionIcon = ({
  campaignIcon,
  className,
}: PromotionIconProps) => {
  const iconMap: Record<string, JSX.Element> = {
    Easter: <Easter className={clsx("w-full h-full", className)} />,
    SantaHat: (
      <SantaHat className={clsx("w-full h-full stroke-black", className)} />
    ),
    Dinner: <Dinner className={clsx("w-full h-full", className)} />,
    NewYear: <NewYear className={clsx("w-full h-full", className)} />,
  };

  return (
    iconMap[campaignIcon] || (
      <Dinner className={clsx("w-full h-full", className)} />
    )
  );
};
