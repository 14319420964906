import * as React from "react";
import { SVGProps } from "react";
const Visa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.999999C0 0.447714 0.447715 0 1 0H39C39.5523 0 40 0.447715 40 1V39C40 39.5523 39.5523 40 39 40H0.999999C0.447714 40 0 39.5523 0 39V0.999999Z"
      fill="#F4F4F1"
    />
    <path
      d="M16.5113 15.1776L12.8872 24.9162H10.5214L8.73736 17.1435C8.62871 16.6659 8.53408 16.4922 8.20812 16.2908C7.66836 15.9593 6.7816 15.6513 6 15.4619L6.05257 15.1776H9.85895C10.3426 15.1776 10.7807 15.5408 10.8894 16.1685L11.8322 21.8055L14.1595 15.1776H16.5113V15.1776ZM25.7749 21.7345C25.7854 19.1646 22.6205 19.0225 22.6415 17.8738C22.6485 17.5264 22.9429 17.1514 23.5913 17.0567C23.9103 17.0093 24.797 16.9738 25.7959 17.4909L26.1885 15.4263C25.6487 15.2092 24.9548 15 24.096 15C21.8844 15 20.3282 16.3224 20.3177 18.2172C20.3037 19.6186 21.4288 20.4002 22.2735 20.866C23.1462 21.3437 23.4371 21.6476 23.4336 22.0779C23.4266 22.7332 22.7396 23.0214 22.0947 23.0332C20.9696 23.053 20.3177 22.6898 19.7955 22.4174L19.3889 24.553C19.9111 24.8215 20.875 25.0583 21.8739 25.0702C24.2292 25.0662 25.7644 23.7596 25.7749 21.7345ZM31.6107 24.9162H33.6786L31.8735 15.1776H29.9668C29.5357 15.1776 29.1747 15.4579 29.0135 15.8921L25.6557 24.9162H28.0041L28.4702 23.4635H31.3408L31.6107 24.9162ZM29.1151 21.4661L30.2928 17.8106L30.9692 21.4661H29.1151ZM19.7078 15.1776L17.8572 24.9162H15.6211L17.4717 15.1776H19.7078V15.1776Z"
      fill="#29297F"
    />
  </svg>
);
export default Visa;
