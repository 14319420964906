import { createClient } from "@sanity/client";
import { createApi } from "@reduxjs/toolkit/query/react";

import { config } from "@chef/constants";
import { isNode } from "@chef/utils/runtime";

import {
  IGetSanityDataArgs,
  IGetSanityDataResponse,
  IGetSanityStatusResponse,
} from "./types";
import { query } from "../../graphql/baseApi";

const client = (args: { preview?: boolean }) =>
  createClient({
    projectId: config.sanity.projectId,
    dataset: config.sanity.dataset,
    useCdn: !args.preview,
    apiVersion: "2021-08-29",
    ...(args.preview && { token: config.sanity.token, withCredentials: true }),
  });

export const sanityDataApi = createApi({
  reducerPath: "sanityData",
  baseQuery: async (args, { dispatch }) => {
    let error: string | undefined;
    let isPreview: boolean = args.preview || false;

    if (!isNode()) {
      const { data } = await dispatch(
        sanityApi.endpoints.getSanityStatus.initiate(),
      );

      isPreview = data?.isPreview || false;
    }

    const res = await client({ preview: isPreview })
      .fetch(args.query, args.params)
      .catch((err) => {
        error = err.message;
      });

    if (error) {
      return { error };
    }

    return {
      data: res,
    };
  },
  endpoints: (builder) => ({
    getSanityData: builder.query<IGetSanityDataResponse, IGetSanityDataArgs>({
      query: (args) => ({
        query: args.query,
        params: args.params,
        preview: args.preview,
      }),
    }),
  }),
});

export const sanityApi = createApi({
  reducerPath: "sanity",
  baseQuery: query,
  endpoints: (builder) => ({
    getSanityStatus: builder.query<IGetSanityStatusResponse, void>({
      query: () => ({
        url: "/sanity/status",
      }),
    }),
  }),
});

export const { useGetSanityDataQuery, useLazyGetSanityDataQuery } =
  sanityDataApi;
export const { useGetSanityStatusQuery } = sanityApi;
