import { Dispatch, useEffect, useState } from "react";

export function usePersistentState<T>(
  key: string,
): [T | undefined, Dispatch<T>];

export function usePersistentState<T>(
  key: string,
  initialValue: T,
): [T, Dispatch<T>];

export function usePersistentState<T>(
  key: string,
  initialValue?: T,
): [T | undefined, Dispatch<T>] {
  const [value, setValue] = useState<T | undefined>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    const item = window.localStorage.getItem(key);

    return item ? (JSON.parse(item) as T) : initialValue;
  });

  useEffect(() => {
    if (value === undefined) {
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
