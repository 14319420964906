import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";

import { Button, Input } from "@chef/components";
import { ChevronUp, ChevronDown } from "@chef/icons/small";
import { BRAND_PATHS } from "@chef/constants";

import {
  setSignupSignicatSessionId,
  useGetSignicatUserDataQuery,
} from "@chef/state-management";
import { useEffectOnce, useExtendedForm } from "@chef/hooks";

import { intl } from "./InvoicePaymentMethod.Intl";

export const enum LocationTypes {
  REGISTRATION = "registration",
  MY_ACCOUNT = "my-account",
}

interface IInvoicePaymentMethod {
  className?: string;
  onSelect: () => void;
  onSubmit: (ssn: string) => void;
  active: boolean;
  isLoading?: boolean;
  isError?: boolean;
  location: LocationTypes;
  ssn?: string;
}

export type IFormInput = yup.InferType<typeof schema>;

const schema = yup.object({
  ssn: !intl.SHOW_SSN_INPUT_FIELD
    ? yup.string().required()
    : yup
        .string()
        .length(
          intl.LENGTH_OF_PERSONAL_NUMBER,
          intl.MUST_BE_EXACTLY_DIGITS(intl.LENGTH_OF_PERSONAL_NUMBER),
        )
        .matches(/^[0-9]+$/, intl.CAN_ONLY_CONTAIN_NUMBERS)
        .required(intl.MANDATORY_FIELD)
        .test({
          test: (value) => intl.IS_VALID_SSN(value),
          message: intl.PERSONAL_NUMBER_NOT_VALID,
        }),
});

export const InvoicePaymentMethod = ({
  className,
  onSelect,
  onSubmit,
  active,
  location,
  isLoading,
  ssn,
}: IInvoicePaymentMethod) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { register, handleSubmit, setValue } = useExtendedForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ssn,
    },
  });

  const { scs_id, scs_status } = router.query;

  const { data: userData } = useGetSignicatUserDataQuery(scs_id as string, {
    skip: scs_status !== "success",
  });

  const nin = userData?.["data"].subject?.nin.value;
  const firstName = userData?.["data"].subject?.firstName;
  const lastName = userData?.["data"].subject?.lastName;

  const isLocationMyAccount = location === LocationTypes.MY_ACCOUNT;

  useEffectOnce(onSelect, !!scs_id);

  useEffect(() => {
    if (!nin) {
      return;
    }

    setValue("ssn", nin, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [nin, setValue]);

  useEffect(() => {
    if (scs_id && !isLocationMyAccount) {
      dispatch(setSignupSignicatSessionId(scs_id as string));
    }
  }, [dispatch, scs_id, isLocationMyAccount]);

  return (
    <div className={className}>
      <button
        className="flex items-center justify-between w-full text-sm"
        onClick={onSelect}
      >
        <div className="flex flex-col text-start">
          <span>
            <strong>{intl.BUTTON_HEADER}</strong>
          </span>

          <span className="text-grey-1">{intl.CREDIT_CHECK_USP}</span>
        </div>
        <div className="flex gap-2">
          <span className="underline text-primary">
            <strong>{intl.BUTTON_ACTION_TEXT}</strong>
          </span>

          {active ? <ChevronUp /> : <ChevronDown />}
        </div>
      </button>

      <div className={clsx("mt-2", !active && "hidden")}>
        <div className="text-xs">
          <p>
            {intl.TERMS_DETAILS_FIRST_PART}{" "}
            <a
              className="underline"
              href={intl.TERMS_LINK_URL}
              target="_blank"
              rel="noreferrer"
            >
              <strong>{intl.TERMS_LINK_TEXT}</strong>
            </a>{" "}
            {intl.TERMS_DETAIL_SECOND_PART}
          </p>

          <div className="mt-3">
            <span>
              <strong>{intl.CREDIT_CHECK_HEADER}</strong>
            </span>

            <p>{intl.CREDIT_CHECK_DETAIL}</p>
          </div>
        </div>
        <form onSubmit={handleSubmit((y) => onSubmit(y.ssn))}>
          <div className="flex flex-col gap-2 mt-6 lg:flex-row">
            <Input
              {...register("ssn")}
              label={intl.PERSONAL_NUMBER}
              disabled={true}
              className="grow"
              helperText={
                scs_status === "success"
                  ? `${intl.AUTHENTICATED_AS} ${lastName}, ${firstName}`
                  : undefined
              }
            />

            <Button
              href={`/api/auth/signicat/sessions?location=${location}`}
              primary
            >
              {intl.AUTHENTICATE_WITH_BANKID?.(ssn)}
            </Button>
          </div>

          {scs_status === "abort" && (
            <div className="text-sm text-error">
              {intl.AUTHENTICATION_ABORTED}
            </div>
          )}

          {scs_status === "error" && (
            <div className="text-sm text-error">
              {intl.AUTHENTICATION_ERROR}
            </div>
          )}

          <div className={clsx(isLocationMyAccount && "text-right")}>
            {isLocationMyAccount && (
              <Link href={BRAND_PATHS.PAYMENT_LINK} passHref legacyBehavior>
                <Button
                  outlined
                  type="submit"
                  primary
                  full
                  className="mt-6 mr-2 lg:w-32"
                  loading={isLoading}
                >
                  {intl.CANCEL_BUTTON}
                </Button>
              </Link>
            )}

            <Button
              type="submit"
              primary
              full
              className={clsx(isLocationMyAccount && "lg:w-48", "mt-6")}
              loading={isLoading}
              disabled={scs_status !== "success"}
            >
              {isLocationMyAccount
                ? intl.MY_ACCOUNT_SAVE_CAHNGE_BUTTON
                : intl.REGISTRATION_PAYMENT_SAVE_BUTTON}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
