import * as React from "react";
import { SVGProps } from "react";
const Truck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15591_225026)">
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#FFF6E8"
      />
      <path
        d="M123.068 104.306H29.0371"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.8123 87H89.3936"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3936 56.3813H107.6L114.35 68.6251H120.312C121.531 68.6251 123.069 68.9251 123.069 70.7626V87.0001H111.594"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.1123 44.1377H89.3936V87.0002H56.4873"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.7059 87.0002H34.2871V44.1377H86.1121"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6064 102.3C54.6806 102.3 58.7939 98.1864 58.7939 93.1123C58.7939 88.0382 54.6806 83.9248 49.6064 83.9248C44.5323 83.9248 40.4189 88.0382 40.4189 93.1123C40.4189 98.1864 44.5323 102.3 49.6064 102.3Z"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.693 102.3C109.767 102.3 113.881 98.1864 113.881 93.1123C113.881 88.0382 109.767 83.9248 104.693 83.9248C99.6192 83.9248 95.5059 88.0382 95.5059 93.1123C95.5059 98.1864 99.6192 102.3 104.693 102.3Z"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15591_225026">
        <rect
          width={150}
          height={150}
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Truck;
