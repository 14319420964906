import clsx from "clsx";

import { Language } from "../../types";
import { Button } from "../components";

interface BasketOverviewProps {
  language?: Language;
  leftClassName?: string;
  rightClassName?: string;
  mealCardsClassName?: string;
  mealCards: React.ReactNode[];
  className?: string;
  ShoppingCart: JSX.Element;
  onClose?: () => void;
}

const BasketOverview = ({
  language = "no",
  leftClassName = "",
  rightClassName = "",
  mealCardsClassName = "",

  mealCards = [],
  ShoppingCart,
  className,

  onClose,

  ...props
}: BasketOverviewProps) => {
  const { RECEIPT, YOUR_ORDER, CLOSE } = intl[language];

  return (
    <div className={clsx("bg-background", className)} {...props}>
      <div
        className={clsx(
          "px-6 pt-8 pb-4 w-full order-last lg:order-first hidden lg:block",
          leftClassName,
        )}
      >
        <h3>
          <strong>{YOUR_ORDER}</strong>
        </h3>

        <div
          className={clsx("grid gap-4 lg:grid-cols-4 pt-6", mealCardsClassName)}
        >
          {mealCards}
        </div>
      </div>

      <div
        className={clsx(
          "pt-8 p-6 order-first lg:order-last md:bg-grey-3 bg-grey-white",
          rightClassName,
        )}
      >
        <h3>
          <strong className="hidden lg:block">{RECEIPT}</strong>
          <strong className="block lg:hidden">{YOUR_ORDER}</strong>
        </h3>

        {ShoppingCart}

        <Button outlined full className="mt-6" onClick={onClose}>
          {CLOSE}
        </Button>
      </div>
    </div>
  );
};

const intl = {
  no: {
    RECEIPT: "Handlekurv",
    YOUR_ORDER: "Din bestilling",
    PROCEED_TO_CHECKOUT: "Fortsett til utsjekking",
    CLOSE: "Lukk",
  },

  se: {
    RECEIPT: "Varukorg",
    YOUR_ORDER: "Din beställning",
    PROCEED_TO_CHECKOUT: "Fortsätt till kassan",
    CLOSE: "Stäng",
  },

  dk: {
    RECEIPT: "Din indkøbskurv",
    YOUR_ORDER: "Din bestilling",
    PROCEED_TO_CHECKOUT: "Fortsæt til kassen",
    CLOSE: "Luk",
  },
};

export default BasketOverview;
