import { CalendarQuery } from "@chef/state-management";
import { Summary } from "../shared/Summary";
import { useFinancesForCalendarWeek } from "./useFinancesForCalendarWeek";

interface CalendarWeekSummaryProps {
  calendarWeek: CalendarQuery["calendar"][number];
}

export const CalendarWeekSummary = ({
  calendarWeek,
}: CalendarWeekSummaryProps) => {
  const finances = useFinancesForCalendarWeek({ calendarWeek });

  return <Summary finalSum={finances.total} />;
};
