import * as React from "react";
import { SVGProps } from "react";
const Easter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.88867 11.6048C2.55978 7.33985 4.94643 2.84436 8.5553 2.84436C12.1642 2.84436 14.5508 7.33985 15.2219 11.6048"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.111 15.1617C15.5288 20.2197 12.0355 23 8.55551 23C5.07553 23 1.58222 20.2244 1 15.1617"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.111 15.1618L13.8888 13.7786L11.2222 15.6229L8.55551 13.7786L5.88886 15.6229L3.22221 13.7786L1 15.1618"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7686 6.95715L16.9996 7.91619L14.9108 10.0832"
      fill="currentColor"
    />
    <path
      d="M13.7686 6.95715L16.9996 7.91619L14.9108 10.0832"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.22333 1C6.69 1.63167 6.41 2.76131 6.73888 3.25005"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.737 3.25002C6.54589 2.99182 5.67034 1.92212 4.11035 1.92212"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7766 7.91633C11.2675 7.91633 11.6655 7.50346 11.6655 6.99417C11.6655 6.48488 11.2675 6.07202 10.7766 6.07202C10.2857 6.07202 9.8877 6.48488 9.8877 6.99417C9.8877 7.50346 10.2857 7.91633 10.7766 7.91633Z"
      fill="currentColor"
    />
  </svg>
);
export default Easter;
