interface SalesArgumentProps {
  image: JSX.Element;
  title: string;
  description: string;
}

const SalesArgument = ({ image, title, description }: SalesArgumentProps) => {
  return (
    <div className={"flex self-start basis-1/2"}>
      <div className="mr-2 min-w-max lg:mr-4">{image}</div>
      <div className="flex flex-col justify-center">
        <strong>{title}</strong>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
};

export default SalesArgument;
