import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DOOR_LABEL: "Velg leilighet",
    },

    se: {
      DOOR_LABEL: "Lägenhetsnummer",
    },

    dk: {
      DOOR_LABEL: "Lejlighed",
    },
  } as const
)[language];
