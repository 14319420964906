import * as React from "react";
import { SVGProps } from "react";
const EyeClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 6C1.73228 7.17164 2.52904 8.17154 3.37117 9M19 6C18.2678 7.17157 17.4712 8.17157 16.6291 9M16.6291 9L19 11.6014M16.6291 9C15.3372 10.271 13.9381 11.1382 12.5 11.6014M3.37117 9L1 11.6014M3.37117 9C4.66318 10.271 6.06227 11.1382 7.50039 11.6014M12.5 11.6014L13.5 15M12.5 11.6014C10.8506 12.1328 9.14981 12.1328 7.50039 11.6014M7.50039 11.6014L6.5 15"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EyeClosed;
