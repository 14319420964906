import * as React from "react";
import { SVGProps } from "react";
const Cart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.25 20C19.25 20.4142 18.9142 20.75 18.5 20.75C18.0858 20.75 17.75 20.4142 17.75 20C17.75 19.5858 18.0858 19.25 18.5 19.25C18.9142 19.25 19.25 19.5858 19.25 20Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 20C12.25 20.4142 11.9142 20.75 11.5 20.75C11.0858 20.75 10.75 20.4142 10.75 20C10.75 19.5858 11.0858 19.25 11.5 19.25C11.9142 19.25 12.25 19.5858 12.25 20Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0117 14.6704H19.4656"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4801 14.672L21.0005 10.3367H9.55957"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7484 14.6703L8.74625 9H7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0117 17.0229H19.5C21 17.0229 21.5323 18.0001 21.5323 18.0001"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.01 17.0221C9.30252 17.0221 8.73047 16.4991 8.73047 15.8522C8.73047 15.2054 9.30252 14.6824 10.01 14.6824"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Cart;
