import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      OPEN: "Åpne",
      CLOSE: "Lukk",
      THANK_YOU_FOR_RATING: "Takk for vurderingen!",
      GIVE_US_YOUR_RATING: "Vurder middagen",
      SAVE: "Lagre",
      SHOW_COMMENT: "Vis kommentar",
      HIDE_COMMENT: "Skjul kommentar",
      ADD_A_COMMENT: "Legg til en kommentar",
      CHANGE_COMMENT: "Endre kommentar",
      HAVE_NOT_MADE_THE_DISH: "Har ikke laget",
    },
    se: {
      OPEN: "Öppna",
      CLOSE: "Stäng",
      THANK_YOU_FOR_RATING: "Tack för ditt betyg!",
      GIVE_US_YOUR_RATING: "Betygsätt middagen",
      SAVE: "Spara",
      SHOW_COMMENT: "Visa kommentar",
      HIDE_COMMENT: "Göm kommentar",
      ADD_A_COMMENT: "Lägg till en kommentar",
      CHANGE_COMMENT: "Ändra kommentar",
      HAVE_NOT_MADE_THE_DISH: "Har inte lagat rätten",
    },
    dk: {
      OPEN: "Åbne",
      CLOSE: "Luk",
      THANK_YOU_FOR_RATING: "Tak for din vurdering!",
      GIVE_US_YOUR_RATING: "Giv os din vurdering!",
      SAVE: "Gem",
      SHOW_COMMENT: "Vis kommentar",
      HIDE_COMMENT: "Skjul kommentar",
      ADD_A_COMMENT: "Tilføj en kommentar",
      CHANGE_COMMENT: "Rediger kommentar",
      HAVE_NOT_MADE_THE_DISH: "Har ikke lavet retten",
    },
  } as const
)[language];
