import React from "react";
import clsx from "clsx";

interface AvatarProps {
  className?: string;
  text?: string;
  id?: string;
}

export const Avatar = ({ text = "", className, id, ...props }: AvatarProps) => {
  return (
    <div
      className={clsx(
        "rounded-full bg-grey-2 h-8 w-8 justify-center items-center flex",
        className,
      )}
      title={text}
      aria-label={text}
      id={id}
      {...props}
    >
      {(text[0] || "").toUpperCase()}
    </div>
  );
};
