import {
  PRODUCT_IDS,
  PRODUCT_TYPE_IDS,
  dishCount,
  productsDishCount,
} from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

export const convertFromPortionsToPlusPortions = (portions: number) => {
  return +`${portions}1`;
};

interface IIsSomethingProduct {
  productTypeId: string;
  productId?: string;
}
export const isMealboxProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.MEALBOX);
};

export const isFinancialProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.FINANCIAL);
};

export const isOneSubProduct = (product: IIsSomethingProduct | undefined) => {
  if (!product || !product.productId) {
    return false;
  }
  return isEqualStrings(product.productId, PRODUCT_IDS.ONESUB);
};

export const isPickAndMixProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.PICKANDMIX);
};

export const isAddonProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.ADDON);
};

export const isStandaloneProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.STANDALONE);
};

export const isLimitedQuantityProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(
    product.productTypeId,
    PRODUCT_TYPE_IDS.LIMITED_QUANTITY,
  );
};

export const isAdministrativeProduct = (product: IIsSomethingProduct) => {
  return isEqualStrings(product.productTypeId, PRODUCT_TYPE_IDS.ADMINISTRATIVE);
};

export const getDishCount = ({ productId }: { productId?: string } = {}) => {
  if (productId && productId in productsDishCount) {
    return productsDishCount[productId as keyof typeof productsDishCount];
  } else {
    return dishCount;
  }
};
