import * as React from "react";
import { SVGProps } from "react";
const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill="currentColor"
    />
    <rect x={8} y={4} width={2} height={2} rx={1} fill="white" />
    <rect x={8} y={8} width={2} height={6} rx={1} fill="white" />
  </svg>
);
export default Info;
