import * as React from "react";
import { SVGProps } from "react";
const Klarna = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M52.957 0H7.04304C3.15328 0 0 3.11824 0 6.96479V17.0352C0 20.8818 3.15328 24 7.04304 24H52.957C56.8467 24 60 20.8818 60 17.0352V6.96479C60 3.11824 56.8467 0 52.957 0Z"
      fill="#FFB3C7"
    />
    <path
      d="M48.0454 10.634C47.4869 10.2534 46.8352 10.0323 46.1604 9.99458C45.4856 9.95681 44.8132 10.1038 44.2157 10.4197C43.6182 10.7356 43.1182 11.2084 42.7694 11.7873C42.4206 12.3662 42.2363 13.0293 42.2363 13.7052C42.2363 14.381 42.4206 15.0441 42.7694 15.623C43.1182 16.2019 43.6182 16.6748 44.2157 16.9907C44.8132 17.3066 45.4856 17.4535 46.1604 17.4158C46.8352 17.378 47.4869 17.1569 48.0454 16.7764V17.2264H50.1541V10.1835H48.0454V10.634ZM46.1276 15.5173C45.7733 15.4984 45.4323 15.3761 45.1468 15.1655C44.8613 14.9549 44.6438 14.6652 44.5212 14.3323C44.3986 13.9994 44.3762 13.6378 44.457 13.2923C44.5377 12.9469 44.7179 12.6326 44.9754 12.3885C45.2328 12.1444 45.5562 11.9811 45.9055 11.9188C46.2548 11.8566 46.6146 11.898 46.9406 12.0382C47.2665 12.1783 47.5443 12.4109 47.7394 12.7072C47.9345 13.0035 48.0385 13.3505 48.0385 13.7053C48.025 14.1988 47.8162 14.6669 47.4579 15.0066C47.0997 15.3463 46.6212 15.53 46.1276 15.5173Z"
      fill="black"
    />
    <path d="M19.1287 7.04492H16.9219V17.2266H19.1287V7.04492Z" fill="black" />
    <path
      d="M38.4927 9.99421C38.0781 9.96971 37.6638 10.0464 37.2854 10.2176C36.907 10.3889 36.5759 10.6495 36.3207 10.9772V10.184H34.2207V17.2267H36.346V13.5255C36.3296 13.3129 36.3593 13.0993 36.4329 12.8991C36.5065 12.699 36.6223 12.5171 36.7725 12.3657C36.9227 12.2143 37.1037 12.0971 37.3033 12.0219C37.5028 11.9467 37.7162 11.9154 37.9289 11.93C38.8558 11.93 39.3884 12.4837 39.3884 13.5109V17.2267H41.4949V12.7479C41.4948 11.1088 40.1916 9.99421 38.4927 9.99421Z"
      fill="black"
    />
    <path
      d="M25.8003 10.634C25.2418 10.2534 24.5901 10.0323 23.9153 9.99458C23.2404 9.95681 22.5681 10.1038 21.9706 10.4197C21.3731 10.7356 20.8731 11.2084 20.5243 11.7873C20.1755 12.3662 19.9912 13.0293 19.9912 13.7052C19.9912 14.381 20.1755 15.0441 20.5243 15.623C20.8731 16.2019 21.3731 16.6748 21.9706 16.9907C22.5681 17.3066 23.2404 17.4535 23.9153 17.4158C24.5901 17.378 25.2418 17.1569 25.8003 16.7764V17.2264H27.909V10.1835H25.8003V10.634ZM23.8824 15.5173C23.5281 15.4985 23.1871 15.3762 22.9016 15.1656C22.616 14.9551 22.3985 14.6654 22.2758 14.3325C22.1532 13.9995 22.1308 13.638 22.2115 13.2925C22.2922 12.947 22.4725 12.6327 22.7299 12.3886C22.9873 12.1444 23.3107 11.9811 23.66 11.9188C24.0093 11.8565 24.3692 11.898 24.6951 12.0381C25.0211 12.1782 25.2988 12.4108 25.494 12.7071C25.6891 13.0034 25.7931 13.3505 25.7931 13.7053C25.7796 14.1988 25.5709 14.6668 25.2126 15.0066C24.8544 15.3463 24.3759 15.53 23.8824 15.5173H23.8824Z"
      fill="black"
    />
    <path
      d="M31.1707 11.101V10.1836H29.0117V17.2266H31.1753V13.9386C31.1753 12.8292 32.3778 12.2328 33.2123 12.2328C33.2207 12.2328 33.2288 12.2337 33.2373 12.2337V10.1841C32.8463 10.1736 32.4579 10.2504 32.1004 10.409C31.7429 10.5677 31.4253 10.8041 31.1707 11.101Z"
      fill="black"
    />
    <path
      d="M52.3938 14.7285C52.132 14.7285 51.8761 14.8062 51.6585 14.9516C51.4408 15.097 51.2712 15.3038 51.171 15.5456C51.0709 15.7874 51.0447 16.0536 51.0958 16.3103C51.1468 16.567 51.2729 16.8029 51.458 16.988C51.6431 17.1731 51.8789 17.2991 52.1357 17.3502C52.3924 17.4012 52.6585 17.375 52.9004 17.2748C53.1422 17.1747 53.3489 17.005 53.4943 16.7874C53.6398 16.5697 53.7174 16.3138 53.7174 16.0521C53.7174 15.8782 53.6832 15.7061 53.6167 15.5455C53.5502 15.3849 53.4527 15.239 53.3297 15.1161C53.2068 14.9932 53.0609 14.8957 52.9003 14.8292C52.7397 14.7627 52.5676 14.7285 52.3938 14.7285Z"
      fill="black"
    />
    <path
      d="M15.4005 7.04297H13.1135C13.117 7.96013 12.9048 8.86527 12.4941 9.6853C12.0833 10.5053 11.4854 11.2173 10.7488 11.7636L9.84277 12.4422L13.3536 17.2298H16.2399L13.0097 12.8246C13.7715 12.0674 14.3752 11.1664 14.7856 10.1738C15.1961 9.18127 15.4051 8.11703 15.4005 7.04297Z"
      fill="black"
    />
    <path d="M9.56356 7.04297H7.27246V17.2293H9.56356V7.04297Z" fill="black" />
  </svg>
);
export default Klarna;
