import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { createAndSendEvent } from "@chef/events";
import { PRODUCT_CATEGORY_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { invariant } from "@chef/utils/invariant";
import { withErrorBoundary } from "@chef/utils/withErrorBoundary";

import { api } from "../graphql/api";
import { RootState } from "..";
import { toggleCartModal } from "../features/modal";
import { createPayloadProductsArray } from "./util";
import { getStandaloneProductCategoryIdsFromProductCategories } from "../helpers";

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: toggleCartModal,
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as RootState;

      const cartIsOpen = state.modal.cart.open;

      invariant(
        action.payload.week && action.payload.year,
        "Missing week or year",
      );

      const deviation = state.basket.deviations.find(
        (d) => d.week === action.payload.week && d.year === action.payload.year,
      );

      invariant(deviation, "Could not find deviation");

      if (cartIsOpen) {
        const categoriesData = await listenerApi
          .dispatch(api.endpoints.productCategories.initiate())
          .unwrap();

        const productsData = await listenerApi
          .dispatch(
            api.endpoints.productsByCategories.initiate({
              categoryIds: [
                ...getStandaloneProductCategoryIdsFromProductCategories(
                  categoriesData.productCategories,
                ),
                PRODUCT_CATEGORY_IDS.FINANCIAL,
                PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT,
              ],
              week: action.payload.week,
              year: action.payload.year,
            }),
          )
          .unwrap();

        const pickAndMixData = await listenerApi
          .dispatch(
            api.endpoints.pickAndMix.initiate({
              productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
              week: action.payload.week,
              year: action.payload.year,
            }),
          )
          .unwrap();

        createAndSendEvent("Cart Viewed", {
          affiliation: "Frontend process",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          products: withErrorBoundary(() =>
            createPayloadProductsArray(
              deviation.products,
              pickAndMixData.pickAndMix,
              productsData.productsByCategories,
            ),
          )!,
        });
      }
    },
  });
