import * as React from "react";
import { SVGProps } from "react";
const Card = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.75}
      y={3.75}
      width={18.5}
      height={12.5}
      rx={1.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <rect
      x={0.375}
      y={12.375}
      width={19.25}
      height={0.75}
      stroke="currentColor"
      strokeWidth={0.75}
    />
    <rect x={4.5} y={7.5} width={1} height={1} rx={0.5} stroke="currentColor" />
    <rect
      x={7.83301}
      y={7.50098}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={11.167}
      y={7.50098}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={14.5}
      y={7.50098}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
  </svg>
);
export default Card;
