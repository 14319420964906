import clsx from "clsx";

import { Tag } from "@chef/components";
import { generateTags } from "@chef/utils/helpers/recipes";
import { TAXONOMY_IDS } from "@chef/constants";
import {
  RecipeAndStepsQuery,
  useRecipeAndStepsQuery,
} from "@chef/state-management";

interface CampaignTagProps {
  recipeId: RecipeAndStepsQuery["recipeAndSteps"]["recipeId"];
}

export const CampaignTag = ({ recipeId }: CampaignTagProps) => {
  const { data: { recipeAndSteps } = {} } = useRecipeAndStepsQuery(
    { recipeId },
    { skip: !recipeId },
  );

  const recipe = recipeAndSteps;

  const { campaign } = generateTags(recipe);

  if (!campaign?.text) {
    return null;
  }

  const isWeightWatchers =
    TAXONOMY_IDS.WEIGHT_WATCHERS &&
    campaign.id === TAXONOMY_IDS.WEIGHT_WATCHERS;

  return (
    <Tag white className="normal-case">
      <div
        className={clsx(
          "mr-2 circle",
          isWeightWatchers ? "bg-[#06167B]" : "bg-highlight",
        )}
      />
      <p>{campaign?.text}</p>
    </Tag>
  );
};
