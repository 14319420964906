import Link from "next/link";

import { BRAND_PATHS } from "@chef/constants";
import { BillingQuery } from "@chef/state-management";
import { useDiscountData } from "@chef/state-management/hooks";
import { formatDate } from "@chef/helpers";
import { Card } from "@chef/components";
import { intl } from "./DiscountInfo.Intl";

const DISCOUNT_TYPES = {
  PERCENTAGE: "%",
  FIXED: "kr",
};

interface IDiscountArgs extends React.HTMLAttributes<HTMLDivElement> {
  discount: BillingQuery["billing"]["discounts"][0];
}

export const Discount = ({ discount, ...props }: IDiscountArgs) => {
  const discountAmount =
    discount?.type === DISCOUNT_TYPES.PERCENTAGE
      ? `${discount?.amount}%`
      : `${discount?.amount} kr`;
  const formatedDate = formatDate(
    new Date(discount?.createdAt || new Date()),
    "d MMMM yyyy",
  );

  return (
    <div className="flex flex-col mt-3 " {...props}>
      <div className="flex justify-between w-full">
        <strong>{discount?.name}</strong>
        <strong>-{discountAmount}</strong>
      </div>
      <p>
        {intl.ADDED} {formatedDate}
      </p>
    </div>
  );
};

interface DiscountInfoProps {
  showAsCard?: boolean;
  showWhyDontISeeDiscountDisclaimer?: boolean;
  showMyDiscountsLink?: boolean;

  type: "temporary" | "permanent" | "info-only";
}

export const DiscountInfo = ({
  showAsCard,
  showWhyDontISeeDiscountDisclaimer,
  showMyDiscountsLink,
  type,
}: DiscountInfoProps) => {
  const {
    discounts: {
      activeDiscounts: { temporaryDiscounts, permanentDiscounts },
    },
  } = useDiscountData();

  const { nextTemporaryDiscount } = temporaryDiscounts;
  const { nextPermanentDiscount } = permanentDiscounts;

  let discount;
  if (type === "temporary") {
    discount = nextTemporaryDiscount;
  } else if (type === "permanent") {
    discount = nextPermanentDiscount;
  }

  return (
    <Card
      noShadow={!showAsCard}
      className="p-4 text-sm text-left bg-grey-3"
      transparent={!showAsCard}
      noPadding={!showAsCard}
    >
      <div>
        {showWhyDontISeeDiscountDisclaimer && (
          <p className="mb-2">
            <strong>{intl.WHY_DONT_I_SEE_DISCOUNT}</strong>
          </p>
        )}

        <p>
          {intl.WHEN_AND_HOW_THE_DISCOUNT_WILL_BE_DEDUCTED(intl.CUT_OFF_DATE)}
        </p>
        <p className="my-2">
          <strong>{intl.DEDUCTED_ON_THE_NEXT_DELIVERY}</strong>
        </p>
      </div>

      {!!discount && (
        <div className="mt-3 border-t border-black">
          <Discount discount={discount} />
        </div>
      )}

      {showMyDiscountsLink && (
        <Link
          className="mt-4 underline text-primary"
          href={BRAND_PATHS.MY_DISCOUNTS_PAGE}
        >
          <strong>{intl.SHOW_MY_DISCOUNTS_AND_ADD_NEW_ONES}</strong>
        </Link>
      )}
    </Card>
  );
};
