import React from "react";
import clsx from "clsx";

import { Container } from "../components";

export const enum HeroTypes {
  FULLSCREEN = "fullScreen",
  MEDIUM = "medium",
}

interface HeroProps {
  centered?: boolean;
  heroType: HeroTypes;
  DesktopImage: () => JSX.Element;
  MobileImage: () => JSX.Element;
  children?: React.ReactNode;
}

const Hero = ({
  centered = false,
  heroType,
  DesktopImage,
  MobileImage,
  children,
}: HeroProps) => {
  const positionStyles = {
    default:
      "md:items-start md:col-start-2 md:col-span-4 md:text-left lg:col-span-5 lg:col-start-2 xl:col-start-2 ",
    centered:
      "md:col-start-3 md:col-span-4 lg:col-span-4 lg:col-start-3 xl:col-start-5",
  };

  let position = positionStyles.default;
  if (centered) {
    position = positionStyles.centered;
  }

  return (
    <Container
      as="section"
      hasPadding={false}
      isFullWidth={heroType === HeroTypes.FULLSCREEN}
      innerClassName="relative h-144"
      grid
    >
      <div className="hidden md:block">
        <DesktopImage />
      </div>
      <div className="block md:hidden">
        <MobileImage />
      </div>
      <div
        className={clsx(
          "absolute h-full flex flex-col px-4 md:px-0 md:mt-0 w-full justify-center items-center text-center",
          position,
          !children && "hidden",
        )}
      >
        {children}
      </div>
    </Container>
  );
};

export default Hero;
