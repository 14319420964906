import * as React from "react";
import { SVGProps } from "react";
const BulbOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 12.5H4M12 3.5V4.5M20 12.5H21M5.6 6.1L6.3 6.8M18.4 6.1L17.7 6.8M9.7002 17.5H14.3002M9 16.5C8.16047 15.8704 7.54033 14.9925 7.22743 13.9908C6.91453 12.9892 6.92473 11.9144 7.25658 10.9189C7.58844 9.9233 8.22512 9.05739 9.07645 8.44379C9.92778 7.83019 10.9506 7.5 12 7.5C13.0494 7.5 14.0722 7.83019 14.9236 8.44379C15.7749 9.05739 16.4116 9.9233 16.7434 10.9189C17.0753 11.9144 17.0855 12.9892 16.7726 13.9908C16.4597 14.9925 15.8395 15.8704 15 16.5C14.6096 16.8865 14.3156 17.3594 14.1419 17.8806C13.9681 18.4018 13.9195 18.9566 14 19.5C14 20.0304 13.7893 20.5391 13.4142 20.9142C13.0391 21.2893 12.5304 21.5 12 21.5C11.4696 21.5 10.9609 21.2893 10.5858 20.9142C10.2107 20.5391 10 20.0304 10 19.5C10.0805 18.9566 10.0319 18.4018 9.85813 17.8806C9.6844 17.3594 9.39043 16.8865 9 16.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BulbOutlined;
