import * as React from "react";
import { SVGProps } from "react";
const Clap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.0473 18.6746C30.2122 17.1275 28.5573 15.3773 27.114 13.4572C26.8512 13.215 26.5305 13.0449 26.1831 12.9632C25.8356 12.8816 25.4731 12.8911 25.1304 12.991C24.7877 13.0909 24.4764 13.2777 24.2267 13.5335C23.9769 13.7892 23.797 14.1052 23.7045 14.451L12.5426 18.751L5.19017 14.7759C4.90144 14.6181 4.58327 14.5221 4.25571 14.4938C3.92815 14.4655 3.59831 14.5056 3.28695 14.6115C2.97559 14.7175 2.68948 14.8869 2.44662 15.1093C2.20377 15.3316 2.00944 15.602 1.87589 15.9035C1.52347 16.5189 1.41987 17.2466 1.58644 17.9364C1.753 18.6262 2.17705 19.2256 2.77112 19.611L3.89493 20.2799C3.37362 20.2024 2.84129 20.2964 2.37771 20.5479C1.91413 20.7994 1.54426 21.1949 1.32351 21.675C0.9825 22.2868 0.885262 23.0056 1.05138 23.6864C1.2175 24.3673 1.63463 24.9597 2.21874 25.3443L4.77112 26.8732C4.48357 26.7149 4.1665 26.6179 3.83982 26.5883C3.51313 26.5587 3.18387 26.5971 2.87269 26.7012C2.56151 26.8052 2.27512 26.9727 2.03147 27.1931C1.78783 27.4134 1.5922 27.6819 1.45684 27.9817C1.13325 28.5929 1.04762 29.303 1.21658 29.974C1.38553 30.645 1.79702 31.2291 2.37112 31.6128C2.37112 31.6128 15.3045 39.6203 19.7616 42.0283C23.2854 43.9395 30.1997 44.8759 34.5616 39.5821C36.1426 37.6137 32.0473 18.6746 32.0473 18.6746Z"
      fill="#8F721A"
    />
    <path
      d="M37.419 18.6172C35.0458 16.9713 33.2892 14.5765 32.4286 11.8136C32.0095 10.7243 30.4286 10.5523 29.5143 12.3105C29.0552 13.1961 28.8715 14.1995 28.987 15.1909C29.1024 16.1824 29.5117 17.1163 30.1619 17.8718L30.5047 18.2923C30.6565 18.8903 30.8474 19.4777 31.0762 20.0505C29.988 19.9181 28.8848 20.0974 27.8939 20.5674C26.9029 21.0375 26.0648 21.7792 25.4762 22.7069C23.8952 24.7901 20.1809 28.4403 18.619 30.4661C17.7325 31.5875 17.3127 33.0095 17.4476 34.4344C17.5825 35.8594 18.2617 37.1767 19.3428 38.1105C19.3428 38.1105 30.5428 46.3283 38.0095 36.1994C43.8381 28.4021 39.7428 20.2034 37.419 18.6172Z"
      fill="#FFCB2E"
    />
    <path
      d="M33.6475 21.6558C34.2075 22.1013 34.5794 22.7424 34.6888 23.4509C34.7983 24.1595 34.6373 24.8834 34.238 25.4781C34.065 25.7719 33.8339 26.0271 33.559 26.2281C33.2841 26.4291 32.9711 26.5715 32.6393 26.6467C32.3075 26.7219 31.9639 26.7282 31.6295 26.6653C31.2952 26.6023 30.9772 26.4715 30.6952 26.2807L12.8856 13.3807C12.318 12.9408 11.9403 12.299 11.8304 11.5878C11.7205 10.8766 11.8869 10.1502 12.2952 9.5585C12.4681 9.26193 12.7 9.00425 12.9764 8.80149C13.2529 8.59873 13.568 8.45524 13.9021 8.37994C14.2363 8.30465 14.5822 8.29917 14.9186 8.36383C15.2549 8.4285 15.5744 8.56194 15.8571 8.75583L33.6475 21.6558Z"
      fill="#FFCB2E"
    />
    <path
      d="M24.4286 20.2227L13.0762 11.9667C12.7205 11.7273 12.4449 11.3859 12.2852 10.9871C12.1255 10.5883 12.0892 10.1505 12.181 9.73071C11.8226 10.3093 11.6942 11.0019 11.8212 11.671C11.9482 12.3401 12.3214 12.9369 12.8667 13.3427L24.2953 21.5796C25.3619 22.4396 25.5714 21.0636 24.4286 20.2227Z"
      fill="#BF9823"
    />
    <path
      d="M27.8183 24.1214C28.3821 24.5642 28.7568 25.2056 28.8665 25.9154C28.9761 26.6253 28.8125 27.3504 28.4088 27.9436C28.2394 28.2411 28.0104 28.5003 27.7364 28.7048C27.4624 28.9092 27.1492 29.0546 26.8164 29.1318C26.4837 29.209 26.1387 29.2163 25.803 29.1532C25.4673 29.0902 25.1483 28.9581 24.8659 28.7654L7.05638 15.789C6.49562 15.3435 6.12461 14.7008 6.0185 13.991C5.9124 13.2813 6.07915 12.5577 6.48496 11.9667C6.65392 11.6686 6.88303 11.4091 7.15763 11.2048C7.43224 11.0006 7.7463 10.8561 8.07974 10.7805C8.41318 10.705 8.75866 10.7001 9.0941 10.7661C9.42954 10.8322 9.74755 10.9677 10.0278 11.1641L27.8183 24.1214Z"
      fill="#FFCB2E"
    />
    <path
      d="M20.9811 24.4271L7.24778 14.4702C6.9081 14.2213 6.64557 13.881 6.49052 13.4887C6.33547 13.0964 6.29424 12.6681 6.37159 12.2533C6.00187 12.8419 5.85974 13.546 5.97204 14.2326C6.08433 14.9192 6.44328 15.5408 6.98111 15.98L20.7906 25.8031C21.9525 26.6249 22.124 25.3444 20.9811 24.4271Z"
      fill="#BF9823"
    />
    <path
      d="M26.714 29.9122C27.2777 30.3549 27.6525 30.9963 27.7621 31.7062C27.8718 32.416 27.7082 33.1412 27.3044 33.7344C27.1355 34.0325 26.9064 34.292 26.6318 34.4963C26.3572 34.7005 26.0431 34.8451 25.7097 34.9206C25.3762 34.9962 25.0307 35.0011 24.6953 34.935C24.3599 34.869 24.0418 34.7334 23.7616 34.5371L5.95205 21.6562C5.38829 21.2134 5.01354 20.572 4.90388 19.8622C4.79422 19.1523 4.95785 18.4272 5.36158 17.834C5.53098 17.5364 5.75989 17.2773 6.03392 17.0728C6.30795 16.8683 6.62117 16.7229 6.9539 16.6458C7.28663 16.5686 7.63168 16.5613 7.96735 16.6243C8.30303 16.6874 8.62207 16.8194 8.90444 17.0122L26.714 29.9122Z"
      fill="#FFCB2E"
    />
    <path
      d="M18.2571 29.0329L6.14277 20.2609C5.78313 19.9875 5.51551 19.6103 5.3759 19.1798C5.23629 18.7493 5.23138 18.2863 5.36182 17.8529C4.95765 18.434 4.7791 19.1433 4.85978 19.8472C4.94047 20.5512 5.27482 21.2013 5.79992 21.6751L18.1428 30.428C19.1904 31.2498 19.3999 29.8547 18.2571 29.0329Z"
      fill="#BF9823"
    />
    <path
      d="M26.2379 36.3526C27.3807 37.1744 19.2093 38.0344 18.0665 37.2126L5.47598 28.0966C4.91221 27.6539 4.53747 27.0125 4.42781 26.3026C4.31814 25.5928 4.48178 24.8676 4.8855 24.2744C5.0531 23.9726 5.2816 23.7092 5.55641 23.5009C5.83121 23.2927 6.14628 23.1443 6.48148 23.0652C6.81668 22.9861 7.16465 22.9779 7.50315 23.0414C7.84166 23.1048 8.16326 23.2383 8.44741 23.4335L26.2379 36.3526Z"
      fill="#FFCB2E"
    />
    <path
      d="M40.9999 28.8611C40.0805 32.8134 37.9002 36.3564 34.7903 38.9517C30.3713 42.3344 23.4951 39.3913 20.238 37.2126C16.9808 35.0339 14.9618 33.7344 6.71415 27.4468C5.17129 26.2619 4.80939 25.4019 4.96177 24.1788C4.72294 24.4658 4.5436 24.7977 4.43427 25.1552C4.32494 25.5126 4.28781 25.8883 4.32506 26.2603C4.36232 26.6323 4.4732 26.9931 4.65122 27.3215C4.82924 27.65 5.07081 27.9395 5.36177 28.1731L6.21891 28.7846C6.21891 28.7846 16.1427 36.0659 19.5522 38.3402C22.5999 40.4424 30.5427 43.8633 35.5903 39.8499C37.2319 38.521 38.5659 36.85 39.4999 34.9526C40.434 33.0551 40.9458 30.9766 40.9999 28.8611Z"
      fill="#BF9823"
    />
    <path
      d="M32.4283 20.662C31.5208 19.6398 30.9839 18.3402 30.9045 16.9736L30.5806 16.5531C30.1217 15.9931 29.7818 15.3447 29.582 14.6479C29.3822 13.9512 29.3266 13.2207 29.4187 12.5016C28.9608 13.3576 28.7773 14.3345 28.8932 15.299C29.0091 16.2635 29.4188 17.1687 30.0664 17.8909L30.4092 18.3496C30.3853 18.7497 30.4779 19.1481 30.6759 19.4963L32.4283 20.662Z"
      fill="#BF9823"
    />
    <path
      d="M25.1333 0.5L27.3047 9.25289L31.4762 1.13067L25.1333 0.5Z"
      fill="#898989"
    />
    <path
      d="M35.7239 3.08008L32.5049 10.3423L40.0096 8.39297L35.7239 3.08008Z"
      fill="#898989"
    />
    <path
      d="M19.7423 1.60852L21.7423 9.42496L15.1709 5.25874L19.7423 1.60852Z"
      fill="#898989"
    />
  </svg>
);
export default Clap;
