import { BRAND_NAME, TAXONOMY_IDS, TAXONOMY_TYPES } from "@chef/constants";
import { isNotEmptyArray } from "../array";
import { isEqualStrings } from "../equal";
import { isNotEmptyObject } from "../object";
import { removeDuplicatesByKey } from "./removeDuplicates";

const findFeaturedPortraitImage = (recipeData = {}, size = "large") => {
  const { images } = recipeData;

  if (!images) {
    return {};
  } else if (isNotEmptyArray(images)) {
    return (
      images
        .find((i) => i.isFeatured === true)
        .urls.find((u) => u.size === size) ||
      images.urls.find((u) => u !== undefined)
    );
  } else if (isNotEmptyObject(images)) {
    return (
      images.urls.find((u) => u.size === size) ||
      images.urls.find((u) => u !== undefined)
    );
  } else {
    return {};
  }
};

const generateTags = (item) => {
  const {
    taxonomies = [],
    cookingTimeMin,
    cookingTimeMax,
    shelfLife: shelfLifeDays,
  } = item;

  const marketingTax = taxonomies.find(
    (t) => t.type === TAXONOMY_TYPES.MARKETING,
  );

  const campaignTax = taxonomies.find(
    (t) => t.type === TAXONOMY_TYPES.CAMPAIGN,
  );

  const specialFood = removeDuplicatesByKey(
    taxonomies
      .filter(
        (t) =>
          t.type === TAXONOMY_TYPES.SPECIALFOOD ||
          (t.type === TAXONOMY_TYPES.CATEGORY &&
            [
              TAXONOMY_IDS.GLUTENFREE,
              TAXONOMY_IDS.GLUTENFREE_OLD,
              TAXONOMY_IDS.LACTOSEFREE,
              TAXONOMY_IDS.LACTOSEFREE_OLD,
            ].includes(t.taxonomyId)),
      )
      .map(({ name, taxonomyId }) => ({
        id: taxonomyId,
        text: name,
      })),
    "text",
  );

  const categoryTag = taxonomies
    .filter((t) => t.type === TAXONOMY_TYPES.CATEGORY)
    .map(({ name, taxonomyId }) => ({
      id: taxonomyId,
      text: name,
    }));

  const cookingTime =
    cookingTimeMax === cookingTimeMin
      ? {
          id: "cookingTime",
          text: cookingTimeMin,
        }
      : {
          id: "cookingTime",
          text: `${cookingTimeMin}-${cookingTimeMax}`,
        };

  const marketing = {
    id: marketingTax && marketingTax.taxonomyId,
    text: marketingTax && marketingTax.name,
  };

  const campaign = {
    id: campaignTax && campaignTax.taxonomyId,
    text: campaignTax && campaignTax.name,
  };

  const shelfLife = {
    id: "shelfLife",
    text: intl.SHELF_LIFE(shelfLifeDays),
  };

  return {
    cookingTime,
    marketing,
    campaign,
    specialFood,
    shelfLife,
    categoryTag,
  };
};

const generateVariationIdFromPortions = (recipe = {}, portionsToUse) => {
  const { portions = [] } = recipe;
  const match = portions.find((p) => +p.size === +portionsToUse);
  return match ? match.variationId : "";
};

const findProductByVariationId = (products = [], variationId = "") =>
  products.find(({ variations = [] }) =>
    variations.find((v) => isEqualStrings(v.variationId, variationId)),
  );

export {
  findFeaturedPortraitImage,
  generateTags,
  generateVariationIdFromPortions,
  findProductByVariationId,
};

const intl = {
  AMK: {
    SHELF_LIFE: (days) => `${days} dagers holdbarhet`,
  },
  GL: {
    SHELF_LIFE: (days) => `${days} dagers holdbarhet`,
  },
  LMK: {
    SHELF_LIFE: (days) => `${days} dagars hållbarhet`,
  },
  RN: {
    SHELF_LIFE: (days) => `${days} dages holdbarhed`,
  },
}[BRAND_NAME];
