import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { useInterval } from "@mantine/hooks";
import { useRouter } from "next/router";

export const NavigationProgress = () => {
  const router = useRouter();

  const [progress, setProgress] = useState(0);

  const interval = useInterval(() => {
    setProgress((amount) => {
      let next = 0;
      if (amount >= 0 && amount <= 20) {
        next = 10;
      } else if (amount >= 20 && amount <= 50) {
        next = 4;
      } else if (amount >= 50 && amount <= 80) {
        next = 2;
      } else if (amount >= 80 && amount <= 99) {
        next = 0.5;
      }

      return amount + next;
    });
  }, 500);

  useEffect(() => {
    const handleStart = (url: string, { shallow }: { shallow: boolean }) => {
      if (url !== router.asPath && !shallow) {
        interval.start();
      }
    };

    const handleComplete = (_: unknown, { shallow }: { shallow: boolean }) => {
      if (shallow) {
        return;
      }

      setProgress(100);

      setTimeout(() => {
        interval.stop();
      }, 100);

      setTimeout(() => {
        setProgress(0);
      }, 300);
    };

    const handleError = (
      _: unknown,
      __: unknown,
      args: { shallow?: boolean },
    ) => {
      handleComplete(__, { shallow: args?.shallow || false });
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleError);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleError);
    };
  }, [router.asPath]);

  return (
    <motion.div
      animate={{ width: `${progress}%`, opacity: interval.active ? 1 : 0 }}
      className="fixed top-0 left-0 right-0 h-1 z-max bg-primary"
    />
  );
};
