import { useCallback, useContext } from "react";

import { Events } from "@chef/events";
import { useTrackMutation } from "@chef/state-management";

import { AnalyticsContext } from ".";

export const useAnalytics = (overrideThrow = false) => {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    // NOTE: this shouldn't ever be used -- needed for datadog temporarily
    // TODO RTK: remove once all brands are on RTK
    if (overrideThrow) {
      return null;
    }
    throw new Error("useAnalytics used outside of TrackingProvider");
  }

  return ctx.analytics;
};

export const useIdentify = () => {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error("useTrack used outside of TrackingProvider");
  }

  const fn = useCallback(
    (options: Record<string, any>) => {
      return ctx.queue.push({ type: "identify", options });
    },
    [ctx.queue],
  );

  return fn;
};

export const useTrack = (args?: { serverSide?: boolean }) => {
  const analytics = useAnalytics();
  const { serverSide } = args || {};
  const [backendTrack] = useTrackMutation();

  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error("useTrack used outside of TrackingProvider");
  }

  const fn = useCallback(
    <K extends keyof Events>(eventName: K, payload: Events[K]) => {
      if (serverSide) {
        return (async () => {
          let id: string | undefined;

          // Attempts to retrieve the user id from the analytics instance
          try {
            const user = await analytics?.user();

            const _id = user?.id() || user?.anonymousId();

            if (_id) {
              id = _id;
            }
          } catch {
            // Do nothing
          }

          return backendTrack({ eventName, payload, id }).unwrap();
        })();
      }

      return ctx.queue.push({ type: "track", eventName, payload });
    },
    [ctx.queue, backendTrack, serverSide, analytics],
  );

  return fn;
};
