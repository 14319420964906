import * as React from "react";
import { SVGProps } from "react";
const LMKGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 45 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.999 4.17279C13.999 4.17279 13.9181 0 9.74846 0C5.5788 0 5.33624 4.33153 5.33624 4.33153H0V15.6706L19.3006 12.0535V4.17279H13.999ZM6.35267 4.25216C6.35267 4.25216 6.73383 0.986501 9.67916 0.986501C12.6245 0.986501 12.8901 4.13877 12.8901 4.13877L6.35267 4.25216Z"
      fill="currentColor"
    />
    <path
      d="M21.5195 11.9968V4.2522H23.0557V10.6815H27.0868V12.0081H21.5195V11.9968Z"
      fill="currentColor"
    />
    <path
      d="M34.3984 11.9968V6.87153L32.3655 10.6928H31.5339L29.501 6.87153V12.0081H27.9648V4.2522H29.6165L31.9497 8.6631L34.306 4.2522H35.9461V12.0081H34.3984V11.9968Z"
      fill="currentColor"
    />
    <path
      d="M37.5273 11.9968V4.26354H39.0635V7.9374L42.3785 4.2522H44.0648L41.0386 7.69928L44.2496 12.0081H42.5286L40.0684 8.66311L39.052 9.7063V12.0195H37.5273V11.9968Z"
      fill="currentColor"
    />
    <path
      d="M17.9969 19.9794C17.3732 20.6598 16.6455 20.9999 15.837 20.9999C15.3981 20.9999 14.9707 20.9092 14.578 20.7165C14.1969 20.5237 13.8619 20.2629 13.5847 19.9567C13.3075 19.6392 13.0765 19.2764 12.9263 18.8795C12.7762 18.4827 12.6953 18.0631 12.6953 17.6436C12.6953 17.2127 12.7762 16.7818 12.9263 16.3736C13.0765 15.9767 13.2959 15.6252 13.5731 15.3077C13.8503 15.0015 14.1853 14.7521 14.5549 14.5707C14.9476 14.3892 15.3634 14.2872 15.8023 14.2985C16.1027 14.2985 16.4145 14.3325 16.7033 14.4006C16.9574 14.4686 17.1999 14.5707 17.4309 14.6954C17.6388 14.8201 17.8237 14.9675 17.9854 15.149C18.1355 15.3304 18.2741 15.5231 18.3781 15.7272L17.8699 16.0561C17.6735 15.6819 17.3847 15.3644 17.0036 15.149C16.634 14.9562 16.2182 14.8541 15.8023 14.8541C15.4558 14.8541 15.1093 14.9335 14.7859 15.0923C14.4856 15.2397 14.2315 15.4551 14.012 15.7159C13.7926 15.9767 13.6309 16.2829 13.5269 16.6004C13.4114 16.9292 13.3537 17.2807 13.3537 17.6322C13.3537 17.9951 13.423 18.3579 13.55 18.6981C13.6771 19.0156 13.8503 19.3217 14.0814 19.5825C14.3008 19.832 14.578 20.0361 14.8783 20.1835C15.1902 20.3309 15.5367 20.4103 15.8832 20.4103C16.2644 20.4103 16.6455 20.3196 16.992 20.1495C17.3847 19.9341 17.7313 19.6506 17.9969 19.3104V18.2105H16.4954V17.7116H18.5513V20.9659H17.9854V19.9794H17.9969Z"
      fill="currentColor"
    />
    <path
      d="M19.8789 20.9546V14.3439H22.7203C22.9975 14.3439 23.2747 14.4006 23.5173 14.5254C23.7598 14.6388 23.9677 14.8088 24.1525 15.0016C24.3258 15.1944 24.4644 15.4211 24.5683 15.6706C24.6607 15.9087 24.7185 16.1695 24.7185 16.419C24.7185 16.6571 24.6838 16.8839 24.6145 17.1107C24.5452 17.3261 24.4413 17.5302 24.3142 17.7116C24.1872 17.8817 24.0255 18.0405 23.8407 18.1539C23.6559 18.2786 23.448 18.358 23.2285 18.4033L24.8802 20.9546H24.1294L22.547 18.5167H20.5604V20.9546H19.8789ZM20.5373 17.9384H22.7434C22.9282 17.9384 23.113 17.8931 23.2747 17.8137C23.4364 17.7343 23.575 17.6096 23.6905 17.4735C23.806 17.3261 23.8984 17.1674 23.9562 16.9859C24.0139 16.8045 24.0486 16.6118 24.0486 16.419C24.0486 16.2262 24.0139 16.0335 23.9446 15.8407C23.8753 15.6706 23.7829 15.5005 23.6559 15.3645C23.5288 15.2284 23.3902 15.115 23.217 15.0356C23.0552 14.9562 22.8704 14.9109 22.6856 14.9109H20.5373V17.9384Z"
      fill="currentColor"
    />
    <path
      d="M28.7392 20.9999C28.2887 20.9999 27.8498 20.9092 27.4571 20.7165C27.0759 20.535 26.741 20.2742 26.4638 19.9567C26.1866 19.6392 25.9671 19.2764 25.8169 18.8795C25.6668 18.4827 25.5859 18.0631 25.5859 17.6436C25.5859 17.2127 25.6668 16.7818 25.8285 16.3736C25.9786 15.9767 26.2097 15.6139 26.4984 15.3077C26.7756 15.0015 27.1221 14.7521 27.5033 14.5707C27.896 14.3892 28.3234 14.2985 28.7507 14.2985C29.2012 14.2985 29.6401 14.3892 30.0444 14.5933C30.4255 14.7861 30.7605 15.0469 31.0377 15.3644C31.3149 15.6819 31.5343 16.0447 31.673 16.4416C31.8231 16.8385 31.904 17.2467 31.904 17.6662C31.904 18.0971 31.8116 18.528 31.6499 18.9362C31.4997 19.3331 31.2687 19.6846 30.9799 20.0021C30.7027 20.3082 30.3562 20.5577 29.9751 20.7391C29.5939 20.9092 29.1665 20.9999 28.7392 20.9999ZM26.2443 17.6436C26.2443 17.9951 26.3021 18.3466 26.4291 18.6754C26.5446 19.0043 26.7179 19.2991 26.9373 19.5599C27.1568 19.8093 27.4224 20.0248 27.7227 20.1722C28.0346 20.3309 28.3811 20.399 28.7392 20.399C29.0972 20.4103 29.4553 20.3196 29.7787 20.1608C30.079 20.0021 30.3562 19.7867 30.5641 19.5259C30.7836 19.2651 30.9453 18.9589 31.0608 18.6414C31.1763 18.3126 31.234 17.9837 31.234 17.6322C31.234 17.2807 31.1763 16.9292 31.0492 16.589C30.9337 16.2715 30.7605 15.9654 30.5295 15.7046C30.31 15.4551 30.0444 15.2397 29.744 15.0923C29.0972 14.7861 28.3465 14.7861 27.6996 15.1036C27.3993 15.2623 27.1221 15.4665 26.9142 15.7273C26.6948 15.988 26.5331 16.2942 26.4176 16.6117C26.3021 16.9632 26.2443 17.3034 26.2443 17.6436Z"
      fill="currentColor"
    />
    <path
      d="M35.8303 20.4105C36.1999 20.4218 36.558 20.3424 36.893 20.1723C37.1702 20.0249 37.4012 19.8095 37.5629 19.5487C37.7361 19.2765 37.8516 18.9817 37.9094 18.6642C37.9787 18.3354 38.0133 18.0066 38.0133 17.6664V14.3327H38.6833V17.6664C38.6833 18.0973 38.6371 18.5168 38.5331 18.9364C38.4407 19.3106 38.279 19.6734 38.048 19.9909C37.817 20.2971 37.5167 20.5465 37.1702 20.7166C36.7544 20.9094 36.3039 21.0001 35.8534 20.9887C35.3914 21.0001 34.9294 20.9094 34.5136 20.7053C34.1671 20.5238 33.8668 20.2744 33.6358 19.9569C33.4163 19.6394 33.2546 19.2765 33.1738 18.891C33.0814 18.4828 33.0352 18.0746 33.0352 17.6664V14.3327H33.6935V17.6664C33.6935 18.0066 33.7282 18.3467 33.7975 18.6756C33.8552 18.9817 33.9823 19.2879 34.1555 19.5487C34.3288 19.8095 34.5482 20.0136 34.8255 20.161C35.1142 20.3424 35.4723 20.4218 35.8303 20.4105Z"
      fill="currentColor"
    />
    <path
      d="M40.207 20.9546V14.3439H42.9907C43.2679 14.3439 43.5451 14.4006 43.7876 14.5254C44.0302 14.6388 44.2381 14.8088 44.4229 15.0016C44.5961 15.1944 44.7463 15.4211 44.8387 15.6706C44.9426 15.9087 44.9888 16.1695 44.9888 16.419C44.9888 16.6911 44.9426 16.9519 44.8502 17.2014C44.7578 17.4508 44.6192 17.6663 44.446 17.8704C44.2727 18.0632 44.0648 18.2219 43.8338 18.3353C43.5913 18.46 43.3141 18.5167 43.0369 18.5167H40.8538V20.9546H40.207ZM40.8769 17.9384H43.0253C43.2101 17.9384 43.3949 17.8931 43.5566 17.8137C43.7183 17.7343 43.8569 17.6096 43.9724 17.4735C44.0879 17.3261 44.1803 17.1674 44.2381 16.9859C44.3074 16.8045 44.3305 16.6118 44.3305 16.4076C44.3305 16.0221 44.1919 15.6366 43.9262 15.3418C43.8107 15.2057 43.6606 15.0923 43.4873 15.0243C43.3256 14.9449 43.1408 14.9109 42.956 14.9109H40.8654V17.9384H40.8769Z"
      fill="currentColor"
    />
  </svg>
);
export default LMKGroup;
