import * as React from "react";
import { SVGProps } from "react";
const DailyCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 21"
    fill="none"
    {...props}
  >
    <path
      d="M13 1.5V5.5M5 1.5V5.5M1 9.5H17M1 5.5C1 4.96957 1.21071 4.46086 1.58579 4.08579C1.96086 3.71071 2.46957 3.5 3 3.5H15C15.5304 3.5 16.0391 3.71071 16.4142 4.08579C16.7893 4.46086 17 4.96957 17 5.5V17.5C17 18.0304 16.7893 18.5391 16.4142 18.9142C16.0391 19.2893 15.5304 19.5 15 19.5H3C2.46957 19.5 1.96086 19.2893 1.58579 18.9142C1.21071 18.5391 1 18.0304 1 17.5V5.5Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DailyCalendar;
