import { BRAND_NAME } from "@chef/constants";
import {
  AmericanExpress,
  Klarna,
  MasterCardBadge,
  MobilePayBadge,
  VippsBadge,
  VisaBadge,
} from "@chef/icons/other";
import { Fodevarekontrol } from "./Fodevarekontrol";

export const PaymentBadges = () => {
  return (
    <div className="flex items-center content-center gap-1 text-3xl">
      {["GL", "AMK"].includes(BRAND_NAME) && <VippsBadge className="w-14" />}
      {BRAND_NAME === "RN" && <MobilePayBadge className="w-24" />}
      <VisaBadge />
      <MasterCardBadge />
      {BRAND_NAME === "LMK" && <Klarna className="w-16" />}
      {BRAND_NAME !== "RN" && <AmericanExpress />}
      {BRAND_NAME === "RN" && <Fodevarekontrol />}
    </div>
  );
};
