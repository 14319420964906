import * as React from "react";
import { SVGProps } from "react";
const Delivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.8879 20H19.5172M19.5172 20V8H4V20H6.37069M19.5172 20H10.25M19.5172 11.4286H24.6466L26.5431 14.8571H28.2241C28.569 14.8571 29 14.9429 29 15.4571V20H25.7672"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M8.5 24C9.88071 24 11 22.8807 11 21.5C11 20.1193 9.88071 19 8.5 19C7.11929 19 6 20.1193 6 21.5C6 22.8807 7.11929 24 8.5 24Z"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M23.5 24C24.8807 24 26 22.8807 26 21.5C26 20.1193 24.8807 19 23.5 19C22.1193 19 21 20.1193 21 21.5C21 22.8807 22.1193 24 23.5 24Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
export default Delivery;
