import React, { HTMLAttributes } from "react";
import clsx from "clsx";

interface CardProps extends HTMLAttributes<keyof React.ReactHTML> {
  as?: React.ElementType;
  noPadding?: boolean;
  noShadow?: boolean;
  transparent?: boolean;
  href?: string;
  id?: string;

  onClick?: (e?: any) => void;
}

export const Card = React.forwardRef<HTMLElement, CardProps>(
  (
    {
      as,
      noPadding = false,
      noShadow = false,
      transparent = false,
      className,
      href,
      onClick,
      id,
      children,
      ...props
    },
    ref,
  ) => {
    return React.createElement(
      as || "div",
      {
        className: clsx(
          "rounded",
          !transparent && "bg-white",
          !noPadding && "p-4 xl:p-6",
          !noShadow && "shadow",
          className,
        ),
        ref,
        href,
        onClick,
        id,
        ...props,
      },
      children,
    );
  },
);
