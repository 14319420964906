export { default as AmericanExpress } from "./AmericanExpress";
export { default as Apple } from "./Apple";
export { default as Google } from "./Google";
export { default as Klarna } from "./Klarna";
export { default as MasterCardBadge } from "./MasterCardBadge";
export { default as Mastercard } from "./Mastercard";
export { default as MobilePayBadge } from "./MobilePayBadge";
export { default as Pepperbørse } from "./Pepperbørse";
export { default as Trustpilot } from "./Trustpilot";
export { default as Vipps } from "./Vipps";
export { default as VippsBadge } from "./VippsBadge";
export { default as VippsIcon } from "./VippsIcon";
export { default as Vippslogo } from "./Vippslogo";
export { default as Visa } from "./Visa";
export { default as VisaBadge } from "./VisaBadge";
