import { BRAND_NAME } from "./misc";

export const CONTACT = (
  {
    AMK: {
      NAME: "Adams Matkasse",
      ADDRESS_STREET: "Tordenskiolds gate 8-10",
      ADDRESS_ZIP: "0160",
      ADDRESS_CITY: "Oslo",
      ORGANIZATION_NUMBER: "915 464 750",
      PHONE: "+47 33 99 71 51",
      EMAIL: "post@adamsmatkasse.no",
      URL: "https://www.adamsmatkasse.no",
    },
    LMK: {
      NAME: "Linas Matkasse",
      ADDRESS_STREET: "Löfströms Allé 5",
      ADDRESS_ZIP: "172 66",
      ADDRESS_CITY: "Sundbyberg",
      ORGANIZATION_NUMBER: "559021-1263",
      PHONE: "+46 8-446 861 32",
      EMAIL: "kundservice@linasmatkasse.se",
      URL: "https://www.linasmatkasse.se",
    },
    GL: {
      NAME: "Godtlevert",
      ADDRESS_STREET: "Tordenskiolds gate 8-10",
      ADDRESS_ZIP: "0160",
      ADDRESS_CITY: "Oslo",
      ORGANIZATION_NUMBER: "899 306 562",
      PHONE: "+47 32 99 35 81",
      EMAIL: "kundeservice@godtlevert.no",
      URL: "https://www.godtlevert.no",
    },
    RN: {
      NAME: "Cheffelo Denmark ApS",
      ADDRESS_STREET: "Ole Rømers Vej 4",
      ADDRESS_ZIP: "3000",
      ADDRESS_CITY: "Helsingør",
      ADDRESS_COUNTRY: "DK",
      ORGANIZATION_NUMBER: "27 76 82 45",
      PHONE: "+45 89 87 55 80",
      EMAIL: "kundeservice@retnemt.dk",
      URL: "https://www.retnemt.dk",
    },
  } as const
)[BRAND_NAME];
