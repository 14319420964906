import clsx from "clsx";
import { Card } from "../components";

interface MealCardSimpleProps {
  Image:
    | ((
        props: React.HTMLAttributes<HTMLImageElement>,
      ) => React.ReactElement | null)
    | React.MemoExoticComponent<() => JSX.Element>;
  title: string;
  tags: JSX.Element[];
  description: string;
  className?: string;
  openRecipeModalHandler?: () => void;
}

const MealCardSimple = ({
  Image,
  title,
  tags,
  description,
  className,
  openRecipeModalHandler,
}: MealCardSimpleProps) => {
  const onClickHandler = () => {
    if (openRecipeModalHandler) {
      openRecipeModalHandler();
    }
  };
  return (
    <Card
      noPadding
      className={clsx("flex md:flex-row flex-col", className)}
      onClick={onClickHandler}
    >
      <Image className="rounded-sm" />

      <div className="p-6 md:w-1/2">
        <div>
          <strong className="text-lg md:text-xl">{title}</strong>
        </div>

        <div className="flex flex-wrap gap-2 mt-2">{tags}</div>

        <p className="mt-4 text-sm">{description}</p>
      </div>
    </Card>
  );
};

export default MealCardSimple;
