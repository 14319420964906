import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      KEEP_SCREEN_ON: "Hold skjermen påslått",
      ACTIVATE_TO_PREVENT_THE_SCREEN_FROM_TURNING_OFF:
        "Aktivér for å unngå at skjermen slukker mens du lager mat.",
    },
    se: {
      KEEP_SCREEN_ON: "Håll skärmen tänd",
      ACTIVATE_TO_PREVENT_THE_SCREEN_FROM_TURNING_OFF:
        "Aktivera för att undvika att skärmen slocknar när du lagar mat.",
    },
    dk: {
      KEEP_SCREEN_ON: "Hold min skærm tændt",
      ACTIVATE_TO_PREVENT_THE_SCREEN_FROM_TURNING_OFF:
        "Aktivér for at undgå, at skærmen slukker under madlavningen.",
    },
  } as const
)[language];
