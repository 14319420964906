import { useCallback, useRef, useState } from "react";

import { noop } from "@chef/utils/noop";

type CB = () => Promise<unknown> | unknown;

interface UseConfirmDialogArgs {
  type?: "onConfirm" | "onCancel";
}

export const useConfirmDialog = ({
  type = "onConfirm",
}: UseConfirmDialogArgs = {}) => {
  const [isShowing, setIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cb = useRef<CB>(noop);

  const handleShow = useCallback(
    (func?: CB) => {
      setIsShowing(true);
      cb.current = func || noop;
    },
    [setIsShowing],
  );

  const handleConfirm = useCallback(async () => {
    setIsLoading(true);

    if (type === "onConfirm") {
      await cb.current();
    }

    setIsShowing(false);
    setIsLoading(false);
  }, [setIsShowing, type]);

  const handleCancel = useCallback(async () => {
    if (type === "onCancel") {
      await cb.current();
    }

    setIsShowing(false);
  }, [setIsShowing, type]);

  return { isShowing, isLoading, handleShow, handleConfirm, handleCancel };
};
