import * as React from "react";
import { SVGProps } from "react";
const NoPay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.3857 34.6578L46.688 23.2293C49.8173 20.0973 53.8455 19.0645 61.3859 19.0645C72.5549 19.0645 89.966 19.1977 89.966 19.1977M66.5792 58.0811C71.893 58.2035 77.1181 56.7043 81.5601 53.783C87.4026 50.1345 89.0838 50.2678 89.9993 50.2678"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.5803 47.9349V68.4761C66.5803 69.1654 66.3067 69.8264 65.8198 70.3138C65.3328 70.8012 64.6723 71.075 63.9836 71.075H14.5967C13.9081 71.075 13.2476 70.8012 12.7606 70.3138C12.2736 69.8264 12.0001 69.1654 12.0001 68.4761V37.2728C11.9979 36.9301 12.0634 36.5903 12.1929 36.2731C12.3224 35.9559 12.5134 35.6674 12.7547 35.4243C12.996 35.1812 13.283 34.9883 13.5992 34.8566C13.9153 34.725 14.2543 34.6572 14.5967 34.6572H54.8786M12.0001 47.6683H41.9618"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.2047 29.3262L39.9306 49.7508C38.7254 50.9592 38.0492 52.5973 38.0508 54.3047C38.0523 56.0121 38.7315 57.649 39.9389 58.8552C41.1463 60.0614 42.783 60.7382 44.489 60.7366C46.195 60.7351 47.8304 60.0553 49.0356 58.8469L60.2047 47.6683C60.2047 47.6683 70.6081 51.0002 79.5633 39.8717"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1396 12L73.1207 90"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NoPay;
