import * as React from "react";
import { SVGProps } from "react";
const Instagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 8C0.5 6.13948 0.5 5.20922 0.722789 4.45047C1.25011 2.65457 2.65457 1.25011 4.45047 0.722789C5.20922 0.5 6.13948 0.5 8 0.5C9.86052 0.5 10.7908 0.5 11.5495 0.722789C13.3454 1.25011 14.7499 2.65457 15.2772 4.45047C15.5 5.20922 15.5 6.13948 15.5 8C15.5 9.86052 15.5 10.7908 15.2772 11.5495C14.7499 13.3454 13.3454 14.7499 11.5495 15.2772C10.7908 15.5 9.86052 15.5 8 15.5C6.13948 15.5 5.20922 15.5 4.45047 15.2772C2.65457 14.7499 1.25011 13.3454 0.722789 11.5495C0.5 10.7908 0.5 9.86052 0.5 8ZM7.99996 4.02943C5.8072 4.02943 4.02937 5.80726 4.02937 8.00002C4.02937 10.1928 5.8072 11.9706 7.99996 11.9706C10.1927 11.9706 11.9705 10.1928 11.9705 8.00002C11.9705 5.80726 10.1927 4.02943 7.99996 4.02943ZM7.99996 10.4816C6.63209 10.4816 5.51834 9.36789 5.51834 8.00002C5.51834 6.63116 6.63209 5.5184 7.99996 5.5184C9.36782 5.5184 10.4816 6.63116 10.4816 8.00002C10.4816 9.36789 9.36782 10.4816 7.99996 10.4816ZM12.7976 3.73165C12.7976 4.02386 12.5607 4.26073 12.2685 4.26073C11.9763 4.26073 11.7394 4.02386 11.7394 3.73165C11.7394 3.43945 11.9763 3.20257 12.2685 3.20257C12.5607 3.20257 12.7976 3.43945 12.7976 3.73165Z"
      fill="currentColor"
    />
  </svg>
);
export default Instagram;
