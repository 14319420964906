import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      FLOOR_LABEL: "Velg etasje",
    },

    se: {
      FLOOR_LABEL: "Antal trappor",
    },

    dk: {
      FLOOR_LABEL: "Etage",
    },
  } as const
)[language];
