import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      TRACK_SHIPMENT: "Spor forsendelse",
    },
    se: {
      TRACK_SHIPMENT: "Spåra leverans",
    },
    dk: {
      TRACK_SHIPMENT: "Spor forsendelse",
    },
  } as const
)[language];
