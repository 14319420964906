import { PropsWithChildren } from "react";
import clsx from "clsx";

import { Saw } from "@chef/icons/small";

interface Props {
  className?: string;
}

export const SawContainer = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={clsx("relative flex items-center justify-center", className)}
    >
      <div className="absolute inset-0">
        <Saw className="w-full h-full" />
      </div>

      <div className="z-1">{children}</div>
    </div>
  );
};
