import { OrderQuery } from "@chef/state-management";
import { Currency, Hr } from "@chef/components";

import { OrderMeals } from "./OrderMeals";
import { OrderDeliveryFee } from "./OrderDeliveryFee";
import { OrderGroceries } from "./OrderGroceries";
import { OrderSummary } from "./OrderSummary";
import { OrderDiscount } from "./OrderDiscount.tsx";

interface OrderShoppingCartProps {
  order: OrderQuery["order"];
  initiallyExpandMealSection?: boolean;
  initiallyExpandGrocerySection?: boolean;
}

export const OrderShoppingCart = ({
  order,
  initiallyExpandMealSection,
  initiallyExpandGrocerySection,
}: OrderShoppingCartProps) => {
  return (
    <div>
      <OrderMeals
        order={order}
        initiallyExpanded={initiallyExpandMealSection}
      />

      <OrderGroceries
        order={order}
        initiallyExpanded={initiallyExpandGrocerySection}
      />

      <OrderDeliveryFee order={order} />

      <PlateForPlateProduct order={order} />

      <OrderDiscount order={order} />

      <OrderSummary order={order} />
    </div>
  );
};

const PlateForPlateProduct = ({ order }: { order: OrderQuery["order"] }) => {
  const plateForPlateProduct = order.orderLines.find((ol) => ol.type === "P4P");

  if (!plateForPlateProduct) {
    return null;
  }

  return (
    <>
      <Hr className="my-4" />

      <div className="flex justify-between">
        <p>Plate for Plate</p>

        <Currency>{plateForPlateProduct.fullPrice}</Currency>
      </div>
    </>
  );
};
