import { useEffect, useState } from "react";

export const useMediaQuery = (
  query: string,
): [boolean, { loading: boolean }] => {
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia(query);

    setLoading(false);
    setMatches(mql.matches);

    const handleChange = () => setMatches(mql.matches);

    mql.addEventListener("change", handleChange);

    return () => mql.removeEventListener("change", handleChange);
  }, [query]);

  return [matches, { loading: loading }];
};
