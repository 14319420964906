import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { showNotification, userApi } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      PERSONAL_SETTINGS_UPDATED: "Personlig informasjon er oppdatert.",
    },

    se: {
      PERSONAL_SETTINGS_UPDATED: "Personlig information har uppdaterats",
    },

    dk: {
      PERSONAL_SETTINGS_UPDATED: "Personlige oplysninger er blevet opdateret",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      userApi.endpoints.updateUserInformation.matchFulfilled,
      userApi.endpoints.updateUserInformation.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.PERSONAL_SETTINGS_UPDATED,
        });
      }
    },
  });
};
