import { Check } from "@chef/icons/small";

const PlusProteinTag = ({ hasPlusProtein = false }) => {
  if (!hasPlusProtein) {
    return null;
  }

  return (
    <div className="absolute flex items-center h-5 p-1 text-xs bg-white rounded-px left-2 bottom-2 md:left-4 md:bottom-4">
      <Check className="mr-1 shrink-0" />
      <span>Ekstra protein tilføjet</span>
    </div>
  );
};

export default PlusProteinTag;
