import { MOBILE_APP_ENDPOINT } from "./common";
import { IFromAppPayload } from "./fromApp";

export type SubscribeEvents<T extends MOBILE_APP_ENDPOINT> = T | "*";

export type SubscribeCallback<T extends MOBILE_APP_ENDPOINT> = (
  payload: IFromAppPayload<T>,
) => void;

export type MobileAppSubscribe = <T extends MOBILE_APP_ENDPOINT>(
  event: SubscribeEvents<T>,
  callback: SubscribeCallback<T>,
) => () => void;

export * from "./common";
export * from "./toApp";
export * from "./fromApp";
