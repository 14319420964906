import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import clsx from "clsx";
import { useCallback, useState } from "react";
import Link from "next/link";
import { IconThumbDown, IconThumbUp } from "@tabler/icons-react";

import { Card, Snackbar } from "@chef/components";
import { BRAND_PATHS } from "@chef/constants";
import { useTrack } from "@chef/feature-tracking";
import { useEffectOnce } from "@chef/hooks";
import { intl } from "./PreselectorFeedback.Intl";

interface PreselectorFeedbackProps {
  week: number;
  year: number;
  mainRecipeIds: number[];
  variationIds: string[];
  preferenceIds: string[];
  portions: number;
}

interface FeedbackButtonProps {
  variant: "thumbs-up" | "thumbs-down";
}

type FormData = {
  feedback: "thumbs-up" | "thumbs-down";
};

const schema = yup.object({
  feedback: yup.string().oneOf(["thumbs-up", "thumbs-down"]).required(),
});

const ThumbsUp = () => {
  return (
    <>
      <span>
        <IconThumbUp className="stroke-inherit w-9 h-9" />
      </span>
      <span>{intl.DELICIOUS}</span>
    </>
  );
};

const ThumbsDown = () => {
  return (
    <>
      <span>
        <IconThumbDown className="mt-1 -mb-1 stroke-inherit w-9 h-9" />
      </span>
      <span>{intl.NOT_REALLY}</span>
    </>
  );
};

const FeedbackButton = ({ variant }: FeedbackButtonProps) => {
  const { watch, setValue } = useFormContext();

  const value = watch("feedback");

  return (
    <label
      className={clsx(
        "border-1.5 py-3 lg:px-8 px-4 rounded-lg flex flex-col items-center text-center gap-3 hover:cursor-pointer",
        {
          "border-grey-2 hover:border-grey-1 stroke-primary": value !== variant,
          "border-primary bg-primary text-white stroke-white":
            value === variant,
        },
      )}
    >
      {variant === "thumbs-up" ? <ThumbsUp /> : <ThumbsDown />}
      <input
        type="radio"
        name="feedback"
        id={`feedback-${variant}`}
        className="sr-only"
        onChange={() => setValue("feedback", variant)}
      />
    </label>
  );
};

export const PreselectorFeedback = ({
  week,
  year,
  mainRecipeIds,
  variationIds,
  preferenceIds,
  portions,
}: PreselectorFeedbackProps) => {
  const track = useTrack({ serverSide: true });
  const [hidden, setHidden] = useState(false);

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { watch, handleSubmit, formState } = methods;

  const feedback = watch("feedback");

  const onSubmit = useCallback(
    async (data: FormData) => {
      await track("preselectorFeedbackSubmitted", {
        week,
        year,
        feedback: data.feedback,
        mainRecipeIds,
        variationIds,
        preferenceIds,
        portions,
      });
    },
    [track, week, year, mainRecipeIds, variationIds, preferenceIds, portions],
  );

  useEffectOnce(() => {
    handleSubmit(onSubmit)();
  }, !!feedback);

  if (hidden) {
    return null;
  }

  if (formState.isSubmitSuccessful) {
    if (feedback === "thumbs-down") {
      return (
        <Snackbar
          warning
          showIcon={false}
          onClose={() => setHidden(true)}
          closeable
        >
          <div className="flex flex-col items-center p-4 text-sm text-center">
            <strong>{intl.WE_WILL_TALK_TO_OUR_CHEFS}</strong>
            <p className="px-6">
              {intl.YOU_CAN_SET_MORE_DETAILED_TASTE_PREFERENCES}
              <strong>
                <Link
                  href={BRAND_PATHS.MEALBOX_PREFERENCE}
                  className="underline"
                >
                  {intl.HERE}
                </Link>
              </strong>
            </p>
          </div>
        </Snackbar>
      );
    }

    return (
      <Snackbar
        success
        showIcon={false}
        onClose={() => setHidden(true)}
        closeable
      >
        <div className="flex flex-col items-center p-4 text-sm text-center">
          <strong>{intl.THANK_YOU_FOR_FEEDBACK}</strong>
        </div>
      </Snackbar>
    );
  }

  return (
    <Card
      className={clsx(
        "border-grey-2 border-1.5 flex flex-col items-center gap-4",
        formState.isSubmitting && "animate-pulse pointer-events-none",
      )}
      noShadow
    >
      <strong>{intl.DO_YOU_LIKE_THIS}</strong>
      <FormProvider {...methods}>
        <form
          className="grid w-full grid-cols-2 gap-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FeedbackButton variant="thumbs-down" />
          <FeedbackButton variant="thumbs-up" />
        </form>
      </FormProvider>
    </Card>
  );
};
