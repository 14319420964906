import {
  IVariant,
  useFlag as _useFlag,
  useVariant as _useVariant,
  useFlagsStatus,
} from "@unleash/nextjs/client";
import { useEffect, useState } from "react";

import { useTrack } from "@chef/feature-tracking";
import { useCookie, useEffectOnce } from "@chef/hooks";

/**
 * Checks if the feature flag is enabled or not.
 * This function will also log the event "experimentStarted" when applicable.
 * @param {string} name
 * @returns {boolean} enabled
 */
export const useFlag = (name: string) => {
  const unleashValue = _useFlag(name);

  const [value, setValue] = useState(unleashValue);

  const { value: overrides } = useCookie("vercel-flag-overrides");

  useEffect(() => {
    if (!overrides) {
      setValue(unleashValue);
      return;
    }

    try {
      const parsed = JSON.parse(overrides) as Record<string, boolean>;
      const override = parsed[name];

      if (override !== undefined) {
        setValue(override);
      } else {
        setValue(unleashValue);
      }
    } catch (e) {
      setValue(unleashValue);
    }
  }, [unleashValue, overrides, name]);

  useTrackExperiment(name, value);

  return value;
};

/**
 * Checks if the the feature flag is enabled, and returns the enabled variant.
 * This function will also log the event "experimentStarted" when applicable.
 * @param {string} name
 * @returns {IVariant} variant
 */
export const useVariant = (name: string) => {
  const unleashValue = _useVariant(name);
  const [value, setValue] = useState(unleashValue);

  useTrackExperiment(name, value);

  const { value: overrides } = useCookie("vercel-flag-overrides");

  useEffect(() => {
    if (!overrides) {
      setValue(unleashValue);
      return;
    }

    try {
      const parsed = JSON.parse(overrides) as Record<string, string>;
      const override = parsed[name];

      if (override) {
        setValue({ enabled: true, name: override });
      } else {
        setValue(unleashValue);
      }
    } catch (e) {
      setValue(unleashValue);
    }
  }, [unleashValue, overrides, name]);

  return value;
};

export const useTrackExperiment = (
  name?: string,
  value?: boolean | Partial<IVariant>,
) => {
  const track = useTrack();
  const { flagsReady } = useFlagsStatus();

  useEffectOnce(
    () => {
      if (!name || !value) {
        return;
      }

      if (typeof value === "boolean") {
        if (typeof window !== "undefined") {
          window.DD_RUM?.addFeatureFlagEvaluation(name, value);
        }

        track("experimentStarted", {
          affiliation: "Frontend process",
          flag_name: name,
          enabled: value,
          flag_type: "default",
        });
      } else {
        const enabled = value.enabled ?? false;
        const variantName = value.name!;

        if (typeof window !== "undefined") {
          window.DD_RUM?.addFeatureFlagEvaluation(name, variantName);
        }

        track("experimentStarted", {
          affiliation: "Frontend process",
          flag_name: name,
          enabled,
          flag_type: "variants",
          variant: {
            name: variantName,
            value: enabled.toString(),
          },
          variant_id: variantName,
          variant_label: value.payload?.value,
        });
      }
    },
    flagsReady && !!name && !!value,
  );
};
