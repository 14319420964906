import { Check, Squares } from "@chef/icons/small";

interface PlusProteinTagProps {
  hasPlusProtein: boolean;
  inBasket: boolean;
}

export const PlusProteinTag = ({
  hasPlusProtein = false,
  inBasket = false,
}: PlusProteinTagProps) => {
  if (!hasPlusProtein) {
    return null;
  }

  return (
    <div className="absolute flex items-center h-5 p-1 text-xs bg-white rounded-px left-2 bottom-2 md:left-4 md:bottom-4">
      {inBasket ? (
        <>
          <Check className="mr-1 shrink-0" />
          <span>Ekstra protein tilføjet</span>
        </>
      ) : (
        <>
          <Squares className="mr-1 shrink-0" />
          <span>Ekstra protein muligt</span>
        </>
      )}
    </div>
  );
};
