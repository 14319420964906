import clsx from "clsx";
import { Card, Container } from "../components";
interface ImageTextCardProps {
  DesktopImage: () => JSX.Element;
  MobileImage: () => JSX.Element;
  children?: React.ReactNode;
  reverse: boolean;
  backgroundColor: string;
}
const ImageTextCard = ({
  DesktopImage,
  MobileImage,
  children,
  reverse,
  backgroundColor,
}: ImageTextCardProps) => {
  return (
    <Container
      outerClassName={clsx(
        "stretch-x  py-10 md:py-20",
        backgroundColor === "bg-transparent" ? "bg-transparent" : "bg-grey-3",
      )}
    >
      <Card
        className={clsx(
          "flex flex-col",
          reverse ? "lg:flex-row-reverse" : "lg:flex-row",
        )}
        noPadding
      >
        <div className="flex-1 lg:self-center ">
          <div className="flex-col hidden lg:block">
            <DesktopImage />
          </div>
          <div className="block lg:hidden">
            <MobileImage />
          </div>
        </div>
        <div className="flex items-center w-full p-8 md:px-12 lg:w-1/2 xl:w-1/3 test not-prose">
          {children}
        </div>
      </Card>
    </Container>
  );
};

export default ImageTextCard;
