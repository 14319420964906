import { Language } from "../../types";
import { Rating } from "../components";

interface AverageDishRatingProps {
  language: Language;
  averageRating?: number;
  numberOfRatings?: number;
  fallback?: string;
}

const intl = {
  no: {
    DISH_HAS_NO_RATINGS: "Retten har ingen vurdering",
    OUT_OF: "av",
  },
  se: {
    DISH_HAS_NO_RATINGS: "Rätten saknar betyg",
    OUT_OF: "av",
  },
  dk: {
    DISH_HAS_NO_RATINGS: "Retten har ingen vurdering",
    OUT_OF: "af",
  },
};

const AverageDishRating = ({
  language,
  averageRating,
  numberOfRatings,
}: AverageDishRatingProps) => {
  const { DISH_HAS_NO_RATINGS, OUT_OF } = intl[language];

  if (!averageRating) {
    return <div className="text-xs text-grey-1">{DISH_HAS_NO_RATINGS}</div>;
  }

  return (
    <div className="flex items-center gap-1 mb-2">
      <Rating
        value={averageRating}
        numOptions={5}
        size={20}
        separator={OUT_OF}
        readOnly
      />
      <span className="text-xs leading-4 ml-1 mt-1.5 text-grey-1">
        {averageRating} ({numberOfRatings})
      </span>
    </div>
  );
};

export default AverageDishRating;
