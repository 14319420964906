import * as React from "react";
import { SVGProps } from "react";
const Phone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.275 12.8301C15.275 12.8301 14.6952 12.3176 13.9108 12.7618L11.9328 13.8552C11.9328 13.8552 9.98883 12.3176 8.89749 11.1217C7.70384 10.0283 6.16914 8.08064 6.16914 8.08064L7.26048 6.09883C7.70384 5.27877 7.19227 4.73206 7.19227 4.73206L5.00959 1.45182C4.63444 0.905117 4.15698 1.00762 4.15698 1.00762C3.50899 1.00762 1.73557 2.64774 1.73557 2.64774C0.814747 3.4678 1.01937 4.21952 1.01937 4.21952C1.63325 8.66151 6.30555 12.9327 6.6807 13.3085C7.15816 13.7527 11.3189 18.3314 15.7525 18.9806C15.7525 18.9806 16.4687 19.1856 17.3213 18.263C17.3213 18.263 18.9924 16.4862 18.9924 15.9054C18.9924 15.9054 19.0947 15.427 18.549 15.0511L15.275 12.8301Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default Phone;
