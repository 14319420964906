import { useCallback } from "react";
import clsx from "clsx";

import { Plus, Minus } from "@chef/icons/small";
import { STOCK_SOLD_OUT_STATES } from "@chef/constants";

import { Card, ButtonCircle, Switch } from "../components";
import { CurrencyWithDiscount } from ".";
import { Language, PropsOf } from "../../types";

interface StandaloneCardProps extends PropsOf<typeof Card> {
  language?: Language;
  name?: string;
  price?: number;
  discount?: number;
  CampaignTag?: (
    props: React.HTMLAttributes<HTMLDivElement>,
  ) => React.ReactElement | null;
  Image?: (
    props: React.HTMLAttributes<HTMLImageElement>,
  ) => React.ReactElement | null;
  onDecrementItem: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onIncrementItem: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAddToSubscription?: () => void;
  count: number;
  maxCount: number;
  borderless?: boolean;
  className?: string;
  isSubscribedTo?: boolean;
  subscribedQuantity?: number;
  stockState?: (typeof STOCK_SOLD_OUT_STATES)[keyof typeof STOCK_SOLD_OUT_STATES];
  id?: string;
  isAllowedToSubscribe?: boolean;
  isLoadingBilling?: boolean;
  onChangeSubscribed: () => void;
}

const intl = {
  no: {
    stockState: {
      "limited-stock": "Begrenset antall",
      "maximum-amount-added": "Maks antall valgt",
      "sold-out": "Utsolgt",
    },
    ADD_TO_YOUR_SUBSCRIPTION: "Legg til i ditt abonnement",
    YOU_ARE_SUBSCRIBING_TO: "Du abonnerer på",
    CHANGE: "endre",
    PIECES: "stk",
  },
  se: {
    stockState: {
      "limited-stock": "Begränsat antal kvar",
      "maximum-amount-added": "Max antal valda",
      "sold-out": "Slutsåld",
    },
    ADD_TO_YOUR_SUBSCRIPTION: "Lägg till i din prenumeration",
    YOU_ARE_SUBSCRIBING_TO: "Du prenumererar på",
    CHANGE: "ändra",
    PIECES: "st",
  },
  dk: {
    stockState: {
      "limited-stock": "Begrænset antal",
      "maximum-amount-added": "Max antal valgt",
      "sold-out": "Udsolgt",
    },
    ADD_TO_YOUR_SUBSCRIPTION: "Tilføj til dit abonnement",
    YOU_ARE_SUBSCRIBING_TO: "Du abonnerer på",
    CHANGE: "Rediger",
    PIECES: "stk",
  },
};

const StandaloneCard = ({
  language = "no",
  name = "",
  price = 0,
  discount = 0,
  CampaignTag = () => null,
  Image = () => null,
  onDecrementItem,
  onIncrementItem,
  onAddToSubscription,
  count,
  maxCount,
  borderless,
  onClick,
  href,
  className,
  stockState,
  isSubscribedTo,
  subscribedQuantity,
  isAllowedToSubscribe,
  isLoadingBilling,
  id,
  onChangeSubscribed = () => null,
  ...props
}: StandaloneCardProps) => {
  let InnerType: keyof React.ReactHTML = "div";

  const { ADD_TO_YOUR_SUBSCRIPTION, YOU_ARE_SUBSCRIBING_TO, CHANGE, PIECES } =
    intl[language];

  if (onClick) {
    InnerType = "button";
  }
  if (href) {
    InnerType = "a";
  }

  const ImageGenerated = useCallback(() => <Image />, []);

  return (
    <Card className={className} noShadow={borderless} noPadding {...props}>
      <div className="relative flex h-full md:flex-col">
        <InnerType onClick={onClick} href={href}>
          <CampaignTag className="absolute z-10 top-2 left-2 md:top-4 md:left-4" />
          <div
            className={clsx(
              "md:h-96 md:w-auto relative w-20 h-24",
              stockState === "sold-out" && "opacity-50",
            )}
          >
            <ImageGenerated />
          </div>
        </InnerType>

        <div
          className={clsx(
            "flex flex-col justify-between w-full flex-grow",
            borderless ? "py-2 md:py-4" : "p-2 md:p-4",
          )}
          id={id}
        >
          <InnerType
            onClick={onClick}
            href={href}
            className="text-left line-clamp-2"
          >
            {name}
          </InnerType>

          {stockState && (
            <div className="my-2 text-xs text-error">
              {intl[language].stockState[stockState]}
            </div>
          )}

          <div className="flex items-center justify-between w-full pt-2">
            <div>
              <CurrencyWithDiscount
                language={language}
                className="mt-2"
                discount={discount}
                total={price}
              />
            </div>
            {stockState !== "sold-out" && (
              <div className="flex items-center">
                {count > 0 && (
                  <>
                    <ButtonCircle
                      small
                      onClick={onDecrementItem}
                      Icon={Minus}
                      id={`${id}-minus-btn`}
                    />
                    <span className="mx-2">{count}</span>
                  </>
                )}
                <ButtonCircle
                  small
                  outlined={count === 0}
                  primary={count > 0}
                  onClick={onIncrementItem}
                  Icon={Plus}
                  disabled={
                    stockState === "maximum-amount-added" || +maxCount === count // +maxCount === count is kept for backward compability, stockState should from now on
                  }
                  id={`${id}-add-btn`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!!count && isAllowedToSubscribe && onAddToSubscription && (
        <div
          id="add-to-subscription"
          className={clsx(
            "flex px-2 py-3 text-sm align-middle rounded-b md:absolute w-full shadow-lg md:-mt-1",
            isSubscribedTo ? "bg-informationBG" : "bg-background",
          )}
        >
          <Switch
            className="shrink-0"
            small
            checked={isSubscribedTo}
            onChange={onAddToSubscription}
            isLoading={isLoadingBilling}
          />

          <span className="pl-2 text-sm">
            {isSubscribedTo ? (
              <>
                <span>
                  {YOU_ARE_SUBSCRIBING_TO} {subscribedQuantity} {PIECES}{" "}
                </span>
                <button onClick={onChangeSubscribed}>
                  <strong className="underline">{CHANGE}</strong>
                </button>
              </>
            ) : (
              <span>{ADD_TO_YOUR_SUBSCRIPTION}</span>
            )}
          </span>
        </div>
      )}
    </Card>
  );
};

export default StandaloneCard;
