import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      BASIC_GOODS: "Basisvarer",
      ALLERGY_HEADER: "Allergeninformasjon",
      ALLERGY_TEXT: `Allergener er ment som veiledende informasjon og tar utgangspunkt i
    ingrediensene og ikke «spor av». Du må selv sjekke
    innholdet på varene du mottar i matkassen`,
      RECIPE_STEP_SECTION_HEADER: "Slik gjør du",
      NUTRITION_PER_PORTION: "Næring per porsjon",
      NUTRITION_PER_GRAM: "Næring per 100 g",
    },
    se: {
      BASIC_GOODS: "Basvaror",
      ALLERGY_HEADER: "Information om allergener",
      ALLERGY_TEXT: `Allergener är tänkta som vägledande information och baseras på
    ingredienserna och inte "spår av". Vänligen kontrollera
    innehållet i varorna du får i kassen.`,
      RECIPE_STEP_SECTION_HEADER: "Så här gör du",
      NUTRITION_PER_PORTION: "Näring per portion",
      NUTRITION_PER_GRAM: "Näring per 100 g",
    },
    dk: {
      BASIC_GOODS: "Basisvarer",
      ALLERGY_HEADER: "Oplysninger om allergener",
      ALLERGY_TEXT: `Allergener er beregnet som vejledende information og er baseret på
    ingredienserne og ikke på "spor af". Venligst kontrollér
    indholdet af de varer, du modtager ved kassen.`,
      RECIPE_STEP_SECTION_HEADER: "Sådan gør du",
      NUTRITION_PER_PORTION: "Næring per portion",
      NUTRITION_PER_GRAM: "Næring per 100 g",
    },
  } as const
)[language];
