import { UseFormReturn } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { Checkbox, Skeleton } from "@chef/components";
import { TIMEBLOCK_TYPE_IDS } from "@chef/constants";
import { formatDate, formatTimeInterval, padPostalCode } from "@chef/helpers";
import {
  TimeblocksByRangeQuery,
  useBillingQuery,
  useTimeblocksByRangeQuery,
} from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import { isEmptyArray } from "@chef/utils/array";

import type { WeekSchedulerFormData } from "./types";
import { intl } from "./WeekSchedulerEditor.Intl";

interface TimeblocksDropdownProps {
  formControls: UseFormReturn<WeekSchedulerFormData>;
  week: number;
  year: number;
  calendarTimeblockId: number | undefined;
}

const UPCOMING_DELIVERY_WEEKS_TO_FETCH = 13;

const createTimeBlocksOptions = (args: {
  timeblocks: TimeblocksByRangeQuery["timeblocksByRange"][number]["timeblocks"];
}) => {
  const { timeblocks } = args;
  if (!timeblocks || timeblocks.length === 0) {
    return [];
  }

  return [...timeblocks]
    .sort((a, b) => {
      const aDate = new Date(a.deliveryDate);
      aDate.setHours(+a.from.slice(0, 2));

      const bDate = new Date(b.deliveryDate);
      bDate.setHours(+b.from.slice(0, 2));

      return aDate.getTime() - bDate.getTime();
    })
    .map((t) => ({
      value: t.timeblockId,
      label: `${formatDate(
        new Date(t.deliveryDate),
        "EEEE d. MMM",
      )} | ${formatTimeInterval(t.from, t.to, "m")}`,
    }));
};

export const TimeblocksCheckboxRadio = ({
  formControls,
  week,
  year,
  calendarTimeblockId,
}: TimeblocksDropdownProps) => {
  const { data: billingQuery } = useBillingQuery();
  const { setValue, getValues } = formControls;
  const addressId = getValues("addressId");

  const postalCode = billingQuery?.billing.addresses.find((a) =>
    isEqualStrings(a.addressId, addressId),
  )?.postalCode;

  const { data: timeblocksByRangeQuery, isFetching } =
    useTimeblocksByRangeQuery(
      {
        postalCode: padPostalCode(postalCode!),
        range: UPCOMING_DELIVERY_WEEKS_TO_FETCH,
        variationIds: [],
        timeblockTypeId: TIMEBLOCK_TYPE_IDS.NORMAL,
      },
      { skip: !postalCode },
    );

  const timeblocks = useMemo(() => {
    return (
      timeblocksByRangeQuery?.timeblocksByRange
        .find((t) => t.week === week && t.year === year)
        ?.timeblocks.filter(
          (t) => !(t.noDelivery && t.fallbackTimeblockId === null),
        ) || []
    );
  }, [timeblocksByRangeQuery, week, year]);

  const timeblockOptions = createTimeBlocksOptions({ timeblocks });

  useEffect(() => {
    const matchingTimeBlockId = timeblocks.find(
      (t) => t.timeblockId === calendarTimeblockId,
    )?.timeblockId;

    const timeblockToSet =
      matchingTimeBlockId || timeblocks[0]?.timeblockId || null;

    setValue("timeblockId", timeblockToSet);

    window.document
      .getElementById(`timeblock-${week}-${year}-${timeblockToSet}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, [
    timeblocksByRangeQuery,
    calendarTimeblockId,
    timeblocks,
    setValue,
    addressId,
    week,
    year,
  ]);

  if (!timeblocksByRangeQuery || !calendarTimeblockId || isFetching) {
    return <Skeleton height="40px" />;
  }

  if (isEmptyArray(timeblocks)) {
    return (
      <div className="my-2 text-sm text-center text-error">
        {intl.NO_DELIVERY_ON_SELECTED_ADDRESS_FOR_THIS_WEEK}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-start mb-2 text-sm">
        <strong>{intl.DELIVERYDATE_AND_TIME}</strong>
      </div>

      {timeblockOptions.map((timeblock) => {
        const selectedValue = getValues("timeblockId");
        const isSelected = selectedValue
          ? timeblock.value === selectedValue
          : false;

        return (
          <Checkbox
            onChange={(e) => {
              e.currentTarget.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });

              setValue("timeblockId", timeblock.value);
            }}
            key={timeblock.value}
            id={`timeblock-${week}-${year}-${timeblock.value.toString()}`}
            name="timeblockId"
            type="radio"
            variant="selector"
            state="checked"
            className="my-2"
            checked={isSelected}
          >
            {timeblock.label}
          </Checkbox>
        );
      })}
    </>
  );
};
