import Link from "next/link";
import { useRouter } from "next/router";
import clsx from "clsx";

import { ChevronRight } from "@chef/icons/small";

type PageLinkButtonProps = {
  title: string;
  href: string;
  id: string;
  Icon: JSX.Element;
};

export const PageLinkButton = ({
  title,
  href,
  id,
  Icon,
}: PageLinkButtonProps) => {
  const router = useRouter();
  const selected = router.pathname === href;
  return (
    <li className="relative border-b-1.5 border-grey-3 last:border-b-0" id={id}>
      {selected && (
        <div
          aria-hidden="true"
          className="absolute z-0 w-full h-full border-l-2 bg-grey-3 border-primary"
        />
      )}
      <Link
        href={href}
        className={clsx(
          "relative flex items-center px-6 py-8 space-x-4 z-1 border-l-2 border-transparent",
          "hover:bg-grey-3 hover:border-primary",
        )}
        aria-selected={selected}
      >
        <span className="flex items-center w-8 h-8 p-0 text-3xl shrink-0">
          {Icon}
        </span>
        <span className="flex-grow truncate">
          <strong>{title}</strong>
        </span>
        <span className="flex items-center">
          <ChevronRight />
        </span>
      </Link>
    </li>
  );
};
