import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DELETE_ACCOUNT_AND_ALL_YOUR_DATA_FROM:
        "Slett konto og alle dine data fra",
      FULL_DISCLAIMER:
        "Vær oppmerksom på at det ikke vil være mulig å åpne opp kontoen igjen, og du kommer derfor til å miste tilgang til oppskrifter og tidligere ordrehistorikk. Vennligst merk at alle leveringer må være betalt. Du vil motta en bekreftelse via chatten når slettingsprosessen er startet.",
      CONFIRM_DELETION: "Bekreft sletting",
      DELETE_ACCOUNT: "Slett konto",
      CANCEL: "Avbryt",
      YOUR_REQUEST_HAS_BEEN_RECEIVED:
        "Din forespørsel om å slette kontoen er mottatt og du vil motta en bekreftelse når slettingsprosessen er startet.",
      SOMETHING_WENT_WRONG: "Noe gikk galt",
    },
    se: {
      DELETE_ACCOUNT_AND_ALL_YOUR_DATA_FROM:
        "Radera konto och alla dina data från",
      FULL_DISCLAIMER:
        "Observera att det inte kommer att vara möjligt att öppna upp ditt konto igen, och du kommer därför att förlora tillgången till recept, eventuella Klubb Lina-poäng och tidigare orderhistorik. Vänligen notera att samtliga leveranser behöver vara betalda. Du får en bekräftelse via chatten när raderingsprocessen är påbörjad.",
      CONFIRM_DELETION: "Bekräfta radering",
      CANCEL: "Avbryt",
      DELETE_ACCOUNT: "Radera konto",
      YOUR_REQUEST_HAS_BEEN_RECEIVED:
        "Din begäran om att radera ditt konto har mottagits och du kommer att få en bekräftelse när raderingsprocessen är påbörjad.",
      SOMETHING_WENT_WRONG: "Något gick fel",
    },
    dk: {
      DELETE_ACCOUNT_AND_ALL_YOUR_DATA_FROM: "Slet konto og alle dine data fra",
      FULL_DISCLAIMER:
        "Bemærk venligst, at det ikke vil være muligt at åbne kontoen igen, og du vil derfor miste adgang til opskrifter og tidligere ordrehistorik. Bemærk at din konto ikke kan lukkes, hvis ikke alle leveringer er betalt. Du vil modtage en bekræftelse via chatten, når sletningsprocessen er startet.",
      CONFIRM_DELETION: "Bekræft sletning",
      CANCEL: "Fortryd",
      DELETE_ACCOUNT: "Slet konto",
      YOUR_REQUEST_HAS_BEEN_RECEIVED:
        "Din anmodning om at slette kontoen er modtaget, og du vil modtage en bekræftelse, når sletningsprocessen er startet.",
      SOMETHING_WENT_WRONG: "Noget gik galt",
    },
  } as const
)[language];
