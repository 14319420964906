/**
 * @desc    Transforms datestring from API into Date Object
 * @param   {String} dateStr - datestring to parse
 * @returns {Date} Date object
 */

export const parseTimeStringFromApi = (dateStr = "") => {
  const parsed = `${dateStr
    .replace(new RegExp("/", "g"), "-")
    .replace(" ", "T")}Z`;
  return new Date(parsed);
};
