import * as React from "react";
import { SVGProps } from "react";
const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 30L13.2513 30.6629C13.4812 30.9226 13.8294 31.0446 14.1712 30.9852C14.513 30.9258 14.7996 30.6935 14.9285 30.3714L14 30ZM12.1999 27.9667L12.9486 27.3038C12.7564 27.0867 12.4794 26.9637 12.1895 26.9667L12.1999 27.9667ZM9 28L8.07692 27.6154C7.94762 27.9257 7.98291 28.2803 8.17085 28.559C8.35879 28.8378 8.67424 29.0034 9.01042 28.9999L9 28ZM14.7487 29.3371L12.9486 27.3038L11.4511 28.6295L13.2513 30.6629L14.7487 29.3371ZM12.1895 26.9667L8.98958 27.0001L9.01042 28.9999L12.2103 28.9666L12.1895 26.9667ZM9.92308 28.3846L12.4231 22.3846L10.5769 21.6154L8.07692 27.6154L9.92308 28.3846ZM16.0715 22.1286L13.0715 29.6286L14.9285 30.3714L17.9285 22.8714L16.0715 22.1286Z"
      fill="currentColor"
    />
    <path
      d="M18 30L18.7071 30.7071C18.4741 30.9401 18.1414 31.0433 17.8174 30.9832C17.4935 30.923 17.22 30.7072 17.0862 30.4061L18 30ZM20 28L19.2929 27.2929C19.4804 27.1054 19.7348 27 20 27V28ZM23 28L23.9231 27.6154C24.0517 27.924 24.0175 28.2765 23.8321 28.5547C23.6466 28.8329 23.3344 29 23 29V28ZM17.2929 29.2929L19.2929 27.2929L20.7071 28.7071L18.7071 30.7071L17.2929 29.2929ZM20 27H23V29H20V27ZM22.0769 28.3846L19.5769 22.3846L21.4231 21.6154L23.9231 27.6154L22.0769 28.3846ZM16.9138 25.0939L18.9138 29.5939L17.0862 30.4061L15.0862 25.9061L16.9138 25.0939Z"
      fill="currentColor"
    />
    <path
      d="M26 13C26 18.5228 21.5228 23 16 23C10.4772 23 6 18.5228 6 13C6 7.47715 10.4772 3 16 3C21.5228 3 26 7.47715 26 13Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3555 13.4642L14.4518 16.7154L20.6444 10.2131"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default Success;
