import * as React from "react";
import { SVGProps } from "react";
const Email = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.9494 3.375H3.05056C2.19421 3.375 1.5 4.06921 1.5 4.92556C1.5 5.43738 1.75257 5.91624 2.17498 6.20525L7.19301 9.63864C7.84502 10.0847 8.17102 10.3078 8.52456 10.3943C8.83691 10.4708 9.16309 10.4708 9.47544 10.3943C9.82898 10.3078 10.155 10.0847 10.807 9.63864L15.825 6.20525C16.2474 5.91624 16.5 5.43738 16.5 4.92556C16.5 4.06921 15.8058 3.375 14.9494 3.375Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M16.125 6V11.8C16.125 12.9201 16.125 13.4802 15.907 13.908C15.7153 14.2843 15.4093 14.5903 15.033 14.782C14.6052 15 14.0451 15 12.925 15H5.075C3.95489 15 3.39484 15 2.96702 14.782C2.59069 14.5903 2.28473 14.2843 2.09299 13.908C1.875 13.4802 1.875 12.9201 1.875 11.8V6"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Email;
