import { SVGProps, useState } from "react";
import { Snackbar } from "../components";

interface RatingConsentDisclaimerProps {
  Title: () => React.ReactElement;
  Icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement | null;
  children: React.ReactNode;
}

const RatingConsentDisclaimer = ({
  Title,
  Icon,
  children,
}: RatingConsentDisclaimerProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleClose = () => setExpanded(false);

  return (
    <>
      <div className="flex justify-center md:justify-start">
        <button className="" onClick={() => setExpanded(!expanded)}>
          <div className="flex items-center text-sm ">
            {Icon && <Icon className="mr-2 h-4.5 w-4.5 text-primary" />}
            <Title />
          </div>
        </button>
      </div>
      <div className="grid grid-cols-2">
        <div className="mt-4 md:col-span-1 col-span-full">
          {expanded && (
            <Snackbar nudge={true} closeable={true} onClose={handleClose}>
              {children}
            </Snackbar>
          )}
        </div>
      </div>
    </>
  );
};

export default RatingConsentDisclaimer;
