import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  IDeliveryCategoryParams,
  IDeliveryCategoryResponse,
  DeliveryRatingArgs,
} from "./types";

export const deliveryApi = createApi({
  reducerPath: "delivery",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    rateDelivery: builder.mutation<null, DeliveryRatingArgs>({
      query: ({ orderId, rating, note, deliveryRatingCategoryId }) => ({
        url: "/delivery/rating",
        method: "POST",
        body: { order_id: orderId, rating, note, deliveryRatingCategoryId },
      }),
    }),
    fetchCategories: builder.query<
      IDeliveryCategoryResponse,
      IDeliveryCategoryParams
    >({
      query: ({ countryId }) => {
        return `/delivery/ratingCategories?countryId=${countryId}`;
      },
    }),
  }),
});

export const { useRateDeliveryMutation, useFetchCategoriesQuery } = deliveryApi;
