import clsx from "clsx";

import { isEqualStrings } from "@chef/utils/equal";

import { Chip } from "../components";
import { Language } from "../../types";
import ScrollableWrapper from "./ScrollableWrapper";

interface FilterNavigationProps<T extends number | string> {
  language: Language;
  items: {
    value: T;
    label: string;
    count: number;
    type: "default" | "highlighted" | "secondary";
    isAll: boolean;
  }[];
  value: T;
  onChange: (newValue: T) => void;

  showGradients?: boolean;
  leftClassName?: string;
  rightClassName?: string;
  className?: string;
}

const FilterNavigation = <T extends number | string>({
  language = "no",
  items = [],
  value,
  onChange = () => null,
  showGradients = true,
  leftClassName = "bg-gradient-to-r from-white via-white",
  rightClassName = "bg-gradient-to-l from-white via-white",
  className,
}: FilterNavigationProps<T>) => {
  const handleClick = (value: T) => {
    onChange(value);
  };

  if (!items || items.length === 0) {
    return (
      <div className="flex space-x-2">
        {[...Array(3)].map((i) => (
          <Chip className="w-20 animate-pulse" key={i} />
        ))}
      </div>
    );
  }

  const vals = value
    .toString()
    .split(",")
    .filter((v) => v !== "");

  return (
    <ScrollableWrapper
      leftClassName={leftClassName}
      rightClassName={rightClassName}
      showGradients={showGradients}
      language={language}
    >
      <ul
        className={clsx("relative flex gap-2", className)}
        id="filter-navigate"
      >
        {items.map((item) => {
          const isHighlighted = item.type === "highlighted";

          const isSelected = vals.some((v) =>
            isEqualStrings(v, item.value.toString()),
          );

          return (
            <li key={item.value}>
              <Chip
                onClick={() => handleClick(item.value)}
                selected={isSelected}
                highlighted={isHighlighted}
                className="tabular-nums"
                id={item.value.toString()}
              >
                <span>{item.label}</span>
                {(!isSelected || item.isAll) && (
                  <span className="ml-2">({item.count})</span>
                )}
              </Chip>
            </li>
          );
        })}
      </ul>
    </ScrollableWrapper>
  );
};

export default FilterNavigation;
