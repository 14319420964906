import dynamic from "next/dynamic";

export const convertToClientSideOnlyComponent = <
  C extends React.FunctionComponent<any>,
>(
  Component: C,
  options?: Parameters<typeof dynamic>[1],
) => {
  return dynamic(async () => Component, {
    ssr: false,
    ...options,
  }) as C;
};
