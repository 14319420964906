import * as React from "react";
import { SVGProps } from "react";
const Phone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6889 11.5157C13.6889 11.5157 13.1735 11.0601 12.4763 11.4549L10.718 12.4269C10.718 12.4269 8.99007 11.0601 8.01999 9.99706C6.95896 9.02513 5.59479 7.2939 5.59479 7.2939L6.56487 5.53229C6.95896 4.80335 6.50424 4.31739 6.50424 4.31739L4.56408 1.40162C4.23061 0.91566 3.8062 1.00678 3.8062 1.00678C3.23022 1.00678 1.65384 2.46466 1.65384 2.46466C0.83533 3.1936 1.01722 3.8618 1.01722 3.8618C1.56289 7.81023 5.71605 11.6068 6.04951 11.9409C6.47392 12.3357 10.1724 16.4057 14.1133 16.9827C14.1133 16.9827 14.7499 17.165 15.5078 16.3449C15.5078 16.3449 16.9932 14.7655 16.9932 14.2492C16.9932 14.2492 17.0842 13.824 16.5991 13.4899L13.6889 11.5157Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default Phone;
