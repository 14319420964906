import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      SHOW_WINE_TIPS: "Se vintips",
      HIDE_WINE_TIPS: "Skjul vintips",
    },
    se: {
      SHOW_WINE_TIPS: "Visa vintips",
      HIDE_WINE_TIPS: "Dölj vintips",
    },
    dk: {
      SHOW_WINE_TIPS: "Se vinforslag",
      HIDE_WINE_TIPS: "Skjul vinforslag",
    },
  } as const
)[language];
