export const PREFERENCE_TYPE_IDS = {
  CONCEPT: "009cf63e-6e84-446c-9ce4-afdbb6bb9687",
  TASTE: "4c679266-7dc0-4a8e-b72d-e9bb8dadc7eb",
  OPTIMIZATION: "56275ca4-5ba6-49aa-8ad9-cd33a6f9564a",
  SELECTOR: "5F901982-12C7-4D44-9D50-F7D1F19BA09D",
} as const;

// TODO: Onesub migration: Does not need Onesub in the name
export const PREFERENCE_IDS = {
  SINGEL_ONESUB: "37ce056f-4779-4593-949a-42478734f747",
  VEGETARIAN_ONESUB: "6a494593-2931-4269-80ee-470d38f04796",
  CHEF_CHOICE: "c94bcc7e-c023-40ce-81e0-c34da3d79545",
} as const;

export const TASTE_PREFERENCE_SELETION_LIMITS = {
  SOFT_LIMIT: 3,
  STRONG_LIMIT: 6,
  MAX_LIMIT: 9,
} as const;

export const VEGETARIAN_TASTE_PREFERENCE_ID =
  "870c7cea-9d06-4f3e-9c9b-c2c395f5e4f5" as const;

export const VEGETARIAN_CONCEPT_PREFERENCE_ID =
  "6a494593-2931-4269-80ee-470d38f04796" as const;

export const VEGETARIAN_TASTE_PREFERENCE_IDS = {
  PORK: "1c936351-eb0f-4815-95f7-0c942cea6cc3",
  CHICKEN: "9eeb4f91-6402-4247-a9e5-39bd8cde5526",
  BEEF: "03d80b36-29da-4b65-8220-75a32f419593",
  LAMB: "ff9f03be-c0eb-4d79-bf40-0659aedd3b89",
  SHELLFISH: "95217648-0ca2-4e7b-a849-52d70eb7bc17",
  FISH: "82e6f0c1-0640-498e-8b15-6c8c5dca5336",
  EGG: "9ad1019d-a43d-4640-9034-d8b7deb02d0e",
  DAIRY: "54555183-10ba-40e2-9408-adb878b0ac82",
} as const;
