// This category constant is duplicated in the standalone. So if you change it here, change it there too.Should be reafctored.
const loyaltyBenefitCategory = {
  DISCOUNT_COUPONS: "discountCoupons",
  MEMBERSHOP_ACCESS: "membershopAccess",
  PROMOTIONAL_PRICES: "promotionalPrices",
  SEASONAL_SALES_PRIORITY: "seasonalSalesPriority",
  EXTRA_CLUB_POINTS: "extraClubPoints",
  REFERRAL_REWARDS: "referralRewards",
  FREE_SHIPPING: "freeShipping",
  MULTIPLIER: "multiplier",
  TAF_BOOST: "tellAFriendBoost",
} as const;

export const benefits = [
  {
    level: 1,
    benefits: [
      {
        name: loyaltyBenefitCategory.DISCOUNT_COUPONS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.MEMBERSHOP_ACCESS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.PROMOTIONAL_PRICES,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.SEASONAL_SALES_PRIORITY,
        value: false,
      },
      {
        name: loyaltyBenefitCategory.EXTRA_CLUB_POINTS,
        value: false,
      },
      {
        name: loyaltyBenefitCategory.REFERRAL_REWARDS,
        value: false,
      },
      {
        name: loyaltyBenefitCategory.FREE_SHIPPING,
        value: false,
      },
      {
        id: "multiplier",
        name: loyaltyBenefitCategory.MULTIPLIER,
        value: 1,
      },
      {
        name: loyaltyBenefitCategory.TAF_BOOST,
        value: 0,
      },
    ],
  },
  {
    level: 2,
    benefits: [
      {
        name: loyaltyBenefitCategory.DISCOUNT_COUPONS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.MEMBERSHOP_ACCESS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.PROMOTIONAL_PRICES,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.SEASONAL_SALES_PRIORITY,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.EXTRA_CLUB_POINTS,
        value: false,
      },
      {
        name: loyaltyBenefitCategory.REFERRAL_REWARDS,
        value: false,
      },
      {
        name: loyaltyBenefitCategory.FREE_SHIPPING,
        value: false,
      },
      {
        id: "multiplier",
        name: loyaltyBenefitCategory.MULTIPLIER,
        value: 1,
      },
      {
        name: loyaltyBenefitCategory.TAF_BOOST,
        value: 0,
      },
    ],
  },
  {
    level: 3,
    benefits: [
      {
        name: loyaltyBenefitCategory.DISCOUNT_COUPONS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.MEMBERSHOP_ACCESS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.PROMOTIONAL_PRICES,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.SEASONAL_SALES_PRIORITY,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.EXTRA_CLUB_POINTS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.REFERRAL_REWARDS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.FREE_SHIPPING,
        value: false,
      },
      {
        id: "multiplier",
        name: loyaltyBenefitCategory.MULTIPLIER,
        value: 1.5,
      },
      {
        id: "taf_boost_1",
        name: loyaltyBenefitCategory.TAF_BOOST,
        value: 0,
      },
    ],
  },
  {
    level: 4,
    benefits: [
      {
        name: loyaltyBenefitCategory.DISCOUNT_COUPONS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.MEMBERSHOP_ACCESS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.PROMOTIONAL_PRICES,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.SEASONAL_SALES_PRIORITY,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.EXTRA_CLUB_POINTS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.REFERRAL_REWARDS,
        value: true,
      },
      {
        name: loyaltyBenefitCategory.FREE_SHIPPING,
        value: true,
      },
      {
        id: "multiplier",
        name: loyaltyBenefitCategory.MULTIPLIER,
        value: 1.5,
      },
      {
        id: "taf_boost_2",
        name: loyaltyBenefitCategory.TAF_BOOST,
        value: 0,
      },
    ],
  },
];
