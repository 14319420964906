import * as React from "react";
import { SVGProps } from "react";
const Gift = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 12.75V23.25H3V12.75H21ZM1.5 8.25H22.5V12.75H1.5V8.25Z"
      stroke="#15193A"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.9994 8.25008V23.2501V8.25008ZM14.9994 8.25008V23.2501V8.25008ZM9.82441 1.87508C9.55711 1.51312 9.20581 1.22158 8.80077 1.02559C8.39573 0.829608 7.94911 0.735055 7.4994 0.750084C6.88977 0.749436 6.2944 0.934535 5.7926 1.28073C5.2908 1.62693 4.90638 2.11778 4.69054 2.68793C4.4747 3.25808 4.43768 3.88046 4.58441 4.47217C4.73115 5.06388 5.05468 5.59685 5.5119 6.00008C7.0869 7.38758 11.9994 8.25008 11.9994 8.25008C11.9994 6.00008 10.7244 2.88758 9.82441 1.87508ZM14.1744 1.87508C14.4417 1.51312 14.793 1.22158 15.198 1.02559C15.6031 0.829608 16.0497 0.735055 16.4994 0.750084C17.109 0.749436 17.7044 0.934535 18.2062 1.28073C18.708 1.62693 19.0924 2.11778 19.3083 2.68793C19.5241 3.25808 19.5611 3.88046 19.4144 4.47217C19.2677 5.06388 18.9441 5.59685 18.4869 6.00008C16.9119 7.38758 11.9994 8.25008 11.9994 8.25008C11.9994 6.00008 13.2744 2.88758 14.1744 1.87508Z"
      stroke="#15193A"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Gift;
