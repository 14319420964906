import React, { HTMLAttributes, createElement } from "react";
import clsx from "clsx";

type BarType = HTMLAnchorElement | HTMLDivElement;
interface BarProps extends HTMLAttributes<BarType> {
  href?: string;
  className?: string;
  children: React.ReactNode;
  id?: string;
}

export const Bar = ({ href, children, className, id, ...props }: BarProps) => {
  return createElement(
    href ? "a" : "div",
    {
      href,

      className: clsx(
        "flex justify-between bg-highlight px-4 py-3 items-center",
        className,
      ),
      id,
      ...props,
    },
    children,
  );
};
