import { intl } from "./utils";

export const CATEGORIES = [
  {
    key: "essential",
    name: intl.CONSENT_SETTINGS_CATEGORY_ESSENTIAL_NAME,
    description: intl.CONSENT_SETTINGS_CATEGORY_ESSENTIAL_DESCRIPTION,
    types: [],
    forced: true,
  },
  {
    key: "functional",
    name: intl.CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_NAME,
    description: intl.CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_DESCRIPTION,
    types: [
      "CRM",
      "Customer Success",
      "Deep Linking",
      "Helpdesk",
      "Livechat",
      "Performance Monitoring",
      "Personalization",
      "SMS & Push Notifications",
      "Security & Fraud",
    ],
    forced: false,
  },
  {
    key: "advertising",
    name: intl.CONSENT_SETTINGS_CATEGORY_ADVERTISING_NAME,
    description: intl.CONSENT_SETTINGS_CATEGORY_ADVERTISING_DESCRIPTION,
    types: ["Advertising", "Tag Managers"],
    forced: false,
  },
  {
    key: "marketing",
    name: intl.CONSENT_SETTINGS_CATEGORY_MARKETING_NAME,
    description: intl.CONSENT_SETTINGS_CATEGORY_MARKETING_DESCRIPTION,
    types: [
      "A/B Testing",
      "Analytics",
      "Attribution",
      "Email",
      "Enrichment",
      "Heatmaps & Recordings",
      "Raw Data",
      "Realtime Dashboards",
      "Referrals",
      "Surveys",
      "Video",
      "Other",
    ],
    forced: false,
  },
];
