import * as React from "react";
import { SVGProps } from "react";
const Delivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26 19H28L27.5 13.5L25 13L24 9H9.00001L8 19H10M21.5 19H14.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={10}
      y={17}
      width={4}
      height={4}
      rx={2}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={22}
      y={17}
      width={4}
      height={4}
      rx={2}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.24998 14.5C5.6642 14.5 5.99999 14.1642 5.99999 13.75C6 13.3358 5.66421 13 5.25 13C4.83579 13 4.5 13.3358 4.49999 13.75C4.49999 14.1642 4.83577 14.5 5.24998 14.5Z"
      fill="currentColor"
    />
    <path
      d="M2.75001 16.25C3.16423 16.25 3.50001 15.9142 3.50001 15.5C3.5 15.0858 3.16421 14.75 2.75 14.75C2.33579 14.75 2 15.0858 2.00001 15.5C2.00001 15.9142 2.3358 16.25 2.75001 16.25Z"
      fill="currentColor"
    />
    <path
      d="M5.75002 17C6.16423 17 6.50001 16.6642 6.50001 16.25C6.5 15.8358 6.16421 15.5 5.75 15.5C5.33579 15.5 5 15.8358 5.00001 16.25C5.00001 16.6642 5.3358 17 5.75002 17Z"
      fill="currentColor"
    />
  </svg>
);
export default Delivery;
