import * as React from "react";
import { SVGProps } from "react";
const Konto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.98887 17C13.3065 17 16.0765 14.7553 16.8469 11.7224C16.8817 11.5856 17.0187 11.5018 17.1579 11.5255C17.2454 11.5404 17.3354 11.5504 17.4252 11.5504C18.3022 11.5504 18.9999 10.8592 18.9999 9.99042C18.9999 9.12159 18.2823 8.43047 17.4252 8.43047C17.344 8.43047 17.2628 8.43857 17.1834 8.45292C17.034 8.47993 16.878 8.39677 16.8392 8.25003C16.0418 5.23125 13.2968 3 10.0087 3H10.011C6.7229 3 3.96029 5.23126 3.16183 8.25004C3.12302 8.39677 2.96694 8.48001 2.81757 8.45303C2.73778 8.43861 2.65614 8.43047 2.57474 8.43047C1.7176 8.43047 1 9.12159 1 9.99042C1 10.8592 1.69767 11.5504 2.57474 11.5504C2.66446 11.5504 2.75445 11.5404 2.84195 11.5255C2.9811 11.5019 3.11817 11.5856 3.15292 11.7224C3.92334 14.7553 6.69334 17 10.011 17"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 12C11.6782 12.439 10.9425 13 10 13C9.05747 13 8.34483 12.4634 8 12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M8 8V9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 8V9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
export default Konto;
