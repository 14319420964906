import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { BRAND_PATHS, language } from "@chef/constants";

import { authApi, showNotification, hideNotification } from "../features";

const intl = {
  no: {
    REGISTRATION_FAILED: "Registrering feilet",
    YOUR_REGISTRATION_FAILED_CONTACT_CUSTOMER_SERVICE:
      "Din registrering feilet. Ta kontakt med kundeservice.",
    CUSTOMER_SERVICE: "Kundeservice",
    CREATING_ACCOUNT: "Oppretter konto...",
    ACCOUNT_CREATED: "Konto opprettet",
  },
  se: {
    REGISTRATION_FAILED: "Registrering misslyckades",
    YOUR_REGISTRATION_FAILED_CONTACT_CUSTOMER_SERVICE:
      "Din registrering misslyckades. Kontakta kundtjänst.",
    CUSTOMER_SERVICE: "Kundtjänst",
    CREATING_ACCOUNT: "Skapar konto...",
    ACCOUNT_CREATED: "Konto skapad",
  },
  dk: {
    REGISTRATION_FAILED: "Registrering mislykkedes",
    YOUR_REGISTRATION_FAILED_CONTACT_CUSTOMER_SERVICE:
      "Din registrering mislykkedes. Kontakt kundeservice.",
    CUSTOMER_SERVICE: "Kundeservice",
    CREATING_ACCOUNT: "Opretter konto...",
    ACCOUNT_CREATED: "Konto oprettet",
  },
}[language];

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    // matcher: authApi.endpoints.registrerUser.matchRejected,
    matcher: isAnyOf(
      authApi.endpoints.registrerUser.matchRejected,
      authApi.endpoints.registrerUser.matchPending,
      authApi.endpoints.registrerUser.matchFulfilled,
    ),
    effect: async (action) => {
      hideNotification("creating-account");

      const arg = { ...action["meta"].arg.originalArgs };

      // Censor the password and personNumber
      arg.password = "*".repeat(arg.password.length);
      arg.personNumber = "*".repeat(arg.personNumber?.length || 0);

      if (action["meta"].requestStatus === "rejected") {
        window.DD_RUM?.addError(
          new Error("Registration call failed", { cause: action["error"]! }),
          { state: arg },
        );

        showNotification({
          message: intl.YOUR_REGISTRATION_FAILED_CONTACT_CUSTOMER_SERVICE,
          title: intl.REGISTRATION_FAILED,
          type: "error",
          links: [
            {
              label: intl.CUSTOMER_SERVICE,
              href: BRAND_PATHS.CONTACT_US_PAGE_LOGOUT,
            },
          ],
        });
      }

      if (action["meta"].requestStatus === "pending") {
        showNotification({
          message: intl.CREATING_ACCOUNT,
          loading: true,
          autoClose: false,
          id: "creating-account",
        });
      }

      if (action["meta"].requestStatus === "fulfilled") {
        hideNotification("creating-account");

        showNotification({
          message: intl.ACCOUNT_CREATED,
          type: "success",
        });
      }
    },
  });
