import { useLoyalty } from "@chef/feature-flags";
import { CalendarQuery } from "@chef/state-management";
import {
  getBasketFinances,
  getBasketForCalendarWeek,
} from "@chef/state-management/helpers";
import { useCalendarData } from "@chef/state-management/hooks";

export const useFinancesForCalendarWeek = ({
  calendarWeek,
}: {
  calendarWeek: CalendarQuery["calendar"][number];
}) => {
  const { basket } = useCalendarData({
    week: calendarWeek.week,
    year: calendarWeek.year,
  });

  const { data: loyaltyData } = useLoyalty();

  return getBasketFinances({
    basketProducts: basket,
    customerFee: getBasketForCalendarWeek({ calendarWeek })?.shippingDetails
      .plannedDeliveryInformation.customerFee,
    loyaltyLevel: loyaltyData?.currentLevel,
  });
};
