import React from "react";
import clsx from "clsx";

import { noop } from "@chef/utils/noop";

import { Currency } from "../components";
import { Language } from "../../types";

interface ReceiptLineProps {
  as?: React.ElementType;
  isIndented?: boolean;
  Icon?: JSX.Element;
  iconLabel?: string;
  onIconClick?: () => void;
  Image?: (props: { className?: string }) => JSX.Element;
  title?: string;
  price?: number;
  hasAdditionalCost?: boolean;
  priceIsDiscounted?: boolean;
  priceIsHighlighted?: boolean;
  priceIsCurrency?: boolean;
  hidePriceIfZero?: boolean;
  prefix?: string;
  className?: string;
  children?: React.ReactNode;
  language?: Language;
  isDiscount?: boolean;
}

const ReceiptLine = ({
  as = "li",
  isIndented = false,
  Icon,
  iconLabel = "",
  onIconClick = noop,
  Image,
  title = "",
  price = 0,
  hasAdditionalCost = false,
  priceIsDiscounted = false,
  priceIsHighlighted = false,
  priceIsCurrency = true,
  hidePriceIfZero = true,
  prefix = "",
  className = "",
  children,
  isDiscount = false,
  language = "no",
}: ReceiptLineProps) => {
  const isPriceNegative = price < 0;

  let currencyPrefix = "";

  if (hasAdditionalCost && !isPriceNegative) {
    currencyPrefix = "+";
  }

  if (isDiscount || isPriceNegative) {
    currencyPrefix = "-";
  }

  const c = (
    <>
      <div className="flex items-center flex-grow">
        {(Icon !== undefined || isIndented) && (
          <div className="flex items-center justify-center w-8 mr-3 shrink-0">
            {onIconClick !== noop ? (
              <button
                onClick={onIconClick}
                aria-label={iconLabel}
                className="a11y-focus:focus-ring"
              >
                {Icon}
              </button>
            ) : (
              Icon
            )}
          </div>
        )}

        {Image && (
          <Image className="object-cover w-12 h-12 mr-3 shrink-0 lg:hidden" />
        )}

        <div
          className="flex-grow mr-3 text-left break-normal line-clamp-2"
          id="order-details-card"
          title={`${prefix}${title}`}
        >
          <strong>{prefix}</strong>
          {children}
        </div>
      </div>

      {!(hidePriceIfZero && price === 0) && (
        <div
          className={clsx(
            "text-sm min-w-[9ch] text-right",
            priceIsDiscounted && "text-discount",
            priceIsHighlighted &&
              "flex items-center justify-center bg-highlight text-sm rounded-px font-medium th-lmk:text-sm th-lmk:font-semibold px-1.5 h-6",
          )}
        >
          {priceIsCurrency ? (
            <Currency language={language} prefix={currencyPrefix}>
              {price}
            </Currency>
          ) : (
            <span>{price}%</span>
          )}
        </div>
      )}
    </>
  );

  return React.createElement(
    as,
    { className: clsx("flex items-center grow", className) },
    c,
  );
};

export default ReceiptLine;
