import React, {
  createContext,
  createElement,
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  SVGProps,
  useContext,
  useState,
} from "react";

import clsx from "clsx";

import { Disclosure } from "@headlessui/react";
import { ChevronDown, ChevronUp, Download } from "@chef/icons/small";

import { ButtonCircle, Card, PriceTag } from "../components";

import { Language } from "../../types";
import ReceiptLoader from "../internals/ReceiptLoader";

interface ReceiptDetailsContextInterface {
  hideDetails: boolean;
  setHideDetails: Dispatch<SetStateAction<boolean>>;
  hideDetailsButton: boolean;
}

const ReceiptDetailsContext = createContext<ReceiptDetailsContextInterface>({
  hideDetails: false,
  setHideDetails: () => false,
  hideDetailsButton: false,
});

const intl = {
  no: {
    RECEIPT: "Kvittering",
    DOWNLOAD_RECEIPT: "Last ned kvittering",
    HIDE_DETAILS: "Skjul detaljer",
    SHOW_DETAILS: "Vis detaljer",
    MAX_DISHES_CHOSEN: "Max antall middager valgt",
    ADD_X_MORE_DISHES: (num: number) =>
      `Velg minst ${num} ${num === 1 ? "rett" : "retter"} til for å gå videre`,
    ORDER_GIVE_YOU: `Bestillingen gir deg`,
    POINTS: "poeng",
  },
  se: {
    RECEIPT: "Kvitto",
    DOWNLOAD_RECEIPT: "Ladda ner kvitto",
    HIDE_DETAILS: "Skjul detaljer",
    SHOW_DETAILS: "Vis detaljer",
    MAX_DISHES_CHOSEN: "Max antal middagar valda",
    ADD_X_MORE_DISHES: (num: number) =>
      `Välj minst ${num} ${
        num === 1 ? "maträtt" : "maträtter"
      } till för att gå vidare`,
    ORDER_GIVE_YOU: `Beställningen ger dig`,
    POINTS: "poäng",
  },
  dk: {
    RECEIPT: "Kvittering",
    DOWNLOAD_RECEIPT: "Download kvittering",
    HIDE_DETAILS: "Skjul detaljer",
    SHOW_DETAILS: "Vis detaljer",
    MAX_DISHES_CHOSEN: "Max antal retter valgt",
    ADD_X_MORE_DISHES: (num: number) =>
      `Vælg mindst ${num} ${num === 1 ? "ret" : "retter"} til for at fortsætte`,
    ORDER_GIVE_YOU: `Bestillingen giver dig`,
    POINTS: "point",
  },
};

interface ReceiptDetailsButtonProps {
  language: Language;
}

const ReceiptDetailsButton = ({
  language = "no",
}: ReceiptDetailsButtonProps) => {
  const { HIDE_DETAILS, SHOW_DETAILS } = intl[language];

  const { hideDetails, setHideDetails } = useContext(ReceiptDetailsContext);

  return (
    <button className="underline" onClick={() => setHideDetails(!hideDetails)}>
      {hideDetails ? SHOW_DETAILS : HIDE_DETAILS}
    </button>
  );
};

type DownloadIconButtonProps = {
  language: Language;
} & (
  | {
      onClick: () => void;
      href?: never;
      download?: never;
    }
  | {
      onClick?: never;
      download: string;
      href: string;
    }
);

const DownloadIconButton = ({
  language = "no",
  onClick,
  href,
}: DownloadIconButtonProps) => {
  const { DOWNLOAD_RECEIPT } = intl[language];

  if (href) {
    return (
      <a href={href} className="flex items-center space-x-2">
        <ButtonCircle Icon={Download} />
        <span>{DOWNLOAD_RECEIPT}</span>
      </a>
    );
  }

  return (
    <button className="flex items-center space-x-2" onClick={onClick}>
      <ButtonCircle Icon={Download} />
      <span>{DOWNLOAD_RECEIPT}</span>
    </button>
  );
};

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  language: Language;
}

const Header = ({ children, className, language = "no" }: HeaderProps) => {
  const { hideDetailsButton } = useContext(ReceiptDetailsContext);

  return (
    <>
      <div className={className}>{children}</div>
      {!hideDetailsButton && <ReceiptDetailsButton language={language} />}
    </>
  );
};
interface TitleProps {
  language: Language;
  children?: React.ReactNode;
  as?: "h1" | "h2" | "h3" | "p";
}

const Title = ({ children, as, language = "no" }: TitleProps) => {
  const { RECEIPT } = intl[language];

  const Element = as || "p";

  return (
    <Element className="text-lg">
      <strong>{children || RECEIPT}</strong>
    </Element>
  );
};

interface MealsProps extends HTMLAttributes<keyof React.ReactHTML> {
  language: Language;
  as?: keyof React.ReactHTML;
  numDishes: number;
  minCount?: number;
  maxCount?: number;
}

const Meals = ({
  as = "ul",
  children,
  numDishes,
  minCount,
  maxCount,
  className,
  language = "no",
}: MealsProps) => {
  const { MAX_DISHES_CHOSEN, ADD_X_MORE_DISHES } = intl[language];

  let countIsMax;
  let countIsLessThanMin;
  if (maxCount) {
    countIsMax = numDishes >= maxCount;
  }
  if (minCount) {
    countIsLessThanMin = numDishes < minCount;
  }

  const c = (
    <>
      {children}
      {maxCount && countIsMax && (
        <Line className="text-sm">
          <strong>{MAX_DISHES_CHOSEN}</strong>
        </Line>
      )}
      {minCount && countIsLessThanMin && (
        <Line className="text-sm text-error">
          <strong>{ADD_X_MORE_DISHES(minCount - numDishes)}</strong>
        </Line>
      )}
    </>
  );

  return createElement(as, {
    className: clsx("flex flex-col", className),
    children: c,
  });
};

const LineImage = ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className="object-cover w-12 h-12 mr-3 shrink-0 lg:hidden" {...props}>
    {children}
  </div>
);
interface LineTextProps extends HTMLAttributes<HTMLDivElement> {
  prefix?: string;
}

const LineText = ({ children, prefix, ...props }: LineTextProps) => (
  <div className="w-full text-left grow" {...props}>
    <strong>{prefix}</strong>
    {children}
  </div>
);

interface LineIconProps extends HTMLAttributes<HTMLDivElement> {
  iconLabel?: string;
  onClick?: () => void;
}

const LineIcon = ({
  onClick,
  iconLabel,
  children,
  ...props
}: LineIconProps) => {
  return (
    <div
      className="flex items-center justify-center w-8 mr-2 shrink-0"
      {...props}
    >
      {onClick ? (
        <button
          onClick={onClick}
          aria-label={iconLabel}
          className="a11y-focus:focus-ring"
        >
          {children}
        </button>
      ) : (
        children
      )}
    </div>
  );
};
interface LineProps extends HTMLAttributes<keyof React.ReactHTML> {
  as?: keyof React.ReactHTML;
}

const Line = ({ as = "li", className, children }: LineProps) => {
  return createElement(
    as,
    {
      className: clsx("flex w-full grow", className),
    },
    children,
  );
};

interface SectionProps extends HTMLAttributes<typeof Disclosure> {
  Icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement | null;
  text?: string;
  price?: number;
  isPercentage?: boolean;
  isDiscounted?: boolean;
  isHighlighted?: boolean;
  hideChevron?: boolean;
  language?: Language;
}

const Section = ({
  Icon,
  text = "",
  price,
  isPercentage,
  isDiscounted,
  isHighlighted,
  hideChevron = false,
  className,
  children,
  language = "no",
}: SectionProps) => {
  return (
    <Disclosure>
      <Disclosure.Button
        className={clsx("w-full a11y-focus:focus-ring", className)}
      >
        {({ open }) => (
          <Receipt.Line>
            {Icon && (
              <Receipt.LineIcon>
                <Icon />
              </Receipt.LineIcon>
            )}

            <Receipt.LineText className="mr-3 truncate grow">
              <div className="flex items-center">
                <span className="mr-4 truncate">{text}</span>
                {!hideChevron && (
                  <span className="text-xs">
                    {open ? <ChevronUp /> : <ChevronDown />}
                  </span>
                )}
              </div>
            </Receipt.LineText>

            <PriceTag
              language={language}
              className="justify-end"
              isDiscounted={isDiscounted}
              isHighlighted={isHighlighted}
              hideIfZero
              isPercentage={isPercentage}
            >
              {price || 0}
            </PriceTag>
          </Receipt.Line>
        )}
      </Disclosure.Button>
      <Disclosure.Panel className="mt-4">{children}</Disclosure.Panel>
    </Disclosure>
  );
};

interface LoyaltyProps extends HTMLAttributes<HTMLDivElement> {
  points: number | undefined;
  className?: string;
  language: Language;
}
const LoyaltyPoints = ({
  points,
  className,
  language = "no",
}: LoyaltyProps) => {
  const { ORDER_GIVE_YOU, POINTS } = intl[language];
  if (!points) {
    return null;
  }
  return (
    <div className={clsx("flex content-center", className)}>
      <div className="w-4 h-4 bg-primary">PH</div>{" "}
      {/* PH TODO: insert correct badge*/}
      <span className="pl-2 text-sm">
        {ORDER_GIVE_YOU}{" "}
        <strong>
          {points} {POINTS}
        </strong>
      </span>
    </div>
  );
};

interface ReceiptProps extends Omit<HTMLAttributes<typeof Card>, "children"> {
  children:
    | React.ReactNode
    | (({ hideDetails }: { hideDetails: boolean }) => React.ReactNode);
  isLoading?: boolean;
  hideDetailsButton?: boolean;
  defaultHideDetails?: boolean;
  className?: string;
}

const Receipt = ({
  className,
  children,
  isLoading,
  hideDetailsButton = false,
  defaultHideDetails = false,
}: ReceiptProps) => {
  const [hideDetails, setHideDetails] = useState(defaultHideDetails);

  if (isLoading) {
    return <ReceiptLoader />;
  }

  return (
    <Card className={className}>
      <ReceiptDetailsContext.Provider
        value={{ hideDetails, setHideDetails, hideDetailsButton }}
      >
        {typeof children === "function" ? children({ hideDetails }) : children}
      </ReceiptDetailsContext.Provider>
    </Card>
  );
};

Receipt.Header = Header;
Receipt.Title = Title;
Receipt.Meals = Meals;
Receipt.DownloadIconButton = DownloadIconButton;
Receipt.LineIcon = LineIcon;
Receipt.LineImage = LineImage;
Receipt.LineText = LineText;
Receipt.Line = Line;
Receipt.Section = Section;
Receipt.LoyaltyPoints = LoyaltyPoints;

export default Receipt;
