import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { IDestination, IDestinationArgs } from "./types";
import { query } from "../../graphql/baseApi";

export const trackingDestinationsApi = createApi({
  reducerPath: "trackingDestinations",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://cdn.segment.com",
  }),
  endpoints: (builder) => ({
    getTrackingDestinations: builder.query<IDestination[], IDestinationArgs>({
      query: ({ writeKey }) => `/v1/projects/${writeKey}/integrations`,
    }),

    sendCustomerIOLinkId: builder.mutation<void, string>({
      // the type of "query" is weird, so we purposely ignore it here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: (link_id, api, extra) => {
        return query(`/trck/customerio/${link_id}`, api, extra);
      },
    }),
  }),
});

export const {
  useGetTrackingDestinationsQuery,
  useSendCustomerIOLinkIdMutation,
} = trackingDestinationsApi;
