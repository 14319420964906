import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      WE_CANNOT_DELIVER_HERE: "Vi beklager, vi leverer ikke til din adresse.",
      CONTACT_CUSTOMER_SERVICE: "kontakt kundeservice",
      FOR_MORE_INFORMATION: "for mer informasjon.",
      REGISTER_ADDRESS: "Registrering av adresse",
    },
    se: {
      WE_CANNOT_DELIVER_HERE: "Vi beklagar, vi levererar inte till din adress.",
      CONTACT_CUSTOMER_SERVICE: "kontakta kundservice",
      FOR_MORE_INFORMATION: "för mer information.",
      REGISTER_ADDRESS: "Adress",
    },
    dk: {
      WE_CANNOT_DELIVER_HERE:
        "Beklager! Vi leverer desværre ikke til din adresse",
      CONTACT_CUSTOMER_SERVICE: "kontakta kundservice",
      FOR_MORE_INFORMATION: "For mere information.",
      REGISTER_ADDRESS: "Adresse",
    },
  } as const
)[language];
