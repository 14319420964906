import { Button, Modal } from "@chef/components";
import { ExitDoor } from "@chef/icons/symbol";
import { intl } from "./LeaveWithoutSavingModal.Intl";

interface LeaveWithoutSavingModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const LeaveWithoutSavingModal = ({
  show,
  onClose,
  onConfirm,
}: LeaveWithoutSavingModalProps) => {
  return (
    <Modal
      outerClassName="flex h-full justify-center items-center mx-6"
      innerClassName="bg-white"
      showClose={false}
      show={show}
      onClose={onClose}
    >
      <div className="px-6 pb-6 pt-22 lg:pt-18 lg:px-22 lg:pb-20">
        <div className="max-w-lg mx-4 mb-8 text-center ">
          <ExitDoor className="mb-6 w-36 h-36 lg:w-20 lg:h-20" />
          <h2>
            <strong>{intl.CHANGES_HAVE_NOT_BEEN_SAVED}</strong>
          </h2>
          <p className="mt-2 text-sm ">
            {intl.IF_YOU_LEAVE_CHANGES_WILL_NOT_BE_SAVED}
          </p>
        </div>
        <div className="flex flex-col max-w-xs mx-auto">
          <Button
            id="go-forward-without-saving-modal-button"
            className="mb-4"
            primary
            onClick={onConfirm}
          >
            {intl.GO_FORWARD_WITHOUT_SAVING_BUTTON}
          </Button>
          <Button id="stay-on-page-modal-button" outlined onClick={onClose}>
            {intl.STAY_BUTTON}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
