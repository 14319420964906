import clsx from "clsx";
import { Dialog } from "@headlessui/react";
import { forwardRef, useRef } from "react";

import { Close } from "@chef/icons/small";

import { Button } from "../Button";
import { PropsOf } from "../../../types";

interface ModalProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Omit<PropsOf<typeof Dialog>, "unmount"> {
  show: boolean;
  showClose?: boolean;
  closePlacement?: "floating" | "corner";
  closeText?: string;
  onClose: () => void;
  outerClassName?: string;
  innerClassName?: string;
  children?: React.ReactNode;
}

/**
//  * @deprecated use Modal from `@chef/smart-components` instead
 */
export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      show,
      showClose = true,
      closePlacement = "floating",
      closeText = "Lukk",
      onClose,
      outerClassName = "",
      innerClassName = "",
      children,
      ...props
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);

    if (!show) {
      return null;
    }

    return (
      <Dialog
        {...props}
        open={show}
        onClose={onClose}
        className="fixed inset-0 z-40 overflow-auto"
        ref={ref}
      >
        <div className={clsx("max-h-full", outerClassName)}>
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm" />

          {showClose && closePlacement === "floating" && (
            <div className="mt-4 mb-4 text-center md:mt-24 md:mb-10">
              <Button
                IconLeft={Close}
                onClick={onClose}
                className="!bg-white !rounded-full"
                small
              >
                {closeText}
              </Button>
            </div>
          )}

          <div ref={containerRef} className={clsx("relative", innerClassName)}>
            {children}
            {showClose && closePlacement === "corner" && (
              <div className="absolute top-0 right-0">
                <button
                  className="w-10 h-10 rounded-md hover:bg-grey-3"
                  onClick={onClose}
                >
                  <Close />
                </button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  },
);
