import * as React from "react";
import { SVGProps } from "react";
const Swap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.04486 15.1877L1.87469 13.9669L3.13762 14.095L2.04486 15.1877Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M15.7601 5.00132C14.8338 3.84152 13.6026 2.96292 12.2046 2.46418C10.8067 1.96544 9.29738 1.8663 7.8462 2.17792C6.39502 2.48953 5.05945 3.19954 3.98948 4.22823C2.91951 5.25691 2.15751 6.56352 1.78906 8.00132"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.29395 15.0012C4.22018 16.161 5.45141 17.0396 6.84936 17.5384C8.24732 18.0371 9.75663 18.1362 11.2078 17.8246C12.659 17.513 13.9946 16.803 15.0645 15.7743C16.1345 14.7456 16.8965 13.439 17.2649 12.0012"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 13.1243L4.785 13.5083L1.51 16.7833L1 13.1243Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1789 6.03565L15.9159 5.90752L17.0087 4.81476L17.1789 6.03565Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M14.2686 6.49424L17.5436 3.21924L18.0536 6.87824L14.2686 6.49424Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Swap;
