import { language, PREFERENCE_IDS, PREFERENCE_TYPE_IDS } from "@chef/constants";
import {
  BRAND_NAME,
  VEGETARIAN_CONCEPT_PREFERENCE_ID,
  VEGETARIAN_TASTE_PREFERENCE_ID,
  VEGETARIAN_TASTE_PREFERENCE_IDS,
} from "@chef/constants";

import { isEqualStrings } from "@chef/utils/equal";

interface IIsSomethingPreference {
  preferenceTypeId?: string;
  preferenceId?: string;
  isAllergen?: boolean;
  description?: string;
}

interface GetSelectedPreferenceDescriptionArgs {
  preferenceIds: string[];
  preferences: IIsSomethingPreference[];
}

const VEGETARIAN_CONCEPT = 0b000000001;
const PORK = 0b000000010;
const CHICKEN = 0b000000100;
const BEEF = 0b000001000;
const LAMB = 0b000010000;
const SHELLFISH = 0b000100000;
const FISH = 0b001000000;
const EGG = 0b010000000;
const DAIRY = 0b100000000;

const preferenceMasks = new Map<string, number>([
  [VEGETARIAN_CONCEPT_PREFERENCE_ID, VEGETARIAN_CONCEPT],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.PORK, PORK],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.CHICKEN, CHICKEN],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.BEEF, BEEF],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.LAMB, LAMB],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.SHELLFISH, SHELLFISH],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.FISH, FISH],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.EGG, EGG],
  [VEGETARIAN_TASTE_PREFERENCE_IDS.DAIRY, DAIRY],
]);

const _FLEXITARIAN = VEGETARIAN_CONCEPT;
const _PESCETARIAN = PORK | CHICKEN | LAMB | BEEF;
const _VEGETARIAN = _PESCETARIAN | SHELLFISH | FISH;
const _VEGAN = _VEGETARIAN | EGG | DAIRY;

export enum PreferenceCombos {
  NONE = 0,
  VEGAN = _VEGAN,
  VEGETARIAN = _VEGETARIAN,
  PESCETARIAN = _PESCETARIAN,
  FLEXITARIAN = _FLEXITARIAN,
}

export const getPreferenceCombo = (preferenceIds: string[]) => {
  const bitmask = preferenceIds.reduce(
    (acc, id) => acc | (preferenceMasks.get(id) ?? 0),
    0,
  );

  if (BRAND_NAME === "LMK") {
    if ((bitmask & _VEGAN) === _VEGAN) {
      return PreferenceCombos.VEGAN;
    }
  }

  if ((bitmask & _VEGETARIAN) === _VEGETARIAN) {
    return PreferenceCombos.VEGETARIAN;
  }

  if ((bitmask & _PESCETARIAN) === _PESCETARIAN) {
    return PreferenceCombos.PESCETARIAN;
  }

  if ((bitmask & _FLEXITARIAN) === _FLEXITARIAN) {
    return PreferenceCombos.FLEXITARIAN;
  }

  return PreferenceCombos.NONE;
};

export const isConceptPreference = (preference: IIsSomethingPreference) => {
  return isEqualStrings(
    preference.preferenceTypeId,
    PREFERENCE_TYPE_IDS.CONCEPT,
  );
};

export const isTastePreference = (preference: IIsSomethingPreference) => {
  return isEqualStrings(preference.preferenceTypeId, PREFERENCE_TYPE_IDS.TASTE);
};

// This will be used to cover edge-cases on Singel-preference in Onesub
export const isSingelPreference = (preference: IIsSomethingPreference) => {
  if (!preference || !preference.preferenceId) {
    return false;
  }
  return isEqualStrings(preference.preferenceId, PREFERENCE_IDS.SINGEL_ONESUB);
};

export const isVegetarianConceptPreference = (
  preference: IIsSomethingPreference,
) => {
  return isEqualStrings(
    preference.preferenceId,
    PREFERENCE_IDS.VEGETARIAN_ONESUB,
  );
};

export const isVegetarianTastePreference = (
  preference: IIsSomethingPreference,
) => {
  return isEqualStrings(
    preference.preferenceId,
    VEGETARIAN_TASTE_PREFERENCE_ID,
  );
};

export const isPreferencesAllergen = (
  preferenceIds: string[],
  preferences: IIsSomethingPreference[],
) => {
  return preferences.some(
    (p) =>
      p.isAllergen && p.preferenceId && preferenceIds.includes(p.preferenceId),
  );
};

export const isFallbackPreference = (preference: IIsSomethingPreference) => {
  return isEqualStrings(preference.preferenceId, PREFERENCE_IDS.CHEF_CHOICE);
};

export const getSelectedPreferenceDescription = ({
  preferenceIds,
  preferences,
}: GetSelectedPreferenceDescriptionArgs): string => {
  if (preferenceIds.length === 0) {
    return intl.IF_YOU_DONT_SELECT_ANY_PREFERENCES_OUR_CHEFS_WILL_RECOMMEND_YOU_THEIR_FAVORITE;
  }
  if (preferenceIds.length === 1) {
    return (
      preferences.find((p) => isEqualStrings(p.preferenceId, preferenceIds[0]))
        ?.description ?? ""
    );
  }
  return intl.SELECTED_A_MIX;
};
const intl = (
  {
    no: {
      SELECTED_A_MIX: "Du har valgt en blanding av ulike typer retter.",
      IF_YOU_DONT_SELECT_ANY_PREFERENCES_OUR_CHEFS_WILL_RECOMMEND_YOU_THEIR_FAVORITE:
        "Hvis du ikke velger noen preferanser, vil våre kokker anbefale sine favoritter til deg.",
    },
    se: {
      SELECTED_A_MIX: "Du har valt en blandning av olika typer av rätter.",
      IF_YOU_DONT_SELECT_ANY_PREFERENCES_OUR_CHEFS_WILL_RECOMMEND_YOU_THEIR_FAVORITE:
        "Då väljer våra kockar sina favoriter!",
    },
    dk: {
      SELECTED_A_MIX: "Du har valgt en blanding af forskellige typer retter.",
      IF_YOU_DONT_SELECT_ANY_PREFERENCES_OUR_CHEFS_WILL_RECOMMEND_YOU_THEIR_FAVORITE:
        "Uden valgte præferencer får du menuforslag fra 'favoritter'.",
    },
  } as const
)[language];
