import { addHours, format } from "date-fns";

import { createApi } from "@reduxjs/toolkit/query/react";
import type { IGetOrderInformation, IGetOrderInformationArgs } from "./types";
import { query } from "../../graphql/baseApi";

const developerPlanAndGoOverride = () => {
  const STATE = {
    PLAN_AND_GO_DOESNT_EXIST_YET: 0,
    PLAN_AND_GO_EXISTS: 1,
    PLAN_AND_GO_CURRENT_DAY: 2,
    PLAN_AND_GO_DELIVERED: 3,
  };
  let currentState = 0;

  let realtimeEstimatedTimestamp =
    null as IGetOrderInformation["realtimeEstimatedTimestamp"];

  return (args: IGetOrderInformationArgs) => {
    const state = currentState;
    currentState = (currentState + 1) % (STATE.PLAN_AND_GO_DELIVERED + 1);

    // reset, since we're using a "global" version of this
    if (state === 0) {
      realtimeEstimatedTimestamp = null;
    }

    const date = addHours(args.date, 10);
    let driverConfirmedTimestamp =
      null as IGetOrderInformation["driverConfirmedTimestamp"];
    const estimatedTimestamp =
      date.toISOString() as IGetOrderInformation["estimatedTimestamp"];
    const timestampWindow = [
      addHours(date, -2).toISOString(),
      addHours(date, 2).toISOString(),
    ] as IGetOrderInformation["timestampWindow"];

    switch (state) {
      case STATE.PLAN_AND_GO_DOESNT_EXIST_YET: {
        // can't return an error here, because RTK will cache the last valid response
        return { data: null as unknown as IGetOrderInformation };
      }

      case STATE.PLAN_AND_GO_CURRENT_DAY: {
        realtimeEstimatedTimestamp = addHours(
          date,
          Math.random() > 0.5 ? 1 : -1,
        ).toISOString();
        break;
      }

      case STATE.PLAN_AND_GO_DELIVERED: {
        driverConfirmedTimestamp = realtimeEstimatedTimestamp;
        break;
      }
    }
    return {
      data: {
        driverConfirmedTimestamp,
        estimatedTimestamp,
        orderId: args.orderId.toString(),
        realtimeEstimatedTimestamp,
        timestampWindow,
      } as IGetOrderInformation,
    };
  };
};

const shouldUseDeveloperMode = true && process.env.NODE_ENV === "development";

export const planAndGoApi = createApi({
  reducerPath: "planAndGo",
  baseQuery: query,
  endpoints: (builder) => ({
    getOrderInformation: builder.query<
      IGetOrderInformation,
      IGetOrderInformationArgs
    >({
      ...(shouldUseDeveloperMode
        ? { queryFn: developerPlanAndGoOverride() }
        : {
            query: (opts) => {
              const date = format(opts.date, "yyyy-MM-dd");
              return `/planAndGo/orderInformation?date=${date}&orderId=${opts.orderId}`;
            },
          }),
    }),
  }),
});

export const { useGetOrderInformationQuery } = planAndGoApi;

export * from "./utils";
export * from "./types";
