import * as React from "react";
import { SVGProps } from "react";
const Broccoli = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M62.3252 65.5763C63.0954 67.1053 64.2809 68.3864 65.7457 69.2728C67.2106 70.1591 68.8955 70.6148 70.6074 70.5876C72.3193 70.5605 73.9889 70.0515 75.4248 69.1191C76.8608 68.1868 78.005 66.8687 78.7263 65.3159H79.6375C82.0449 65.3041 84.3739 64.4584 86.228 62.9229C88.0822 61.3874 89.3471 59.2568 89.8074 56.8938C90.2677 54.5308 89.8951 52.0812 88.7529 49.962C87.6107 47.8428 85.7695 46.1848 83.5426 45.2701C84.5893 43.359 84.9165 41.1361 84.4649 39.0045C84.0133 36.8729 82.8128 34.9737 81.081 33.6513C79.3493 32.3288 77.201 31.6708 75.0257 31.7964C72.8504 31.922 70.7921 32.823 69.2241 34.336C68.9341 30.2589 67.1781 26.4245 64.2807 23.5414C61.3833 20.6584 57.5403 18.9215 53.4618 18.6517C49.3833 18.3819 45.345 19.5975 42.0931 22.0738C38.8411 24.5501 36.5954 28.1197 35.7709 32.1231C34.5885 31.6323 33.3035 31.4397 32.0291 31.5623C30.7547 31.6849 29.53 32.1189 28.4627 32.8261C27.3955 33.5333 26.5185 34.492 25.9089 35.6178C25.2993 36.7437 24.9759 38.0021 24.967 39.2824H23.6653C20.5583 39.2824 17.5785 40.5166 15.3815 42.7136C13.1845 44.9106 11.9502 47.8904 11.9502 50.9975C11.9502 54.1045 13.1845 57.0843 15.3815 59.2813C17.5785 61.4783 20.5583 62.7126 23.6653 62.7126H25.3575C25.789 64.0375 26.5671 65.2228 27.6112 66.1456C28.6552 67.0684 29.927 67.695 31.2949 67.9606C32.6627 68.2262 34.0767 68.1211 35.3901 67.656C36.7036 67.191 37.8688 66.383 38.7648 65.3159"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9834 56.7246L40.5868 83.5392H58.8103L63.6265 59.5883M48.3968 62.7123V83.5392"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.7773 53.8607C36.1399 56.4806 40.3674 57.7349 44.6147 57.373C48.862 57.011 52.8162 55.0593 55.6869 51.9082C57.1127 54.6487 59.2614 56.9466 61.9002 58.5528C64.5389 60.159 67.567 61.0122 70.6562 61.02"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Broccoli;
