import clsx from "clsx";

import { sendIntercomMessage } from "@chef/helpers";

import { intl } from "./AddressNotFoundDisclaimer.Intl";
interface Props {
  className?: string;
  id?: string;
  noAddressFound: boolean;
}

export const AddressNotFoundDisclaimer = ({
  className,
  id,
  noAddressFound,
}: Props) => {
  if (!noAddressFound) {
    return null;
  }

  const sendChat = () =>
    sendIntercomMessage({
      prepopulatedMessage: "",
      subject: intl.REGISTER_ADDRESS,
    });
  return (
    <div
      className={clsx("gap-1 p-4 col-span-full bg-background", className)}
      id={id}
    >
      <p className="pb-2">
        <strong>{intl.CANT_FIND_ADDRESS}</strong>
      </p>
      <ul className="text-sm list-disc list-inside">
        {intl.SEARCH_TIPS.map((tip, index) => {
          return <li key={index}>{tip}</li>;
        })}
      </ul>
      <p className="pt-2 text-sm">
        {intl.OR}
        <button
          className="px-1 underline text-primary"
          onClick={sendChat}
          type="button"
        >
          {intl.CONTACT_CUSTOMER_SERVICE}
        </button>
        {intl.HELP_SET_UP_ACCOUNT}
      </p>
    </div>
  );
};
