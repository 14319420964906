import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import {
  CURRENCY,
  language,
  ORDER_TYPE_IDS,
  PRODUCT_CATEGORY_IDS,
} from "@chef/constants";
import { createAndSendEvent } from "@chef/events";

import { api, RootState } from "..";
import {
  showNotification,
  setSignupCoupon,
  selectSignupApplicantInfo,
  selectSignupMealbox,
  selectSignupWeekAndYear,
} from "../features";
import { getAttribute, getPriceOfProductByVariation } from "../helpers";

const intl = {
  se: {
    COUPON_CODE_ACTIVATED: (couponCode: string) => `${couponCode} är aktiverad`,
  },
  no: {
    COUPON_CODE_ACTIVATED: (couponCode: string) => `${couponCode} er aktivert`,
  },
  dk: {
    COUPON_CODE_ACTIVATED: (couponCode: string) => `${couponCode} tilføjet`,
  },
}[language];

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setSignupCoupon,
    effect: async (action, { getState, getOriginalState, dispatch }) => {
      if (
        (getOriginalState() as RootState).signupRtk.coupon?.couponCode ===
        action.payload.couponCode
      ) {
        return;
      }

      if (action.payload.source !== "default") {
        showNotification({
          type: "success",
          message: intl.COUPON_CODE_ACTIVATED(action.payload.couponCode),
        });
      }

      if (action.payload.userInitiated) {
        const state = getState() as RootState;
        const applicantInfo = selectSignupApplicantInfo(state);
        if (!applicantInfo) {
          console.error(
            "Required property applicantinfo not set in signup state before tracking signup coupon",
          );
        }

        const { week, year } = selectSignupWeekAndYear(state);

        const productsByCategoriesResponse = await dispatch(
          api.endpoints.productsByCategories.initiate({
            categoryIds: [PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT],
            week,
            year,
          }),
        ).unwrap();

        const selectedMealbox = selectSignupMealbox(state);

        const selectedProduct =
          productsByCategoriesResponse.productsByCategories
            .flatMap(({ products }) => products)
            .find((product) => product.productId === selectedMealbox.productId);

        const selectedVariation = selectedProduct?.variations.find(
          (variation) => variation.variationId === selectedMealbox.variationId,
        );

        const selectedNumberOfMeals = Number(
          getAttribute("Meals", selectedVariation || { attributes: [] }),
        );

        const selectedNumberOfPortions = Number(
          getAttribute("Meals", selectedVariation || { attributes: [] }),
        );

        const variationPrice =
          (selectedVariation &&
            getPriceOfProductByVariation({
              variation: selectedVariation,
              week,
              year,
            })) ||
          0;

        createAndSendEvent("Order Updated", {
          affiliation: "Frontend process",
          campaign_id: action.payload.discountId,
          coupon: action.payload.couponCode,
          currency: CURRENCY,
          discount: action.payload.amount,
          discount_code: action.payload.name,
          discount_code_id: action.payload.discountId,
          order_id: applicantInfo?.applicantId || "",
          order_type_id: ORDER_TYPE_IDS.RECURRING,

          products: [
            {
              name: selectedProduct?.name || "",
              portions: selectedNumberOfPortions,
              meals: selectedNumberOfMeals,
              variant: selectedVariation?.name || "",
              variant_id: selectedVariation?.variationId || "",
              category: selectedProduct?.productTypeName || "",
              category_id: selectedProduct?.productTypeId || "",
              price: variationPrice,
              product_id: selectedProduct?.productId || "",
              quantity: 1,
              sku: selectedVariation?.sku || "",
              url: typeof window !== "undefined" ? window.location.href : "",
            },
          ],
        });
      }
    },
  });
