import * as React from "react";
import { SVGProps } from "react";
const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.41016 10.0979C8.41016 10.0979 12.7494 10.346 15.5896 11.0904"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.41016 13.0752C8.41016 13.0752 12.7494 13.3233 15.5896 14.0677"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.41016 16.0527C8.41016 16.0527 10.9743 16.0527 13.096 16.7971"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6663 8.11289C14.051 6.62417 5.33301 6.12793 5.33301 6.12793V21.0151C5.33301 21.0151 14.5638 22.0076 18.6663 23.0001V8.11289Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.63761 5.40926C4.24073 5.79331 4.23032 6.42639 4.61438 6.82328C4.99844 7.22017 5.63151 7.23057 6.0284 6.84651L4.63761 5.40926ZM15.5894 2.15796H16.5894C16.5894 1.86886 16.4643 1.5939 16.2463 1.40399C16.0284 1.21408 15.7389 1.12779 15.4525 1.16738L15.5894 2.15796ZM5.33301 6.12788C6.0284 6.84651 6.02806 6.84684 6.02774 6.84716C6.02764 6.84725 6.02732 6.84755 6.02714 6.84773C6.02676 6.8481 6.02643 6.84841 6.02615 6.84869C6.02558 6.84923 6.0252 6.8496 6.025 6.84978C6.0246 6.85016 6.02494 6.84983 6.02603 6.84882C6.0282 6.84679 6.03333 6.842 6.04147 6.83461C6.05775 6.81984 6.08602 6.79466 6.12653 6.76026C6.20755 6.69145 6.33753 6.58578 6.51842 6.45275C6.88014 6.18673 7.44569 5.81112 8.23099 5.40194C9.79985 4.58448 12.2543 3.6285 15.7264 3.14854L15.4525 1.16738C11.745 1.67989 9.07129 2.70888 7.30682 3.62827C6.42545 4.08751 5.77306 4.51829 5.3335 4.84155C5.11375 5.00316 4.94725 5.13786 4.83196 5.23576C4.77431 5.28471 4.72946 5.32447 4.69716 5.35379C4.68101 5.36846 4.66799 5.38051 4.65808 5.38981C4.65312 5.39445 4.64894 5.39841 4.64553 5.40166C4.64383 5.40328 4.64231 5.40473 4.641 5.406C4.64034 5.40663 4.63972 5.40722 4.63916 5.40776C4.63888 5.40803 4.63849 5.40841 4.63835 5.40854C4.63798 5.4089 4.63761 5.40926 5.33301 6.12788ZM14.5894 2.15796V7.12037H16.5894V2.15796H14.5894Z"
      fill="currentColor"
    />
  </svg>
);
export default Menu;
