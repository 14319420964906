import * as React from "react";
import { SVGProps } from "react";
const Parcel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.75 9.5625L20 1.25L1.25 9.5625V30.4375L20 38.75L38.75 30.4375V9.5625Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 9.5625L20 17.875M20 17.875L38.75 9.5625M20 17.875V38.75M11.5 5L30.25 13.3125"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.75 16.625L26.25 23.0625V30.125L33.75 26.8125V16.625Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Parcel;
