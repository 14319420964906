import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      MEMBERSHIP_LEVEL: "Medlemsnivå",
      POINTS_BALANCE: "Verdipoeng",
      POINTS_BALANCE_DESCRIPTION:
        "Totalt antall poeng du har spart opp og kan bruke for å få rabatter.",
      ACCRUED_POINTS: "Nivåpoeng du har fått siste periode",
      ACCRUED_POINTS_DESCRIPTION:
        "Disse poengene brukes for å bestemme ditt medlemsnivå.",
      REMAINING_ACCRUED_POINTS: "Nivåpoeng til neste nivå",
      REMAINING_ACCRUED_POINTS_DESCRIPTION:
        "Så mange nivåpoeng trenger du for å gå videre til neste medlemsnivå.",

      POINTS_RECEIVED_FROM_NEXT_DELIVERY: "Poeng for din neste leveranse",
      POINTS_RECEIVED_FROM_NEXT_DELIVERY_DESCRIPTION:
        "Poeng du får etter din neste leveranse",
      POINT: "p",
      CLICK_HERE: "Klikk her",
    },

    se: {
      MEMBERSHIP_LEVEL: "Medlemsnivå",
      POINTS_BALANCE: "Värdepoäng",
      POINTS_BALANCE_DESCRIPTION:
        "Totala poängen som du har tjänat och kan använda för att få rabatter.",
      ACCRUED_POINTS: "Nivåpoäng intjänade denna period",
      ACCRUED_POINTS_DESCRIPTION:
        "Dessa poäng används för att bestämma din medlemsnivå.",
      REMAINING_ACCRUED_POINTS: "Nivåpoäng kvar till nästa nivå",
      REMAINING_ACCRUED_POINTS_DESCRIPTION:
        "Nivåpoäng som krävs för att uppgraderas till nästa medlemsnivå.",
      POINTS_RECEIVED_FROM_NEXT_DELIVERY: "Poängvärdet av nästa leverans",
      POINTS_RECEIVED_FROM_NEXT_DELIVERY_DESCRIPTION:
        "Beräknade poäng som du kommer att få efter kommande leverans.",
      POINT: "p",
      CLICK_HERE: "Klicka här",
    },

    dk: {
      MEMBERSHIP_LEVEL: "Medlemsniveau",
      POINTS_BALANCE: "Vaerdipoint",
      POINTS_BALANCE_DESCRIPTION:
        "Samlet antal point du har optjent og kan anvende til at få rabatter og fordele",
      ACCRUED_POINTS: "Niveupoint optjent i denne periode",
      ACCRUED_POINTS_DESCRIPTION:
        "Disse point bruges til at bestemme dit medlemsniveau.",
      REMAINING_ACCRUED_POINTS: "Niveupoint tilbage til næste niveau",
      REMAINING_ACCRUED_POINTS_DESCRIPTION:
        "Niveupoint, som kræves for at blive opgraderet til næste medlemsniveau.",
      POINTS_RECEIVED_FROM_NEXT_DELIVERY: "Pointværdien af næste levering",
      POINTS_RECEIVED_FROM_NEXT_DELIVERY_DESCRIPTION:
        "Antal point, som du vil få efter din kommende levering.",
      POINT: "p",
      CLICK_HERE: "Klik her",
    },
  } as const
)[language];
