import { language } from "@chef/constants";
import { validateNorwegianSSN, validateSwedishSSN } from "@chef/utils/ssn";

export const intl = (
  {
    dk: {
      PAYMENT_LINK: "/app/min-konto/betaling",
      IS_VALID_SSN: (ssn?: string): boolean => {
        if (!ssn) {
          return false;
        }
        if (ssn === "18888888886") {
          return true;
        }
        return validateNorwegianSSN(ssn);
      },
      CANCEL_BUTTON: "Annuller",
    },
    no: {
      BUTTON_HEADER: "Faktura",
      BUTTON_ACTION_TEXT: "Betal med faktura",
      TERMS_DETAILS_FIRST_PART:
        "Fakturaen kommer først etter levering. Når du velger faktura som betalingsalternativ godtar du ",
      TERMS_LINK_TEXT: "vilkårene",
      TERMS_LINK_URL: "https://cdn.walleypay.com/terms/Terms_NO.pdf",
      TERMS_DETAIL_SECOND_PART:
        "til Walley og bekrefter at du har lest Walleys håndtering av personopplysninger.",
      CREDIT_CHECK_HEADER: "Kredittopplysninger",
      CREDIT_CHECK_DETAIL:
        "En kredittsjekk gjennomføres på samtlige kunder i forbindelse med registrering, og dersom betalingsmetode endres til faktura.",
      CREDIT_CHECK_USP: "Fakturaavgift 39 kr.",
      REGISTRATION_PAYMENT_SAVE_BUTTON: "Bestill med faktura og velg retter",
      CAN_ONLY_CONTAIN_NUMBERS: "Kan bare inneholde tall",
      MUST_BE_EXACTLY_DIGITS: (digit?: number) => {
        return `Må være ${digit} siffer`;
      },
      MANDATORY_FIELD: "Påkrevd felt",
      LENGTH_OF_PERSONAL_NUMBER: 11,
      LENGTH_OF_POSTALCODE: 4,
      LENGTH_OF_TELEPHONE_NUMBER: 8,
      TELEPHONE_NUMBER_ALREADY_IN_USE: "Nummeret er i bruk!",
      YOU_DECIDE: "Du bestemmer",
      PAUSE_OR_QUIT: "Pause eller avslutt når du vil",
      MOVE_ON: "Gå videre",
      SKIP_SSN_VALIDATION: true,
      MOVE_TO_NEXT_STEP: "Gå videre",
      WE_CANNOT_DELIVER_HERE: "Beklager, vi kan ikke levere til din adresse",
      CHECK_IF_WE_CAN_DELIVER_HERE: "Sjekker om vi kan levere hit...",
      PERSONAL_NUMBER_NOT_VALID: "Personnummeret er ikke gyldig",
      IS_VALID_SSN: (ssn?: string): boolean => {
        if (!ssn) {
          return false;
        }
        if (ssn === "18888888886") {
          return true;
        }
        return validateNorwegianSSN(ssn);
      },
      PERSONAL_NUMBER_ALREADY_IN_USE:
        "Noe gikk galt under valideringen av personnummeret. Det kan hende at personnummeret allerede er i bruk, eller at du har tastet feil. Vennligst prøv igjen.",
      SHOW_SSN_INPUT_FIELD: true,
      AUTHENTICATE_WITH_BANKID: () => "Fyll inn med BankID",
      RESET: "Tilbakestill",
      PERSONAL_NUMBER: "Personnummer",
      AUTHENTICATION_ABORTED: "Autentisering avbrutt. Vennligst prøv igjen.",
      AUTHENTICATION_ERROR: "Autentisering feilet. Vennligst prøv igjen.",
      AUTHENTICATED_AS: "Autentisert som",
      MY_ACCOUNT_SAVE_CAHNGE_BUTTON: "Lagre endringer",
      CANCEL_BUTTON: "Avbryt",
      PAYMENT_LINK: "/app/min-konto/betaling",
    },
    se: {
      BUTTON_HEADER: "Faktura",
      BUTTON_DETAIL: "Få först, betala sen. Fakturaavgift 19 kr.",
      BUTTON_ACTION_TEXT: "Betala med faktura",

      TERMS_DETAILS_FIRST_PART:
        "Fakturan kommer först efter leverans. När du väljer faktura som betalningsalternativ godtar du därmed även",
      TERMS_LINK_TEXT: "villkoren",
      TERMS_LINK_URL: "https://cdn.walleypay.com/terms/Terms_SE.pdf",
      TERMS_DETAIL_SECOND_PART:
        "till Walley och bekräftar att du har läst Walleys hantering av personuppgifter.",
      CREDIT_CHECK_HEADER: "Kreditupplysning",
      CREDIT_CHECK_DETAIL:
        "En kreditupplysning genomförs på samtliga kunder i samband med registrering och vid ändrad betalmetod till faktura.",
      CREDIT_CHECK_USP: "Få först. Betala sen. Fakturaavgift 19 kr.",
      REGISTRATION_PAYMENT_SAVE_BUTTON: "Beställ med faktura & välj rätter",
      MUST_BE_EXACTLY_DIGITS: (digit?: number) => {
        return `Må være ${digit} siffer`;
      },
      LENGTH_OF_PERSONAL_NUMBER: 12,
      IS_VALID_SSN: (ssn?: string): boolean => {
        if (!ssn) {
          return false;
        }
        if (ssn === "188888888886") {
          return true;
        }
        return validateSwedishSSN(ssn);
      },
      SHOW_SSN_INPUT_FIELD: true,
      AUTHENTICATE_WITH_BANKID: (ssn?: string) =>
        `${ssn ? "Verifiera" : "Fyll i"} med BankID`,
      RESET: "Tilbakestill",
      PERSONAL_NUMBER: "Personnummer",
      AUTHENTICATION_ABORTED: "Autentiseringen avbröts. Var god försök igen.",
      AUTHENTICATION_ERROR:
        "Autentiseringen misslyckades. Var god försök igen.",
      AUTHENTICATED_AS: "Autentiserad som",
      MY_ACCOUNT_SAVE_CAHNGE_BUTTON: "Spara ändringar",
      CANCEL_BUTTON: "Avbryt",
      PAYMENT_LINK: "/mina-sidor/mitt-konto/betalningsoversikt",
    },
  } as const
)[language];
