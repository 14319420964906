import * as React from "react";
import { SVGProps } from "react";
const NewYear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8941:146264)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4664 0.253153C11.5102 0.318884 11.541 0.393289 11.5569 0.471901C12.5783 4.49228 12.1256 7.75805 11.0197 8.37129L11.5985 9.9668C11.5985 9.9668 13.0646 9.64043 13.4759 10.2121C13.4893 10.2343 13.4972 10.2597 13.499 10.286C13.5007 10.3123 13.4962 10.3386 13.4858 10.3626C13.4754 10.3866 13.4596 10.4074 13.4396 10.4232C13.4197 10.4391 13.3963 10.4494 13.3716 10.4532L9.74674 11.4781C9.72412 11.486 9.70003 11.4882 9.67647 11.4843C9.65291 11.4804 9.63057 11.4706 9.61132 11.4558C9.59206 11.4409 9.57644 11.4214 9.56577 11.3989C9.55509 11.3764 9.54967 11.3515 9.54995 11.3263C9.63654 10.6133 11.0593 10.1206 11.0593 10.1206L10.4667 8.5272C9.62363 8.56216 8.47347 7.40203 7.50051 5.41549C7.65698 4.95293 7.80199 4.46473 7.93226 3.95337C7.95561 3.87643 7.96387 3.79526 7.95653 3.71484C7.94919 3.63441 7.92641 3.55641 7.88957 3.48559C7.85274 3.41478 7.80263 3.35264 7.7423 3.30296C7.68197 3.25329 7.61268 3.21713 7.53868 3.19669L6.52406 2.91008C6.43112 2.60593 6.34325 2.2919 6.26128 1.96863C6.23791 1.89155 6.22964 1.81025 6.23696 1.72968C6.24428 1.64912 6.26704 1.57097 6.30386 1.49999C6.34068 1.42901 6.39078 1.36669 6.45112 1.31681C6.51147 1.26693 6.58079 1.23055 6.65486 1.20987L10.8642 0.0187255C10.9379 -0.00125672 11.0147 -0.00529685 11.0899 0.0068472C11.1652 0.0189912 11.2373 0.0470692 11.302 0.0893952C11.3667 0.131721 11.4226 0.187422 11.4664 0.253153Z"
        fill="black"
      />
      <path
        d="M2.43066 2.77571C2.44564 2.70154 2.47447 2.63137 2.51542 2.56944C2.55637 2.50752 2.60862 2.4551 2.66897 2.41536C2.72932 2.37562 2.79655 2.34936 2.86662 2.33818C2.93669 2.32699 3.00814 2.33111 3.07668 2.35028L6.99107 3.4717C7.05989 3.49098 7.12431 3.52508 7.18042 3.57193C7.23652 3.61877 7.28312 3.67738 7.31738 3.74417C7.35163 3.81095 7.37282 3.88452 7.37964 3.96037C7.38647 4.03622 7.37879 4.11277 7.35708 4.18534C6.40363 7.98094 4.60115 10.4238 3.43908 10.3728L2.77454 12.7102C2.77454 12.7102 4.09949 13.1749 4.17818 13.8473C4.19099 13.9551 4.08668 14.0198 3.99517 13.9924L0.618752 13.018C0.595805 13.0144 0.57407 13.0047 0.555526 12.9897C0.536982 12.9748 0.522219 12.9551 0.512579 12.9325C0.502939 12.9099 0.498729 12.8851 0.500333 12.8603C0.501936 12.8355 0.509302 12.8115 0.52176 12.7906C0.904238 12.2514 2.26762 12.5592 2.26762 12.5592L2.92662 10.2277C1.9018 9.64935 1.4827 6.56739 2.43066 2.77571Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_8941:146264">
        <rect width={14} height={14} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NewYear;
