import * as React from "react";
import { SVGProps } from "react";
const MasterCardBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.8463 0H2.15374C0.964262 0 0 0.976833 0 2.18182V21.8182C0 23.0232 0.964262 24 2.15374 24H31.8463C33.0357 24 34 23.0232 34 21.8182V2.18182C34 0.976833 33.0357 0 31.8463 0Z"
      fill="#36495D"
    />
    <path
      d="M19.8626 7.28516H14.5586V16.7172H19.8626V7.28516Z"
      fill="#F16522"
    />
    <path
      d="M14.8972 12C14.8976 11.0893 15.1066 10.1908 15.5081 9.37329C15.9095 8.55582 16.4928 7.84112 17.2133 7.284C16.3282 6.58788 15.2652 6.15486 14.1456 6.03442C13.0261 5.91399 11.8953 6.11101 10.8824 6.60296C9.86961 7.09492 9.01567 7.86194 8.41821 8.81634C7.82075 9.77075 7.50391 10.874 7.50391 12C7.50391 13.126 7.82075 14.2292 8.41821 15.1837C9.01567 16.1381 9.86961 16.9051 10.8824 17.397C11.8953 17.889 13.0261 18.086 14.1456 17.9656C15.2652 17.8451 16.3282 17.4121 17.2133 16.716C16.4929 16.1588 15.9096 15.4441 15.5082 14.6266C15.1067 13.8092 14.8977 12.9107 14.8972 12"
      fill="#E41B24"
    />
    <path
      d="M27.015 11.9986C27.0008 13.1297 26.6711 14.2343 26.063 15.1881C25.4549 16.1419 24.5924 16.9071 23.573 17.3972C22.5535 17.8874 21.4176 18.0832 20.2928 17.9626C19.1681 17.842 18.0994 17.4098 17.207 16.7146C17.9275 16.1576 18.5108 15.4429 18.9121 14.6254C19.3134 13.8079 19.5221 12.9093 19.5221 11.9986C19.5221 11.0879 19.3134 10.1894 18.9121 9.37185C18.5108 8.55434 17.9275 7.83963 17.207 7.28262C18.0994 6.58744 19.1681 6.15523 20.2928 6.03463C21.4176 5.91403 22.5535 6.10982 23.573 6.6C24.5924 7.09018 25.4549 7.8553 26.063 8.80911C26.6711 9.76291 27.0008 10.8675 27.015 11.9986ZM26.437 15.7156V15.5256H26.516V15.4866H26.316V15.5256H26.395V15.7187L26.437 15.7156ZM26.826 15.7156V15.4826H26.764L26.693 15.6426L26.6221 15.4826H26.561V15.7156H26.604V15.5396L26.67 15.6916H26.715L26.781 15.5396V15.7156H26.826Z"
      fill="#F89E1C"
    />
  </svg>
);
export default MasterCardBadge;
