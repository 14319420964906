import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      YOUR_POINTS: "Dine verdipoeng",
      POINT: "p",
      SPEND_YOUR_POINTS: "Bruk dine poeng",
      EXPIRES: "utløper",
    },

    se: {
      YOUR_POINTS: "Dina värdepoäng",
      POINT: "p",
      SPEND_YOUR_POINTS: "Använd dina poäng",
      EXPIRES: "löper ut",
    },

    dk: {
      YOUR_POINTS: "Dine værdipoint",
      POINT: "p",
      SPEND_YOUR_POINTS: "Indløs dine point",
      EXPIRES: "udløber",
    },
  } as const
)[language];
