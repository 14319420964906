import * as React from "react";
import { SVGProps } from "react";
const Club = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7977 7.33069L15.9804 3L18.1451 7.3441L23.0007 8.05024L19.4792 11.4156L20.2961 16.1842L15.9532 13.9273L11.6013 16.1664L12.4454 11.4022L8.9375 8.01448L13.7977 7.33069Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8475 14.466V18.9211C16.8475 19.3477 16.5107 19.7 16.1029 19.7H1.77095C1.36309 19.7 1.02637 19.3477 1.02637 18.9211V9.93149C1.02637 9.50483 1.36309 9.15259 1.77095 9.15259H9.81587"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M3.38086 12.8462H7.85074"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M3.38086 15.6968H7.85074"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </svg>
);
export default Club;
