import { ChevronDown, ChevronUp } from "@chef/icons/small";

import ReceiptLine from "./ReceiptLine";
import { Language } from "../../types";
import { Disclosure } from "../components";

interface ReceiptSectionProps {
  isIndented?: boolean;
  Icon?: JSX.Element;
  text?: string;
  price?: number;
  priceIsCurrency?: boolean;
  priceIsDiscounted?: boolean;
  priceIsHighlighted?: boolean;
  children?: React.ReactNode;
  isOpenDisclosure?: boolean;
  id?: string;
  language: Language;
  onDisclosureClicked?: () => void;
  isDiscount?: boolean;
}

const ReceiptSection = ({
  isIndented,
  Icon,
  text = "",
  price,
  priceIsCurrency,
  priceIsDiscounted,
  priceIsHighlighted,
  children,
  isOpenDisclosure = false,
  isDiscount = false,
  id = "",
  language,
  onDisclosureClicked,
}: ReceiptSectionProps) => {
  return (
    <Disclosure
      open={!!isOpenDisclosure}
      HeaderRow={() => (
        <ReceiptLine
          language={language}
          Icon={Icon}
          price={price}
          priceIsCurrency={priceIsCurrency}
          priceIsHighlighted={priceIsHighlighted}
          priceIsDiscounted={priceIsDiscounted}
          isIndented={isIndented}
          isDiscount={isDiscount}
          hidePriceIfZero
        >
          <div className="flex items-between grow" id={id}>
            <span className="mr-4">{text}</span>

            <span className="text-xs">
              {isOpenDisclosure ? <ChevronUp /> : <ChevronDown />}
            </span>
          </div>
        </ReceiptLine>
      )}
      onClick={() => onDisclosureClicked && onDisclosureClicked()}
    >
      {children}
    </Disclosure>
  );
};

export default ReceiptSection;
