import { ListenerMiddlewareInstance, isAnyOf } from "@reduxjs/toolkit";
import { language } from "@chef/constants";
import { billingApi, showNotification } from "../features";

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.createOrUpdateDeviation.matchFulfilled,
      billingApi.endpoints.createOrUpdateDeviation.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showNotification({
          type: "error",
          message: intl.DEVIATION_UPDATE_FAILED,
        });
      }
    },
  });
};

const intl = (
  {
    no: {
      DEVIATION_UPDATE_FAILED:
        "Det oppstod en feil under oppdatering av bestillingen, vennligst prøv igjen. Kontakt support om problemet vedvarer",
    },
    se: {
      DEVIATION_UPDATE_FAILED:
        "Ett fel uppstod när beställningen skulle uppdateras, försök igen.",
    },
    dk: {
      DEVIATION_UPDATE_FAILED:
        "Der skete en fejl under opdatering af din ordre.",
    },
  } as const
)[language];
