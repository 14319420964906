import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

const getIntercomLauncherElement = () =>
  document.getElementsByClassName("intercom-app")[0] ||
  document.getElementsByClassName("intercom-lightweight-app")[0];

export const useIntercom = (show?: boolean) => {
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);

  const showBubble = useCallback(() => {
    const el = getIntercomLauncherElement();

    if (!el) {
      return;
    }

    setIsReady(true);

    el.setAttribute("style", "display: block !important");
  }, []);

  const hideBubble = useCallback(() => {
    const el = getIntercomLauncherElement();

    if (!el) {
      return;
    }

    setIsReady(false);

    el.setAttribute("style", "display: none !important");
  }, []);

  useEffect(() => {
    // Intercom might not be loaded yet, so we need to wait for it (up to 5 seconds)
    let count = 0;
    const interval = setInterval(() => {
      if (count > 10) {
        clearInterval(interval);
        setIsUnavailable(true);
        return;
      }

      const el = getIntercomLauncherElement();
      setIsReady(!!el);

      if (!el) {
        count += 1;
        return;
      }

      if (show === false) {
        hideBubble();
      }

      clearInterval(interval);
    }, 500);

    return () => {
      clearInterval(interval);
      showBubble();
    };
  }, [router.isReady, show, showBubble, hideBubble]);

  return {
    isReady,
    isUnavailable,
    showBubble,
    hideBubble,
  };
};
