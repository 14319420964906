import { useLoyalty } from "@chef/feature-flags";

import { LoyaltyTierIcon } from "./LoyaltyTierIcon";

interface CircularLoyaltyProgressProps {
  inverted?: boolean;
}

export const CircularLoyaltyProgress = ({
  inverted = false,
}: CircularLoyaltyProgressProps) => {
  const { data } = useLoyalty();

  return (
    <div className="flex items-center justify-center">
      <LoyaltyTierIcon
        progress={data?.progress || { current: 0, projected: 0 }}
        inverted={inverted}
        tierId={data?.currentLevel || 1}
      />
    </div>
  );
};
