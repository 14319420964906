import { createListenerMiddleware } from "@reduxjs/toolkit";

import startPersistListener from "./persist";
import startSetDeviationListener from "./setDeviation";
import startSetRecipeModalListener from "./setRecipeModal";
import startToggleCartModalListener from "./toggleCart";
import startSetUpdateAddonToDeviationListener from "./updateAddonToDeviation";
import startSetStandaloneProductModalListener from "./setStandaloneProductModal";
import startSetSignupCouponListener from "./setSignupCoupon";
import startSubmitCouponListener from "./submitCoupon";
import startSubmitGiftcardListener from "./submitGiftcard";
import startRegistrationListener from "./registration";
import startSetAddonProductsListener from "./setAddonProducts";
import startSendTellAFriendSmsListener from "./sendRecruitmentSms";
import startSignupListener from "./signup";
import startUpdateBillingAgreementListener from "./updateBillingAgreement";
import startCreateOrUpdateMultipleSchedulersListener from "./createOrUpdateMultipleSchedulers";
import startCreateOrUpdateSchedulerListener from "./createOrUpdateScheduler";
import startUpdateAddressListener from "./updateAddress";
import startCreateAddressListener from "./createAddress";
import startUpdateContactPreferencesListener from "./updateContactPreferences";
import startUpdateUserInformationListener from "./updateUserInformation";
import startUpdatePasswordListener from "./updatePassword";
import startUpdateUserConsentListener from "./updateUserConsent";
import startErrorsListener from "./errors";
import startSubmitRatingAndCommentRecipeListener from "./rateAndCommentRecipe";
import startDeleteCommentFromRecipeListener from "./deleteCommentFromRecipe";
import startValidateBasketMutationListener from "./validateBasketMutation";
import startCreateOrUpdateDeviationMutationListener from "./createOrUpdateDeviationMutation";
import startUpdateBillingAgreementAddonsListener from "./updateBillingAgreementAddons";
import startDeliveryRatingListener from "./deliveryRating";

export const listenerMiddleware = createListenerMiddleware();

startPersistListener(listenerMiddleware);
startSetDeviationListener(listenerMiddleware);
startSetRecipeModalListener(listenerMiddleware);
startToggleCartModalListener(listenerMiddleware);
startSetUpdateAddonToDeviationListener(listenerMiddleware);
startSetStandaloneProductModalListener(listenerMiddleware);
startSetSignupCouponListener(listenerMiddleware);
startSubmitCouponListener(listenerMiddleware);
startSubmitGiftcardListener(listenerMiddleware);
startRegistrationListener(listenerMiddleware);
startSetAddonProductsListener(listenerMiddleware);
startSendTellAFriendSmsListener(listenerMiddleware);
startSignupListener(listenerMiddleware);
startUpdateBillingAgreementListener(listenerMiddleware);
startCreateOrUpdateMultipleSchedulersListener(listenerMiddleware);
startCreateOrUpdateSchedulerListener(listenerMiddleware);
startUpdateAddressListener(listenerMiddleware);
startCreateAddressListener(listenerMiddleware);
startUpdateContactPreferencesListener(listenerMiddleware);
startUpdateUserInformationListener(listenerMiddleware);
startUpdatePasswordListener(listenerMiddleware);
startUpdateUserConsentListener(listenerMiddleware);
startErrorsListener(listenerMiddleware);
startSubmitRatingAndCommentRecipeListener(listenerMiddleware);
startDeleteCommentFromRecipeListener(listenerMiddleware);
startValidateBasketMutationListener(listenerMiddleware);
startCreateOrUpdateDeviationMutationListener(listenerMiddleware);
startUpdateBillingAgreementAddonsListener(listenerMiddleware);
startDeliveryRatingListener(listenerMiddleware);

export * from "./util";
