import * as React from "react";
import { SVGProps } from "react";
const Setting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 10C19 9.09591 18.2677 8.36364 17.3636 8.36364H16.3311C16.1765 7.76473 15.9408 7.20018 15.634 6.67982L16.3638 5.95C17.0028 5.311 17.0028 4.27518 16.3638 3.63618C15.7248 2.99718 14.689 2.99718 14.05 3.63618L13.3202 4.366C12.7998 4.05918 12.2353 3.82355 11.6364 3.66891V2.63636C11.6364 1.73309 10.9041 1 10 1C9.09591 1 8.36364 1.73227 8.36364 2.63636V3.66891C7.76473 3.82355 7.20018 4.05918 6.67982 4.366L5.95 3.63618C5.311 2.99718 4.27518 2.99718 3.63618 3.63618C2.99718 4.27518 2.99718 5.311 3.63618 5.95L4.366 6.67982C4.05918 7.20018 3.82355 7.76473 3.66891 8.36364H2.63636C1.73309 8.36364 1 9.09591 1 10C1 10.9041 1.73227 11.6364 2.63636 11.6364H3.66891C3.82355 12.2353 4.05918 12.7998 4.366 13.3202L3.63618 14.05C2.99718 14.689 2.99718 15.7248 3.63618 16.3638C4.27518 17.0028 5.311 17.0028 5.95 16.3638L6.67982 15.634C7.19936 15.9408 7.76473 16.1765 8.36364 16.3311V17.3636C8.36364 18.2669 9.09591 19 10 19C10.9041 19 11.6364 18.2677 11.6364 17.3636V16.3311C12.2353 16.1765 12.7998 15.9408 13.3202 15.634L14.05 16.3638C14.689 17.0028 15.7248 17.0028 16.3638 16.3638C17.0028 15.7248 17.0028 14.689 16.3638 14.05L15.634 13.3202C15.9408 12.8006 16.1765 12.2353 16.3311 11.6364H17.3636C18.2669 11.6364 19 10.9041 19 10Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99947 12.4545C11.3551 12.4545 12.454 11.3556 12.454 9.99995C12.454 8.64435 11.3551 7.54541 9.99947 7.54541C8.64386 7.54541 7.54492 8.64435 7.54492 9.99995C7.54492 11.3556 8.64386 12.4545 9.99947 12.4545Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Setting;
