import { PRODUCT_CATEGORY_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { isOneSubProduct } from "@chef/helpers";

import {
  ICampaignBasket,
  IDeviationBasket,
  IDeviationProduct,
} from "../features";
import {
  CalendarQuery,
  OrdersQuery,
  PickAndMixQuery,
  PreselectedProductsForWeekQuery,
  ProductsByCategoriesQuery,
} from "../graphql/generated";
import { GetPriceOfPickAndMixProduct } from "../hooks/usePickAndMixPrice";
import {
  convertFromPortionsToPlusPortions,
  getAttribute,
  getPortionsValueFromVariationWithPlus,
  getVariationFromPickAndMix,
  getVariationFromProductsByCategories,
  isAddonProduct,
  isFinancialProduct,
  isLimitedQuantityProduct,
  isMealboxProduct,
  isPickAndMixProduct,
  isStandaloneProduct,
  isVariationPlusPortions,
} from "./product";
import { getLoyaltyFee } from "./loyalty";

// TODO: oncce fully merged to OneSub this financial product logic should be removed
export const getFinancialVariationFromBasket = (args: {
  basketProducts: IDeviationBasket["products"];
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { basketProducts, productsByCategories } = args;

  const financialProduct = basketProducts.find(isFinancialProduct);
  if (!financialProduct) {
    return null;
  }

  const financialVariation = getVariationFromProductsByCategories({
    productCategoryId: PRODUCT_CATEGORY_IDS.FINANCIAL,
    productsByCategories,
    variationId: financialProduct.variationId,
  });

  return financialVariation;
};

export const getMealboxVariationFromBasket = (args: {
  basketProducts: IDeviationBasket["products"];
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { basketProducts, productsByCategories } = args;

  const mealboxProduct = basketProducts.find(isOneSubProduct);
  if (!mealboxProduct) {
    return null;
  }

  const mealboxVariation = getVariationFromProductsByCategories({
    productCategoryId: PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
    productsByCategories,
    variationId: mealboxProduct.variationId,
  });

  return mealboxVariation;
};

export const getPreselectedMealboxIdFromBasket = (args: {
  basketProducts: IDeviationBasket["products"];
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { basketProducts, productsByCategories } = args;

  // if we have a mealbox in the basket
  const mealboxInBasket = basketProducts.find(isMealboxProduct);
  let preselectedMealboxProductId = mealboxInBasket?.productId;

  // if we didn't have a mealbox in the basket
  if (!preselectedMealboxProductId) {
    const financialVariation = getFinancialVariationFromBasket({
      basketProducts,
      productsByCategories,
    });
    if (!financialVariation) {
      return null;
    }

    preselectedMealboxProductId = getAttribute(
      "preselected_for_mealbox_product_id",
      financialVariation,
    );
    if (!preselectedMealboxProductId) {
      return null;
    }
  }

  return preselectedMealboxProductId;
};

interface IGetBasketFinancesArgs<T extends ICampaignBasket | IDeviationBasket> {
  basketProducts: T["products"];
  customerFee?: number;
  loyaltyLevel?: number;
}

export const getBasketFinances = <T extends ICampaignBasket | IDeviationBasket>(
  args: IGetBasketFinancesArgs<T>,
) => {
  const { basketProducts, customerFee, loyaltyLevel } = args;

  const deliveryFeeAfterLoyalty = getLoyaltyFee({
    loyaltyLevel: loyaltyLevel || 0,
    customerFee: customerFee || 0,
  });

  const finances = {
    addon: 0,
    pickAndMix: 0,
    financial: 0,
    mealbox: 0,
    total: deliveryFeeAfterLoyalty,
    customerFee: deliveryFeeAfterLoyalty,
  };

  for (const product of basketProducts) {
    const price = product.price * product.quantity;

    finances.total += price;
    if (isFinancialProduct(product)) {
      finances.financial += price;
    } else if (isMealboxProduct(product)) {
      finances.mealbox += price;
    } else if (isPickAndMixProduct(product)) {
      finances.pickAndMix += price;
    } else if (
      isAddonProduct(product) ||
      isStandaloneProduct(product) ||
      isLimitedQuantityProduct(product)
    ) {
      finances.addon += price;
    }
  }

  return finances;
};

export const DISCOUNT_TYPE = {
  MULTIPLICATIVE: 1,
  ADDITIVE: 2,
} as const;

type DiscountType = (typeof DISCOUNT_TYPE)[keyof typeof DISCOUNT_TYPE];

interface IGetBasketFinancesWithDiscount
  extends IGetBasketFinancesArgs<IDeviationBasket> {
  discounts: {
    amount: number;
    type?: DiscountType;
    amountType?: DiscountType;
  }[];
}

export const getBasketFinancesWithDiscount = (
  args: IGetBasketFinancesWithDiscount,
) => {
  const { discounts } = args;

  const basketFinances = getBasketFinances(args);
  if (!basketFinances) {
    return null;
  }

  const finances = {
    ...basketFinances,
    discount: 0,
  };

  for (const discount of discounts) {
    if (!discount.amount) {
      continue;
    }

    const type = discount.amountType || discount.type;

    let discountAmount = 0;
    if (type === DISCOUNT_TYPE.MULTIPLICATIVE) {
      discountAmount =
        finances.total - (finances.total * (100 - discount.amount)) / 100;
    } else if (type === DISCOUNT_TYPE.ADDITIVE) {
      discountAmount = discount.amount;
    }

    // in case the discountAmount is higher than the total
    finances.discount += Math.min(discountAmount, finances.total);
    finances.total = Math.max(0, finances.total - discountAmount);
  }

  return finances;
};

type NewDeviationMinimumType = Pick<
  IDeviationBasket["products"][0],
  "productId" | "productTypeId" | "quantity"
> & {
  // needs to be passed on PickAndMix if you want to support plus portions
  variationId?: string;
};

export const getNewBasketDeviation = (args: {
  getPriceOfPickAndMixProduct: GetPriceOfPickAndMixProduct;
  // minimum version ONLY applies to financial and pickAndMix
  basketProducts: NewDeviationMinimumType[];
  // requires financial products
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
  pickAndMix: PickAndMixQuery["pickAndMix"];
  // this cannot be plus portion
  portions: number;

  // NOTE: this is only intended to be used by week editor
  updateProduct?: {
    productId: string;
    variationId: string;
  };
}) => {
  const {
    getPriceOfPickAndMixProduct,
    basketProducts,
    productsByCategories,
    pickAndMix,
    portions,
    updateProduct,
  } = args;

  let financialProduct: IDeviationBasket["products"][0] | null = null;
  const pickAndMixProducts: IDeviationBasket["products"] = [];
  const otherProducts: IDeviationBasket["products"] = [];

  for (const product of basketProducts) {
    if (isFinancialProduct(product) || isOneSubProduct(product)) {
      financialProduct = {
        price: 0,
        variationId: "",
        ...product,
      };
    } else if (isPickAndMixProduct(product)) {
      pickAndMixProducts.push({
        price: 0,
        variationId: "",
        ...product,
      });
    } else {
      otherProducts.push(product as IDeviationBasket["products"][0]);
    }
  }

  if (!financialProduct) {
    return null;
  }

  // update our pickAndMixProducts with the update
  if (updateProduct) {
    const idx = pickAndMixProducts.findIndex(
      (p) => p.productId === updateProduct.productId,
    );
    if (idx !== -1) {
      pickAndMixProducts.splice(idx, 1);
    } else {
      const product = pickAndMix.find((pnm) =>
        isEqualStrings(pnm.productId, updateProduct.productId),
      )?.product;
      if (!product) {
        return null;
      }

      pickAndMixProducts.push({
        price:
          getPriceOfPickAndMixProduct({
            product,
            variationId: updateProduct.variationId,
          }) || 0,
        productId: updateProduct.productId,
        productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
        quantity: 1,
        variationId: updateProduct.variationId,
      });
    }
  }

  // update our financial product
  let found = false;
  for (const pbc of productsByCategories) {
    if (
      ![
        PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN as string,
        PRODUCT_CATEGORY_IDS.FINANCIAL as string,
      ].includes(pbc.productCategoryId)
    ) {
      continue;
    }

    for (const product of pbc.products) {
      if (!isEqualStrings(product.productId, financialProduct.productId)) {
        continue;
      }

      // make sure we have a financial variation that fits this meal size
      const mealSizes = product.variations.map(
        (v) => +(getAttribute("Meals", v) || 0),
      );

      const hasFinancialVariationForThisMealSize = mealSizes.includes(
        pickAndMixProducts.length,
      );

      for (const variation of product.variations) {
        const p = +(getAttribute("Portions", variation) || 0);
        const meals = +(getAttribute("Meals", variation) || 0);

        // if meal/portion doesn't match, it isn't the correct financial variation
        if (
          hasFinancialVariationForThisMealSize &&
          meals !== pickAndMixProducts.length
        ) {
          continue;
        }
        if (p !== portions) {
          continue;
        }

        found = true;
        financialProduct = {
          ...financialProduct,
          price: variation.finalPrice,
          variationId: variation.variationId,
          quantity: 1,
        };
      }
    }
  }
  // if we didn't find the new financial variation, it means it doesn't exist
  // this should never happen
  if (!found) {
    console.error(args);
    return null;
  }

  // update the pick and mix products with the correct portion sizes
  const newPickAndMixProducts: IDeviationBasket["products"] = [];
  for (const pnm of pickAndMix) {
    for (const product of pickAndMixProducts) {
      if (!isEqualStrings(pnm.productId, product.productId)) {
        continue;
      }

      const currentVariation = getVariationFromPickAndMix({
        pickAndMix,
        productId: product.productId,
        variationId: product.variationId,
      });
      if (product.variationId && !currentVariation) {
        continue;
      }

      // get the preferred portions to lookup
      const portionsToLookup =
        currentVariation && isVariationPlusPortions(currentVariation)
          ? convertFromPortionsToPlusPortions(portions)
          : portions;

      // find newVariation using portionsToLookup
      let newVariation = pnm.product.variations.find(
        (v) => getPortionsValueFromVariationWithPlus(v) === portionsToLookup,
      );
      // if we didn't find the new variation, try finding it using the given portions value
      if (!newVariation) {
        newVariation = pnm.product.variations.find(
          (v) => getPortionsValueFromVariationWithPlus(v) === portions,
        );
      }
      if (!newVariation) {
        continue;
      }

      newPickAndMixProducts.push({
        ...product,
        variationId: newVariation.variationId,
        price:
          getPriceOfPickAndMixProduct({
            product: pnm.product,
            variationId: newVariation.variationId,
            basketProducts: [financialProduct],
          }) || 0,
      });
    }
  }

  return [financialProduct, ...newPickAndMixProducts, ...otherProducts];
};

export const getPreselectedDeviationForBasket = (args: {
  preselectedProductsForWeek: PreselectedProductsForWeekQuery["preselectedProductsForWeek"];
  // this needs to contain the [financial, mealbox] productCategories
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
  pickAndMixProducts: PickAndMixQuery["pickAndMix"];
  selectedProducts: IDeviationBasket["products"];
  signup?: boolean;
}): IDeviationBasket["products"] | null => {
  const {
    preselectedProductsForWeek,
    selectedProducts,
    productsByCategories,
    pickAndMixProducts,
    signup,
  } = args;

  const mealbox = selectedProducts.find(isMealboxProduct);
  // if we don't have a mealbox, it means we have a deviation already
  if (!mealbox) {
    return null;
  }

  const preselected = preselectedProductsForWeek.find(
    (p) => p.productId === mealbox.productId,
  );
  if (!preselected) {
    return null;
  }

  // get our mealbox variation from productsByCategories
  const mealboxVariation = getVariationFromProductsByCategories({
    variationId: mealbox.variationId,
    productCategoryId: signup
      ? PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT
      : PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
    productsByCategories,
  });

  if (!mealboxVariation) {
    return null;
  }

  const financialVariationId = getAttribute(
    "flex_financial_variation_id",
    mealboxVariation,
  );
  if (!financialVariationId) {
    return null;
  }

  // get the financial variation for our financial product
  const financialVariation = getVariationFromProductsByCategories({
    variationId: financialVariationId,
    productCategoryId: PRODUCT_CATEGORY_IDS.FINANCIAL,
    productsByCategories,
  });

  if (!financialVariation) {
    return null;
  }

  const meals = +(getAttribute("Meals", financialVariation) || 0);
  const portions = +(getAttribute("Portions", financialVariation) || 0);
  if (!meals || !portions) {
    return null;
  }

  // generate the basket
  const deviations: IDeviationBasket["products"] = [];
  for (const preselectedProduct of preselected.preselectedProducts) {
    // if we've already gotten all the products we need
    if (deviations.length === meals) {
      break;
    }

    for (const pnm of pickAndMixProducts) {
      // if it's a different pick and mix product
      if (!isEqualStrings(pnm.productId, preselectedProduct.productId)) {
        continue;
      }

      // find the correct pick and mix variation using portion size
      for (const variation of pnm.product.variations) {
        const p = +(getAttribute("Portions", variation) || 0);
        if (p !== portions) {
          continue;
        }

        deviations.push({
          productId: pnm.productId,
          variationId: variation.variationId,
          productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
          quantity: 1,
          // price will always be 0 because it's preselected products
          price: 0,
        });
      }
    }
  }

  // add financial product
  deviations.push({
    productId: financialVariation.product.productId,
    variationId: financialVariation.variationId,
    productTypeId: PRODUCT_TYPE_IDS.FINANCIAL,
    quantity: 1,
    price: financialVariation.finalPrice,
  });

  // the other products that were in our old basket
  const oldProducts = selectedProducts.filter((p) => !isMealboxProduct(p));

  return [...deviations, ...oldProducts];
};

export const getProductsFromOrderLines = (
  basketProducts: OrdersQuery["orders"][number]["orderLines"],
) => {
  return basketProducts?.map((basketProduct) => {
    const { pricePerQuantity, quantity, variation } = basketProduct;

    const { productId, productTypeId } = variation.product;
    return {
      price: pricePerQuantity,
      quantity,
      productId,
      productTypeId,
      variationId: variation.variationId,
    };
  });
};

export const getBasketForCalendarWeek = (args: {
  calendarWeek: CalendarQuery["calendar"][number];
}) => {
  const { calendarWeek } = args;

  return calendarWeek.baskets.find(
    (basket) =>
      basket.week === calendarWeek.week && basket.year === calendarWeek.year,
  );
};

export const compareBasketsProducts = (
  basketA: IDeviationProduct[],
  basketB: IDeviationProduct[],
) => {
  const productIds1 = basketA.map((item) => item.productId);
  const productIds2 = basketB.map((item) => item.productId);

  if (productIds1.length !== productIds2.length) {
    return false;
  }

  productIds1.sort();
  productIds2.sort();

  for (let i = 0; i < productIds1.length; i++) {
    if (productIds1[i] !== productIds2[i]) {
      return false;
    }
  }

  return true;
};
