import { Dinner, Plus } from "@chef/icons/small";
import { Button, Card } from "../components";

interface UpsaleCardProps {
  text: string;
  href?: string;
  onClick?: () => void;
}

const UpsaleCard = ({ text, href, onClick }: UpsaleCardProps) => {
  return (
    <Card
      className="flex flex-col items-center justify-center flex-grow w-full h-full space-y-3 text-center !bg-grey-2 px-2 md:px-12"
      noPadding
      noShadow
    >
      <Dinner className="w-10 h-10" />
      <span>{text}</span>
      <Button
        outlined
        href={href}
        onClick={onClick}
        className="flex items-center"
        IconLeft={() => <Plus className="mr-1" />}
        tiny
      >
        Velg middag
      </Button>
    </Card>
  );
};

export default UpsaleCard;
