import * as React from "react";
import { SVGProps } from "react";
const TimeSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_33415_329214)">
      <path
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#F4F4F1"
      />
      <path
        d="M56.5001 20.5V28H49.0001M40.0001 60.25C36.5405 60.2497 33.1387 59.363 30.1191 57.6746C27.0995 55.9862 24.5629 53.5523 22.751 50.6051C20.9392 47.6579 19.9127 44.2957 19.7693 40.8391C19.626 37.3825 20.3706 33.9468 21.9322 30.8596C23.4938 27.7725 25.8202 25.137 28.6896 23.2044C31.5591 21.2717 34.8758 20.1064 38.3235 19.8197C41.7711 19.5329 45.2348 20.1342 48.3841 21.5663C51.5334 22.9983 54.2633 25.2133 56.3134 28M49.7389 57.7585C48.094 58.663 46.3307 59.3327 44.5001 59.7483M56.3134 52C55.2964 53.38 54.1092 54.6261 52.7801 55.7088M59.3434 46.0098C59.0275 47.0276 58.6313 48.0187 58.1584 48.9738"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 42.25C41.2426 42.25 42.25 41.2426 42.25 40C42.25 38.7574 41.2426 37.75 40 37.75C38.7574 37.75 37.75 38.7574 37.75 40C37.75 41.2426 38.7574 42.25 40 42.25Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4707 41.6958L49 47.5M40 25V37.75"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33415_329214">
        <rect width={80} height={80} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TimeSwap;
