import { BRAND_NAME } from "./misc";

export const TRUSTPILOT = (
  {
    AMK: {
      BUSINESS_UNIT_ID: "58d17bc90000ff00059eefcb",
      TEMPLATE_ID: "53aa8807dec7e10d38f59f32",
      LOCALE: "nb-NO",
    },
    LMK: {
      BUSINESS_UNIT_ID: "4bdfd4610000640005073019",
      TEMPLATE_ID: "53aa8807dec7e10d38f59f32",
      LOCALE: "sv-SE",
    },
    GL: {
      BUSINESS_UNIT_ID: "55f03cca0000ff0005831ed4",
      TEMPLATE_ID: "53aa8807dec7e10d38f59f32",
      LOCALE: "nb-NO",
    },
    RN: {
      BUSINESS_UNIT_ID: "46d29ac600006400050097aa",
      TEMPLATE_ID: "5613c9cde69ddc09340c6beb",
      LOCALE: "da-DK",
    },
  } as const
)[BRAND_NAME];
