interface PrizeWithCheckMarkProps extends React.SVGProps<SVGSVGElement> {
  completed: boolean;
  hasCompetitionEnded: boolean;
}

export const PrizeWithCheckMark = ({
  completed,
  hasCompetitionEnded,
  ...props
}: PrizeWithCheckMarkProps) => {
  const competitionEndedReqNotMet = !completed && hasCompetitionEnded;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_32130_321753)">
        <path
          d="M60.8534 120.018C74.7446 120.018 86.0057 118.892 86.0057 117.504C86.0057 116.115 74.7446 114.989 60.8534 114.989C46.9622 114.989 35.7012 116.115 35.7012 117.504C35.7012 118.892 46.9622 120.018 60.8534 120.018Z"
          fill="#DFDBE4"
        />
        <path
          d="M84.4725 3.21218C86.0571 2.93559 87.5914 3.99165 87.8681 5.60088C88.1447 7.18497 87.0883 8.71878 85.4786 8.99536C83.894 9.27195 82.3597 8.21589 82.083 6.60666C81.8064 5.02257 82.8628 3.48877 84.4725 3.21218Z"
          fill="#614B79"
        />
        <path
          d="M78.5756 15.8652C78.4499 15.1863 78.9026 14.5325 79.6069 14.4068C80.286 14.2811 80.9399 14.7337 81.0657 15.4377C81.1915 16.1166 80.7387 16.7704 80.0345 16.8961C79.3554 17.0218 78.7014 16.5692 78.5756 15.8652Z"
          fill="#614B79"
        />
        <path
          d="M25.513 20.2731C25.5884 18.9907 26.6951 18.0101 27.9779 18.1107C29.2606 18.1861 30.2416 19.2925 30.141 20.5748C30.0655 21.8572 28.9588 22.8378 27.6761 22.7372C26.3933 22.6618 25.4124 21.5554 25.513 20.2731Z"
          fill="#614B79"
        />
        <path
          d="M68.7886 21.7112C61.3687 22.365 58.2247 39.8905 57.2186 47.4087C57.0928 48.2636 57.445 49.1185 58.0989 49.6717C58.6271 50.0991 59.2811 50.3003 59.935 50.25C60.1111 50.25 60.2872 50.1997 60.4884 50.1494C67.7071 47.8613 84.4585 41.7261 83.8045 34.2834C83.1506 26.6898 76.4098 21.0575 68.8138 21.7112H68.7886ZM67.3298 32.9759C70.172 32.7244 72.6621 34.8365 72.9387 37.4264C72.5112 38.583 68.6377 40.7706 64.0097 42.6061C65.0158 37.7281 66.4495 33.5542 67.3298 32.9759Z"
          fill="#614B79"
        />
        <path
          d="M62.1043 46.9913C59.8154 39.7749 53.6783 23.0288 46.2332 23.6825C38.6372 24.3363 33.0031 31.075 33.6571 38.6685C34.3111 46.0861 51.8422 49.2291 59.3627 50.2349C59.5388 50.2349 59.74 50.26 59.916 50.2349C60.57 50.1846 61.1988 49.8577 61.6264 49.3548C62.1797 48.7011 62.3558 47.7959 62.1043 46.9661V46.9913ZM44.9505 40.1269C44.6989 37.2856 46.8117 34.7963 49.4276 34.5197C50.5846 34.9472 52.7728 38.8194 54.6089 43.4459C49.7294 42.4402 45.5541 41.0069 44.9756 40.1269H44.9505Z"
          fill="#614B79"
        />
        <path
          d="M52.1877 48.415C51.8355 44.2662 54.9041 40.5952 59.0542 40.2431C63.2044 39.8911 66.8766 42.9587 67.2287 47.1075L52.1877 48.415Z"
          fill="#614B79"
        />
        <path
          d="M90.6934 45.0576L28.668 50.4887L37.8234 99.7715C38.0498 101.054 39.2319 101.934 40.5147 101.808L87.9518 97.6594C89.2346 97.5588 90.2406 96.4776 90.2406 95.1701L90.6934 45.0324V45.0576Z"
          fill="#F4E5CE"
        />
        <path
          d="M25.3809 53.501L27.6194 66.6766L94.5747 60.818L94.4741 47.4664C94.4741 46.0081 93.1913 44.8514 91.7325 44.9771L27.6446 50.5843C26.1857 50.71 25.1294 52.0678 25.3809 53.501Z"
          fill="#F4E5CE"
        />
        <path
          d="M67.2253 47.0797L52.1914 48.3945L56.7401 100.37L71.7741 99.0555L67.2253 47.0797Z"
          fill="#AE172D"
        />
        <path
          d="M90.5181 69.1778L90.5936 61.1316L31.6367 66.2862L33.1207 74.2066L90.5181 69.1778Z"
          fill="#614B79"
        />
        <path
          d="M67.2253 47.112L52.1914 48.4269L56.7401 100.403L71.7741 99.0878L67.2253 47.112Z"
          fill="#614B79"
        />
        {(completed || hasCompetitionEnded) && (
          <g filter="url(#filter0_dd_32130_321753)">
            <rect x="75.5" y="83" width="24" height="24" rx="12" fill="white" />
            <rect
              x="76"
              y="83.5"
              width="23"
              height="23"
              rx="11.5"
              stroke="white"
            />
          </g>
        )}
        {completed && (
          <g clipPath="url(#clip1_32130_321753)">
            <path
              d="M82.5 95.5L85.8333 99L92.5 92"
              stroke="#285C4D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </g>
        )}

        {competitionEndedReqNotMet && (
          <g
            xmlns="http://www.w3.org/2000/svg"
            clipPath="url(#clip1_32154_321334)"
          >
            <path
              d="M92.5 90L82.5 100"
              stroke="#AE172D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M82.5 90L92.5 100"
              stroke="#AE172D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </g>
        )}
      </g>
      <defs>
        <filter
          id="filter0_dd_32130_321753"
          x="63.5"
          y="75"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.207843 0 0 0 0 0.215686 0 0 0 0 0.227451 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_32130_321753"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_32130_321753"
            result="effect2_dropShadow_32130_321753"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_32130_321753"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_32130_321753">
          <rect
            width="95"
            height="120"
            fill="white"
            transform="translate(12.5)"
          />
        </clipPath>
        <clipPath id="clip1_32130_321753">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(81.5 89)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
