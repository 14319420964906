import { ListenerMiddlewareInstance, isAnyOf } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { showNotification, userApi } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      USER_CONSENT_UPDATED: "Dine samtykker er oppdatert",
    },
    se: {
      USER_CONSENT_UPDATED: "Dina samtycken har uppdaterats",
    },
    dk: {
      USER_CONSENT_UPDATED: "Dine samtykker er blevet opdateret",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      userApi.endpoints.updateUserConsents.matchFulfilled,
      userApi.endpoints.updateUserConsents.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.USER_CONSENT_UPDATED,
        });
      }
    },
  });
};
