import clsx from "clsx";
import { useBillingQuery, useUserInfoQuery } from "@chef/state-management";
import { formatDate, replaceDynamicText } from "@chef/helpers";

interface DynamicTextProps {
  blocks: any;
  className?: string;
  allowLineBreaks?: boolean;
  dynamicValues?: any;
}

export const DynamicText = ({
  blocks,
  allowLineBreaks = false,
  dynamicValues,
  className,
}: DynamicTextProps) => {
  const { data: billingQuery } = useBillingQuery();
  const { data: userInfoQuery } = useUserInfoQuery();

  if (!billingQuery || !userInfoQuery || !blocks) {
    return null;
  }

  const referralCode = billingQuery.billing.couponCode;
  const firstName = userInfoQuery.userInfo.firstName;

  const {
    invitesRequired,
    deliveriesRequired,
    deliveryCutoffDate,
    endDate,
    prizeValue,
  } = dynamicValues || {};

  const competitionDeliveryCutOffDate =
    deliveryCutoffDate && formatDate(deliveryCutoffDate, "yyyy-MM-dd");

  const competitionEndDate = endDate && formatDate(endDate, "yyyy-MM-dd");

  return (
    <>
      {blocks.map((block: any) => {
        const text = replaceDynamicText({
          block,
          firstName,
          referralCode,
          invitesRequired,
          deliveriesRequired,
          competitionDeliveryCutOffDate,
          competitionEndDate,
          prizeValue,
        });
        return (
          <div
            key={block._key}
            className={clsx(className, allowLineBreaks && "mb-3")}
          >
            {text}
          </div>
        );
      })}
    </>
  );
};
