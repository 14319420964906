import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      LOGGED_OUT: "Utlogget",
      YOU_ARE_NOW_LOGGED_OUT: "Du er nå utlogget.",
      IF_YOU_WANT_TO_LOG_IN_AGAIN: "Hvis du vil logge inn igjen, kan du",
      CLICK_HERE: "klikke her",
      GO_TO_LANDING_PAGE: "Gå til forsiden",
      THANK_YOU_FOR_YOUR_VISIT: "Takk for besøket!",
    },
    se: {
      LOGGED_OUT: "Utloggad",
      YOU_ARE_NOW_LOGGED_OUT: "Du är nu utloggad.",
      IF_YOU_WANT_TO_LOG_IN_AGAIN: "Om du vill logga in igen kan du",
      CLICK_HERE: "klicka här",
      GO_TO_LANDING_PAGE: "Gå till startsidan",
      THANK_YOU_FOR_YOUR_VISIT: "Tack för besöket!",
    },
    dk: {
      LOGGED_OUT: "Logget ud",
      YOU_ARE_NOW_LOGGED_OUT: "Du er nu logget ud.",
      IF_YOU_WANT_TO_LOG_IN_AGAIN: "Hvis du vil logge ind igen, kan du",
      CLICK_HERE: "klikke her",
      GO_TO_LANDING_PAGE: "Gå til forsiden",
      THANK_YOU_FOR_YOUR_VISIT: "Tak for besøget!",
    },
  } as const
)[language];
