import * as React from "react";
import { SVGProps } from "react";
const Help = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={18} height={18} rx={9} fill="currentColor" />
    <path
      d="M7.36709 6.17083C7.51899 5.55833 8.21772 5.0625 9.02279 5.0625C9.93418 5.0625 10.557 5.66042 10.557 6.40417C10.557 7.97917 7.91392 8.27083 7.91392 10.0792C7.91392 10.6188 8.17215 11.1875 8.79494 11.6396L9.7519 10.6771C9.49367 10.4583 9.35696 10.2542 9.35696 10.0646C9.35696 9.30625 12 8.65 12 6.40417C12 4.94583 10.7392 3.75 9.02279 3.75C7.54937 3.75 6.3038 4.61042 6 5.79167L7.36709 6.17083ZM7.89873 13.2292C7.89873 13.7979 8.36962 14.25 8.96203 14.25C9.55443 14.25 10.0253 13.7979 10.0253 13.2292C10.0253 12.6604 9.55443 12.2083 8.96203 12.2083C8.36962 12.2083 7.89873 12.6604 7.89873 13.2292Z"
      fill="white"
    />
  </svg>
);
export default Help;
