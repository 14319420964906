import * as React from "react";
import { SVGProps } from "react";
const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    {...props}
  >
    <path
      d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 35.9791 8.77644 45.908 20.25 47.7085V30.9375H14.1563V24H20.25V18.7125C20.25 12.6975 23.833 9.37501 29.3152 9.37501C31.941 9.37501 34.6875 9.84375 34.6875 9.84375V15.75H31.6612C28.6798 15.75 27.75 17.6 27.75 19.498V24H34.4063L33.3422 30.9375H27.75V47.7085C39.2236 45.908 48 35.9791 48 24Z"
      fill="#1877F2"
    />
  </svg>
);
export default FacebookIcon;
