import * as React from "react";
import { SVGProps } from "react";
const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 13C13.5864 13 9 20 9 20C9 20 13.5864 27 20 27C26.4136 27 31 20 31 20C31 20 26.4136 13 20 13Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <path
      d="M20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
export default Eye;
