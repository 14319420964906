import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface CompetitionDisclosureProps {
  open: boolean;
}

const initialState = {
  competition: {
    open: false,
  } as CompetitionDisclosureProps,
};

export const competitionDisclosureSlice = createSlice({
  name: "competitionDisclosure",
  initialState,
  reducers: {
    setCompetitionDisclosureOpen: (
      state,
      action: PayloadAction<CompetitionDisclosureProps>,
    ) => {
      state.competition.open = action.payload.open;
    },
    toggleCompetitionDisclosureOpen: (state) => {
      state.competition.open = !state.competition.open;
    },
  },
});

export const { setCompetitionDisclosureOpen, toggleCompetitionDisclosureOpen } =
  competitionDisclosureSlice.actions;

export const selectCompetitionDisclosureOpen = (state: RootState) =>
  state.competitionDisclosure.competition.open;

export default competitionDisclosureSlice.reducer;
