import * as React from "react";
import { SVGProps } from "react";
const Delivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31 24H34L33.5 17L30.5 16.5L29 11H9L8 24H11M26 24H16"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={11}
      y={22}
      width={5}
      height={5}
      rx={2.5}
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={26}
      y={22}
      width={5}
      height={5}
      rx={2.5}
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.99998 18C4.55226 18 4.99998 17.5523 4.99999 17C4.99999 16.4477 4.55228 16 4 16C3.44772 16 2.99999 16.4477 2.99999 17C2.99998 17.5523 3.44769 18 3.99998 18Z"
      fill="currentColor"
    />
    <path
      d="M1.00002 20C1.5523 20 2.00001 19.5523 2.00001 19C2 18.4477 1.55228 18 1 18C0.447715 18 3.79254e-06 18.4477 8.47089e-06 19C1.31492e-05 19.5523 0.447732 20 1.00002 20Z"
      fill="currentColor"
    />
    <path
      d="M5.00002 21C5.55231 21 6.00002 20.5523 6.00001 20C6 19.4477 5.55228 19 5 19C4.44772 19 4 19.4477 4.00001 20C4.00002 20.5523 4.44774 21 5.00002 21Z"
      fill="currentColor"
    />
  </svg>
);
export default Delivery;
