import * as React from "react";
import { SVGProps } from "react";
const House = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#F4F4F1" />
    <path
      d="M11.981 7.0498L7.06231 12.2639C7.04564 12.281 7.04564 12.315 7.06231 12.3321C7.06231 12.3321 7.07898 12.3491 7.09566 12.3491H8.32951V16.9498H10.8306V14.0531H13.1649V16.9498H15.6659V12.3491H16.8998C16.9165 12.3491 16.9498 12.3321 16.9498 12.315C16.9498 12.298 16.9498 12.298 16.9331 12.281L11.981 7.0498Z"
      stroke="currentColor"
      strokeMiterlimit={10}
    />
  </svg>
);
export default House;
