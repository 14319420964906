import { RecipeAndStepsQuery } from "@chef/state-management";

import { intl } from "./NutritionalContentBox.Intl";

type NutritionalContent =
  RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][number]["nutritionFacts"];

interface NutritionalContentBoxProps {
  nutritionalContent: NutritionalContent;
  type: "portion" | "gram";
}

type NutrientKey = NutritionalContent["recipeNutritionPer100g"];

export const NutritionalContentBox = ({
  nutritionalContent,
  type,
}: NutritionalContentBoxProps) => {
  const { recipeNutritionPerPortion, recipeNutritionPer100g } =
    nutritionalContent;

  const isMeasuredByPortion = type === "portion";

  const getNutrientValue = (nutrient: keyof NutrientKey) => {
    const nutritionSource = isMeasuredByPortion
      ? recipeNutritionPerPortion
      : recipeNutritionPer100g;

    if (!nutritionSource) {
      return "N/A";
    }

    const nutritionValue = nutritionSource[nutrient];

    const nutritionUnit = nutrient === "energyKcal" ? "kcal" : "g";

    if (!nutritionValue) {
      return "N/A";
    }

    return `${Math.round(nutritionValue)} ${nutritionUnit}`;
  };

  return (
    <div className="flex flex-col w-full">
      <h6>
        <strong>
          {isMeasuredByPortion
            ? intl.NUTRITION_PER_PORTION
            : intl.NUTRITION_PER_100_GRAM}
        </strong>
      </h6>
      <div className="pt-1 pb-2">
        <ul>
          <li>
            <strong>{intl.ENERGY}: </strong>
            {getNutrientValue("energyKcal")}
          </li>
          <li>
            <strong>{intl.FATS}: </strong>
            {getNutrientValue("fat")}
          </li>
          <li>
            <strong>{intl.CARBOHYDRATES}: </strong>
            {getNutrientValue("carbs")}
          </li>
          <li>
            <strong>{intl.PROTEIN}: </strong>
            {getNutrientValue("protein")}
          </li>
        </ul>
      </div>
      {/* <div className="grid grid-cols-3 pt-4 text-sm">
        <div className="col-span-2 pb-4 border-b border-black">
          <strong>Inhold</strong>
        </div>
        <div className="pb-4 text-right border-b border-black">
          <strong>Pr. portion</strong>
        </div>

        <div className="col-span-2 pt-6 pb-1">Fedt</div>
        <div className="pt-6 pb-1 text-right">{Math.round(recipeNutritionPerPortion.fat)} g</div>

        <div className="col-span-2 pb-4 text-xs text-grey-1">heraf mættede fedtsyrer</div>
        <div className="pb-4 text-xs text-right text-grey-1">
          {Math.round(recipeNutritionPerPortion.fatSaturated)} g
        </div>

        <div className="col-span-2 pt-4 pb-1">Kulhydrat</div>
        <div className="pt-4 pb-1 text-right">{Math.round(recipeNutritionPerPortion.carbs)} g</div>

        <div className="col-span-2 pb-4 text-xs text-grey-1">heraf sukkerarter</div>
        <div className="pb-4 text-xs text-right text-grey-1">
          {Math.round(recipeNutritionPerPortion.carbsSugar)} g
        </div>

        <div className="col-span-2 py-4">Protein</div>
        <div className="py-4 text-right">{Math.round(recipeNutritionPerPortion.protein)} g</div>

        <div className="col-span-2 py-4">Salt</div>
        <div className="py-4 text-right">{Math.round(recipeNutritionPerPortion.salt) || "N/A"} g</div>

        <div className="col-span-2 pt-4">Kostfibre</div>
        <div className="pt-4 text-right">{Math.round(recipeNutritionPerPortion.carbsFiber)} g</div>
      </div> */}
    </div>
  );
};
