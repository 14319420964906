import * as React from "react";
import { SVGProps } from "react";
const SantaHat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5513 9.12252L22.9245 8.21057L22.0182 7.82392C20.6366 7.23458 19.144 6.90918 17.6407 6.90918C11.0336 6.90918 5.85742 12.8632 5.85742 19.9365C5.85742 19.9454 5.85742 19.9544 5.85741 19.9637C5.8573 20.1933 5.85715 20.5206 5.92206 20.8604L6.07723 21.6728H6.90431H22.329H23.329V20.6728C23.329 18.5365 22.6031 16.5009 21.3649 14.8756L21.4492 13.9933C21.5179 13.274 21.8547 12.6566 22.4077 12.2479L23.1235 11.7188L22.681 10.9465C22.4821 10.5995 22.3914 10.279 22.3914 9.87282C22.3914 9.63352 22.4501 9.36981 22.5513 9.12252Z"
      fill="white"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M24.2045 12.8365C25.8353 12.8365 27.0798 11.4657 27.0798 9.87282C27.0798 8.2799 25.8353 6.90918 24.2045 6.90918C22.5736 6.90918 21.3291 8.2799 21.3291 9.87282C21.3291 11.4657 22.5736 12.8365 24.2045 12.8365Z"
      fill="white"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M22.7981 20.6547H6.38876C6.00664 20.6547 5.61725 20.7937 5.32567 21.099C5.03806 21.4002 4.91992 21.7845 4.91992 22.1456V24.6001C4.91992 24.9612 5.03806 25.3455 5.32567 25.6467C5.61725 25.952 6.00664 26.091 6.38876 26.091H22.7981C23.1802 26.091 23.5696 25.952 23.8611 25.6467C24.1488 25.3455 24.2669 24.9612 24.2669 24.6001V22.1456C24.2669 21.7845 24.1488 21.4002 23.8611 21.099C23.5696 20.7937 23.1802 20.6547 22.7981 20.6547Z"
      fill="white"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
export default SantaHat;
