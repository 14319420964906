import React from "react";
import clsx from "clsx";

export const Nudge = ({
  className,
  id,
  variant = "default",
  ...props
}: {
  className?: string;
  id?: string;
  variant?: "default" | "error";
}) => {
  return (
    <span
      id={id}
      className={clsx(variant === "error" ? "nudge-error" : "nudge", className)}
      {...props}
    />
  );
};
