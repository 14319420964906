import * as React from "react";
import { SVGProps } from "react";
const Seedling = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 15H11.25C9.26088 15 7.35322 14.2098 5.9467 12.8033C4.54018 11.3968 3.75 9.48912 3.75 7.5V5H7.5C9.48912 5 11.3968 5.79018 12.8033 7.1967C14.2098 8.60322 15 10.5109 15 12.5V25M15 17.5C15 15.5109 15.7902 13.6032 17.1967 12.1967C18.6032 10.7902 20.5109 10 22.5 10H26.25V11.25C26.25 13.2391 25.4598 15.1468 24.0533 16.5533C22.6468 17.9598 20.7391 18.75 18.75 18.75H15"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Seedling;
