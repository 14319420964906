import { Fragment } from "react";

import clsx from "clsx";

import HeaderBase from "../internals/HeaderBase";
import { Language, PropsOf } from "../../types";
import { Nudge } from "../components";

const intl = {
  no: {
    WEEK: "Uke",
    CANCELLED: "Avbestilt",
    CLOSED: "Stengt",
  },
  se: {
    WEEK: "Vecka",
    CANCELLED: "Avbokad",
    CLOSED: "Stängt",
  },
  dk: {
    WEEK: "Uge",
    CANCELLED: "Afbestilt",
    CLOSED: "Lukket",
  },
};

interface ActiveCampaignAddonProps {
  isSelected: boolean;
  title: string;
  Icon?: () => JSX.Element;
  onClick: () => void;
}

const ActiveCampaignAddon = ({
  isSelected = false,
  title = "",
  Icon,
  onClick,
}: ActiveCampaignAddonProps) => {
  return (
    <button
      className={clsx(
        "flex items-center justify-center h-12 w-20 md:w-22 relative",
        isSelected && "bg-primary text-white rounded",
      )}
      onClick={onClick}
    >
      <div className="text-sm text-center">
        <div className="pt-1 -mb-1">{Icon && <Icon />}</div>
        <div>{title}</div>
      </div>
      {!isSelected && <Nudge className="absolute top-0 right-0 z-1" />}
    </button>
  );
};

interface WeekType {
  week: number;
  date: string;
  showNudge?: boolean;
  isPaused?: boolean;
  undeliverable?: boolean;
}

interface OwnHeaderSubBaseProps<T extends WeekType> {
  language: Language;
  weeks: T[];
  activeWeek: number;
  onClick: (week: T) => void;
  activeCampaign?: {
    isSelected: boolean;
    title: string;
    Icon?: () => JSX.Element;
    onClick: () => void;
  };
}

type HeaderSubProps<T extends WeekType> = OwnHeaderSubBaseProps<T> &
  Omit<PropsOf<typeof HeaderBase>, "children">;

const HeaderSub = <T extends WeekType>({
  language = "no",
  weeks = [],
  activeWeek,
  onClick = () => null,
  activeCampaign,
  ...props
}: HeaderSubProps<T>) => {
  const { WEEK = "", CANCELLED = "", CLOSED } = intl[language];

  return (
    <HeaderBase {...props}>
      <div className="flex items-center justify-center w-full h-full p-2 space-x-2 md:space-x-6">
        {weeks.map((week, i) => {
          const {
            week: weekNumber,
            date,
            showNudge,
            isPaused = false,
            undeliverable,
          } = week;

          const isActive = +weekNumber === +activeWeek;

          return (
            <Fragment key={i}>
              <button
                className={clsx(
                  "flex items-center justify-center h-12 w-20 md:w-22 relative",
                  isActive && "bg-primary text-white rounded",
                )}
                id={i.toString()}
                onClick={() => onClick(week)}
              >
                <div className="text-sm text-center">
                  {isPaused || undeliverable ? (
                    <div className={clsx(!isActive && "text-error")}>
                      {undeliverable ? CLOSED : CANCELLED}
                    </div>
                  ) : (
                    <div>
                      {WEEK} {weekNumber}
                    </div>
                  )}

                  <strong
                    className={clsx(isPaused && !isActive && "text-grey-1")}
                  >
                    {date}
                  </strong>
                </div>
                {!isActive && showNudge && (
                  <Nudge className="absolute top-0 right-0 z-1" />
                )}
              </button>
            </Fragment>
          );
        })}
        {activeCampaign && Object.keys(activeCampaign).length !== 0 && (
          <ActiveCampaignAddon {...activeCampaign} />
        )}
      </div>
    </HeaderBase>
  );
};

export default HeaderSub;
