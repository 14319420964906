import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { language } from "@chef/constants";
import { showNotification } from "../features";
import { recipeApi } from "../features/recipe";

const intl = (
  {
    no: {
      SUCCESS: "Kommentaren er slettet!",
      ERROR: "Ops, her gikk noe galt! Prøv igjen senere.",
    },

    se: {
      SUCCESS: "Kommentaren är raderad!",
      ERROR: "Oops, något gick fel. Prova ingen senare!",
    },

    dk: {
      SUCCESS: "Kommentar fjernet!",
      ERROR: "Hov, noget gik galt! Prøv venligst igen.",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      recipeApi.endpoints.deleteCommentFromRecipe.matchFulfilled,
      recipeApi.endpoints.deleteCommentFromRecipe.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showNotification({
          message: intl.ERROR,
          type: "error",
        });
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.SUCCESS,
        });
      }
    },
  });
};
