import * as React from "react";
import { SVGProps } from "react";
const VippsSmiley = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2834.65 1700.79"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#fff;}.cls-2{fill:#ff5b24;}"}</style>
    </defs>
    <rect className="cls-2" width={2834.65} height={1700.79} />
    <path
      className="cls-1"
      d="m1432,983.97c91.53,0,143.47-44.53,192.95-108.84,27.21-34.63,61.84-42.05,86.58-22.26,24.74,19.79,27.21,56.89,0,91.53-71.74,94-163.26,150.9-279.53,150.9-126.16,0-237.47-69.26-314.16-190.47-22.26-32.16-17.32-66.79,7.42-84.11,24.74-17.31,61.84-9.89,84.11,24.74,54.42,81.63,128.63,138.53,222.63,138.53Zm170.68-304.26c0,44.53-34.63,74.21-74.21,74.21s-74.21-29.68-74.21-74.21,34.63-74.21,74.21-74.21,74.21,32.16,74.21,74.21Z"
    />
  </svg>
);
export default VippsSmiley;
