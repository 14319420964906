import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DELIVERY_TIME_ADJUSTED: "Ditt leveringstidspunkt er endret",

      WHY_ARE_DELIVERY_TIME_ADJUSTED: "Hvorfor endres leveringstidspunktet?",
      CLOSE: "Lukk",
    },

    se: {
      DELIVERY_TIME_ADJUSTED: "Din leveranstid har blivit justerad",

      WHY_ARE_DELIVERY_TIME_ADJUSTED: "Varför justeras leveranstider?",
      CLOSE: "Stäng",
    },

    dk: {
      DELIVERY_TIME_ADJUSTED: "Leveringstidspunkt ændret",

      WHY_ARE_DELIVERY_TIME_ADJUSTED: "Hvorfor ændres leveringstidspunktet?",
      CLOSE: "Luk",
    },
  } as const
)[language];
