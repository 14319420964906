import * as React from "react";
import { SVGProps } from "react";
const Complaint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.25 8.00001C1.25 5.4287 1.25 4.14305 1.81909 3.19358C2.15727 2.62937 2.62937 2.15727 3.19358 1.81909C4.14305 1.25 5.4287 1.25 8.00001 1.25H9.12502C10.6374 1.25 11.3936 1.25 12.0048 1.45189C13.2074 1.84919 14.1508 2.79262 14.5481 3.99527C14.75 4.60639 14.75 5.3626 14.75 6.87501V6.87501C14.75 8.38742 14.75 9.14362 14.5481 9.75475C14.1508 10.9574 13.2074 11.9008 12.0048 12.2981C11.3936 12.5 10.6374 12.5 9.12501 12.5H5.97669C5.90807 12.5 5.87376 12.5 5.84066 12.5006C4.82574 12.5174 3.85516 12.9194 3.12563 13.6252C3.10184 13.6482 3.07758 13.6724 3.02905 13.721V13.721C2.96938 13.7806 2.93955 13.8105 2.92114 13.8271C2.29561 14.3934 1.29311 13.9782 1.25123 13.1354C1.25 13.1106 1.25 13.0684 1.25 12.9841V8.00001Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M8 4L8 7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.5C8.55228 10.5 9 10.0523 9 9.5C9 8.94772 8.55228 8.5 8 8.5C7.44772 8.5 7 8.94772 7 9.5C7 10.0523 7.44772 10.5 8 10.5Z"
      fill="currentColor"
    />
  </svg>
);
export default Complaint;
