import clsx from "clsx";
import { PropsWithChildren } from "react";
interface CircularProgressProps {
  progress: { current: number; projected?: number };
  strokeWidth?: number;
  animate?: boolean;
  inverted?: boolean;
  className?: string;
}
const VIEWBOX_WIDTH = 100;
const VIEWBOX_HEIGHT = 100;
const VIEWBOX_CENTER_X = VIEWBOX_WIDTH / 2;
const VIEWBOX_CENTER_Y = VIEWBOX_HEIGHT / 2;
const CIRCLE_RATIO = 0.75;
// Shamelessly inspired by https://github.com/kevinsqi/react-circular-progressbar
export const CircularProgress = ({
  children,
  progress,
  strokeWidth = 8,
  inverted = false,
  className,
}: PropsWithChildren<CircularProgressProps>) => {
  const pathRadius = VIEWBOX_WIDTH / 2 - strokeWidth / 2;
  return (
    <div
      className={clsx(
        className,
        "relative transition-all",
        inverted ? "text-white" : "text-primary",
      )}
    >
      <svg
        className="w-full align-middle rotate-[225deg]"
        viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          className={clsx(
            inverted ? "stroke-white opacity-20" : "stroke-grey-3",
          )}
          d={getPathDescription({ radius: pathRadius })}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            ...getDashStyle({ pathRadius, dashRatio: CIRCLE_RATIO }),
          }}
        />
        {progress.projected && (
          <path
            d={getPathDescription({ radius: pathRadius })}
            stroke="currentColor"
            className="stroke-disabled"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              ...getDashStyle({
                pathRadius,
                dashRatio: (progress.projected / 100) * CIRCLE_RATIO,
              }),
            }}
          />
        )}
        <path
          className="transition-all"
          d={getPathDescription({ radius: pathRadius })}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            ...getDashStyle({
              pathRadius,
              dashRatio: (progress.current / 100) * CIRCLE_RATIO,
            }),
          }}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        {children}
      </div>
    </div>
  );
};
const getPathDescription = ({ radius }: { radius: number }) => {
  return `
    M ${VIEWBOX_CENTER_X}, ${VIEWBOX_CENTER_Y}
    m 0,-${radius}
    a ${radius},${radius} 0 1 1 0,${2 * radius}
    a ${radius},${radius} 0 1 1 0,-${2 * radius}
  `;
};
const getDashStyle = ({
  pathRadius,
  dashRatio,
}: {
  pathRadius: number;
  dashRatio: number;
}) => {
  const diameter = Math.PI * 2 * pathRadius;
  const gapLength = (1 - dashRatio) * diameter;
  return {
    strokeDasharray: `${diameter}px, ${diameter}px`,
    strokeDashoffset: `${gapLength}px`,
  };
};
