import * as React from "react";
import { SVGProps } from "react";
const AdamsCoin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M28 16.0002C28 22.6277 22.6274 28 15.9998 28C9.37218 28 4 22.6275 4 16.0002C4 9.37295 9.37218 4 15.9996 4C22.6269 4 27.9998 9.37228 27.9998 16.0002H28Z"
      fill="url(#paint0_linear_34326_333203)"
    />
    <path
      d="M23.7905 16.0007C23.7905 20.3028 20.303 23.7904 16.0005 23.7904C11.698 23.7904 8.21094 20.3026 8.21094 16.0007C8.21094 11.6987 11.6989 8.21094 16.0005 8.21094C20.3021 8.21094 23.7905 11.6985 23.7905 16.0007Z"
      fill="#FFDC5A"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
    >
      <path
        d="M16.0022 25.0442C11.0212 25.0442 6.96875 20.9916 6.96875 16.0106C6.96875 11.0296 11.0215 6.97656 16.0022 6.97656C20.9829 6.97656 25.0363 11.0294 25.0363 16.0106C25.0363 20.9918 20.9835 25.0442 16.0022 25.0442ZM16.0022 7.08912C11.0829 7.08912 7.08108 11.091 7.08108 16.0106C7.08108 20.9302 11.0831 24.9319 16.0022 24.9319C20.9213 24.9319 24.9235 20.9298 24.9235 16.0106C24.9235 11.0914 20.9215 7.08912 16.0022 7.08912Z"
        fill="#CE8912"
      />
    </g>
    <g
      style={{
        mixBlendMode: "screen",
      }}
    >
      <path
        d="M24.9576 16.0335C24.9576 13.2557 23.6811 10.7706 21.6838 9.13281C23.6644 10.7706 24.9282 13.2454 24.9282 16.0103C24.9282 20.9297 20.9262 24.9316 16.0069 24.9316C13.8656 24.9316 11.8988 24.1729 10.3594 22.9103C11.9028 24.1866 13.8814 24.9547 16.0361 24.9547C20.9552 24.9547 24.9576 20.9524 24.9576 16.0332V16.0335Z"
        fill="#FFF486"
      />
      <path
        d="M6.99767 16.034C6.99767 11.0525 11.0499 6.99992 16.0313 6.99992C18.1998 6.99992 20.1919 7.76821 21.7505 9.04678C20.1877 7.75465 18.1842 6.97656 16.0022 6.97656C11.0212 6.97656 6.96875 11.0294 6.96875 16.0106C6.96875 18.8231 8.2613 21.3395 10.283 22.9975C8.27754 21.3391 6.99767 18.8329 6.99767 16.0337V16.034Z"
        fill="#FFF486"
      />
    </g>
    <g
      style={{
        mixBlendMode: "screen",
      }}
    >
      <path
        d="M12.1562 7.63545C12.7119 7.40033 13.288 7.21526 13.8765 7.08069L13.0409 4.36719C12.451 4.51689 11.8793 4.7113 11.3281 4.94485L12.1562 7.63522V7.63545Z"
        fill="#FCC952"
      />
    </g>
    <path
      d="M25.9391 15.9501C25.9391 21.4024 21.5193 25.822 16.0671 25.822C10.6148 25.822 6.19531 21.4024 6.19531 15.9501C6.19531 10.4977 10.6153 6.07812 16.0671 6.07812C21.5189 6.07812 25.9391 10.4979 25.9391 15.9503V15.9501Z"
      fill="#ED9B00"
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
    >
      <path
        d="M7.2036 16.5771C7.2036 11.1248 11.6235 6.70495 17.0754 6.70495C18.7672 6.70495 20.3591 7.13114 21.7509 7.88097C20.1436 6.74677 18.184 6.07812 16.0671 6.07812C10.6153 6.07812 6.19531 10.4979 6.19531 15.9503C6.19531 19.7108 8.29841 22.9795 11.392 24.6462C8.85961 22.8592 7.20382 19.9123 7.20382 16.5769L7.2036 16.5771Z"
        fill="#C1683F"
      />
    </g>
    <path
      d="M20.7709 7.26562C23.32 9.06447 24.9867 12.0307 24.9867 15.3881C24.9867 20.8763 20.5378 25.3252 15.0498 25.3252C13.347 25.3252 11.7446 24.8962 10.3438 24.1414C11.9617 25.2832 13.934 25.9561 16.0647 25.9561C21.553 25.9561 26.0017 21.5071 26.0017 16.0189C26.0017 12.2335 23.885 8.94346 20.7709 7.26562Z"
      fill="#FFF162"
    />
    <path
      style={{
        mixBlendMode: "screen",
      }}
      opacity={0.41}
      d="M4.04688 15.0193C7.28883 16.7489 11.4563 17.7897 16.0031 17.7897C20.5498 17.7897 24.718 16.7489 27.9601 15.0191C27.4608 8.85067 22.3003 4 16.0033 4C9.70622 4 4.54646 8.85089 4.04688 15.0193Z"
      fill="url(#paint1_linear_34326_333203)"
    />
    <path
      style={{
        mixBlendMode: "screen",
      }}
      opacity={0.56}
      d="M23.7882 11.6047C23.7882 12.688 20.5073 11.4506 16.4602 11.4506C12.413 11.4506 9.13281 12.688 9.13281 11.6047C9.13281 10.5214 12.4137 7.14062 16.4602 7.14062C20.5067 7.14062 23.7882 10.5212 23.7882 11.6047Z"
      fill="url(#paint2_linear_34326_333203)"
    />
    <path
      style={{
        mixBlendMode: "screen",
      }}
      d="M18.1891 8.21078C18.6126 9.44708 17.471 10.9579 15.64 11.5851C13.8085 12.2126 11.9805 11.719 11.557 10.4827C11.1335 9.24666 12.2748 7.73565 14.1065 7.10816C15.9378 6.48089 17.7658 6.97447 18.1893 8.21055L18.1891 8.21078Z"
      fill="url(#paint3_radial_34326_333203)"
      fillOpacity={0.5}
    />
    <g
      style={{
        mixBlendMode: "multiply",
      }}
    >
      <path
        d="M19.6657 19.1446L16.4453 9.88281H16.2976C15.8258 10.4147 15.205 10.4736 14.2588 10.4736V10.6213L14.4341 11.122L11.5442 19.2111C11.324 19.8106 11.1249 20.0422 10.8433 20.1736H10.8295L10.7882 20.1912C10.6629 20.237 10.535 20.2755 10.4051 20.3064H10.39L10.3438 20.3189V20.4804H12.5779V20.324L12.5265 20.3153C12.1648 20.2501 11.9395 20.1814 11.8632 20.0513C11.7869 19.9211 11.8194 19.6758 11.9695 19.2527L12.5002 17.7595H16.7469L17.2263 19.1451C17.5365 20.0313 17.4627 20.1638 16.6506 20.3115V20.4892H20.8621V20.3129C20.2865 20.1652 19.9797 20.0326 19.6657 19.1451V19.1446ZM16.6005 17.3411H12.6481L14.6607 11.7602L16.6005 17.3411Z"
        fill="#C1683F"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_34326_333203"
        x1={16}
        y1={7.47646}
        x2={16}
        y2={26.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#FFF384" />
        <stop offset={0.19} stopColor="#FDE774" />
        <stop offset={0.43} stopColor="#F9CB4C" />
        <stop offset={0.77} stopColor="#F49C0C" />
        <stop offset={0.83} stopColor="#F39400" />
        <stop offset={0.9} stopColor="#F39502" />
        <stop offset={0.93} stopColor="#F49B0A" />
        <stop offset={0.96} stopColor="#F6A617" />
        <stop offset={0.99} stopColor="#F9B42B" />
        <stop offset={1} stopColor="#FCC03A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_34326_333203"
        x1={4.04688}
        y1={10.8949}
        x2={27.9604}
        y2={10.8949}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F39400" />
        <stop offset={0.39} stopColor="#F39502" />
        <stop offset={0.6} stopColor="#F49B0A" />
        <stop offset={0.77} stopColor="#F6A617" />
        <stop offset={0.92} stopColor="#F9B42B" />
        <stop offset={1} stopColor="#FCC03A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_34326_333203"
        x1={15.3918}
        y1={7.36795}
        x2={16.5005}
        y2={11.7497}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#FFF384" />
        <stop offset={0.45} stopColor="#FBD449" />
        <stop offset={0.98} stopColor="#F8AE00" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_34326_333203"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.8741 9.3488) rotate(-18.9103) scale(3.50532 2.38365)"
      >
        <stop offset={0.06} stopColor="#FFF384" />
        <stop offset={0.45} stopColor="#FBD449" />
        <stop offset={0.98} stopColor="#F8AE00" />
      </radialGradient>
    </defs>
  </svg>
);
export default AdamsCoin;
