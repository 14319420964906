export const ORDER_STATUS_IDS = {
  CREATED: "a434ea1a-a175-4d01-a076-25742e67f995",
  PROCESSING: "38a5cc25-a639-4433-a8e6-43fb35dabfd9",
  HOLD: "e1179a59-ecbd-40cf-951a-485893c981fd",
  WAITING: "389e1648-3531-47e3-8e45-570a783b755f",
  CANCELLED: "2ef21fdf-2432-490b-b1ff-7b75f294c695",
  FINISHED: "4508130e-6ba1-4c14-94a4-a56b074bb135",
} as const;

export const ORDER_TYPE_IDS = {
  DAILYDIRECT: "C4C44413-78A7-4EB8-8715-2643D88849B6",
  LOYALTY: "BF0B884E-DC86-4754-B778-5FD8E5CB589F",
  RECURRING: "5F34860B-7E61-46A0-80F7-98DCDC53BA9E",
  CAMPAIGN: "7DB44860-1075-49A1-BF37-9B4D5B9C4BA4",
  GIFTCARD: "D6BDF76C-05EB-4F05-8BDA-E8CF7D0EC388",
  ORDERSAFTERCUT: "1C182E51-ECFA-4119-8928-F2D9F57C5FCC",
} as const;

export const ORDER_STATUSES = {
  CREATED: "Created",
  PROCESSING: "Processing",
  FINISHED: "Finished",
} as const;
