import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { language } from "@chef/constants";

interface GetTrustpilotReviewsArgs {
  stars: number;
}

const locales = new Map<typeof language, string>();

locales.set("no", "no");
locales.set("se", "sv");
locales.set("dk", "da");

export const trustpilotApi = createApi({
  reducerPath: "trustpilot",
  tagTypes: ["reviews"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/trustpilot",
  }),
  endpoints: (builder) => ({
    getTrustpilotReviews: builder.query<
      { [key: string]: any },
      GetTrustpilotReviewsArgs
    >({
      providesTags: ["reviews"],
      query: ({ stars }) => {
        const search = new URLSearchParams({
          stars: stars.toString(),
          language: locales.get(language)!,
        });
        return {
          url: `/reviews?${search.toString()}`,
        };
      },
    }),
  }),
});

export const { useGetTrustpilotReviewsQuery } = trustpilotApi;
