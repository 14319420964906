export const getLoyaltyFee = ({
  loyaltyLevel,
  customerFee,
}: {
  customerFee: number;
  loyaltyLevel: number;
}) => {
  if (loyaltyLevel !== 4) {
    return customerFee;
  }

  return 0;
};
