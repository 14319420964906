import Head from "next/head";
import { Organization } from "schema-dts";
import { jsonLdScriptProps } from "react-schemaorg";

import { CONTACT, LOGO, language } from "@chef/constants";
import { removeCountryCode } from "@chef/utils/phone";

interface ContactProps {
  hidden?: boolean;
}

export const Contact = ({ hidden }: ContactProps) => {
  return (
    <div>
      <Head>
        <script
          {...jsonLdScriptProps<Organization>({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: CONTACT.NAME,
            url: CONTACT.URL,
            logo: LOGO.DEFAULT,
            address: {
              "@type": "PostalAddress",
              streetAddress: CONTACT.ADDRESS_STREET,
              addressLocality: CONTACT.ADDRESS_CITY,
              postalCode: CONTACT.ADDRESS_ZIP,
              addressCountry: CONTACT.ADDRESS_COUNTRY,
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: CONTACT.PHONE,
              email: CONTACT.EMAIL,
              contactType: "Kundeservice",
            },
          })}
        />
        {/* <script
          {...jsonLdScriptProps<FAQPage>({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: FAQ.map(({ question, answer }) => ({
              "@type": "Question",
              name: question,
              acceptedAnswer: {
                "@type": "Answer",
                text: answer,
              },
            })),
          })}
        /> */}
      </Head>

      {!hidden && (
        <address className="text-xs not-italic">
          <p>
            <strong>{CONTACT.NAME}</strong>
          </p>
          <p>{CONTACT.ADDRESS_STREET}</p>
          <p>
            {CONTACT.ADDRESS_ZIP} {CONTACT.ADDRESS_CITY}
          </p>
          <p>
            Tlf:{" "}
            <a href={`tel:${CONTACT.PHONE}`}>
              {removeCountryCode(CONTACT.PHONE, language)}
            </a>
          </p>
          <p>
            E-mail: <a href={`mailto:${CONTACT.EMAIL}`}>{CONTACT.EMAIL}</a>
          </p>
        </address>
      )}
    </div>
  );
};
