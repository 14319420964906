import * as React from "react";
import { SVGProps } from "react";
const SantaHat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8941:146254)">
      <path
        d="M13.5468 1.6C13.5468 1.28 13.6259 0.96 13.7445 0.68C12.6771 0.24 11.5306 0 10.384 0C5.36311 0 1.29102 4.4 1.29102 9.8C1.29102 10 1.29102 10.2 1.33055 10.4H14.3375C14.3375 7.44 12.3608 4.76 9.55381 3.88L9.79102 3.12C10.9375 3.48 11.9259 4.08 12.7561 4.88C12.8352 4.08 13.2306 3.36 13.9026 2.88C13.6654 2.48 13.5468 2.08 13.5468 1.6Z"
        fill="white"
      />
      <path
        d="M15.9193 3.2C16.7927 3.2 17.5007 2.48366 17.5007 1.6C17.5007 0.716344 16.7927 0 15.9193 0C15.0459 0 14.3379 0.716344 14.3379 1.6C14.3379 2.48366 15.0459 3.2 15.9193 3.2Z"
        fill="white"
      />
      <path
        d="M14.7326 11.2H0.895349C0.65814 11.2 0.5 11.36 0.5 11.6V13.6C0.5 13.84 0.65814 14 0.895349 14H14.7326C14.9698 14 15.1279 13.84 15.1279 13.6V11.6C15.1279 11.36 14.9698 11.2 14.7326 11.2Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8941:146254">
        <rect width={17} height={14} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default SantaHat;
