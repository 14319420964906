import { useCallback } from "react";
import { useSelector } from "react-redux";

import { PRODUCT_CATEGORY_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { getWeek, getYear, nextDeliveryWeekDateObj } from "@chef/helpers";

import {
  PickAndMixQuery,
  useCalendarQuery,
  usePickAndMixQuery,
  usePreselectedProductsForWeekQuery,
  useProductsByCategoriesQuery,
} from "../graphql/generated";
import {
  IDeviationBasket,
  selectDeviationProducts,
  useMeQuery,
} from "../features";
import { getPriceOfProduct, isProductPreselected } from "../helpers/product";

interface IGetPriceOfPickAndMixProductBase {
  /**
   * Required if not being used inside of week editor
   */
  basketProducts?: IDeviationBasket["products"];
}

interface IGetPriceOfPickAndMixProductWithProduct
  extends IGetPriceOfPickAndMixProductBase {
  product: PickAndMixQuery["pickAndMix"][0]["product"];
  productId?: never;
  variation?: never;
  variationId: string;
}

interface IGetPriceOfPickAndMixProductWithProductId
  extends IGetPriceOfPickAndMixProductBase {
  product?: never;
  productId: string;
  variation?: never;
  variationId: string;
}

interface IGetPriceOfPickAndMixProductWithVariationId
  extends IGetPriceOfPickAndMixProductBase {
  product?: never;
  productId?: never;
  variation?: never;
  variationId: string;
}

interface IGetPriceOfpickAndMixProductWithVariation
  extends IGetPriceOfPickAndMixProductBase {
  product?: never;
  productId?: never;
  variation: PickAndMixQuery["pickAndMix"][0]["product"]["variations"][0];
  variationId?: never;
}

export type GetPriceOfPickAndMixProductArgs =
  | IGetPriceOfPickAndMixProductWithProduct
  | IGetPriceOfPickAndMixProductWithProductId
  | IGetPriceOfPickAndMixProductWithVariationId
  | IGetPriceOfpickAndMixProductWithVariation;

export type GetPriceOfPickAndMixProduct = (
  args: GetPriceOfPickAndMixProductArgs,
) => number | null;

export const usePickAndMixPrice = (args: {
  week: number;
  year: number;
  signup?: boolean;
}) => {
  const { week, year, signup = false } = args;

  const date = nextDeliveryWeekDateObj();
  const currentWeek = getWeek(date);
  const currentYear = getYear(date);
  const { data: isLoggedIn } = useMeQuery();

  const skip = !week || !year;

  const { data: { calendar } = {}, ...calendarQuery } = useCalendarQuery(
    { week: currentWeek, year: currentYear, range: 4 },
    { skip: skip || !isLoggedIn },
  );

  const { data: { preselectedProductsForWeek } = {}, ...preselectedQuery } =
    usePreselectedProductsForWeekQuery(
      {
        week,
        year,
      },
      { skip },
    );

  const { data: { pickAndMix } = {}, ...pickAndMixQuery } = usePickAndMixQuery(
    {
      productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
      week,
      year,
    },
    { skip },
  );

  const { data: { productsByCategories } = {}, ...productsByCategoriesQuery } =
    useProductsByCategoriesQuery(
      {
        categoryIds: [
          PRODUCT_CATEGORY_IDS.FINANCIAL,
          signup
            ? PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT
            : PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
        ],
        week,
        year,
      },
      { skip },
    );

  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const getPriceOfPickAndMixProduct: GetPriceOfPickAndMixProduct = useCallback(
    (args) => {
      if (
        !preselectedProductsForWeek ||
        !pickAndMix ||
        !productsByCategories ||
        !calendar
      ) {
        return null;
      }

      const { variation, basketProducts } = args;

      let { product, productId, variationId } = args;

      // if variation is set
      if (variation) {
        variationId = variation.variationId;
        productId = variation.product.productId;
      }

      // if variationId isn't set by this point, there is a bug
      if (!variationId) {
        return null;
      }

      // find product data by variationId
      if (!productId && !product) {
        let found = false;
        for (const pnm of pickAndMix) {
          if (found) {
            break;
          }

          for (const variation of pnm.product.variations) {
            if (!isEqualStrings(variation.variationId, variationId)) {
              continue;
            }

            product = pnm.product;
            productId = pnm.product.productId;
            found = true;
            break;
          }
        }

        // not a valid variationId
        if (!found) {
          return null;
        }
      }

      // find product by productId
      if (!product) {
        product = pickAndMix.find((pnm) =>
          isEqualStrings(pnm.productId, productId),
        )?.product;

        if (!product) {
          return null;
        }
      }

      // set productId from product
      if (!productId) {
        productId = product.productId;
      }

      const preselectorBasket = calendar.find(
        (c) => c.week === week && c.year === year,
      )?.preselectorBasket;

      let isPreselected = false;
      if (preselectorBasket && preselectorBasket.variationIds.length > 0) {
        isPreselected = preselectorBasket.variationIds.some((v) =>
          isEqualStrings(v, variationId),
        );
      } else {
        // TODO: Onesub migration, keep old logic until fully migrated
        isPreselected = isProductPreselected({
          productId,
          basketProducts: basketProducts || selectedProducts || [],
          preselectedProductsForWeek,
          productsByCategories,
          week,
          year,
        });
      }

      return getPriceOfProduct({
        isPreselected,
        product,
        variationId,
        week,
        year,
      });
    },
    [
      preselectedProductsForWeek,
      pickAndMix,
      productsByCategories,
      selectedProducts,
      week,
      year,
    ],
  );

  return {
    getPriceOfPickAndMixProduct,
    isLoading:
      preselectedQuery.isLoading ||
      pickAndMixQuery.isLoading ||
      productsByCategoriesQuery.isLoading ||
      calendarQuery.isLoading,
    isFetching:
      preselectedQuery.isFetching ||
      pickAndMixQuery.isFetching ||
      productsByCategoriesQuery.isFetching ||
      calendarQuery.isFetching,
    isError:
      preselectedQuery.isError ||
      pickAndMixQuery.isError ||
      productsByCategoriesQuery.isError ||
      calendarQuery.isError,
  };
};
