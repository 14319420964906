import { BRAND_NAME } from "@chef/constants";
import { RecipeAndStepsQuery } from "@chef/state-management";

export const formatPortionsIngredientSections = (
  portions: RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"],
) => {
  if (!portions) {
    return [];
  }
  return [...portions]
    .sort((a, b) => {
      if (a.size < b.size) {
        return -1;
      }

      if (a.size > b.size) {
        return 1;
      }
      return 0;
    })
    .map((portion) => {
      return {
        ...portion,
        ingredientSections: [
          ...portion.ingredientSections.filter((s) =>
            s.ingredients.some((i) => !i.isBasis),
          ),
          {
            sectionId: "basis", //TODO: uuid ?
            sectionTitle: intl.BASIS_SECTION_TITLE,
            isBasisArray: true,
            ingredients: [
              ...portion.ingredientSections
                .flatMap((s) => s.ingredients)
                .filter((i) => i.isBasis)
                .map((i) => i.name),
            ],
          },
        ],
      };
    });
};

export const splitRecipeStepString = (step: string) => {
  const splittedStepString = step.split(":");

  const hasHeading = splittedStepString.length > 1;

  const heading = hasHeading ? splittedStepString[0].replace(":", "") : "";
  const description = hasHeading
    ? splittedStepString.slice(1).join(":")
    : splittedStepString[0];

  return { heading, description };
};

const intl = {
  AMK: {
    BASIS_SECTION_TITLE: "Du må selv ha",
  },
  GL: {
    BASIS_SECTION_TITLE: "Du må selv ha",
  },
  LMK: {
    BASIS_SECTION_TITLE: "Du måste ha detta själv",
  },
  RN: {
    BASIS_SECTION_TITLE: "Du skal selv have",
  },
}[BRAND_NAME];

const isPlusPortion = (portionSize: string): boolean =>
  portionSize.endsWith("+");

export const getPlusPortionString = (portionSizeString: string): string => {
  return isPlusPortion(portionSizeString) ? " (PLUS)" : "";
};
