import * as React from "react";
import { SVGProps } from "react";
const Chat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 14C9.86168 15.6877 5.5 16.5 5.5 16.5C5.5 16.5 7.01067 13.9563 7 13.5C4.41389 13.6339 0.999953 12 1 9C1.00005 6 4.49989 3 9.49992 3C14.5 3 18.9999 5.5 18.9999 8.50002C18.9999 12 16.7549 12.9663 13.9999 13.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Chat;
