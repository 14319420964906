import React from "react";
import clsx from "clsx";
import { Switch } from "../components";
import FutureDeliveryCardBase from "../internals/FutureDeliveryCardBase";

const intl = {
  no: {
    DISH: "rett",
    DISHES: "retter",
    FOODBOX: "Matkasse",
    DELIVERY: "Levering",
    SWITCH_LABEL_ACTIVE: "Pause levering",
    SWITCH_LABEL_DISABLED: "Start levering",
    NO_DELIVERY: "Ingen levering",
  },
  se: {
    DISH: "rätt",
    DISHES: "rätter",
    FOODBOX: "Matkasse",
    DELIVERY: "Leverans",
    SWITCH_LABEL_ACTIVE: "Pausa leverans",
    SWITCH_LABEL_DISABLED: "Aktivera leverans",
    NO_DELIVERY: "Ingen leverans",
  },
  dk: {
    DISH: "ret",
    DISHES: "retter",
    FOODBOX: "Måltidskasse",
    DELIVERY: "Levering",
    SWITCH_LABEL_ACTIVE: "Pause levering",
    SWITCH_LABEL_DISABLED: "Aktiver levering",
    NO_DELIVERY: "Ingen levering",
  },
};

const FutureDeliveryCard = ({
  language = "",
  date = "",
  productName = "",
  portionsAndMeals = "",
  addressName = "",
  deliveryDateAndTime = "",
  LinkComponent = () => null,
  isActive = false,
  onSwitchClick = () => null,
  nPickAndMixDishes = 0,
  switchLoading = false,
}) => {
  const {
    DISH,
    DISHES,
    FOODBOX,
    DELIVERY,
    SWITCH_LABEL_ACTIVE,
    SWITCH_LABEL_DISABLED,
    NO_DELIVERY,
  } = intl[language];
  return (
    <FutureDeliveryCardBase>
      <div className="w-32 mt-4 shrink-0 md:mt-0">
        <strong>{date}</strong>
      </div>
      <div className="shrink-0 md:w-64">
        <div>
          <strong>{FOODBOX}</strong>
        </div>
        {isActive ? (
          <>
            <div>
              {nPickAndMixDishes > 0
                ? `${nPickAndMixDishes} ${
                    nPickAndMixDishes === 1 ? DISH : DISHES
                  }`
                : productName}
            </div>
            <div>{portionsAndMeals}</div>
          </>
        ) : (
          <div>{NO_DELIVERY}</div>
        )}
      </div>
      <div className={clsx(!isActive && "text-grey-1")}>
        <div>
          <strong>{DELIVERY}</strong>
        </div>
        <div>{addressName}</div>
        <div>{deliveryDateAndTime}</div>
        <LinkComponent />
      </div>
      <div className="absolute top-4 right-4 md:top-6 md:right-6">
        <Switch
          className="ml-6 shrink-0"
          onChange={onSwitchClick}
          checked={isActive}
          label={isActive ? SWITCH_LABEL_ACTIVE : SWITCH_LABEL_DISABLED}
          isLoading={switchLoading}
        />
      </div>
    </FutureDeliveryCardBase>
  );
};

export default FutureDeliveryCard;
