/*
  Before modifying this hook, make sure you read and understand the below

  Regarding the solution we're using:
    We're using this https://github.com/vercel/next.js/issues/2476#issuecomment-843311387
    The ideal solution would be this https://github.com/vercel/next.js/issues/2476#issuecomment-850030407
    But due to the below, it isn't possible anymore since v12.1.15 of nextjs
    1. https://github.com/vercel/next.js/discussions/34980
    2. https://github.com/vercel/next.js/pull/35681
    3. https://github.com/vercel/next.js/pull/35770
    4. https://github.com/vercel/next.js/pull/36861 (where the PR finally got merged)
    5. follow up from the problem that arose from the above PR
    5.1: https://github.com/vercel/next.js/pull/37192
    5.2: https://github.com/vercel/next.js/discussions/40383

  The solution we're using:
  The above thread contains important information regarding issues and what to test for

  Extra discussion: https://github.com/vercel/next.js/discussions/12348

  Using "no-restricted-globals" due to "history" never being used in SSR

  Also using the following to detect refreshing vs closing of tab
    https://stackoverflow.com/questions/11453741/javascript-beforeunload-detect-refresh-versus-close

  --------------

  To replicate the exact functionality as the github issue, do the following:
  const { intercepting, replyIntercepting } = useNavigationIntercept({ shouldIntercept: ()=> shouldWarn });

  useEffect(()=> {
    if(intercepting) {
      replyIntercepting(window.confirm(message));
    }
  }, [intercepting]);
*/

import Router from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCallbackRef } from "./useCallbackRef";

export enum INTERCEPT_TYPES {
  CLOSE_TAB,
  NAVIGATION,
}

interface ShouldInterceptCloseTab {
  type: INTERCEPT_TYPES.CLOSE_TAB;
}

interface ShouldInterceptNavigation {
  type: INTERCEPT_TYPES.NAVIGATION;

  newUrl: string;
}

export type UseNavigationInterceptArgs =
  | ShouldInterceptCloseTab
  | ShouldInterceptNavigation;

interface IUseNavigationIntercept {
  shouldIntercept: (args: UseNavigationInterceptArgs) => boolean;

  includeRefresh?: boolean;
}

export const useNavigationIntercept = ({
  shouldIntercept,
  includeRefresh,
}: IUseNavigationIntercept) => {
  const isWarned = useRef(false);
  const [intercepting, setIntercepting] = useState<null | {
    url: string;
    backButton: boolean;
  }>(null);

  // We're using a useEffect to sync isWarned.current with intercepting instead of setting them seperately
  // due to the execution order of Next.js's events execution order. We could do Router.push().then(...) to sync it properly
  // but Router.back() does not return a promise, so that solution would not work in that case, which causes a race condition
  // due to event(s) execution order. But in order to prevent isWarned.current needing to rely on React rendering cycle
  // we will also need to set isWarned.current where we're setting "setIntercepting" -- the exception to that is whenever
  // we want to execute a navigation, for instance Router.push or Router.back (so _almost_ whenever we're setting it to false)
  useEffect(() => {
    // Next.js will update the state on this rendering cycle, so we use setTimeout to schedule it for the next tick.
    setTimeout(() => {
      isWarned.current = !!intercepting;
    });
  }, [intercepting]);

  const _shouldIntercept = useCallbackRef(shouldIntercept);
  useEffect(() => {
    const onRouteChangeStart = (newUrl: string) => {
      if (
        Router.asPath !== newUrl &&
        !isWarned.current &&
        _shouldIntercept({
          type: INTERCEPT_TYPES.NAVIGATION,
          newUrl,
        })
      ) {
        isWarned.current = true;
        setIntercepting({
          url: newUrl,
          backButton: false,
        });

        Router.events.emit("routeChangeError");

        // eslint-disable-next-line no-throw-literal
        throw "Abort route change. Please ignore this error.";
      }
    };

    let refreshKeyPressed = false;
    let modifierPressed = false;
    const f5key = 116;
    const rkey = 82;
    const modkey = [17, 224, 91, 93];

    const keyDown = (evt: KeyboardEvent) => {
      // Check for refresh
      if (evt.keyCode === f5key || (modifierPressed && evt.keyCode === rkey)) {
        refreshKeyPressed = true;
      }

      // Check for modifier
      if (modkey.indexOf(evt.keyCode) >= 0) {
        modifierPressed = true;
      }
    };

    const keyUp = (evt: KeyboardEvent) => {
      // Check undo keys
      if (evt.keyCode === f5key || evt.keyCode === rkey) {
        refreshKeyPressed = false;
      }

      // Check for modifier
      if (modkey.indexOf(evt.keyCode) >= 0) {
        modifierPressed = false;
      }
    };

    const beforeUnload = (e: BeforeUnloadEvent) => {
      // NOTE: don't think this is being used in any browser spec?
      const message = "Changes that you made may not be saved.";

      if (
        // if we're closing the tab or we're including refresh and we're refreshing
        (!refreshKeyPressed || (includeRefresh && refreshKeyPressed)) &&
        !isWarned.current &&
        _shouldIntercept({ type: INTERCEPT_TYPES.CLOSE_TAB })
      ) {
        const event = e || window.event;
        event.returnValue = message;
        return message;
      }
      return null;
    };

    Router.events.on("routeChangeStart", onRouteChangeStart);
    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    window.addEventListener("beforeunload", beforeUnload);

    // this is Next.js's way of handling when the user clicks the back button in browser
    Router.beforePopState(({ as: newUrl }) => {
      if (
        Router.asPath !== newUrl &&
        !isWarned.current &&
        _shouldIntercept({
          type: INTERCEPT_TYPES.NAVIGATION,
          newUrl,
        })
      ) {
        isWarned.current = true;
        setIntercepting({
          url: newUrl,
          backButton: true,
        });

        // doing it like this will overwrite the history, but it won't make the url bar flash
        // alternative is window.history.forward(), which won't overwrite but it'll flash
        window.history.pushState(null, "", Router.asPath);

        return false;
      }

      return true;
    });

    return () => {
      Router.events.off("routeChangeStart", onRouteChangeStart);
      window.removeEventListener("keydown", keyDown);
      window.removeEventListener("keyup", keyUp);
      window.removeEventListener("beforeunload", beforeUnload);

      Router.beforePopState(() => {
        return true;
      });
    };
  }, [_shouldIntercept, includeRefresh]);

  const replyIntercept = useCallback(
    (redirect: boolean, overrideUrl?: string) => {
      if (!intercepting) {
        console.error("Trying to replyIntercept without intercepting");
        return;
      }

      if (!redirect) {
        setIntercepting(null);
        isWarned.current = false;
        return;
      }

      if (intercepting.backButton) {
        Router.back();
      } else {
        Router.push(overrideUrl || intercepting.url);
      }

      setIntercepting(null);
    },
    [intercepting, setIntercepting],
  );

  return {
    intercepting: !!intercepting,
    replyIntercept,
  };
};
