import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DO_YOU_WANT_TO_SAVE_YOUR_FAVORITE_RECIPES:
        "Vil du lagre dine favorittoppskrifter?",
      AS_OUR_CUSTOMER_YOU_CAN_CREATE_YOUR_OWN_COOKBOOK:
        "En av fordelene ved å være kunde hos oss, er at du kan lage din egen unike kokebok",
      LOG_IN_OR_CREATE_AN_ACCOUNT: "Logg inn eller opprett konto",
      NO_THANK_YOU: "Nei takk",
    },
    se: {
      DO_YOU_WANT_TO_SAVE_YOUR_FAVORITE_RECIPES:
        "Vill du spara dina favoritrecept?",
      AS_OUR_CUSTOMER_YOU_CAN_CREATE_YOUR_OWN_COOKBOOK:
        "En av fördelarna med att vara kund hos oss är att du kan skapa din egen unika kokbok.",
      LOG_IN_OR_CREATE_AN_ACCOUNT: "Logga in eller skapa konto",
      NO_THANK_YOU: "Nej tack",
    },
    dk: {
      DO_YOU_WANT_TO_SAVE_YOUR_FAVORITE_RECIPES:
        "Vil du gemme dine favoritopskrifter?",
      AS_OUR_CUSTOMER_YOU_CAN_CREATE_YOUR_OWN_COOKBOOK:
        "En af fordelene ved at være kunde hos os er, at du kan lave din helt egen kogebog.",
      LOG_IN_OR_CREATE_AN_ACCOUNT: "Log ind eller opret konto",
      NO_THANK_YOU: "Nej tak",
    },
  } as const
)[language];
