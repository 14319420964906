import * as React from "react";
import { SVGProps } from "react";
const Coin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_34326_333064)">
      <path
        d="M24 12.0002C24 18.6277 18.6274 24 11.9998 24C5.37218 24 0 18.6275 0 12.0002C0 5.37295 5.37218 0 11.9996 0C18.6269 0 23.9998 5.37228 23.9998 12.0002H24Z"
        fill="url(#paint0_linear_34326_333064)"
      />
      <path
        d="M19.7905 12.0007C19.7905 16.3028 16.303 19.7904 12.0005 19.7904C7.69801 19.7904 4.21094 16.3026 4.21094 12.0007C4.21094 7.69874 7.6989 4.21094 12.0005 4.21094C16.3021 4.21094 19.7905 7.69852 19.7905 12.0007Z"
        fill="#FFDC5A"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
      >
        <path
          d="M12.0022 21.0364C7.02124 21.0364 2.96875 16.9838 2.96875 12.0028C2.96875 7.02176 7.02147 2.96875 12.0022 2.96875C16.9829 2.96875 21.0363 7.02154 21.0363 12.0028C21.0363 16.984 16.9835 21.0364 12.0022 21.0364ZM12.0022 3.0813C7.08286 3.0813 3.08108 7.08316 3.08108 12.0028C3.08108 16.9224 7.08308 20.924 12.0022 20.924C16.9213 20.924 20.9235 16.922 20.9235 12.0028C20.9235 7.0836 16.9215 3.0813 12.0022 3.0813Z"
          fill="#CE8912"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
      >
        <path
          d="M20.9498 12.0256C20.9498 9.24786 19.6733 6.7628 17.676 5.125C19.6566 6.7628 20.9204 9.23763 20.9204 12.0025C20.9204 16.9219 16.9184 20.9238 11.9991 20.9238C9.85776 20.9238 7.89102 20.165 6.35156 18.9025C7.89502 20.1788 9.87356 20.9469 12.0283 20.9469C16.9473 20.9469 20.9498 16.9446 20.9498 12.0254V12.0256Z"
          fill="#FFF486"
        />
        <path
          d="M2.99767 12.0261C2.99767 7.04468 7.04994 2.99211 12.0313 2.99211C14.1998 2.99211 16.1919 3.7604 17.7505 5.03897C16.1877 3.74683 14.1842 2.96875 12.0022 2.96875C7.02124 2.96875 2.96875 7.02154 2.96875 12.0028C2.96875 14.8153 4.2613 17.3317 6.28299 18.9897C4.27754 17.3312 2.99767 14.8251 2.99767 12.0259V12.0261Z"
          fill="#FFF486"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
      >
        <path
          d="M8.15624 3.63545C8.71188 3.40033 9.28798 3.21526 9.87653 3.08069L9.04085 0.367188C8.45096 0.516887 7.87931 0.711297 7.32812 0.944855L8.15624 3.63522V3.63545Z"
          fill="#FCC952"
        />
      </g>
      <path
        d="M21.9391 11.9579C21.9391 17.4103 17.5193 21.8298 12.0671 21.8298C6.61482 21.8298 2.19531 17.4103 2.19531 11.9579C2.19531 6.50553 6.61526 2.08594 12.0671 2.08594C17.5189 2.08594 21.9391 6.50575 21.9391 11.9581V11.9579Z"
        fill="#ED9B00"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
      >
        <path
          d="M3.2036 12.5849C3.2036 7.13257 7.62355 2.71276 13.0754 2.71276C14.7672 2.71276 16.3591 3.13895 17.7509 3.88878C16.1436 2.75458 14.184 2.08594 12.0671 2.08594C6.61526 2.08594 2.19531 6.50575 2.19531 11.9581C2.19531 15.7186 4.29841 18.9873 7.392 20.654C4.85961 18.867 3.20382 15.9201 3.20382 12.5847L3.2036 12.5849Z"
          fill="#ED9B00"
        />
      </g>
      <path
        d="M16.7709 3.27344C19.32 5.07228 20.9867 8.03847 20.9867 11.3959C20.9867 16.8841 16.5378 21.333 11.0498 21.333C9.34703 21.333 7.74463 20.904 6.34375 20.1492C7.96172 21.291 9.93403 21.9639 12.0647 21.9639C17.553 21.9639 22.0017 17.5149 22.0017 12.0268C22.0017 8.24133 19.885 4.95128 16.7709 3.27344Z"
        fill="#FFF162"
      />
      <path
        d="M12.0024 5.47656C13.0492 5.47656 14.3938 8.91365 14.3938 8.91365C14.3938 8.91365 18.0782 9.1303 18.4018 10.1259C18.7252 11.1213 15.8719 13.4623 15.8719 13.4623C15.8719 13.4623 16.8045 17.0335 15.9575 17.6487C15.1107 18.2638 12.0026 16.2734 12.0026 16.2734C12.0026 16.2734 8.89437 18.2638 8.0478 17.6487C7.20122 17.0335 8.13366 13.4623 8.13366 13.4623C8.13366 13.4623 5.28007 11.1213 5.60371 10.1259C5.92713 9.1303 9.61172 8.91365 9.61172 8.91365C9.61172 8.91365 10.9561 5.47656 12.0029 5.47656H12.0024Z"
        fill="url(#paint1_linear_34326_333064)"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
      >
        <path
          d="M8.23566 13.1255C8.23566 13.1255 6.41082 11.7437 5.67969 10.7188C6.01667 11.5193 7.52454 12.9571 8.13 13.4959C8.16581 13.3437 8.23566 13.1255 8.23566 13.1255Z"
          fill="#E3A426"
        />
        <path
          d="M18.4063 10.1253C18.3607 9.98512 18.2468 9.86077 18.0878 9.75C18.9237 10.6062 15.5463 13.2425 15.5463 13.2425C15.5463 13.2425 16.4667 16.4449 15.6199 17.0602C14.7731 17.6752 11.9745 15.685 11.9745 15.685C11.9745 15.685 8.66556 17.6051 7.75781 17.0884C7.81142 17.3413 7.90417 17.5406 8.05231 17.6481C8.89911 18.2633 12.0072 16.2727 12.0072 16.2727C12.0072 16.2727 15.1152 18.2631 15.962 17.6481C16.8088 17.0328 15.8764 13.4616 15.8764 13.4616C15.8764 13.4616 18.73 11.1207 18.4063 10.1253Z"
          fill="#E3A426"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
      >
        <path
          d="M12.0007 5.47656C10.954 5.47656 9.60957 8.91365 9.60957 8.91365C9.60957 8.91365 5.9252 9.1303 5.60156 10.1259L5.62269 10.1337C5.71834 9.98246 5.86959 9.90349 6.0224 9.83609C6.17811 9.77181 6.34137 9.72198 6.50998 9.68595C6.84429 9.60521 7.19129 9.55916 7.53739 9.51245C7.88594 9.47619 8.23383 9.4277 8.58571 9.40346C8.93404 9.36965 9.29105 9.33606 9.63337 9.3196L9.89629 9.30692L9.98815 9.06179C10.1087 8.74082 10.2524 8.4125 10.391 8.09086C10.5233 7.76388 10.6759 7.44757 10.8183 7.12771C10.97 6.81296 11.1208 6.49688 11.3009 6.20393C11.3877 6.05512 11.4858 5.91521 11.5948 5.78708C11.7058 5.66296 11.8294 5.54329 12.0007 5.49925V5.47678V5.47656Z"
          fill="#E7C77A"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
      >
        <path
          d="M12.3557 5.85115C12.4498 5.9835 12.5296 6.13075 12.6141 6.27444L13.1097 7.16975L13.3777 7.61885C13.4767 7.76477 13.5615 7.91869 13.6691 8.06305C13.8713 8.35823 14.0909 8.64828 14.3714 8.92166L14.3925 8.91365C14.3925 8.91365 13.0479 5.47656 12.0011 5.47656L12 5.49903C12.1693 5.58934 12.2622 5.71412 12.3557 5.85137V5.85115Z"
          fill="#E7C77A"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
      >
        <path
          d="M15.4243 9.34092C15.5944 9.39897 15.7675 9.43123 15.937 9.48061L16.4468 9.59539L17.4517 9.78891C17.6148 9.82516 17.7792 9.85541 17.9344 9.90413C18.0935 9.95062 18.2418 10.0022 18.3784 10.1335L18.4 10.1263C18.0766 9.13072 14.392 8.91406 14.392 8.91406L14.3906 8.93653C14.7372 9.12004 15.0808 9.23949 15.4243 9.34047V9.34092Z"
          fill="#E7C77A"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.41}
      >
        <path
          d="M0.046875 11.0193C3.28883 12.7489 7.45632 13.7897 12.0031 13.7897C16.5498 13.7897 20.718 12.7489 23.9601 11.0191C23.4608 4.85067 18.3003 0 12.0033 0C5.70622 0 0.546458 4.85089 0.046875 11.0193Z"
          fill="#FCC952"
        />
      </g>
      <path
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.56}
        d="M19.4679 8.12814C19.4679 9.21141 16.187 7.97399 12.1399 7.97399C8.09271 7.97399 4.8125 9.21141 4.8125 8.12814C4.8125 7.04487 8.09338 3.66406 12.1399 3.66406C16.1864 3.66406 19.4679 7.04465 19.4679 8.12814Z"
        fill="url(#paint2_linear_34326_333064)"
      />
      <path
        style={{
          mixBlendMode: "screen",
        }}
        d="M13.8687 4.74203C14.2923 5.97833 13.1507 7.48912 11.3197 8.11639C9.48816 8.74388 7.66021 8.25029 7.2367 7.01399C6.81319 5.77791 7.95449 4.2669 9.78622 3.63941C11.6175 3.01214 13.4455 3.50572 13.869 4.7418L13.8687 4.74203Z"
        fill="url(#paint3_radial_34326_333064)"
        fillOpacity={0.5}
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_34326_333064"
        x1={12}
        y1={3.47646}
        x2={12}
        y2={22.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#FFF384" />
        <stop offset={0.39} stopColor="#F9C949" />
        <stop offset={0.83} stopColor="#F39400" />
        <stop offset={0.9} stopColor="#F39502" />
        <stop offset={0.93} stopColor="#F49B0A" />
        <stop offset={0.96} stopColor="#F6A617" />
        <stop offset={0.99} stopColor="#F9B42B" />
        <stop offset={1} stopColor="#FCC03A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_34326_333064"
        x1={12.0024}
        y1={6.60387}
        x2={12.0024}
        y2={13.8769}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#FFF7AD" />
        <stop offset={0.45} stopColor="#FCDC72" />
        <stop offset={0.98} stopColor="#F9BB29" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_34326_333064"
        x1={11.0715}
        y1={3.89139}
        x2={12.1802}
        y2={8.27316}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#FFF384" />
        <stop offset={0.45} stopColor="#FBD449" />
        <stop offset={0.98} stopColor="#F8AE00" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_34326_333064"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.5233 5.789) rotate(-18.9103) scale(3.50532 2.38365)"
      >
        <stop offset={0.06} stopColor="#FFF384" />
        <stop offset={0.45} stopColor="#FBD449" />
        <stop offset={0.98} stopColor="#F8AE00" />
      </radialGradient>
      <clipPath id="clip0_34326_333064">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Coin;
