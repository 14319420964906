import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      EXTRA_PROTEIN_POSSIBLE: "[PH] EXTRA_PROTEIN_POSSIBLE",
      EXTRA_PROTEIN_ADDED: "[PH] EXTRA_PROTEIN_ADDED",
      GOOD_AND_AFFORDABLE: "Godt og rimelig",
    },
    se: {
      EXTRA_PROTEIN_POSSIBLE: "[PH] EXTRA_PROTEIN_POSSIBLE",
      EXTRA_PROTEIN_ADDED: "[PH] EXTRA_PROTEIN_ADDED",
      GOOD_AND_AFFORDABLE: "Lägre pris",
    },
    dk: {
      EXTRA_PROTEIN_POSSIBLE: "Ekstra protein muligt",
      EXTRA_PROTEIN_ADDED: "Ekstra protein tilføjet",
      GOOD_AND_AFFORDABLE: "Budget",
    },
  } as const
)[language];
