import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DISCOUNT: "RABATT",
      UNIT: "matkasse",
      UNITS: "matkasser",
      NEW_CUSTOMER_DISCOUNT_ACTIVE: "Velkomsttilbud aktivert",
      SHOW_DETAILS: "Vis detaljer",
      SHOW_LESS: "Vis mindre",
      YOU_CAN_ADD_A_DIFFERENT_DISCOUNT_AT_CHECKOUT:
        "Du kan legge til en annen rabattkode i kassen.",
      TAKE_ADVANTAGE_OF_THE_OFFER: "Utnytt tilbudet",
      ADD_ANOTHER_DISCOUNT: "Legg til en annen rabattkode",
      LOADING_DISCOUNT: "Laster rabatt...",
      GIFT: "GAVE",
      ON_PURCHASE: "På kjøpet",
    },
    se: {
      DISCOUNT: "RABATT",
      UNIT: "matkasse",
      UNITS: "matkassar",
      NEW_CUSTOMER_DISCOUNT_ACTIVE: "Nykundserbjudande aktivt",
      SHOW_DETAILS: "Visa detaljer",
      SHOW_LESS: "Visa mindre",
      YOU_CAN_ADD_A_DIFFERENT_DISCOUNT_AT_CHECKOUT:
        "Du kan lägga till en annan rabattkod i kassan.",
      TAKE_ADVANTAGE_OF_THE_OFFER: "Ta del av erbjudandet",
      ADD_ANOTHER_DISCOUNT: "Lägg till en annan rabattkod",
      LOADING_DISCOUNT: "Laddar rabatt...",
      GIFT: "EN PREMIE",
      ON_PURCHASE: "På köpet",
    },
    dk: {
      DISCOUNT: "RABAT",
      UNIT: "måltidskasse",
      UNITS: "måltidskasser",
      NEW_CUSTOMER_DISCOUNT_ACTIVE: "Velkomsttilbud aktiveret",
      SHOW_DETAILS: "Vis detaljer",
      SHOW_LESS: "Vis mindre",
      YOU_CAN_ADD_A_DIFFERENT_DISCOUNT_AT_CHECKOUT:
        "Du kan tilføje endnu en rabatkode til kassen.",
      TAKE_ADVANTAGE_OF_THE_OFFER: "Benyt tilbuddet",
      ADD_ANOTHER_DISCOUNT: "Tilføj endnu en rabatkode",
      LOADING_DISCOUNT: "Indlæser rabat...",
      GIFT: "GAVE",
      ON_PURCHASE: "På köpet",
    },
  } as const
)[language];
