import * as React from "react";
import { SVGProps } from "react";
const Whisk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.8905 2.55969C28.5468 1.54146 29.8046 1.21991 30.8436 1.80941C31.8827 2.39891 32.2655 3.73868 31.6093 4.75691L27.7264 11.2414C27.1249 12.2596 25.7577 12.6348 24.7186 11.9917C23.6796 11.4022 23.2968 10.0624 23.953 9.04418L27.8905 2.55969Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7496 12.1465L9.21834 38.3778M6.48397 27.9832C4.35116 31.6295 5.60897 36.3098 9.27303 38.3778C12.9371 40.4458 17.5855 39.303 19.7183 35.6023C20.2652 34.6771 20.5933 33.6975 20.7027 32.6635L22.9449 15.1941L8.61678 25.6431C7.74178 26.2417 7.03084 27.0581 6.48397 27.9832Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27371 29.6161C7.14089 33.2624 7.14089 37.1807 9.16433 38.4324C11.1878 39.6841 14.6878 37.6705 16.8753 34.0787C17.3675 33.2079 17.805 32.2283 18.1331 31.2488L23.0003 15.1943L11.1878 27.1671C10.4768 27.8746 9.87527 28.7454 9.27371 29.6161Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Whisk;
