import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DISH_RATING_TITLE: "Slik blir rettene vurdert",
      DISH_RATING_BODY:
        "For å hjelpe deg å velge retter, viser vi rettenes vurdering. Vurderingene er basert på våre kunders gjennomsnittlige vurdering av hver rett. Vurderingene kan kun gjøres av kunder som har bestilt de respektive rettene.",

      RATING_DISH_TITLE: "Slik bruker vi dine vurderinger",
      RATING_DISH_BODY:
        "For å gi deg som kunde den beste mulige opplevelsen av vår tjeneste - samt for å hjelpe deg og andre kunder å velge retter, gir vi deg muligheten til å vurdere de rettene du har bestilt. Din vurdering blir kun synlig for deg, men vi bruker den også til å regne ut en gjennomsnittlig vurdering av rettene basert på alle kunders vurderinger. Din vurdering sendes anonymt til vårt menyteam med formål om å forbedre våre retter og gi deg som kunde en enda bedre opplevelse. Du kan lese mer om hvordan vi bruker dine personopplysninger til dette formålet i vår personvernpolicy.",
      RATING_DISH_LINK_TEXT: "Forlat denne siden for å lese mer",
    },

    se: {
      DISH_RATING_TITLE: "Så här blir rätten betygsatt",
      DISH_RATING_BODY:
        "För att hjälpa dig att välja rätter, visar vi rättens bedömning. Bedömningen är baserad på våra kunders genomsnittliga betyg av varje rätt. Bedömningen kan endast göras av kunder som har beställt respektive rätter.",

      RATING_DISH_TITLE: "Så här använder vi din bedömning",
      RATING_DISH_BODY:
        "För att ge dig som kund den bästa möjliga upplevelsen av vår tjänst - samt för att hjälpa dig och andra kunder att välja rätter, ger vi möjlighet till att bedöma de rätter du har beställt. Din bedömning blir synlig för dig, men vi använder den också för att räkna ut en genomsnittlig bedömning av rätten baserat på allas bedömningar. Din bedömning skickas anonymt till vårt meny team med syfte att förbättra våra rätter och ge dig som kund och en bättre upplevelse. Du kan läsa mer om hur vi hanterar dina personuppgifter till detta syfte i vår Integritetspolicy.",
      RATING_DISH_LINK_TEXT: "Hantera personuppgifter",
    },

    dk: {
      DISH_RATING_TITLE: "Sådan foretages bedømmelsen",
      DISH_RATING_BODY:
        "For at hjælpe dig med at vælge retter viser vi retternes bedømmelse. Bedømmelsen er baseret på vores kunders gennemsnitlige vurdering af hver ret. Vurderingerne kan kun foretages af kunder, som har bestilt de pågældende retter.",

      RATING_DISH_TITLE: "Sådan bruges din vurdering",
      RATING_DISH_BODY:
        "For at give dig den bedste oplevelse har du mulighed for at bedømme dine retter. Din vurdering vil kun blive vist til dig og indgår i udarbejdelsen af retternes gennemsnitlige vurdering, der er baseret på alle kunders vurdering af de enkelte retter. Bedømmelsen videresendes desuden anonymt til vores menuteam med det formål at forbedre vores retter og give vores kunder de bedste smagsoplevelser. Du kan læse mere om, hvordan og hvor længe vi bruger dine persondata til dette formål i vores persondatapolitik.",
      RATING_DISH_LINK_TEXT: "Forlad denne side og læs mere.",
    },
  } as const
)[language];
