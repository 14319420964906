import clsx from "clsx";

import { Container } from "@chef/components";

type MainProps = {
  grid?: boolean | "default" | "no-gutter";
  noPadding?: boolean;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
} & (
  | { transparent?: boolean; gradient?: never }
  | { gradient?: boolean; transparent?: never }
);

export const Main = ({
  transparent = false,
  gradient = false,
  grid = false,
  noPadding = false,
  className,
  children,
  style,
}: MainProps) => {
  return (
    <Container
      as="main"
      hasPadding={!noPadding}
      outerClassName={clsx(
        gradient && "bg-gradient-to-b from-background to-grey-2",
        !gradient && !transparent && "bg-background",
        !noPadding && "pt-10 pb-32",
        "grow",
        className,
      )}
      grid={grid}
      style={style}
    >
      {children}
    </Container>
  );
};
