import clsx from "clsx";

interface ProgressBarProps {
  className?: string;
  progress: { current: number; projected?: number };
}

export const ProgressBar = ({ className, progress }: ProgressBarProps) => {
  return (
    <div
      className={clsx("relative bg-white rounded-full", className)}
      role="progressbar"
      aria-valuemin={0}
      aria-valuenow={progress.current}
      aria-valuemax={100}
    >
      {progress.projected !== undefined && (
        <div
          className={clsx(
            "h-2 w-full bg-primary/50 rounded-full absolute top-0 left-0 bottom-0 transition-all",
          )}
          style={{ width: `${progress.projected}%` }}
        />
      )}

      <div
        className={clsx("h-2 w-full bg-primary rounded-full transition-all")}
        style={{ width: `${progress.current}%` }}
      />
    </div>
  );
};
