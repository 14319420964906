import * as React from "react";
import { SVGProps } from "react";
const Ramen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_14624_213183)">
      <path
        d="M40.5 80C62.5914 80 80.5 62.0914 80.5 40C80.5 17.9086 62.5914 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0914 18.4086 80 40.5 80Z"
        fill="#F4F4F1"
      />
      <path
        d="M32.98 60.8334H47.66M50.5 16.2734L40.32 36.8334L50.5 16.2734ZM59.32 22.1434L46.12 36.8334L59.32 22.1434Z"
        stroke="#282828"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3701 37.3438C18.746 42.9092 21.2219 48.1241 25.297 51.9333C29.3721 55.7424 34.742 57.8614 40.3201 57.8614C45.8983 57.8614 51.2682 55.7424 55.3432 51.9333C59.4183 48.1241 61.8943 42.9092 62.2701 37.3438H18.3701Z"
        stroke="#282828"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14624_213183">
        <rect width={80} height={80} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default Ramen;
