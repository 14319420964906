import { PRODUCT_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

interface IProduct {
  productTypeId: string;
}
interface IVariation {
  product: IProduct;
}
interface IIsSomethingProductBasket {
  variation: IVariation;
}

export const isMealboxBasket = (basket: IIsSomethingProductBasket) => {
  return isEqualStrings(
    basket.variation.product.productTypeId,
    PRODUCT_TYPE_IDS.MEALBOX,
  );
};

export const isAddonProductBasket = (basket: IIsSomethingProductBasket) => {
  return isEqualStrings(
    basket.variation.product.productTypeId,
    PRODUCT_TYPE_IDS.ADDON,
  );
};

export const isStandaloneProductBasket = (
  basket: IIsSomethingProductBasket,
) => {
  return isEqualStrings(
    basket.variation.product.productTypeId,
    PRODUCT_TYPE_IDS.STANDALONE,
  );
};
