import * as React from "react";
import { SVGProps } from "react";
const LoyaltySilver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_31314_313629)">
      <g clipPath="url(#clip1_31314_313629)">
        <path
          d="M9.99873 20.1243C4.48658 20.1243 0 15.6098 0 10.0608C0 4.51189 4.48658 0 9.99873 0C15.5109 0 19.9975 4.51445 19.9975 10.0608C19.9975 15.6072 15.5109 20.1217 9.99873 20.1217V20.1243ZM9.99873 0.660651C4.84795 0.660651 0.656572 4.87806 0.656572 10.0608C0.656572 15.2436 4.84794 19.4636 10.0013 19.4636C15.1546 19.4636 19.346 15.2462 19.346 10.0608C19.346 4.8755 15.1521 0.660651 9.99873 0.660651Z"
          fill="#E4E4E4"
        />
        <path
          d="M18.9165 12.2157C20.0978 7.26088 17.0636 2.28061 12.1393 1.09198C7.21506 -0.0966613 2.26554 2.95645 1.08424 7.91128C-0.0970562 12.8661 2.9372 17.8464 7.86145 19.035C12.7857 20.2237 17.7352 17.1706 18.9165 12.2157Z"
          fill="url(#paint0_radial_31314_313629)"
        />
        <path
          d="M18.9165 12.2157C20.0978 7.26088 17.0636 2.28061 12.1393 1.09198C7.21506 -0.0966613 2.26554 2.95645 1.08424 7.91128C-0.0970562 12.8661 2.9372 17.8464 7.86145 19.035C12.7857 20.2237 17.7352 17.1706 18.9165 12.2157Z"
          fill="url(#paint1_linear_31314_313629)"
        />
        <path
          d="M6.57209 1.78958C10.9416 -0.0310541 16.3214 1.99955 18.2352 6.31939C18.2072 6.24513 18.1232 5.91993 18.0926 5.84823C16.2527 1.37987 10.8322 -0.494534 6.39141 1.35683C1.95318 3.20818 -0.469516 8.3756 1.37041 12.844C1.40095 12.9157 1.43149 13.0258 1.46203 13.0975C-0.22012 8.68288 2.20258 3.61277 6.57209 1.78958Z"
          fill="#696969"
        />
        <path
          d="M16.0626 16.4768C15.9176 16.5997 15.77 16.7149 15.6198 16.8276C13.2098 18.9427 9.76156 19.693 6.57031 18.5202C6.64411 18.5509 6.71791 18.5868 6.79426 18.6149C11.4412 20.4637 16.6276 18.2359 18.465 13.5627C18.8212 12.6588 18.9688 11.5833 19.0274 10.6641C18.7602 12.8176 17.8389 14.966 16.0601 16.4768H16.0626Z"
          fill="#989898"
        />
        <path
          d="M9.99657 0.695312C4.86361 0.695312 0.6875 4.89736 0.6875 10.0622C0.6875 15.2271 4.86361 19.4291 9.99657 19.4291C15.1295 19.4291 19.3056 15.2271 19.3056 10.0622C19.3056 4.89736 15.1295 0.695312 9.99657 0.695312ZM9.99657 19.1475C5.01884 19.1475 0.967434 15.0734 0.967434 10.0622C0.967434 5.051 5.01884 0.979546 9.99657 0.979546C14.9743 0.979546 19.0257 5.05356 19.0257 10.0648C19.0257 15.076 14.9769 19.15 9.99657 19.15V19.1475Z"
          fill="url(#paint2_linear_31314_313629)"
        />
        <path
          d="M10.0038 20.0211C4.55014 20.0211 0.109375 15.5553 0.109375 10.0652C0.109375 4.57517 4.55014 0.109375 10.0038 0.109375C15.4574 0.109375 19.8982 4.57517 19.8982 10.0652C19.8982 15.5553 15.4599 20.0211 10.0038 20.0211ZM10.0038 0.762344C4.90642 0.762344 0.758313 4.93623 0.758313 10.0652C0.758313 15.1942 4.90642 19.3681 10.0038 19.3681C15.1011 19.3681 19.2492 15.1942 19.2492 10.0652C19.2492 4.93623 15.1037 0.762344 10.0038 0.762344Z"
          fill="url(#paint3_linear_31314_313629)"
        />
      </g>
      <path
        d="M13.5005 12.45C15.1505 12.4 17.0005 10.575 17.0005 8.95C17.0037 8.59199 16.9268 8.2378 16.7754 7.91336C16.624 7.58893 16.4019 7.30248 16.1255 7.075C15.5207 6.6053 14.7645 6.37399 14.0005 6.425V5.5H6.00047V6.425C5.23759 6.34671 4.47448 6.57115 3.87547 7.05C3.59513 7.28516 3.37102 7.58013 3.2196 7.91324C3.06819 8.24636 2.99331 8.60916 3.00047 8.975C3.00047 10.65 4.82547 12.475 6.50047 12.475C6.80905 13.0199 7.24128 13.4848 7.76234 13.8322C8.28341 14.1796 8.87876 14.3997 9.50047 14.475V18.05C8.97348 18.6021 8.26135 18.9403 7.50047 19H6.75047C6.55156 19 6.36079 19.079 6.22014 19.2197C6.07949 19.3603 6.00047 19.5511 6.00047 19.75V20.5H14.0005V19.75C14.0005 19.5511 13.9215 19.3603 13.7808 19.2197C13.6401 19.079 13.4494 19 13.2505 19H12.5005C11.7396 18.9403 11.0275 18.6021 10.5005 18.05V14.475C11.1245 14.3971 11.7214 14.1731 12.2426 13.8212C12.7639 13.4694 13.1948 12.9996 13.5005 12.45ZM15.4755 7.85C15.6436 7.98452 15.7784 8.15601 15.8695 8.35112C15.9605 8.54624 16.0054 8.75974 16.0005 8.975C16.0005 9.9 14.9255 11.075 13.9005 11.4C13.9645 11.1211 13.998 10.8361 14.0005 10.55V7.425C14.5311 7.38501 15.0575 7.5456 15.4755 7.875V7.85ZM4.00047 8.975C3.99548 8.75612 4.04012 8.53894 4.13104 8.33977C4.22197 8.14061 4.35682 7.96461 4.52547 7.825C4.73172 7.66244 4.96869 7.54322 5.22215 7.47448C5.47561 7.40575 5.74034 7.38892 6.00047 7.425V10.525C6.00108 10.8195 6.03462 11.113 6.10047 11.4C5.10047 11.125 4.00047 9.925 4.00047 8.975Z"
        fill="white"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_31314_313629"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.0007 10.0637) scale(9.16911 9.22607)"
      >
        <stop stopColor="white" />
        <stop offset={0.31} stopColor="#FCFCFC" />
        <stop offset={0.44} stopColor="#F5F5F5" />
        <stop offset={0.55} stopColor="#E8E8E8" />
        <stop offset={0.64} stopColor="#D6D6D6" />
        <stop offset={0.71} stopColor="#BFBFBF" />
        <stop offset={0.78} stopColor="#A2A2A2" />
        <stop offset={0.84} stopColor="#808080" />
        <stop offset={0.9} stopColor="#585858" />
        <stop offset={0.95} stopColor="#2C2C2C" />
        <stop offset={1} />
      </radialGradient>
      <linearGradient
        id="paint1_linear_31314_313629"
        x1={3.88032}
        y1={3.40089}
        x2={16.0312}
        y2={16.4621}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8E8E8" />
        <stop offset={0.17} stopColor="#E5E5E5" />
        <stop offset={0.3} stopColor="#DBDBDB" />
        <stop offset={0.34} stopColor="#D6D6D6" />
        <stop offset={0.47} stopColor="#C0C0C0" />
        <stop offset={0.67} stopColor="#989898" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_31314_313629"
        x1={14.6511}
        y1={18.1744}
        x2={5.25785}
        y2={2.00297}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0F0F0" />
        <stop offset={0.18} stopColor="#D6D6D6" />
        <stop offset={0.65} stopColor="#989898" />
        <stop offset={1} stopColor="#D6D6D6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_31314_313629"
        x1={14.951}
        y1={18.687}
        x2={4.96693}
        y2={1.49959}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E0E0E0" />
        <stop offset={0.35} stopColor="#989898" />
        <stop offset={0.82} stopColor="#D6D6D6" />
        <stop offset={1} stopColor="#F0F0F0" />
      </linearGradient>
      <clipPath id="clip0_31314_313629">
        <rect width={20} height={20} rx={10} fill="white" />
      </clipPath>
      <clipPath id="clip1_31314_313629">
        <rect width={20} height={21} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LoyaltySilver;
