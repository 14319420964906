import * as React from "react";
import { SVGProps } from "react";
const Heart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 41 38"
    fill="none"
    {...props}
  >
    <path
      d="M30 1C25.8833 1 21.83 3.8 20.5 7.66667C19.17 3.8 15.1167 1 11 1C8.48044 1 6.06408 2.05357 4.28249 3.92893C2.50089 5.8043 1.5 8.34784 1.5 11C1.5 19.6667 8.46667 28.3333 20.5 37C32.5333 28.3333 39.5 19.6667 39.5 11C39.5 8.34784 38.4991 5.8043 36.7175 3.92893C34.9359 2.05357 32.5196 1 30 1V1Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Heart;
