import * as React from "react";
import { SVGProps } from "react";
const Link = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0001 5.56039L15.2197 2.34057C15.6414 1.91576 16.143 1.57861 16.6956 1.34853C17.2482 1.11845 17.8408 1 18.4394 1C19.0379 1 19.6306 1.11845 20.1831 1.34853C20.7357 1.57861 21.2373 1.91576 21.659 2.34057C22.0838 2.76229 22.4209 3.26391 22.651 3.81651C22.8811 4.36912 22.9995 4.9618 22.9995 5.56039C22.9995 6.15898 22.8811 6.75165 22.651 7.30426C22.4209 7.85687 22.0838 8.35848 21.659 8.78021L16.5003 13.9392C16.0786 14.364 15.577 14.7012 15.0244 14.9313C14.4718 15.1614 13.8792 15.2798 13.2806 15.2798C12.6821 15.2798 12.0894 15.1614 11.5368 14.9313C10.9843 14.7012 10.4827 14.364 10.061 13.9392C9.84343 13.7274 9.64734 13.4945 9.47559 13.244"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9994 18.4399L8.77978 21.6597C8.35808 22.0845 7.85649 22.4217 7.30392 22.6517C6.75134 22.8818 6.1587 23.0003 5.56014 23.0003C4.96158 23.0003 4.36894 22.8818 3.81636 22.6517C3.26378 22.4217 2.76219 22.0845 2.34049 21.6597C1.91571 21.238 1.57857 20.7364 1.34851 20.1837C1.11844 19.6311 1 19.0385 1 18.4399C1 17.8413 1.11844 17.2486 1.34851 16.696C1.57857 16.1434 1.91571 15.6418 2.34049 15.2201L7.49924 10.061C7.92094 9.63622 8.42253 9.29906 8.97511 9.06899C9.52769 8.83891 10.1203 8.72046 10.7189 8.72046C11.3174 8.72046 11.9101 8.83891 12.4627 9.06899C13.0152 9.29906 13.5168 9.63622 13.9385 10.061C14.1563 10.2727 14.3524 10.5056 14.5239 10.7562"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Link;
