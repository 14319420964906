import * as React from "react";
import { SVGProps } from "react";
const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.0013 3L19.0006 13L25 23H13.0013H1L6.99936 13L13.0013 3Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 11V15"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 19.5C13.5523 19.5 14 19.0523 14 18.5C14 17.9477 13.5523 17.5 13 17.5C12.4477 17.5 12 17.9477 12 18.5C12 19.0523 12.4477 19.5 13 19.5Z"
      fill="currentColor"
    />
  </svg>
);
export default Warning;
