import React from "react";
import clsx from "clsx";

import { Hamburger } from "@chef/icons/large";

import HeaderBase from "../internals/HeaderBase";
import { Button } from "../components/Button";
import { Language } from "../../types";

interface HeaderDefaultProps {
  language?: Language;
  Logo?: () => React.ReactElement | null;
  onMenuClick?: () => void;
  onLoginClick?: () => void;
  title?: string;
  menuItems?: React.ReactNode;
  isLoggedIn?: boolean;
  hideBurger?: boolean;
  className?: string;
  id?: string;
}

const intl = {
  no: {
    LOG_IN: "Logg inn",
    OPEN_MENU: "Åpne meny",
  },
  se: {
    LOG_IN: "Logga inn",
    OPEN_MENU: "Öppna menyn",
  },
  dk: {
    LOG_IN: "Log ind",
    OPEN_MENU: "Åbn menuen",
  },
};

const HeaderDefault = ({
  language = "no",
  Logo = () => null,
  onMenuClick = () => null,
  onLoginClick = () => null,
  title = "",
  menuItems = [],
  isLoggedIn,
  className = "",
  hideBurger = false,
  id,
  ...props
}: HeaderDefaultProps) => {
  const { LOG_IN, OPEN_MENU } = intl[language];

  return (
    <HeaderBase className={clsx("h-15 md:h-20", className)} {...props}>
      <Logo />

      {title && (
        <div className="md:hidden">
          <h1 className="text-base">{title}</h1>
        </div>
      )}

      <div className="flex items-center">
        <nav className="hidden space-x-6 mr-7 md:block">{menuItems}</nav>

        {!hideBurger && (
          <button
            aria-label={OPEN_MENU}
            className={clsx(
              "h-10 w-10 a11y-focus:focus-ring",
              !isLoggedIn && "md:hidden",
            )}
            onClick={onMenuClick}
          >
            <Hamburger className="w-5 h-5" />
          </button>
        )}

        {!isLoggedIn && (
          <Button
            tiny
            outlined
            className="hidden md:inline-flex"
            onClick={onLoginClick}
            id={id}
          >
            {LOG_IN}
          </Button>
        )}
      </div>
    </HeaderBase>
  );
};

export default HeaderDefault;
