import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { IAddDiscount } from "./types";
import { tags } from "../../graphql/api";

export const discountApi = createApi({
  reducerPath: "discount",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    addRk: builder.query<IAddDiscount, string>({
      query: (rk) => {
        return `/discount/validateCoupon?coupon=${rk}`;
      },
      transformResponse: (
        response: Omit<IAddDiscount, "source">,
      ): IAddDiscount => ({
        ...response,
        source: "rk",
      }),
      providesTags: [tags.discount],
    }),
    addSid: builder.query<IAddDiscount, string>({
      query: (sid) => {
        return `/discount/getSalesPoint?sid=${sid}`;
      },
      transformResponse: (
        response: Omit<IAddDiscount, "source">,
      ): IAddDiscount => ({
        ...response,
        source: "sid",
      }),
      providesTags: [tags.discount],
    }),
  }),
});

export const {
  useAddRkQuery,
  useAddSidQuery,
  useLazyAddRkQuery,
  useLazyAddSidQuery,
} = discountApi;
