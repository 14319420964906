import clsx from "clsx";

interface RecipeInformationProps {
  Icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement | null;
  children: React.ReactNode;
  className?: string;
}

export const RecipeInformation = ({
  Icon,
  children,
  className,
}: RecipeInformationProps) => {
  return (
    <div
      className={clsx(
        className,
        "py-2 text-xs text-center bg-informationBG text-secondary px-1 rounded-sm md:text-base lg:text-xs",
      )}
    >
      <div className="gap-1 mx-auto text-center">
        <div className="mb-1">
          <Icon width={18} height={18} />
        </div>
        {children}
      </div>
    </div>
  );
};
