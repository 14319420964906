import { ChatBox } from "@chef/components";
import { getPreferenceCombo, PreferenceCombos } from "@chef/helpers";
import {
  getDishCount,
  TASTE_PREFERENCE_SELETION_LIMITS,
} from "@chef/constants";

import { intl } from "./TastePreferencesNotification.Intl";

interface TastePreferencesNotificationProps {
  tastePreferenceIds: string[];
  conceptPreferenceIds: string[];
}

const textMap = new Map<PreferenceCombos, string | null>([
  [PreferenceCombos.NONE, null],
  [PreferenceCombos.VEGAN, intl.YOU_HAVE_CREATED_A_VEGAN_MEALBOX],
  [PreferenceCombos.VEGETARIAN, intl.YOU_HAVE_CREATED_A_VEGETARIAN_MEALBOX],
  [PreferenceCombos.PESCETARIAN, intl.YOU_HAVE_CREATED_A_PESCETARIAN_MEALBOX],
  [PreferenceCombos.FLEXITARIAN, intl.YOU_HAVE_CREATED_A_FLEXITARIAN_MEALBOX],
]);

export const TastePreferencesNotification = ({
  tastePreferenceIds,
  conceptPreferenceIds,
}: TastePreferencesNotificationProps) => {
  const combo = getPreferenceCombo([
    ...conceptPreferenceIds,
    ...tastePreferenceIds,
  ]);

  const text = textMap.get(combo);

  if (combo >= PreferenceCombos.VEGAN) {
    return (
      <ChatBox background="information" className="justify-center text-sm">
        <strong>{text}</strong>
      </ChatBox>
    );
  }

  if (tastePreferenceIds.length >= TASTE_PREFERENCE_SELETION_LIMITS.MAX_LIMIT) {
    return (
      <ChatBox background="error" className="flex-col justify-center text-sm">
        <strong className="text-error">
          {intl.WE_WILL_NOT_BE_ABLE_TO_MATCH_YOUR_PREFERENCES}
        </strong>
        <p>
          {intl.WE_WILL_DO_OUR_BEST_TO_MATCH_YOUR_NEEDS(
            getDishCount({ portions: 2 }),
          )}
        </p>
      </ChatBox>
    );
  }

  if (text) {
    return (
      <ChatBox background="information" className="justify-center text-sm">
        <strong>{text}</strong>
      </ChatBox>
    );
  }

  if (
    tastePreferenceIds.length >= TASTE_PREFERENCE_SELETION_LIMITS.STRONG_LIMIT
  ) {
    return (
      <ChatBox
        background="highlight"
        className="flex-col justify-center text-sm"
      >
        <strong className="text-error">{intl.YOU_KNOW_WHAT_YOU_WANT}</strong>
        <p>
          {
            intl.WE_WILL_DO_OUR_BEST_BUT_STRONGLY_RECOMMEND_THAT_YOU_CHOOSE_YOUR_OWN_DISHES
          }
        </p>
      </ChatBox>
    );
  }

  if (
    tastePreferenceIds.length >= TASTE_PREFERENCE_SELETION_LIMITS.SOFT_LIMIT
  ) {
    return (
      <ChatBox
        background="information"
        className="flex-col justify-center text-sm"
      >
        <strong>{intl.HEADS_UP}</strong>
        <p>
          {intl.THE_MORE_INGREDIENTS_YOU_REMOVE_THE_FEWER_DISHES_WE_CAN_SUGGEST}
        </p>
      </ChatBox>
    );
  }

  return null;
};
