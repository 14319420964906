import { useState } from "react";

import { DeliveryInterval } from "@chef/constants";

import { Button, Dropdown } from "../components";
import { Language } from "../../types";

const intl = {
  se: {
    whenToDeliverHeader: "När ska vi leverera?",
    deliveryFrequencyHeader: "Hur ofta ska vi leverera?",
    saveChanges: "Spara ändringar",
    abort: "Avbryt",
  },
  no: {
    whenToDeliverHeader: "Når skal vi levere?",
    deliveryFrequencyHeader: "Hvor ofte skal vi levere?",
    saveChanges: "Lagre endringer",
    abort: "Avbryt",
  },
  dk: {
    whenToDeliverHeader: "Hvornår skal vi levere?",
    deliveryFrequencyHeader: "Hvor ofte skal vi levere?",
    saveChanges: "Gem ændringer",
    abort: "Afbryd",
  },
};

interface DeliveryDetailsProps {
  language: Language;
  whenToDeliverOptions: {
    label: string;
    value: string;
  }[];
  whenToDeliverInitialValue?: string;
  deliveryFrequencyOptions: {
    label: string;
    value: DeliveryInterval;
  }[];
  deliveryFrequencyInitialValue?: DeliveryInterval;
  onSave: (args: { date?: string; frequency?: DeliveryInterval }) => void;
  onAbort: () => void;
  isLoading?: boolean;
}

const DeliveryDetails = ({
  language,
  whenToDeliverOptions,
  whenToDeliverInitialValue,
  deliveryFrequencyOptions,
  deliveryFrequencyInitialValue,
  onSave,
  onAbort,
  isLoading = false,
}: DeliveryDetailsProps) => {
  const { whenToDeliverHeader, deliveryFrequencyHeader, saveChanges, abort } =
    intl[language];

  const [whenToDeliver, setWhenToDeliver] = useState(whenToDeliverInitialValue);
  const [deliveryFrequency, setDeliveryFrequency] = useState(
    deliveryFrequencyInitialValue,
  );

  return (
    <div className="flex justify-center w-full bg-inherit">
      <div className="flex flex-col items-center w-full">
        <div className="mb-3">
          <strong>{whenToDeliverHeader}</strong>
        </div>

        <Dropdown
          options={whenToDeliverOptions}
          className="w-full mb-10"
          onChange={(val) => setWhenToDeliver(val)}
          value={whenToDeliver}
        />

        <div className="mb-3">
          <strong>{deliveryFrequencyHeader}</strong>
        </div>

        <Dropdown
          options={deliveryFrequencyOptions}
          className="w-full mb-10"
          onChange={(val) => setDeliveryFrequency(val)}
          value={deliveryFrequency}
        />

        <Button
          className="w-full mb-3 lg:w-64"
          onClick={() =>
            onSave({ date: whenToDeliver, frequency: deliveryFrequency })
          }
          loading={isLoading}
          disabled={!whenToDeliver || !deliveryFrequency}
          primary
        >
          {saveChanges}
        </Button>

        <Button
          className="w-full mb-10 lg:w-64"
          onClick={onAbort}
          disabled={isLoading}
          outlined
        >
          {abort}
        </Button>
      </div>
    </div>
  );
};

export default DeliveryDetails;
