import { Key, useRef } from "react";
import clsx from "clsx";
import { RadioGroup } from "@headlessui/react";
import { motion } from "framer-motion";

import { AnimatedCheck } from "../AnimatedCheck";

export interface RadioButtonOption<T> {
  name: string;
  value: T;
}
interface RadioButtonBarProps<T> {
  options: RadioButtonOption<T>[];
  onChange: (value: T) => void;
  value: T;
  label?: string;
  hasCheckmark?: boolean;
  small?: boolean;
  className?: string;
  name: string;
  animation?: "default" | "none";
  suffix?: string;
  prefix?: string;
}

interface OptionProps<T> {
  name: string;
  option: RadioButtonOption<T>;
  hasCheckmark: boolean;
  small: boolean;
  prefix?: string;
  suffix?: string;
}

const OptionContent = <T,>({
  option,
  hasCheckmark,
  small,
  prefix,
  suffix,
}: Omit<OptionProps<T>, "name">) => {
  let name = option.name;

  if (prefix) {
    name = `${prefix} ${name}`;
  }

  if (suffix) {
    name = `${name} ${suffix}`;
  }

  return (
    <>
      {hasCheckmark && (
        <AnimatedCheck
          className={clsx(
            "absolute -top-px -right-px text-white rounded-tr border-2 border-information rounded-bl-sm bg-information",
            small ? "w-3 h-3" : "w-4 h-4",
          )}
          isChecked
        />
      )}
      <RadioGroup.Label as="span" className="m-3 text-sm whitespace-nowrap">
        {name}
      </RadioGroup.Label>
    </>
  );
};

const AnimatedOption = <T,>({
  name,
  option,
  hasCheckmark,
  small,
  prefix,
  suffix,
}: OptionProps<T>) => (
  <motion.div
    layoutId={`${name}-selected`}
    transition={{ type: "spring", stiffness: 700, damping: 40 }}
    className="absolute z-10 flex items-center justify-center rounded bg-informationBG outline-2 -inset-2 outline outline-information"
  >
    <OptionContent
      option={option}
      hasCheckmark={hasCheckmark}
      small={small}
      prefix={prefix}
      suffix={suffix}
    />
  </motion.div>
);

const NonAnimatedOption = <T,>({
  option,
  hasCheckmark,
  small,
  prefix,
  suffix,
}: Omit<OptionProps<T>, "name">) => (
  <div className="absolute z-10 flex items-center justify-center rounded outline-2 -inset-2 outline outline-information bg-informationBG">
    <OptionContent
      option={option}
      hasCheckmark={hasCheckmark}
      small={small}
      prefix={prefix}
      suffix={suffix}
    />
  </div>
);

export const RadioButtonBar = <T extends Key | null | undefined>({
  options,
  onChange,
  value,
  label = "",
  hasCheckmark = false,
  small = false,
  className,
  name,
  animation = "default",
  suffix,
  prefix,
}: RadioButtonBarProps<T>) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const inner = useRef<HTMLDivElement>(null);

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className="inline-block w-full p-3 overflow-x-scroll scrollbar-hidden"
    >
      {label && (
        <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
      )}
      <div
        className={clsx("flex", small ? "h-11" : "h-14", className)}
        ref={wrapper}
      >
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            data-testid={option.value}
            className={clsx(
              "flex relative w-16 flex-grow first:rounded-l last:rounded-r justify-center items-center border-t-1.5 border-b-1.5 border-l-1.5 last:border-r-1.5 border-grey-2 cursor-pointer h-full focus:outline-none a11y-focus:focus-ring",
            )}
          >
            {({ checked }) => {
              if (checked && inner.current && wrapper.current) {
                const dims = inner.current.getBoundingClientRect();

                if (
                  dims.right >
                  wrapper.current.scrollLeft + window.innerWidth
                ) {
                  wrapper.current.scrollTo({
                    left: dims.left,
                    top: wrapper.current.scrollTop,
                    behavior: "smooth",
                  });
                }

                if (dims.left < wrapper.current.scrollLeft) {
                  wrapper.current.scrollTo({
                    left: dims.left,
                    top: wrapper.current.scrollTop,
                    behavior: "smooth",
                  });
                }
              }

              return (
                <>
                  {checked && animation === "default" && (
                    <AnimatedOption
                      name={name}
                      option={option}
                      hasCheckmark={hasCheckmark}
                      small={small}
                      prefix={prefix}
                      suffix={suffix}
                    />
                  )}
                  {checked && animation === "none" && (
                    <NonAnimatedOption
                      option={option}
                      hasCheckmark={hasCheckmark}
                      small={small}
                      prefix={prefix}
                      suffix={suffix}
                    />
                  )}
                  <div
                    className="flex items-center"
                    id={`${name}-${option.value}`}
                    ref={inner}
                  >
                    <RadioGroup.Label
                      as="div"
                      className="m-3 text-sm whitespace-nowrap"
                    >
                      {option.name}
                    </RadioGroup.Label>
                  </div>
                </>
              );
            }}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
