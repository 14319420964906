import { useEffect, useRef, useState } from "react";

export const useWakeLock = () => {
  const [isSupported, setSupported] = useState(false);
  const [lock, setLock] = useState<boolean>(false);

  const sentinel = useRef<WakeLockSentinel>();

  useEffect(() => {
    if ("wakeLock" in navigator) {
      setSupported(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (lock) {
        if (
          sentinel.current?.released === undefined ||
          sentinel.current?.released === true
        ) {
          sentinel.current = await navigator.wakeLock.request("screen");
        }
      } else {
        if (sentinel.current?.released === false) {
          await sentinel.current.release();
        }
      }
    })();
  }, [lock, sentinel]);

  useEffect(() => {
    return () => {
      if (sentinel.current?.released === false) {
        sentinel.current.release();
      }
    };
  }, []);

  const toggleWakeLock = () => setLock((prev) => !prev);

  return {
    isWakeLocked: lock,
    toggleWakeLock,
    isWakeLockSupported: isSupported,
  };
};
