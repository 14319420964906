import * as React from "react";
import { SVGProps } from "react";
const Apple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0284 10.719C15.0375 10.0274 15.2252 9.34933 15.574 8.74783C15.9229 8.14633 16.4215 7.64095 17.0236 7.27872C16.6411 6.74416 16.1366 6.30424 15.55 5.9939C14.9634 5.68356 14.3109 5.51134 13.6444 5.49093C12.2227 5.34488 10.8443 6.32351 10.1198 6.32351C9.3813 6.32351 8.26582 5.50544 7.06462 5.52962C6.28766 5.55418 5.53044 5.77529 4.86676 6.1714C4.20308 6.5675 3.65556 7.1251 3.27755 7.78985C1.64014 10.5642 2.8615 14.6417 4.43004 16.8843C5.21481 17.9825 6.13198 19.2091 7.3321 19.1656C8.50647 19.1179 8.94508 18.4328 10.3626 18.4328C11.7671 18.4328 12.1786 19.1656 13.403 19.138C14.6632 19.1179 15.4571 18.0349 16.2144 16.9264C16.7783 16.1439 17.2122 15.2791 17.5 14.364C16.7679 14.061 16.1431 13.5538 15.7036 12.9056C15.2641 12.2574 15.0292 11.4969 15.0284 10.719Z"
      fill="currentColor"
    />
    <path
      d="M12.364 3.72548C13.0511 2.91828 13.3896 1.88076 13.3076 0.833252C12.2579 0.941148 11.2882 1.43212 10.5919 2.20835C10.2514 2.58756 9.99061 3.02872 9.82446 3.50662C9.6583 3.98451 9.59002 4.48977 9.62352 4.9935C10.1486 4.99879 10.668 4.88742 11.1427 4.66778C11.6174 4.44814 12.035 4.12595 12.364 3.72548V3.72548Z"
      fill="currentColor"
    />
  </svg>
);
export default Apple;
