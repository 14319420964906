import * as React from "react";
import { SVGProps } from "react";
const Email = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9326 4.5H4.06742C4.00482 4.5 3.97352 4.5 3.94706 4.5007C2.88399 4.52885 2.02885 5.38399 2.0007 6.44706C2 6.47352 2 6.50482 2 6.56742C2 6.60203 2 6.61934 2.00027 6.6357C2.01069 7.27268 2.32396 7.8666 2.84378 8.23489C2.85712 8.24435 2.8714 8.25412 2.89995 8.27365L2.89998 8.27367L10.193 13.2636C10.845 13.7097 11.171 13.9328 11.5246 14.0193C11.8369 14.0958 12.1631 14.0958 12.4754 14.0193C12.829 13.9328 13.155 13.7097 13.807 13.2636L21.1 8.27367L21.1 8.27365C21.1286 8.25412 21.1429 8.24435 21.1562 8.23489C21.676 7.8666 21.9893 7.27268 21.9997 6.6357C22 6.61934 22 6.60203 22 6.56742C22 6.50482 22 6.47352 21.9993 6.44706C21.9711 5.38399 21.116 4.52885 20.0529 4.5007C20.0265 4.5 19.9952 4.5 19.9326 4.5Z"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M21.5 8V16.8C21.5 17.9201 21.5 18.4802 21.282 18.908C21.0903 19.2843 20.7843 19.5903 20.408 19.782C19.9802 20 19.4201 20 18.3 20H5.7C4.57989 20 4.01984 20 3.59202 19.782C3.21569 19.5903 2.90973 19.2843 2.71799 18.908C2.5 18.4802 2.5 17.9201 2.5 16.8V8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Email;
