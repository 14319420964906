import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import {
  IDeviationProduct,
  ProductsByCategoriesQuery,
  createPayloadProductsArray,
  selectCurrentlySubmittingQuickAddVariationId,
  selectDeviationProducts,
  setCurrentlySubmittingQuickAddVariationId,
  setStandaloneProductModal,
  updateAddonToDeviation,
  useBillingQuery,
  useCalendarQuery,
  useCreateOrUpdateDeviationMutation,
  useValidateBasketMutation,
} from "@chef/state-management";
import {
  getBasketDataFromCalendar,
  getBasketFinances,
  getDiscountedAmount,
  getImageFromImages,
  getPriceOfProductByVariation,
  getStockData,
  getWeeklyTimeblockDataFromCalendar,
} from "@chef/state-management/helpers";
import {
  Button,
  ButtonCircle,
  Card,
  CurrencyWithDiscount,
} from "@chef/components";
import { language } from "@chef/constants";
import { Check, Loading, Minus, Plus } from "@chef/icons/small";
import { useTrack } from "@chef/feature-tracking";
import { useDebounce, useEffectOnce, usePrevious } from "@chef/hooks";
import { formatDate } from "@chef/helpers";
import { useLoyalty } from "@chef/feature-flags";
import { sum } from "@chef/utils/array";
import { withErrorBoundary } from "@chef/utils/withErrorBoundary";
import { useCalendarData } from "@chef/state-management/hooks";
import { isEqualStrings } from "@chef/utils/equal";
import { intl } from "./QuickAddStandaloneCard.Intl";

interface QuickAddStandaloneCardProps {
  product: ProductsByCategoriesQuery["productsByCategories"][0]["products"][0];
  variationId: string;
  week: number;
  year: number;
  className?: string;
  isSubmittingDeviation?: boolean;
}

export const QuickAddStandaloneCard = ({
  week,
  year,
  product,
  variationId,
  className,
}: QuickAddStandaloneCardProps) => {
  const dispatch = useDispatch();
  const track = useTrack();

  const { submitDeviation, isSubmittingDeviation } = useSubmitDeviationChange({
    week,
    year,
  });

  const deviationProducts = useSelector(
    selectDeviationProducts({ week, year }),
  );

  const currentlySubmittingQuickAddVariationId = useSelector(
    selectCurrentlySubmittingQuickAddVariationId,
  );

  const dirty = useRef(false);

  const [quantity, setQuantity] = useState<number>(0);

  const debouncedQuantity = useDebounce(quantity, 750, (v) => v > 0);
  const prevDebouncedQuantity = usePrevious(debouncedQuantity);

  useEffect(() => {
    if (
      prevDebouncedQuantity === undefined ||
      prevDebouncedQuantity === debouncedQuantity
    ) {
      return;
    }

    const updatedProduct = {
      productId: product.productId,
      productTypeId: product.productTypeId,
      quantity: debouncedQuantity,
      variationId: variationId,
      price: price || 0,
    };

    dispatch(setCurrentlySubmittingQuickAddVariationId({ variationId }));
    dispatch(updateAddonToDeviation({ week, year, product: updatedProduct }));

    dirty.current = true;
  }, [debouncedQuantity]);

  useEffect(() => {
    const submitAndDispatch = async () => {
      if (dirty.current && deviationProducts) {
        try {
          await submitDeviation(deviationProducts);
        } finally {
          dirty.current = false;
          dispatch(
            setCurrentlySubmittingQuickAddVariationId({ variationId: null }),
          );
        }
      }
    };

    submitAndDispatch();
  }, [deviationProducts]);

  const variation = product.variations.find((variation) =>
    isEqualStrings(variation.variationId, variationId),
  );
  const price =
    variation && getPriceOfProductByVariation({ variation, week, year });

  const discountedAmount =
    variation &&
    getDiscountedAmount({
      variation,
      week,
      year,
    });

  const { data: calendarData } = useCalendarQuery({ week, year, range: 1 });

  useEffectOnce(
    () => {
      track("quickAddStandaloneProductShown", {
        product_name: product.name,
        variation_name: variation?.name,
        week,
        year,
        price,
        discounted_amount: discountedAmount,
      });
    },
    variation !== undefined &&
      price !== undefined &&
      discountedAmount !== undefined,
  );

  if (!calendarData) {
    return null;
  }

  const basketData = getBasketDataFromCalendar({
    calendar: calendarData.calendar,
    week,
    year,
  });

  if (!basketData || !variation || !price || discountedAmount === undefined) {
    return null;
  }

  const stockData = getStockData({
    addedQuantity: debouncedQuantity,
    variation,
  });

  const handleRemoveAllProducts = () => {
    setQuantity(0);
  };

  const handleShowProductModal = () => {
    dispatch(
      setStandaloneProductModal({
        open: true,
        productByCategoryVariation: variation,
      }),
    );
  };

  const formattedDeliveryDate = formatDate(
    new Date(
      basketData.shippingDetails.plannedDeliveryInformation.deliveryDate,
    ),
    "EEE d. MMM",
    { lowerCase: true },
  );

  const isUpdatingCurrentCard =
    currentlySubmittingQuickAddVariationId === variationId;

  return (
    <Card
      className={clsx(
        "flex flex-col",
        className,
        currentlySubmittingQuickAddVariationId !== null &&
          !isUpdatingCurrentCard &&
          "opacity-50 pointer-events-none hover:cursor-wait",
      )}
      noPadding
    >
      <div className="flex">
        <Image
          src={getImageFromImages(variation)}
          alt={product.description}
          width={99}
          height={80}
          onClick={handleShowProductModal}
          className="rounded-l"
        />

        <div className="flex flex-col self-stretch justify-between p-2 grow">
          <p>{variation.name}</p>

          <div className="flex flex-row items-end justify-between">
            <CurrencyWithDiscount
              language={language}
              total={price}
              discount={discountedAmount}
              emphasized
            />

            {quantity === 0 && (
              <Button
                primary
                outlined
                small
                onClick={() => setQuantity(1)}
                disabled={isSubmittingDeviation}
              >
                {intl.ADD}
              </Button>
            )}

            {quantity > 0 && (
              <div className="flex items-center gap-2">
                <ButtonCircle
                  small
                  onClick={() => setQuantity((q) => q - 1)}
                  Icon={Minus}
                  disabled={isSubmittingDeviation}
                />
                <p>{quantity}</p>

                <ButtonCircle
                  disabled={
                    quantity >= stockData.maxPurchasableAmount ||
                    isSubmittingDeviation
                  }
                  small
                  primary
                  onClick={() => setQuantity((q) => q + 1)}
                  Icon={Plus}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {(isSubmittingDeviation || debouncedQuantity > 0) && (
        <div className="flex items-center self-stretch px-2 py-3 text-xs bg-informationBG">
          {isUpdatingCurrentCard ? (
            <>
              <Loading className="animate-spin text-information" />

              <p className="ml-2">{intl.UPDATING_YOUR_ORDER}…</p>
            </>
          ) : (
            <>
              <Check className="text-information" />

              <p className="ml-2">
                {intl.ADDED_TO_YOUR_ORDER} {formattedDeliveryDate}
              </p>

              <button
                className="ml-1 underline"
                onClick={handleRemoveAllProducts}
              >
                <strong>{intl.REMOVE}</strong>
              </button>
            </>
          )}
        </div>
      )}
    </Card>
  );
};

export const useSubmitDeviationChange = ({
  week,
  year,
}: {
  week: number;
  year: number;
}) => {
  const track = useTrack();

  const { calendar, pickAndMix, productsByCategories } = useCalendarData({
    week,
    year,
  });

  const { data: billingData } = useBillingQuery();

  const [validateBasketMutation, { isLoading: isLoadingValidateBasket }] =
    useValidateBasketMutation();
  const [
    createOrUpdateDeviationMutation,
    { isLoading: isLoadingCreateOrUpdateDeviation },
  ] = useCreateOrUpdateDeviationMutation();
  const { data: loyaltyData } = useLoyalty();

  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));
  const timeblock =
    calendar &&
    getWeeklyTimeblockDataFromCalendar({
      calendar,
      week,
      year,
    });

  const finances =
    selectedProducts &&
    getBasketFinances({
      basketProducts: selectedProducts,
      customerFee: timeblock?.customerFee,
      loyaltyLevel: loyaltyData?.currentLevel,
    });

  const submitDeviation = async (selectedProducts: IDeviationProduct[]) => {
    if (!selectedProducts || !pickAndMix) {
      throw new Error("Data not initialized");
    }

    await validateBasketMutation(
      selectedProducts.map((bp) => ({
        quantity: bp.quantity,
        variationId: bp.variationId,
      })),
    ).unwrap();

    await createOrUpdateDeviationMutation({
      Week: week,
      Year: year,
      active: true,
      products: selectedProducts.map((bp) => ({
        basketProductId: bp.productId,
        subscribedQuantity: bp.quantity,
        subscribedVariationId: bp.variationId,
      })),
    }).unwrap();

    track("deviationCreated", {
      affiliation: "Frontend process",
      source: "quick_add",
      is_active: true,
      products: withErrorBoundary(() =>
        createPayloadProductsArray(
          selectedProducts,
          pickAndMix,
          productsByCategories,
        ),
      )!,
      quantity: sum(selectedProducts.map((bp) => bp.quantity)),
      total: finances?.total,
      status: billingData?.billing.status,
      week,
      year,
    });
  };

  return {
    submitDeviation,
    isSubmittingDeviation:
      isLoadingCreateOrUpdateDeviation || isLoadingValidateBasket,
  };
};
