import * as React from "react";
import { SVGProps } from "react";
const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.001 2L14.5005 10L19 18H10.001H1L5.49952 10L10.001 2Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9V12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15C10.4142 15 10.75 14.6642 10.75 14.25C10.75 13.8358 10.4142 13.5 10 13.5C9.58579 13.5 9.25 13.8358 9.25 14.25C9.25 14.6642 9.58579 15 10 15Z"
      fill="currentColor"
    />
  </svg>
);
export default Warning;
