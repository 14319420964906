import { BRAND_NAME, CONSENT_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { BillingQuery } from "../graphql/generated";

export const isAccountAcceptedProfiling = (
  consents?: BillingQuery["billing"]["consents"],
) =>
  consents?.find(
    (c) =>
      isEqualStrings(c.consentId, CONSENT_IDS.PROFILING[BRAND_NAME]) &&
      c.isAccepted,
  )?.isAccepted;

export const isAccountAcceptedDataProcessing = (
  consents?: BillingQuery["billing"]["consents"],
) =>
  !!consents?.find(
    (c) =>
      isEqualStrings(c.consentId, CONSENT_IDS.DATA_PROCESSING[BRAND_NAME]) &&
      c.isAccepted,
  )?.isAccepted;

export const isAccountAcceptedAllergenStoring = (
  consents?: BillingQuery["billing"]["consents"],
) =>
  !!consents?.find(
    (c) =>
      isEqualStrings(c.consentId, CONSENT_IDS.ALLERGEN_STORING[BRAND_NAME]) &&
      c.isAccepted,
  )?.isAccepted;
