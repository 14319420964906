import { Language } from "../../types";
import { Card, Container } from "../components";

interface RelatedArticlesProps {
  articles: Article[];
  language?: Language;
}
type Article = {
  image: JSX.Element;
  title: string;
  slug: string;
};
const intl = {
  no: {
    RELATED_ARTICLES: "Relaterte artikler",
  },
  se: {
    RELATED_ARTICLES: "Relaterade artiklar",
  },
  dk: {
    RELATED_ARTICLES: "Relaterede artikler",
  },
};

const RelatedArticles = ({
  articles,
  language = "no",
}: RelatedArticlesProps) => {
  const { RELATED_ARTICLES } = intl[language];
  return (
    articles?.length === 3 && (
      <Container outerClassName="bg-grey-3 py-10 md:py-20">
        <h2 className="pb-4">{RELATED_ARTICLES}</h2>
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-6 md:space-y-0">
          {articles?.map((article: Article) => {
            return (
              <Card
                className="rounded-sm basis-1/3"
                noPadding
                key={article.slug}
                title={article.title}
              >
                <a href={article.slug}>
                  {article.image}
                  <div className="flex-1 p-4 text-lg md:text-xl md:p-6">
                    <strong className="line-clamp-2">{article.title}</strong>
                  </div>
                </a>
              </Card>
            );
          })}
        </div>
      </Container>
    )
  );
};

export default RelatedArticles;
