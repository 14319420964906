import clsx from "clsx";

import { ChevronDown } from "@chef/icons/small";

interface ChevronProps {
  className?: string;
}

export const Chevron = ({ className }: ChevronProps) => (
  <ChevronDown className={clsx("w-7.5 h-7.5 p-2.5", className)} />
);
