import * as React from "react";
import { SVGProps } from "react";
const InfoOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={11} cy={11} r={10.5} stroke="#15193A" />
    <path
      d="M9.80512 6.61397C9.80512 6.27192 9.92431 5.9817 10.1627 5.7433C10.4063 5.49972 10.6991 5.37793 11.0412 5.37793C11.378 5.37793 11.6657 5.49972 11.9041 5.7433C12.1476 5.9817 12.2694 6.27192 12.2694 6.61397C12.2694 6.95602 12.1476 7.24884 11.9041 7.49242C11.6657 7.73081 11.378 7.85001 11.0412 7.85001C10.6991 7.85001 10.4063 7.73081 10.1627 7.49242C9.92431 7.24884 9.80512 6.95602 9.80512 6.61397ZM9.10547 15.997C9.38533 15.9504 9.57708 15.8804 9.68073 15.7871C9.83621 15.6472 9.91395 15.3958 9.91395 15.0331V10.3532C9.91395 10.0319 9.85953 9.81421 9.7507 9.70019C9.64186 9.58099 9.42679 9.49289 9.10547 9.43588V9.04719H12.1606V15.0952C12.1606 15.4425 12.2124 15.6653 12.3161 15.7638C12.4197 15.8623 12.6141 15.94 12.8991 15.997V16.3779H9.10547V15.997Z"
      fill="#15193A"
    />
  </svg>
);
export default InfoOutlined;
