import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      POSTAL_CODE_LABEL: "Postnummer",
      POSTAL_AREA_LABEL: "Poststed",
      ADDRESS_LABEL: "Adresse",
      MANDATORY_FIELD: "Obligatorisk felt",
      SELECT_FROM_LIST: "Velg fra listen",
      UNKNOWN: "Ukjent",
    },

    se: {
      POSTAL_CODE_LABEL: "Postnummer",
      POSTAL_AREA_LABEL: "Stad",
      ADDRESS_LABEL: "Adress",
      MANDATORY_FIELD: "Obligatoriskt fält",
      SELECT_FROM_LIST: "Välj från listan",
      UNKNOWN: "Okänd",
    },

    dk: {
      POSTAL_CODE_LABEL: "Postnummer",
      POSTAL_AREA_LABEL: "By",
      ADDRESS_LABEL: "Adresse",
      MANDATORY_FIELD: "Obligatorisk felt",
      SELECT_FROM_LIST: "Vælg fra listen",
      UNKNOWN: "Ukend",
    },
  } as const
)[language];
