import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      NO_PREFERENCES_SELECTED: "Du har ikke valgt noen preferanser.",
      CHOOSE_WHAT_YOU_LIKE_AND_DISLIKE:
        "Fortell oss hva du liker og hva du vil unngå, så lager vi personlige menyforslag til deg.",
    },
    se: {
      NO_PREFERENCES_SELECTED: "Du har inte angett några preferenser.",
      CHOOSE_WHAT_YOU_LIKE_AND_DISLIKE:
        "Berätta vad du gillar och vad du vill undvika, så skapar vi personliga menuförslag åt dig.",
    },
    dk: {
      NO_PREFERENCES_SELECTED: "Du har ikke angivet nogen præferencer.",
      CHOOSE_WHAT_YOU_LIKE_AND_DISLIKE:
        "Fortæl os, hvad du synes godt om og hvad du ikke kan lide, så vi kan give dig personlige menuforslag.",
    },
  } as const
)[language];
