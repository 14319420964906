import React from "react";

const FutureDeliveryCardBase = ({ children, ...props }) => {
  return (
    <section className="relative p-4 rounded-sm bg-grey-3 md:p-6" {...props}>
      <div className="flex flex-col space-y-2 md:space-x-6 md:flex md:flex-row md:space-y-0">
        {children}
      </div>
    </section>
  );
};

export default FutureDeliveryCardBase;
