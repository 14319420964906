import React, { SVGProps } from "react";
import clsx from "clsx";

interface TagProps {
  small?: boolean;
  secondary?: boolean;
  highlight?: boolean;
  white?: boolean;
  error?: boolean;
  casing?: "lowercase" | "uppercase" | "capitalize" | "normal-case";
  Icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement | null;
  className?: string;
  children?: React.ReactNode;
  id?: string;
}

interface TypeSize {
  default: string;
  small: string;
}

interface TypeStyles {
  default: string;
  secondary: string;
  highlight: string;
  white: string;
  error: string;
}

export const Tag = ({
  small = false,
  secondary = false,
  highlight = false,
  white = false,
  error = false,
  casing = "lowercase",
  Icon,
  className,
  children,
  id,
  ...props
}: TagProps) => {
  const baseStyle =
    "inline-flex whitespace-nowrap text-xs items-center justify-center leading-normal th-lmk:font-semibold rounded-sm";

  const sizeStyles: TypeSize = {
    default: "h-6 px-1.5",
    small: "h-5 px-1",
  };

  const typeStyles: TypeStyles = {
    default: "bg-grey-2",
    secondary: "bg-secondary text-white",
    highlight: "bg-highlight",
    white: "bg-white",
    error: "bg-error text-white",
  };

  let casingStyle;
  switch (casing) {
    case "uppercase":
      casingStyle = "uppercase";
      break;
    case "capitalize":
      casingStyle = "capitalize";
      break;
    case "normal-case":
      break;
    case "lowercase":
    default:
      casingStyle = "lowercase";
  }

  let size: keyof typeof sizeStyles = "default";
  if (small) {
    size = "small";
  }

  let type: keyof typeof typeStyles = "default";
  if (secondary) {
    type = "secondary";
  }

  if (highlight) {
    type = "highlight";
  }

  if (white) {
    type = "white";
  }

  if (error) {
    type = "error";
  }

  if (!children) {
    return null;
  }

  return (
    <div
      className={clsx(
        baseStyle,
        sizeStyles[size as keyof TypeSize],
        typeStyles[type as keyof TypeStyles],
        casingStyle,
        className,
      )}
      id={id}
      {...props}
    >
      {Icon && <Icon className="mr-1 shrink-0" />}
      {children}
    </div>
  );
};
