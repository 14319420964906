import Link from "next/link";
import { useSelector } from "react-redux";

import { Banner, Button } from "@chef/components";
import { selectSignupCoupon, useMeQuery } from "@chef/state-management";
import { ChevronRight, Gift, Parcel } from "@chef/icons/small";
import { BRAND_PATHS } from "@chef/constants";
import { useDiscount } from "@chef/state-management/hooks";
import { convertToClientSideOnlyComponent } from "@chef/helpers";
import { useTrack } from "@chef/feature-tracking";

import { intl } from "./DiscountBanner.Intl";

type CTA = "discount-scroll" | "text" | "signup-link";

interface DiscountBannerComponentProps {
  cta?: CTA;
}

interface HoverContentProps {
  discounts: {
    id?: string;
    type: "percent" | "fixed";
    amount: number;
    uses: number;
    order: number;
  }[];
  cta: CTA;
  description?: string;
  hasGiftProduct?: boolean;
}

const HoverContent = ({
  cta,
  discounts,
  description,
  hasGiftProduct,
}: HoverContentProps) => {
  const track = useTrack();
  const handleScrollToDiscount = () => {
    const discount = document.getElementById("signup-discount-code-input");

    discount?.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => discount?.focus(), 750);
  };

  return (
    <div className="flex flex-col p-4 overflow-x-hidden gap-y-3 max-w-lg min-w-[20rem] text-center">
      <div className="flex justify-center overflow-x-hidden">
        <div className="flex items-center gap-3 overflow-x-auto whitespace-nowrap">
          {discounts
            .sort((a, b) => a.order - b.order)
            .map(({ amount, type, uses }, i) => (
              <div key={i} className="flex items-center">
                <div className="space-y-1 grow shrink-0 basis-18">
                  <div>
                    <strong className="text-sm md:text-base">
                      {amount}
                      {type === "percent" ? "%" : " kr"} {intl.DISCOUNT}
                    </strong>
                  </div>
                  <div>
                    <Parcel className="w-10 h-10" />
                  </div>
                  <div>
                    <strong className="text-xs md:text-base whitespace-nowrap">
                      {uses}× {uses > 1 ? intl.UNITS : intl.UNIT}
                    </strong>
                  </div>
                </div>
                {i < discounts.length - 1 && (
                  <ChevronRight className="hidden w-6 h-6 text-grey-1 md:block" />
                )}
              </div>
            ))}

          {hasGiftProduct && (
            <div className="flex items-center ml-2 md:ml-4">
              <div className="space-y-1 md:space-y-0">
                <div className="text-sm md:text-base">
                  <strong>{intl.GIFT}</strong>
                </div>

                <Gift className="w-12 h-12" />

                <div className="text-xs md:text-base">
                  <strong>{intl.ON_PURCHASE}</strong>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <p className="text-xs">{description}</p>

      {cta === "text" && (
        <p className="text-sm text-grey-1">
          {intl.YOU_CAN_ADD_A_DIFFERENT_DISCOUNT_AT_CHECKOUT}
        </p>
      )}

      {cta === "signup-link" && (
        <Link
          onClick={() =>
            track("linkClicked", {
              affiliation: "Frontend process",
              link_text: intl.TAKE_ADVANTAGE_OF_THE_OFFER,
              link_placement: "discountBanner",
              link_source: "code",
              link_action: "click",
              link_destination: BRAND_PATHS.SIGNUP_HREF,
            })
          }
          href={BRAND_PATHS.SIGNUP_HREF}
          passHref
          legacyBehavior
        >
          <Button primary>{intl.TAKE_ADVANTAGE_OF_THE_OFFER}</Button>
        </Link>
      )}

      {cta === "discount-scroll" && (
        <button
          onClick={handleScrollToDiscount}
          className="text-xs underline text-primary"
        >
          {intl.ADD_ANOTHER_DISCOUNT}
        </button>
      )}
    </div>
  );
};

const DiscountBannerComponent = ({
  cta = "signup-link",
}: DiscountBannerComponentProps) => {
  const { data: isLoggedIn } = useMeQuery();
  const { isUninitialized } = useDiscount();
  const signupCoupon = useSelector(selectSignupCoupon);

  const giftProduct =
    signupCoupon?.giftProduct || signupCoupon?.giftProducts?.[0];

  const hasGiftProduct = !!giftProduct && giftProduct.quantity > 0;

  // Discount banner is not shown if user is logged in
  if (isLoggedIn) {
    return null;
  }

  if (isUninitialized) {
    return (
      <div className="z-30 flex items-center justify-center w-full h-8 text-sm whitespace-pre shadow-inner-sm bg-highlight">
        <span>
          {intl.NEW_CUSTOMER_DISCOUNT_ACTIVE}
          <strong className="ml-2 underline">{intl.SHOW_DETAILS}</strong>
        </span>
      </div>
    );
  }

  const couponCodeExists = signupCoupon?.couponCode && signupCoupon?.amount > 0;

  if (!couponCodeExists) {
    return null;
  }

  return (
    <Banner
      tooltip={{
        element: (
          <HoverContent
            discounts={[
              signupCoupon,
              ...(signupCoupon?.chainedDiscounts || []),
            ].map((d) => ({
              id: d.discountId,
              type: d.type === 1 || d.amountType === 1 ? "percent" : "fixed",
              amount: d.amount,
              uses: d.initialUsage || d.usesLeft || 1,
              order: d.order || 0,
            }))}
            description={signupCoupon.description}
            cta={cta}
            hasGiftProduct={hasGiftProduct}
          />
        ),
        popperOptions: {
          delayHide: 100,
          interactive: true,
          trigger: "click",
        },
      }}
      className="sticky top-0 z-30"
      highlighted
    >
      <span>
        {intl.NEW_CUSTOMER_DISCOUNT_ACTIVE}
        <strong className="ml-2 underline">{intl.SHOW_DETAILS}</strong>
      </span>
    </Banner>
  );
};

export const DiscountBanner = convertToClientSideOnlyComponent(
  DiscountBannerComponent,
);
