import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      LOG_OUT: "Logg ut",
      LOG_IN: "Logg inn",
      CREATE_ACCOUNT: "Bestill nå",
      OPEN_MENU_LABEL: "Åpne meny",
    },
    se: {
      LOG_OUT: "Logga ut",
      LOG_IN: "Logga in",
      CREATE_ACCOUNT: "Beställ nu",
      OPEN_MENU_LABEL: "Öppna meny",
    },
    dk: {
      LOG_OUT: "Log ud",
      LOG_IN: "Log ind",
      CREATE_ACCOUNT: "Bestil nu",
      OPEN_MENU_LABEL: "Åbn menu",
    },
  } as const
)[language];
