import { useCallback, useContext } from "react";
import { DiscountsContext } from "../components/discount/Provider";

export const useDiscount = () => {
  const context = useContext(DiscountsContext);

  if (!context) {
    throw new Error("useDiscount must be used within a DiscountProvider");
  }

  const setRk = useCallback(
    ({ rk, userInitiated }: { rk: string; userInitiated?: boolean }) => {
      context.setOverride({ rk, userInitiated });
    },
    [],
  );

  const setSid = useCallback((sid: string) => {
    context.setOverride({ sid });
  }, []);

  return {
    setRk,
    setSid,
    isLoading: context.isLoading,
    isError: context.isError,
    isUninitialized: context.isUninitialized,
  };
};
