import clsx from "clsx";
import { useEffect } from "react";
import { Config, usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

interface TooltipProps {
  element: React.ReactNode;
  popperOptions?: Config;
  className?: string;
  wrapperClassName?: string;
  variant?: "default" | "light";
  children:
    | React.ReactNode
    | (({ visible }: { visible: boolean }) => React.ReactNode);
}

export const Tooltip = ({
  variant = "default",
  element,
  popperOptions = {},
  children,
  className,
  wrapperClassName,
}: TooltipProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    visible,
  } = usePopperTooltip(popperOptions);

  useEffect(() => {
    if (variant === "default") {
      tooltipRef?.style.setProperty(
        "--tooltipBackground",
        "rgba(var(--black))",
      );

      tooltipRef?.style.setProperty("--tooltipColor", "rgba(var(--white))");
      tooltipRef?.style.setProperty("color", "rgba(var(--white))");
    }
  }, [variant, tooltipRef]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={wrapperClassName}
    >
      <button type="button" ref={setTriggerRef} className="w-full">
        {typeof children === "function" ? children({ visible }) : children}
      </button>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: clsx(
              "!rounded !shadow !border-1.5 !border-grey-2 max-w-full tooltip-container",
              className,
            ),
          })}
        >
          <div
            {...getArrowProps({
              className: "tooltip-arrow",
            })}
          />
          {element}
        </div>
      )}
    </div>
  );
};
