import Link from "next/link";
import { useDispatch } from "react-redux";

import { Container, Disclosure } from "@chef/components";
import { BRAND_NAME, language, VISIBILITY } from "@chef/constants";
import nav from "@chef/constants/nav";
import {
  toggleDialog as toggleCookiesDialog,
  useMeQuery,
} from "@chef/state-management";

import { Logo } from "../Logo";

import { PaymentBadges } from "./PaymentBadges";
import { AppStoreBanner } from "./AppStoreBanner";
import { TrustpilotWidget } from "./TrustpilotWidget";
import { Contact } from "./Contact";
import { SoMe } from "./SoMe";
import { CheffeloBanner } from "./CheffeloBanner";
import { Fodevarekontrol } from "./Fodevarekontrol";

interface FooterProps {
  minified?: boolean;
  hideAppStoreBanner?: boolean;
}

const intl = {
  no: {
    COOKIE_SETTINGS: "Cookieinnstillinger",
  },
  se: {
    COOKIE_SETTINGS: "Cookie-inställningar",
  },
  dk: {
    COOKIE_SETTINGS: "Cookie-indstillinger",
  },
}[language];

const { FOOTER, MINIFIED_FOOTER } = nav.data;

const FOOTER_ITEMS_BY_CATEGORY = FOOTER.reduce(
  (acc, cur) => {
    const idx = acc.findIndex((item) => item.category === cur.category);

    if (idx !== -1) {
      acc[idx].items.push(cur);
    } else {
      acc.push({ category: cur.category, items: [cur] });
    }

    return acc;
  },
  [] as { category: string; items: typeof FOOTER }[],
);

const isExternalLink = (href: string) => href.startsWith("http");

export const Footer = ({
  minified = false,
  hideAppStoreBanner = false,
}: FooterProps) => {
  const dispatch = useDispatch();
  const { data: isLoggedIn } = useMeQuery();

  const handleToggleCookieSettings = () => {
    dispatch(toggleCookiesDialog());
  };

  if (minified) {
    return (
      <footer className="print:hidden">
        <Container
          innerClassName="flex md:justify-between md:items-center flex-col md:flex-row"
          outerClassName="py-6 md:py-10 px-4 md:px-15 bg-grey-3"
        >
          <Logo />

          <Contact hidden />

          <nav className="mt-6 md:mt-0">
            <ul className="flex flex-col gap-1 text-xs md:gap-4 md:flex-row">
              {MINIFIED_FOOTER.filter(
                ({ visibility }) => visibility & VISIBILITY.ALL,
              ).map(({ label, href }, i) => {
                const isExternal = isExternalLink(href);

                if (isExternal) {
                  return (
                    <li key={href + i}>
                      <a href={href} target="_blank" rel="noopener noreferrer">
                        {label}
                      </a>
                    </li>
                  );
                }

                return (
                  <li key={href + i}>
                    <Link href={href} legacyBehavior>
                      {label}
                    </Link>
                  </li>
                );
              })}

              <li key="cookie-settings">
                <button onClick={handleToggleCookieSettings}>
                  {intl.COOKIE_SETTINGS}
                </button>
              </li>
              {BRAND_NAME === "RN" && (
                <li key="fodevarekontrol">
                  <Fodevarekontrol />
                </li>
              )}
            </ul>
          </nav>
        </Container>

        {!hideAppStoreBanner && <AppStoreBanner />}
      </footer>
    );
  }

  return (
    <footer className="print:hidden">
      <Container
        outerClassName="bg-grey-3 pt-6 md:pt-15 pb-6 md:pb-10 px-4 md:px-15"
        grid
      >
        <div className="md:row-start-1 xl:col-span-4 col-span-full">
          <Logo />
        </div>

        <div className="col-span-4 row-start-4 space-y-4 md:row-start-3 xl:row-start-2 md:col-span-2">
          <Contact />
          <SoMe />
        </div>

        <div className="grid grid-cols-3 md:row-start-2 md:gap-6 col-span-full xl:col-span-8 xl:col-start-5">
          {FOOTER_ITEMS_BY_CATEGORY.map(({ category, items }) => {
            return (
              <div className="col-span-full md:col-span-1" key={category}>
                <nav className="hidden md:block">
                  <div>
                    <strong>{category}</strong>
                  </div>
                  <ul className="flex flex-col">
                    {items
                      .filter(
                        ({ visibility }) =>
                          visibility &
                          (isLoggedIn ? VISIBILITY.PRIVATE : VISIBILITY.PUBLIC),
                      )
                      .map((item) => {
                        const isExternal = isExternalLink(item.href);

                        if (isExternal) {
                          return (
                            <li key={item.label}>
                              <a
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.label}
                              </a>
                            </li>
                          );
                        } else {
                          return (
                            <li key={item.label}>
                              <Link href={item.href} legacyBehavior>
                                {item.label}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </nav>
                <nav className="md:hidden">
                  <Disclosure
                    key={category}
                    Title={() => (
                      <strong className="text-base">{category}</strong>
                    )}
                  >
                    <ul className="flex flex-col mb-4 -mt-2">
                      {items
                        .filter(
                          ({ visibility }) =>
                            visibility &
                            (isLoggedIn
                              ? VISIBILITY.PRIVATE
                              : VISIBILITY.PUBLIC),
                        )
                        .map((item) => {
                          const isExternal = isExternalLink(item.href);

                          if (isExternal) {
                            return (
                              <li key={item.label}>
                                <a
                                  href={item.href}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.label}
                                </a>
                              </li>
                            );
                          } else {
                            return (
                              <li key={item.label}>
                                <Link href={item.href} legacyBehavior>
                                  {item.label}
                                </Link>
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </Disclosure>
                </nav>
              </div>
            );
          })}
        </div>

        <nav className="mt-6 md:row-start-4 col-span-full md:col-span-6 xl:col-span-10 mb:mt-0">
          <div className="mb-6">
            <PaymentBadges />
          </div>
          <ul className="flex flex-col gap-1 text-xs md:gap-4 md:flex-row">
            {MINIFIED_FOOTER.filter(
              ({ visibility }) => visibility & VISIBILITY.ALL,
            ).map(({ label, href }, i) => {
              const isExternal = isExternalLink(href);

              if (isExternal) {
                return (
                  <li key={href + i}>
                    <a href={href} target="_blank" rel="noopener noreferrer">
                      {label}
                    </a>
                  </li>
                );
              }

              return (
                <li key={href + i}>
                  <Link href={href} legacyBehavior>
                    {label}
                  </Link>
                </li>
              );
            })}

            <li key="cookie-settings">
              <button onClick={handleToggleCookieSettings}>
                {intl.COOKIE_SETTINGS}
              </button>
            </li>
          </ul>
        </nav>

        <div className="justify-end hidden col-start-7 md:row-start-4 xl:col-start-11 md:flex md:col-span-2">
          <TrustpilotWidget />
        </div>
      </Container>

      <AppStoreBanner />

      <CheffeloBanner />
    </footer>
  );
};
