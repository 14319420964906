import { motion } from "framer-motion";

interface AnimatedCrossProps extends React.SVGProps<SVGSVGElement> {
  isCrossed: boolean;
}

export const AnimatedCross = ({ isCrossed, ...props }: AnimatedCrossProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-1 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <motion.path
        d="M11 1L1 11"
        stroke="currentColor"
        strokeWidth={2}
        initial={{ pathLength: 0 }}
        animate={{ pathLength: isCrossed ? 1 : 0 }}
        transition={{ duration: 0.25 }}
      />
      <motion.path
        d="M1 1L11 11"
        stroke="currentColor"
        strokeWidth={2}
        initial={{ pathLength: 0 }}
        animate={{ pathLength: isCrossed ? 1 : 0, rotate: 180 }}
        transition={{ duration: 0.25, delay: 0.25 }}
      />
    </svg>
  );
};
