import * as React from "react";
import { SVGProps } from "react";
const ForkLove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 82 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_fork_love)">
      <path
        d="M19.5164 45.4396L42.9967 68.8933L71.9517 40.6571C72.6866 40.0138 73.3787 39.3234 74.0235 38.5903L74.3238 38.2408C77.0285 35.023 78.7567 31.1021 79.3053 26.9386C79.8539 22.775 79.2002 18.5418 77.4209 14.736C75.6417 10.9302 72.8107 7.70992 69.2606 5.45339C65.7105 3.19686 61.5887 1.99777 57.3792 1.99695C50.6834 1.99695 42.9167 8.63667 42.9167 8.63667C39.1634 5.70121 34.4393 1.99695 29.3049 1.99695C25.5294 1.98411 21.814 2.93995 18.5156 4.77265"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.90808 14.4777V69.0032"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.50171 14.4777V28.1565C2.51732 28.9091 2.68142 29.6514 2.98465 30.3408C3.28787 31.0301 3.72428 31.6532 4.26894 32.1743C4.81359 32.6954 5.45582 33.1043 6.15894 33.3777C6.86206 33.6511 7.6123 33.7837 8.36678 33.7678H11.7797C13.3044 33.7999 14.7796 33.227 15.8812 32.1749C16.9827 31.1228 17.6207 29.6775 17.6548 28.1565V14.4777"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.8856 33.2586C66.6349 30.8659 67.4141 27.9026 67.0675 24.9614C66.1967 17.8624 61.6228 12.8701 53.7159 13.7588"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_fork_love">
        <rect width={81} height={71} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default ForkLove;
