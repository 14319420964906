import {
  OrderQuery,
  OrdersQuery,
  RecipesByProductIdListQueryVariables,
} from "../../graphql/generated";
import { isMealboxProduct, isPickAndMixProduct } from "../../helpers/product";

export const convertFromOrderToRecipeByProductIdListQuery = (
  order: OrderQuery["order"] | OrdersQuery["orders"][0],
): RecipesByProductIdListQueryVariables => {
  const productIds =
    order.orderLines
      .filter(
        (ol) =>
          isMealboxProduct(ol.variation.product) ||
          isPickAndMixProduct(ol.variation.product),
      )
      .map((ol) => ol.variation.product.productId) || [];

  return {
    productIds,
    week: order.week,
    year: order.year,
    range: 1,
  };
};
