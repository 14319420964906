import React, { forwardRef } from "react";
import clsx from "clsx";
import { AnimatedCross } from "../AnimatedCross";
import { AnimatedCheck } from "../AnimatedCheck";

interface BaseProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  innerClassName?: string;
  id?: string;
  name?: string;
  type?: "checkbox" | "radio";
  value?: string;
  valid?: boolean;
}

type CheckboxProps = BaseProps &
  (
    | {
        variant?: "default";
        state?: never;
        size?: "sm" | "md";
      }
    | {
        variant: "selector";
        state: "checked" | "crossed";
        size?: never;
      }
  );

const DefaultCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      size = "md",
      className,
      innerClassName,
      disabled,
      checked,
      children,
      id,
      value,
      type = "checkbox",
      valid: __,
      ...props
    },
    ref,
  ) => {
    const svg = (
      <AnimatedCheck
        isChecked={!!checked}
        className={clsx(size === "md" && "ml-px")}
      />
    );

    const isActive = checked || value === id;

    return (
      <label
        htmlFor={id}
        className={clsx(
          "flex items-center",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
      >
        <div
          className={clsx(
            "flex shrink-0 items-center leading-normal text-center mr-3 rounded-sm",
            "a11y-focus-within:focus-ring focus-within:border-blue-500 justify-center",
            isActive && !disabled && "border-black",
            !isActive && !disabled && "border-grey-1",
            disabled && "border-grey-1",
            {
              "w-4 h-4 border-1.5": size === "sm",
              "w-6 h-6 border-2 ": size === "md",
            },
            className,
          )}
        >
          <input
            id={id}
            ref={ref}
            type={type}
            className="sr-only"
            checked={checked}
            value={value}
            disabled={disabled}
            {...props}
          />
          {svg}
        </div>
        <span className={clsx(innerClassName, disabled && "text-grey-1")}>
          {children}
        </span>
      </label>
    );
  },
);

const SelectorCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      disabled,
      checked,
      state,
      children,
      size: _,
      id,
      type,
      value,
      valid: __,
      ...props
    },
    ref,
  ) => {
    let styleSelector = "border-grey-2 bg-white";

    if (state === "checked" && checked) {
      styleSelector = "border-secondary bg-informationBG";
    }

    if (state === "crossed" && checked) {
      styleSelector = "border-error bg-errorBG";
    }

    const defaultIconStyle =
      "absolute top-0 right-0 w-4 h-4 p-0.5 text-white rounded-bl rounded-tr-sm";

    return (
      <label
        htmlFor={id}
        className={clsx(
          "flex w-full items-center justify-center h-14 px-4 py-2 text-sm border rounded shadow-sm relative",
          disabled ? "cursor-not-allowed text-grey-1" : "cursor-pointer",
          styleSelector,
          className,
        )}
        data-testid={id}
      >
        <input
          id={id}
          type={type}
          ref={ref}
          className="sr-only"
          checked={checked}
          value={value}
          disabled={disabled}
          {...props}
        />

        <span className="w-full text-center">{children}</span>

        {state === "checked" && (
          <AnimatedCheck
            className={clsx(defaultIconStyle, !!checked && "bg-secondary")}
            isChecked={!!checked}
          />
        )}

        {state === "crossed" && (
          <AnimatedCross
            className={clsx(defaultIconStyle, !!checked && "bg-error")}
            isCrossed={!!checked}
          />
        )}
      </label>
    );
  },
);

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    if (props.variant === "selector") {
      return <SelectorCheckbox {...props} ref={ref} />;
    }
    return <DefaultCheckbox {...props} ref={ref} />;
  },
);
