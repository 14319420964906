import Link from "next/link";

import { useMeQuery } from "@chef/state-management";
import { BRAND_PATHS, language } from "@chef/constants";
import { Button } from "@chef/components";
import { useEffectOnce } from "@chef/hooks";
import { useTrack } from "@chef/feature-tracking";

import { AppShell } from "../AppShell";

export const NotFoundPage = () => {
  const track = useTrack();
  const { data: isLoggedIn } = useMeQuery();

  useEffectOnce(() => {
    track("pageNotFound", { affiliation: "Frontend process" });
  }, true);

  return (
    <AppShell title={intl.CAN_NOT_FIND_PAGE}>
      <AppShell.Meta />

      <AppShell.Header />

      <AppShell.Main>
        <aside>
          <code>{intl.ERROR_CODE} 404</code>
        </aside>
        <h1 className="my-4 lg:my-6">
          <strong>{intl.CAN_NOT_FIND_PAGE}</strong>
        </h1>
        <div className="max-w-lg space-y-4 lg:space-y-6">
          <p>{intl.PAGE_NOT_FOUND_CHECK_IF_YOU_TYPED_ADDRESS_CORRECTLY}</p>
          <p>
            {intl.IF_YOU_THINK_THIS_IS_AN_ERROR_CONTACT_WITH}
            <Link
              href={BRAND_PATHS.CONTACT_US_PAGE_LOGOUT}
              className="underline"
            >
              {intl.CUSTOMER_SEVICE}
            </Link>
            .
          </p>
        </div>
        <div className="mt-8 space-x-2 lg:mt-12">
          <Button onClick={() => window.history.back()} outlined>
            {intl.BACK}
          </Button>
          <Link
            href={
              isLoggedIn
                ? BRAND_PATHS.MY_MENU_PAGE
                : BRAND_PATHS.MY_MENU_LOGOUT_PAGE
            }
            passHref
            legacyBehavior
          >
            <Button primary>{intl.SEE_THIS_WEEKS_MEALS}</Button>
          </Link>
        </div>
      </AppShell.Main>
      <AppShell.Footer />
    </AppShell>
  );
};

const intl = (
  {
    no: {
      ERROR_CODE: "Feilkode",
      CAN_NOT_FIND_PAGE: "Kan ikke finne siden",
      PAGE_NOT_FOUND_CHECK_IF_YOU_TYPED_ADDRESS_CORRECTLY:
        "Siden du prøvde å besøke kunne vi dessverre ikke finne. Sjekk at du skrev inn adressen riktig eller gå tilbake til forrige side.",
      IF_YOU_THINK_THIS_IS_AN_ERROR_CONTACT_WITH:
        "Hvis du tror dette kan være en feil, ta gjerne kontakt med ",
      CUSTOMER_SEVICE: "kundeservice",
      BACK: "Tilbake",
      SEE_THIS_WEEKS_MEALS: "Se ukens retter",
    },
    se: {
      ERROR_CODE: "Felkod",
      CAN_NOT_FIND_PAGE: "Sidan kan inte hittas",
      PAGE_NOT_FOUND_CHECK_IF_YOU_TYPED_ADDRESS_CORRECTLY:
        "Det verkar som att den sida du försöker besöka inte finns. Kontrollera att du stavat rätt eller gå tillbaka till föregående sida!",
      IF_YOU_THINK_THIS_IS_AN_ERROR_CONTACT_WITH:
        "Om du tror att det är ett fel, vänligen kontakta ",
      CUSTOMER_SEVICE: "kundtjänst",
      BACK: "Tillbaka",
      SEE_THIS_WEEKS_MEALS: "Se veckans rätter",
    },
    dk: {
      ERROR_CODE: "Fejlkode",
      CAN_NOT_FIND_PAGE: "Siden kunne ikke findes",
      PAGE_NOT_FOUND_CHECK_IF_YOU_TYPED_ADDRESS_CORRECTLY:
        "Siden du forsøgte at besøge kunne vi desværre ikke finde. Kontroller at du har stavet adressen korrekt eller gå tilbage til forrige side.",
      IF_YOU_THINK_THIS_IS_AN_ERROR_CONTACT_WITH:
        "Hvis du tror dette kan være en fejl, kontakt venligst ",
      CUSTOMER_SEVICE: "kundeservice",
      BACK: "Tilbage",
      SEE_THIS_WEEKS_MEALS: "Se ugens retter",
    },
  } as const
)[language];
