import { motion } from "framer-motion";

interface PlusMinusIconProps extends React.SVGProps<SVGSVGElement> {
  isPlus: boolean;
}

export const PlusMinusIcon = ({ isPlus, ...props }: PlusMinusIconProps) => {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <motion.line
        x1="12"
        y1="5"
        x2="12"
        y2="19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        animate={{
          rotate: isPlus ? 0 : 90,
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      />

      <motion.line
        x1="5"
        y1="12"
        x2="19"
        y2="12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      />
    </svg>
  );
};
