import { Card, Skeleton } from "../components";

const DeliveryCardLoader = () => (
  <Card>
    <div className="flex items-center w-full h-full">
      <div>
        <Skeleton
          width="8"
          height="8"
          className="!w-8 !h-8 "
          variant="circular"
        />
      </div>

      <div className="flex flex-col flex-grow gap-1 ml-4 justify-evenly">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>

    <div className="my-6">
      <hr className="border-grey-2" />
    </div>

    <div className="flex justify-between gap-4">
      <Skeleton width={32} className="grow" />
      <Skeleton width={32} className="grow" />
      <Skeleton width={32} className="grow" />
      <Skeleton width={32} className="grow" />
    </div>
  </Card>
);

export default DeliveryCardLoader;
