interface AvatarWithCheckMarkIconProps extends React.SVGProps<SVGSVGElement> {
  active: boolean;
  withCheckMark?: boolean;
  width?: number;
  height?: number;
}

export const AvatarWithCheckMark = ({
  active = false,
  withCheckMark = false,
  width = 45,
  height = 45,
  ...props
}: AvatarWithCheckMarkIconProps) => {
  const verified = active && withCheckMark;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 61 64"
      fill="none"
      {...props}
    >
      <path
        d="M10.6178 29.5992C12.7717 28.0164 15.6332 27.8084 18.0075 29.0375C19.3514 29.7327 20.8784 30.1268 22.4984 30.1268C24.1185 30.1268 25.6455 29.7327 26.9894 29.0375C29.3636 27.8115 32.2252 28.0164 34.3791 29.5992C36.6572 31.2721 38.4541 33.4229 39.5746 35.8654C42.6502 32.0821 44.5 27.256 44.5 21.9984C44.5 9.85088 34.6491 0 22.4984 0C10.3478 0 0.5 9.85088 0.5 21.9984C0.5 27.256 2.34665 32.0821 5.42544 35.8654C6.54585 33.4198 8.34284 31.2721 10.6209 29.5992H10.6178Z"
        fill={active || verified ? "#EAEFED" : "#FFF6E8"}
      />
      <path
        d="M22.498 44.0028C29.3911 44.0028 35.5394 40.8309 39.5741 35.8713C38.4537 33.4256 36.6567 31.2779 34.3786 29.6051C32.2247 28.0222 29.3632 27.8143 26.9889 29.0433C25.6451 29.7385 24.1181 30.1327 22.498 30.1327C20.8779 30.1327 19.3509 29.7385 18.007 29.0433C15.6328 27.8174 12.7712 28.0222 10.6173 29.6051C8.33928 31.2779 6.54228 33.4287 5.42188 35.8713C9.45658 40.8309 15.6048 44.0028 22.498 44.0028Z"
        fill={active || verified ? "#285C4D" : "#F4E5CE"}
      />
      <path
        d="M22.4956 26.9555C26.8596 26.9555 30.3974 22.4131 30.3974 16.8098C30.3974 11.2065 26.8596 6.66406 22.4956 6.66406C18.1315 6.66406 14.5938 11.2065 14.5938 16.8098C14.5938 22.4131 18.1315 26.9555 22.4956 26.9555Z"
        fill={active || verified ? "#285C4D" : "#F4E5CE"}
      />
      {verified && (
        <>
          <g filter="url(#filter0_dd_32154_321902)">
            <rect x={24.5} y={24} width={24} height={24} rx={12} fill="white" />
            <rect
              x={25}
              y={24.5}
              width={23}
              height={23}
              rx={11.5}
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip0_32154_321902)">
            <path
              d="M31.5 36.5L34.8333 40L41.5 33"
              stroke="#285C4D"
              strokeWidth={1.5}
              strokeLinecap="round"
            />
          </g>
        </>
      )}

      <defs>
        <filter
          id="filter0_dd_32154_321902"
          x={12.5}
          y={16}
          width={48}
          height={48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={6} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.207843 0 0 0 0 0.215686 0 0 0 0 0.227451 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_32154_321902"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_32154_321902"
            result="effect2_dropShadow_32154_321902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_32154_321902"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_32154_321902">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(30.5 30)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
