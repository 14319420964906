import * as React from "react";
import { SVGProps } from "react";
const Chef = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 14.3333V16.8333H12.5V14.3333"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49995 14V10.6378M9.99992 14V9.67721M14.9999 5.83472C15.004 5.45014 14.911 5.07033 14.7287 4.72788C14.5465 4.38543 14.2804 4.09057 13.9535 3.86858C13.6265 3.6466 13.2484 3.50413 12.8515 3.45339C12.4547 3.40265 12.0509 3.44516 11.6749 3.57727C11.4241 3.10273 11.0414 2.70417 10.5693 2.42577C10.0971 2.14737 9.55394 2 8.99993 2C8.44593 2 7.90275 2.14737 7.43059 2.42577C6.95844 2.70417 6.57576 3.10273 6.32496 3.57727C6.0605 3.48451 5.7815 3.43578 5.49997 3.43317C4.83694 3.43317 4.20106 3.68619 3.73222 4.13657C3.26339 4.58695 3 5.19779 3 5.83472C3 6.47166 3.26339 7.0825 3.73222 7.53288C4.20106 7.98326 4.83694 8.23628 5.49997 8.23628V14H12.4999V8.23628C12.8282 8.23628 13.1533 8.17416 13.4566 8.05347C13.7599 7.93278 14.0355 7.75588 14.2676 7.53288C14.4998 7.30987 14.6839 7.04513 14.8096 6.75376C14.9352 6.46239 14.9999 6.1501 14.9999 5.83472Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Chef;
