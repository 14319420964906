import * as React from "react";
import { SVGProps } from "react";
const Google = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3392 13.3219L11.1191 10.1339V9.97841V9.90066L14.3392 6.71265L14.4178 6.7904L18.2661 8.96758C19.3657 9.58963 19.3657 10.6005 18.2661 11.2225L14.3392 13.3219Z"
      fill="url(#paint0_linear_23260_306237)"
    />
    <path
      d="M1.3009 0.258789C1.06528 0.492058 0.908203 0.88084 0.908203 1.34738V18.5315C0.908203 18.9981 1.06528 19.3869 1.3009 19.6201L1.37943 19.6979L11.1182 10.0561V9.97834V9.90058L1.3009 0.258789Z"
      fill="url(#paint1_linear_23260_306237)"
    />
    <path
      d="M14.4167 13.244L11.1181 9.97827L1.30078 19.6978C1.69347 20.0866 2.24324 20.0866 2.95009 19.7756L14.4167 13.244Z"
      fill="url(#paint2_linear_23260_306237)"
    />
    <path
      opacity={0.2}
      d="M14.3382 13.1663L2.95009 19.5423C2.32178 19.9311 1.77201 19.8533 1.37932 19.5423L1.30078 19.62L1.37932 19.6978C1.77201 20.0088 2.32178 20.0866 2.95009 19.6978L14.3382 13.1663Z"
      fill="currentColor"
    />
    <path
      d="M14.4167 6.71329L2.95009 0.259511C2.24324 -0.129271 1.69347 -0.0515141 1.30078 0.337268L11.1181 9.97905L14.4167 6.71329Z"
      fill="url(#paint3_linear_23260_306237)"
    />
    <path
      opacity={0.12}
      d="M18.2648 10.9891L14.3379 13.1663L14.4164 13.244L18.2648 11.0669C18.8146 10.7558 19.0502 10.3671 19.0502 9.97827C19.0502 10.3671 18.736 10.6781 18.2648 10.9891Z"
      fill="currentColor"
    />
    <path
      opacity={0.25}
      d="M2.94979 0.336218L18.2648 8.96717C18.736 9.2782 19.0502 9.58922 19.0502 9.97801C19.0502 9.58922 18.8146 9.20044 18.2648 8.88942L2.94979 0.258461C1.85025 -0.363589 0.986328 0.180705 0.986328 1.42481V1.50256C0.986328 0.258462 1.85025 -0.285833 2.94979 0.336218Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23260_306237"
        x1={19.7069}
        y1={9.9795}
        x2={0.702969}
        y2={9.9795}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset={0.4087} stopColor="#FFBD00" />
        <stop offset={0.7754} stopColor="#FFA500" />
        <stop offset={1} stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23260_306237"
        x1={10.2541}
        y1={1.19941}
        x2={-2.79468}
        y2={14.3794}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset={0.00657445} stopColor="#00A1FF" />
        <stop offset={0.2601} stopColor="#00BEFF" />
        <stop offset={0.5122} stopColor="#00D2FF" />
        <stop offset={0.7604} stopColor="#00DFFF" />
        <stop offset={1} stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_23260_306237"
        x1={12.6315}
        y1={11.7636}
        x2={-5.0636}
        y2={29.6368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset={1} stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_23260_306237"
        x1={-1.13603}
        y1={-5.43521}
        x2={6.76564}
        y2={2.54587}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset={0.0685} stopColor="#2DA771" />
        <stop offset={0.4762} stopColor="#15CF74" />
        <stop offset={0.8009} stopColor="#06E775" />
        <stop offset={1} stopColor="#00F076" />
      </linearGradient>
    </defs>
  </svg>
);
export default Google;
