import Image from "next/legacy/image";
import { useState } from "react";
import clsx from "clsx";

import { RecipeAndStepsQuery } from "@chef/state-management";
import { getImageFromImages } from "@chef/state-management/helpers";
import { isEmptyArray } from "@chef/utils/array";
import { capitalize } from "@chef/utils/string";
import { AnimatedCheck } from "@chef/components";

import { intl } from "./Ingredient.intl";

type Ingredient =
  RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][number]["ingredientSections"][number]["ingredients"][number];

interface IngredientProps {
  type: "list" | "grid";
  ingredient: Ingredient;
}

interface AllergiesProps {
  allergies: IngredientProps["ingredient"]["allergies"];
}

const Allergies = ({ allergies }: AllergiesProps) => {
  const filteredAllergies = allergies.filter(
    (a) => a.showAllergy && !a.hasTraceOf,
  );

  if (isEmptyArray(filteredAllergies)) {
    return null;
  }

  const allergiesString = filteredAllergies.map((a) => a.name).join(", ");

  return <strong> ({allergiesString})</strong>;
};

const Ingredient = ({ ingredient, type }: IngredientProps) => {
  const [checked, setChecked] = useState(false);

  const { name, amount, ingredientAmountType, isBasis, allergies } = ingredient;

  const shouldShowAmount = amount !== null && amount !== "0";

  const amountString = shouldShowAmount
    ? `${amount} ${ingredientAmountType}`
    : "";

  const imageSrc = getImageFromImages({
    images: ingredient.images,
    withoutFallback: true,
  });

  if (type === "list") {
    return (
      <div className="border-b border-grey-3 ">
        <div className="grid grid-cols-12 py-4 grid-flow-dense">
          <div className="flex items-center col-span-7 gap-2">
            <span>{capitalize(name)}</span>
          </div>
          <div className="col-span-4 text-right">{amountString}</div>
          <div className="col-span-1 text-right">
            {isBasis && <div className="w-2 h-2 circle bg-grey-1" />}
          </div>
        </div>
        <Allergies allergies={allergies} />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <label htmlFor={ingredient.name} className="relative">
        <div
          className={clsx(
            checked ? "border-secondary" : "border-grey-2",
            !imageSrc && "flex",
            "p-0.5 border rounded-md aspect-square",
          )}
        >
          <input
            type="checkbox"
            id={ingredient.name}
            className="sr-only"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          {checked && (
            <AnimatedCheck
              isChecked={checked}
              className="absolute top-0 right-0 w-4 h-4 p-0.5 text-white bg-secondary rounded-tr-sm z-30"
            />
          )}
          {isBasis && !checked && (
            <div className="absolute right-1.5 top-1.5 circle bg-grey-1 z-30 w-2 h-2" />
          )}
          {imageSrc ? (
            <Image
              src={imageSrc}
              alt={ingredient.name}
              width={1}
              height={1}
              layout="responsive"
              sizes="(min-width: 768px) 20vw, 35vw"
            />
          ) : (
            <p className="flex items-center justify-center mx-auto text-xs text-grey-2">
              <strong>{intl.IMAGE_MISSING}</strong>
            </p>
          )}
        </div>
      </label>

      <div className="text-left break-words hyphens-auto">
        <div className="pt-3 ">{capitalize(name)}</div>
        <Allergies allergies={allergies} />
        <div>{amountString}</div>
      </div>
    </div>
  );
};

export default Ingredient;
