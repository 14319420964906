import * as React from "react";
import { SVGProps } from "react";
const CookingClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.1875 48.2858C4.87826 48.2858 6.49976 48.9574 7.69531 50.153C8.89085 51.3485 9.5625 52.97 9.5625 54.6608H15.9375M98.8125 48.2858C97.1217 48.2858 95.5002 48.9574 94.3047 50.153C93.1092 51.3485 92.4375 52.97 92.4375 54.6608H86.0625M45.4219 35.6952C44.8235 34.7296 44.494 33.6217 44.4674 32.4861C44.4408 31.3505 44.7181 30.2284 45.2706 29.236C45.8232 28.2435 46.6309 27.4167 47.6101 26.841C48.5894 26.2654 49.7047 25.9619 50.8406 25.9619C51.9765 25.9619 53.0918 26.2654 54.0711 26.841C55.0504 27.4167 55.8581 28.2435 56.4106 29.236C56.9631 30.2284 57.2405 31.3505 57.2139 32.4861C57.1873 33.6217 56.8578 34.7296 56.2594 35.6952H45.4219Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9375 48.2861C26.2969 40.6361 37.2937 35.5361 51 35.5361C64.7063 35.5361 75.7031 40.6361 86.0625 48.2861M86.0625 48.2861V67.4111C86.0625 72.4834 84.0476 77.3479 80.4609 80.9346C76.8743 84.5212 72.0098 86.5361 66.9375 86.5361H35.0625C29.9902 86.5361 25.1257 84.5212 21.5391 80.9346C17.9525 77.3479 15.9375 72.4834 15.9375 67.4111V48.2861H86.0625Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.3818 37.0154C87.0643 36.6467 88.1294 34.9838 87.7607 33.3012C87.392 31.6186 85.7291 30.5535 84.0466 30.9222C82.364 31.2909 81.2989 32.9538 81.6676 34.6364C82.0363 36.319 83.6992 37.3841 85.3818 37.0154Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.5279 27.327C76.0346 26.7777 77.6214 24.3004 77.0721 21.7937C76.5228 19.287 74.0454 17.7003 71.5387 18.2496C69.0321 18.7989 67.4453 21.2762 67.9946 23.7829C68.5439 26.2895 71.0212 27.8763 73.5279 27.327Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.8628 22.0343C92.1067 21.3235 94.1602 18.1175 93.4494 14.8736C92.7385 11.6297 89.5326 9.57628 86.2886 10.2871C83.0447 10.998 80.9913 14.2039 81.7021 17.4478C82.413 20.6917 85.6189 22.7452 88.8628 22.0343Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CookingClosed;
