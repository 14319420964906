import { BRAND_NAME, language } from "@chef/constants";

export const intl = (
  {
    no: {
      TIME_TO_REVIEW_YOUR_CHOICES: "Se over dine valg!",
      MAKE_YOUR_EXPERIENCE_MORE_PERSONAL:
        "Gjør din matopplevelse mer personlig.",
      SHOW_THE_NEW_PREFERENCES: "Utforsk de nye valgmulighetene",
      DECORATIVE_VEGGIES: "Dekorative grønnsaker",
    },
    se: {
      TIME_TO_REVIEW_YOUR_CHOICES: "Granska dina val!",
      MAKE_YOUR_EXPERIENCE_MORE_PERSONAL:
        "Gör din matupplevelse mer personlig.",
      SHOW_THE_NEW_PREFERENCES: "Visa mina inställningar",
      DECORATIVE_VEGGIES: "Dekorativa grönsaker",
    },
    dk: {
      TIME_TO_REVIEW_YOUR_CHOICES: "Gennemgå dine præferencer!",
      MAKE_YOUR_EXPERIENCE_MORE_PERSONAL:
        "Vær med til at forbedre dine menuforslag.",
      SHOW_THE_NEW_PREFERENCES: "Se de nye valgmuligheder",
      DECORATIVE_VEGGIES: "Dekorative grøntsager",
    },
  } as const
)[language];

export const brand = (
  {
    GL: {
      DECORATIVE_IMG:
        "https://ggfrontendassets.azureedge.net/publicassets/godtlevert/veggies.png",
      BACKGROUND_COLOR: "#ebf4e8",
    },
    AMK: {
      DECORATIVE_IMG:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/veggies.png",
      BACKGROUND_COLOR: "#e8ebdf",
    },
    LMK: {
      DECORATIVE_IMG:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/veggies.png",
      BACKGROUND_COLOR: "#ddefdd",
    },
    RN: {
      DECORATIVE_IMG:
        "https://ggfrontendassets.azureedge.net/publicassets/retnemt/veggies.png",
      BACKGROUND_COLOR: "#dfefdc",
    },
  } as const
)[BRAND_NAME];
