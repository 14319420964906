import * as React from "react";
import { SVGProps } from "react";
const AmericanExpress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 24V20.197H21.1046L19.6138 18.5486L18.1157 20.197H8.56944V12.5146H5.48832L9.31008 3.86544H12.996L14.3117 6.82848V3.86544H18.8741L19.6661 6.0984L20.4634 3.86544H24V0H0V24H24ZM21.5923 19.235H24L20.8152 15.8578L24 12.5189H21.6307L19.6637 14.6683L17.7158 12.5189H15.3077L18.4738 15.877L15.3077 19.235H17.6491L19.6258 17.0664L21.5928 19.235H21.5923ZM22.1554 15.8606L23.9995 17.8229V13.9147L22.1554 15.8606ZM11.4211 17.6808V16.6349H15.2112V15.119H11.4211V14.0731H15.3072V12.5189H9.58848V19.2355H15.3072V17.6813H11.4211V17.6808ZM22.1861 11.544H23.9995V4.82736H21.1786L19.6718 9.01056L18.1752 4.82736H15.3062V11.544H17.1197V6.8424L18.8467 11.544H20.4586L22.1856 6.8328V11.544H22.1861ZM13.2437 11.544H15.3067L12.3418 4.82736H9.98112L7.01616 11.544H9.0312L9.588 10.2005H12.6778L13.2437 11.544ZM12.0442 8.69424H10.2211L11.1326 6.4968L12.0442 8.69424Z"
      fill="#006FCF"
    />
  </svg>
);
export default AmericanExpress;
