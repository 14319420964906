import * as React from "react";
import { SVGProps } from "react";
const Truck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
      fill="#FFF6E8"
    />
    <path
      d="M65.3697 55.6299H15.2197"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9002 46.3999H47.4102"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.4102 30.0698H57.1202L60.7202 36.5998H63.9002C64.5502 36.5998 65.3702 36.7598 65.3702 37.7398V46.3998H59.2502"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.6604 23.54H47.4104V46.4H29.8604"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5095 46.4H18.0195V23.54H45.6595"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.19 54.5598C28.8962 54.5598 31.09 52.366 31.09 49.6598C31.09 46.9536 28.8962 44.7598 26.19 44.7598C23.4838 44.7598 21.29 46.9536 21.29 49.6598C21.29 52.366 23.4838 54.5598 26.19 54.5598Z"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.5699 54.5598C58.2761 54.5598 60.4699 52.366 60.4699 49.6598C60.4699 46.9536 58.2761 44.7598 55.5699 44.7598C52.8637 44.7598 50.6699 46.9536 50.6699 49.6598C50.6699 52.366 52.8637 54.5598 55.5699 54.5598Z"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Truck;
