import { language } from "@chef/constants";

export const isValidPostalCode = (
  postalCode?: string,
): postalCode is string => {
  if (postalCode === undefined) {
    return false;
  }

  if (language === "se") {
    return /^[0-9]{5}$/.test(postalCode);
  }

  return /^[0-9]{4}$/.test(postalCode);
};

export const padPostalCode = (postalCode: string | number) => {
  if (typeof postalCode === "number") {
    postalCode = postalCode.toString();
  }

  // if it's an empty string, we don't want to pad
  if (!postalCode) {
    return postalCode;
  }

  if (language === "se") {
    return postalCode.padStart(5, "0");
  }

  return postalCode.padStart(4, "0");
};
