import clsx from "clsx";

import { CircularProgress } from "@chef/components";
import { BRAND_NAME } from "@chef/constants";
import { AdamsBadge, Bowl, LongHat } from "@chef/icons/small";

type Progress = {
  current: number;
  projected?: number | undefined;
};

interface LoyaltyTierIconProps {
  inverted?: boolean;
  progress: Progress;
  tierId: number;
}

export const LoyaltyTierIcon = ({
  inverted,
  progress,
  tierId,
}: LoyaltyTierIconProps) => {
  return (
    <CircularProgress progress={progress} inverted={inverted}>
      <div
        className={clsx(
          tierId === 1 && "bg-primary",
          tierId === 2 && "bg-[#D0984F]",
          tierId === 3 && "bg-grey-1",
          tierId === 4 && "bg-highlight",
          "rounded-full flex justify-center items-center w-3/4 h-3/4",
        )}
      >
        <brand.BadgeIcon className="text-white" />
      </div>
    </CircularProgress>
  );
};

const brand = (
  {
    GL: {
      BadgeIcon: LongHat,
    },
    AMK: {
      BadgeIcon: AdamsBadge,
    },
    LMK: {
      BadgeIcon: Bowl,
    },
    RN: {
      BadgeIcon: LongHat,
    },
  } as const
)[BRAND_NAME];
