import clsx from "clsx";

import { PropsOf } from "../../types";
import { Currency } from "../components";
import { Language } from "../../types";

interface CurrencyWithDiscountProps
  extends Omit<PropsOf<typeof Currency>, "children"> {
  total: number | string;
  discount: number | string;
  id?: string;
  language: Language;
  emphasized?: boolean;
}

const CurrencyWithDiscount = ({
  prefix,
  total,
  discount,
  className,
  id,
  language,
  emphasized,
}: CurrencyWithDiscountProps) => {
  const hasDiscount = +discount > 0;

  return (
    <div className={clsx("flex gap-2", className)} id={id}>
      {hasDiscount && (
        <div className="flex flex-wrap items-center gap-2">
          <Currency
            language={language}
            className="text-sm text-discount"
            prefix={prefix}
            emphasized={emphasized}
          >
            {+total}
          </Currency>

          <Currency
            language={language}
            className="mt-px text-xs line-through"
            prefix={prefix}
          >
            {+total + +discount}
          </Currency>
        </div>
      )}

      {!hasDiscount && (
        <Currency language={language} prefix={prefix} emphasized={emphasized}>
          {+total}
        </Currency>
      )}
    </div>
  );
};

export default CurrencyWithDiscount;
