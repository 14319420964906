import Image from "next/legacy/image";
import Link from "next/link";

import { motion } from "framer-motion";

import { BRAND_NAME, BRAND_NAME_FULL, LOGO } from "@chef/constants";

interface LogoProps {
  href?: string;
  responsive?: boolean;
  variant?: "default" | "inverted";
}

const MobileVariant = ({ variant }: { variant: LogoProps["variant"] }) => (
  <Image
    src={variant === "default" ? LOGO.SHORT : LOGO.SHORT_INVERTED}
    alt={`${BRAND_NAME_FULL} logo`}
    width={brand.LOGO_HEIGHT}
    height={brand.LOGO_HEIGHT}
    layout="fixed"
    priority
  />
);

const DefaultVariant = ({ variant }: { variant: LogoProps["variant"] }) => (
  <Image
    src={variant === "default" ? LOGO.DEFAULT : LOGO.DEFAULT_INVERTED}
    alt={`${BRAND_NAME_FULL} logo`}
    width={brand.LOGO_WIDTH}
    height={brand.LOGO_HEIGHT}
    layout="fixed"
    priority
  />
);

const ResponsiveLogo = ({ variant }: { variant: LogoProps["variant"] }) => (
  <>
    <div className="md:hidden">
      <MobileVariant variant={variant} />
    </div>
    <div className="hidden md:block">
      <DefaultVariant variant={variant} />
    </div>
  </>
);

export const Logo = ({ href, responsive, variant = "default" }: LogoProps) => {
  if (href) {
    return (
      <Link href={href} passHref legacyBehavior>
        <motion.a
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 1.05 }}
          style={{ height: brand.LOGO_HEIGHT }}
        >
          {responsive ? (
            <ResponsiveLogo variant={variant} />
          ) : (
            <DefaultVariant variant={variant} />
          )}
        </motion.a>
      </Link>
    );
  }

  if (responsive) {
    return <ResponsiveLogo variant={variant} />;
  }

  return <DefaultVariant variant={variant} />;
};

const brand = (
  {
    AMK: {
      LOGO_HEIGHT: 40,
      LOGO_WIDTH: 122,
    },

    GL: {
      LOGO_HEIGHT: 28,
      LOGO_WIDTH: 156,
    },

    LMK: {
      LOGO_HEIGHT: 36,
      LOGO_WIDTH: 92,
    },

    RN: {
      LOGO_HEIGHT: 32,
      LOGO_WIDTH: 141,
    },
  } as const
)[BRAND_NAME];
