import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      BASIC_GOODS: "Basisvarer",
    },
    se: {
      BASIC_GOODS: "Basvaror",
    },
    dk: {
      BASIC_GOODS: "Basisvarer",
    },
  } as const
)[language];
