import { useEffect, useState } from "react";

/**
 *
 * @param value Value to debounce
 * @param delay Delay in ms
 * @param fn Function to check if value should be debounced. If it returns false, the value will update immediately.
 * @returns New value
 */
export const useDebounce = <T>(
  value: T,
  delay: number,
  fn?: (v: T) => boolean,
) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (fn && !fn(value)) {
      setCurrentValue(value);
      return;
    }

    const timer = setTimeout(() => setCurrentValue(value), delay);

    return () => clearTimeout(timer);
  }, [value, delay, setCurrentValue, fn]);

  return currentValue;
};
