import { useState } from "react";

import { RecipeAndStepsQuery } from "@chef/state-management";
import { Disclosure, PropsOf, RadioButtonBar } from "@chef/components";

import { IngredientSection } from "./IngredientSection";

import { intl } from "./IngredientSections.Intl";

interface IngredientSectionsProps {
  ingredientSections: RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][0]["ingredientSections"];
}

enum INGREDIENT_LAYOUT {
  LIST = "list",
  GRID = "grid",
}

const IngredientLayoutOptions: PropsOf<
  typeof RadioButtonBar<INGREDIENT_LAYOUT>
>["options"] = [
  {
    name: intl.LIST,
    value: INGREDIENT_LAYOUT.LIST,
  },
  {
    name: intl.PICTURES,
    value: INGREDIENT_LAYOUT.GRID,
  },
];

export const IngredientSections = ({
  ingredientSections,
}: IngredientSectionsProps) => {
  const [layoutType, setLayoutType] = useState<INGREDIENT_LAYOUT>(
    INGREDIENT_LAYOUT.LIST,
  );

  return (
    <>
      <div className="mb-4 col-span-full">
        <RadioButtonBar
          options={IngredientLayoutOptions}
          value={layoutType}
          onChange={(val) => {
            setLayoutType(val);
          }}
          name="ingredient-layout"
          small
        />
      </div>
      <div>
        {ingredientSections
          .filter((section) => section.sectionId !== "basis")
          .map((section) => {
            const amount = section.ingredients.length;

            const sectionTitleWithAmount = `${section.sectionTitle} ${
              amount > 0 && `(${amount})`
            }`;
            return (
              <Disclosure
                className="py-4 border-b-2 border-grey-1"
                key={section.sectionId}
                defaultOpen
                ExpandedTitle={() => (
                  <strong className="text-lg">{section.sectionTitle}</strong>
                )}
                Title={() => (
                  <strong className="text-lg">{sectionTitleWithAmount}</strong>
                )}
              >
                <IngredientSection
                  ingredients={section.ingredients}
                  type={layoutType}
                />
              </Disclosure>
            );
          })}
      </div>
    </>
  );
};
