import * as React from "react";
import { SVGProps } from "react";
const AdamsBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12 9"
    fill="none"
    {...props}
  >
    <path
      d="M7.79041 7.86418L5.1048 0H4.97691C4.58259 0.454327 4.06039 0.497596 3.27176 0.497596V0.627404L3.42096 1.04928L1.00178 7.91827C0.820604 8.42668 0.650089 8.6214 0.415631 8.74039H0.373002C0.26643 8.79447 0.159858 8.82692 0.053286 8.84856H0V9H1.86501V8.87019H1.82238C1.52398 8.80529 1.33215 8.7512 1.26821 8.64303C1.20426 8.53486 1.23623 8.32933 1.35346 7.96154L1.80107 6.69591H5.34991L5.75488 7.875C6.01066 8.63221 5.95737 8.74039 5.27531 8.87019V9.02164H8.79218V8.87019C8.31261 8.74039 8.05684 8.63221 7.79041 7.875V7.86418ZM5.23268 6.32813H1.92895L3.61279 1.59014L5.23268 6.32813Z"
      fill="white"
    />
    <path d="M11.9977 7.34375H10.3672V8.9988H11.9977V7.34375Z" fill="white" />
  </svg>
);
export default AdamsBadge;
