import { RecipeAndStepsQuery } from "@chef/state-management";
import { brand } from "./AlergensBox.Intl";

interface AllergensBoxProps {
  allergens: RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][0]["allergies"];
}

export const AllergensBox = ({ allergens = [] }: AllergensBoxProps) => {
  return (
    <div className="flex flex-col w-full pb-4">
      <strong>Allergener:</strong>
      <ul className="flex flex-wrap gap-1 pb-1">
        {allergens
          .filter(
            (obj) =>
              typeof obj.id !== "undefined" &&
              obj.showAllergy &&
              !obj.hasTraceOf,
          )
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((allergen, i, arr) => (
            <li key={allergen.id} className="flex items-center gap-1">
              {allergen.name}
              {i < arr.length - 1 && <div>·</div>}
            </li>
          ))}
      </ul>
      <p className="text-grey-1">{brand.DISCLAIMER}</p>
    </div>
  );
};
