import clsx from "clsx";
import { Language } from "../../../types";

interface CurrencyProps {
  prefix?: string;
  hideIfZero?: boolean;
  className?: string;
  children: React.ReactNode;
  id?: string;
  language?: Language;
  emphasized?: boolean;
}

export const Currency = ({
  prefix = "",
  hideIfZero = false,
  className,
  children,
  id,
  language = "no",
  emphasized,
  ...props
}: CurrencyProps) => {
  if (children === 0 && hideIfZero) {
    return null;
  }

  if (typeof children !== "string" && typeof children !== "number") {
    return null;
  }

  let _children = children;

  if (typeof children === "string") {
    const parsedNumber = parseFloat(children);
    if (!isNaN(parsedNumber)) {
      _children = parsedNumber;
    }
  }

  const formatted = _children.toLocaleString(intl[language].LOCALE, {
    style: "currency",
    currency: intl[language].CURRENCY,
    signDisplay: "never",
  });

  const Component = (
    <span id={id} className={clsx("whitespace-nowrap", className)} {...props}>
      {prefix}
      {formatted}
    </span>
  );

  return emphasized ? <strong>{Component}</strong> : Component;
};

const intl = {
  no: {
    LOCALE: "no-NO",
    CURRENCY: "NOK",
  },
  se: {
    LOCALE: "sv-SE",
    CURRENCY: "SEK",
  },
  dk: {
    LOCALE: "da-DK",
    CURRENCY: "DKK",
  },
} as const;
