import { LMKGroup } from "@chef/icons/small";
import { Language } from "../../types";

interface LMKGroupLinkProps {
  language?: Language;
}

const intl = {
  no: {
    DESCRIPTION: "Del av ",
  },
  se: {
    DESCRIPTION: "En del av ",
  },
  dk: {
    DESCRIPTION: "En del af ",
  },
};

const LMKGroupLink = ({ language = "no" }: LMKGroupLinkProps) => {
  const { DESCRIPTION } = intl[language];
  const baseStyles = "flex justify-center py-5 text-sm";

  return (
    <div className="print:hidden">
      <hr className="border-black" />
      <div className={baseStyles}>
        <div>
          <span>{DESCRIPTION}</span>
          <a href="https://lmkgroup.se/" target="_blank" rel="noreferrer">
            <strong>lmkgroup.se</strong>
            <LMKGroup className="ml-2 text-4xl" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LMKGroupLink;
