import { ListenerMiddlewareInstance, isAnyOf } from "@reduxjs/toolkit";
import { language } from "@chef/constants";
import { showNotification, validateApi } from "../features";

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      validateApi.endpoints.validateBasket.matchFulfilled,
      validateApi.endpoints.validateBasket.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showNotification({
          type: "error",
          message: intl.BASKET_VALIDATION_FAILED,
        });
      }
    },
  });
};

const intl = (
  {
    no: {
      BASKET_VALIDATION_FAILED:
        "Det oppstod en feil under validering av bestillingen. Vennligst prøv igjen.",
    },
    se: {
      ORDER_UPDATED: "Leverans uppdaterad",
      BASKET_VALIDATION_FAILED:
        "Ett fel uppstod när din beställning skulle valideras. Var god försök igen.",
    },
    dk: {
      BASKET_VALIDATION_FAILED:
        "Der skete en fejl under valideringen af din ordre. Prøv igen.",
    },
  } as const
)[language];
