import { Snackbar } from "@chef/components";
import { STAGE, usePreselector } from "@chef/state-management/hooks";

import { intl } from "./CompletedWithAllergenWarning.Intl";

interface CompletedWithAllergenWarningProps {
  className?: string;
}

export const CompletedWithAllergenWarning = ({
  className,
}: CompletedWithAllergenWarningProps) => {
  const { stage } = usePreselector();

  if (stage !== STAGE.COMPLETED_WITH_ALLERGEN) {
    return null;
  }

  return (
    <Snackbar error className={className}>
      <p className="text-sm text-left">
        {intl.YOU_HAVE_COMPLETED_WITH_ALLERGEN}
      </p>
    </Snackbar>
  );
};
