import { BRAND_NAME } from "./misc";

export const LOGO = (
  {
    AMK: {
      DEFAULT:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/adams-logo-black.svg",
      DEFAULT_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/adams-logo-white.svg",
      SHORT:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/a-logo-black.svg",
      SHORT_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/a-logo-white.svg",
      EMBLEM:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/a-emblem-black.svg",
      EMBLEM_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/a-emblem-white.svg",
    },
    LMK: {
      DEFAULT:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/linas-logo-red.svg",
      DEFAULT_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/linas-logo-creme.svg",
      SHORT:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/ln-logo-red.svg",
      SHORT_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/ln-logo-creme.svg",
      EMBLEM:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/ln-logo-red.svg",
      EMBLEM_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/ln-logo-creme.svg",
    },
    GL: {
      DEFAULT:
        "https://ggfrontendassets.azureedge.net/publicassets/godtlevert/godtlevert-logo.svg",
      DEFAULT_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/godtlevert/godtlevert-logo-white.svg",
      SHORT:
        "https://ggfrontendassets.azureedge.net/publicassets/godtlevert/godtlevert-icon.svg",
      SHORT_INVERTED: "",
      EMBLEM:
        "https://ggfrontendassets.azureedge.net/publicassets/godtlevert/godtlevert-icon.svg",
      EMBLEM_INVERTED: "",
    },
    RN: {
      DEFAULT:
        "https://ggfrontendassets.azureedge.net/publicassets/retnemt/retnemt-logo.svg",
      DEFAULT_INVERTED:
        "https://ggfrontendassets.azureedge.net/publicassets/retnemt/retnemt-logo-white.svg",
      SHORT:
        "https://ggfrontendassets.azureedge.net/publicassets/retnemt/retnemt-icon.svg",
      SHORT_INVERTED: "",
      EMBLEM:
        "https://ggfrontendassets.azureedge.net/publicassets/retnemt/retnemt-icon.svg",
      EMBLEM_INVERTED: "",
    },
  } as const
)[BRAND_NAME];
