import * as React from "react";
import { SVGProps } from "react";
const Chili = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 75C0 33.5786 33.5786 0 75 0V0C116.421 0 150 33.5786 150 75V75C150 116.421 116.421 150 75 150V150C33.5786 150 0 116.421 0 75V75Z"
      fill="#F4F4F1"
    />
    <path
      d="M51.0293 58.5059C50.4671 57.7205 50.0818 56.8225 49.9002 55.8738C49.7185 54.9252 49.7447 53.9484 49.9771 53.0109C50.2094 52.0734 50.6423 51.1974 51.2459 50.4433C51.8495 49.6892 52.6095 49.0751 53.4734 48.6431C54.3373 48.2111 55.2847 47.9717 56.2501 47.9412C57.2155 47.9108 58.176 48.0901 59.0654 48.4667C59.9549 48.8434 60.752 49.4084 61.402 50.123C62.0519 50.8375 62.5391 51.6844 62.8301 52.6055M43.125 41.25C46.6875 41.25 52.8105 43.6992 54.1465 48.2637"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.469 103.595C105.469 102.413 105 101.281 104.164 100.446C103.329 99.6106 102.197 99.1414 101.016 99.1414C91.6641 99.1414 85.9863 96.2469 80.4199 81.7742C74.8535 67.3016 73.1836 61.5125 70.1777 57.282L69.1758 56.0574H69.0645C67.547 54.4362 65.5795 53.3058 63.4146 52.8115C61.2498 52.3173 58.9866 52.4816 56.9159 53.2836C54.8453 54.0855 53.0617 55.4884 51.7945 57.3118C50.5273 59.1353 49.8343 61.296 49.8047 63.5164V63.9617C49.8047 77.3211 71.959 107.936 100.904 108.048H101.127C102.289 108.019 103.393 107.537 104.204 106.705C105.015 105.873 105.469 104.757 105.469 103.595Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Chili;
