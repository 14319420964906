import dynamic from "next/dynamic";
import { SyntheticEvent, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";
import clsx from "clsx";

import { usePersistentState, useKeyToggle } from "@chef/hooks";
import {
  showNotification,
  useGetSanityStatusQuery,
  useLazyMeQuery,
} from "@chef/state-management";

declare const DD_RUM: typeof datadogRum;

const DebugBar = () => {
  const router = useRouter();
  const [meQuery] = useLazyMeQuery();
  const { data: sanityStatus } = useGetSanityStatusQuery();

  const [hidden, setHidden] = useKeyToggle("KeyD", true);
  const [agreementId, setAgreementId] = useState("N/A");

  const [prefersHidden, setPrefersHidden] = usePersistentState(
    "hide_debug_bar",
    true,
  );

  useEffect(() => {
    (async () => {
      const agreementId =
        (await meQuery().unwrap())?.agreementId?.toString() || "N/A";

      setAgreementId(agreementId);
    })();
  }, [meQuery, router.route]);

  useEffect(() => {
    setHidden(!!prefersHidden);
  }, []);

  useEffect(() => {
    setPrefersHidden(hidden);
  }, [hidden]);

  let viewId = "Datadog not loaded";
  if (DD_RUM) {
    viewId = DD_RUM.getInternalContext()?.view?.id || "No view";
  }

  const handleCopy = (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const target = e.target as HTMLButtonElement;
    const text = target.textContent as string;
    navigator.clipboard.writeText(text);

    // Show a notification

    showNotification({ type: "success", message: "Copied to clipboard!" });
  };

  const data = [
    {
      label: "AgreementId",
      value: agreementId,
    },
    {
      label: "ViewId",
      value: viewId || "Loading...",
    },
    {
      label: "Draft mode",
      value: sanityStatus ? sanityStatus.isPreview.toString() : "Loading...",
      link: sanityStatus?.isPreview
        ? `/api/sanity/exit-preview?redirect=${window.location.pathname}`
        : `/api/sanity/draft?type=landing&secret=preview.secret`,
    },
  ];

  return (
    <div
      className={clsx(
        "sticky top-0 z-50 w-full h-auto px-8 text-white bg-black print:hidden",
        hidden && "hidden",
      )}
    >
      <div className="relative w-full h-full">
        <div
          onClick={() => setHidden(!hidden)}
          className="w-full h-full"
          tabIndex={hidden ? -1 : 0}
        >
          <dl className="flex flex-wrap gap-x-8 gap-y-2">
            {data.map((item) => (
              <div key={item.label}>
                <dt className="inline mr-2 text-sm">{item.label}:</dt>
                <dd className="inline text-sm">
                  {item.link ? (
                    <a href={item.link}>{item.value}</a>
                  ) : (
                    <button
                      onClick={handleCopy}
                      className="hover:underline"
                      tabIndex={hidden ? -1 : 0}
                    >
                      {item.value}
                    </button>
                  )}
                </dd>
              </div>
            ))}
          </dl>
          <div className="text-sm top-px md:absolute right-2">
            <span>Press &quot;D&quot; to hide</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default dynamic(() => Promise.resolve(DebugBar), {
  ssr: false,
});
