import * as React from "react";
import { SVGProps } from "react";
const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 10.3325V13.4436C15 13.8562 14.8361 14.2519 14.5444 14.5436C14.2527 14.8353 13.857 14.9992 13.4444 14.9992H2.55556C2.143 14.9992 1.74733 14.8353 1.45561 14.5436C1.16389 14.2519 1 13.8562 1 13.4436V10.3325"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11035 6.44507L7.99924 10.334L11.8881 6.44507"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.3333V1"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Download;
