type ImageProps = {
  dimensions: { width: number; height: number };
  crop?: { bottom: number; left: number; right: number; top: number };
};

export const getCroppedAspectRatio = ({ dimensions, crop }: ImageProps) => {
  if (!crop) {
    return {
      width: dimensions.width,
      height: dimensions.height,
    };
  }
  const { top, right, bottom, left } = crop;
  return {
    width: dimensions.width * (1 - (left + right)),
    height: dimensions.height * (1 - (top + bottom)),
  };
};
