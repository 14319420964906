import * as React from "react";
import { SVGProps } from "react";
const Gift = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.75 12.5625V20.4375H5.25V12.5625H18.75ZM4.125 9.1875H19.875V12.5625H4.125V9.1875Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.74849 13V20.4387M14.2485 13V20.4387M11.9985 9.18866C11.9985 9.18866 8.31411 8.54179 7.13286 7.50116C6.78994 7.19873 6.5473 6.79901 6.43724 6.35523C6.32719 5.91144 6.35495 5.44466 6.51683 5.01705C6.67872 4.58944 6.96703 4.22129 7.34338 3.96165C7.71973 3.702 8.16626 3.56318 8.62349 3.56366C8.96077 3.55239 9.29573 3.6233 9.59951 3.77029C9.90329 3.91728 10.1668 4.13594 10.3672 4.40741C11.0422 5.16679 11.9985 7.50116 11.9985 9.18866ZM11.9985 9.18866C11.9985 9.18866 15.6829 8.54179 16.8641 7.50116C17.207 7.19873 17.4497 6.79901 17.5597 6.35523C17.6698 5.91144 17.642 5.44466 17.4801 5.01705C17.3183 4.58944 17.0299 4.22129 16.6536 3.96165C16.2772 3.702 15.8307 3.56318 15.3735 3.56366C15.0362 3.55239 14.7012 3.6233 14.3975 3.77029C14.0937 3.91728 13.8302 4.13594 13.6297 4.40741C12.9547 5.16679 11.9985 7.50116 11.9985 9.18866Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Gift;
