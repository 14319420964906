import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import { Button, BurgerButton } from "@chef/components";
import { useIntercom } from "@chef/hooks";
import {
  selectDrawerModalIsOpen,
  hideDrawerModal,
  useLogoutMutation,
} from "@chef/state-management";
import { language } from "@chef/constants";

import { Logo } from "../../Logo";
import { DrawerButton } from "./DrawerButton";

interface DrawerProps {
  hidden?: boolean;
}

const sidebar = {
  open: {
    display: "block",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    display: "none",
    transition: {
      delay: 0.1,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const intl = (
  {
    no: {
      LOG_OUT: "Logg ut",
      LOG_IN: "Logg inn",
      CREATE_ACCOUNT: "Bestill nå",
      OPEN_MENU_LABEL: "Åpne meny",
    },
    se: {
      LOG_OUT: "Logga ut",
      LOG_IN: "Logga in",
      CREATE_ACCOUNT: "Beställ nu",
      OPEN_MENU_LABEL: "Öppna meny",
    },
    dk: {
      LOG_OUT: "Log ud",
      LOG_IN: "Log ind",
      CREATE_ACCOUNT: "Bestil nu",
      OPEN_MENU_LABEL: "Åbn menu",
    },
  } as const
)[language];

export const B2BDrawer = ({ hidden = false }: DrawerProps) => {
  const [scrollY, setScrollY] = useState(0);

  const dispatch = useDispatch();
  const router = useRouter();
  const [logout] = useLogoutMutation();
  const containerRef = useRef(null);

  const isOpen = useSelector(selectDrawerModalIsOpen);

  useIntercom(!isOpen);

  useEffect(() => {
    router.events.on("routeChangeStart", handleClose);

    return () => {
      router.events.off("routeChangeStart", handleClose);
    };
  }, []);

  useEffect(() => {
    setScrollY(window.scrollY);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${scrollY}px`;
      document.body.classList.add("disable-scroll");
    } else {
      document.body.style.top = "";
      document.body.classList.remove("disable-scroll");
    }
  }, [isOpen, scrollY]);

  const handleClose = () => {
    dispatch(hideDrawerModal());
  };

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      ref={containerRef}
    >
      {!hidden && <DrawerButton isB2B />}

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 z-45 bg-black/50 backdrop-blur-sm"
            onClick={handleClose}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }}
          />
        )}
      </AnimatePresence>

      <motion.div
        variants={sidebar}
        className="fixed top-0 bottom-0 right-0 z-50 w-screen h-full overflow-y-auto shadow bg-background md:w-96"
        drag="x"
        onDragEnd={(_, { offset }) => {
          if (offset.x > 100) {
            handleClose();
          }
        }}
        dragConstraints={{ left: 0, right: 1 }}
        dragElastic={{ left: 0, right: 0.8 }}
        style={{ touchAction: "none" }}
      >
        <div>
          <div className="flex items-center justify-between gap-8 p-4 h-15 bg-primary">
            <Logo variant="inverted" />
            <BurgerButton
              onClick={handleClose}
              className="rounded stroke-white hover:bg-grey-2/75 hover:stroke-black"
              title={intl.OPEN_MENU_LABEL}
            />
          </div>
        </div>

        <motion.ul
          variants={{
            open: {
              transition: { staggerChildren: 0.03, delayChildren: 0.1 },
            },
            closed: {
              transition: { staggerChildren: 0.01, staggerDirection: -1 },
            },
          }}
          className="flex flex-col justify-between px-4"
        >
          {drawerItems.map(({ label, href }) => {
            return (
              <li
                className="text-lg block relative border-b-1.5 border-grey-2"
                key={`${href}-${label}`}
                id={`${label}-drawer-link`}
              >
                <Link href={href} className="block py-3 group" passHref>
                  {label}
                </Link>
              </li>
            );
          })}
        </motion.ul>

        <div className="flex flex-col px-4">
          <Button
            outlined
            className="mx-4 my-4 no-underline"
            onClick={() => {
              logout();
            }}
          >
            {intl.LOG_OUT}
          </Button>
        </div>
      </motion.div>
    </motion.nav>
  );
};

const drawerItems = [
  {
    label: "Konto",
    href: "/bedrift/konto",
  },
  {
    label: "Kontakt",
    href: "/bedrift/kontakt",
  },
];
