import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const shlinkApi = createApi({
  reducerPath: "shlink",
  baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
  endpoints: (builder) => ({
    createShortLink: builder.mutation<
      { shortUrl: string },
      { longUrl: string }
    >({
      query: ({ longUrl }) => ({
        url: "/shlink",
        method: "POST",
        body: {
          longUrl: longUrl,
        },
      }),
    }),
  }),
});

export const { useCreateShortLinkMutation } = shlinkApi;
