import clsx from "clsx";

interface SubTextProps {
  error: string;
  warning?: string;
  helperText?: string;
  htmlFor?: string;
  maxLength?: number;
  value?: string;
  className?: string;
  showLengthCounter?: boolean;
}

const SubText = ({
  error,
  warning,
  helperText,
  htmlFor,
  maxLength,
  value = "",
  className,
  showLengthCounter = true,
}: SubTextProps) => {
  let status: 0 | 1 | 2;

  if (error) {
    status = 2;
  } else if (warning) {
    status = 1;
  } else {
    status = 0;
  }

  const maxLengthIsSet = !!(maxLength && +maxLength > 0);

  return (
    <div
      className={clsx(
        "relative text-xs flex mt-2",
        maxLengthIsSet && "justify-between",
        className,
      )}
    >
      {(helperText || warning || error || maxLengthIsSet) && (
        <>
          <label className="flex" htmlFor={htmlFor}>
            <span
              className={clsx(
                "rounded-full w-2.5 h-2.5 ml-1 mr-2 mt-0.5 inline-block shrink-0",
                status === 2 && "bg-error",
                status === 1 && "bg-highlight",
                status === 0 && "invisible -ml-2",
              )}
            />

            <div data-testid="sub-text">
              {status === 2 && error}
              {status === 1 && warning}
              {status === 0 && (
                <span className="text-grey-1">{helperText}</span>
              )}
            </div>
          </label>

          {showLengthCounter && maxLengthIsSet && (
            <label htmlFor={htmlFor} className="text-grey-1">
              {value?.length || 0}/{maxLength}
            </label>
          )}
        </>
      )}
    </div>
  );
};

export default SubText;
