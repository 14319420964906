import React, { useState } from "react";
import clsx from "clsx";

import { ChevronUp, ChevronDown } from "@chef/icons/small";

export interface BaseDisclosureProps {
  Icon?: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement | null;
  showRightSideIcon?: boolean;
  className?: string;
  classNameIcon?: string;
  children?: React.ReactNode;
  id?: string;
  hideContentTopMargin?: boolean;
}

type ControlledDisclosureProps = {
  open: boolean;
  onClick: () => void;
  defaultOpen?: never;
};

type UnControlledDisclosureProps = {
  open?: never;
  onClick?: never;
  defaultOpen?: boolean;
};

type HeaderProps =
  | {
      Title: () => React.ReactElement | null;
      HeaderRow?: never;
      ExpandedTitle?: () => React.ReactElement | null;
    }
  | {
      Title?: never;
      ExpandedTitle?: never;
      HeaderRow: () => React.ReactElement | null;
    };

type DisclosureProps = BaseDisclosureProps &
  (ControlledDisclosureProps | UnControlledDisclosureProps) &
  HeaderProps;

export type ControlledProps = BaseDisclosureProps &
  ControlledDisclosureProps &
  HeaderProps;

export const Disclosure = ({
  className,
  classNameIcon,
  id,
  defaultOpen,
  Icon,
  showRightSideIcon = true,
  Title,
  ExpandedTitle,
  HeaderRow,
  children,
  onClick,
  open: controlledOpen,
  hideContentTopMargin,
}: DisclosureProps) => {
  const [unControlledOpen, setUncontrolledOpen] = useState(
    defaultOpen || false,
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      setUncontrolledOpen(!unControlledOpen);
    }
  };

  const open = controlledOpen || unControlledOpen;
  const Header = Title ? (
    <div
      className={clsx(
        "flex flex-row text-sm items-center justify-between grow cursor-pointer",
        className,
      )}
    >
      <div className="flex items-center grow">
        {(ExpandedTitle && (open ? <ExpandedTitle /> : <Title />)) || <Title />}
      </div>

      {showRightSideIcon &&
        (open ? (
          <ChevronUp className={clsx("w-4 h-4", classNameIcon)} />
        ) : (
          <ChevronDown className={clsx("w-4 h-4", classNameIcon)} />
        ))}
    </div>
  ) : (
    <HeaderRow />
  );

  return (
    <div id={id}>
      <button
        className="flex items-center justify-between w-full"
        onClick={handleClick}
      >
        <div className="flex items-center grow">
          {Icon && <Icon className="mr-2 h-4.5 w-4.5 text-primary" />}

          {Header}
        </div>
      </button>

      <div
        className={clsx(!hideContentTopMargin && "mt-4 ", !open && "sr-only")}
      >
        {children}
      </div>
    </div>
  );
};
