import React, { CSSProperties } from "react";
import clsx from "clsx";

export interface SkeletonProps<T extends keyof React.ReactHTML>
  extends React.HTMLAttributes<keyof T> {
  as?: T;
  variant?: "circular" | "rectangular" | "rounded";
  animation?: "pulse" | "ping" | "none";
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  className?: string;
  id?: string;
}

export const Skeleton = <T extends keyof React.ReactHTML>({
  as,
  variant = "rectangular",
  animation = "none",
  width = "100%",
  height = 2,
  className,
  ...props
}: SkeletonProps<T>) => {
  const variantStyle = {
    circular: "rounded-full",
    rounded: "rounded-sm",
    rectangular: "rounded-none",
  };

  const animationStyle = {
    ping: "animate-ping",
    pulse: "animate-pulse",
    none: "animate-none",
  };

  const variantClass = variantStyle[variant];
  const animationClass = animationStyle[animation];

  const heightStyle = typeof height === "string" ? height : `${height * 4}px`;
  const widthStyle = typeof width === "string" ? width : `${width * 4}px`;

  return React.createElement(as || "div", {
    className: clsx("bg-grey-3", variantClass, animationClass, className),
    style: {
      height: heightStyle,
      width: widthStyle,
    },

    ...props,
  });
};
