import { Card } from "../components";
import { Button } from "../components/Button";
import DeliveryCardLoader from "../internals/DeliveryCardLoader";

const intl = {
  no: {
    REACTIVATE: "Gjenoppta og velg middager",
    DESCRIPTION:
      "Du er alltid velkommen tilbake. Klikk på knappen for å gjenoppta leveransene dine og velge ukens middager.",
    DISH: "rett",
    DISHES: "retter",
  },
  se: {
    REACTIVATE: "Återuppta och välj middagar",
    DESCRIPTION:
      "Du är alltid välkommen tilbaka. Klicka på knappen för att återuppta dina leveranser och välj veckans middagar.",
    DISH: "rätt",
    DISHES: "rätter",
  },
  dk: {
    REACTIVATE: "Genaktivér og vælg retter",
    DESCRIPTION:
      "Du er altid velkommen tilbage. Klik på knappen for at starte leverancer igen og vælg de retter, du vil have leveret ved døren.",
    DISH: "ret",
    DISHES: "retter",
  },
};

const PausedCard = ({ isLoading, children }) => {
  if (isLoading) {
    return <DeliveryCardLoader />;
  }

  return <Card>{children}</Card>;
};

const Action = ({ language = "no", ...props }) => {
  const { REACTIVATE } = intl[language];

  return <Button {...props}>{REACTIVATE}</Button>;
};

PausedCard.Action = Action;

const Description = ({ language = "no", children }) => {
  const { DESCRIPTION } = intl[language];
  if (children) {
    return children;
  }

  return <strong>{DESCRIPTION}</strong>;
};

PausedCard.Description = Description;

export default PausedCard;
