import { motion } from "framer-motion";

const dotVariants = {
  initial: { y: 0 },
  animate: (i: number) => ({
    y: [2, -8, 2],
    transition: {
      duration: 0.5,
      easing: "easeInOut",
      repeat: Infinity,
      repeatDelay: 1,
      delay: i * 0.15,
    },
  }),
};

export const DotsLoader = () => {
  return (
    <div className="inline-flex items-start justify-center gap-1">
      {[0, 1, 2].map((i) => (
        <motion.div
          key={i}
          data-testid={`dot-${i}`}
          className="rounded-full"
          custom={i}
          variants={dotVariants}
          initial="initial"
          animate="animate"
          style={{
            width: "0.3em",
            height: "0.3em",
            backgroundColor: "currentColor",
          }}
        />
      ))}
    </div>
  );
};
