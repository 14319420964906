import * as React from "react";
import { SVGProps } from "react";
const Cooking = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8632_129617)">
      <path
        d="M3.32617 44.1016C5.01045 44.2495 6.56699 45.0604 7.65337 46.3559C8.73975 47.6514 9.26699 49.3255 9.1191 51.0098L15.4697 51.5674M98.5846 52.4661C96.9004 52.3182 95.2263 52.8455 93.9308 53.9319C92.6352 55.0183 91.8243 56.5748 91.6764 58.2591L85.3259 57.7014"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.8835 51.3513L84.2106 70.403C83.7669 75.4558 81.3342 80.1254 77.4476 83.3846C73.561 86.6438 68.5388 88.2255 63.486 87.7818L31.7332 84.9936C26.6804 84.5499 22.0108 82.1172 18.7516 78.2305C15.4925 74.3439 13.9107 69.3218 14.3544 64.269L16.0273 45.2173L85.8835 51.3513Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8904 42.5844C78.5988 42.3643 79.8052 40.8009 79.5851 39.0926C79.365 37.3842 77.8017 36.1777 76.0933 36.3978C74.3849 36.6179 73.1784 38.1813 73.3986 39.8897C73.6187 41.598 75.182 42.8045 76.8904 42.5844Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.7959 37.8099C94.341 37.482 96.1384 35.153 95.8104 32.6078C95.4825 30.0627 93.1535 28.2653 90.6083 28.5933C88.0632 28.9212 86.2658 31.2502 86.5938 33.7953C86.9217 36.3405 89.2507 38.1378 91.7959 37.8099Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5004 20.6956C97.7941 20.2712 100.12 17.2572 99.6957 13.9635C99.2714 10.6699 96.2573 8.34387 92.9637 8.76824C89.67 9.1926 87.344 12.2067 87.7684 15.5003C88.1928 18.7939 91.2068 21.12 94.5004 20.6956Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.7697 19.0167C39.0045 18.1772 38.4775 17.1484 38.2433 16.0369C38.009 14.9254 38.076 13.7715 38.4373 12.6946C38.7986 11.6177 39.4411 10.6568 40.2983 9.91149C41.1555 9.16616 42.1962 8.66339 43.3129 8.45522C44.4296 8.24704 45.5816 8.34102 46.6498 8.72742C47.718 9.11383 48.6635 9.77862 49.3886 10.653C50.1137 11.5274 50.5919 12.5797 50.7739 13.7009C50.9559 14.8222 50.835 15.9717 50.4237 17.0305L39.7697 19.0167Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.0288 23.9467L13.0915 36.7982C21.8735 27.3793 31.7494 20.3503 45.2235 17.8384C58.6977 15.3265 70.4429 18.3248 82.0288 23.9467Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8632_129617">
        <rect width={102} height={102} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Cooking;
