import { createSlice } from "@reduxjs/toolkit";

import { getAgreementId } from "../../graphql/user";
import { CreateOrderInput, CreateOrderResult } from "./types";
import { api, tags } from "../../graphql/api";
import { RootState } from "../../";

export const loyaltyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    asd: builder.query({
      query: () => ({ url: "/" }),
    }),
    createLoyaltyOrder: builder.mutation<CreateOrderResult, CreateOrderInput>({
      query: ({
        week,
        year,
        deliveryDate,
        shippingAddress,
        billingAddress,
        statusId,
        products,
      }) => ({
        url: `/loyalty/createOrder`,
        method: "POST",
        body: {
          AgreementID: getAgreementId(),
          Week: week,
          Year: year,
          DeliveryDate: deliveryDate,
          ShippingAddress: shippingAddress,
          BillingAddress: billingAddress,
          Products: products,
          StatusID: statusId,
        },
      }),
      invalidatesTags: [tags.billing],
    }),
  }),
});

export const loyaltySlice = createSlice({
  name: "loyalty",
  initialState: {
    hasSeenLoyaltyPage: false,
  },
  reducers: {
    setHasSeenLoyaltyPage: (state, action) => {
      state.hasSeenLoyaltyPage = action.payload;
    },
  },
});

export const { useCreateLoyaltyOrderMutation } = loyaltyApi;
export const { setHasSeenLoyaltyPage } = loyaltySlice.actions;

export const selectHasSeenLoyaltyPage = (state: RootState) =>
  state.loyalty.hasSeenLoyaltyPage;

export default loyaltySlice.reducer;
