import { decompressFromBase64, compressToBase64 } from "lz-string";

import { nonNullable } from "@chef/utils/nonNullable";

interface Flag {
  name: string;
  type?: unknown;
  enabled: boolean;
  variant?: {
    name: string;
    enabled: boolean;
  };
  forced?: boolean;
}

const FLAG_DELIMITER = ";";
const VARIANT_DELIMITER = "~";
const FORCED_FLAG_SUFFIX = "!";

export const parseFlagsCookie = (cookie: string): Flag[] => {
  const decompressed = decompressFromBase64(cookie) as string | null;

  if (!decompressed) {
    return [];
  }

  return decompressed
    .split(FLAG_DELIMITER)
    .map((flag) => {
      const [name, value] = flag.split(VARIANT_DELIMITER);

      if (!name) {
        return null;
      }

      const forced = name.endsWith(FORCED_FLAG_SUFFIX);

      let _name = name;

      if (forced) {
        _name = name.slice(0, -1);
      }

      if (!value) {
        return {
          name: _name,
          enabled: true,
          forced,
        };
      }

      return {
        name: _name,
        enabled: true,
        variant: {
          name: value,
          enabled: true,
        },
        forced,
      };
    })
    .filter(nonNullable);
};

export const createFlagsCookie = (flags: Flag[]): string => {
  let str = "";

  for (const flag of flags) {
    let name = flag.name;

    if (flag.forced) {
      name = `${name}${FORCED_FLAG_SUFFIX}`;
    }

    if (flag.variant && flag.variant.name !== "disabled") {
      str += `${name}${VARIANT_DELIMITER}${flag.variant.name}${FLAG_DELIMITER}`;
    } else {
      str += `${flag.name}${FLAG_DELIMITER}`;
    }
  }

  return compressToBase64(str);
};
