import clsx from "clsx";

import { RecipeAndStepsQuery } from "@chef/state-management";

import Ingredient from "./Ingredient";

interface IngredientSectionProps {
  type: "list" | "grid";
  ingredients: RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][number]["ingredientSections"][number]["ingredients"];
}

export const IngredientSection = ({
  ingredients,
  type,
}: IngredientSectionProps) => {
  const sortedIngredients = [...ingredients].sort(
    (a, b) => +a.order - +b.order,
  );

  return (
    <div className="relative">
      <div className={clsx(type === "grid" && "grid grid-cols-3 gap-2 my-6")}>
        {sortedIngredients.map((ingredient) => (
          <Ingredient
            ingredient={ingredient}
            key={ingredient.name}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};
