import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";

interface Nudge {
  seen: boolean;
  week: number;
  year: number;
}

const initialState = {
  nudges: [] as Nudge[],
};

export const nudgeSlice = createSlice({
  name: "nudge",
  initialState,
  reducers: {
    setNudgeSeen: (state, action: PayloadAction<Omit<Nudge, "seen">>) => {
      const idx = state.nudges.findIndex(
        (n) => n.week === action.payload.week && n.year === action.payload.year,
      );

      if (idx === -1) {
        state.nudges.push({ seen: true, ...action.payload });
      } else {
        state.nudges[idx].seen = true;
      }
    },
  },
});

export const selectNudgeIsSeen =
  (week: number, year: number) => (state: RootState) => {
    const nudge = state.nudge.nudges.find(
      (n) => n.week === week && n.year === year,
    );

    return nudge?.seen ?? false;
  };

export const selectNudges = (state: RootState) => state.nudge.nudges;

export const { setNudgeSeen } = nudgeSlice.actions;
