import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext } from "react";

import { Event } from "@chef/events";

interface AnalyticsContextValue {
  analytics: AnalyticsBrowser | undefined;
  queue: Event[];
}

export const AnalyticsContext = createContext<AnalyticsContextValue | null>(
  null,
);

export { TrackingProvider } from "./components/Provider";

export * from "./hooks";
