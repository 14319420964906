import { useBillingQuery, useMeQuery } from "@chef/state-management";

import { Banner } from "@chef/components";
import { useConfirmDialog } from "@chef/hooks";
import { ACCOUNT_STATUSES, BRAND_PATHS, language } from "@chef/constants";

import { noop } from "@chef/utils/noop";
import { AccountReactivationModal } from "@chef/smart-components";

const intl = (
  {
    no: {
      REACTIVATE: "Gjenoppta",
      YOUR_DELIVERIES_ARE_STOPPED: "Dine leveringer er pauset",
    },
    se: {
      REACTIVATE: "Förnya prenumeration",
      YOUR_DELIVERIES_ARE_STOPPED: "Du har pausat din prenumeration",
    },
    dk: {
      REACTIVATE: "Genaktiver",
      YOUR_DELIVERIES_ARE_STOPPED: "Dine leveringer er sat på pause",
    },
  } as const
)[language];

const AccountPausedBanner = () => {
  const { isShowing, handleCancel, handleConfirm, handleShow } =
    useConfirmDialog();

  const { data: isLoggedIn } = useMeQuery();

  const { data: billingQuery } = useBillingQuery(undefined, {
    skip: !isLoggedIn,
  });

  if (billingQuery?.billing?.status !== ACCOUNT_STATUSES.PAUSED) {
    return null;
  }

  return (
    <>
      <AccountReactivationModal
        show={isShowing}
        onClose={handleCancel}
        handleConfirm={handleConfirm}
        redirectUrl={BRAND_PATHS.POST_REACTIVATION_LOCATION}
      />
      <Banner onClick={() => handleShow(noop)} highlighted>
        <span className="mr-2">{intl.YOUR_DELIVERIES_ARE_STOPPED}</span>
        <span className="underline">
          <strong>{intl.REACTIVATE}</strong>
        </span>
      </Banner>
    </>
  );
};

export default AccountPausedBanner;
