import { Currency } from "@chef/components";
import { language } from "@chef/constants";

interface CurrencyProps {
  prefix?: string;
  hideIfZero?: boolean;
  className?: string;
  children: React.ReactNode;
  id?: string;
}

export const CurrencyWrapper = ({
  prefix = "",
  hideIfZero = false,
  className,
  children,
  id,
  ...props
}: CurrencyProps) => {
  return (
    <Currency
      prefix={prefix}
      hideIfZero={hideIfZero}
      className={className}
      language={language}
      id={id}
      {...props}
    >
      {children}
    </Currency>
  );
};
