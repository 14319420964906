import { isWithinInterval } from "date-fns";
import { PRODUCT_TYPES } from "@chef/constants";
import { isEqualStrings } from "../equal";
import { isEmptyArray } from "../array";
import { parseTimeStringFromApi } from "./time";
import { removeDuplicates } from "./removeDuplicates";

/**
 * Find attribute value based on key
 * @param {object} object
 * @param {string} attributeKey
 * @deprecated Use getAttribute from "@chef/state-management" instead
 */
const getAttribute = (object = {}, attributeKey = "") => {
  const { attributes = [] } = object;
  const match =
    attributes.find(({ name = "" }) => isEqualStrings(name, attributeKey)) ||
    {};
  return match.value || "";
};

/**
 * Find product that has the selected variationId
 * @param {array} products
 * @param {string} variationId
 */
const findProductByVariationId = (products = [], variationId = "") =>
  products.find((p) =>
    p.variations.find((v) => isEqualStrings(v.variationId, variationId)),
  ) || {};

/**
 * Find the productType of a product
 * @param {Object} product
 * @returns {String}
 */
const getProductType = (product = {}) => {
  if (product.productData) {
    return (
      product.productData.type ||
      product.productData.productType ||
      product.productData.productTypeName
    );
  }

  return product.type || product.productType || product.productTypeName;
};
/**
 * Find the product with MEALBOX or BUNDLE
 * @param {Object} product
 * @returns {Object}
 */
const isMealboxProduct = (product) =>
  [PRODUCT_TYPES.MEALBOX, PRODUCT_TYPES.BUNDLE].includes(
    getProductType(product),
  );

/**
 * Find the product with VELGVRAK
 * @param {Object} product
 * @returns {Object}
 *  @deprecated Use isMealboxProduct from @chef/helpers instead

 */

const isPickAndMixProduct = (product) =>
  getProductType(product) === PRODUCT_TYPES.VELGVRAK;
/**
 * Find the product with STANDALONE
 * @param {Object} product
 * @returns {Object}
 * @deprecated Use isPickAndMixProduct from @chef/helpers instead
 *
 */

const isStandAloneProduct = (product) =>
  getProductType(product) === PRODUCT_TYPES.STANDALONE;

/**
 * Find the product with LIMITED_QUANTITY
 * @param {Object} product
 * @returns {Object}
 * @deprecated Use isStandAloneProduct from @chef/helpers instead
 *
 */
const isLimitedQuantityProduct = (product) =>
  getProductType(product) === PRODUCT_TYPES.LIMITED_QUANTITY;

/**
 * Find the product with ADDON
 * @param {Object} product
 * @returns {Object}
 * @deprecated Use isLimitedQuantityProduct from @chef/helpers instead
 *
 */
const isAddonProduct = (product) =>
  getProductType(product) === PRODUCT_TYPES.ADDON;
/**
 * Find the product with FINANCIAL
 * @param {Object} product
 * @returns {Object}
 * @deprecated Use isAddonProduct from @chef/helpers instead
 *
 */
const isFinancialProduct = (product) =>
  getProductType(product) === PRODUCT_TYPES.FINANCIAL;

/**
 * Find the default variation for product
 * @param {Array} variations
 * @returns {Object}
 * @deprecated Use isFinancialProduct from @chef/helpers instead
 *
 */
const findDefaultVariation = (variations = []) =>
  variations.find((v) => getAttribute(v, "isDefaultVariation") === "True") ||
  {};

/**
 * @desc    Finds available product variations for given week
 * @param   {object} Object product to check
 * @param   {object} Date date object for week/year to match
 * @returns {object} Product with filtered variations
 */
function findAvailableVariationsForDate(product, currentDate) {
  const newProduct = { ...product };

  // Check all variations for a match
  newProduct.variations = product.variations.filter((variation) => {
    // Get attributes from product
    const startDateStr =
      getAttribute(variation, "start_sales_date") || "1970-01-01 00:00:00.000";
    const endDateStr =
      getAttribute(variation, "end_sales_date") || "2099-12-31 00:00:00.000";

    // Generate date objects
    const startDate = parseTimeStringFromApi(startDateStr);
    const endDate = parseTimeStringFromApi(endDateStr);

    // Check if date is within range
    return isWithinInterval(new Date(currentDate), {
      start: startDate,
      end: endDate,
    });
  });

  return newProduct;
}

/**
 * @desc    Finds available products for given week
 * @param   {array} products - product to check
 * @param   {object} currentDate date object for week/year to match
 * @returns {array} Products with filtered variations
 */
function filterAvailableProductsForDate(products = [], currentDate) {
  return products
    .map((p) => findAvailableVariationsForDate(p, currentDate))
    .filter((p) => p.variations.length > 0);
}

/**
 * @desc    Finds available products for given week
 * @param   {array} categories - categories to check
 * @param   {object} currentDate date object for week/year to match
 * @returns {array} Products with filtered variations
 */
function filterAvailableProductsForDateByCategory(
  categories = [],
  currentDate,
) {
  return categories
    .map((category) => {
      const { products = [] } = category;
      if (isEmptyArray(products)) {
        return category;
      }

      const newCategory = { ...category };

      newCategory.products = products
        .map((p) => findAvailableVariationsForDate(p, currentDate))
        .filter((p) => p.variations.length > 0);

      return newCategory;
    })
    .filter((c) => c.products.length > 0);
}

function findAvailableVariationsForPortions(
  product,
  portions,
  includePlusVariations = false,
) {
  const newProduct = { ...product };

  // Check all variations for a match
  newProduct.variations = product.variations.filter((variation) => {
    const variationPortion = +getAttribute(variation, "Portions");
    if (variationPortion === 1) {
      return +portions === 2;
    }

    if (includePlusVariations) {
      return (
        +portions === variationPortion || +`${portions}1` === variationPortion
      );
    }

    return +portions === variationPortion;
  });

  return newProduct;
}

function filterAvailableProductsByPortions(
  products = [],
  portions,
  includePlusVariations = false,
) {
  if (!portions) {
    return products;
  }

  return products
    .map((p) =>
      findAvailableVariationsForPortions(p, portions, includePlusVariations),
    )
    .filter((p) => p.variations.length > 0);
}

const sortProductsByList = (products = [], list = [], key = "productId") =>
  products.sort((a, b) => {
    const aProduct = list.find((l) => a[key] === l[key]) || { order: Infinity };
    const bProduct = list.find((l) => b[key] === l[key]) || { order: Infinity };

    const aOrder = aProduct.order || Infinity;
    const bOrder = bProduct.order || Infinity;

    return aOrder - bOrder;
  });

/**
 * @desc Sorts products array based on order basket
 * @param {array} products - products to be sorted
 * @param {boolean} sort - if we should sort the products or not
 * @param {array} orderProducts - products that should be listed on top
 */
function sortBySelectedProducts(products, sort = false, orderProducts = []) {
  if (!sort) {
    return products;
  }

  const sortProducts = [...orderProducts].reverse();

  return products.sort((a, b) => {
    const bp = sortProducts.findIndex(
      ({
        productData: { productId: productDataProductId = "" } = {},
        productId = "",
      }) => productId === b.productId || productDataProductId === b.productId,
    );
    const ap = sortProducts.findIndex(
      ({
        productData: { productId: productDataProductId = "" } = {},
        productId = "",
      }) => productId === a.productId || productDataProductId === a.productId,
    );

    return bp - ap;
  });
}

const findProductByVariationAttribute = (
  products = [],
  key = "slug",
  value = "",
) =>
  products.find(({ variations = [] }) =>
    variations.find((v) => getAttribute(v, key) === value),
  );

function findProductVariation(product = {}, variationId) {
  const { variations = [] } = product;
  return variations.find((v) => isEqualStrings(v.variationId, variationId));
}

function getProductFeaturedImage(product = {}, size = "THUMBNAIL") {
  const { images = [] } = product;
  const featured = images.find((i) => i.isFeatured) || {};
  const match = featured.urls && featured.urls.find((u) => u.size === size);
  return (match && match.url) || "";
}

/**
 * total = total cost after discount; discount = amount of discount you get (0 if no discount)
 * @param {*} basket
 * @param {*} param1
 * @returns
 */
const getBasketFinances = (
  basket = [],
  { week, year, discount, preselectedProducts },
) => {
  const finances = {
    addon: 0,
    pickAndMix: 0,
    financial: 0,
    total: 0,
    discount: 0,
  };

  // find the mealbox data in the basket (if any)
  const mealboxInBasket = basket.find(isMealboxProduct);
  let preselectedMealboxProductId = mealboxInBasket?.productData?.productId;

  // if we don't have a mealbox in the basket; use the financial product
  if (!mealboxInBasket) {
    const financialInBasket = basket.find(isFinancialProduct);
    const variationFromFinancial =
      financialInBasket?.productData?.variations?.find(
        (v) => v.variationId === financialInBasket.variationId,
      );
    preselectedMealboxProductId = getAttribute(
      variationFromFinancial,
      "preselected_for_mealbox_product_id",
    );
  }

  // calculate sums
  for (const item of basket) {
    let variation = item.productData.variations.find((v) =>
      isEqualStrings(v.variationId, item.variationId),
    );
    if (!variation) {
      continue;
    }

    // if the product is preselected for this week, it costs nothing
    if (
      preselectedProducts.some(
        (pp) =>
          // the preselectedProducts list depends on the mealbox selected
          isEqualStrings(pp.productId, preselectedMealboxProductId) &&
          pp.preselectedProducts.some(
            (p) =>
              isEqualStrings(p.productId, item.productData.productId) &&
              +p.week === +week &&
              +p.year === +year,
          ),
      )
    ) {
      continue;
    }

    const flexiblePrice = variation.flexiblePriceRange?.find(
      (f) => +f.week === +week && +f.year === +year,
    );
    const price =
      (+variation.campaignPrice ||
        +(flexiblePrice?.flexiblePrice || 0) ||
        +variation.flexiblePrice ||
        +variation.finalPrice ||
        0) * item.quantity;
    finances.total += price;

    if (isFinancialProduct(item)) {
      finances.financial += price;
    } else if (isPickAndMixProduct(item)) {
      finances.pickAndMix += price;
    } else if (
      isAddonProduct(item) ||
      isStandAloneProduct(item) ||
      isLimitedQuantityProduct(item)
    ) {
      finances.addon += price;
    }
  }

  // apply discount
  if (discount && discount.sum) {
    let discountAmount = 0;
    if (discount.type === 1) {
      discountAmount =
        finances.total - (finances.total * (100 - discount.sum)) / 100;
    } else if (discount.type === 2) {
      discountAmount = discount.sum;
    }

    // in case the discountAmount is higher than the total
    finances.discount = Math.min(discountAmount, finances.total);
    finances.total = Math.max(0, finances.total - discountAmount);
  }

  return finances;
};

const getCostOfProductInBasket = (productId, basket, financeData) => {
  const mealbox = basket.find(isMealboxProduct);
  const financial = basket.find(isFinancialProduct);
  const product = basket.find((b) =>
    isEqualStrings(b.productData.productId, productId),
  );

  const finances = getBasketFinances(
    [mealbox, financial, product].filter(Boolean),
    financeData,
  );
  return finances.total - finances.financial;
};

function findVariationByMealsAndPortions(variations = [], portions, meals) {
  return variations.find((v) => {
    const portionOption = parseInt(getAttribute(v, "Portions"), 10);
    const mealOption = parseInt(getAttribute(v, "Meals"), 10);
    return (
      portionOption === parseInt(portions, 10) &&
      parseInt(meals, 10) === mealOption
    );
  });
}

function findPortionsByVariations(variations = [], language = "no") {
  const no = {
    1: "1 person",
    2: "2 personer",
    4: "4 personer",
    6: "6 personer",
  };

  const se = {
    1: "1 person",
    2: "2 personer",
    4: "4 personer",
    6: "6 personer",
  };

  const dk = {
    1: "1 pers",
    2: "2 pers",
    3: "3 pers",
    4: "4 pers",
    6: "6 pers",
  };

  const portionDescriptionAllLanguages = { no, se, dk };

  return removeDuplicates(
    variations.map((v) => parseInt(getAttribute(v, "Portions"), 10)),
  )
    .sort((a, b) => a - b)
    .map((p) => ({
      label: `${p}`,
      description: portionDescriptionAllLanguages[language][p],
      value: p,
    }));
}

function findMealsByVariations(variations = [], portions, language = "no") {
  const no = "middager";

  const se = "middagar";

  const dk = "retter";

  const dinnersAllLanguages = { no, se, dk };
  return removeDuplicates(
    variations.filter((v) => +getAttribute(v, "Portions") === +portions),
  )
    .map((v) => +getAttribute(v, "Meals"))
    .filter((f) => f)
    .sort((a, b) => a - b)
    .map((p) => ({
      label: `${p}`,
      description: `${p} ${dinnersAllLanguages[language]}`,
      value: p,
    }));
}

function findCheapestVariation(product = {}) {
  const { variations = [] } = product;
  if (variations.length < 1) {
    return null;
  }
  const match = variations.reduce(
    (lowest, variation) =>
      parseInt(variation.finalPrice, 10) < parseInt(lowest.finalPrice, 10)
        ? variation
        : lowest,
    variations[0],
  );
  return match;
}

export {
  getAttribute,
  findProductByVariationId,
  getProductType,
  isMealboxProduct,
  isPickAndMixProduct,
  isStandAloneProduct,
  isLimitedQuantityProduct,
  isAddonProduct,
  isFinancialProduct,
  findDefaultVariation,
  findAvailableVariationsForDate,
  filterAvailableProductsForDate,
  filterAvailableProductsForDateByCategory,
  findAvailableVariationsForPortions,
  filterAvailableProductsByPortions,
  sortProductsByList,
  sortBySelectedProducts,
  findProductByVariationAttribute,
  findProductVariation,
  getProductFeaturedImage,
  getBasketFinances,
  getCostOfProductInBasket,
  findVariationByMealsAndPortions,
  findPortionsByVariations,
  findMealsByVariations,
  findCheapestVariation,
};
