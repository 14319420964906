import clsx from "clsx";

interface GradientProps {
  height?: "short" | "medium" | "long";
  background?: "white" | "background";
  children: React.ReactNode;
  isExpanded?: boolean;
  className?: string;
}

export const Gradient = ({
  height,
  background,
  isExpanded,
  children,
  className,
}: GradientProps) => {
  return (
    <div
      className={clsx(
        !isExpanded && {
          "max-h-18": height === "short",
          "max-h-32": height === "medium",
          "max-h-64": height === "long",
        },
        className,
        "overflow-hidden relative",
      )}
    >
      {children}

      {!isExpanded && (
        <div
          className={clsx(
            {
              "from-white": background === "white",
              "from-background": background === "background",
            },
            "absolute bottom-0 left-0 right-0 h-18 bg-gradient-to-t print:hidden",
          )}
        />
      )}
    </div>
  );
};
