import clsx from "clsx";
import { SVGProps } from "react";

import { Button } from "../Button";

type ButtonProps = React.ComponentProps<typeof Button>;
interface ButtonCircleProps extends ButtonProps {
  Icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement | null;
  small?: boolean;
  tiny?: boolean;
  className?: string;
  id?: string;
}

export const ButtonCircle = ({
  Icon = () => null,
  small,
  tiny,
  className,
  id,
  ...props
}: ButtonCircleProps) => {
  const baseStyles = "!px-0 justify-center !rounded-full";

  const sizeStyles = {
    default: "!w-10 !h-10",
    small: "!w-9 !h-9",
    tiny: "!w-9 !h-9",
  };

  let size: "default" | "small" | "tiny" = "default";

  if (small) {
    size = "small";
  }

  if (tiny) {
    size = "tiny";
  }

  return (
    <Button
      className={clsx(baseStyles, sizeStyles[size], className)}
      id={id}
      {...props}
    >
      <Icon />
    </Button>
  );
};
