import Script from "next/script";

import { TRUSTPILOT } from "@chef/constants";

export const TrustpilotWidget = () => {
  return (
    <div>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <div
        className="w-56 trustpilot-widget"
        data-locale={TRUSTPILOT.LOCALE}
        data-template-id={TRUSTPILOT.TEMPLATE_ID}
        data-businessunit-id={TRUSTPILOT.BUSINESS_UNIT_ID}
        data-style-height="100%"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://dk.trustpilot.com/review/www.retnemt.dk"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;
        </a>
      </div>
    </div>
  );
};
