import clsx from "clsx";

import { capitalize } from "@chef/utils/string";
import { sendIntercomMessage } from "@chef/helpers";

import { intl } from "./DoNotDeliverDisclaimer.Intl";

interface Props {
  className?: string;
  id?: string;
  cannotDeliver: boolean;
}

export const DoNotDeliverDisclaimer = ({
  className,
  id,
  cannotDeliver,
}: Props) => {
  if (!cannotDeliver) {
    return null;
  }

  const sendChat = () =>
    sendIntercomMessage({
      prepopulatedMessage: "",
      subject: intl.REGISTER_ADDRESS,
    });
  return (
    <div
      className={clsx("gap-1 p-4 col-span-full bg-errorBG", className)}
      id={id}
    >
      <p className="text-base">
        <strong>{intl.WE_CANNOT_DELIVER_HERE}</strong>
      </p>
      <p className="text-sm">
        <button
          className="pr-1 underline text-primary"
          onClick={sendChat}
          type="button"
        >
          {capitalize(intl.CONTACT_CUSTOMER_SERVICE)}
        </button>
        {intl.FOR_MORE_INFORMATION}
      </p>
    </div>
  );
};
