import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import type { JSONObject } from "@chef/types/utils";

import {
  fieldsToPersistInLocalState,
  fieldsToPersistInSessionState,
  saveLocalState,
  saveSessionState,
} from "../util";

const listener = (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    predicate: () => true,
    effect: (_, listenerApi) => {
      const fullStore = listenerApi.getState() as JSONObject;
      const previousStore = listenerApi.getOriginalState() as JSONObject;

      const currentSessionValues: JSONObject = {};
      const currentLocalValues: JSONObject = {};

      const previousSessionValues: JSONObject = {};
      const previousLocalValues: JSONObject = {};

      for (const field of fieldsToPersistInSessionState) {
        currentSessionValues[field] = fullStore[field];
        previousSessionValues[field] = previousStore[field];
      }

      for (const field of fieldsToPersistInLocalState) {
        currentLocalValues[field] = fullStore[field];
        previousLocalValues[field] = previousStore[field];
      }

      // only save if the values have changed
      if (
        JSON.stringify(currentSessionValues) !==
        JSON.stringify(previousSessionValues)
      ) {
        saveSessionState(currentSessionValues);
      }

      if (
        JSON.stringify(currentLocalValues) !==
        JSON.stringify(previousLocalValues)
      ) {
        saveLocalState(currentLocalValues);
      }
    },
  });

export default listener;
