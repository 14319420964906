/**
 * @module array
 * @category utils
 */

import Debug from "debug";

import type { Primitive } from "@chef/types/utils";

const debug = Debug("array");

/**
 * Check to test if array is empty -- will return null if it isn't an array
 * @example isEmptyArray([]); // returns true
 * @example isEmptyArray(["foo", "bar"]); // returns false
 */
export const isEmptyArray = <T>(array: any) => {
  return !isNotEmptyArray<T>(array);
};

/**
 * Check to test if array is not empty -- will return null if it isn't an array
 * @example isNotEmptyArray(["foo", "bar"]); // returns true
 * @example isNotEmptyArray([]); // returns false
 */
export const isNotEmptyArray = <T>(array: any): array is T[] => {
  if (array && array.constructor === Array) {
    return array.length > 0;
  }
  if (process.env["NODE_ENV"] !== "production") {
    // eslint-disable-next-line
    debug(
      `[isNotEmptyArray]: The array being verified is not an array: ${array}\n` +
        new Error().stack,
    );
  }
  return false;
};
/**
 * Flatten a multidimensional array
 * @param {Array} array array to flatten
 * @returns {Array}
 * @example flatten(["foo", "bar"]); // returns ["foo", "bar"]
 * @example flatten(["foo", ["bar", ["foobar"]]]); // returns ["foo", "bar", "foobar"]
 */
export const flatten = <T>(list: any[]): T[] =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

/**
 * Remove duplicates in array
 * @param {Array} array array to remove duplicates
 * @returns {Array}
 * @example unique(["foo", "bar", "foo"]); // returns ["foo", "bar"]
 * @example unique(["foo", "bar", "foobar"]); // returns ["foo", "bar", "foobar"]
 */

export const unique = <T>(list: T[]): T[] =>
  list?.filter((elm, pos, arr) => arr.indexOf(elm) === pos);

/**
 * Sum all values in array
 * @param {Array} array array to sum
 * @returns {Number} sum of all values in array
 * @example sum([1, 2, 3]); // returns 6
 * @example sum([3, 17, 6]); // returns 26
 */
export const sum = (list: number[]) => list.reduce((a, b) => a + b, 0);

/**
 * Sum all values in array based on property
 * @param {Array} array array to sum
 * @param {String} property property to use on list elements
 * @returns {Number} sum of all values in array
 */
export const sumByProperty = <T>(list: T[], property: keyof T): number =>
  list.reduce((a, b) => {
    const value = b[property];

    if (typeof value === "number") {
      return a + value;
    } else {
      return a;
    }
  }, 0);

/**
 * Sum all values in array based on function
 * @param {Array} array array to sum
 * @param {Function} fn function to use on list elements
 * @returns {Number} sum of all values in array
 * @example sumByFn([{ foo: 1 }, { foo: 2 }, { foo: 3 }], (item) => item.foo); // returns 6
 */
export const sumByFn = <T>(list: T[], fn: (item: T) => number): number =>
  list.reduce((a, b) => a + fn(b), 0);

/**
 * Find entries in array 1 that are not in array 2, and entries in array 2 that are not in array 1
 * @param list1 Array 1
 * @param list2 Array 2
 * @param fn Function to compare entries
 * @returns Array of differences. If no differences, returns an empty array
 */
export const difference = <T>(
  list1: T[],
  list2: T[],
  fn: (a: T, b: T) => boolean = (a, b) => a === b,
): T[] =>
  list1
    .filter((a) => !list2.some((b) => fn(a, b)))
    .concat(list2.filter((b) => !list1.some((a) => fn(a, b))));

export const equal = <T extends Primitive>(a: T[], b: T[]) => {
  return a.length === b.length && a.every((id) => b.includes(id));
};
