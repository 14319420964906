import clsx from "clsx";
import { Listbox } from "@headlessui/react";

import { Check, ChevronDown } from "@chef/icons/small";
import SubText from "../../internals/SubText";

interface DropdownProps<T extends string | number> {
  onChange: (newValue: T) => void;
  onBlur?: () => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  native?: boolean;
  options: Array<{
    value: T;
    label: string | JSX.Element;
    disabled?: boolean;
  }>;
  id?: string;
  value?: T;
  error?: string;
  className?: string;
  extraAction?: {
    label: string | JSX.Element;
    onClick: () => void;
  };
}
export const Dropdown = <T extends string | number>({
  onChange,
  label = "",
  placeholder = "",
  disabled = false,
  native = false,
  options,
  value,
  error,
  className,
  extraAction,
  id,
  ...props
}: DropdownProps<T>) => {
  const selectedItem = options.find((option) => option.value === value);

  if (native) {
    return (
      <div
        className={clsx(
          "px-3.5 border-1.5 bg-white rounded a11y-focus-within:focus-ring",
          disabled ? "border-grey-2" : "border-grey-1 hover:bg-background",
        )}
      >
        {label && (
          <label htmlFor="nativeSelect" className="sr-only">
            {label}
          </label>
        )}
        <select
          id="nativeSelect"
          className={clsx("h-10 focus:outline-none w-full bg-transparent")}
          disabled={disabled}
        >
          {options.map((item) => (
            <option
              key={item.value}
              className="bg-white"
              value={item.value}
              selected={item.value === value}
              disabled={item.disabled}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <>
      <Listbox
        as="div"
        value={value}
        className={className}
        onChange={onChange}
        disabled={disabled}
        {...props}
      >
        {label && <Listbox.Label className="sr-only">{label}</Listbox.Label>}
        <div className="relative" id={id}>
          <Listbox.Button
            className={clsx(
              "relative flex border-solid h-10 items-center focus:outline-none rounded w-full bg-white a11y-focus:focus-ring",
              disabled
                ? "border-grey-2 cursor-default"
                : "border-grey-1 hover:bg-background",
              error ? "border-error border-2" : "border-1.5",
            )}
          >
            <div
              className={clsx(
                "flex justify-between items-center w-full truncate mx-3.5 ",
                disabled && "text-grey-1",
              )}
            >
              {selectedItem ? (
                selectedItem.label
              ) : (
                <div className="text-grey-1">{placeholder}</div>
              )}
              <ChevronDown className="text-xs" />
            </div>
          </Listbox.Button>

          {error && <SubText error={error} />}

          <Listbox.Options
            className={clsx(
              "absolute left-0 top-0 w-full bg-white shadow border-grey-1 border rounded z-50 max-h-144 overflow-y-scroll",
            )}
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                className="first:mt-2 last:mb-2"
              >
                {({ selected, active, disabled }) => (
                  <div
                    className={clsx(
                      (active || selected) && "bg-background",
                      "h-10 flex items-center justify-between relative px-4",
                    )}
                  >
                    <div className={clsx(disabled && "text-grey-1")}>
                      {selected && <Check className="mr-2 text-xs" />}
                      {option.label}
                    </div>
                  </div>
                )}
              </Listbox.Option>
            ))}
            {extraAction && (
              <div className="mt-2 border-t-1.5 border-grey-3 sticky bottom-0 bg-background shadow-fab">
                <Listbox.Button
                  className="w-full h-10 mt-2 underline hover:bg-background"
                  onClick={extraAction.onClick}
                >
                  {extraAction.label}
                </Listbox.Button>
              </div>
            )}
          </Listbox.Options>
        </div>
      </Listbox>
      {extraAction && (
        <button className="sr-only" onClick={extraAction.onClick}>
          {extraAction.label}
        </button>
      )}
    </>
  );
};
