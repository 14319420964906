import { ExitDoor } from "@chef/icons/symbol";
import { Language } from "../../types";
import { Button, Card } from "../components";

const intl = {
  se: {
    CHANGES_NOT_SAVED: "Dina ändringar är inte sparade",
    CHANGES_NOT_SAVED_DETAILED:
      "Du har gjort ändringar som inte är sparade, lämnar du sidan går dessa förlorade.",
    LEAVE_WITHOUT_SAVING: "Lämna utan att spara",
    STAY: "Stanna kvar",
  },
  no: {
    CHANGES_NOT_SAVED: "Dine endringer er ikke lagret",
    CHANGES_NOT_SAVED_DETAILED:
      "Du har gjort endringer som ikke er lagret. Hvis du forlater siden, vil disse gå tapt. Klikk 'Gå videre' og bekreft for å lagre.",
    LEAVE_WITHOUT_SAVING: "Gå tilbake uten å lagre",
    STAY: "Oppholde seg",
  },
  dk: {
    CHANGES_NOT_SAVED: "Dine ændringer er ikke blevet gemt",
    CHANGES_NOT_SAVED_DETAILED:
      "Du har foretaget ændringer, der ikke er gemt. Hvis du forlader siden, vil de gå tabt.",
    LEAVE_WITHOUT_SAVING: "Gå tilbage uden at gemme",
    STAY: "Bliv",
  },
};

interface WeekEditorExitWarningProps {
  onExit: () => void;
  onCancel: () => void;
  language?: Language;
}

const WeekEditorExitWarning = ({
  onExit,
  onCancel,
  language = "se",
}: WeekEditorExitWarningProps) => {
  const {
    CHANGES_NOT_SAVED,
    CHANGES_NOT_SAVED_DETAILED,
    LEAVE_WITHOUT_SAVING,
    STAY,
  } = intl[language];
  return (
    <Card className="relative w-full max-w-lg pt-12 mx-auto overflow-y-auto text-base text-center md:p-20 md:mt-28 lg:max-w-1/2 md:max-w-2xl ">
      <div className="xl:m-10">
        <div className="text-center ">
          <ExitDoor className="w-36 h-36 md:w-20 md:h-20" />
        </div>
        <h2 className="my-6">
          <strong>{CHANGES_NOT_SAVED}</strong>
        </h2>
        <p className="mt-2 text-base">{CHANGES_NOT_SAVED_DETAILED}</p>
        <div className="mt-8">
          <Button onClick={onExit} primary>
            <div className="w-64">{LEAVE_WITHOUT_SAVING}</div>
          </Button>
        </div>
        <div className="mt-4">
          <Button outlined onClick={onCancel}>
            <div className="w-64">{STAY}</div>
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default WeekEditorExitWarning;
