import * as React from "react";
import { SVGProps } from "react";
const Edit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9457 4.10864H3.98794C3.46071 4.10864 2.95507 4.31809 2.58225 4.6909C2.20944 5.06371 2 5.56935 2 6.09658V20.0122C2 20.5394 2.20944 21.045 2.58225 21.4179C2.95507 21.7907 3.46071 22.0001 3.98794 22.0001H17.9035C18.4308 22.0001 18.9364 21.7907 19.3092 21.4179C19.682 21.045 19.8915 20.5394 19.8915 20.0122V13.0544"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4006 2.61757C18.796 2.22215 19.3323 2 19.8915 2C20.4507 2 20.987 2.22215 21.3825 2.61757C21.7779 3.013 22 3.54931 22 4.10853C22 4.66775 21.7779 5.20406 21.3825 5.59949L11.9397 15.0422L7.96387 16.0362L8.95784 12.0603L18.4006 2.61757Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Edit;
