import { BRAND_NAME } from "./misc";

import dishCount from "../data/dish-count.json";

type GetDishCountArgs =
  | {
      week: number;
      year: number;
      portions?: number;
    }
  | {
      week?: never;
      year?: never;
      portions?: number;
    };

export const getDishCount = (args?: GetDishCountArgs): number => {
  const data = dishCount.data[BRAND_NAME];

  let entry = data[0];

  const { week, year, portions } = args || {};

  if (week && year) {
    const e = data.find((d) => d.week === week && d.year === year);

    if (!e) {
      console.error(`Dish count not found for week ${week} and year ${year}`);
      return 0;
    }

    entry = e;
  }

  if (portions) {
    const p = portions.toString();
    const e = entry.count as Record<string, number>;
    const c = e[p];

    if (!c) {
      console.error(`Dish count not found for portions ${portions}`);
      return 0;
    }

    return c;
  }

  return entry.count.all;
};
