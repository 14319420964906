import { BRAND_NAME, VISIBILITY } from "@chef/constants";
import nav from "@chef/constants/nav";
import { useMeQuery } from "@chef/state-management";

export const isActivePath = (
  current: string,
  target: string,
  targets: string[],
) => {
  // Specific edge-case for GL, where the menu path leads to /matkasse/favorittkassen
  // but the current path can be /matkasse/..., which should still be considered active
  // as well as another path exists (/matkasse) which shouldn't be considered active here
  // We solve this by overriding the current path to /matkasse/favorittkassen
  if (BRAND_NAME === "GL" && /\/matkasse\/.+/.test(current)) {
    current = "/matkasse/favorittkassen";
  }

  // If the target path includes the current path, it is considered active
  // If any other paths are also active, the current path is only considered active if it is the most specific one
  // Specificity is determined by the number of slashes in the path

  const activePaths = targets.filter((path) => current.includes(path));

  if (activePaths.length === 0) {
    return false;
  }

  if (activePaths.length === 1) {
    return activePaths[0] === target;
  }

  let s = 0;
  let p = "";

  for (const path of activePaths) {
    const specificity = path.split("/").length;

    if (specificity > s) {
      s = specificity;
      p = path;
    }
  }

  return p === target;
};

export const useGetNavItems = () => {
  const { HEADER, DRAWER } = nav.data;

  const { data: isLoggedIn } = useMeQuery();

  const visibilityType = isLoggedIn ? VISIBILITY.PRIVATE : VISIBILITY.PUBLIC;

  const headerItems = HEADER.filter(
    ({ visibility }) => visibility & visibilityType,
  );

  const drawerItems = DRAWER.filter(
    ({ visibility }) => visibility & visibilityType,
  ) as {
    label: string;
    href: string;
    visibility: number;
    category?: string;
    iconHref?: string;
    empasize?: boolean;
    campaignText?: string;
  }[];

  return {
    headerItems,
    drawerItems,
  };
};
