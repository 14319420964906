import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { language } from "@chef/constants";
import { showNotification } from "../features";
import { recipeApi } from "../features/recipe";

const intl = (
  {
    no: {
      SUCCESS: "Takk for din tilbakemelding!",
      ERROR: "Noe gikk galt, prøv igjen senere",
    },

    se: {
      SUCCESS: "Tack för din feedback!",
      ERROR: "Något gick fel, försök igen senare",
    },

    dk: {
      SUCCESS: "Tak for din tilbagemelding!",
      ERROR: "Noget gik galt, prøv igen senere",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      recipeApi.endpoints.rateAndCommentRecipe.matchFulfilled,
      recipeApi.endpoints.rateAndCommentRecipe.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showNotification({
          message: intl.ERROR,
          type: "error",
        });
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.SUCCESS,
        });
      }
    },
  });
};
