import * as React from "react";
import { SVGProps } from "react";
const User = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 25.0015C9.92509 22.4919 13.0052 20.8477 16.5 20.8477C19.9652 20.8477 23.0749 22.4919 25 25.0015"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1683 12.5385C21.1683 15.0235 19.1006 17.0769 16.5016 17.0769C13.9026 17.0769 11.835 15.0235 11.835 12.5385C11.835 10.0534 13.9026 8 16.5016 8C19.1006 8 21.1683 10.0534 21.1683 12.5385Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);
export default User;
