import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface IDatadog {
  recording: boolean;
}

const initialState = {
  recording: false,
} as IDatadog;

export const datadogSlice = createSlice({
  name: "datadog",
  initialState,
  reducers: {
    startRecording: (state) => {
      state.recording = true;
    },
    stopRecording: (state) => {
      state.recording = false;
    },
  },
});

export const { startRecording, stopRecording } = datadogSlice.actions;

export const selectIsRecording = (state: RootState) => state.datadog.recording;
