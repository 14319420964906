import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { createAndSendEvent } from "@chef/events";
import { invariant } from "@chef/utils/invariant";

import { setStandaloneProductModal } from "../features/modal";

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setStandaloneProductModal,
    effect: async (action) => {
      const variation = action.payload.productByCategoryVariation;

      invariant(variation, "No variation found");

      const productId = variation.product.productId;
      const category = variation.product.productTypeName;
      const name = variation.product.name;
      const productTypeId = variation.product.productTypeId;
      const variant = variation.name;
      const price = variation.finalPrice;

      createAndSendEvent("Product Clicked", {
        affiliation: "Frontend process",
        category,
        name,
        product_id: productId,
        variant_id: variation.variationId,
        category_id: productTypeId,
        variant,
        quantity: 1,
        price,
      });
    },
  });
