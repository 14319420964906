import clsx from "clsx";
import { HTMLAttributes } from "react";

interface ChatBoxProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  noPadding?: boolean;
  background?: "information" | "highlight" | "error" | "grey-3";
}

export const ChatBox = ({
  noPadding = false,
  background = "information",
  className,
  children,
  id,
  ...props
}: ChatBoxProps) => {
  return (
    <div
      className={clsx(
        {
          "bg-informationBG": background === "information",
          "bg-highlightBG": background === "highlight",
          "bg-errorBG": background === "error",
          "bg-grey-3": background === "grey-3",
        },
        !noPadding && "p-4",
        "triangle rounded w-full mt-6 text-center relative",
        className,
      )}
      id={id}
      {...props}
    >
      {children}
    </div>
  );
};
