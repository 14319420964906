import * as React from "react";
import { SVGProps } from "react";
const Chat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6896 21.5045C15.1719 23.7547 13.2012 25.1453 10.5095 24.9879C11.3894 24.5886 12.2312 21.9414 12.217 21.333C8.76886 21.5116 4.18714 19.4328 4.00453 15.0169C3.82904 10.7936 8.77123 7.27968 15.243 7.0166C21.7148 6.75353 27.627 9.6403 27.9827 14.0374C28.2742 17.648 24.84 20.6214 21.1665 21.333"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Chat;
