import clsx from "clsx";

import { BRAND_NAME } from "@chef/constants";
import { AdamsCoin, Coin } from "@chef/icons/small";

interface BrandSpecificCoinProps {
  className?: string;
}

export const BrandSpecificCoin = ({ className }: BrandSpecificCoinProps) => (
  <>
    {BRAND_NAME === "AMK" ? (
      <AdamsCoin className={clsx("z-10 w-8 h-8", className)} />
    ) : (
      <Coin className={clsx("z-10 w-6 h-6", className)} />
    )}
  </>
);
