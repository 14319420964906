import * as React from "react";
import { SVGProps } from "react";
const Nudge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_29468_293088)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8197 7.77316L13.6034 7.48318C13.3694 7.16779 13.3632 6.74045 13.5945 6.41919L13.8055 6.12661C14.1274 5.67929 13.9783 5.05651 13.4829 4.79522L13.1611 4.62588C12.8099 4.44244 12.6167 4.05791 12.6841 3.67055L12.7453 3.31777C12.8392 2.77849 12.4264 2.27918 11.8669 2.25637L11.5008 2.24226C11.1029 2.22486 10.7572 1.96098 10.6464 1.58491L10.5436 1.24083C10.3886 0.712836 9.79716 0.440254 9.28231 0.658273L8.94556 0.798915C8.57952 0.953668 8.15229 0.862181 7.88562 0.569607L7.64272 0.302905C7.27045 -0.10444 6.61807 -0.101853 6.24939 0.314194L6.00913 0.583483C5.74583 0.879584 5.32123 0.976717 4.95183 0.827373L4.61244 0.690023C4.09471 0.480001 3.50882 0.764107 3.35977 1.2921L3.26304 1.63947C3.15767 2.01813 2.81804 2.28742 2.41936 2.30741L2.05333 2.3281C1.4948 2.35938 1.0906 2.8641 1.19261 3.40338L1.26006 3.75663C1.3335 4.14069 1.14581 4.52805 0.797294 4.71737L0.478305 4.89282C-0.0101399 5.15952 -0.151033 5.78489 0.179717 6.2261L0.396697 6.51609C0.630719 6.83147 0.636959 7.25951 0.405578 7.58007L0.194599 7.87265C-0.127271 8.32068 0.021783 8.94275 0.516468 9.20404L0.839058 9.37338C1.19021 9.55682 1.38343 9.94135 1.31598 10.3287L1.25478 10.6815C1.16093 11.2208 1.57376 11.7201 2.13326 11.7429L2.49857 11.7575C2.89725 11.7749 3.24216 12.0381 3.35377 12.4141L3.4565 12.7582C3.61155 13.2862 4.20296 13.5588 4.71781 13.3408L5.05456 13.2001C5.4206 13.0454 5.84783 13.1368 6.1145 13.4294L6.3574 13.6968C6.73232 14.1042 7.3847 14.1009 7.75073 13.6848L7.99099 13.4155C8.2543 13.1201 8.67889 13.0223 9.04757 13.1717L9.38768 13.3097C9.90541 13.519 10.4913 13.2352 10.6404 12.7069L10.7371 12.3596C10.8425 11.9809 11.1821 11.7116 11.58 11.6916L11.9461 11.6716C12.5053 11.6396 12.9095 11.1349 12.8068 10.5956L12.7393 10.2429C12.6666 9.8581 12.8536 9.47074 13.2021 9.28142L13.5211 9.10667C14.0103 8.84256 14.1512 8.21743 13.8197 7.77269V7.77316Z"
        fill="#FFCB2E"
      />
      <g clipPath="url(#clip1_29468_293088)">
        <path
          d="M6.25 5C6.25003 4.70333 6.16207 4.4133 5.99727 4.16661C5.83246 3.91992 5.5982 3.72765 5.32411 3.6141C5.05003 3.50054 4.74842 3.47082 4.45744 3.52869C4.16646 3.58655 3.89918 3.7294 3.68938 3.93917C3.47959 4.14895 3.33672 4.41622 3.27883 4.7072C3.22094 4.99817 3.25064 5.29978 3.36417 5.57387C3.4777 5.84797 3.66996 6.08225 3.91663 6.24707C4.16331 6.4119 4.45332 6.49988 4.75 6.49988C5.14766 6.49942 5.5289 6.34125 5.81011 6.06007C6.09131 5.77889 6.2495 5.39766 6.25 5ZM4 5C3.99998 4.85166 4.04394 4.70665 4.12634 4.58329C4.20873 4.45994 4.32586 4.36379 4.4629 4.307C4.59994 4.25022 4.75075 4.23535 4.89624 4.26427C5.04174 4.2932 5.17538 4.36462 5.28029 4.4695C5.38519 4.57439 5.45663 4.70803 5.48558 4.85351C5.51453 4.999 5.49968 5.14981 5.44292 5.28686C5.38616 5.42391 5.29003 5.54106 5.16669 5.62347C5.04335 5.70589 4.89834 5.74988 4.75 5.74988C4.55118 5.74965 4.36057 5.67057 4.21997 5.53C4.07937 5.38942 4.00027 5.19882 4 5ZM9.25 7.54975C8.95333 7.54975 8.66332 7.63773 8.41665 7.80255C8.16997 7.96737 7.97771 8.20164 7.86418 8.47573C7.75065 8.74982 7.72095 9.05142 7.77882 9.34239C7.8367 9.63336 7.97956 9.90063 8.18934 10.1104C8.39912 10.3202 8.66639 10.4631 8.95737 10.5209C9.24834 10.5788 9.54994 10.5491 9.82403 10.4356C10.0981 10.322 10.3324 10.1298 10.4972 9.88311C10.662 9.63643 10.75 9.34643 10.75 9.04975C10.7495 8.65207 10.5914 8.27081 10.3102 7.9896C10.0289 7.7084 9.64768 7.55022 9.25 7.54975V7.54975ZM9.25 9.79975C9.10167 9.79975 8.95666 9.75577 8.83332 9.67336C8.70999 9.59094 8.61386 9.47381 8.55709 9.33677C8.50033 9.19972 8.48547 9.04892 8.51441 8.90344C8.54335 8.75795 8.61478 8.62431 8.71967 8.51942C8.82456 8.41453 8.9582 8.3431 9.10368 8.31416C9.24917 8.28523 9.39997 8.30008 9.53701 8.35684C9.67406 8.41361 9.79119 8.50974 9.8736 8.63308C9.95601 8.75641 10 8.90142 10 9.04975C9.99977 9.24859 9.92068 9.43923 9.78008 9.57983C9.63947 9.72043 9.44884 9.79952 9.25 9.79975V9.79975Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.3}
        />
        <path
          d="M4.29899 9.94983C4.14681 9.80934 4.13732 9.57209 4.2778 9.4199L9.16906 4.12103C9.30955 3.96884 9.54681 3.95935 9.69901 4.09984V4.09984C9.85119 4.24032 9.86068 4.47758 9.7202 4.62977L4.82894 9.92864C4.68845 10.0808 4.45119 10.0903 4.29899 9.94983V9.94983Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.3}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_29468_293088">
        <rect width={14} height={14} fill="white" />
      </clipPath>
      <clipPath id="clip1_29468_293088">
        <rect width={8} height={8} fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
export default Nudge;
