/**
 * Turns the first character of a string to uppercase.
 * @param {String} str string to capitalize
 * @returns {String}
 * @example capitalize("foo")); // returns "Foo"
 */
const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *  Turns the first character of a string to lowercase.
 * @param {String} str  string to decapitalize
 * @returns {String}
 * @example decapitalize("Foo")); // returns "foo"
 */
const decapitalize = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

/**
 * Strips emojis from a string.
 * @param {String} str string to strip emojis from
 * @returns {String}
 * @example stripEmojis("foo😊")); // returns "foo"
 */
const stripEmojis = (str: string): string => {
  return str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    "",
  );
};

export { capitalize, decapitalize, stripEmojis };
