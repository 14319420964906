import * as React from "react";
import { SVGProps } from "react";
const Club = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4984 6.10891L13.3174 2.5L15.1212 6.12008L19.1676 6.70853L16.233 9.51298L16.9137 13.4869L13.2947 11.6061L9.66807 13.472L10.3715 9.5018L7.44824 6.67874L11.4984 6.10891Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0397 12.055V15.7676C14.0397 16.1232 13.7591 16.4167 13.4192 16.4167H1.47595C1.13607 16.4167 0.855469 16.1232 0.855469 15.7676V8.27628C0.855469 7.92073 1.13607 7.6272 1.47595 7.6272H8.18005"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M2.81738 10.7053H6.54229"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M2.81738 13.0806H6.54229"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </svg>
);
export default Club;
