import * as React from "react";
import { SVGProps } from "react";
const HeartHandShake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M23.5044 16.5773L16.0083 24L8.51221 16.5773C8.01777 16.0962 7.62831 15.5181 7.36835 14.8791C7.10839 14.2402 6.98357 13.5544 7.00173 12.8649C7.0199 12.1753 7.18067 11.4971 7.47391 10.8727C7.76715 10.2483 8.18652 9.69146 8.7056 9.23711C9.22468 8.78275 9.83223 8.44078 10.49 8.23272C11.1478 8.02466 11.8415 7.95502 12.5275 8.02819C13.2135 8.10135 13.8769 8.31574 14.476 8.65784C15.0751 8.99995 15.5968 9.46236 16.0083 10.016C16.4216 9.46638 16.944 9.00801 17.5426 8.66953C18.1413 8.33106 18.8034 8.11977 19.4875 8.04889C20.1715 7.97801 20.8629 8.04907 21.5182 8.25762C22.1736 8.46616 22.7788 8.8077 23.2961 9.26087C23.8134 9.71404 24.2315 10.2691 24.5243 10.8912C24.8172 11.5134 24.9784 12.1893 24.998 12.8766C25.0175 13.564 24.895 14.2479 24.638 14.8857C24.381 15.5236 23.9951 16.1015 23.5044 16.5833M16.0108 10.0064L12.7195 13.297C12.5321 13.4844 12.4269 13.7386 12.4269 14.0035C12.4269 14.2685 12.5321 14.5226 12.7195 14.71L13.2622 15.2526C13.9519 15.9422 15.0713 15.9422 15.7609 15.2526L16.7604 14.2534C17.3568 13.657 18.1658 13.322 19.0093 13.322C19.8527 13.322 20.6617 13.657 21.2581 14.2534L23.5069 16.5018M16.5107 19.4996L18.5097 21.4982M19.0094 17.0014L21.0084 19"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HeartHandShake;
