import { useSelector } from "react-redux";

import { PRODUCT_IDS, PRODUCT_TYPE_IDS, VARIATION_IDS } from "@chef/constants";
import {
  IDeviationBasket,
  selectDeviationProducts,
  usePickAndMixQuery,
} from "@chef/state-management";
import { getRecipeFromPickAndMix } from "@chef/state-management/helpers";
import {
  useCalendarData,
  useExtendedBasketData,
  useUpdateBasketPickAndMixDeviation,
} from "@chef/state-management/hooks";
import { findFeaturedPortraitImage } from "@chef/utils/helpers/recipes";
import { isEqualStrings } from "@chef/utils/equal";

import { Meals } from "../shared/Meals";
import { useFinancesForDeviation } from "./useFinancesForDeviation";

interface DeviationMealsProps {
  deviation: IDeviationBasket;
  initiallyExpanded?: boolean;
  allowMealRemoval?: boolean;
  showImages?: boolean;
}

export const DeviationMeals = ({
  deviation,
  initiallyExpanded,
  allowMealRemoval,
  showImages,
}: DeviationMealsProps) => {
  const { week, year } = deviation;

  const { updateBasketPickAndMixDeviation } =
    useUpdateBasketPickAndMixDeviation({ week, year });
  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const { data: { pickAndMix = [] } = {} } = usePickAndMixQuery({
    productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
    week,
    year,
  });

  const { basket } = useCalendarData({
    week,
    year,
  });

  const selectedPickAndMixWithRecipe = deviation.products
    .filter(
      ({ productTypeId }) => productTypeId === PRODUCT_TYPE_IDS.PICKANDMIX,
    )
    .map((product) => ({
      ...product,
      recipe: getRecipeFromPickAndMix({
        pickAndMix,
        productId: product.productId,
        variationId: product.variationId,
      }),
    }));

  const { data: extendedBasketData } = useExtendedBasketData({ week, year });

  const { finances } = useFinancesForDeviation({
    deviation,
  });

  if (
    !selectedPickAndMixWithRecipe ||
    !extendedBasketData ||
    !selectedProducts
  ) {
    console.warn(
      "DeviationMeals rendering without required data. This is not necessarily an error, but it could mean that some required pre-loading is not happening, which could cause UI glitches.",
      { selectDeviationProducts, extendedBasketData, selectedProducts },
    );
    return null;
  }

  const portions = extendedBasketData.portions;
  const isOneSubAndNotSingle = basket.some(
    (product) =>
      isEqualStrings(product.productId, PRODUCT_IDS.ONESUB) &&
      !isEqualStrings(product.variationId, VARIATION_IDS.ONESUB_SINGLE),
  );

  return (
    <Meals
      dishes={selectedPickAndMixWithRecipe.map(
        ({ recipe, price, productId }) => {
          const { url } = findFeaturedPortraitImage(recipe || undefined);

          return {
            name: recipe?.recipeName,
            extraPrice: price,
            imageUrl: showImages && url,
            onRemove: allowMealRemoval
              ? () => {
                  updateBasketPickAndMixDeviation({
                    basketProducts: [...selectedProducts].filter(
                      (selectedProduct) =>
                        selectedProduct.productId !== productId,
                    ),
                    portions,
                  });
                }
              : undefined,
          };
        },
      )}
      portions={portions}
      priceOfMeals={finances.financial || finances.mealbox}
      initiallyExpanded={initiallyExpanded}
      minMeals={isOneSubAndNotSingle ? 2 : undefined}
    />
  );
};
