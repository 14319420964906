interface MarkWrapperProps {
  markType?: { node: { mark: string } };
  children: React.ReactNode;
}

// Applies 2nd mark to text from portable text, e.g mergeField and bold
export const MarkWrapper = ({ markType, children }: MarkWrapperProps) => {
  const mark = markType ? markType.node.mark : "no-marks";
  switch (mark) {
    case "strong":
      return <strong>{children}</strong>;
    case "em":
      return <em>{children}</em>;
    default:
      return <span>{children}</span>;
  }
};
