import { CalendarQuery } from "../graphql/generated";

export const getBasketDataFromCalendar = (args: {
  calendar?: CalendarQuery["calendar"];
  week: number;
  year: number;
}) => {
  const { calendar, week, year } = args;

  const data = calendar?.find((c) => {
    return c.week === week && c.year === year;
  });
  if (!data) {
    return null;
  }

  const basket = data.baskets.find((b) => b.week === week && b.year === year);
  if (!basket) {
    return null;
  }

  return basket;
};

export const getIsBeforeStartDateFromCalendar = (args: {
  calendar: CalendarQuery["calendar"];
  week: number;
  year: number;
}) => {
  const { calendar, week, year } = args;

  const data = calendar.find((c) => {
    return c.week === week && c.year === year;
  });

  if (!data) {
    return null;
  }

  return data.isBeforeStartDateWeek;
};

export const getNextDeliveryFromCalendar = (args: {
  calendar: CalendarQuery["calendar"];
  notRequiredDelivery?: true;
}) => {
  const { calendar, notRequiredDelivery } = args;

  for (const c of calendar) {
    if (c.isBeforeStartDateWeek) {
      continue;
    }

    for (const basket of c.baskets) {
      if (basket.shippingDetails.hasDelivery || notRequiredDelivery) {
        return c;
      }
    }
  }

  return null;
};

export const getWeeklyTimeblockDataFromCalendar = (args: {
  calendar: CalendarQuery["calendar"];
  week: number;
  year: number;
}) => {
  const { calendar, week, year } = args;

  const basket = getBasketDataFromCalendar({ calendar, week, year });

  if (!basket) {
    return null;
  }

  return basket.shippingDetails.plannedDeliveryInformation;
};

export const getCurrentWeekDataAndBasketFromCalendar = (args: {
  calendar: CalendarQuery["calendar"];
  week: number;
  year: number;
}) => {
  const { calendar, week, year } = args;

  const calendarWeek = calendar.find((c) => {
    return c.week === week && c.year === year;
  });

  if (!calendarWeek) {
    return null;
  }
  const basket = getBasketDataFromCalendar({ calendar, week, year });
  if (!basket) {
    return null;
  }

  const { baskets: _, ...restData } = calendarWeek;
  const currentWeekData = {
    ...restData,
    basket,
  };
  return currentWeekData;
};
