import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Snackbar } from "@chef/components";
import { noop } from "@chef/utils/noop";
import { removeNotification } from "@chef/state-management";

interface NotificationProps {
  type: "default" | "success" | "warn" | "error";
  hideIcon: boolean;
  disallowClose: boolean;
  autoClose: number | boolean;
  loading: boolean;
  notificationId: string;
  children: React.ReactNode;
  className?: string;
}

const Notification = memo(
  ({
    type,
    hideIcon,
    disallowClose,
    autoClose,
    loading,
    notificationId,
    children,
    className,
  }: NotificationProps) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
      dispatch(removeNotification(notificationId));
    }, [dispatch, notificationId]);

    useEffect(() => {
      if (typeof autoClose === "number") {
        const timeout = setTimeout(handleClose, autoClose);

        return () => clearTimeout(timeout);
      }

      return noop;
    }, [handleClose, autoClose]);

    return (
      <Snackbar
        showIcon={!hideIcon}
        closeable={!disallowClose}
        onClose={handleClose}
        error={type === "error"}
        nudge={type === "default"}
        warning={type === "warn"}
        success={type === "success"}
        loading={loading}
        className={clsx("relative md:w-96 drop-shadow-sm", className)}
      >
        {typeof autoClose === "number" && (
          <motion.div
            className={clsx("absolute h-0.5 bottom-0 left-0 mt-0.5", {
              "bg-information": type === "success",
              "bg-error": type === "error",
              "bg-highlight": type === "warn",
              "bg-grey-1": type === "default",
            })}
            initial={{
              width: "100%",
            }}
            animate={{ width: "0%" }}
            transition={{ duration: autoClose / 1000, ease: "linear" }}
          />
        )}
        {children}
      </Snackbar>
    );
  },
);

Notification.displayName = "Notification";

export default Notification;
