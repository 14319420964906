import * as React from "react";
import { SVGProps } from "react";
const Discount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 14L21 11L18 8V4H14L11 1L8 4H4V8L1 11L4 14V18H8L11 21L14 18H18V14Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="path-2-inside-1_36535_99778" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4351 7.56633C14.8256 7.95685 14.8256 8.59002 14.4351 8.98054L8.98054 14.4351C8.59002 14.8256 7.95685 14.8256 7.56633 14.4351C7.17581 14.0446 7.17581 13.4114 7.56633 13.0209L13.0209 7.56633C13.4114 7.17581 14.0446 7.17581 14.4351 7.56633ZM8.27335 9.18244C8.77543 9.18244 9.18244 8.77543 9.18244 8.27335C9.18244 7.77127 8.77543 7.36426 8.27335 7.36426C7.77127 7.36426 7.36426 7.77127 7.36426 8.27335C7.36426 8.77543 7.77127 9.18244 8.27335 9.18244ZM14.6375 13.7282C14.6375 14.2303 14.2305 14.6373 13.7284 14.6373C13.2263 14.6373 12.8193 14.2303 12.8193 13.7282C12.8193 13.2261 13.2263 12.8191 13.7284 12.8191C14.2305 12.8191 14.6375 13.2261 14.6375 13.7282Z"
      />
    </mask>
    <path
      d="M14.4351 8.98054L15.1422 9.68765L15.1422 9.68765L14.4351 8.98054ZM14.4351 7.56633L15.1422 6.85922L15.1422 6.85922L14.4351 7.56633ZM8.98054 14.4351L9.68765 15.1422L9.68765 15.1422L8.98054 14.4351ZM7.56633 14.4351L6.85922 15.1422L6.85922 15.1422L7.56633 14.4351ZM7.56633 13.0209L6.85922 12.3138H6.85922L7.56633 13.0209ZM13.0209 7.56633L12.3138 6.85922V6.85922L13.0209 7.56633ZM15.1422 9.68765C15.9232 8.9066 15.9232 7.64027 15.1422 6.85922L13.728 8.27344V8.27344L15.1422 9.68765ZM9.68765 15.1422L15.1422 9.68765L13.728 8.27344L8.27344 13.728L9.68765 15.1422ZM6.85922 15.1422C7.64027 15.9232 8.9066 15.9232 9.68765 15.1422L8.27344 13.728H8.27344L6.85922 15.1422ZM6.85922 12.3138C6.07818 13.0948 6.07818 14.3611 6.85922 15.1422L8.27344 13.728V13.728L6.85922 12.3138ZM12.3138 6.85922L6.85922 12.3138L8.27344 13.728L13.728 8.27344L12.3138 6.85922ZM15.1422 6.85922C14.3611 6.07818 13.0948 6.07818 12.3138 6.85922L13.728 8.27344H13.728L15.1422 6.85922ZM8.18244 8.27335C8.18244 8.22314 8.22314 8.18244 8.27335 8.18244V10.1824C9.32771 10.1824 10.1824 9.32771 10.1824 8.27335H8.18244ZM8.27335 8.36426C8.22314 8.36426 8.18244 8.32356 8.18244 8.27335H10.1824C10.1824 7.21899 9.32771 6.36426 8.27335 6.36426V8.36426ZM8.36426 8.27335C8.36426 8.32356 8.32356 8.36426 8.27335 8.36426V6.36426C7.21899 6.36426 6.36426 7.21899 6.36426 8.27335H8.36426ZM8.27335 8.18244C8.32356 8.18244 8.36426 8.22314 8.36426 8.27335H6.36426C6.36426 9.32771 7.21899 10.1824 8.27335 10.1824V8.18244ZM13.7284 15.6373C14.7828 15.6373 15.6375 14.7825 15.6375 13.7282H13.6375C13.6375 13.678 13.6782 13.6373 13.7284 13.6373V15.6373ZM11.8193 13.7282C11.8193 14.7825 12.6741 15.6373 13.7284 15.6373V13.6373C13.7786 13.6373 13.8193 13.678 13.8193 13.7282H11.8193ZM13.7284 11.8191C12.6741 11.8191 11.8193 12.6738 11.8193 13.7282H13.8193C13.8193 13.7784 13.7786 13.8191 13.7284 13.8191V11.8191ZM15.6375 13.7282C15.6375 12.6738 14.7828 11.8191 13.7284 11.8191V13.8191C13.6782 13.8191 13.6375 13.7784 13.6375 13.7282H15.6375Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_36535_99778)"
    />
  </svg>
);
export default Discount;
