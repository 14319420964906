import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      PORTIONS: "porsjoner",
    },
    se: {
      PORTIONS: "portioner",
    },
    dk: {
      PORTIONS: "portioner",
    },
  } as const
)[language];
