import * as React from "react";
import { SVGProps } from "react";
const Swap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.30827 21.5607L7.1731 20.6306L8.17628 20.7282L7.30827 21.5607Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M23.3105 10.376C22.2243 9.07142 20.7803 8.08313 19.1408 7.52213C17.5014 6.96112 15.7313 6.84961 14.0294 7.20013C12.3275 7.55064 10.7612 8.34929 9.50636 9.5064C8.25153 10.6635 7.35789 12.1332 6.92578 13.7505"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.69043 21.6245C9.77668 22.9291 11.2206 23.9174 12.8601 24.4784C14.4996 25.0394 16.2697 25.1509 17.9715 24.8004C19.6734 24.4499 21.2398 23.6512 22.4946 22.4941C23.7494 21.337 24.643 19.8673 25.0752 18.25"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 19.5117L10.4389 19.9437L6.59811 23.6275L6 19.5117Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.8266 11.368L23.8234 11.2704L24.6914 10.4378L24.8266 11.368Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M21.5608 12.0549L25.4016 8.37109L25.9997 12.4869L21.5608 12.0549Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Swap;
