import * as React from "react";
import { SVGProps } from "react";
const LockOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.2764 47.2838V31.3661C38.2764 27.7074 39.7009 24.1985 42.2366 21.6114C44.7723 19.0242 48.2114 17.5708 51.7974 17.5708C55.3834 17.5708 58.8225 19.0242 61.3582 21.6114C63.8938 24.1985 65.3184 27.7074 65.3184 31.3661V33.2763"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.7967 58.9567V69.5685M28.915 47.2837H74.6784V81.2415H28.915V47.2837Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LockOpen;
