import { personasApi } from "./personas";
import { planAndGoApi } from "./planAndGo";
import { basketSlice } from "./basket";
import { modalSlice } from "./modal";
import { notificationsSlice } from "./notifications";
import { authApi } from "./auth";
import { signupSlice } from "./signup";
import { sanityApi, sanityDataApi } from "./sanity";
import { featureFlagsApi } from "./featureFlags";

import { directOrderSlice, directPaymentSessionApi } from "./directOrder";
import { segmentApi, trackingDestinationsApi, trackingSlice } from "./tracking";
import { discountApi } from "./discount";
import { loyaltyApi, loyaltySlice } from "./loyalty";
import { deliveryApi } from "./delivery";
import { smsApi } from "./sms";
import { nudgeSlice } from "./nudge";
import { giftcardSlice } from "./giftcard";
import { competitionDisclosureSlice } from "./competition";
import { unleashApi } from "./unleash";
import { trustpilotApi } from "./trustpilot";
import { datadogSlice } from "./datadog";
import { shlinkApi } from "./shlink";
import { cioApi } from "./cio";

import { signupSessionApi } from "./signup/session";
import { billingSessionApi } from "./billing/session";
import { api } from "../graphql/baseApi";

const reducers = {
  [authApi.reducerPath]: authApi.reducer,
  [basketSlice.name]: basketSlice.reducer,
  [billingSessionApi.reducerPath]: billingSessionApi.reducer,
  [cioApi.reducerPath]: cioApi.reducer,
  [competitionDisclosureSlice.name]: competitionDisclosureSlice.reducer,
  [datadogSlice.name]: datadogSlice.reducer,
  [deliveryApi.reducerPath]: deliveryApi.reducer,
  [directOrderSlice.name]: directOrderSlice.reducer,
  [directPaymentSessionApi.reducerPath]: directPaymentSessionApi.reducer,
  [discountApi.reducerPath]: discountApi.reducer,
  [featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
  [giftcardSlice.name]: giftcardSlice.reducer,
  [loyaltySlice.name]: loyaltySlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [nudgeSlice.name]: nudgeSlice.reducer,
  [personasApi.reducerPath]: personasApi.reducer,
  [planAndGoApi.reducerPath]: planAndGoApi.reducer,
  [sanityApi.reducerPath]: sanityApi.reducer,
  [sanityDataApi.reducerPath]: sanityDataApi.reducer,
  [segmentApi.reducerPath]: segmentApi.reducer,
  [shlinkApi.reducerPath]: shlinkApi.reducer,
  [signupSessionApi.reducerPath]: signupSessionApi.reducer,
  [signupSlice.name]: signupSlice.reducer,
  [smsApi.reducerPath]: smsApi.reducer,
  [trackingDestinationsApi.reducerPath]: trackingDestinationsApi.reducer,
  [trackingSlice.name]: trackingSlice.reducer,
  [trustpilotApi.reducerPath]: trustpilotApi.reducer,
  [unleashApi.reducerPath]: unleashApi.reducer,
  [api.reducerPath]: api.reducer,
};

const middleware = [
  planAndGoApi.middleware,
  authApi.middleware,
  signupSessionApi.middleware,
  billingSessionApi.middleware,
  directPaymentSessionApi.middleware,
  personasApi.middleware,
  trackingDestinationsApi.middleware,
  discountApi.middleware,
  loyaltyApi.middleware,
  deliveryApi.middleware,
  sanityDataApi.middleware,
  sanityApi.middleware,
  smsApi.middleware,
  segmentApi.middleware,
  featureFlagsApi.middleware,
  unleashApi.middleware,
  trustpilotApi.middleware,
  shlinkApi.middleware,
  cioApi.middleware,
  api.middleware,
];

export * from "./planAndGo";
export * from "./auth";
export * from "./billing";
export * from "./personas";
export * from "./signup";
export * from "./recipe";
export * from "./preferences";
export * from "./basket";
export * from "./modal";
export * from "./notifications";
export * from "./validate";
export * from "./user";
export * from "./directOrder";
export * from "./discount";
export * from "./payment";
export * from "./loyalty";
export * from "./tracking";
export * from "./delivery";
export * from "./sanity";
export * from "./sms";
export * from "./nudge";
export * from "./giftcard";
export * from "./featureFlags";
export * from "./competition";
export * from "./unleash";
export * from "./trustpilot";
export * from "./datadog";
export * from "./shlink";
export * from "./cio";

export default {
  reducers,
  middleware,
};
