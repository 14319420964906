import * as React from "react";
import { SVGProps } from "react";
const LongHat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M4.27817 12.3246L11.8327 12.3188M8.00311 1.07031C9.227 1.07031 10.2493 1.95075 10.4981 3.12531C11.1516 2.94456 11.8486 3.03654 12.4358 3.38102C13.0229 3.72549 13.4521 4.29425 13.629 4.96216C13.8058 5.63007 13.7159 6.34243 13.3788 6.94253C13.0418 7.54263 12.4853 7.9813 11.8318 8.16205V14.9275H4.17446V8.16205C3.85087 8.07247 3.54772 7.91862 3.28231 7.7093C3.0169 7.49997 2.79443 7.23927 2.6276 6.94207C2.46077 6.64487 2.35285 6.317 2.31 5.97717C2.26715 5.63735 2.29021 5.29223 2.37786 4.96151C2.46552 4.63079 2.61604 4.32096 2.82085 4.0497C3.02567 3.77844 3.28075 3.55106 3.57154 3.38056C3.86232 3.21005 4.18312 3.09975 4.51562 3.05596C4.84812 3.01216 5.1858 3.03573 5.50938 3.12531C5.63307 2.54329 5.9479 2.02205 6.40155 1.64821C6.8552 1.27437 7.42036 1.07044 8.00311 1.07031Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LongHat;
