import { motion } from "framer-motion";
import dynamic from "next/dynamic";

import { Container } from "@chef/components";
import { BRAND_PATHS, language } from "@chef/constants";
import { Apple, Google } from "@chef/icons/other";
import { getMobileOperatingSystem } from "@chef/utils/runtime";
import { useMobileApp } from "@chef/feature-mobile-app";

const intl = (
  {
    no: {
      DOWNLOAD_APP: "Last ned appen",
      TO_IOS_AND_ANDROID: "til iOS og Android",
    },
    se: {
      DOWNLOAD_APP: "Ladda ner appen",
      TO_IOS_AND_ANDROID: "till iOS och Android",
    },
    dk: {
      DOWNLOAD_APP: "Download appen",
      TO_IOS_AND_ANDROID: "til iOS og Android",
    },
  } as const
)[language];

const AppStoreBannerComponent = () => {
  const { isApp } = useMobileApp();

  if (isApp) {
    return null;
  }

  const os = getMobileOperatingSystem();

  if (os === "Windows Phone") {
    return null;
  }

  const showIOS = os === "iOS" || os === "unknown";
  const showAndroid = os === "Android" || os === "unknown";

  return (
    <Container
      as="footer"
      innerClassName="flex justify-center flex-wrap gap-4 items-center py-6 md:py-8"
      outerClassName="bg-white"
    >
      <div>
        <span>{intl.DOWNLOAD_APP} </span>
        {os === "unknown" && <strong>{intl.TO_IOS_AND_ANDROID}</strong>}
      </div>
      <div className="flex gap-4">
        {showIOS && (
          <motion.a
            href={BRAND_PATHS.APP_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
            initial="rest"
            whileHover="hover"
            whileTap="hover"
          >
            <motion.div
              variants={{
                rest: { opacity: 0.9 },
                hover: {
                  opacity: 1,
                  scale: [1, 0.875, 1.125, 1],
                },
              }}
            >
              <Apple className="w-12 h-12 drop-shadow-lg" />
            </motion.div>
            <strong className="-ml-1 drop-shadow">App Store</strong>
          </motion.a>
        )}
        {showAndroid && (
          <motion.a
            href={BRAND_PATHS.GOOGLE_PLAY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
            initial="rest"
            whileHover="hover"
            whileTap="hover"
          >
            <motion.div
              variants={{
                rest: { opacity: 0.9 },
                hover: {
                  opacity: 1,
                  scale: [1, 0.875, 1.125, 1],
                },
              }}
            >
              <Google className="w-12 h-12 drop-shadow-lg" />
            </motion.div>
            <strong className="-ml-10 drop-shadow">Google Play</strong>
          </motion.a>
        )}
      </div>
    </Container>
  );
};

export const AppStoreBanner = dynamic(
  () => Promise.resolve(AppStoreBannerComponent),
  { ssr: false },
);
