import React from "react";
import clsx from "clsx";

interface HrProps {
  className?: string;
  light?: boolean;
  dark?: boolean;
  id?: string;
}

export const Hr = ({ light, dark, className, id, ...props }: HrProps) => {
  interface TypeStyles {
    light: string;
    default: string;
    dark: string;
  }
  const typeStyles: TypeStyles = {
    light: "bg-grey-3 border-grey-3",
    default: "bg-grey-1 border-grey-1",
    dark: "bg-black border-black",
  };

  let type = "default";
  if (light) {
    type = "light";
  }
  if (dark) {
    type = "dark";
  }

  return (
    <hr
      className={clsx(
        "border-solid border-t-2",
        typeStyles[type as keyof TypeStyles],
        className,
      )}
      id={id}
      {...props}
    />
  );
};
