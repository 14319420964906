import React, { HTMLAttributes } from "react";
import clsx from "clsx";

interface ContainerProps<T extends keyof React.ReactHTML>
  extends HTMLAttributes<keyof T> {
  as?: T;
  grid?: boolean | "no-gutter" | "default";
  hasPadding?: boolean;
  outerClassName?: string;
  innerClassName?: string;
  isFullWidth?: boolean;
  children: React.ReactNode;
  id?: string;
}

export const Container = <T extends keyof React.ReactHTML>({
  as,
  grid,
  hasPadding = true,
  outerClassName,
  innerClassName,
  isFullWidth,
  id,
  children,
  ...props
}: ContainerProps<T>) => {
  const outerStyles = clsx(hasPadding && "page-padding", outerClassName);

  const innerStyles = clsx(
    isFullWidth ? "max-w-full mx-auto" : "page-max-width",
    innerClassName,
  );

  return React.createElement(
    as || "div",
    { ...props, className: outerStyles, id: id },
    <div
      className={clsx(
        innerStyles,
        grid && "page-grid",
        grid === "no-gutter" && "!gap-0",
      )}
    >
      {children}
    </div>,
  );
};
