import * as React from "react";
import { SVGProps } from "react";
const UnderConstruction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.9459 21.4406L15.1069 7.60156L7.57422 15.1336L21.3558 29.023"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.0358 17.5981L18.4137 43.1908L8.32812 47.6589L12.7962 37.5733L38.4176 11.9799M44.0358 17.5981L38.4176 11.9799M44.0358 17.5981L48.4276 13.2112L42.8094 7.59375L38.4176 11.9799"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9523 34.6595L40.5897 48.4033L48.2519 40.7418L34.5676 27.0575M36.0635 43.7567L39.9107 39.9081M16.7253 24.352L20.9638 20.1121M31.8369 39.5812L34.9883 36.4305M12.3125 19.9056L15.4226 16.7969"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.793 37.5781L18.4105 43.1956"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UnderConstruction;
