import {
  CalendarQuery,
  IDeviationBasket,
  OrderQuery,
} from "@chef/state-management";
import { CalendarWeekShoppingCart } from "./calendar-week/CalendarWeekShoppingCart";
import { DeviationShoppingCart } from "./deviation/DeviationShoppingCart";
import { OrderShoppingCart } from "./order/OrderShoppingCart";

type OrderShoppingCartProps = {
  order: OrderQuery["order"];
  calendarWeek?: never;
  deviation?: never;
  allowMealRemoval?: never;
  allowGroceryRemoval?: never;
};

type CalendarWeekShoppingCartProps = {
  calendarWeek: CalendarQuery["calendar"][number];
  order?: never;
  deviation?: never;
  allowMealRemoval?: never;
  allowGroceryRemoval?: never;
};

type DeviationShoppingCartProps = {
  deviation: IDeviationBasket;
  order?: never;
  calendarWeek?: never;
  allowMealRemoval?: boolean;
  allowGroceryRemoval?: boolean;
};

type ShoppingCartSharedProps = {
  initiallyExpandMealSection?: boolean;
  initiallyExpandGrocerySection?: boolean;
  showMealImages?: boolean;
};

type ShoppingCartProps = (
  | OrderShoppingCartProps
  | CalendarWeekShoppingCartProps
  | DeviationShoppingCartProps
) &
  ShoppingCartSharedProps;

export const ShoppingCart = ({
  order,
  calendarWeek,
  deviation,
  allowMealRemoval,
  allowGroceryRemoval,
  initiallyExpandMealSection,
  initiallyExpandGrocerySection,
  showMealImages,
}: ShoppingCartProps) => {
  if (calendarWeek) {
    return (
      <CalendarWeekShoppingCart
        initiallyExpandMealSection={initiallyExpandMealSection}
        calendarWeek={calendarWeek}
        initiallyExpandGrocerySection={initiallyExpandGrocerySection}
      />
    );
  }

  if (deviation) {
    return (
      <DeviationShoppingCart
        deviation={deviation}
        allowMealRemoval={allowMealRemoval}
        allowGroceryRemoval={allowGroceryRemoval}
        initiallyExpandMealSection={initiallyExpandMealSection}
        initiallyExpandGrocerySection={initiallyExpandGrocerySection}
        showMealImages={showMealImages}
      />
    );
  }

  if (order) {
    return (
      <OrderShoppingCart
        order={order}
        initiallyExpandMealSection={initiallyExpandMealSection}
        initiallyExpandGrocerySection={initiallyExpandGrocerySection}
      />
    );
  }

  console.warn("No order, calendarWeek or deviation provided");

  return null;
};
