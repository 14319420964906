import { nanoid } from "@reduxjs/toolkit";

import type {
  IFromAppPayload,
  IToAppPayload,
  MOBILE_APP_ENDPOINT,
  MobileAppSubscribe,
} from "../types";

export function sendAppMessage<T extends MOBILE_APP_ENDPOINT>(
  endpoint: T,
  payload: Omit<IToAppPayload<T>, "id" | "type">,
): Promise<void>;

export function sendAppMessage<T extends MOBILE_APP_ENDPOINT>(
  endpoint: T,
  payload: Omit<IToAppPayload<T>, "id" | "type">,
  options: {
    timeout?: number | false;
    subscribe: MobileAppSubscribe;
  },
): Promise<IFromAppPayload<T>>;

export function sendAppMessage<T extends MOBILE_APP_ENDPOINT>(
  endpoint: T,
  payload: Omit<IToAppPayload<T>, "id" | "type">,
  options?: {
    timeout?: number | false;
    subscribe?: MobileAppSubscribe;
  },
): Promise<void | IFromAppPayload<T>> {
  const _payload: IToAppPayload<T> = {
    // TODO: find out why it doesn't work with Omit
    ...(payload as IToAppPayload<T>),

    id: nanoid(),
    type: endpoint,
  };

  return new Promise((resolve, reject) => {
    if (!window.ReactNativeWebView) {
      return reject("ReactNativeWebView does not exist on window");
    }

    if (options?.subscribe) {
      const cleanup = () => {
        clearTimeout(timeout);
        unsubscribe();
      };

      const unsubscribe = options.subscribe(endpoint, (response) => {
        if (response.id !== _payload.id) {
          return;
        }

        cleanup();
        resolve(response as IFromAppPayload<T>);
      });

      let timeout: NodeJS.Timeout;
      if (options?.timeout !== false) {
        timeout = setTimeout(() => {
          cleanup();
          reject("timedout");
        }, options?.timeout || 2500);
      }
    }

    // send the message
    window.ReactNativeWebView.postMessage(JSON.stringify(_payload));

    if (!options?.subscribe) {
      resolve();
    }
  });
}
