import * as React from "react";
import { SVGProps } from "react";
const Help = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={22} height={22} rx={11} fill="currentColor" />
    <path
      d="M9.00389 7.54205C9.18955 6.79344 10.0436 6.18742 11.0275 6.18742C12.1414 6.18742 12.9026 6.91821 12.9026 7.82723C12.9026 9.75223 9.67225 10.1087 9.67225 12.3189C9.67225 12.9784 9.98786 13.6735 10.749 14.2261L11.9187 13.0497C11.6031 12.7823 11.436 12.5328 11.436 12.3011C11.436 11.3742 14.6663 10.5721 14.6663 7.82723C14.6663 6.04483 13.1254 4.58325 11.0275 4.58325C9.22668 4.58325 7.70432 5.63487 7.33301 7.07862L9.00389 7.54205ZM9.65368 16.1689C9.65368 16.864 10.2292 17.4166 10.9533 17.4166C11.6773 17.4166 12.2528 16.864 12.2528 16.1689C12.2528 15.4738 11.6773 14.9212 10.9533 14.9212C10.2292 14.9212 9.65368 15.4738 9.65368 16.1689Z"
      fill="white"
    />
  </svg>
);
export default Help;
