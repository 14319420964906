import { useContext } from "react";

import { useCallbackRef, useEnvironment } from "@chef/hooks";

import { MOBILE_APP_ENDPOINT } from "../types";
import { sendAppMessage as _sendAppMessage } from "../utils/sendAppMessage";
import { MobileAppContext } from "./MobileAppContext";

type SendAppMessageArgs<T extends MOBILE_APP_ENDPOINT> = Parameters<
  typeof _sendAppMessage<T>
>;

export const useMobileApp = () => {
  const mobileApp = useContext(MobileAppContext);
  if (!mobileApp) {
    throw new Error(
      "useMobileApp needs to be used inside of MobileAppProvider",
    );
  }

  const sendAppMessage = useCallbackRef(
    async <T extends MOBILE_APP_ENDPOINT>(
      endpoint: SendAppMessageArgs<T>[0],
      payload: SendAppMessageArgs<T>[1],
      options?: Omit<SendAppMessageArgs<T>[2], "subscribe">,
    ) => {
      return _sendAppMessage(endpoint, payload, {
        ...(options || {}),
        subscribe: mobileApp.subscribe,
      });
    },
  );

  const { isWebView } = useEnvironment();

  return {
    sendAppMessage,
    subscribe: mobileApp.subscribe,
    isApp: mobileApp.isApp,
    appVersion: mobileApp.appVersion,
    appOS: mobileApp.appOS,
    isOldApp: isWebView && !mobileApp.isApp,
    isWebView,
  };
};
