import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DELIVERY: "Levering",
      WE_OFFER_FREE_SHIPPING_TO_GOLD_MEMBERS:
        "Vi tilbyr fri frakt til deg som er gullmedlem i vår kundeklubb!",
    },

    se: {
      DELIVERY: "Leverans",
      WE_OFFER_FREE_SHIPPING_TO_GOLD_MEMBERS:
        "Vi bjuder dig som är guldmedlem på fri frakt!",
    },

    dk: {
      DELIVERY: "Levering",
      WE_OFFER_FREE_SHIPPING_TO_GOLD_MEMBERS:
        "Som guldmedlem i vores kundeklub får du fri fragt!",
    },
  } as const
)[language];
