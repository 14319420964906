import * as React from "react";
import { SVGProps } from "react";
const ThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1299 54.8896H25.9999C29.1599 54.8896 35.4699 58.0496 38.6299 58.0496H54.4399C55.278 58.0496 56.0817 57.7167 56.6743 57.1241C57.267 56.5315 57.5999 55.7277 57.5999 54.8896C57.5999 54.0515 57.267 53.2478 56.6743 52.6552C56.0817 52.0625 55.278 51.7296 54.4399 51.7296H55.9999C56.8059 51.6842 57.564 51.332 58.1186 50.7453C58.6732 50.1586 58.9822 49.3819 58.9822 48.5746C58.9822 47.7673 58.6732 46.9906 58.1186 46.4039C57.564 45.8172 56.8059 45.465 55.9999 45.4196H57.5999C58.438 45.4196 59.2417 45.0867 59.8343 44.4941C60.427 43.9015 60.7599 43.0977 60.7599 42.2596C60.7599 41.4215 60.427 40.6178 59.8343 40.0252C59.2417 39.4325 58.438 39.0996 57.5999 39.0996"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.5999 39.0999H59.1699C59.5991 39.1241 60.0288 39.0604 60.4326 38.9128C60.8364 38.7652 61.2059 38.5367 61.5183 38.2413C61.8307 37.946 62.0796 37.59 62.2497 37.1951C62.4198 36.8003 62.5076 36.3749 62.5076 35.9449C62.5076 35.515 62.4198 35.0896 62.2497 34.6947C62.0796 34.2998 61.8307 33.9438 61.5183 33.6485C61.2059 33.3532 60.8364 33.1247 60.4326 32.977C60.0288 32.8294 59.5991 32.7657 59.1699 32.7899H41.8099C42.8601 28.1262 43.39 23.3604 43.3899 18.5799C43.3899 17.7418 43.057 16.9381 42.4643 16.3455C41.8717 15.7528 41.068 15.4199 40.2299 15.4199C39.3918 15.4199 38.588 15.7528 37.9954 16.3455C37.4028 16.9381 37.0699 17.7418 37.0699 18.5799C37.0699 18.5799 33.9099 35.9999 22.8599 35.9999H18.1299"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ThumbsUp;
