import * as React from "react";
import { SVGProps } from "react";
const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.0042 13L70.5021 47.6667L90 82.3333H51.0042H12L31.4979 47.6667L51.0042 13Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51 43.3335V56.3335"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51 68C52.1046 68 53 67.1046 53 66C53 64.8954 52.1046 64 51 64C49.8954 64 49 64.8954 49 66C49 67.1046 49.8954 68 51 68Z"
      fill="currentColor"
    />
  </svg>
);
export default Warning;
