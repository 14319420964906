import { Card, Skeleton } from "../components";

const ReceiptLoader = () => (
  <Card className="w-full text-base md:max-w-md md:mx-auto">
    <div className="space-y-3">
      <Skeleton width={48} height={8} />
      <Skeleton width={48} />
      <Skeleton width={64} height={12} />
      <Skeleton width={48} />
      <Skeleton height={0.5} />
      <div className="flex justify-between">
        <Skeleton width={36} />
        <Skeleton width={16} />
      </div>
      <div className="flex justify-between">
        <Skeleton width={64} />
        <Skeleton width={12} />
      </div>
      <div className="flex justify-between">
        <Skeleton width={64} />
        <Skeleton width={12} />
      </div>
      <div className="flex justify-between">
        <Skeleton width={64} />
        <Skeleton width={12} />
      </div>
      <Skeleton height={0.5} />
      <div className="flex justify-between">
        <Skeleton width={36} />
        <Skeleton width={16} />
      </div>
      <Skeleton height={0.5} />
      <div className="flex justify-between">
        <Skeleton width={36} />
        <Skeleton width={16} />
      </div>
      <Skeleton height={0.5} />
      <div className="flex justify-between">
        <Skeleton width={36} />
        <Skeleton width={16} />
      </div>
    </div>
  </Card>
);

export default ReceiptLoader;
