import React from "react";
import clsx from "clsx";

import { Container } from "../components/Container";
import { PropsOf } from "../../types";

interface OwnHeaderBaseProps {
  sticky?: boolean;
}

type HeaderBaseProps = OwnHeaderBaseProps & PropsOf<typeof Container>;

const HeaderBase = ({
  className,
  children,
  sticky,
  ...props
}: HeaderBaseProps) => {
  return (
    <Container
      outerClassName={clsx(
        "bg-white shadow-full z-20 w-full",
        sticky && "sticky top-0",
      )}
      innerClassName={clsx("flex justify-between items-center", className)}
      {...props}
    >
      {children}
    </Container>
  );
};

export default HeaderBase;
