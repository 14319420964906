import React, { HTMLAttributes } from "react";
import { Card } from "../Card";

const Panel = ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="flex flex-col items-start justify-start flex-grow w-full"
    {...props}
  >
    {children}
  </div>
);

const Title = ({ children, ...props }: HTMLAttributes<HTMLElement>) => (
  <strong {...props}>{children}</strong>
);

const Description = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => <div {...props}>{children}</div>;

const Icon = ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className="flex-shrink-0 w-8 h-8" {...props}>
    {children}
  </div>
);

interface ActionCardProps extends HTMLAttributes<keyof React.ReactHTML> {
  as?: keyof React.ReactHTML;
  href?: string;
  onClick?: () => void;
  noShadow?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
  id?: string;
}

export const ActionCard = React.forwardRef<HTMLElement, ActionCardProps>(
  ({ as, href, onClick, children, noShadow, noPadding, id, ...props }, ref) => {
    const getComponentType = (): keyof React.ReactHTML => {
      if (as) {
        return as;
      } else if (href) {
        return "a";
      } else {
        return "button";
      }
    };

    return (
      <Card
        as={getComponentType()}
        className="flex items-center w-full"
        href={href}
        ref={ref}
        onClick={onClick}
        noShadow={noShadow}
        noPadding={noPadding}
        id={id}
        {...props}
      >
        {children}
      </Card>
    );
  },
) as React.ForwardRefExoticComponent<
  ActionCardProps & React.RefAttributes<HTMLElement>
> & {
  Panel: typeof Panel;
  Title: typeof Title;
  Description: typeof Description;
  Icon: typeof Icon;
};

ActionCard.Panel = Panel;
ActionCard.Title = Title;
ActionCard.Description = Description;
ActionCard.Icon = Icon;
