import { LiveQueryProvider } from "next-sanity/preview";

import { getClient } from "../utils";

interface PreviewProviderProps {
  children: React.ReactNode;
  draftMode?: boolean;
}

const PreviewProvider = ({ children, draftMode }: PreviewProviderProps) => {
  if (!draftMode) {
    return children;
  }

  const client = getClient({ preview: true });

  return (
    <LiveQueryProvider client={client} logger={console}>
      {children}
    </LiveQueryProvider>
  );
};

export default PreviewProvider;
