import * as React from "react";
import { SVGProps } from "react";
const Invite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2439_2427)">
      <path
        d="M7 24.9998C8.92509 22.4902 12.0052 20.8459 15.5 20.8459C18.9652 20.8459 22.0749 22.4902 24 24.9998"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1683 12.5385C20.1683 15.0235 18.1006 17.0769 15.5016 17.0769C12.9026 17.0769 10.835 15.0235 10.835 12.5385C10.835 10.0534 12.9026 8 15.5016 8C18.1006 8 20.1683 10.0534 20.1683 12.5385Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M27 1V9"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M31 5L23 5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2439_2427">
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Invite;
