import { getImage } from "@sanity/asset-utils";
import { SanityReference } from "next-sanity";
import { dataset, projectId } from "../api";

export const getAsset = (value: SanityReference) => {
  return getImage(value, {
    projectId,
    dataset,
  });
};
