import { useDiscountData } from "@chef/state-management/hooks";

import { Discount } from "./Discount";

export const PermanentDiscount = () => {
  const {
    discounts: {
      activeDiscounts: { permanentDiscounts },
    },
  } = useDiscountData();

  const { nextPermanentDiscount } = permanentDiscounts;

  if (!nextPermanentDiscount) {
    return null;
  }

  return <Discount discount={nextPermanentDiscount} type="permanent" />;
};
