import { useMemo } from "react";
import { useSelector } from "react-redux";

import { config, PRODUCT_CATEGORY_IDS } from "@chef/constants";

import { selectDeviationProducts } from "../features";
import { useProductsByCategoriesQuery } from "../graphql/generated";
import {
  getFinancialVariationFromBasket,
  getMealboxVariationFromBasket,
} from "../helpers/basket";
import { getAttribute, isPickAndMixProduct } from "../helpers/product";

// The purpose of this hook is to give access to commonly used data that is often needed
// when dealing with the basket. Do not include a bunch of niche stuff in this hook.
export const useExtendedBasketData = (args: {
  week: number;
  year: number;
  signup?: boolean;
}) => {
  const { week, year, signup = false } = args;
  const skip = !week || !year;

  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const { data: { productsByCategories } = {}, ...productsByCategoriesQuery } =
    useProductsByCategoriesQuery(
      {
        categoryIds: [
          PRODUCT_CATEGORY_IDS.FINANCIAL,
          signup
            ? PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT
            : PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
        ],
        week,
        year,
      },
      { skip },
    );

  // TODO: oncce fully merged to OneSub this financial product logic should be removed
  const financialVariation = useMemo(() => {
    if (!productsByCategories) {
      return null;
    }

    return getFinancialVariationFromBasket({
      productsByCategories,
      basketProducts: selectedProducts || [],
    });
  }, [selectedProducts, productsByCategories]);

  const mealboxVariation = useMemo(() => {
    if (financialVariation) {
      return financialVariation;
    }
    if (!productsByCategories) {
      return null;
    }

    return getMealboxVariationFromBasket({
      productsByCategories,
      basketProducts: selectedProducts || [],
    });
  }, [selectedProducts, financialVariation, productsByCategories]);

  const selectedPortions = useMemo(() => {
    if (!mealboxVariation) {
      return config.order.defaultPortions;
    }
    return +(
      getAttribute("Portions", mealboxVariation) || config.order.defaultPortions
    );
  }, [mealboxVariation]);

  const selectedMeals = useMemo(() => {
    if (!mealboxVariation) {
      if (!selectedProducts) {
        return config.order.defaultMeals;
      }
      return selectedProducts.filter(isPickAndMixProduct).length;
    }

    return +(
      getAttribute("Meals", mealboxVariation) || config.order.defaultMeals
    );
  }, [mealboxVariation]);

  return {
    data: {
      financialVariation,
      mealboxVariation,
      portions: selectedPortions,
      meals: selectedMeals,
    },
    isLoading: productsByCategoriesQuery.isLoading,
    isFetching: productsByCategoriesQuery.isFetching,
    isError: productsByCategoriesQuery.isError,
  };
};
