import * as React from "react";
import { SVGProps } from "react";
const Card = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={6}
      y={9}
      width={20}
      height={14}
      rx={1}
      stroke="currentColor"
      strokeWidth={2}
    />
    <rect
      x={5.375}
      y={18.375}
      width={21.25}
      height={0.75}
      stroke="currentColor"
      strokeWidth={0.75}
    />
    <rect
      x={9.5}
      y={12.5}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={13.5}
      y={12.5}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={17.5}
      y={12.5}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={21.5}
      y={12.5}
      width={1}
      height={1}
      rx={0.5}
      stroke="currentColor"
    />
  </svg>
);
export default Card;
