/**
 * @module order
 * @category helpers
 */

/**
 * Check whether order ID is valid or not
 * @param {string} orderId Order ID to check
 * @returns {boolean} true or false
 */
export const isValidOrderId = (orderId: any): boolean => {
  return /^[0-9]{6,8}$/.test(orderId);
};
