import * as React from "react";
import { SVGProps } from "react";
const ClapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5234 45.4939L30.9434 33.2839C31.3799 32.747 32.0116 32.4055 32.6998 32.3342C33.388 32.2629 34.0763 32.4678 34.6134 32.9039V32.9039C35.1466 33.3401 35.4864 33.9688 35.5594 34.6538C35.6324 35.3388 35.4326 36.025 35.0034 36.5639L31.9134 41.1639"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.8881 41.1634L50.9981 30.4634C51.3113 30.3229 51.6491 30.2454 51.9922 30.2355C52.3354 30.2255 52.6771 30.2832 52.9979 30.4053C53.3188 30.5275 53.6124 30.7116 53.8621 30.9471C54.1118 31.1827 54.3126 31.4652 54.4531 31.7784C54.5936 32.0916 54.6711 32.4294 54.681 32.7725C54.691 33.1157 54.6333 33.4574 54.5111 33.7782C54.389 34.0991 54.2049 34.3927 53.9693 34.6424C53.7338 34.8921 53.4513 35.0929 53.1381 35.2334L41.9981 42.3534L55.1081 36.4834C55.7406 36.2009 56.4595 36.1813 57.1065 36.4289C57.7535 36.6764 58.2756 37.1709 58.5581 37.8034C58.8405 38.4359 58.8602 39.1548 58.6126 39.8018C58.3651 40.4488 57.8706 40.9709 57.2381 41.2534L42.9981 47.6534L55.3981 43.5134C56.0306 43.2296 56.75 43.2087 57.3979 43.4553C58.0459 43.702 58.5693 44.1959 58.8531 44.8284C59.1369 45.4609 59.1578 46.1803 58.9111 46.8282C58.6645 47.4762 58.1706 47.9996 57.5381 48.2834L43.8881 53.0034L51.8881 50.3334C53.3181 50.3334 54.7581 50.3334 55.3381 51.6434C55.9181 52.9534 55.4081 54.8334 54.0281 55.1034L41.8481 59.5934L34.0981 63.0634C33.3155 63.4136 32.4716 63.6061 31.6146 63.6297C30.7576 63.6534 29.9043 63.5078 29.1037 63.2012C28.303 62.8947 27.5706 62.4333 26.9485 61.8434C26.3264 61.2535 25.8267 60.5467 25.4781 59.7634L21.9281 51.8434C21.4673 50.8153 21.2777 49.6863 21.3775 48.5641C21.4773 47.4419 21.8631 46.364 22.4981 45.4334"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 42.3078L29 29.7878C29.2058 29.5129 29.4639 29.2813 29.7594 29.1064C30.0549 28.9314 30.3821 28.8164 30.7221 28.7681C31.0621 28.7198 31.4083 28.7391 31.7408 28.8248C32.0734 28.9106 32.3857 29.0611 32.66 29.2678V29.2678C33.78 30.1378 34.06 31.1578 33.21 32.2678"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.5625 34.0016L49.0625 26.5516C49.3703 26.3994 49.7052 26.3094 50.0479 26.2869C50.3906 26.2643 50.7343 26.3096 51.0595 26.4202C51.3846 26.5307 51.6848 26.7044 51.9427 26.9311C52.2006 27.1579 52.4112 27.4333 52.5625 27.7416C53.2025 29.0316 52.2925 29.8516 50.9925 30.4916"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0711 48.6097C20.5094 47.6337 20.208 46.5298 20.1957 45.4038C20.1834 44.2779 20.4608 43.1676 21.0011 42.1797"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.6287 25.1188L32.7188 17.4688"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0291 23.0203L40.7891 15.5703"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.2891 22.3688L48.4591 16.2188"
      stroke="#282828"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ClapIcon;
