import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import {
  ACCOUNT_STATUSES,
  PAYMENT_PARTNER_IDS,
  PRODUCT_CATEGORY_IDS,
  language,
} from "@chef/constants";
import { sumByProperty } from "@chef/utils/array";
import {
  getWeek,
  getYear,
  isAddonProduct,
  isLimitedQuantityProduct,
  isMealboxProduct,
  isStandaloneProduct,
  nextDeliveryWeekDateObj,
} from "@chef/helpers";
import { createAndSendEvent } from "@chef/events";
import { nonNullable } from "@chef/utils/nonNullable";

import { BillingQuery, ProductsByCategoriesQuery, api } from "..";
import { showErrorNotification } from "./util";
import {
  billingApi,
  IBillingAgreementArgs,
  showNotification,
} from "../features";
import {
  getStandaloneProductCategoryIdsFromProductCategories,
  productAndVariationToTrackingData,
} from "../helpers";

const intl = (
  {
    no: {
      YOUR_MEALBOX_CHANGED: "Din matkasse er oppdatert",
      DELIVERY_INFORMATION_CHANGED: "Leveringsinformasjon oppdatert",
      ADDONS_CHANGED: "Abonnerte dagligvarer oppdatert",
      SUBSCRIPTION_PAUSED: "Abonnement pauset",
      SUBSCRIPTION_RESUMED: "Abonnement gjenopptatt",
    },

    se: {
      YOUR_MEALBOX_CHANGED: "Din matkasse är uppdaterad",
      DELIVERY_INFORMATION_CHANGED: "Leveransuppgifter uppdaterad",
      ADDONS_CHANGED: "Prenumererade livsmedel uppdaterad",
      SUBSCRIPTION_PAUSED: "Prenumeration avslutad",
      SUBSCRIPTION_RESUMED: "Prenumeration återupptagen",
    },

    dk: {
      YOUR_MEALBOX_CHANGED: "Din måltidskasse er opdateret",
      DELIVERY_INFORMATION_CHANGED: "Leveringsoplysninger oppdateret",
      ADDONS_CHANGED: "Abonnement på specialvarer oppdateret",
      SUBSCRIPTION_PAUSED: "Abonnement pauset",
      SUBSCRIPTION_RESUMED: "Abonnement genoptaget",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  initBillingAgreementFulfilledListener(listener);
  initBillingAgreementMatchRejected(listener);
};

const initBillingAgreementMatchRejected = (
  listener: ListenerMiddlewareInstance,
) =>
  listener.startListening({
    matcher: billingApi.endpoints.updateBillingAgreement.matchRejected,
    effect: showErrorNotification,
  });

const initBillingAgreementFulfilledListener = (
  listener: ListenerMiddlewareInstance,
) =>
  listener.startListening({
    matcher: billingApi.endpoints.updateBillingAgreement.matchFulfilled,
    effect: async (action, { dispatch }) => {
      const weekObj = nextDeliveryWeekDateObj();
      const week = getWeek(weekObj);
      const year = getYear(weekObj);

      const originalArgs = action.meta.arg.originalArgs;

      const billingQuery = await dispatch(
        api.endpoints.billing.initiate(),
      ).unwrap();

      const updatedBasket = originalArgs.basket;

      const originalBasket = billingQuery?.billing.baskets?.find(() => true);

      const updatedStatus = originalArgs.status;

      if (!originalArgs._suppressNotification) {
        handleShowAccountStatusChanged({
          updatedStatus,
          oldStatus: billingQuery.billing.status,
        });

        handleShowMealboxChanged({ originalBasket, updatedBasket });
        handleShowDeliveryInformationUpdated({ originalBasket, updatedBasket });
        handleShowAddonsChanged({ originalBasket, updatedBasket });
      }

      const productCategories = await dispatch(
        api.endpoints.productCategories.initiate(),
      );

      const productsByCategoriesQuery = await dispatch(
        api.endpoints.productsByCategories.initiate({
          categoryIds: [
            PRODUCT_CATEGORY_IDS.FINANCIAL,
            PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
            ...getStandaloneProductCategoryIdsFromProductCategories(
              productCategories.data?.productCategories || [],
            ),
          ],
          week,
          year,
        }),
      ).unwrap();

      handleTrackSubscriptionUpdated({
        originalBillingQuery: billingQuery,
        updatedBasket,
        productsByCategoriesQuery: productsByCategoriesQuery,
      });
    },
  });

const handleShowAccountStatusChanged = ({
  updatedStatus,
  oldStatus,
}: {
  updatedStatus?: number;
  oldStatus?: number;
}) => {
  if (oldStatus === updatedStatus) {
    return;
  }

  if (updatedStatus === ACCOUNT_STATUSES.ACTIVE) {
    showNotification({
      type: "success",
      message: intl.SUBSCRIPTION_RESUMED,
    });
  }

  if (updatedStatus === ACCOUNT_STATUSES.PAUSED) {
    showNotification({
      type: "success",
      message: intl.SUBSCRIPTION_PAUSED,
    });
  }
};

const handleShowMealboxChanged = ({
  originalBasket,
  updatedBasket,
}: {
  originalBasket?: BillingQuery["billing"]["baskets"][number];
  updatedBasket?: IBillingAgreementArgs["basket"];
}) => {
  const originalMealboxVariationId = originalBasket?.basketProducts.find((bp) =>
    isMealboxProduct(bp.variation.product),
  )?.variationId;

  const updatedBasketHasOriginalVariationId =
    updatedBasket?.basketProducts?.some(
      (bp) => bp.subscribedVariationId === originalMealboxVariationId,
    );

  if (updatedBasket && !updatedBasketHasOriginalVariationId) {
    showNotification({
      type: "success",
      message: intl.YOUR_MEALBOX_CHANGED,
    });
  }
};

const handleShowDeliveryInformationUpdated = ({
  originalBasket,
  updatedBasket,
}: {
  originalBasket?: BillingQuery["billing"]["baskets"][number];
  updatedBasket?: IBillingAgreementArgs["basket"];
}) => {
  const shippingAddressIdWasChanged =
    originalBasket?.shippingAddressId !== updatedBasket?.shippingAddress;

  const timeblockIdWasChanged =
    originalBasket?.timeblockId !== updatedBasket?.timeblock;

  const originalDeliveryInterval = originalBasket?.deliveryInterval.toString();

  const deliveryIntervalWasChanged =
    originalDeliveryInterval !== updatedBasket?.subscribedDeliveryInterval;

  const deliveryInformationWasUpdated =
    updatedBasket?.shippingAddress !== undefined &&
    (shippingAddressIdWasChanged ||
      timeblockIdWasChanged ||
      deliveryIntervalWasChanged);

  if (updatedBasket && deliveryInformationWasUpdated) {
    showNotification({
      type: "success",
      message: intl.DELIVERY_INFORMATION_CHANGED,
    });
  }
};

const handleShowAddonsChanged = ({
  originalBasket,
  updatedBasket,
}: {
  originalBasket?: BillingQuery["billing"]["baskets"][number];
  updatedBasket?: IBillingAgreementArgs["basket"];
}) => {
  const originalAddonCount = originalBasket?.basketProducts
    ? sumByProperty(originalBasket?.basketProducts, "quantity")
    : 0;

  const updatedAddonCount = updatedBasket?.basketProducts
    ? sumByProperty(updatedBasket?.basketProducts, "subscribedQuantity")
    : 0;

  const addonCountChanged = originalAddonCount !== updatedAddonCount;

  const originalAddonProducts = originalBasket?.basketProducts.filter(
    (bp) =>
      isAddonProduct(bp.variation.product) ||
      isStandaloneProduct(bp.variation.product) ||
      isLimitedQuantityProduct(bp.variation.product),
  );

  const variationsWereChanged = !originalAddonProducts?.every(
    (originalBasketProduct) =>
      updatedBasket?.basketProducts?.find(
        (updatedBasketProduct) =>
          updatedBasketProduct.subscribedVariationId ===
          originalBasketProduct.variationId,
      ),
  );

  const addonsWereChanged = addonCountChanged || variationsWereChanged;

  if (updatedBasket && addonsWereChanged) {
    showNotification({
      type: "success",
      message: intl.ADDONS_CHANGED,
    });
  }
};

const handleTrackSubscriptionUpdated = ({
  originalBillingQuery,
  productsByCategoriesQuery,
  updatedBasket,
}: {
  originalBillingQuery: BillingQuery;
  productsByCategoriesQuery?: ProductsByCategoriesQuery;
  updatedBasket?: IBillingAgreementArgs["basket"];
}) => {
  const billing = originalBillingQuery.billing;

  const paymentPartnerId = billing.paymentPartnerId;

  const updatedBasketProducts = updatedBasket?.basketProducts || [];

  const allVariations = productsByCategoriesQuery?.productsByCategories
    ?.flatMap((category) => category.products)
    .flatMap((product) => product.variations);

  const trackingProducts = updatedBasketProducts
    .map((basketProduct) => {
      const variation = allVariations?.find(
        (v) => v.variationId === basketProduct.subscribedVariationId,
      );

      if (!variation) {
        console.error("Failed to find variation for basketProduct", {
          basketProduct,
        });
        return null;
      }

      return {
        selectedProduct: variation?.product,
        selectedVariation: variation,
        quantity: basketProduct.subscribedQuantity,
      };
    })
    .filter(nonNullable)
    .map(productAndVariationToTrackingData);

  createAndSendEvent("subscriptionUpdated", {
    affiliation: "Frontend process",
    payment_method: (paymentPartnerId === PAYMENT_PARTNER_IDS.COLLECTOR
      ? "invoice"
      : "cc") as "invoice" | "cc",
    products: trackingProducts,
  });
};
