import React, { useEffect, useRef, useState, useCallback } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import { ChevronRight, ChevronLeft } from "@chef/icons/large";

import { ButtonCircle } from "../ButtonCircle";
import TabButton from "../../internals/TabButton";

interface TabProps {
  defaultIndex?: number;
  onChange?: <T>(arg: T) => void;
  className?: string;
  children: React.ReactNode[];
}

export const Tabs = ({
  defaultIndex = 0,
  onChange,
  className,
  children,
}: TabProps) => {
  const wrapper = useRef<HTMLDivElement & { scrollY: number }>(null);

  const [showControls, setShowControls] = useState(false);
  const [stepLeftDisabled, setStepLeftDisabled] = useState(defaultIndex <= 0);
  const [stepRightDisabled, setStepRightDisabled] = useState(
    defaultIndex >= children.length - 1,
  );

  const handleResize = useCallback(() => {
    if (!wrapper.current) {
      return;
    }
    setShowControls(wrapper.current.scrollWidth > wrapper.current.clientWidth);

    setStepLeftDisabled(defaultIndex <= 0);
    setStepRightDisabled(defaultIndex >= children.length - 1);
  }, [defaultIndex, children.length]);

  useEffect(() => {
    if (!wrapper.current) {
      return;
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(handleResize, [children.length, handleResize]);

  const handleStepLeft = () => {
    if (!wrapper.current) {
      return;
    }
    wrapper.current.scrollTo({
      left:
        wrapper.current.scrollLeft - wrapper.current.children[0].clientWidth,
      behavior: "smooth",
    });
  };

  const handleStepRight = () => {
    if (!wrapper.current) {
      return;
    }

    wrapper.current.scrollTo({
      left:
        wrapper.current.scrollLeft + wrapper.current.children[0].clientWidth,
      behavior: "smooth",
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!e) {
      return;
    }
    const target = e.target as HTMLDivElement;
    setStepLeftDisabled(target.scrollLeft === 0);
    setStepRightDisabled(
      target.scrollLeft + target.offsetWidth >= target.scrollWidth,
    );
  };

  return (
    <Tab.Group
      as="div"
      className={className}
      defaultIndex={defaultIndex}
      onChange={(i: number | React.FormEvent<HTMLDivElement>) => {
        if (typeof i === "number") {
          onChange?.(i);
        }
      }}
    >
      <Tab.List className="flex items-center justify-center">
        <ButtonCircle
          Icon={ChevronLeft}
          className={clsx("flex-shrink-0 mr-3", !showControls && "invisible")}
          onClick={handleStepLeft}
          disabled={stepLeftDisabled}
          small
        />

        <div
          className="inline-flex p-1 overflow-x-scroll scrollbar-hidden"
          ref={wrapper}
          onScroll={handleScroll}
        >
          {React.Children.map(children, (child, index) => {
            if (!child) {
              return null;
            }

            const props = (child as React.ReactElement).props;

            const title = props["data-title"] as string;
            const subtitle = props["data-subtitle"] as string;
            const disabled = props.disabled as boolean;

            return (
              <Tab key={`tab-${index}`}>
                {({ selected }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  const ref = useRef<HTMLDivElement>(null);

                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    if (!ref.current || !wrapper.current) {
                      return;
                    }

                    if (selected) {
                      const offset =
                        ref.current.offsetLeft - wrapper.current.offsetLeft;

                      if (
                        offset + ref.current.offsetWidth >
                        wrapper.current.scrollLeft + wrapper.current.clientWidth
                      ) {
                        wrapper.current.scrollTo({
                          left:
                            offset -
                            wrapper.current.clientWidth +
                            ref.current.offsetWidth,
                          top: wrapper.current.scrollY,
                          behavior: "smooth",
                        });
                      }

                      if (offset < wrapper.current.scrollLeft) {
                        wrapper.current.scrollTo({
                          left: offset,
                          top: wrapper.current.scrollY,
                          behavior: "smooth",
                        });
                      }
                    }
                  }, [selected]);

                  return (
                    <TabButton
                      title={title}
                      subtitle={subtitle}
                      selected={selected}
                      disabled={disabled}
                      ref={ref}
                    />
                  );
                }}
              </Tab>
            );
          })}
        </div>

        <ButtonCircle
          Icon={ChevronRight}
          className={clsx("flex-shrink-0 ml-3", !showControls && "invisible")}
          onClick={handleStepRight}
          disabled={stepRightDisabled}
          small
        />
      </Tab.List>
      <Tab.Panels>
        {React.Children.map(children, (child, index) => (
          <Tab.Panel key={`panel-${index}`}>{child}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
