import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      SORTING_INGREDIENTS: "Sorterer råvarer...",
      CALCULATING_CALORIES_0: "Beregner kalorier...",
      CALCULATING_CALORIES_1: "Balanserer næringsinnhold...",
      PUTTING_TOGETHER_MENU_0: "Filterer oppskrifter...",
      PUTTING_TOGETHER_MENU_1: "Finner varierte retter...",
      PUTTING_TOGETHER_MENU_2: "Setter sammen din meny...",
    },
    se: {
      SORTING_INGREDIENTS: "Sorterar råvaror...",
      CALCULATING_CALORIES_0: "Beräknar kalorier...",
      CALCULATING_CALORIES_1: "Beräknar kalorier...",
      PUTTING_TOGETHER_MENU_0: "Sätter ihop din meny...",
      PUTTING_TOGETHER_MENU_1: "Sätter ihop din meny...",
      PUTTING_TOGETHER_MENU_2: "Sätter ihop din meny...",
    },
    dk: {
      SORTING_INGREDIENTS: "Sorterer ingredienser...",
      CALCULATING_CALORIES_0: "Beregner kalorier...",
      CALCULATING_CALORIES_1: "Beregner kalorier...",
      PUTTING_TOGETHER_MENU_0: "Sætter din menu sammen...",
      PUTTING_TOGETHER_MENU_1: "Sætter din menu sammen...",
      PUTTING_TOGETHER_MENU_2: "Sætter din menu sammen...",
    },
  } as const
)[language];
