import * as React from "react";
import { SVGProps } from "react";
const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 11.6663V15.222C17 15.6936 16.8127 16.1458 16.4793 16.4792C16.1459 16.8126 15.6937 16.9999 15.2222 16.9999H2.77778C2.30628 16.9999 1.8541 16.8126 1.5207 16.4792C1.1873 16.1458 1 15.6936 1 15.222V11.6663"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.55469 7.22339L8.99913 11.6681L13.4436 7.22339"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.6674V1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Download;
