import * as React from "react";
import { SVGProps } from "react";
const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 12.0731C24 5.4046 18.6281 0 12 0C5.37188 0 0 5.4046 0 12.0731C0 18.1002 4.3875 23.0945 10.125 24V15.563H7.07812V12.0731H10.125V9.41324C10.125 6.3879 11.9156 4.71605 14.6578 4.71605C15.9703 4.71605 17.3438 4.95186 17.3438 4.95186V7.92297H15.8297C14.3391 7.92297 13.875 8.85439 13.875 9.80939V12.0731H17.2031L16.6711 15.563H13.875V24C19.6125 23.0945 24 18.1002 24 12.0731Z"
      fill="currentColor"
    />
    <path
      d="M16.6711 15.4688L17.2031 12H13.875V9.75C13.875 8.80078 14.3391 7.875 15.8297 7.875H17.3438V4.92188C17.3438 4.92188 15.9703 4.6875 14.6578 4.6875C11.9156 4.6875 10.125 6.34922 10.125 9.35625V12H7.07812V15.4688H10.125V23.8547C10.7367 23.9508 11.3625 24 12 24C12.6375 24 13.2633 23.9508 13.875 23.8547V15.4688H16.6711Z"
      fill="white"
    />
  </svg>
);
export default Facebook;
