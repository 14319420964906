import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";
import { getWeek, getYear, nextDeliveryWeekDateObj } from "@chef/helpers";

import { billingApi, showNotification } from "../features";
import { CalendarQuery } from "../graphql/generated";
import { RootState } from "../index";
import { getCurrentWeekDataAndBasketFromCalendar } from "../helpers/calendar";

const intl = (
  {
    no: {
      SCHEDULER_UPDATE_SUCCESS: (week: number, delivery: boolean) =>
        `Levering uke ${week} ${delivery ? "bestilt" : "avbestilt"}`,
      SCHEDULER_UPDATE_TIMEBLOCK_OR_ADDRESS_SUCCESS: (week: number) =>
        `Levering endret for uke ${week}`,
      SCHEDULER_UPDATE_FAILED: (week: number, delivery: boolean) =>
        `Noe gikk galt ved ${
          delivery ? "bestilling" : "avbestilling"
        } av levering for uke ${week}, kontakt kundeservice`,
    },

    se: {
      SCHEDULER_UPDATE_SUCCESS: (week: number, delivery: boolean) =>
        `Leverans vecka ${week} ${delivery ? "bokad" : "avbokad"}`,
      SCHEDULER_UPDATE_TIMEBLOCK_OR_ADDRESS_SUCCESS: (week: number) =>
        `Leverans ändrad för vecka ${week}`,
      SCHEDULER_UPDATE_FAILED: (week: number, delivery: boolean) =>
        `Något gick fel vid ${
          delivery ? "bokning" : "avbokning"
        } av leverans för vecka ${week}, kontakta kundservice`,
    },

    dk: {
      SCHEDULER_UPDATE_SUCCESS: (week: number, delivery: boolean) =>
        `Levering uge ${week} ${delivery ? "bestilt" : "afbestilt"}`,
      SCHEDULER_UPDATE_TIMEBLOCK_OR_ADDRESS_SUCCESS: (week: number) =>
        `Levering ændret for uge ${week}`,
      SCHEDULER_UPDATE_FAILED: (week: number, delivery: boolean) =>
        `Noget gik galt ved ${
          delivery ? "bestilling" : "afbestilling"
        } af levering for uge ${week}, kontakt kundeservice`,
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.createOrUpdateScheduler.matchFulfilled,
      billingApi.endpoints.createOrUpdateScheduler.matchRejected,
    ),
    effect: async (action, context) => {
      const payload = action["meta"].arg.originalArgs;
      const originalState = context.getOriginalState() as RootState;

      const currentDate = nextDeliveryWeekDateObj();
      const currentWeek = getWeek(currentDate);
      const currentYear = getYear(currentDate);

      const originalScheduler4w = originalState.api.queries[
        `calendar({"range":4,"week":${currentWeek},"year":${currentYear}})`
      ]?.data as CalendarQuery;

      const originalScheduler12w = originalState.api.queries[
        `calendar({"range":12,"week":${currentWeek},"year":${currentYear}})`
      ]?.data as CalendarQuery;

      const originalScheduler = originalScheduler12w || originalScheduler4w;

      if (!originalScheduler) {
        return;
      }

      const originalWeekObject = getCurrentWeekDataAndBasketFromCalendar({
        calendar: originalScheduler.calendar,
        week: payload.week,
        year: currentYear,
      });

      if (!originalWeekObject) {
        return;
      }

      const hadDeliveryInOriginalScheduler =
        originalWeekObject.basket.shippingDetails.hasDelivery;
      const hasDeliveryInNewScheduler = payload.delivery;

      const userHasCancelledDelivery =
        !hasDeliveryInNewScheduler && hadDeliveryInOriginalScheduler;

      const userHasOrderedDelivery =
        hasDeliveryInNewScheduler && !hadDeliveryInOriginalScheduler;

      const userHasUpdatedTimeblock =
        !userHasOrderedDelivery &&
        !userHasCancelledDelivery &&
        payload.timeblockId !==
          originalWeekObject.basket.shippingDetails.plannedDeliveryInformation
            .actualTimeblockId;

      const userHasUpdatedAddress =
        !userHasOrderedDelivery &&
        !userHasCancelledDelivery &&
        payload.addressId !==
          originalWeekObject.basket.shippingDetails.addressInfo.addressId;

      if (action["meta"].requestStatus === "rejected") {
        showNotification({
          type: "error",
          message: intl.SCHEDULER_UPDATE_FAILED(payload.week, payload.delivery),
        });
      }

      if (action["meta"].requestStatus === "fulfilled") {
        if (userHasUpdatedTimeblock || userHasUpdatedAddress) {
          showNotification({
            type: "success",
            message: intl.SCHEDULER_UPDATE_TIMEBLOCK_OR_ADDRESS_SUCCESS(
              payload.week,
            ),
          });
        }
        if (userHasCancelledDelivery || userHasOrderedDelivery) {
          showNotification({
            type: "success",
            message: intl.SCHEDULER_UPDATE_SUCCESS(
              payload.week,
              payload.delivery,
            ),
          });
        }
      }
    },
  });
};
