import * as React from "react";
import { SVGProps } from "react";
const Breakfast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.22814 11.094V24.9981H2.63672V11.094"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22754 11.094V24.9981H10.7126"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22814 11.0943L5.43318 6.95605L2.63672 11.0943"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4014 6.44481H5.43359V3H12.2721L12.3894 6.44481H12.4014Z"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4922 24.9981V18.6756C26.4974 18.1175 26.7501 15.5091 25.4083 14.3944C24.0665 13.2782 21.2474 12.9473 19.5867 12.9473C17.926 12.9473 15.107 13.2797 13.7651 14.3944C12.4233 15.5106 12.676 18.1175 14.6813 18.6756V24.9981H24.4922Z"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5149 10.085L12.4014 6.95605"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Breakfast;
