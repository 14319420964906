import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";

import { findFeaturedPortraitImage } from "@chef/utils/helpers/recipes";
import { isEmptyArray } from "@chef/utils/array";
import { Card } from "@chef/components";
import {
  resetPreselectedRecipesModal,
  selectPreselectedRecipesModal,
  useMeQuery,
  usePreferencesQuery,
} from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import {
  getDishCount,
  getPreviewRecipes,
  PreviewRecipe,
} from "@chef/constants";
import { stripEmojis } from "@chef/utils/string";
import { removeDuplicatesByKey } from "@chef/utils/helpers/removeDuplicates";

import { Modal } from "../modal/Modal";

import { intl } from "./PreselectedRecipesModal.Intl";

interface PreselectedRecipesModalProps {
  portions: number;
  week: number;
  year: number;
}

interface PreselectedRecipeProps {
  recipe: PreviewRecipe;
}

// interface BulletItemProps {
//   children: React.ReactNode;
// }

// const BulletItem = ({ children }: BulletItemProps) => {
//   return (
//     <div className="flex items-center gap-2">
//       <Check className="flex items-center justify-center w-4 h-4 p-1 text-white rounded-full bg-information shrink-0" />
//       {children}
//     </div>
//   );
// };

const PreselectedRecipe = ({ recipe }: PreselectedRecipeProps) => {
  return (
    <li className="flex items-center gap-3">
      <div className="relative w-15 h-15 shrink-0">
        <Image
          src={findFeaturedPortraitImage(recipe).url}
          fill
          alt={recipe.recipeName}
          className="object-cover rounded"
          sizes="60px"
        />
      </div>

      <div className="flex items-center text-sm text-left">
        {recipe.recipeName}
      </div>
    </li>
  );
};

export const PreselectedRecipesModal = ({
  portions,
  week,
  year,
}: PreselectedRecipesModalProps) => {
  const dispatch = useDispatch();
  const { open, preferenceIds, type } = useSelector(
    selectPreselectedRecipesModal,
  );

  const { data: user } = useMeQuery();
  const isLoggedIn = !!user;

  const onClose = () => {
    dispatch(resetPreselectedRecipesModal());
  };

  const { data: preferencesQuery } = usePreferencesQuery();

  if (!preferenceIds || isEmptyArray(preferenceIds)) {
    return null;
  }

  // We need to allow multiple preference Ids to be selected and display the recipes for those selected preferenceIds in the sign-up meal box summary.
  const recipes = removeDuplicatesByKey(
    preferenceIds.flatMap((preferenceId) =>
      getPreviewRecipes({ week, year, preferenceId }),
    ),
    "recipeId",
  );

  const selectedPreference = preferencesQuery?.preferences.find((preference) =>
    preferenceIds.some((preferenceId) =>
      isEqualStrings(preference.preferenceId, preferenceId),
    ),
  );

  if (!selectedPreference || !recipes || isEmptyArray(recipes)) {
    return null;
  }

  // const remainingDishCount =
  //   getDishCount({ week, year, portions }) - recipes.length;

  const dishCount = getDishCount({ week, year, portions });

  return (
    <Modal
      name="preselected-recipes-modal"
      open={open}
      onClose={onClose}
      size="md"
    >
      <Card className="w-full min-h-screen text-center md:min-h-fit">
        <div className="mx-4 my-8 md:mx-8">
          <h2 className="flex flex-col items-center justify-center gap-1 my-2 text-xl">
            <Image
              src={`https://ggfrontendassets.azureedge.net/publicassets/preference-icons/${selectedPreference.preferenceId.toUpperCase()}.svg`}
              width={32}
              height={32}
              alt={stripEmojis(selectedPreference.preferenceName)}
              sizes="32px"
            />
            <strong>
              {type === "single"
                ? stripEmojis(selectedPreference.preferenceName)
                : intl.YOUR_PERSONALIZED_MENU}
            </strong>
          </h2>

          <div>
            {type === "single"
              ? selectedPreference.description
              : intl.DISHES_FROM_OUR_UPCOMING_WEEKS_MENU_THAT_MATCHES_YOUR_PREFERENCES}
          </div>

          {/* This can be re-implemented when we have a separte component for the mealbox summary
            <div className="flex justify-center gap-6 mt-4">
              <BulletItem>
                {intl.XX_DISHES_EACH_WEEK(
                  getDishCount({ week, year, portions }),
                )}
              </BulletItem>
              <BulletItem>
                <span className="text-sm">{intl.YOU_CHOOSE}</span>
              </BulletItem>
            </div>
          )} */}

          <hr className="my-6 border-t-1.5 border-grey-1" />

          <div className="relative">
            <ul className="grid md:grid-cols-2 gap-x-6 gap-y-3">
              {recipes.map((recipe) => (
                <PreselectedRecipe key={recipe.recipeId} recipe={recipe} />
              ))}
            </ul>
            <div className="absolute inset-0 z-0 h-full bg-gradient-to-t from-white to-transparent" />
          </div>

          <div className="mt-6">
            <h3 className="text-lg">
              <strong>{intl.CHOOSE_BETWEEN_N_DISHES(dishCount)}</strong>
              {/* NOTE: Can be re-implemented with preference-dish-filtering */}
              {/* <strong>{intl.MORE_DISHES(remainingDishCount)}</strong> */}
            </h3>

            <div className="mt-2">
              {isLoggedIn
                ? intl.ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK
                : intl.SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK}
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
