import React from "react";

interface RowLinkProps {
  LinkComponent: () => React.ReactElement | null;
  title: string;
  id?: string;
}

export const RowLink = ({
  title = "",
  LinkComponent = () => null,
  id,
  ...props
}: RowLinkProps) => {
  return (
    <div
      className="flex items-center justify-between px-6 py-4 bg-grey-3 "
      {...props}
      id={id}
    >
      <div>
        <strong>{title}</strong>
      </div>
      <div className="flex items-center">
        <LinkComponent />
      </div>
    </div>
  );
};
