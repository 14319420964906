import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../..";
import { ICategories } from "./types";

interface TrackingState {
  consents: ICategories;
  dialog: {
    open: boolean;
    dirty: boolean;
  };
  isConfigured: boolean;
}

const initialState = {
  consents: { All: false, functional: true, "Segment.io": true },
  dialog: {
    open: false,
    dirty: false,
  },
  isConfigured: false,
} as TrackingState;

export const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setConsent: (
      state,
      action: PayloadAction<{ name: keyof ICategories; enabled: boolean }>,
    ) => {
      state.consents[action.payload.name] = action.payload.enabled;

      state.dialog.dirty = true;
    },
    acceptAllConsents: (state) => {
      state.consents = {
        All: true,
        "Segment.io": true,
        functional: true,
      };

      state.dialog.dirty = false;
      state.dialog.open = false;

      // Reload the page to load any third parties.
      // We only do this if the user has previously configured consents.
      if (state.isConfigured) {
        window.location.reload();
      }

      state.isConfigured = true;
    },
    rejectAllConsents: (state) => {
      state.consents = { All: false, functional: false, "Segment.io": true };

      state.dialog.dirty = false;
      state.dialog.open = false;

      // Reload the page to clear any third parties.
      // We only do this if the user has previously configured consents.
      if (state.isConfigured) {
        window.location.reload();
      }

      state.isConfigured = true;
    },
    rejectIosAppTrackingTransparency: (state) => {
      state.consents = { All: false, functional: true, "Segment.io": true };

      state.dialog.dirty = false;
      state.dialog.open = false;

      // Reload the page to clear any third parties.
      // We only do this if the user has previously configured consents.
      if (state.isConfigured) {
        window.location.reload();
      }

      state.isConfigured = true;
    },
    openDialog: (state) => {
      state.dialog.open = true;
    },
    hideDialog: (state) => {
      state.dialog.open = false;
      state.isConfigured = true;

      // Reload the page if dirty to clear/load any third parties
      const shouldReload = state.dialog.dirty;

      state.dialog.dirty = false;

      if (shouldReload) {
        window.location.reload();
      }
    },
    toggleDialog: (state) => {
      state.dialog.open = !state.dialog.open;

      if (!state.dialog.open && state.dialog.dirty) {
        const shouldReload = state.isConfigured;

        state.isConfigured = true;
        state.dialog.dirty = false;

        // Reload the page to clear any third parties
        // We only do this if the user has previously configured consents.
        if (shouldReload) {
          window.location.reload();
        }
      }

      state.dialog.dirty = false;
    },
  },
});

export const {
  setConsent,
  toggleDialog,
  hideDialog,
  openDialog,
  acceptAllConsents,
  rejectAllConsents,
  rejectIosAppTrackingTransparency,
} = trackingSlice.actions;

export const selectTrackingConsents = (state: RootState) =>
  state.tracking.consents;

export const selectTrackingDialogOpen = (state: RootState) =>
  state.tracking.dialog.open;

export const selectTrackingIsConfigured = (state: RootState) =>
  state.tracking.isConfigured;

export default trackingSlice.reducer;
