import * as React from "react";
import { SVGProps } from "react";
const Easter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.16699 14.5836C3.04781 9.18779 6.18027 3.50037 10.9169 3.50037C15.6535 3.50037 18.7859 9.18779 19.6668 14.5836"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8331 19.0835C20.0689 25.4826 15.484 29 10.9165 29C6.3491 29 1.76416 25.4884 1 19.0835"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8331 19.0835L17.9164 17.3335L14.4165 19.6668L10.9165 17.3335L7.41658 19.6668L3.91663 17.3335L1 19.0835"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7588 8.70361L21.9996 9.91693L19.2579 12.6586"
      fill="currentColor"
    />
    <path
      d="M17.7588 8.70361L21.9996 9.91693L19.2579 12.6586"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16689 1.16699C8.4669 1.96615 8.0994 3.3953 8.53106 4.01362"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.53144 4.0136C8.28061 3.68693 7.13146 2.33362 5.08398 2.33362"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8327 9.91692C14.477 9.91692 14.9993 9.39459 14.9993 8.75027C14.9993 8.10595 14.477 7.58362 13.8327 7.58362C13.1883 7.58362 12.666 8.10595 12.666 8.75027C12.666 9.39459 13.1883 9.91692 13.8327 9.91692Z"
      fill="currentColor"
    />
  </svg>
);
export default Easter;
