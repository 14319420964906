import { STOCK_SOLD_OUT_STATES } from "@chef/constants";
import { PickAndMixQuery } from "../graphql/generated";
import { getAttribute } from "./product";

export const getStockData = (args: {
  addedQuantity: number;
  variation: Pick<
    PickAndMixQuery["pickAndMix"][number]["product"]["variations"][number],
    "attributes" | "variationId"
  >;
}) => {
  // NOTE: addedQuantity should take into account the "original" addedQuantity to be more accurate
  // but this isn't supported at the time of writing this comment as it increases complexity
  // by a lot.

  const { addedQuantity, variation } = args;

  const maxPurchasableAmount = +(
    getAttribute("max_variation_qty_allowed", variation) || 0
  );

  const isMaximumAmountAdded = addedQuantity >= maxPurchasableAmount;

  let stockState:
    | (typeof STOCK_SOLD_OUT_STATES)[keyof typeof STOCK_SOLD_OUT_STATES]
    | undefined;

  // ideally "addedQuantity" would do a strict === 0 check; but the user might send it in as undefined
  if (maxPurchasableAmount === 0) {
    stockState = STOCK_SOLD_OUT_STATES.SOLD_OUT;
  } else if (isMaximumAmountAdded) {
    stockState = STOCK_SOLD_OUT_STATES.MAXIMUM_AMOUNT_ADDED;
  }

  return {
    maxPurchasableAmount,
    stockState,
  };
};
