import Image from "next/image";

import { language } from "@chef/constants";

const CHEFFELO_LOGO =
  "https://ggfrontendassets.azureedge.net/publicassets/cheffelo-logo.png";

export const CheffeloBanner = () => {
  return (
    <a
      href="https://www.cheffelo.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <aside className="flex justify-center items-center h-15 border-t-1.5">
        <span>
          {intl.A_PART_OF} <strong>cheffelo.com</strong>
        </span>
        <Image
          src={CHEFFELO_LOGO}
          alt="Cheffelo"
          width={96}
          height={17}
          className="object-contain ml-2"
        />
      </aside>
    </a>
  );
};

const intl = {
  no: {
    A_PART_OF: "Del av",
  },
  se: {
    A_PART_OF: "En del av",
  },
  dk: {
    A_PART_OF: "En del af",
  },
}[language];
