import React from "react";

import { Dinner } from "@chef/icons/small";

import { Currency, Hr } from "../components";

import ReceiptSection from "./ReceiptSection";
import ReceiptLine from "./ReceiptLine";
import { Language } from "../../types";

interface ReceiptSignupProps extends React.HTMLAttributes<HTMLDivElement> {
  language: Language;
  recipes: {
    url: string;
    altText: string;
  }[];
  mealboxText: string;
  mealboxName: string;
  mealboxPrice: number;
  extraCost?: number;
  extraCostText?: string;
  discountAmount: number;
  discountIsCurrency: boolean;
  DiscountComponent?: () => JSX.Element;
  giftcardAmount: number;
  GiftcardComponent?: () => JSX.Element;
  deliveryPrice: number;
  showDeliveryPrice?: boolean;
  totalSum: number;
  className: string;
  Link?: () => JSX.Element;
  giftcardDiscountsInfoExpanded: boolean;
  signupDiscountsInfoExpanded: boolean;
  toggleSignupDiscountsInfoExpanded: () => void;
  toggleGiftcardDiscountsInfoExpanded: () => void;
}

const intl = {
  no: {
    RECIPESCHANGE: "Hvis du endrer startdatoen endres menyen",
    TITLE: "Din bestilling",
    GIFTCARD: "Gavekort",
    DELIVERY: "Levering",
    TOTALTEXT: "Totalt",
    FIRSTDELIVERYHEADER: "Første levering",
    DELIVERYINTERVALHEADER: "Etterfølgende leveranser",
    DELIVERYPAUSEHEADER: "Du kan når som helst pause leveransene",
    DISCOUNT: "Rabatt",
  },
  se: {
    RECIPESCHANGE: "Du kan ändra rätterna efter du skapat konto",
    TITLE: "Din beställning",
    GIFTCARD: "Presentkort",
    DELIVERY: "Leverans",
    TOTALTEXT: "Totalt",
    FIRSTDELIVERYHEADER: "Första leverans",
    DELIVERYINTERVALHEADER: "Efterföljande leveranser",
    DELIVERYPAUSEHEADER: "Du kan pausa leveranser när som helst",
    DISCOUNT: "Rabatt",
  },
  dk: {
    RECIPESCHANGE: "Hvis du ændrer startdatoen, ændres menuen",
    TITLE: "Din bestilling",
    GIFTCARD: "Gavekort",
    DELIVERY: "Levering",
    TOTALTEXT: "I alt",
    FIRSTDELIVERYHEADER: "Første levering",
    DELIVERYINTERVALHEADER: "Efterfølgende leveringer",
    DELIVERYPAUSEHEADER:
      "Du kan til enhver tid sætte dine leveringer på pause og ændre datoen for efterfølgende leveringer.",
    DISCOUNT: "Rabat",
  },
};

const ReceiptSignup = ({
  language = "no",
  recipes = [],
  mealboxText = "",
  mealboxName = "",
  mealboxPrice = 0,
  extraCost = 0,
  extraCostText = "",
  discountAmount = 0,
  discountIsCurrency = true,
  DiscountComponent,
  giftcardAmount = 0,
  GiftcardComponent,
  deliveryPrice = 0,
  showDeliveryPrice = true,
  totalSum = 0,
  className,
  giftcardDiscountsInfoExpanded,
  signupDiscountsInfoExpanded,
  toggleSignupDiscountsInfoExpanded,
  toggleGiftcardDiscountsInfoExpanded,
  Link,
  ...props
}: ReceiptSignupProps) => {
  const { TITLE, RECIPESCHANGE, GIFTCARD, DELIVERY, TOTALTEXT, DISCOUNT } =
    intl[language];
  return (
    <div className={className} {...props} id="receipt-details-card">
      <h3 className="flex justify-between text-lg">
        <strong>{TITLE}</strong>
        {Link && <Link />}
      </h3>

      {recipes.length !== 0 && (
        <>
          <div className="grid grid-cols-4 gap-2 mb-4">
            {recipes.slice(0, 4).map(({ url, altText }, i) => {
              const amountofMeals = recipes.length;
              const remainingMeals = amountofMeals - 3;

              if (amountofMeals > 4 && i >= 3) {
                return (
                  <div className="flex items-center justify-center bg-grey-3">
                    +{remainingMeals}
                  </div>
                );
              }
              return (
                <div
                  key={i}
                  className="relative block object-cover object-center w-full pb-32 bg-cover md:pb-40 "
                >
                  <img
                    src={url}
                    className="absolute top-0 bottom-0 left-0 object-cover object-center w-full h-full"
                    alt={altText}
                  />
                </div>
              );
            })}
          </div>
          <p className="text-sm">{RECIPESCHANGE}</p>
        </>
      )}

      <Hr className="my-3" />
      <ReceiptLine language={language} Icon={<Dinner />} price={mealboxPrice}>
        {mealboxText}
      </ReceiptLine>

      {+extraCost > 0 && extraCostText ? (
        <ReceiptLine language={language} price={extraCost} isIndented>
          {extraCostText}
        </ReceiptLine>
      ) : (
        <ReceiptLine isIndented language={language}>
          {mealboxName}
        </ReceiptLine>
      )}

      {DiscountComponent && (
        <>
          <Hr className="my-3" />
          <ReceiptSection
            id="discount"
            text={DISCOUNT}
            isDiscount={true}
            price={discountAmount}
            priceIsCurrency={discountIsCurrency}
            priceIsHighlighted
            isOpenDisclosure={signupDiscountsInfoExpanded}
            onDisclosureClicked={toggleSignupDiscountsInfoExpanded}
            language={language}
          >
            <DiscountComponent />
          </ReceiptSection>
        </>
      )}
      {GiftcardComponent && (
        <>
          <Hr className="my-3" />
          <ReceiptSection
            text={GIFTCARD}
            id="gift-card"
            price={-giftcardAmount}
            priceIsHighlighted
            priceIsCurrency
            language={language}
            isOpenDisclosure={giftcardDiscountsInfoExpanded}
            onDisclosureClicked={toggleGiftcardDiscountsInfoExpanded}
          >
            <GiftcardComponent />
          </ReceiptSection>
        </>
      )}
      {showDeliveryPrice && (
        <>
          <Hr className="my-3" />
          <ReceiptLine
            price={deliveryPrice}
            hidePriceIfZero={false}
            language={language}
          >
            {DELIVERY}
          </ReceiptLine>
        </>
      )}

      <Hr className="my-3" />
      {totalSum !== null && (
        <div className="flex justify-between">
          <strong>{TOTALTEXT}</strong>
          <Currency language={language} emphasized>
            {totalSum}
          </Currency>
        </div>
      )}
    </div>
  );
};

export default ReceiptSignup;
