import * as React from "react";
import { SVGProps } from "react";
const Pepperbørse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.999999C0 0.447714 0.447715 0 1 0H39C39.5523 0 40 0.447715 40 1V39C40 39.5523 39.5523 40 39 40H0.999999C0.447714 40 0 39.5523 0 39V0.999999Z"
      fill="#F4F4F1"
    />
    <path
      d="M20.9428 13.7298C19.4065 17.9531 24.3687 20.0029 25.7638 23.3072C26.7704 25.6751 28.3067 29.3152 28.3067 29.3152C28.3067 29.3152 27.2295 30.8702 24.2451 32.1425C21.2607 33.4148 19.3888 33.1144 19.3888 33.1144C19.3888 33.1144 17.8524 29.4742 16.8459 27.1064C15.4508 23.802 17.411 18.8013 13.314 16.9812L14.0734 14.7194C12.8196 14.631 11.7247 13.9595 11.1596 12.6343C10.3119 10.5845 11.6717 8.12827 13.7025 7.26242C15.7333 6.39656 18.4529 7.12105 19.3182 9.17083C19.8656 10.4431 19.6184 11.6624 18.859 12.6166L20.9428 13.7298Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 24.5C23.5 24.5 22.3653 25.6466 21.5 26C20.6347 26.3711 19.5 26.5 19.5 26.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Pepperbørse;
