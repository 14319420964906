import * as React from "react";
import { SVGProps } from "react";
const Vipps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 9C3.17778 12.5577 9.02667 17.7241 15 9.92809"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M12.5 3.5C12.5 4.38458 11.8077 5 10.9998 5C10.1922 5 9.5 4.38458 9.5 3.5C9.5 2.61521 10.1922 2 10.9998 2C11.8077 2 12.5 2.61521 12.5 3.5Z"
      fill="currentColor"
    />
  </svg>
);
export default Vipps;
