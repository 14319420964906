import React from "react";

interface ConditionalRendererProps {
  shouldRender?: boolean;
  children?: React.ReactNode;
}

export const ConditionalRenderer = ({
  shouldRender,
  children,
}: ConditionalRendererProps): JSX.Element => {
  if (!shouldRender) {
    return null as unknown as JSX.Element;
  }

  return children as JSX.Element;
};
