import React from "react";
import clsx from "clsx";
import { Container, Switch } from "../components";
import { Language } from "../../types";

const intl = {
  no: {
    UPCOMING: "KOMMER",
    PAUSED: "PAUSET",
  },
  se: {
    UPCOMING: "KOMMER",
    PAUSED: "AVBOKAD",
  },
  dk: {
    UPCOMING: "KOMMER",
    PAUSED: "SAT PÅ PAUSE",
  },
};

interface WeekStatusProps {
  onHandleChange?: () => void;
  label?: string;
  className?: string;
  Link?: (props: { className?: string }) => JSX.Element;
  isActive?: boolean;
  language?: Language;
  subTitle?: string;
  disclaimerText?: string;
  overrideTitle?: string;
  isLoading?: boolean;
  hideSwitch?: boolean;
}

const WeekStatus = ({
  onHandleChange,
  label,
  className,
  Link,
  isActive,
  language = "no",
  subTitle,
  disclaimerText,
  overrideTitle,
  isLoading,
  hideSwitch,
}: WeekStatusProps) => {
  const { UPCOMING, PAUSED } = intl[language];
  const titleIntlText = isActive ? UPCOMING : PAUSED;
  const titleText = overrideTitle || titleIntlText;

  return (
    <Container
      innerClassName={clsx("flex justify-between items-center py-4", className)}
      outerClassName={clsx(isActive ? "bg-informationBG" : "bg-errorBG")}
    >
      <div className="flex-grow min-w-0">
        <div className={clsx(isActive ? "text-information" : "text-error")}>
          <strong>{titleText}</strong>
        </div>
        <div
          className={clsx(!isActive && "text-grey-1", "truncate mt-2 text-sm")}
        >
          <strong>{subTitle}</strong>
        </div>

        {Link && <Link className={clsx(!isActive && "text-grey-1")} />}

        <div className={clsx(!isActive && "text-black", "text-sm")}>
          {disclaimerText}
        </div>
      </div>
      {!hideSwitch && (
        <Switch
          className="ml-6 shrink-0"
          onChange={onHandleChange}
          checked={isActive}
          label={label}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};

export default WeekStatus;
