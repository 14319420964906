import { useDiscountData } from "@chef/state-management/hooks";
import { Discount } from "./Discount";

export const TemporaryDiscount = () => {
  const {
    discounts: {
      activeDiscounts: { temporaryDiscounts },
    },
  } = useDiscountData();

  const { nextTemporaryDiscount } = temporaryDiscounts;

  if (!nextTemporaryDiscount) {
    return null;
  }

  return <Discount discount={nextTemporaryDiscount} type="temporary" />;
};
