import { useMediaQuery } from "./useMediaQuery";

export const useViewport = () => {
  const [isMd, { loading }] = useMediaQuery("(min-width: 768px)");
  const [isLg] = useMediaQuery("(min-width: 1024px)");
  const [isXl] = useMediaQuery("(min-width: 1440px)");

  let breakpoint: "sm" | "md" | "lg" | "xl" = "sm";

  if (isMd) {
    breakpoint = "md";
  }

  if (isLg) {
    breakpoint = "lg";
  }

  if (isXl) {
    breakpoint = "xl";
  }

  return [breakpoint, loading] as const;
};
