import React from "react";

interface ImageMobileDesktopProps {
  DesktopImage: () => JSX.Element;
  MobileImage: () => JSX.Element;
  desktopCaption: string;
  mobileCaption: string;
}

const ImageMobileDesktop = ({
  DesktopImage,
  MobileImage,
  desktopCaption,
  mobileCaption,
}: ImageMobileDesktopProps) => {
  return (
    <>
      <figure className="hidden md:block ">
        <DesktopImage />
        <figcaption>{desktopCaption}</figcaption>
      </figure>
      <figure className="md:hidden">
        <MobileImage />
        <figcaption>{mobileCaption}</figcaption>
      </figure>
    </>
  );
};

export default ImageMobileDesktop;
