export const showNewIntercomMessageWithEmailFallback = ({
  prepopulatedMessage,
  email,
  subject,
}: {
  prepopulatedMessage: string;
  email: string;
  subject: string;
}) => {
  const Intercom = (window as any).Intercom;
  if (typeof Intercom !== "undefined" && Intercom) {
    Intercom("showNewMessage", prepopulatedMessage);
  } else {
    window.open(
      `mailto:${email}?subject=${subject}&body=${prepopulatedMessage}`,
    );
  }
};
