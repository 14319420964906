import * as React from "react";
import { SVGProps } from "react";
const Bag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6673 18.3353H3.33398L3.88954 6.11304H16.1118L16.6673 18.3353Z"
      stroke="#202020"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2207 8.33317V4.44428C7.2207 3.70757 7.51336 3.00103 8.0343 2.4801C8.55523 1.95916 9.26177 1.6665 9.99848 1.6665C10.3633 1.6665 10.7245 1.73835 11.0615 1.87795C11.3985 2.01755 11.7047 2.22216 11.9627 2.4801C12.2206 2.73804 12.4252 3.04426 12.5648 3.38127C12.7044 3.71829 12.7763 4.0795 12.7763 4.44428V8.33317"
      stroke="#202020"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Bag;
