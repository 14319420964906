import clsx from "clsx";

import { Switch, Tooltip } from "@chef/components";
import { useWakeLock } from "@chef/hooks";
import { useTrack } from "@chef/feature-tracking";

import { intl } from "./WakeLockToggle.Intl";

interface WakeLockToggleProps {
  className?: string;
}

export const WakeLockToggle = ({ className }: WakeLockToggleProps) => {
  const track = useTrack();
  const { isWakeLocked, toggleWakeLock, isWakeLockSupported } = useWakeLock();

  if (!isWakeLockSupported) {
    return null;
  }

  return (
    <div
      className={clsx(
        "flex items-center justify-end gap-4 z-10 py-4 sticky top-0",
        className,
      )}
    >
      <Tooltip
        element={
          <div className="max-w-xs m-2">
            {intl.ACTIVATE_TO_PREVENT_THE_SCREEN_FROM_TURNING_OFF}
          </div>
        }
      >
        <div className="underline">
          <strong>{intl.KEEP_SCREEN_ON}</strong>
        </div>
      </Tooltip>
      <Switch
        checked={isWakeLocked}
        onChange={(wasChecked) => {
          track("buttonClicked", {
            affiliation: "Frontend process",
            button_colour: "default",
            button_placement: "recipe-instructions",
            button_text: intl.KEEP_SCREEN_ON,
            button_source: "code",
            button_action: wasChecked ? "disabled" : "enabled",
            button_destination: "wake-lock",
          });

          toggleWakeLock();
        }}
      />
    </div>
  );
};
