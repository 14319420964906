import { createApi } from "@reduxjs/toolkit/query/react";

import type { RecursivePartial } from "@chef/types/utils";
import type { DirectPaymentSession } from "@chef/types/payment";

import { query } from "../../graphql/baseApi";

type IGetDirectPaymentSessionArgs = {
  token: string;
};

type IGetDirectPaymentSessionResponse = RecursivePartial<DirectPaymentSession>;

type IUpdateDirectPaymentSessionArgs = RecursivePartial<DirectPaymentSession>;

type IUpdateDirectPaymentSessionResponse = {
  redirectUri: string;
};

export const directPaymentSessionApi = createApi({
  reducerPath: "directPaymentSession",
  baseQuery: query,
  tagTypes: ["directPaymentSession"],
  endpoints: (builder) => ({
    getDirectPaymentSession: builder.query<
      IGetDirectPaymentSessionResponse,
      IGetDirectPaymentSessionArgs
    >({
      query: ({ token }) => ({
        url: "/direct-order/session",
        method: "GET",
        params: {
          token,
        },
      }),
      providesTags: ["directPaymentSession"],
    }),
    updateDirectPaymentSession: builder.mutation<
      IUpdateDirectPaymentSessionResponse,
      IUpdateDirectPaymentSessionArgs
    >({
      query: (body) => ({
        url: "/direct-order/session",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["directPaymentSession"],
    }),
  }),
});

export const {
  useGetDirectPaymentSessionQuery,
  useUpdateDirectPaymentSessionMutation,
} = directPaymentSessionApi;
