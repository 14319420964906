import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { billingApi, showNotification } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      CONTACT_SETTINGS_UPDATED: "Kontaktindstillingerne er blitt oppdatert.",
    },

    se: {
      CONTACT_SETTINGS_UPDATED: "Kontaktuppgifter har uppdaterats.",
    },

    dk: {
      CONTACT_SETTINGS_UPDATED: "Kontaktindstillingerne er blevet opdateret.",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.updateContactPreferences.matchFulfilled,
      billingApi.endpoints.updateContactPreferences.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.CONTACT_SETTINGS_UPDATED,
        });
      }
    },
  });
};
