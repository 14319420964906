import { BillingQuery, PreferencesQuery } from "../graphql/generated";
import { getAttribute } from "./product";

type Preference =
  | PreferencesQuery["preferences"][number]
  | BillingQuery["billing"]["preferences"][number];

export const isFixedPreference = (preference: Preference) => {
  return getAttribute("is_fixed", preference) === "True";
};
