import * as React from "react";
import { SVGProps } from "react";
const Support = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.14453 13.8V12.3333C8.14453 8.3 11.2874 5 15.6088 5H16.3945C20.716 5 23.8588 8.3 23.8588 12.3333V13.8M19.1445 25.5333C19.1445 25.1443 18.979 24.7713 18.6843 24.4962C18.3896 24.2212 17.9899 24.0667 17.5731 24.0667H16.0017C15.5849 24.0667 15.1852 24.2212 14.8905 24.4962C14.5958 24.7713 14.4302 25.1443 14.4302 25.5333C14.4302 25.9223 14.5958 26.2954 14.8905 26.5704C15.1852 26.8455 15.5849 27 16.0017 27H17.5731C17.9899 27 18.3896 26.8455 18.6843 26.5704C18.979 26.2954 19.1445 25.9223 19.1445 25.5333ZM19.1445 25.5333C19.1445 25.5333 23.8588 25.5333 23.8588 20.4"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.71429 20.3969H6.41429C6.03919 20.3969 5.67946 20.2578 5.41423 20.0103C5.149 19.7627 5 19.427 5 19.0769V15.1169C5 14.7668 5.149 14.431 5.41423 14.1835C5.67946 13.9359 6.03919 13.7969 6.41429 13.7969H9.71429V20.3969ZM22.2857 20.3969H25.5857C25.9608 20.3969 26.3205 20.2578 26.5858 20.0103C26.851 19.7627 27 19.427 27 19.0769V15.1169C27 14.7668 26.851 14.431 26.5858 14.1835C26.3205 13.9359 25.9608 13.7969 25.5857 13.7969H22.2857V20.3969Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Support;
