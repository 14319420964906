import Link from "next/link";
import clsx from "clsx";
import { useRouter } from "next/router";
import { motion } from "framer-motion";

import { Button } from "@chef/components";
import {
  BRAND_LOGGED_IN_START_PAGE,
  BRAND_PATHS,
  language,
} from "@chef/constants";
import { useMeQuery } from "@chef/state-management";

import { Logo } from "../Logo";
import { isActivePath, useGetNavItems } from "../../utils";
import { Drawer } from "./Drawer";

const intl = {
  no: {
    LOG_IN: "Logg inn",
    OPEN_MENU: "Åpne menyen",
  },
  se: {
    LOG_IN: "Logga in",
    OPEN_MENU: "Öppna menyn",
  },
  dk: {
    LOG_IN: "Log ind",
    OPEN_MENU: "Åbne menuen",
  },
}[language];

const MenuItems = () => {
  const router = useRouter();

  const { headerItems } = useGetNavItems();

  return (
    <nav className="hidden lg:block">
      <ul className="flex items-center justify-between gap-4">
        {headerItems.map(({ label, href }, index, items) => {
          const isActive = isActivePath(
            router.asPath,
            href,
            items.map((i) => i.href),
          );

          return (
            <li key={`${href}-${label}`} className="relative">
              <Link
                href={href}
                passHref
                className={clsx(
                  !isActive &&
                    "hover:underline decoration-2 underline-offset-8 decoration-primary/75",
                )}
                id={"header_menu_item_" + index}
              >
                {label}
              </Link>

              {isActive && (
                <motion.div
                  layoutId="header-nav-underline"
                  className="absolute w-full h-0.5 bg-primary"
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export const DefaultVariant = () => {
  const { data: isLoggedIn } = useMeQuery();

  if (isLoggedIn) {
    return (
      <div className="flex items-center justify-between h-15">
        <Logo href={BRAND_LOGGED_IN_START_PAGE} />

        <div className="flex items-center gap-8">
          <MenuItems />

          <div className="flex items-center">
            <Drawer />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between w-full h-15">
      <Logo href="/" />

      <div className="flex items-center gap-8">
        <MenuItems />

        <div className="hidden md:block">
          <Button
            id="header_login_btn"
            href={BRAND_PATHS.LOG_IN_URL}
            outlined
            tiny
          >
            {intl.LOG_IN}
          </Button>
        </div>

        <Drawer />
      </div>
    </div>
  );
};
