import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { showNotification, smsApi } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      YOU_HAVE_SENT_RECRUIT_DISCOUNT_TO_A_FRIEND:
        "Du har sendt en rabatt til din venn!",
    },

    se: {
      YOU_HAVE_SENT_RECRUIT_DISCOUNT_TO_A_FRIEND:
        "Du har skickat en rabatt till din vän!",
    },

    dk: {
      YOU_HAVE_SENT_RECRUIT_DISCOUNT_TO_A_FRIEND:
        "Du har sendt en rabat til din ven!",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      smsApi.endpoints.sendTellAFriendSms.matchFulfilled,
      smsApi.endpoints.sendTellAFriendSms.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.YOU_HAVE_SENT_RECRUIT_DISCOUNT_TO_A_FRIEND,
        });
      }
    },
  });
};
