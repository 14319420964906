import { BRAND_NAME } from "./misc";

export const TIME = (
  {
    AMK: {
      DEFAULT_DATE_FORMAT: "EEEE d. MMMM yyyy",
    },
    GL: {
      DEFAULT_DATE_FORMAT: "EEEE d. MMMM yyyy",
    },
    LMK: {
      DEFAULT_DATE_FORMAT: "EEEE d MMMM yyyy",
    },
    RN: {
      DEFAULT_DATE_FORMAT: "EEEE 'd.' d/M-yyyy",
    },
  } as const
)[BRAND_NAME];
