import * as React from "react";
import { SVGProps } from "react";
const Chef = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2459_10)">
      <path
        d="M6.75 19.5V23.25H17.25V19.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.74993 19V13.9567M13.4999 19V12.5158M20.9998 6.75209C21.006 6.17521 20.8664 5.60549 20.5931 5.09182C20.3197 4.57814 19.9207 4.13585 19.4302 3.80288C18.9398 3.4699 18.3726 3.25619 17.7773 3.18008C17.182 3.10397 16.5764 3.16774 16.0124 3.3659C15.6362 2.65409 15.0621 2.05625 14.3539 1.63865C13.6457 1.22105 12.8309 1 11.9999 1C11.1689 1 10.3541 1.22105 9.64589 1.63865C8.93766 2.05625 8.36363 2.65409 7.98744 3.3659C7.59075 3.22676 7.17225 3.15367 6.74996 3.14976C5.75541 3.14976 4.80159 3.52929 4.09834 4.20485C3.39508 4.88042 3 5.79669 3 6.75209C3 7.70748 3.39508 8.62375 4.09834 9.29932C4.80159 9.97488 5.75541 10.3544 6.74996 10.3544V19H17.2498V10.3544C17.7423 10.3544 18.2299 10.2612 18.6849 10.0802C19.1399 9.89917 19.5532 9.63382 19.9015 9.29932C20.2497 8.96481 20.5259 8.56769 20.7144 8.13064C20.9028 7.69358 20.9998 7.22515 20.9998 6.75209Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2459_10">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Chef;
