import * as React from "react";
import { SVGProps } from "react";
const Loading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.8269 15C11.7885 15 15 11.866 15 8C15 4.13401 11.7885 1 7.8269 1C5.42686 1 3.30213 2.15025 2 3.91667"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Loading;
