import { Currency, Hr } from "@chef/components";
import { language } from "@chef/constants";
import { Discount as DiscountIcon } from "@chef/icons/small";

import { LoyaltyPointsNotification } from "../../LoyaltyPointsNotification";

interface SummaryProps {
  finalSum: number;
  hasBeenDelivered?: boolean;

  overridePoints?: number;
}

export const Summary = ({
  finalSum,
  hasBeenDelivered,
  overridePoints,
}: SummaryProps) => {
  return (
    <div className="flex flex-col">
      <Hr className="my-4" />

      <div className="flex justify-between">
        <strong>
          <p>{intl.TOTAL_SUM}</p>
        </strong>

        <div className="flex flex-col items-end">
          <Currency emphasized>{finalSum}</Currency>

          {!hasBeenDelivered && (
            <p className="text-xs text-discount">
              {intl.BEFORE_DISCOUNTS_ARE_APPLIED}
            </p>
          )}
        </div>
      </div>

      <LoyaltyPointsNotification
        hasBeenDelivered={hasBeenDelivered}
        {...(overridePoints
          ? { points: overridePoints }
          : { totalCostOfOrder: finalSum })}
      />

      {!hasBeenDelivered && <DiscountDisclaimer />}
    </div>
  );
};

const DiscountDisclaimer = () => {
  return (
    <div className="flex items-center gap-3 p-4 mt-5 text-sm bg-grey-3">
      <div className="flex items-center justify-center flex-shrink-0 rounded-full f h-11 w-11 bg-grey-2 ">
        <DiscountIcon className="w-6 h-6" />
      </div>

      <div>{intl.WHEN_THE_DISCOUNT_WILL_BE_DEDUCTED}</div>
    </div>
  );
};

const intl = (
  {
    no: {
      TOTAL_SUM: "Totalt",
      BEFORE_DISCOUNTS_ARE_APPLIED: "Før rabatter er trukket",
      WHEN_THE_DISCOUNT_WILL_BE_DEDUCTED:
        "Hvis du har en rabatt, trekkes denne når ordren er låst kl. 23:59",
    },

    se: {
      TOTAL_SUM: "Totalt",
      BEFORE_DISCOUNTS_ARE_APPLIED: "Innan rabatter är dragna",
      WHEN_THE_DISCOUNT_WILL_BE_DEDUCTED:
        "Eventuella rabatter dras när ordern är låst, tisdag 23.59 i aktuell leveransvecka.",
    },

    dk: {
      TOTAL_SUM: "I alt",
      BEFORE_DISCOUNTS_ARE_APPLIED: "Før rabatten er fratrukket",
      WHEN_THE_DISCOUNT_WILL_BE_DEDUCTED:
        "Hvis du har en rabat, fratrækkes denne, når ordren er låst onsdag kl. 23.59",
    },
  } as const
)[language];
