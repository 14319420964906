import React from "react";
import clsx from "clsx";
import { ChevronRight } from "@chef/icons/small";

import { Button, Card } from "../index";

interface CTACardProps {
  title: string;
  text: string;
  Image: () => React.ReactElement;
  buttonLink?: string;
  buttonText?: string;
  className?: string;
  id?: string;
}

export const CTACard = ({
  title,
  text,
  Image,
  buttonLink,
  buttonText,
  className,
  id,
  ...props
}: CTACardProps) => {
  return (
    <Card
      {...props}
      className={clsx("flex flex-col lg:flex-row !p-0", className)}
      id={id}
    >
      <div className="relative w-full overflow-hidden h-52 lg:w-1/3 lg:h-auto lg:order-last">
        <Image />
      </div>
      <div className="flex-1 p-4 lg:p-6 lg:order-first">
        <h3>
          <strong>{title}</strong>
        </h3>
        <p className="mt-1 overflow-auto break-words lg:mt-2">{text}</p>
        {buttonText && buttonLink && (
          <Button
            tiny
            outlined
            href={buttonLink}
            IconRight={() => <ChevronRight />}
            className="mt-4 lg:mt-6"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Card>
  );
};
