import * as React from "react";
import { SVGProps } from "react";
const Default = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 18V17H15V18C15 19 15.5 20 17 20H7C5.5 20 5 19 5 18Z"
      stroke="currentColor"
      strokeWidth={1.33333}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 17V4H19V18C19 19.0044 18.5 20 17 20H15"
      stroke="currentColor"
      strokeWidth={1.33333}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7H16"
      stroke="currentColor"
      strokeWidth={1.33333}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10H16"
      stroke="currentColor"
      strokeWidth={1.33333}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13H16"
      stroke="currentColor"
      strokeWidth={1.33333}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Default;
