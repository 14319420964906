import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ISendTellAFriendSmsResponse, ISendTellAFriendSmsArgs } from "./types";

export const smsApi = createApi({
  reducerPath: "sms",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    sendTellAFriendSms: builder.mutation<
      ISendTellAFriendSmsResponse,
      ISendTellAFriendSmsArgs
    >({
      query: ({ phoneNumber }) => ({
        url: "/tell-a-friend/sendSms",
        method: "POST",
        body: { phoneNumber },
      }),
    }),
  }),
});

export const { useSendTellAFriendSmsMutation } = smsApi;
