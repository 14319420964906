import * as React from "react";
import { SVGProps } from "react";
const Bowl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_33579_332776)">
      <path
        d="M11.3333 1L8.24444 7.53333M14 2.86667L10 7.53333M6 15H10.4444"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8C1.14387 9.63492 1.94371 11.1597 3.24059 12.2713C4.53746 13.3829 6.23646 14 8 14C9.76354 14 11.4625 13.3829 12.7594 12.2713C14.0563 11.1597 14.8561 9.63492 15 8H1Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33579_332776">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Bowl;
