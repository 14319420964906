import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { authApi } from "../features";

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    predicate: (action) => {
      try {
        const is102Error = (action["payload"]?.message as string).includes(
          '\\"ErrorCode\\":102}',
        );

        return action?.["meta"].rejectedWithValue && is102Error;
      } catch {
        return false;
      }
    },
    effect: async (_, listenerApi) => {
      listenerApi.dispatch(authApi.endpoints.logout.initiate());
    },
  });
