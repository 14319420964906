import {
  isStandaloneProduct,
  isAddonProduct,
  isLimitedQuantityProduct,
} from "@chef/helpers";
import { OrderQuery } from "@chef/state-management";
import { Groceries } from "../shared/Groceries";

interface OrderGroceriesProps {
  order: OrderQuery["order"];
  initiallyExpanded?: boolean;
}

export const OrderGroceries = ({
  order,
  initiallyExpanded,
}: OrderGroceriesProps) => {
  const groceries = order.orderLines.filter(
    (ol) =>
      isStandaloneProduct(ol.variation.product) ||
      isAddonProduct(ol.variation.product) ||
      isLimitedQuantityProduct(ol.variation.product),
  );

  if (groceries.length === 0) {
    return null;
  }

  return (
    <Groceries
      groceries={groceries.map((grocery) => ({
        name: grocery.variation.product.name,
        quantity: grocery.quantity,
        price: grocery.fullPrice,
      }))}
      initiallyExpanded={initiallyExpanded}
    />
  );
};
