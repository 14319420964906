import * as React from "react";
import { SVGProps } from "react";
const Magic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.14062 11.4297H4.64062"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.85938 10.5759V9.71875"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.85938 13.1462V12.2891"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 1C14.5 2.93667 12.9367 4.5 11 4.5C12.9367 4.5 14.5 6.06333 14.5 8C14.5 6.06333 16.0633 4.5 18 4.5C16.0633 4.5 14.5 2.93667 14.5 1Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.42857 14C7.42857 15.8943 5.89429 17.4286 4 17.4286C5.89429 17.4286 7.42857 18.9629 7.42857 20.8571C7.42857 18.9629 8.96286 17.4286 10.8571 17.4286C8.96286 17.4286 7.42857 15.8943 7.42857 14Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8.71875H22.5"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7109 7.85714V7"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7109 10.4275V9.57031"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.25433 2.51911L2.50739 3.26605C2.01143 3.76201 1.84343 4.39812 2.13215 4.68684L18.8757 21.4303C19.1644 21.7191 19.8005 21.5511 20.2964 21.0551L21.0434 20.3082C21.5394 19.8122 21.7074 19.1761 21.4186 18.8874L4.67512 2.14387C4.3864 1.85515 3.75029 2.02315 3.25433 2.51911Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 6.84286L6.84286 5"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Magic;
