import * as React from "react";
import { SVGProps } from "react";
const Sms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_30058_297300)">
      <path
        d="M14.25 15.75V21.75C14.25 22.1478 14.092 22.5294 13.8107 22.8107C13.5294 23.092 13.1478 23.25 12.75 23.25H3C2.60218 23.25 2.22064 23.092 1.93934 22.8107C1.65804 22.5294 1.5 22.1478 1.5 21.75V2.25C1.5 1.85218 1.65804 1.47064 1.93934 1.18934C2.22064 0.908035 2.60218 0.75 3 0.75H11.25M1.5 3.75H10.5M1.5 18H14.25"
        stroke="#FFFCF5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.275 0.75H11.475C11.2164 0.75 10.9684 0.852723 10.7856 1.03557C10.6027 1.21842 10.5 1.46641 10.5 1.725V8.775C10.5 8.90304 10.5252 9.02982 10.5742 9.14812C10.6232 9.26641 10.695 9.37389 10.7856 9.46443C10.8761 9.55497 10.9836 9.62678 11.1019 9.67578C11.2202 9.72478 11.347 9.75 11.475 9.75H14.25V12.375L16.875 9.75H22.275C22.403 9.75 22.5298 9.72478 22.6481 9.67578C22.7664 9.62678 22.8739 9.55497 22.9644 9.46443C23.055 9.37389 23.1268 9.26641 23.1758 9.14812C23.2248 9.02982 23.25 8.90304 23.25 8.775V1.725C23.25 1.46641 23.1473 1.21842 22.9644 1.03557C22.7816 0.852723 22.5336 0.75 22.275 0.75Z"
        stroke="#FFFCF5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30058_297300">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Sms;
