import Image from "next/image";
export const Fodevarekontrol = () => {
  return (
    <a
      href="https://www.findsmiley.dk/108235"
      target="_blank"
      rel="noopener noreferrer"
      className="text-xs"
    >
      <Image
        src="https://ggfrontendassets.azureedge.net/publicassets/retnemt/fodevarekontroll.gif"
        alt="Fødevarestyrelsens kontrolrapport"
        height={80}
        width={80}
      />
    </a>
  );
};
