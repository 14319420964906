import * as React from "react";
import { SVGProps } from "react";
const NoDelivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={150} height={150} rx={75} fill="#F4F4F1" />
    <path
      d="M113.001 101L37.001 101"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5592 87.2876L85.7598 87.2876"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.001 62.0005L100.598 62.0005L106.008 72.0241L110.791 72.0241C111.764 72.0241 113.001 72.2768 113.001 73.7087L113.001 87.0005L103.798 87.0005"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.8613 52.6582L85.5047 52.6582L85.5047 87.2873L58.9219 87.2873"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.8005 87.2873L41.001 87.2873L41.001 52.6582L82.8618 52.6582"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.501 100C57.6431 100 61.001 96.6426 61.001 92.5005C61.001 88.3584 57.6431 85.0005 53.501 85.0005C49.3588 85.0005 46.001 88.3584 46.001 92.5005C46.001 96.6426 49.3588 100 53.501 100Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.501 100C102.643 100 106.001 96.6426 106.001 92.5005C106.001 88.3584 102.643 85.0005 98.501 85.0005C94.3588 85.0005 91.001 88.3584 91.001 92.5005C91.001 96.6426 94.3588 100 98.501 100Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.001 36.0005L96.001 114"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NoDelivery;
