import * as React from "react";
import { SVGProps } from "react";
const Dinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5957 9.52563C20.0765 10.1724 21.9119 12.4076 21.9119 15.0579C21.9119 17.5855 20.2427 19.7352 17.9365 20.4895"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2693 6.47591C14.1647 6.18634 15.1215 6.03052 16.1161 6.03052C21.1656 6.03052 25.2598 10.0722 25.2598 15.057C25.2598 20.0418 21.1656 24.0834 16.1161 24.0834C13.4691 24.0834 11.0833 22.972 9.41406 21.1973"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.92871 3.91675V7.61779C1.92871 9.94955 3.07412 10.4929 5.42639 10.4929C7.77866 10.4929 8.92406 10.3825 8.92406 7.61779V3.91675"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.42676 3.91675V23.5954"
      stroke="currentColor"
      strokeWidth={1.9988}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Dinner;
