import { CONTACT } from "@chef/constants";
import { showNewIntercomMessageWithEmailFallback } from ".";

export const sendIntercomMessage = ({
  prepopulatedMessage,
  subject,
}: {
  prepopulatedMessage: string;
  subject: string;
}) => {
  showNewIntercomMessageWithEmailFallback({
    prepopulatedMessage,
    subject,
    email: CONTACT.EMAIL,
  });
};
