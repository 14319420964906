import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      DO_YOU_LIKE_THIS: "Likte du de foreslåtte rettene?",
      NOT_REALLY: "Ikke egentlig...",
      DELICIOUS: "Ja, utmerket",
      WE_WILL_TALK_TO_OUR_CHEFS: "Vi tar din tilbakemeldingen med våre kokker.",
      YOU_CAN_SET_MORE_DETAILED_TASTE_PREFERENCES:
        "Du kan velge mer detaljerte smakspreferanser for din matkasse ",
      HERE: "her.",
      THANK_YOU_FOR_FEEDBACK: "Takk for tilbakemeldingen!",
    },
    se: {
      DO_YOU_LIKE_THIS: "Gillar du de rekommenderade rätterna?",
      NOT_REALLY: "Nej, inte riktigt…",
      DELICIOUS: "Ja, utmärkt",
      WE_WILL_TALK_TO_OUR_CHEFS: "Vi delar din feedback med vårt kockteam.",
      YOU_CAN_SET_MORE_DETAILED_TASTE_PREFERENCES:
        "Du kan anpassa dina preferenser ytterligare så att de passar din smak ",
      HERE: "här.",
      THANK_YOU_FOR_FEEDBACK: "Tack så mycket för din feedback!",
    },
    dk: {
      DO_YOU_LIKE_THIS: "Kan du lide de anbefalede retter?",
      NOT_REALLY: "Nej, ikke rigtig...",
      DELICIOUS: "Ja, lækkert",
      WE_WILL_TALK_TO_OUR_CHEFS: "Vi deler din feedback med vores kokketeam.",
      YOU_CAN_SET_MORE_DETAILED_TASTE_PREFERENCES:
        "Du kan tilpasse dine præferencer yderligere, så de passer til din smag lige",
      HERE: "her.",
      THANK_YOU_FOR_FEEDBACK: "Mange tak for din feedback!",
    },
  } as const
)[language];
