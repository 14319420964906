import * as React from "react";
import { SVGProps } from "react";
const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.625 6.83333L9.38125 12.6667M6.61875 12.6667L6.375 6.83333M3.125 3.5L3.81593 14.1297C3.88431 15.1817 4.75751 16 5.81172 16H10.1883C11.2425 16 12.1157 15.1817 12.1841 14.1297L12.875 3.5M3.125 3.5H5.5625M3.125 3.5H1.5M12.875 3.5H14.5M12.875 3.5H10.4375M10.4375 3.5V3C10.4375 1.89543 9.54207 1 8.4375 1H7.5625C6.45793 1 5.5625 1.89543 5.5625 3V3.5M10.4375 3.5H5.5625"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Trash;
