import { Loading } from "@chef/icons/small";

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  badgeContent?: number | string;
  loading?: boolean;
  children?: React.ReactNode;
  id?: string;
}

export const Badge = ({
  badgeContent,
  loading,
  children,
  id,
  ...props
}: BadgeProps) => {
  return (
    <div className="relative" id={id} {...props}>
      {children}
      <div className="absolute w-4 h-4 text-xs text-white rounded-full -top-1.25 -right-1.25 bg-primary flex items-center justify-center">
        {loading ? (
          <Loading className="animate-spin" id="loading" />
        ) : (
          badgeContent
        )}
      </div>
    </div>
  );
};
