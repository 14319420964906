import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CHANGES_HAVE_NOT_BEEN_SAVED: "Endringene dine er ikke lagret",
      IF_YOU_LEAVE_CHANGES_WILL_NOT_BE_SAVED:
        "Eventuelle endringer du har gjort vil ikke bli lagret.",
      GO_FORWARD_WITHOUT_SAVING_BUTTON: "Fortsett uten å lagre",
      STAY_BUTTON: "Avbryt",
    },
    se: {
      CHANGES_HAVE_NOT_BEEN_SAVED: "Dina ändringar är inte sparade",
      IF_YOU_LEAVE_CHANGES_WILL_NOT_BE_SAVED:
        "Eventuella ändringar du har gjort kommer inte att sparas.",
      GO_FORWARD_WITHOUT_SAVING_BUTTON: "Lämna utan att spara",
      STAY_BUTTON: "Stanna kvar",
    },
    dk: {
      CHANGES_HAVE_NOT_BEEN_SAVED: "Dine ændringer er ikke blevet gemt",
      IF_YOU_LEAVE_CHANGES_WILL_NOT_BE_SAVED:
        "Du har foretaget ændringer, der ikke er gemt. Hvis du forlader siden, vil de gå tabt.",
      GO_FORWARD_WITHOUT_SAVING_BUTTON: "Gå videre uden at gemme",
      STAY_BUTTON: "Bliv",
    },
  } as const
)[language];
