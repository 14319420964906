import Link from "next/link";

import { Button, Container, Snackbar } from "@chef/components";
import { ChevronLeft } from "@chef/icons/large";
import { BRAND_PATHS } from "@chef/constants";

import { intl } from "./ErrorComponent.Intl";

export const ErrorComponent = ({ context }: { context?: object }) => {
  const {
    BACK,
    ERROR,
    RELOAD_BUTTON_PREFIX,
    RELOAD_BUTTON_TEXT,
    RELOAD_BUTTON_SUFFIX,
    CONTACT_CUSTOMER_SERVICE,
  } = intl;

  if (typeof window !== "undefined") {
    const error = new Error("Failed to load data for page");
    window.DD_RUM?.addError(error, { context });
  }

  return (
    <Container outerClassName="pt-10 pb-32 grow bg-background">
      <Button
        className="mb-4"
        // eslint-disable-next-line no-restricted-globals
        onClick={() => history.back()}
        IconLeft={() => <ChevronLeft className="w-3 h-3 mr-2" />}
        small
        outlined
      >
        {BACK}
      </Button>
      <Snackbar error>
        <p>{ERROR}</p>
        <p>
          {RELOAD_BUTTON_PREFIX}{" "}
          <button onClick={() => window.location.reload()}>
            {RELOAD_BUTTON_TEXT}
          </button>{" "}
          {RELOAD_BUTTON_SUFFIX}{" "}
          <Link
            href={BRAND_PATHS.CONTACT_US_PAGE_LOGOUT}
            passHref
            className="underline"
          >
            {CONTACT_CUSTOMER_SERVICE}
          </Link>
          .
        </p>
      </Snackbar>
      <Snackbar error showIcon={false} className="py-0 mt-4">
        <code className="text-xs">
          {typeof window !== "undefined" &&
            window.DD_RUM?.getInternalContext()?.view?.id}
        </code>
      </Snackbar>
    </Container>
  );
};
