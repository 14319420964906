import { motion } from "framer-motion";

interface AnimatedCheckProps extends React.SVGProps<SVGSVGElement> {
  isChecked: boolean;
}

export const AnimatedCheck = ({ isChecked, ...props }: AnimatedCheckProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <motion.path
        d="M3.5 9L7 12.5L14.5 5"
        stroke="currentColor"
        strokeWidth="2"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: isChecked ? 1 : 0 }}
        transition={{ duration: 0.15 }}
      />
    </svg>
  );
};
