import * as yup from "yup";

import { companyId, config } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

const signupSchema = yup.object().shape({
  applicantInfo: yup
    .object()
    .shape({
      applicantId: yup.string().required(),
      frontendId: yup.string().required(),
    })
    .required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  birthdate: yup
    .string()
    .required()
    .test(
      "birthdate",
      "birthdate should be 01-01-1901",
      (value) => value === "01-01-1901",
    ),
  telephone: yup.string().required(),
  email: yup.string().required().email(),
  password: yup
    .string()
    .required()
    .min(5, "password should be at least 5 characters"),
  personNumber: yup.string().notRequired(),
  salesPointId: yup.number().notRequired(),
  company: yup
    .string()
    .required()
    .test("company", `company should be ${companyId}`, (value) =>
      isEqualStrings(value, companyId),
    ),
  billingAddress: yup.object().shape({
    PostalCode: yup.string().required(),
    PostalCodeCity: yup.string().required().max(255).min(1),
    Address: yup.string().required().max(255).min(1),
    DoorCode: yup
      .string()
      .notRequired()
      .test(
        "DoorCode",
        "billingAddress.DoorCode length should be less or equal to 40 characters",
        (value) => {
          if (value) {
            return value.length <= 40;
          }

          return true;
        },
      ),
    deliveryTimeblock: yup.number().required(),
    deliveryInstructionsToDriver: yup
      .string()
      .notRequired()
      .test(
        "deliveryInstructionsToDriver",
        "billingAddress.deliveryInstructionsToDriver length should be less or equal to 255 characters",
        (value) => {
          if (value) {
            return value.length <= 255;
          }

          return true;
        },
      ),
    alias: yup
      .string()
      .required()
      .max(255)
      .min(1)
      .test(
        "alias",
        `billingAddress.alias should be ${config.signup.defaultBillingAddressAlias}`,
        (value) => value === config.signup.defaultBillingAddressAlias,
      ),
    // TODO: add proper validation to these fields
    streetName: yup.string().notRequired(),
    streetNo: yup.number().notRequired(),
    letterExtra: yup.string().notRequired(),

    apartmentName: yup.string().notRequired(),
    flatNo: yup.string().notRequired(),
    floorNo: yup.string().notRequired(),

    validated: yup.string().notRequired(),
    addressUUID: yup.string().notRequired(),
    addressCode: yup.string().notRequired(),
    longitude: yup.number().notRequired(),
    latitude: yup.number().notRequired(),
    addressDigitalKeyProviderId: yup.string().notRequired(),
  }),
  basket: yup.object().shape({
    shippingAddress: yup.object().shape({
      PostalCode: yup.string().required(),
      PostalCodeCity: yup.string().required().min(1).max(255),
      Address: yup.string().required().min(1).max(255),
      DoorCode: yup
        .string()
        .notRequired()
        .test(
          "DoorCode",
          "basket.shippingAddress.DoorCode length should be less or equal to 40 characters",
          (value) => {
            if (value) {
              return value.length <= 40;
            }

            return true;
          },
        ),
      deliveryTimeblock: yup.number().required(),
      deliveryInstructionsToDriver: yup
        .string()
        .notRequired()
        .test(
          "deliveryInstructionsToDriver",
          "basket.shippingAddress.deliveryInstructionsToDriver length should be less or equal to 255 characters",
          (value) => {
            if (value) {
              return value.length <= 255;
            }

            return true;
          },
        ),
      alias: yup
        .string()
        .required()
        .min(1)
        .max(255)
        .test(
          "alias",
          `basket.shippingAddress.alias should be ${config.signup.defaultBasketAddressAlias}`,
          (value) => value === config.signup.defaultBasketAddressAlias,
        ),
    }),
    deliveryInterval: yup
      .number()
      .required()
      .oneOf([10, 20, 30], "deliveryInterval should be 10, 20 or 30"),
    isDefault: yup
      .boolean()
      .required()
      .test("isDefault", "isDefault should be true", (value) => value === true),
    isActive: yup
      .boolean()
      .required()
      .test("isActive", "isActive should be true", (value) => value === true),
    subscribedProducts: yup.array().of(
      yup.object().shape({
        subscribedVariation: yup.string().required(),
        subscribedDeliveryInterval: yup
          .number()
          .required()
          .oneOf(
            [10, 20, 30],
            "subscribedProducts[].subscribedDeliveryInterval should be 10, 20 or 30",
          ),
        subscribedQuantity: yup
          .number()
          .required()
          .test(
            "subscribedQuantity",
            "subscribedProducts[].subscribedQuantity should be 1",
            (value) => value === 1,
          ),
      }),
    ),
  }),
  firstDelivery: yup.string().required(),
  discountCoupon: yup.string().notRequired(),
  contacts: yup
    .object()
    .shape({
      email: yup
        .boolean()
        .required()
        .test(
          "email",
          "contacts.email should be true",
          (value) => value === true,
        ),
      socialMedia: yup
        .boolean()
        .required()
        .test(
          "socialMedia",
          "contacts.socialMedia should be true",
          (value) => value === true,
        ),
      sms: yup
        .boolean()
        .required()
        .test("sms", "contacts.sms should be true", (value) => value === true),
      phone: yup
        .boolean()
        .required()
        .test(
          "phone",
          "contacts.phone should be true",
          (value) => value === true,
        ),
      print: yup
        .boolean()
        .required()
        .test("print", "print should be true", (value) => value === true),
      door: yup
        .boolean()
        .required()
        .test("door", "door should be true", (value) => value === true),
      cutOffNotice: yup
        .boolean()
        .required()
        .test(
          "cutOffNotice",
          "cutOffNotice should be true",
          (value) => value === true,
        ),
    })
    .required(),
  giftCardCode: yup.string().notRequired(),
  affiliate: yup.string().notRequired(),
  registrationProcessTypeId: yup
    .string()
    .required()
    .test(
      "registrationProcessTypeId",
      "registrationProcessTypeId should be NORMAL (542e5466-3482-4479-adc1-950415d404e7)",
      (value) => isEqualStrings(value, "542e5466-3482-4479-adc1-950415d404e7"),
    ),
  paymentMethod: yup
    .string()
    .required()
    .oneOf(["Invoice", "CC"], "paymentMethod should be Invoice or CC"),
  cardType: yup.string().when("paymentMethod", {
    is: "CC",
    then: yup
      .string()
      .required()
      .test("cardType", "cardType should be cc", (value) => value === "cc"),
    otherwise: yup
      .string()
      .test(
        "cardType",
        "cardType should be invoice",
        (value) => value === "invoice",
      ),
  }),
  paymentPartnerId: yup.string().when("paymentMethod", {
    is: "CC",
    then: yup
      .string()
      .required()
      .test(
        "paymentPartnerId",
        "paymentPartnerId should be ADYEN (0B25518E-4871-46FE-9494-036D6F64A6D6)",
        (value) =>
          isEqualStrings(value, "0B25518E-4871-46FE-9494-036D6F64A6D6"),
      ),
    otherwise: yup
      .string()
      .required()
      .test(
        "paymentPartnerId",
        "paymentPartnerId should be COLLECTOR (815224F7-071E-40FB-A9D0-BEC6E233E190)",
        (value) =>
          isEqualStrings(value, "815224F7-071E-40FB-A9D0-BEC6E233E190"),
      ),
  }),
});

export const validateSignupData = async (data: any) => {
  let isValid: boolean;

  try {
    await signupSchema.validate(data, { abortEarly: false });

    isValid = true;
  } catch (error) {
    console.error(error);

    if (error instanceof yup.ValidationError) {
      window.DD_RUM?.addError(
        new Error("Signup schema validation failed", { cause: error }),
        {
          state: {
            ...data,
            password: data.password?.length,
            personNumber: data.personNumber?.length,
          },
        },
      );
    }

    isValid = false;
  }

  return isValid;
};
