import { motion } from "framer-motion";
import { useState } from "react";
import { Snackbar, ConditionalRenderer, Button } from "@chef/components";
import { BRAND_NAME, CONSENT_IDS } from "@chef/constants";
import {
  useBillingQuery,
  useUpdateUserConsentsMutation,
} from "@chef/state-management";
import { isAccountAcceptedProfiling } from "@chef/state-management/helpers";
import { intl } from "./ConsentsWarning.Intl";

export const ConsentsWarning = () => {
  const [isVisible, setIsVisible] = useState(true);

  const { data: billingQuery, isLoading } = useBillingQuery();
  const [updateUserConsentsMutation] = useUpdateUserConsentsMutation();

  const hasAcceptedProfiling =
    isAccountAcceptedProfiling(billingQuery?.billing.consents) || false;

  const acceptConsentHandler = async () => {
    await updateUserConsentsMutation([
      {
        consentId: CONSENT_IDS.PROFILING[BRAND_NAME],
        isAccepted: true,
      },
    ]).unwrap();
    setIsVisible(true);
  };

  const animationVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -50 },
  };

  return (
    <ConditionalRenderer shouldRender={!hasAcceptedProfiling && !isLoading}>
      <motion.div
        className=" md:col-start-2 xl:col-start-4 col-span-full md:col-span-6"
        variants={animationVariants}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        exit="hidden"
        transition={{ duration: 0.5 }}
        style={{ position: "relative" }}
      >
        <Snackbar showIcon warning className="mt-10 text-sm md:-mb-16">
          <div className="flex flex-col md:flex-row">
            {
              intl.WE_NEED_YOUR_CONSENT_TO_STORE_INFORMATION_ABOUT_YOUR_PREFERENCES
            }
            <Button
              primary
              className="mt-2 text-sm md:my-auto md:mx-2"
              onClick={acceptConsentHandler}
              id="accept-consent-btn"
            >
              {intl.YES_ITS_FINE}
            </Button>
          </div>
        </Snackbar>
      </motion.div>
    </ConditionalRenderer>
  );
};
