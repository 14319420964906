import { Currency, Hr } from "@chef/components";
import { language } from "@chef/constants";
import { useDiscountData } from "@chef/state-management/hooks";

export const Credits = () => {
  const { credits } = useDiscountData();

  const remainingCredits = credits?.activeCredits?.reduce(
    (sumOfActiveCredits, activeCredit) =>
      activeCredit.remainingAmount + sumOfActiveCredits,
    0,
  );

  if (!remainingCredits) {
    return null;
  }

  return (
    <>
      <Hr className="my-4" />

      <div className="flex justify-between">
        <p>{intl.CREDIT}</p>

        <Currency prefix="-">{remainingCredits}</Currency>
      </div>
    </>
  );
};

const intl = (
  {
    no: {
      CREDIT: "Kreditt",
    },

    se: {
      CREDIT: "Kredit",
    },

    dk: {
      CREDIT: "Kredit",
    },
  } as const
)[language];
