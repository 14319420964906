import {
  ActionCreatorWithoutPayload,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { companyId } from "@chef/constants";

import { RootState } from "../..";
import { tags, api } from "../../graphql/api";

export interface ICreateDirectOrder {
  id: string;
  price: number;
  firstName?: string;
  email?: string;
}

export interface ICreateDirectOrderArgs {
  paymentPartner: string;
  deliveryDate?: Date;
  campaign: string;
  coupon?: string;
  products: {
    variationId: string;
    quantity: number;
    dynamicPrice?: number;
  }[];
  orderType: string;
  shippingAddress?: string;
  timeblockId?: number;
  week?: number;
  year?: number;
  billingAddress?: string;
  paymentMethod?: string;
  buyerInfo: {
    firstName: string;
    lastName?: string;
    email: string;
    phone?: string;
    ssn?: string;
    organizationalId?: string;
  };
  // friend?: {
  //   firstName?: string;
  //   lastName?: string;
  //   email?: string;
  //   message?: string;
  // };
  shippingAddressData?: ShippingAddressData;
  billingAddressData?: ShippingAddressData;
  giftCardPDFId?: string;
  redirectTo?: string;
  restrictMultipleOrdersOnSameDay?: boolean;
  agreementDiscounts?: string[];
}

interface IUpdateDirectOrderArgs {
  orderId: string;
  shippingAddress?: string;
  timeblockId?: number;
  variations: { variationId: string; quantity: number }[];
}

interface ShippingAddressData {
  street: string;
  city: string;
  postalCode: string;
  country?: string;

  streetName?: string;
  streetNo?: number;
  letterExtra?: string;
  apartmentName?: string;
  floorNo?: string;
  flatNo?: string | null;

  addressUUID?: string;
  addressCode?: string;
  longitude?: number;
  latitude?: number;
  validated?: string;

  deliveryInstructionsToDriver?: string;
  doorCode?: string;
  requiresPhysicalKey?: boolean;
  addressDigitalKeyProviderId?: string;
}

const endpointsToCallOnDirectOrderCalls: ActionCreatorWithoutPayload<string>[] =
  [];
export const addResetEndpointOnDirectOrderUpdates = (
  endpoint: ActionCreatorWithoutPayload<string>,
) => {
  endpointsToCallOnDirectOrderCalls.push(endpoint);
};

export const directOrderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateDirectOrder: builder.query<void, string>({
      query: (checkoutId) => `/direct-order/validate?orderId=${checkoutId}`,
    }),

    createDirectOrder: builder.mutation<
      ICreateDirectOrder,
      ICreateDirectOrderArgs
    >({
      query: (args) => ({
        url: "/direct-order/create",
        method: "POST",
        body: { ...args, company: companyId },
      }),
      invalidatesTags: [tags.order],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        for (const endpoint of endpointsToCallOnDirectOrderCalls) {
          dispatch(endpoint());
        }
      },
    }),

    updateDirectOrder: builder.mutation<void, IUpdateDirectOrderArgs>({
      query: ({ orderId, shippingAddress, timeblockId, variations }) => ({
        url: "/direct-order/update",
        method: "POST",
        body: { baoId: orderId, shippingAddress, timeblockId, variations },
      }),
      invalidatesTags: [tags.order],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        for (const endpoint of endpointsToCallOnDirectOrderCalls) {
          dispatch(endpoint());
        }
      },
    }),

    cancelDirectOrder: builder.mutation<void, string>({
      query: (orderId) => ({
        url: "/direct-order/cancel?orderId=" + orderId,
      }),
      invalidatesTags: [tags.order],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        for (const endpoint of endpointsToCallOnDirectOrderCalls) {
          dispatch(endpoint());
        }
      },
    }),
  }),
});

export const {
  useCreateDirectOrderMutation,
  useValidateDirectOrderQuery,
  useLazyValidateDirectOrderQuery,
  useUpdateDirectOrderMutation,
  useCancelDirectOrderMutation,
} = directOrderApi;

const initialState = {
  checkoutId: null as string | null,
};

export const directOrderSlice = createSlice({
  name: "directOrderSlice",
  initialState,
  reducers: {
    setDirectOrderCheckoutId: (state, action: PayloadAction<string>) => {
      state.checkoutId = action.payload;
    },
  },
});

export const { setDirectOrderCheckoutId } = directOrderSlice.actions;

export const selectDirectOrderCheckoutId = (state: RootState) =>
  state.directOrderSlice.checkoutId;

export * from "./session";
