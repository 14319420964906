import React from "react";
import { Button } from "../components";
import FutureDeliveryCardBase from "../internals/FutureDeliveryCardBase";

const intl = {
  no: {
    YOUR_ORDER_IS: "Din første levering er den",
    BUT_YOU_CAN_START_EARLY: "men du kan snukstarte allerede nå.",
    EARLY_START: "Snikstart",
    DELIVERY: "Levering",
    ORDER_NOW: "Bestill nå",
  },
  se: {
    YOUR_ORDER_IS: "Din första leverans kommer",
    BUT_YOU_CAN_START_EARLY: "men du kan börja redan den här veckan.",
    EARLY_START: "Starta tidigare!",
    DELIVERY: "Leverans",
    ORDER_NOW: "Beställ nu",
  },
  dk: {
    YOUR_ORDER_IS: "Din første levering sker den",
    BUT_YOU_CAN_START_EARLY: "men du kan smugstart allerede i denne uge.",
    EARLY_START: "Smugstart",
    DELIVERY: "Levering",
    ORDER_NOW: "Bestil nu",
  },
};

const EarlyBirdDeliveryCard = ({
  date = "",
  firstDeliveryDate = "",
  addressName = "",
  deliveryDateString = "",
  LinkComponent = () => null,
  onOrderEarly = () => null,
  language = "",
}) => {
  const {
    EARLY_START = "",
    DELIVERY = "",
    ORDER_NOW = "",
    YOUR_ORDER_IS = "",
    BUT_YOU_CAN_START_EARLY = "",
  } = intl[language];
  return (
    <FutureDeliveryCardBase>
      <div className="w-32 mt-4 shrink-0 md:mt-0">
        <strong>{date}</strong>
      </div>
      <div className="shrink-0 md:w-64">
        <div>
          <strong>{EARLY_START}</strong>
        </div>
        <div>
          {YOUR_ORDER_IS}&nbsp;{firstDeliveryDate},&nbsp;
          {BUT_YOU_CAN_START_EARLY}
        </div>
      </div>
      <div className="text-grey-1">
        <div>
          <strong>{DELIVERY}</strong>
        </div>
        <div>{addressName}</div>
        <div>{deliveryDateString}</div>
        <LinkComponent />
      </div>
      <div className="absolute top-4 right-4 md:top-6 md:right-6">
        <Button onClick={onOrderEarly} primary small>
          {ORDER_NOW}
        </Button>
      </div>
    </FutureDeliveryCardBase>
  );
};

export default EarlyBirdDeliveryCard;
