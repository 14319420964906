import clsx from "clsx";
import React, { useState, HTMLAttributes } from "react";

import { ChevronLeft, ChevronRight } from "@chef/icons/small";

import { ButtonCircle } from "../ButtonCircle";

interface CarouselProps extends HTMLAttributes<HTMLDivElement> {
  innerClassName?: string;
  scrollStepWidth?: number;
}

export const Carousel = ({
  className,
  children,
  innerClassName = "",
  scrollStepWidth = 100,
  ...props
}: CarouselProps) => {
  const wrapper = React.createRef<HTMLDivElement>();

  const [stepLeftDisabled, setStepLeftDisabled] = useState(true);
  const [stepRightDisabled, setStepRightDisabled] = useState(false);

  const handleStepLeft = () => {
    if (wrapper.current !== null) {
      wrapper.current.scrollTo({
        left: wrapper.current.scrollLeft - scrollStepWidth,
        behavior: "smooth",
      });
    }
  };

  const handleStepRight = () => {
    if (wrapper.current !== null) {
      wrapper.current.scrollTo({
        left: wrapper.current.scrollLeft + scrollStepWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setStepLeftDisabled(e.currentTarget.scrollLeft === 0);
    setStepRightDisabled(
      e.currentTarget.scrollLeft + e.currentTarget.offsetWidth >=
        e.currentTarget.scrollWidth,
    );
  };

  return (
    <div className="flex items-center">
      <ButtonCircle
        onClick={handleStepLeft}
        disabled={stepLeftDisabled}
        Icon={ChevronLeft}
        className="hidden my-auto mr-2 text-xs md:block"
      />
      <div
        className={clsx(
          "overflow-hidden md:ml-0 stretch-r stretch-l w-1/3 ",
          className,
        )}
        {...props}
      >
        <div
          ref={wrapper}
          className={clsx(
            "relative px-6 md:px-8 flex snap-x items-start scrollbar-hidden overflow-x-scroll space-x-2 md:space-x-4",
          )}
          onScroll={handleScroll}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child as React.ReactElement, {
              className: clsx("snap-center", innerClassName),
              key: index,
            });
          })}
        </div>
      </div>
      <ButtonCircle
        onClick={handleStepRight}
        disabled={stepRightDisabled}
        Icon={ChevronRight}
        className="hidden ml-6 text-xs md:block"
      />
    </div>
  );
};
