import * as React from "react";
import { SVGProps } from "react";
const Credit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 5.4C19.3137 5.4 22 4.63888 22 3.7C22 2.76112 19.3137 2 16 2C12.6863 2 10 2.76112 10 3.7C10 4.63888 12.6863 5.4 16 5.4Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.2987C10 7.23203 12.7 7.9987 16 7.9987C19.3 7.9987 22 7.23203 22 6.2987M10 8.96536C10 9.8987 12.7 10.6654 16 10.6654C19.3 10.6654 22 9.8987 22 8.96536M10 11.632C10 12.5654 12.7 13.332 16 13.332C19.3 13.332 22 12.5654 22 11.632M14 15.8987C14.6333 15.9654 15.3 15.9987 16 15.9987C19.3 15.9987 22 15.232 22 14.2987M10 13.3654V3.83203"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 3.69922V16.9659C22 17.8992 19.3 18.6659 16 18.6659C15.3 18.6659 14.6333 18.6326 14 18.5659"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16.6656C11.3137 16.6656 14 15.9045 14 14.9656C14 14.0267 11.3137 13.2656 8 13.2656C4.68629 13.2656 2 14.0267 2 14.9656C2 15.9045 4.68629 16.6656 8 16.6656Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 17.6328C2 18.5661 4.66667 19.3328 8 19.3328C11.3333 19.3328 14 18.5661 14 17.6328"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 14.9648V20.2982C14 21.2315 11.3 21.9982 8 21.9982C4.7 21.9982 2 21.2315 2 20.2982V15.0982"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Credit;
