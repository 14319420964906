import * as React from "react";
import { SVGProps } from "react";
const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2316 15.032V17.1C14.2316 17.6523 13.7839 18.1 13.2316 18.1H3C2.44772 18.1 2 17.6523 2 17.1V2.4585"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M3.41667 2.4585H2C2 5.1705 2.55226 11.6745 7.06638 14.4585H19C14.4859 11.6745 13.9336 5.1705 13.9336 2.4585H12.541"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 1C6.6337 1 7.14857 1.67241 7.14857 2.5C7.14857 3.32759 6.6337 4 6 4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 1C9.63365 1 10.1485 1.67241 10.1485 2.5C10.1485 3.32759 9.63365 4 9 4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Calendar;
