import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      SHARE: "Del",
      LINK_COPIED: "Linken er kopiert!",
    },
    se: {
      SHARE: "Dela",
      LINK_COPIED: "Länk kopierad!",
    },
    dk: {
      SHARE: "Del",
      LINK_COPIED: "Linket er kopieret!",
    },
  } as const
)[language];
