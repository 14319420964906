import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      NUTRITION_PER_PORTION: "Næringsbergening (per porsjon)",
      NUTRITION_PER_100_GRAM: "Næringsberegning (per 100 g)",
      ENERGY: "Energi",
      FATS: "Fett",
      CARBOHYDRATES: "Karbohydrater",
      PROTEIN: "Protein",
    },
    se: {
      NUTRITION_PER_PORTION: "Näringsinnehåll per portion",
      NUTRITION_PER_100_GRAM: "Näringsinnehåll per 100 g",
      ENERGY: "Energi",
      FATS: "Fett",
      CARBOHYDRATES: "Kolhydrater",
      PROTEIN: "Protein",
    },
    dk: {
      NUTRITION_PER_PORTION: "Næringsindhold (per portion)",
      NUTRITION_PER_100_GRAM: "Næringsindhold (per 100 g)",
      HEADER: "Ernæringsberegning (pr. portion)",
      ENERGY: "Energi",
      FATS: "Fedt",
      CARBOHYDRATES: "Kulhydrater",
      PROTEIN: "Protein",
    },
  } as const
)[language];
