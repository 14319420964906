import clsx from "clsx";
import { isPast } from "date-fns";
import { useLoyalty } from "@chef/feature-flags";
import { formatDate } from "@chef/helpers";

import { PointsNote } from "./PointsNote";

import { intl } from "./PointsNotification.Intl";

interface PointsNotificationProps {
  showExpirationDate?: boolean;
  hasSaw?: boolean;
  className?: string;
}

interface PointsExpiryProps {
  expirationDate: string | undefined;
  points: number;
}

const PointsExpiry = ({ expirationDate, points }: PointsExpiryProps) => {
  const d = new Date(expirationDate || 0);

  if (!expirationDate || isPast(d)) {
    <p className="text-sm text-success">{intl.SPEND_YOUR_POINTS}</p>;
  }

  return (
    <div className="text-sm text-error">
      {points}p {intl.EXPIRES} {formatDate(d, "yyyy-MM-dd")}
    </div>
  );
};

export const PointsNotification = ({
  showExpirationDate,
  hasSaw,
  className,
}: PointsNotificationProps) => {
  const { data: loyaltyData } = useLoyalty();

  const points = loyaltyData?.availableLoyaltyPoints;
  const firstEntry = loyaltyData?.loyaltyEntries[0];

  if (!points) {
    return null;
  }

  return (
    <PointsNote className={clsx(className)} hasSaw={hasSaw}>
      <div className="ml-5">
        <strong>{intl.YOUR_POINTS}: </strong>
        <span>
          {points}
          {intl.POINT}
        </span>

        {showExpirationDate && firstEntry?.points && (
          <PointsExpiry
            expirationDate={firstEntry?.expirationDate}
            points={firstEntry?.points}
          />
        )}
      </div>
    </PointsNote>
  );
};
