import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { isEqualStrings } from "@chef/utils/equal";
import { BRAND_PATHS, EXTERNAL_PARTNER_IDS } from "@chef/constants";
import {
  addSignupExternalIdentifier,
  showNotification,
  useAddOrUpdateExternalPartnerMutation,
  useBillingNorwegianQuery,
  useMeQuery,
} from "@chef/state-management";
import { useExtendedForm } from "@chef/hooks";
import { Button, Input } from "@chef/components";

import { intl } from "./NorwegianRewardInputForm.Intl";

interface FormData {
  norwegianNumber: string;
}

const schema = yup.object().shape({
  norwegianNumber: yup
    .string()
    .min(8, intl.REWARD_NUMBER_MUST_EIGHT_DIGTIS)
    .required("Required")
    .test({
      test: (value) => {
        if (!value) {
          return false;
        }
        return isValidNorwegianRewardNumber(value);
      },
      message: intl.INVALID_REWARD_NUMBER,
    }),
});

export const NorwegianRewardInputForm = () => {
  const [rewardNumber, setRewardNumber] = useState("");
  const dispatch = useDispatch();

  const router = useRouter();

  const { data: isLoggedIn } = useMeQuery();

  // Update norwegian number when we get the data fetched
  const { rewardno = "" } = router.query;

  const { data: billingNorwegianQuery } = useBillingNorwegianQuery(undefined, {
    skip: !isLoggedIn,
  });

  const externalBillingIdentifiers =
    billingNorwegianQuery?.externalBillingIdentifiers;

  useEffect(() => {
    // if not logged in, we will be just using the rewward number from url
    if (!isLoggedIn) {
      return;
    }
    if (rewardno) {
      return;
    } // it's set in the query
    if (!externalBillingIdentifiers) {
      return;
    }

    const externalPartner = externalBillingIdentifiers.find(
      ({ externalPartnerId }) =>
        isEqualStrings(externalPartnerId, EXTERNAL_PARTNER_IDS.NORWEGIAN),
    );

    if (!externalPartner) {
      return;
    }

    setRewardNumber(externalPartner.externalId);
  }, [externalBillingIdentifiers, rewardno, setRewardNumber]);

  // Update number based on query
  useEffect(() => {
    const rewardValue = Array.isArray(rewardno)
      ? rewardno.find(() => true)
      : rewardno;

    if (rewardValue) {
      setRewardNumber(rewardValue);
    }
  }, [rewardno]);

  const [addOrUpdateExternalPartner] = useAddOrUpdateExternalPartnerMutation();

  useEffect(() => {
    reset({ norwegianNumber: rewardNumber });
  }, [rewardNumber]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useExtendedForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      norwegianNumber: rewardNumber,
    },
  });

  const handleSubmitLoggedOut = () => {
    dispatch(
      addSignupExternalIdentifier({
        externalId: rewardNumber,
        externalPartnerId: EXTERNAL_PARTNER_IDS.NORWEGIAN,
      }),
    );

    router.push(BRAND_PATHS.SIGNUP_HREF);
  };

  const handleSubmitLoggedIn: SubmitHandler<FormData> = async () => {
    try {
      await addOrUpdateExternalPartner({
        externalId: rewardNumber,
        externalPartnerId: EXTERNAL_PARTNER_IDS.NORWEGIAN,
      }).unwrap();

      showNotification({
        type: "success",
        message: intl.YOUR_CHANGES_HAVE_BEEN_SAVED,
      });
    } catch (err) {
      console.error(err);
      showNotification({ type: "error", message: intl.CANNOT_SAVE });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(
        isLoggedIn ? handleSubmitLoggedIn : handleSubmitLoggedOut,
      )}
      className="xl:flex xl:gap-2 xl:w-4/5 xl:justify-center xl:mx-auto"
    >
      <Input
        {...register("norwegianNumber")}
        label="Reward-number"
        className="w-full"
      />

      <Button
        type="submit"
        loading={isSubmitting}
        primary
        className="my-4 shrink-0 xl:mt-0 "
      >
        {isLoggedIn ? intl.SAVE_CHANGES : intl.SIGN_UP_NOW}
      </Button>
    </form>
  );
};

// This is code from :  https://norwegian-reward.readme.io/docs/other-features

const isValidNorwegianRewardNumber = (rewardNumber: string): boolean => {
  const isDigitRegExp = new RegExp("^\\d{8}(\\d{2})?$");
  if (isDigitRegExp.test(rewardNumber)) {
    const len = rewardNumber.length;
    const rewardNumberWithoutCheckDigit = rewardNumber.substring(0, len - 1);
    const checkDigit = rewardNumber.substring(len - 1, len);
    const calculatedCheckDigit = calculateRewardNumberCheckDigit(
      rewardNumberWithoutCheckDigit,
    );
    return checkDigit === calculatedCheckDigit;
  }
  return false;
};

const calculateRewardNumberCheckDigit = (val: string): string => {
  const checksum: string[] = [];

  let odd = false;
  let increment = 0;

  for (let i = 0; i < val.length; i++) {
    const t = val.substring(i, i + 1);
    if (odd) {
      odd = false;
      const intCheck = parseInt(t);
      const oddCheck = (intCheck * 2).toString();
      for (let j = 0; j < oddCheck.length; j++) {
        checksum[increment++] = oddCheck.substring(j, j + 1);
      }
    } else {
      odd = true;
      checksum[increment++] = t;
    }
  }
  let sumInput = 0;
  let sumChecksum = 0;
  for (let i = 0; i < val.length; i++) {
    const t2 = val.substring(i, i + 1);
    const currentInt = parseInt(t2);
    sumInput = sumInput + currentInt;
  }
  for (let i = 0; i < checksum.length; i++) {
    if (parseInt(checksum[i])) {
      sumChecksum = sumChecksum + parseInt(checksum[i]);
    }
  }
  const topRes = sumChecksum + 10;
  const divRes = Math.floor(topRes / 10);
  const sumRes = divRes * 10;
  let calcCheckSum = sumRes - sumChecksum;
  // if the highest number 10 is 10 then we force it to 0 to avoid number
  // overflow
  // eslint-disable-next-line eqeqeq
  if (calcCheckSum == 10) {
    calcCheckSum = 0;
  }
  return calcCheckSum.toString();
};
