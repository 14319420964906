import { Fragment } from "react";
import clsx from "clsx";

import { ChevronRight, Check } from "@chef/icons/small";

interface StepsProps {
  className?: string;
  currentStep: number;
  steps: { label: string }[];
}

export const Steps = ({
  steps = [],
  currentStep = 1,
  className,
}: StepsProps) => {
  const baseStyles = "flex justify-center items-center ";

  return (
    <ul className={clsx(baseStyles, className)}>
      {steps.map((step, i) => {
        const isActive = i + 1 === currentStep;
        const isCompleted = i + 1 < currentStep;

        const stepStyle =
          "items-center justify-center text-center w-5 h-5 mr-2 rounded-full border-1.5 border-primary flex-shrink-0 text-xs text-[10px] hidden md:flex ";

        const labelStyle =
          "text-[10px] md:text-sm font-normal md:th-lmk:font-semibold";

        return (
          <Fragment key={step.label + i}>
            {i > 0 && (
              <li className="text-grey-1 text-xs mr-1.5 hidden md:inline">
                <ChevronRight />
              </li>
            )}

            <li className="flex items-center ml-0 mr-3 md:mr-4 md:ml-5">
              <div
                className={clsx(
                  stepStyle,
                  (isActive || isCompleted) && "bg-primary text-white",
                )}
              >
                {isCompleted ? (
                  <Check />
                ) : (
                  <span
                    className=" th-lmk:lining-nums th-lmk:proportional-nums tabular-nums th-amk:pt-0.5"
                    style={{ lineHeight: "0.75rem" }}
                  >
                    {i + 1}
                  </span>
                )}
              </div>
              <div>
                <div
                  className={clsx(
                    "md:hidden border-4 border-solid rounded-xl",
                    isActive || isCompleted
                      ? "border-primary th-gl:border-secondary"
                      : "border-grey-2",
                  )}
                />
                <span
                  className={clsx(
                    labelStyle,
                    isCompleted && !isActive ? "text-black" : "text-grey-1",
                    isActive &&
                      "sm:text-primary sm:th-gl:text-secondary sm:th-lmk:font-semibold sm:th-gl:font-bold md:th-gl:font-normal md:text-black md:font-normal md:th-gl:text-black",
                  )}
                >
                  {step.label}
                </span>
              </div>
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};
