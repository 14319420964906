import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      ENJOY_THE_FOOD: "God middag!",
    },
    se: {
      ENJOY_THE_FOOD: "Smaklig måltid!",
    },
    dk: {
      ENJOY_THE_FOOD: "Håber maden smager!",
    },
  } as const
)[language];
