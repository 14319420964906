import * as React from "react";
import { SVGProps } from "react";
const Default = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 28.5C28 29.3284 27.3284 30 26.5 30C25.6716 30 25 29.3284 25 28.5C25 27.6716 25.6716 27 26.5 27C27.3284 27 28 27.6716 28 28.5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 28.5C17 29.3284 16.3284 30 15.5 30C14.6716 30 14 29.3284 14 28.5C14 27.6716 14.6716 27 15.5 27C16.3284 27 17 27.6716 17 28.5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 19.5H29.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5 19.5L32 11.5H12.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 19L11 9H8"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 23.5H28.5C31 23.5 32 25 32 25"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 23.5C11.8941 23.5 11 22.6059 11 21.5C11 20.3941 11.8941 19.5 13 19.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Default;
