/* eslint-disable @typescript-eslint/no-explicit-any */

export const withErrorBoundary = <T extends (...args: any[]) => any>(
  fn: T,
  env?: NodeJS.ProcessEnv["NODE_ENV"],
): ReturnType<T> | null => {
  try {
    return fn();
  } catch (error) {
    if ((env || process.env["NODE_ENV"]) === "production") {
      console.error(error);
      return null;
    } else {
      throw error;
    }
  }
};
