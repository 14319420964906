import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export type GiftcardInputType = "custom" | "preset" | "none";
interface GiftcardState {
  inputType: GiftcardInputType;
  amount?: string;
}

const initialState: GiftcardState = {
  inputType: "none",
};

export const giftcardSlice = createSlice({
  name: "giftcard",
  initialState,
  reducers: {
    setGiftcardAmount: (state, action: PayloadAction<GiftcardState>) => {
      state.amount = action.payload.amount;
      state.inputType = action.payload.inputType;
    },
  },
});

export const { setGiftcardAmount } = giftcardSlice.actions;

export const selectGiftcardAmount = (state: RootState) => state.giftcard.amount;
export const selectInputType = (state: RootState) => state.giftcard.inputType;
