import { v4 as uuidv4 } from "uuid";

import { api, tags } from "../graphql/api";
import { IApplicantInfo } from "./auth";
export interface IUpdateUserInfoArgs {
  firstName: string;
  lastName: string;
  // birthdate?: Date; // not used
  telephone: string;
  telephoneAlt?: string;
  email: string;
}

export interface IUpdateUserConsentArgs {
  consentId: string;
  isAccepted: boolean;
}

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAndGetApplicantInfo: builder.mutation<IApplicantInfo, void>({
      query: () => ({
        url: "user/createAndGetApplicantId",
        method: "POST",
        body: { frontendId: uuidv4() },
      }),
    }),

    updateUserConsents: builder.mutation<void, IUpdateUserConsentArgs[]>({
      query: (args) => ({
        url: "/user/updateUserConsents",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.consent],
    }),

    updateUserInformation: builder.mutation<void, IUpdateUserInfoArgs>({
      query: (args) => ({
        url: "/user/updateUserInformation",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [tags.userInfo],
    }),
  }),
});

export const {
  useCreateAndGetApplicantInfoMutation,
  useUpdateUserConsentsMutation,
  useUpdateUserInformationMutation,
} = userApi;
