import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { language } from "@chef/constants";
import { billingApi, showNotification } from "../features";

const intl = {
  se: {
    GIFT_CARD_ACTIVATED: `Presentkort är aktiverad`,
    ERROR_MESSAGE: "En fel har uppstått. Kontakta kundtjänst.",
  },
  no: {
    GIFT_CARD_ACTIVATED: `Gavekortet er aktivert`,
    ERROR_MESSAGE: "En feil oppstod. Prøv igjen eller kontakt kundeservice.",
  },
  dk: {
    GIFT_CARD_ACTIVATED: `Gavekort tilføjet`,
    ERROR_MESSAGE: "En fejl opstod. Prøv igen eller kontakt kundeservice.",
  },
}[language];

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.submitGiftcard.matchFulfilled,
      billingApi.endpoints.submitGiftcard.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        // const errorCode = action["payload"].data.ErrorCode;

        const message = intl.ERROR_MESSAGE;

        showNotification({
          type: "error",
          message,
        });
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.GIFT_CARD_ACTIVATED,
        });
      }
    },
  });
