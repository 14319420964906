import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { config, PRODUCT_TYPE_IDS, BRAND_PATHS } from "@chef/constants";
import { isPickAndMixProduct } from "@chef/helpers";
import {
  useExtendedBasketData,
  useUpdateBasketPickAndMixDeviation,
} from "@chef/state-management/hooks";
import {
  PickAndMixQuery,
  RecipeAndStepsQuery,
  resetRecipeModal,
  selectDeviationProducts,
  selectRecipeModal,
  usePickAndMixQuery,
  useRecipeAndStepsQuery,
} from "@chef/state-management";
import { isEqualStrings } from "@chef/utils/equal";
import {
  getVariationFromPickAndMix,
  isVariationPlusPortions,
} from "@chef/state-management/helpers";
import { useIntercom } from "@chef/hooks";
import { Modal } from "@chef/smart-components";

import { Recipe } from "./recipe/Recipe";

type PickAndMixProductVariation =
  PickAndMixQuery["pickAndMix"][0]["product"]["variations"][0];

type RecipeAndStepsPortion =
  RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][number];

export interface IRecipeComponentProps {
  // generic
  recipe: RecipeAndStepsQuery["recipeAndSteps"];
  disableUpdateBasket?: boolean;
  isInBasket?: boolean;
  addOrRemoveToBasket?: (variation?: PickAndMixProductVariation) => void;
  week?: number | null;
  year?: number | null;
  productVariation?: PickAndMixProductVariation | null;

  selectedPortionSize?: Omit<RecipeAndStepsPortion, "ingredientSections">;

  // overrides
  overridePortionSize?: string;
  overrideDisableDropdown?: boolean;

  // plus protein (RN specific)
  plusProteinVariation?: PickAndMixProductVariation | null;
  isPlusProteinInBasket?: boolean;

  // Call To Action button if user is logged out
  showCTA?: boolean;

  // used for price calculation
  signup?: boolean;
}

interface IRecipeModalProps {
  Loader: () => JSX.Element;
}

export const RecipeModal = ({ Loader }: IRecipeModalProps) => {
  const dispatch = useDispatch();

  const { open, recipeId, pickAndMixProduct, misc } =
    useSelector(selectRecipeModal);

  useIntercom(!open);

  const { week = 0, year = 0 } = pickAndMixProduct || {};

  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const {
    updateBasketPickAndMixDeviation,
    isLoading: isLoadingUpdateBasketPickAndMixDeviation,
  } = useUpdateBasketPickAndMixDeviation({ week, year, signup: misc?.signup });

  const { data: extendedBasketData, isLoading: isLoadingExtendedBasketData } =
    useExtendedBasketData({ week, year, signup: misc?.signup });

  const { data: { pickAndMix = [] } = {}, isLoading: isLoadingPickAndMix } =
    usePickAndMixQuery(
      {
        productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
        week,
        year,
      },
      { skip: !week || !year },
    );

  const {
    data: recipeAndStepsQuery,
    isLoading: isLoadingRecipeAndSteps,
    isFetching: isFetchingRecipesAndSteps,
  } = useRecipeAndStepsQuery({ recipeId }, { skip: !recipeId });

  const recipeAndSteps = recipeAndStepsQuery?.recipeAndSteps;
  // misc
  const recipeUrl =
    (recipeAndSteps &&
      BRAND_PATHS.RECIPE_WITH_NAME_HREF({
        recipeId: recipeAndSteps?.recipeId,
        recipeName: recipeAndSteps?.recipeName,
      })) ||
    "/";

  const [isRecipeUrlSet, setIsRecipeUrlSet] = useState(false);
  useEffect(() => {
    if (!open) {
      return;
    }
    if (isLoadingRecipeAndSteps) {
      return;
    }
    if (isFetchingRecipesAndSteps) {
      return;
    }

    // https://stackoverflow.com/a/33004917
    let initialScrollRestoration: ScrollRestoration | undefined;
    if ("scrollRestoration" in window.history) {
      initialScrollRestoration = window.history.scrollRestoration;
      window.history.scrollRestoration = "manual";
    }

    // Replace current state with scroll position so that when we close the modal we recover it
    window.history.replaceState(
      { path: window.location.href, scrollTop: window.scrollY },
      "",
    );
    window.history.pushState({}, "", recipeUrl);
    setIsRecipeUrlSet(true);

    window.addEventListener("popstate", onClose);

    return () => {
      if (window.location.href.includes(recipeUrl)) {
        window.history.back();
      }
      setIsRecipeUrlSet(false);
      window.removeEventListener("popstate", onClose);

      if (initialScrollRestoration) {
        window.history.scrollRestoration = initialScrollRestoration;
      }
    };
  }, [recipeUrl, open, setIsRecipeUrlSet]);

  const isLoading =
    isLoadingRecipeAndSteps ||
    isLoadingExtendedBasketData ||
    isLoadingPickAndMix ||
    isFetchingRecipesAndSteps ||
    !isRecipeUrlSet ||
    isLoadingUpdateBasketPickAndMixDeviation;

  // if we for some reason don't have the recipe data
  if (!isLoading && !recipeAndSteps) {
    return null;
  }

  const isInBasket =
    selectedProducts?.some((p) =>
      isEqualStrings(p.productId, pickAndMixProduct?.productId),
    ) || false;

  // if basket is full && not added to basket
  const disableUpdateBasket =
    selectedProducts?.filter(isPickAndMixProduct)?.length ===
      config.order.maxMeals && !isInBasket;

  // find variations
  let productVariation: PickAndMixProductVariation | null = null;
  let plusProteinVariation: PickAndMixProductVariation | null = null;
  if (pickAndMixProduct) {
    for (const recipe of pickAndMixProduct.recipes) {
      for (const portion of recipe.portions) {
        if (portion.size !== extendedBasketData.portions) {
          continue;
        }

        const variation = getVariationFromPickAndMix({
          pickAndMix,
          productId: pickAndMixProduct.productId,
          variationId: portion.variationId,
        });
        if (!variation) {
          continue;
        }

        const isPlusPortions = isVariationPlusPortions(variation);
        if (isPlusPortions) {
          plusProteinVariation = variation;
        } else {
          productVariation = variation;
        }
      }
    }
  }

  const isPlusProteinInBasket =
    selectedProducts?.some(
      (p) =>
        isEqualStrings(p.productId, pickAndMixProduct?.productId) &&
        isEqualStrings(p.variationId, plusProteinVariation?.variationId),
    ) || false;

  // functions
  const onClose = () => {
    dispatch(resetRecipeModal());
  };

  const addOrRemoveToBasket = (variation?: PickAndMixProductVariation) => {
    // if we don't have a valid basket
    if (!selectedProducts) {
      return;
    }
    if (!pickAndMixProduct) {
      return;
    }

    updateBasketPickAndMixDeviation({
      // filter out any products of the same id that are already in the basket
      basketProducts: [...selectedProducts].filter(
        (p) => !isEqualStrings(p.productId, pickAndMixProduct.productId),
      ),
      portions: extendedBasketData.portions,
      // if we got a variation, add it to the basket
      ...(variation && {
        updateProduct: {
          productId: pickAndMixProduct.productId,
          variationId: variation.variationId,
        },
      }),
    });
  };

  return (
    <Modal
      name="recipe-modal"
      open={open}
      onClose={onClose}
      animation="fly-up"
      gesture={!isLoading && "pull-down-to-close"}
      closeable={!isLoading}
    >
      <div
        className="min-h-screen"
        id={`recipe-${recipeAndSteps?.recipeId?.toString()}`}
      >
        {isLoading ? (
          <div className="flex justify-center p-10">
            <Loader />
          </div>
        ) : (
          <Recipe
            // we're ignoring here because this should be an impossible case
            // we're checking recipeAndSteps earlier in the code
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            recipe={recipeAndSteps}
            disableUpdateBasket={disableUpdateBasket}
            isInBasket={isInBasket}
            week={week}
            year={year}
            addOrRemoveToBasket={addOrRemoveToBasket}
            productVariation={productVariation}
            plusProteinVariation={plusProteinVariation}
            isPlusProteinInBasket={isPlusProteinInBasket}
            overrideDisableDropdown={misc?.disableDropdown}
            overridePortionSize={misc?.portionSize}
            signup={misc?.signup}
            showCTA={misc?.showCTA}
          />
        )}
      </div>
    </Modal>
  );
};
