import * as React from "react";
import { SVGProps } from "react";
const ExitDoor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 75C0 33.5786 33.5786 0 75 0V0C116.421 0 150 33.5786 150 75V75C150 116.421 116.421 150 75 150V150C33.5786 150 0 116.421 0 75V75Z"
      fill="#F4F4F1"
    />
    <path
      d="M70.6875 98.5H91.1875V42.125H45.0625V97.2188L70.6875 115.156V61.3438L45.0625 42.125"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.844 57.5L117.656 70.3125L104.844 83.125M80.5 70.3125H117.656H80.5Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ExitDoor;
