import { useRef, useState } from "react";
import clsx from "clsx";

import { ChevronLeft, ChevronRight } from "@chef/icons/small";

import { Trustpilot } from "@chef/icons/other";
import { ButtonCircle, Card, Rating } from "../components";

const SCROLL_STEP_WIDTH = 448;

const intl = {
  no: {
    READ_MORE: "Les mer",
    READ_LESS: "Les mindre",
    SCROLL_LEFT: "Scroll til venstre",
    SCROLL_RIGHT: "Scroll til høyre",
  },
  se: {
    READ_MORE: "Läs mer",
    READ_LESS: "Läs mindre",
    SCROLL_LEFT: "Scrolla vänster",
    SCROLL_RIGHT: "Scrolla höger",
  },
  dk: {
    READ_MORE: "Læs mere",
    READ_LESS: "Læse mindre",
    SCROLL_LEFT: "Scroll til venstre",
    SCROLL_RIGHT: "Scroll til højre",
  },
};

const ScrollButtons = ({
  stepLeftDisabled,
  stepRightDisabled,
  handleStepLeft,
  handleStepRight,
  className,
  language,
}) => {
  const { SCROLL_LEFT, SCROLL_RIGHT } = intl[language];
  return (
    <div className={clsx("space-x-2", className)}>
      <ButtonCircle
        onClick={handleStepLeft}
        disabled={stepLeftDisabled}
        Icon={ChevronLeft}
        className="text-xs bg-grey-2"
        aria-label={SCROLL_LEFT}
      />
      <ButtonCircle
        onClick={handleStepRight}
        disabled={stepRightDisabled}
        Icon={ChevronRight}
        className="text-xs bg-grey-2"
        aria-label={SCROLL_RIGHT}
      />
    </div>
  );
};

const ReviewCard = ({ review, language = "no" }) => {
  const [expanded, setExpanded] = useState(false);
  const canExpand = review.text.length > 140 || review.title.length > 40;
  const { READ_MORE, READ_LESS } = intl[language];
  return (
    <article className="inline-flex justify-center w-full shrink snap-center">
      <Card
        noPadding
        className={
          "mb-5 p-10 space-y-6 w-80 lg:w-112 min-h-[24rem] flex flex-col rounded-sm"
        }
      >
        <Rating value={review.stars} numOptions={5} readOnly />
        <strong className={clsx(!expanded && "line-clamp-2", "text-xl")}>
          {review.title}
        </strong>
        <div className="grow">
          <p className={clsx(!expanded && "line-clamp-3")} title={review.text}>
            {review.text}
          </p>
          {canExpand && (
            <aside>
              <button
                onClick={() => setExpanded(!expanded)}
                className="text-sm underline"
              >
                {!expanded ? READ_MORE : READ_LESS}
              </button>
            </aside>
          )}
        </div>
        <div className="flex flex-col">
          <strong>{review.consumer.displayName}</strong>
          <span className="h-4 text-grey-1">{review.location}</span>
        </div>
      </Card>
    </article>
  );
};

const TrustPilotCarousel = ({ reviews, title, language }) => {
  const wrapper = useRef();

  const [stepLeftDisabled, setStepLeftDisabled] = useState(true);
  const [stepRightDisabled, setStepRightDisabled] = useState(false);

  const handleStepLeft = () => {
    wrapper.current.scrollTo({
      left: wrapper.current.scrollLeft - SCROLL_STEP_WIDTH,
      behavior: "smooth",
    });
  };

  const handleStepRight = () => {
    wrapper.current.scrollTo({
      left: wrapper.current.scrollLeft + SCROLL_STEP_WIDTH,
      behavior: "smooth",
    });
  };

  const handleScroll = (e) => {
    setStepLeftDisabled(e.target.scrollLeft === 0);
    setStepRightDisabled(
      e.target.scrollLeft + e.target.offsetWidth >= e.target.scrollWidth,
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between md:flex-row">
        <div className="w-full pb-6 md:basis-80 xl:basis-112 shrink-0 md:pb-0 md:pr-8">
          <Trustpilot className="w-32 h-8 mb-6" />
          <h2 className="text-2xl text-left break-words lg:text-3xl">
            <strong>{title}</strong>
          </h2>
          <ScrollButtons
            stepLeftDisabled={stepLeftDisabled}
            stepRightDisabled={stepRightDisabled}
            handleStepLeft={handleStepLeft}
            handleStepRight={handleStepRight}
            language={language}
            className="hidden mt-12 md:flex"
          />
        </div>
        <div className="overflow-hidden md:ml-0 stretch-r stretch-l">
          <div
            ref={wrapper}
            className={clsx(
              "px-4 relative flex items-stretch scrollbar-hidden overflow-x-scroll snap-x space-x-2 md:space-x-4",
            )}
            onScroll={handleScroll}
          >
            {reviews.map((review) => {
              return (
                <ReviewCard
                  key={review.id}
                  review={review}
                  language={language}
                />
              );
            })}
          </div>
        </div>
      </div>
      <ScrollButtons
        stepLeftDisabled={stepLeftDisabled}
        stepRightDisabled={stepRightDisabled}
        handleStepLeft={handleStepLeft}
        handleStepRight={handleStepRight}
        language={language}
        className="flex justify-center md:hidden"
      />
    </>
  );
};

export default TrustPilotCarousel;
