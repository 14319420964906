import React from "react";
import clsx from "clsx";

interface TabButtonProps {
  title: string;
  subtitle?: string;
  selected?: boolean;
  disabled?: boolean;
}

const TabButton = React.forwardRef<HTMLDivElement, TabButtonProps>(
  ({ title, subtitle, selected, disabled }, ref) => {
    return (
      <div
        className={clsx(
          "flex items-center justify-center h-16 w-21 md:h-18 md:w-28",
          selected
            ? "bg-informationBG border-1.5 border-secondary"
            : "text-grey-1",
        )}
        ref={ref}
      >
        <div className="leading-normal">
          <strong className={clsx(disabled && "line-through")}>{title}</strong>
          {subtitle && <div className="text-xs">{subtitle}</div>}
        </div>
      </div>
    );
  },
);

export default TabButton;
