import { useEffect } from "react";
import Link from "next/link";

import { useAnalytics } from "@chef/feature-tracking";
import { useMeQuery } from "@chef/state-management";
import { BRAND_PATHS } from "@chef/constants";
import { Button } from "@chef/components";

import { AppShell } from "../components/AppShell";
import { intl } from "./LoggedOutPage.Intl";

export const LoggedOutPage = () => {
  const analytics = useAnalytics();
  const { data: meQuery } = useMeQuery();

  useEffect(() => {
    if (!analytics) {
      return;
    }

    // Make sure we're only resetting the analytics instance if the user is logged out (me query returns null)
    if (meQuery !== null) {
      return;
    }

    (async () => {
      const user = await analytics.user();

      const isAlreadyAnonymous = user.id() === null;

      if (!isAlreadyAnonymous) {
        analytics.reset();
      }
    })();
  }, [analytics, meQuery]);

  return (
    <AppShell title={intl.LOGGED_OUT}>
      <AppShell.Meta />
      <AppShell.Header />
      <AppShell.Main grid>
        <section className="flex flex-col gap-6 col-span-full">
          <h1>
            <strong>{intl.YOU_ARE_NOW_LOGGED_OUT}</strong>
          </h1>

          <p>{intl.THANK_YOU_FOR_YOUR_VISIT}</p>

          <p>
            {intl.IF_YOU_WANT_TO_LOG_IN_AGAIN}{" "}
            <Link href={BRAND_PATHS.LOG_IN_URL} className="underline">
              {intl.CLICK_HERE}
            </Link>
            .
          </p>

          <p>
            <Link href="/">
              <Button primary>{intl.GO_TO_LANDING_PAGE}</Button>
            </Link>
          </p>
        </section>
      </AppShell.Main>
      <AppShell.Footer />
    </AppShell>
  );
};
