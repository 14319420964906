import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { Button } from "@chef/components";
import { useScrollDirection } from "@chef/hooks";
import { ChevronUp } from "@chef/icons/small";
import { intl } from "./BackToTopButton.Intl";

interface BackToTopButtonProps {
  element?: HTMLElement | null;
  sticky?: boolean;
}

export const BackToTopButton = ({
  element,
  sticky = true,
}: BackToTopButtonProps) => {
  const { direction, isAtTop, isAtBottom } = useScrollDirection(60);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const isScrollable =
        (element || document.documentElement).scrollHeight > window.innerHeight;

      setIsScrollable(isScrollable);
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, [element]);

  if (!isScrollable) {
    return null;
  }

  return (
    <motion.div
      className={clsx(
        "flex justify-center w-full pointer-events-none",
        sticky && "sticky bottom-6 pt-12 z-20",
      )}
      animate={{
        y: direction === "up" && !isAtTop && !isAtBottom ? -80 : 0,
      }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
    >
      <Button
        className={clsx("pointer-events-auto", direction === "up" && "shadow")}
        small
        IconRight={ChevronUp}
        onClick={() => {
          (element || window).scroll({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        {intl.BACK_TO_TOP}
      </Button>
    </motion.div>
  );
};
