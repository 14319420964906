import { IDeviationBasket } from "@chef/state-management";
import { useLoyalty } from "@chef/feature-flags";

import { useFinancesForDeviation } from "./useFinancesForDeviation";
import { DeliveryFee } from "../shared/DeliveryFee";

interface DeviationDeliveryProps {
  deviation: IDeviationBasket;
}

export const DeviationDelivery = ({ deviation }: DeviationDeliveryProps) => {
  const { data: loyaltyData } = useLoyalty();
  const { finances } = useFinancesForDeviation({ deviation });

  if (!loyaltyData) {
    return null;
  }

  return (
    <DeliveryFee
      customerFee={finances.customerFee}
      hasFreeShipping={loyaltyData.currentLevel === 4}
    />
  );
};
