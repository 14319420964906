import { useCallback } from "react";

import { BRAND_PATHS, language } from "@chef/constants";
import { applyGenitiveCase } from "@chef/helpers";

import { useCreateShortLinkMutation } from "../features";

interface ShareData {
  recipeIds: number[];
  week: number;
  year: number;
  name: string;
  portions: number;
  meals: number;
  agreementId: number;
}

const createUrl = (data: ShareData) => {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  for (const recipeId of data.recipeIds) {
    params.append("ids", recipeId.toString());
  }

  const base64Encoded = Buffer.from(params.toString()).toString("base64");

  const url = new URL(
    BRAND_PATHS.SHARED_MENU_PAGE(base64Encoded),
    window.location.origin,
  );

  return url.toString();
};

export const useSharedMenuUrl = () => {
  const [createShortLink, { data: shortLink, isLoading }] =
    useCreateShortLinkMutation();

  const createSharedMenuUrl = useCallback(async (data: ShareData) => {
    const { recipeIds, week, year, name, portions, meals, agreementId } = data;

    let result;

    // Check for existing short link
    if (shortLink) {
      result = shortLink;
    } else {
      const encodedUrl = createUrl({
        recipeIds,
        week,
        year,
        name,
        portions,
        meals,
        agreementId,
      });
      result = await createShortLink({ longUrl: encodedUrl }).unwrap();
    }

    const sharerName = applyGenitiveCase(data.name);

    return { href: result.shortUrl, text: intl.CHECK_OUT_MENU(sharerName) };
  }, []);

  return [createSharedMenuUrl, { isLoading }] as const;
};

const intl = (
  {
    no: {
      CHECK_OUT_MENU: (name: string) => `Sjekk ut ${name} ukemeny!`,
    },
    se: {
      CHECK_OUT_MENU: (name: string) => `Kolla in ${name} veckomeny!`,
    },
    dk: {
      CHECK_OUT_MENU: (name: string) => `Se ${name} ugemenu!`,
    },
  } as const
)[language];
