import { BRAND_NAME, language } from "@chef/constants";

export const intl = (
  {
    no: {
      WELCOME_BACK: "Velkommen tilbake!",
      RESUME_SUBSCRIPTION: "Gjenoppta abonnement",
      CANCEL: "Avbryt",
    },

    se: {
      WELCOME_BACK: "Välkommen tillbaka!",
      RESUME_SUBSCRIPTION: "Återuppta prenumeration",
      CANCEL: "Avbryt",
    },

    dk: {
      WELCOME_BACK: "Velkommen tilbage!",
      RESUME_SUBSCRIPTION: "Genaktiver abonnement",
      CANCEL: "Afbryd",
    },
  } as const
)[language];

export const brand = (
  {
    AMK: {
      NICE_THAT_YOU_ARE_BACK:
        "Vi er glade for at du har funnet veien tilbake til oss i Adams Matkasse",
      BY_RESUMING_I_ACCEPT_THE_CURRENT: `Når jeg gjenopptar abonnementet, aksepterer jeg ${" "}`,
      TERMS_AND_CONDITIONS: "Adams Matkasse sine vilkår og betingelser.",
    },
    LMK: {
      NICE_THAT_YOU_ARE_BACK:
        "Vad roligt att du har hittat tillbaka till oss på Linas Matkasse.",
      RESUME_SUBSCRIPTION: "Återuppta prenumeration",
      BY_RESUMING_I_ACCEPT_THE_CURRENT: `Genom att återuppta prenumerationen godkänner jag ${" "}`,
      TERMS_AND_CONDITIONS: "Linas Matkasses Användarvillkor.",
    },
    GL: {
      NICE_THAT_YOU_ARE_BACK:
        "Vi er glade for at du har funnet veien tilbake til oss i Godtlevert",
      BY_RESUMING_I_ACCEPT_THE_CURRENT: `Når jeg gjenopptar abonnementet, aksepterer jeg ${" "}`,
      TERMS_AND_CONDITIONS: "Godtlevert sine vilkår og betingelser.",
    },
    RN: {
      NICE_THAT_YOU_ARE_BACK:
        "Vi glæder os til at hjælpe dig med at skabe masser af madglæde i hverdagen.",
      BY_RESUMING_I_ACCEPT_THE_CURRENT: `Ved at genaktivere mit abonnement accepterer jeg ${" "}`,
      TERMS_AND_CONDITIONS: "RetNemts handelsbetingelser",
    },
  } as const
)[BRAND_NAME];
