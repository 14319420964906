import * as React from "react";
import { SVGProps } from "react";
const Paused = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29 20.2002H42.2V81.8002H29V20.2002Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.7998 20.2002H72.9998V81.8002H59.7998V20.2002Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Paused;
