import * as React from "react";
import { SVGProps } from "react";
const Broken = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 75C0 33.5786 33.5786 0 75 0V0C116.421 0 150 33.5786 150 75V75C150 116.421 116.421 150 75 150V150C33.5786 150 0 116.421 0 75V75Z"
      fill="#F4F4F1"
    />
    <path
      d="M63.877 114.344H85.1216"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.5 35L74.4973 40.1387L71.8438 47.9348L77.1562 53.2473L71.8438 69.1848"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.5 113V87V113ZM61.6875 35H87.3125C87.3125 35 95 57.5329 95 68.8C95 80.2868 85.8237 87 74.5 87C63.1763 87 54 80.2868 54 68.8C54 61.0403 61.6875 35 61.6875 35Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Broken;
