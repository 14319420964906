import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Events } from "@chef/events";
import { BRAND_NAME } from "@chef/constants";

export const segmentApi = createApi({
  reducerPath: "segmentApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/segment" }),

  endpoints: (builder) => ({
    track: builder.mutation<
      { message: string },
      { eventName: keyof Events; payload: Events[keyof Events]; id?: string }
    >({
      query: ({ eventName, payload, id }) => ({
        url: "/trck",
        method: "POST",
        body: {
          eventName,
          payload: { ...payload, brand: BRAND_NAME },
          id,
        },
      }),
    }),
  }),
});

export const { useTrackMutation } = segmentApi;
