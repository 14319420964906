import { BRAND_NAME } from "./misc";

export const STOCK_CONFIG = {
  FILTER_PRODUCTS_WITH_STOCK_GREATER_THAN: 20,
} as const;

export const PRODUCT_TYPE_IDS = {
  MEALBOX: "2f163d69-8ac1-6e0c-8793-ff0000804eb3",
  // deprecated due to norwegian naming -- here for historical purposes
  // VELGVRAK: "cac333ea-ec15-4eea-9d8d-2b9ef60ec0c1",
  PICKANDMIX: "cac333ea-ec15-4eea-9d8d-2b9ef60ec0c1",
  ADDON: "e146c37c-dd49-42c7-8a78-6bb647021f66",
  STANDALONE: "adb241c3-deca-4fd7-a173-923be1a7abd3",
  FINANCIAL: "288ed8ca-b437-4a6d-be16-8f9a30185008",
  LIMITED_QUANTITY: "cc020f8e-faf1-4438-8730-4872e9fe5e07",
  ADMINISTRATIVE: "6f20ec61-30f3-4007-be83-02eee88c7fb2",
} as const;

export const PRODUCT_IDS = {
  ONESUB: "D699150E-D2DE-4BC1-A75C-8B70C9B28AE3",
} as const;

export const VARIATION_IDS = {
  ONESUB_SINGLE: "c1ec9994-1283-4271-b350-79740e50074d",
};

export type ProductTypeId =
  (typeof PRODUCT_TYPE_IDS)[keyof typeof PRODUCT_TYPE_IDS];

export const PRODUCT_CATEGORY_IDS = (
  {
    AMK: {
      FINANCIAL: "5fd0e392-ffad-4cfc-bb14-02875284fab1",
      // matkasse
      MEALBOX_LOGGED_OUT: "bbe12b1a-dece-4dcb-adbc-5a0742fe7998",
      // appMinMatkasse
      MEALBOX_LOGGED_IN: "367af439-00c5-4026-9fdc-e90f83a70650",
      // appMinMatkasseUkeMiddager
      PICKANDMIX_LOGGED_IN: "60621401-b1a9-41fb-ae35-5a6884e83810",
      //appMinkontoMatkasse
      MY_PAGE_MY_MENU: "8428e129-47c7-4073-b363-1ac3335e07c8",
    },
    GL: {
      FINANCIAL: "7f091762-05e6-4cc9-9d34-793811d4a569",
      // matkasse
      MEALBOX_LOGGED_OUT: "4c3f0319-c82f-492d-ab79-6ece00b36175",
      // appMinmatkasse
      MEALBOX_LOGGED_IN: "187cfe20-82ff-4207-9979-65b9298d9c0c",
      // appMinmatkasseUkeMiddager
      PICKANDMIX_LOGGED_IN: "55eb86ee-64f1-4daa-baa2-68e85a3c7acd",
      //appMinkontoMatkasse
      MY_PAGE_MY_MENU: "093fabe5-b523-4af8-82a3-a0e12c154b1e",
    },
    LMK: {
      FINANCIAL: "ae898ff7-1b96-4c33-bb5a-c84055810ca5",
      // varaMatkasser
      MEALBOX_LOGGED_OUT: "f2effe2c-a0d6-482f-b962-9c6384f6730b",
      // minaSidorMittKontoMatkasse
      MEALBOX_LOGGED_IN: "d2b27d42-9240-4ff4-b798-e3e09ac26742",
      // minaSidorMinMenyVeckaAlacarte
      PICKANDMIX_LOGGED_IN: "549c85bb-f8ad-4a6c-8891-139cab033c2b",
      //minaSidorMinMeny
      MY_PAGE_MY_MENU: "e2b1d517-291d-4942-8e15-2743aecb3aab",
    },
    RN: {
      FINANCIAL: "7b3a598f-743a-44ad-979c-bcaebbf99fe6",
      // matkasse
      MEALBOX_LOGGED_OUT: "eb8ef9b7-b5d0-4e51-89a1-1e51f0a86d63",
      // appMinMatkasse / appMinKontoMatkasse
      MEALBOX_LOGGED_IN: "2908d25b-7f9a-4263-8142-f8e9687f9895",
      // appMinMatkasseUkeMiddager
      PICKANDMIX_LOGGED_IN: "3d202211-8b73-47ab-bca8-28cf9ed3ba0a",
      //appMinkontoMatkasse
      MY_PAGE_MY_MENU: "6ed3ba75-3537-40c7-8386-cab617a4e514",
    },
  } as const
)[BRAND_NAME];

export type ProductCategoryId =
  (typeof PRODUCT_CATEGORY_IDS)[keyof typeof PRODUCT_CATEGORY_IDS];

export const TAXONOMY_IDS = (
  {
    AMK: {
      GLUTENFREE_OLD: 952,
      LACTOSEFREE_OLD: 953,
      GLUTENFREE: 1838,
      LACTOSEFREE: 1837,
    },
    GL: {
      GLUTENFREE_OLD: 952,
      LACTOSEFREE_OLD: 953,
      GLUTENFREE: 1838,
      LACTOSEFREE: 1837,
    },
    LMK: {
      GLUTENFREE_OLD: 1163,
      LACTOSEFREE_OLD: 1164,
      GLUTENFREE: 1842,
      LACTOSEFREE: 1841,
      WEIGHT_WATCHERS: 1878,
      CHRISTMAS_CAMPAIGN: 1875, //TODO: Add this to all brands.
    },
    RN: {
      GLUTENFREE_OLD: 1533,
      LACTOSEFREE_OLD: 1534,
      GLUTENFREE: 1836,
      LACTOSEFREE: 1835,
    },
  } as const
)[BRAND_NAME];

export const TAXONOMY_TYPES = {
  CAMPAIGN: "campaign_tag",
  CATEGORY: "category_tag",
  COUNTRY: "country",
  DURABILITY: "durability",
  MAIN_CUSTOMER: "main_customer_tag",
  MARKETING: "marketing_tag",
  MENUPLANNING: "menu_planning",
  PROCUREMENT: "procurement",
  RECIPE: "recipe",
  RECIPECHEFSINTERNATIONAL: "recipe_chefs_internal",
  SPECIALFOOD: "special_food_tag",
  SUPPLIER: "supplier",
  RECOMMENDED: "recommended",
  PUBLIC_RECIPE_BANK: "public_recipe_bank",
} as const;

export const PRODUCT_TYPES = {
  VELGVRAK: "Velg&Vrak",
  ADDON: "Add-on-product",
  MEALBOX: "Mealbox",
  STANDALONE: "Standalone-groceries",
  ALL: "All",
  LOYALTY: "LoyaltyProducts",
  PRODUCTGROUP: "product_group",
  FINANCIAL: "Financial",
  GIFTCARD: "GiftCard",
  BUNDLE: "Bundling",
  NONFOOD: "Non-Food",
  FOODDISH: "Food-dishes",
  ADMIN: "Administrative",
  LIMITED_QUANTITY: "Limited-quantity-groceries",
  MEALBOXES: "Mealboxes",
} as const;
