import * as React from "react";
import { SVGProps } from "react";
const Dinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6396 6.33511C11.4858 6.10317 11.4006 5.33587 11.3932 5.06168C11.3857 4.7875 11.4563 4.51649 11.5973 4.2773C11.7384 4.03811 11.9447 3.83961 12.1945 3.70279C12.4443 3.56597 12.7283 3.49591 13.0165 3.5C13.3047 3.50409 13.5864 3.58219 13.8318 3.72605C14.0772 3.8699 14.2772 4.07416 14.4107 4.31726C14.5442 4.56035 14.6062 4.83324 14.5902 5.10709C14.5741 5.38094 14.4807 6.14571 14.3196 6.37318M2.59961 16.9548V16.1935C2.59961 13.5688 3.69532 11.0516 5.6457 9.19566C7.59608 7.33971 10.2414 6.29705 12.9996 6.29705C15.7579 6.29705 18.4031 7.33971 20.3535 9.19566C22.3039 11.0516 23.3996 13.5688 23.3996 16.1935V16.9548H2.59961Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2 16.9548L23.4 18.858C23.2971 19.1864 23.0861 19.4746 22.798 19.6802C22.51 19.8858 22.1601 19.9979 21.8 19.9999H4.2C3.52 19.9999 2.92 19.5051 2.6 18.858L1.8 16.9548M1 16.9548H25H1Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Dinner;
