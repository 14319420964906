import { useLoyalty } from "@chef/feature-flags";
import { CalendarQuery } from "@chef/state-management";
import {
  getBasketForCalendarWeek,
  getLoyaltyFee,
} from "@chef/state-management/helpers";
import { DeliveryFee } from "../shared/DeliveryFee";

interface CalendarWeekDeliveryProps {
  calendarWeek: CalendarQuery["calendar"][number];
}

export const CalendarWeekDeliveryFee = ({
  calendarWeek,
}: CalendarWeekDeliveryProps) => {
  const { data: loyaltyData } = useLoyalty();

  const basketFromCalendar = getBasketForCalendarWeek({ calendarWeek });
  const feeFromCalendar =
    basketFromCalendar?.shippingDetails.plannedDeliveryInformation.customerFee;
  const customerFee = feeFromCalendar || 0;

  if (!loyaltyData) {
    return null;
  }

  const finalFee = getLoyaltyFee({
    loyaltyLevel: loyaltyData.currentLevel,
    customerFee,
  });

  return (
    <DeliveryFee
      customerFee={finalFee}
      hasFreeShipping={loyaltyData.currentLevel === 4}
    />
  );
};
