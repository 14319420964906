import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { deliveryApi, showNotification } from "../features";

const intl = (
  {
    no: {
      SOMETHING_UNEXPECTED_WENT_WRONG:
        "Noe uventet gikk galt. Kontakt support om problemet vedvarer",
    },

    se: {
      SOMETHING_UNEXPECTED_WENT_WRONG:
        "Något gick oväntat fel. Kontakta kundsupport om problemet kvarstår",
    },

    dk: {
      SOMETHING_UNEXPECTED_WENT_WRONG:
        "Noget gik uventet galt. Kontakt kundesupport, hvis problemet fortsætter",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    matcher: isAnyOf(
      deliveryApi.endpoints.rateDelivery.matchFulfilled,
      deliveryApi.endpoints.rateDelivery.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showNotification({
          type: "error",
          message: intl.SOMETHING_UNEXPECTED_WENT_WRONG,
          autoClose: false,
        });
      }
    },
  });
