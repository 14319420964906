export const createDefaultOgImage = ({
  urlOrDomain,
}: {
  urlOrDomain: string;
}) => {
  const isUrl =
    urlOrDomain.startsWith("http://") || urlOrDomain.startsWith("https://");

  if (isUrl) {
    return new URL("/images/og-image.jpg", urlOrDomain);
  } else {
    return new URL("/images/og-image.jpg", `https://www.${urlOrDomain}`);
  }
};
