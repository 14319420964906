import Link from "next/link";
import clsx from "clsx";

import { Card } from "@chef/components";

interface FlexWrapperProps {
  title?: React.ReactNode;
  back: ActionProps;
  close: ActionProps;
  background?: "default" | "white" | "grey";
  children: React.ReactNode;
  className?: string;
}

type ActionProps = {
  type: "hidden" | "link" | "button";
  href?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const Action = ({ type, href, onClick, children }: ActionProps) => {
  if (type === "hidden") {
    return <div aria-hidden="true" className="w-10 h-10" />;
  }

  if (type === "button") {
    if (!onClick) {
      throw new Error("Missing onClick");
    }

    return (
      <button
        onClick={onClick}
        className="flex items-center justify-center w-10 h-10 rounded bg-grey-2"
      >
        {children}
      </button>
    );
  }

  if (type === "link") {
    if (!href) {
      throw new Error("Missing href");
    }

    return (
      <Link
        href={href}
        className="flex items-center justify-center w-10 h-10 rounded bg-grey-2"
      >
        {children}
      </Link>
    );
  }

  return null;
};

export const FlexWrapper = ({
  title,
  close,
  back,
  background = "default",
  className,
  children,
}: FlexWrapperProps) => {
  const shouldHideHeader =
    !title && back.type === "hidden" && close.type === "hidden";
  return (
    <Card
      noPadding
      transparent={background === "default"}
      className={clsx(
        "flex flex-col col-span-full md:col-span-6 md:col-start-2 xl:col-start-4",
        {
          "bg-transparent md:bg-white": background === "default",
          "bg-grey-3": background === "grey",
        },
        className,
      )}
    >
      {!shouldHideHeader && (
        <header className="flex items-center justify-between p-4">
          <Action {...back} />

          <span>{title}</span>

          <Action {...close} />
        </header>
      )}

      <div className="grow">{children}</div>
    </Card>
  );
};
