import { api, tags } from "../../graphql/api";
import { IUpdatePreferenceArgs } from "./types";

const preferencesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updatePreferences: builder.mutation<null, IUpdatePreferenceArgs[]>({
      query: (args) => ({
        url: "/billing/updatePreferences",
        method: "POST",
        body: args,
      }),

      invalidatesTags: [tags.billing],
    }),
  }),
});

export const { useUpdatePreferencesMutation } = preferencesApi;

export * from "./types";
