import { useDispatch, useSelector } from "react-redux";

import { isPickAndMixProduct } from "@chef/helpers";
import { isEmptyArray, isNotEmptyArray } from "@chef/utils/array";

import {
  IDeviationProduct,
  resetDeviations,
  selectDeviationProducts,
  setResumeOngoingDeviationModal,
} from "../features";
import { compareBasketsProducts } from "./basket";

export const useNavigateWithDeviationCheck = ({
  week = 0,
  year = 0,
  calendarBasketProducts = [],
}: {
  week: number;
  year: number;
  calendarBasketProducts: IDeviationProduct[];
}) => {
  const dispatch = useDispatch();

  const deviationProducts = useSelector(
    selectDeviationProducts({ week, year }),
  );

  const pickAndMixProducts =
    deviationProducts?.filter(isPickAndMixProduct) || [];

  const isDeviationBasketMatchingCalendarOrder =
    deviationProducts && isNotEmptyArray(pickAndMixProducts)
      ? compareBasketsProducts(calendarBasketProducts, deviationProducts)
      : true;

  const navigateWithDeviationCheck = async (destinationUrl = "") => {
    if (deviationProducts && !isDeviationBasketMatchingCalendarOrder) {
      dispatch(
        setResumeOngoingDeviationModal({
          open: true,
          week,
          year,
          deviationProducts,
          calendarBasketProducts,
          destinationUrl,
        }),
      );
      return true;
    }

    if (isEmptyArray(pickAndMixProducts)) {
      dispatch(resetDeviations());
    }

    return false;
  };

  return navigateWithDeviationCheck;
};
