import React from "react";
import clsx from "clsx";

import { Steps } from "../components";
import HeaderBase from "../internals/HeaderBase";

import type { Language, PropsOf } from "../../types";

const intl = {
  no: {
    CLOSE: "Lukk",
    RECEIPT_NOTIFICATION: "Se din bestilling",
  },
  se: {
    CLOSE: "Stäng",
    RECEIPT_NOTIFICATION: "Se din beställning",
  },
  dk: {
    CLOSE: "Luk",
    RECEIPT_NOTIFICATION: "Se din ordre",
  },
};

interface OwnHeaderProgressProps {
  onRightContentClick?: () => void;
  onLeftContentClick?: () => void;
  Logo?: () => JSX.Element;
  steps: Parameters<typeof Steps>[0]["steps"];
  currentStep: number;
  contentLeft?: {
    icon?: JSX.Element;
    text?: string;
  };
  contentRight?: {
    icon?: JSX.Element;
    text?: string;
  };
  hideLeft?: boolean;
  hideRight?: boolean;
  loggedIn?: boolean;
  language: Language;
}

type HeaderProgressProps = OwnHeaderProgressProps &
  Omit<PropsOf<typeof HeaderBase>, "children">;

const HeaderProgress = ({
  onRightContentClick,
  onLeftContentClick,
  Logo,
  steps = [],
  currentStep = 1,
  title = "",
  contentLeft = {},
  contentRight = {},
  hideLeft,
  hideRight,
  loggedIn,
  className,
  language = "no",
  ...props
}: HeaderProgressProps) => {
  const { CLOSE, RECEIPT_NOTIFICATION } = intl[language];
  return (
    <HeaderBase
      className={clsx(loggedIn ? "h-15" : "h-24 lg:h-15", className)}
      {...props}
    >
      {!hideLeft && (
        <div className="flex items-center">
          {!loggedIn && (
            <div className="hidden lg:block">{Logo && <Logo />}</div>
          )}
          {loggedIn && (
            <button
              className="flex items-center"
              onClick={onLeftContentClick}
              aria-label={CLOSE}
            >
              <span className="mr-3.5" aria-hidden="true">
                {contentLeft.icon}
              </span>
              <span className="hidden text-sm lg:block">
                {contentLeft.text}
              </span>
            </button>
          )}
        </div>
      )}

      <div className="flex justify-center flex-grow">
        {title && loggedIn && (
          <h1 className="block lg:hidden !text-base">{title}</h1>
        )}

        <Steps
          steps={steps}
          className="hidden lg:flex"
          currentStep={currentStep}
        />

        {!loggedIn && (
          <div className="flex flex-col items-center justify-between space-y-4 lg:hidden">
            {Logo && <Logo />}

            <Steps steps={steps} currentStep={currentStep} />
          </div>
        )}
      </div>

      {hideRight && <div></div>}

      {!hideRight && (
        <button
          className="flex items-center justify-center w-10 h-10 align-center lg:h-auto lg:w-auto "
          onClick={onRightContentClick}
          aria-label={RECEIPT_NOTIFICATION}
        >
          <span className="hidden text-sm lg:mr-1 lg:block">
            {contentRight.text}
          </span>
          <span>{contentRight.icon}</span>
        </button>
      )}
    </HeaderBase>
  );
};

export default HeaderProgress;
