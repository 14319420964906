import * as React from "react";
import { SVGProps } from "react";
const Delivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5948 14H13.7931M13.7931 14V5H2V14H3.80172M13.7931 14H6.75M13.7931 7.57143H17.6914L19.1328 10.1429H20.4103C20.6724 10.1429 21 10.2071 21 10.5929V14H18.5431"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M5.41953 17C6.46887 17 7.31953 16.1605 7.31953 15.125C7.31953 14.0895 6.46887 13.25 5.41953 13.25C4.37019 13.25 3.51953 14.0895 3.51953 15.125C3.51953 16.1605 4.37019 17 5.41953 17Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M16.8199 17C17.8693 17 18.7199 16.1605 18.7199 15.125C18.7199 14.0895 17.8693 13.25 16.8199 13.25C15.7706 13.25 14.9199 14.0895 14.9199 15.125C14.9199 16.1605 15.7706 17 16.8199 17Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
export default Delivery;
