import clsx from "clsx";
import { MotionProps, motion } from "framer-motion";
import { PropsOf } from "../../../types";

const Path = (props: PropsOf<typeof motion.path>) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    strokeLinecap="round"
    {...props}
  />
);

type InheritedProps = React.HTMLAttributes<HTMLButtonElement> & MotionProps;

interface BurgerButtonProps extends InheritedProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  className?: string;
  title: string;
}

export const BurgerButton = ({
  onClick,
  children,
  className,
  title,
  ...props
}: BurgerButtonProps) => {
  return (
    <motion.button
      title={title}
      onClick={onClick}
      className={clsx(
        "flex items-center h-10 gap-4 min-w-[40px] justify-center",
        className,
      )}
      id="close-burger-button"
      {...props}
    >
      {children}
      <svg width="23" height="23" viewBox="0 0 23 23" className="mt-1 ml-0.5">
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
        />
      </svg>
    </motion.button>
  );
};
