import { useEffect, useState } from "react";

/**
 *
 * @param targetKeyCode String of key code to listen to. Can be a combination of keys separated by a "+" sign. Modifier keys are documented here https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#modifier_keys
 * @param initialValue Initial value of the toggle. Defaults to false.
 * @returns A tuple containing the current state of the key press and a function to override the state.
 */
export const useKeyToggle = (targetKeyCode: string, initialValue?: boolean) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(initialValue || false);

  const downHandler = (e: KeyboardEvent) => {
    if (!e.target) {
      return;
    }

    // If target is a text field, don't do anything
    const target = e.target as HTMLElement;

    if (/^(?:input|textarea)$/i.test(target.tagName)) {
      return;
    }

    if (targetKeyCode.includes("+")) {
      const keys = targetKeyCode.split("+");

      for (const [i, key] of keys.entries()) {
        if (i === keys.length - 1) {
          if (e.code === key) {
            setKeyPressed((state) => !state);
            break;
          }
        }

        if (!e.getModifierState(key)) {
          break;
        }
      }
    }

    if (e.code === targetKeyCode) {
      setKeyPressed((state) => !state);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return [keyPressed, setKeyPressed] as const;
};
