export const convertToE164Format = <T extends number | string>(
  number: T,
  locale: "no" | "se" | "dk",
) => {
  let _number: string;

  if (typeof number === "number") {
    _number = number.toString();
  } else {
    _number = number;
  }

  // Sanitize number
  _number = _number.replace(/\s/g, "");

  // If number is swedish and starts with 0, remove it
  if (locale === "se" && _number.startsWith("0")) {
    _number = _number.slice(1);
  }

  if (_number.startsWith("+")) {
    return _number;
  }

  switch (locale) {
    case "no":
      return `+47${_number}`;
    case "se":
      return `+46${_number}`;
    case "dk":
      return `+45${_number}`;
  }
};

export const removeCountryCode = (
  number: string,
  locale: "no" | "se" | "dk",
) => {
  if (locale === "se") {
    return number.replace(/^\+\d{2} /, "0");
  }

  return number.replace(/^\+\d{2}/, "");
};
