import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      YOUR_ORDER: "Bestillingen",
      GIVES: "gir",
      GAVE: "ga",
      YOU: "deg",
      POINTS: "poeng",
    },
    se: {
      YOUR_ORDER: "Beställningen",
      GIVES: "ger",
      GAVE: "gav",
      YOU: "dig",
      POINTS: "poäng",
    },
    dk: {
      YOUR_ORDER: "Bestillingen",
      GIVES: "giver",
      GAVE: "gav",
      YOU: "dig",
      POINTS: "point",
    },
  } as const
)[language];
