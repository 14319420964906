import clsx from "clsx";

import { Card } from "../components";

interface StandaloneCardSimpleProps {
  quantity?: number;
  name?: string;
  amount?: number;
  hideAmount?: boolean;
  Image?:
    | ((
        props: React.HTMLAttributes<HTMLImageElement>,
      ) => React.ReactElement | null)
    | React.MemoExoticComponent<() => JSX.Element>;
  className?: string;
  id?: string;
}

const StandaloneCardSimple = ({
  quantity = 0,
  name = "",
  amount = 0,
  hideAmount = false,
  Image = () => null,
  className = "",
  id,
}: StandaloneCardSimpleProps) => {
  return (
    <Card noPadding className={clsx("flex", className)} id={id}>
      <div className="relative w-20 h-24 shrink-0">
        <Image />
      </div>
      <div className={clsx("flex flex-col justify-center p-2")}>
        <div>
          {quantity} x {name}
        </div>
        {!hideAmount && <div>{amount} g</div>}
      </div>
    </Card>
  );
};

export default StandaloneCardSimple;
