import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { ChevronLeft, ChevronRight } from "@chef/icons/small";

import { Language } from "../../types";

interface ScrollableWrapperProps {
  children: React.ReactNode;
  showGradients?: boolean;
  leftClassName?: string;
  rightClassName?: string;
  className?: string;
  language: Language;
}

const ScrollableWrapper: React.FC<ScrollableWrapperProps> = ({
  children,
  showGradients = true,
  leftClassName = "bg-gradient-to-r from-white via-white",
  rightClassName = "bg-gradient-to-l from-white via-white",
  className,
  language = "no",
}) => {
  const [showControls, setShowControls] = useState(false);
  const [stepLeftDisabled, setStepLeftDisabled] = useState(true);
  const [stepRightDisabled, setStepRightDisabled] = useState(false);

  const intl = {
    no: { LEFT: "venstre", RIGHT: "høyre" },
    se: { LEFT: "vänster", RIGHT: "höger" },
    dk: { LEFT: "venstre", RIGHT: "højre" },
  };

  const { LEFT, RIGHT } = intl[language];
  const wrapper = useRef<HTMLDivElement | null>(null);

  const handleResize = () => {
    if (!wrapper.current) {
      return;
    }
    setShowControls(wrapper.current.scrollWidth > wrapper.current.clientWidth);
  };

  useEffect(() => {
    if (!wrapper.current) {
      return;
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    wrapper.current.addEventListener("wheel", (evt) => {
      if (!wrapper.current) {
        return;
      }

      evt.preventDefault();
      wrapper.current.scrollLeft += evt.deltaX;
      wrapper.current.scrollLeft += evt.deltaY;
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(handleResize, [children]);

  const handleStepLeft = () => {
    if (!wrapper.current) {
      return;
    }
    wrapper.current.scrollTo({
      left: wrapper.current.scrollLeft - wrapper.current.clientWidth / 3,
      behavior: "smooth",
    });
  };

  const handleStepRight = () => {
    if (!wrapper.current) {
      return;
    }
    wrapper.current.scrollTo({
      left: wrapper.current.scrollLeft + wrapper.current.clientWidth / 3,
      behavior: "smooth",
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    setStepLeftDisabled(target.scrollLeft === 0);
    setStepRightDisabled(
      target.scrollLeft + target.offsetWidth >= target.scrollWidth - 1,
    );
  };

  return (
    <div className="relative">
      <div
        className={clsx(
          "hidden md:flex absolute top-0 bottom-0 left-0 z-5 items-center w-24 pointer-events-none",
          showGradients && leftClassName,
          (!showControls || stepLeftDisabled) && "!hidden",
        )}
      >
        <button
          className="w-12 h-full pointer-events-auto"
          aria-label={LEFT}
          onClick={handleStepLeft}
          title={LEFT}
        >
          <ChevronLeft className="h-full" />
        </button>
      </div>

      <div
        className={clsx(
          "relative flex gap-2 overflow-x-scroll scrollbar-hidden",
          className,
        )}
        ref={wrapper}
        onScroll={handleScroll}
      >
        {children}
      </div>

      <div
        className={clsx(
          "hidden absolute top-0 bottom-0 right-0 md:flex items-center justify-end w-24 pointer-events-none z-1",
          showGradients && rightClassName,
          (!showControls || stepRightDisabled) && "!hidden",
        )}
      >
        <button
          title={RIGHT}
          className="w-12 h-full pointer-events-auto "
          onClick={handleStepRight}
          aria-label={RIGHT}
        >
          <ChevronRight className="h-full" />
        </button>
      </div>
    </div>
  );
};

export default ScrollableWrapper;
