import { OrderQuery } from "@chef/state-management";
import { FREE_SHIPPING_DISCOUNT_ID } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";

import { DeliveryFee } from "../shared/DeliveryFee";

interface OrderDeliveryFeeProps {
  order: OrderQuery["order"];
}

export const OrderDeliveryFee = ({ order }: OrderDeliveryFeeProps) => {
  const transportFeeProduct = order.orderLines.find(
    (ol) => ol.type === "Transport",
  );

  const hasFreeShipping = order.orderLines.some((ol) =>
    isEqualStrings(ol.discountId, FREE_SHIPPING_DISCOUNT_ID),
  );

  return (
    <DeliveryFee
      customerFee={transportFeeProduct?.fullPrice || 0}
      hasFreeShipping={hasFreeShipping}
    />
  );
};
