import clsx from "clsx";

import {
  BillingQuery,
  PreferencesQuery,
  usePreferencesQuery,
} from "@chef/state-management";
import { isNotEmptyArray } from "@chef/utils/array";
import { isFixedPreference } from "@chef/state-management/helpers";

import { PreferencesListBox } from "./PreferencesListBox";

import { intl } from "./PreferencesDetails.Intl";

type Preference =
  | BillingQuery["billing"]["preferences"][number]
  | PreferencesQuery["preferences"][number];

interface PreferencesDetailsProps {
  conceptPreferences: Preference[];
  tastePreferences?: Preference[];
  className?: string;
}

interface InfoBoxForNoPreferencesProps {
  children: React.ReactNode;
  noPreferencesAllowed?: boolean;
}

const InfoBoxForNoPreferences = ({
  children,
  noPreferencesAllowed = false,
}: InfoBoxForNoPreferencesProps) => {
  return (
    <div
      className={clsx(
        noPreferencesAllowed
          ? "bg-informationBG border-information"
          : "bg-highlightBG border-highlight",
        "p-4 text-sm border-t-2 rounded-sm ",
      )}
    >
      {children}
    </div>
  );
};

export const PreferencesDetails = ({
  conceptPreferences,
  tastePreferences,
  className,
}: PreferencesDetailsProps) => {
  const hasConceptPreferences = isNotEmptyArray(conceptPreferences);
  const hasTastePreferences = isNotEmptyArray(tastePreferences);

  const { data: preferencesQuery } = usePreferencesQuery();

  const fixedPreference = conceptPreferences.find(isFixedPreference);

  const fixedPreferenceDescription = preferencesQuery?.preferences.find(
    (p) => p.preferenceId === fixedPreference?.preferenceId,
  )?.description;

  if (fixedPreference) {
    return (
      <InfoBoxForNoPreferences noPreferencesAllowed>
        {fixedPreferenceDescription}
      </InfoBoxForNoPreferences>
    );
  }

  if (!hasConceptPreferences && !hasTastePreferences) {
    return (
      <InfoBoxForNoPreferences>
        <strong className="text-xs">{intl.NO_PREFERENCES_SELECTED}</strong>
        <p className="text-sm">{intl.CHOOSE_WHAT_YOU_LIKE_AND_DISLIKE}</p>
      </InfoBoxForNoPreferences>
    );
  }

  return (
    <div className={clsx(className, "grid grid-cols-2 gap-3 text-left")}>
      {hasConceptPreferences && (
        <PreferencesListBox
          preferences={conceptPreferences}
          className={clsx(hasTastePreferences ? "col-span-1" : "col-span-full")}
        />
      )}

      {hasTastePreferences && (
        <PreferencesListBox
          preferences={tastePreferences}
          type="taste"
          className={clsx(
            hasConceptPreferences ? "col-span-1" : "col-span-full",
          )}
        />
      )}
    </div>
  );
};
