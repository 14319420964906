import { BRAND_NAME } from "@chef/constants";
import { useTrack } from "@chef/feature-tracking";
import { ExternalLink } from "@chef/icons/small";
import { OrdersQuery } from "@chef/state-management";
import { intl } from "./OrderTrackingLink.Intl";

interface OrderTrackingLinkProps {
  order: OrdersQuery["orders"][number];
}

export const OrderTrackingLink = ({ order }: OrderTrackingLinkProps) => {
  const track = useTrack();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    if (!order.realtimeEstimate?.trackingLink) {
      return;
    }

    track("linkClicked", {
      affiliation: "Frontend process",
      link_destination: order.realtimeEstimate.trackingLink,
      link_placement: "Order tracking link",
      link_source: "code",
      link_text: intl.TRACK_SHIPMENT,
      link_action: "click",
    });
  };

  if (BRAND_NAME === "RN") {
    return null;
  }

  if (!order.realtimeEstimate?.trackingLink) {
    return null;
  }

  return (
    <p className="mt-2 text-sm">
      <a
        href={order.realtimeEstimate.trackingLink}
        onClick={handleClick}
        className="pb-1 border-b-2 text-primary border-primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>{intl.TRACK_SHIPMENT}</strong>{" "}
        <ExternalLink className="w-5 h-5 mb-2" />
      </a>
    </p>
  );
};
