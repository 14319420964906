import { Tag } from "@chef/components";
import { generateTags } from "@chef/state-management/helpers";
import { Time, Calendar } from "@chef/icons/small";
import {
  OrderQuery,
  PickAndMixQuery,
  RecipeAndStepsQuery,
  RecipesByIngredientCategoriesQuery,
  RecipesByIdsQuery,
  RecipesByProductIdListQuery,
} from "@chef/state-management";

import { slugify } from "@chef/helpers";

interface RecipeTagsProps {
  recipe:
    | RecipeAndStepsQuery["recipeAndSteps"]
    | RecipesByProductIdListQuery["recipesByProductIdList"][number]["products"][number]["recipes"][number]
    | PickAndMixQuery["pickAndMix"][number]["recipes"][number]
    | RecipesByIngredientCategoriesQuery["getRecipesByIngredientCategories"]["recipes"][number]
    | RecipesByIdsQuery["recipesByIds"][number]
    | OrderQuery["order"]["orderLines"][number]["recipes"][number];
  showCampaign?: boolean;
  showCookingTime?: boolean;
}

export const RecipeTags = ({
  recipe,
  showCampaign = false,
  showCookingTime = true,
}: RecipeTagsProps) => {
  const { cookingTime, marketing, shelfLife, specialFood, campaign } =
    generateTags(recipe);

  if (!cookingTime && !marketing && !shelfLife && !specialFood && !campaign) {
    return null;
  }

  return (
    <>
      {showCookingTime && (
        <Tag Icon={Time} small>
          {cookingTime.text}
        </Tag>
      )}
      <Tag secondary small>
        {marketing.text}
      </Tag>
      {specialFood.map(({ id, text }) => (
        <Tag key={`${id}-${slugify(text)}`} small>
          {text}
        </Tag>
      ))}
      {shelfLife && (
        <Tag Icon={Calendar} small>
          {shelfLife.text}
        </Tag>
      )}
      {showCampaign && (
        <Tag secondary small>
          {campaign.text}
        </Tag>
      )}
    </>
  );
};
