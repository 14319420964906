import { jsonLdScriptProps } from "react-schemaorg";
import { Recipe as RecipeSchema, WithContext } from "schema-dts";
import Head from "next/head";
import { useRouter } from "next/router";

import { RecipeAndStepsQuery } from "@chef/state-management";
import { findFeaturedPortraitImage } from "@chef/utils/helpers/recipes";
import { BRAND_DOMAIN, TAXONOMY_TYPES } from "@chef/constants";

interface RichSnippetProps {
  recipe: RecipeAndStepsQuery["recipeAndSteps"];
}

const stripHTMLTags = (str: string) => str.replace(/(<([^>]+)>)/gi, "");

export const RichSnippet = ({ recipe }: RichSnippetProps) => {
  const router = useRouter();

  const cookPrepTime = +recipe.cookingTimeMax / 2;
  const cookTimeTotal = +recipe.cookingTimeMax;

  const keywords = recipe.taxonomies
    .filter((t) => t.type === TAXONOMY_TYPES.CATEGORY)
    .map((t) => t.name)
    .join(", ");

  const { ingredientSections, stepSections, nutritionFacts } =
    recipe.instructions.portions.find(({ size }) => size === "4") || {};

  const ingredients = ingredientSections?.flatMap(({ ingredients = [] }) =>
    ingredients.map((ingredient) => {
      if (!ingredient.amount) {
        return ingredient.name;
      }

      return `${ingredient.amount} ${ingredient.ingredientAmountType} ${ingredient.name}`;
    }),
  );

  const instructions = stepSections?.flatMap(
    ({ sectionTitle = "", steps = [] }) =>
      steps
        .filter((step) => step.step.length > 0)
        .map((step) => ({
          "@type": "HowToStep" as const,
          ...(sectionTitle && { name: sectionTitle }),
          text: stripHTMLTags(step.step),
        })),
  );

  const image = findFeaturedPortraitImage(recipe);

  const payload: WithContext<RecipeSchema> = {
    "@context": "https://schema.org",
    "@type": "Recipe",
    name: recipe.recipeName,
    url: `https://${BRAND_DOMAIN}${router.asPath}`,
    image: image?.url,
    thumbnailUrl: image?.url,
    description: recipe.recipeDescription,
    prepTime: `PT${cookPrepTime}M`,
    cookTime: `PT${cookPrepTime}M`,
    totalTime: `PT${cookTimeTotal}M`,
    recipeIngredient: ingredients,
    recipeInstructions: instructions,
    recipeYield: "4",
    recipeCategory: "Dinner",
    nutrition: {
      "@type": "NutritionInformation",
      calories: `${nutritionFacts?.kcalPerPortion || 0} kcal`,
    },
    keywords,
  };

  if (recipe.averageRating !== null) {
    payload.aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: recipe.averageRating,
      reviewCount: recipe.numberOfRatings,
    };
  }

  const props = jsonLdScriptProps<RecipeSchema>(payload);

  return (
    <Head>
      <script {...props} />
    </Head>
  );
};
