interface ExperimentWrapperProps<T> {
  isExperimentEnabled: boolean | null;
  aContent: T;
  bContent: T;
  children: (content: T) => JSX.Element;
}

const ExperimentWrapper = <T,>({
  isExperimentEnabled,
  aContent,
  bContent,
  children,
}: ExperimentWrapperProps<T>): JSX.Element | null => {
  return children(isExperimentEnabled ? bContent : aContent);
};

export default ExperimentWrapper;
