import { isFuture, isPast } from "date-fns";

import { isNotEmptyArray } from "@chef/utils/array";

import {
  BillingQuery,
  UserCreditsQuery,
  useBillingQuery,
  useUserCreditsQuery,
} from "../graphql/generated";

type Discount = BillingQuery["billing"]["discounts"][0];
type GiftCard = BillingQuery["billing"]["giftCards"][0];
type Credit = UserCreditsQuery["userCredits"][0];

export const isValid = (remainingAmount: number, expirationDate: string) => {
  return (
    remainingAmount !== 0 &&
    (expirationDate === null || isFuture(new Date(expirationDate)))
  );
};

export const isExpired = (remainingAmount: number, expirationDate: string) => {
  return remainingAmount === 0 || isPast(new Date(expirationDate));
};

export const isDiscountActive = (discount: Discount) => {
  const { usesLeft, validEndDate } = discount;
  return {
    active: isValid(usesLeft, validEndDate),
    expired: isExpired(usesLeft, validEndDate),
  };
};

export const isGiftCardActive = (giftcard: GiftCard) => {
  const { expirationDate, remainingAmount } = giftcard;

  return {
    active: isValid(+remainingAmount, expirationDate),
    expired: isExpired(+remainingAmount, expirationDate),
  };
};

export const isCreditActive = (credit: Credit) => {
  const { expirationDate, remainingAmount } = credit;
  return {
    active: isValid(+remainingAmount, expirationDate),
    expired: isExpired(+remainingAmount, expirationDate),
  };
};

export const useDiscountData = () => {
  const { data: billingQuery } = useBillingQuery();
  const { discounts, giftCards } = billingQuery?.billing || {};

  const { data: userCreditsQuery } = useUserCreditsQuery();

  const activeDiscounts = discounts
    ?.filter((discount) => isDiscountActive(discount).active)
    .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));

  const temporaryDiscounts = activeDiscounts?.filter(
    (discount) => discount.usesLeft > 0,
  );

  const permanentDiscounts = activeDiscounts?.filter(
    (discount) => discount.usesLeft < 0,
  );

  const usedDiscounts = discounts?.filter(
    (discount) => isDiscountActive(discount).expired && discount.usesLeft <= 0,
  );

  const expiredDiscounts = discounts?.filter(
    (discount) => isDiscountActive(discount).expired && discount.usesLeft > 0,
  );

  const activeGiftCards = giftCards
    ?.filter((giftcard) => {
      return isGiftCardActive(giftcard).active;
    })
    .sort((a, b) => +new Date(b.redeemedAt) - +new Date(a.redeemedAt));

  const expiredGiftCards = giftCards?.filter((giftcard) => {
    return isGiftCardActive(giftcard).expired;
  });

  const credits = userCreditsQuery?.userCredits;

  const activeCredits = credits?.filter((credit) => {
    return isCreditActive(credit).active;
  });

  const expiredCredits = credits?.filter((credit) => {
    return isCreditActive(credit).expired;
  });

  const [nextTemporaryDiscount, ...otherTemporaryDiscounts] =
    temporaryDiscounts || [];

  const [nextPermanentDiscount, ...otherPermanentDiscounts] =
    permanentDiscounts || [];

  const shouldRenderExpiredCoupons = !!(
    isNotEmptyArray(expiredDiscounts) ||
    isNotEmptyArray(expiredGiftCards) ||
    isNotEmptyArray(usedDiscounts) ||
    isNotEmptyArray(expiredCredits)
  );

  return {
    discounts: {
      activeDiscounts: {
        temporaryDiscounts: { nextTemporaryDiscount, otherTemporaryDiscounts },
        permanentDiscounts: { nextPermanentDiscount, otherPermanentDiscounts },
      },
      expiredDiscounts,
      usedDiscounts,
    },

    giftCards: { activeGiftCards, expiredGiftCards },

    credits: { activeCredits, expiredCredits },

    shouldRenderExpiredCoupons,
  };
};
