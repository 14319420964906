import * as React from "react";
import { SVGProps } from "react";
const TimeSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_33415_329238)">
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#F4F4F1"
      />
      <path
        d="M103.26 43.2407V55.6481H90.7575M75.7543 109C69.987 108.999 64.3161 107.533 59.2824 104.739C54.2486 101.946 50.0199 97.9199 46.9996 93.0442C43.9792 88.1686 42.268 82.6064 42.029 76.888C41.79 71.1697 43.0313 65.486 45.6345 60.3789C48.2377 55.2718 52.1159 50.9118 56.8994 47.7146C61.6828 44.5174 67.2119 42.5897 72.9592 42.1153C78.7066 41.6409 84.4806 42.6357 89.7306 45.0047C94.9805 47.3737 99.5313 51.038 102.949 55.6481M91.989 104.878C89.247 106.374 86.3075 107.482 83.2559 108.17M102.949 95.3518C101.254 97.6348 99.2746 99.6962 97.0589 101.487M108 85.442C107.473 87.1258 106.813 88.7655 106.025 90.3454"
        stroke="currentColor"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.7537 79.2218C77.8253 79.2218 79.5046 77.5553 79.5046 75.4996C79.5046 73.4438 77.8253 71.7773 75.7537 71.7773C73.6822 71.7773 72.0029 73.4438 72.0029 75.4996C72.0029 77.5553 73.6822 79.2218 75.7537 79.2218Z"
        stroke="currentColor"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.7577 78.1795L91.875 89.0625M75 46.875V70.7812"
        stroke="currentColor"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33415_329238">
        <rect width={150} height={150} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TimeSwap;
