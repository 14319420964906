import * as React from "react";
import { SVGProps } from "react";
const Setting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 11C21 9.99545 20.1864 9.18182 19.1818 9.18182H18.0345C17.8627 8.51636 17.6009 7.88909 17.26 7.31091L18.0709 6.5C18.7809 5.79 18.7809 4.63909 18.0709 3.92909C17.3609 3.21909 16.21 3.21909 15.5 3.92909L14.6891 4.74C14.1109 4.39909 13.4836 4.13727 12.8182 3.96545V2.81818C12.8182 1.81455 12.0045 1 11 1C9.99545 1 9.18182 1.81364 9.18182 2.81818V3.96545C8.51636 4.13727 7.88909 4.39909 7.31091 4.74L6.5 3.92909C5.79 3.21909 4.63909 3.21909 3.92909 3.92909C3.21909 4.63909 3.21909 5.79 3.92909 6.5L4.74 7.31091C4.39909 7.88909 4.13727 8.51636 3.96545 9.18182H2.81818C1.81455 9.18182 1 9.99545 1 11C1 12.0045 1.81364 12.8182 2.81818 12.8182H3.96545C4.13727 13.4836 4.39909 14.1109 4.74 14.6891L3.92909 15.5C3.21909 16.21 3.21909 17.3609 3.92909 18.0709C4.63909 18.7809 5.79 18.7809 6.5 18.0709L7.31091 17.26C7.88818 17.6009 8.51636 17.8627 9.18182 18.0345V19.1818C9.18182 20.1855 9.99545 21 11 21C12.0045 21 12.8182 20.1864 12.8182 19.1818V18.0345C13.4836 17.8627 14.1109 17.6009 14.6891 17.26L15.5 18.0709C16.21 18.7809 17.3609 18.7809 18.0709 18.0709C18.7809 17.3609 18.7809 16.21 18.0709 15.5L17.26 14.6891C17.6009 14.1118 17.8627 13.4836 18.0345 12.8182H19.1818C20.1855 12.8182 21 12.0045 21 11Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9997 13.7272C12.506 13.7272 13.727 12.5062 13.727 11C13.727 9.49375 12.506 8.27271 10.9997 8.27271C9.4935 8.27271 8.27246 9.49375 8.27246 11C8.27246 12.5062 9.4935 13.7272 10.9997 13.7272Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Setting;
