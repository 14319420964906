import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { isEqualStrings } from "@chef/utils/equal";
import { createAndSendEvent } from "@chef/events";
import { invariant } from "@chef/utils/invariant";

import { setRecipeModal } from "../features/modal";
import { getPriceOfProductByVariation } from "../helpers";

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setRecipeModal,
    effect: async (action) => {
      if (
        !action.payload.pickAndMixProduct ||
        !action.payload.misc?.variationId
      ) {
        return;
      }

      const product = action.payload.pickAndMixProduct;
      const variationId = action.payload.misc.variationId;

      const variation = product.product.variations.find((v) =>
        isEqualStrings(v.variationId, variationId),
      );

      invariant(variation, `Could not find variation with id ${variationId}`);

      const productId = product.productId;
      const category = product.product.productTypeName;
      const name = product.product.name;
      const productTypeId = product.product.productTypeId;
      const variant = variation.name;
      const price = getPriceOfProductByVariation({
        variation,
        week: product.week,
        year: product.year,
      });

      createAndSendEvent("Product Clicked", {
        affiliation: "Frontend process",
        category,
        name,
        product_id: productId,
        variant_id: variationId,
        category_id: productTypeId,
        variant,
        quantity: 1,
        price,
      });
    },
  });
