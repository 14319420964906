import * as React from "react";
import { SVGProps } from "react";
const Vipps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 13.4285C5.11111 18.5109 13.4667 25.8914 22 14.7543"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <path
      d="M18.4283 5.57132C18.4283 6.835 17.4392 7.71418 16.2852 7.71418C15.1314 7.71418 14.1426 6.835 14.1426 5.57132C14.1426 4.30734 15.1314 3.42847 16.2852 3.42847C17.4392 3.42847 18.4283 4.30734 18.4283 5.57132Z"
      fill="currentColor"
    />
  </svg>
);
export default Vipps;
