import * as React from "react";
import { SVGProps } from "react";
const VippsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.999999C0 0.447714 0.447715 0 1 0H39C39.5523 0 40 0.447715 40 1V39C40 39.5523 39.5523 40 39 40H0.999999C0.447714 40 0 39.5523 0 39V0.999999Z"
      fill="#F4F4F1"
    />
    <path
      d="M20.5615 25.3478C24.3414 25.3478 26.4868 23.5422 28.53 20.934C29.6538 19.5297 31.084 19.2287 32.1056 20.0312C33.1272 20.8337 33.2294 22.3384 32.1056 23.7428C29.143 27.5547 25.363 29.8618 20.5615 29.8618C15.3513 29.8618 10.7541 27.0531 7.58717 22.1378C6.66772 20.8337 6.87205 19.4294 7.89365 18.7272C8.91525 18.025 10.4477 18.3259 11.3671 19.7303C13.6146 23.0406 16.6794 25.3478 20.5615 25.3478ZM27.6106 13.0094C27.6106 14.815 26.1803 16.0187 24.5458 16.0187C22.9112 16.0187 21.481 14.815 21.481 13.0094C21.481 11.2037 22.9112 10 24.5458 10C26.1803 10 27.6106 11.3041 27.6106 13.0094Z"
      fill="currentColor"
    />
  </svg>
);
export default VippsIcon;
