import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      YOU_HAVE_NO_FAVORITES_THIS_WEEK: "Ingen favoritter denne uken",
      WHEN_SOME_OF_YOUR_FAVORITES_ARE_AVAILABLE:
        "Når noen av dine favorittretter finnes i ukens meny, vil de vises her.",
      SHOW_WHOLE_MENU: "Vis hele menyen",
      YOU_HAVE_NO_FAVORITES: "Du har ingen favoritter",
      CLICK_ON_THE_HEART_ON_THE_RECIPE_TO_ADD:
        "Klikk på hjertet på oppskriften for å legge til retten som en av dine favoritter.",
      SHOW_ALL_MY_RECIPES: "Vis alle mine oppskrifter",
    },
    se: {
      YOU_HAVE_NO_FAVORITES_THIS_WEEK: "Inga favoriter denna vecka",
      WHEN_SOME_OF_YOUR_FAVORITES_ARE_AVAILABLE:
        "När någon av dina favoriträtter finns på veckans meny hittar du dem här.",
      SHOW_WHOLE_MENU: "Visa alla veckans rätter",
      YOU_HAVE_NO_FAVORITES: "Du har inga favoriter",
      CLICK_ON_THE_HEART_ON_THE_RECIPE_TO_ADD:
        "Klicka på hjärtat på receptet för att lägga till rätten som en av dina favoriter.",
      SHOW_ALL_MY_RECIPES: "Visa alla mina recept",
    },
    dk: {
      YOU_HAVE_NO_FAVORITES_THIS_WEEK: "Ingen favoritter denne uge",
      WHEN_SOME_OF_YOUR_FAVORITES_ARE_AVAILABLE:
        "Når nogle af dine favoritretter er på ugemenuen kan du finde dem her.",
      SHOW_WHOLE_MENU: "Vis alle ugens retter",
      YOU_HAVE_NO_FAVORITES: "Du har ingen favoritter",
      CLICK_ON_THE_HEART_ON_THE_RECIPE_TO_ADD:
        "Klik på hjertet på opskriften for at føje retten til dine favoritter.",
      SHOW_ALL_MY_RECIPES: "Vis alle mine opskrifter",
    },
  } as const
)[language];
