import clsx from "clsx";
import { useMeQuery } from "@chef/state-management";
import { CircularLoyaltyProgress } from "@chef/smart-components";
import { Nudge } from "@chef/components";

interface LoyaltyBadgeProps {
  inverted?: boolean;
  showNudge?: boolean;
  className?: string;
}

export const LoyaltyBadge = ({
  inverted,
  showNudge,
  className,
}: LoyaltyBadgeProps) => {
  const { data: isLoggedIn } = useMeQuery();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div
      className={clsx(
        "flex items-center justify-center w-8 h-8 relative",
        className,
      )}
    >
      {showNudge && (
        <Nudge variant="error" className="absolute top-0 right-0 z-50" />
      )}
      <CircularLoyaltyProgress inverted={inverted} />
    </div>
  );
};
