import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      SAVE_CHANGES: "[PH]:SAVE_CHANGES", //no norwegian reward partner for no and se
      SIGN_UP_NOW: "[PH]:SIGN_UP_NOW",
      REWARD_NUMBER_MUST_EIGHT_DIGTIS: "[PH]:REWARD_NUMBER_MUST_EIGHT_DIGTIS",
      INVALID_REWARD_NUMBER: "[PH]:INVALID_REWARD_NUMBER",
      YOUR_CHANGES_HAVE_BEEN_SAVED: "[PH]:YOUR_CHANGES_HAVE_BEEN_SAVED",
      CANNOT_SAVE: "[PH]:CANNOT_SAVE",
    },
    se: {
      SAVE_CHANGES: "[PH]:SAVE_CHANGES", //no norwegian reward partner for no and se
      SIGN_UP_NOW: "[PH]:SIGN_UP_NOW",
      REWARD_NUMBER_MUST_EIGHT_DIGTIS: "[PH]:REWARD_NUMBER_MUST_EIGHT_DIGTIS",
      INVALID_REWARD_NUMBER: "[PH]:INVALID_REWARD_NUMBER",
      YOUR_CHANGES_HAVE_BEEN_SAVED: "[PH]:YOUR_CHANGES_HAVE_BEEN_SAVED",
      CANNOT_SAVE: "[PH]:CANNOT_SAVE",
    },
    dk: {
      SAVE_CHANGES: "Gem ændringer",
      SIGN_UP_NOW: "Tilmeld dig nu",
      REWARD_NUMBER_MUST_EIGHT_DIGTIS: "Reward nummer skal være 8 cifre",
      INVALID_REWARD_NUMBER: "Ugyldigt Reward nummer",
      YOUR_CHANGES_HAVE_BEEN_SAVED: "Dine ændringer er gemt",
      CANNOT_SAVE: "Kunne ikke gemme",
    },
  } as const
)[language];
