import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CHOOSE_HOW_MANY_YOU_WANT_TO_SUBSCRIBE_TO:
        "Velg hvor mange du vil abonnere på",
      WILL_BE_ADDED_TO_EVERY_DELIVERY:
        "vil bli lagt til på hver levering. Du kan endre dette når du vil.",
      PIECES: "stk",
      SAVE_CHANGE: "Lagre endring",
      CANCEL: "Avbryt",
      SUBSCRIBE_TO_GROCERIES: "Abonnere på dagligvarer?",
      SUBSCRIBE_TO_GROCERIES_TOOLTIP_TEXT: "",
    },
    se: {
      CHOOSE_HOW_MANY_YOU_WANT_TO_SUBSCRIBE_TO:
        "Välj hur många du vill prenumerera på",
      WILL_BE_ADDED_TO_EVERY_DELIVERY:
        "kommer att läggas till på varje leverans. Du kan ändra detta när du vill.",
      PIECES: "st",
      SAVE_CHANGE: "Spara ändring",
      CANCEL: "Avbryt",
      SUBSCRIBE_TO_GROCERIES: "Prenumerera på livsmedel?",
      SUBSCRIBE_TO_GROCERIES_TOOLTIP_TEXT: "",
    },
    dk: {
      CHOOSE_HOW_MANY_YOU_WANT_TO_SUBSCRIBE_TO:
        "Vælg, hvor mange du vil abonnere på",
      WILL_BE_ADDED_TO_EVERY_DELIVERY:
        "Dette vil blive tilføjet til hver levering. Du kan ændre dette, når du vil.",
      PIECES: "stk",
      SAVE_CHANGE: "Gem ændring",
      CANCEL: "Annuller",
      SUBSCRIBE_TO_GROCERIES: "Abonner på specialvarer",
      SUBSCRIBE_TO_GROCERIES_TOOLTIP_TEXT: "",
    },
  } as const
)[language];
