export const getHost = () => {
  if (
    process.env.VERCEL_ENV === "preview" &&
    process.env.VERCEL_GIT_COMMIT_REF !== "qa"
  ) {
    // We need to replace all dots with dashes in the app name to make it a valid subdomain
    const app = process.env.NX_APP_NAME.replace(/\./g, "-");
    return `https://${app}-git-${process.env.VERCEL_GIT_COMMIT_REF}-cheffelo.vercel.app`;
  }

  return process.env.NX_HOST;
};

export const getPaymentGatewayOrigin = () => {
  if (
    process.env.VERCEL_ENV === "preview" &&
    process.env.VERCEL_GIT_COMMIT_REF !== "qa"
  ) {
    return `https://pay-cheffelo-com-git-${process.env.VERCEL_GIT_COMMIT_REF}-cheffelo.vercel.app`;
  }

  return process.env.PAYMENT_GATEWAY_ORIGIN;
};
