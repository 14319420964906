import clsx from "clsx";
import { useEffect, useState } from "react";

import { Disclosure, Hr } from "@chef/components";
import { Breakfast, Trash } from "@chef/icons/small";
import { language } from "@chef/constants";

import { Chevron } from "./Chevron";
import { CurrencyWrapper as Currency } from "../../CurrencyWrapper";

interface GroceriesProps {
  groceries: {
    name: string;
    quantity: number;
    price: number;
    onRemove?: () => void;
  }[];
  initiallyExpanded?: boolean;
}

export const Groceries = ({ groceries, initiallyExpanded }: GroceriesProps) => {
  const [expanded, setExpanded] = useState(!!initiallyExpanded);
  useEffect(() => {
    setExpanded(!!initiallyExpanded);
  }, [initiallyExpanded]);

  const { totalPrice, totalGroceryCount } = groceries.reduce(
    (sum, grocery) => ({
      totalPrice: sum.totalPrice + grocery.price,
      totalGroceryCount: sum.totalGroceryCount + grocery.quantity,
    }),
    { totalPrice: 0, totalGroceryCount: 0 },
  );

  if (totalGroceryCount === 0) {
    return (
      <div className="flex flex-col">
        <Hr className="my-4" />

        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <Breakfast className="w-6.5 h-6.5 p-1" />

            <p>
              {totalGroceryCount} {intl.GROCERIES}
            </p>
          </div>

          <Currency className="text-base">{totalPrice}</Currency>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Hr className="my-4" />

      <Disclosure
        open={expanded}
        hideContentTopMargin
        onClick={() => setExpanded(!expanded)}
        HeaderRow={() => (
          <div className="flex items-center justify-between grow">
            <div className="flex items-center">
              <Breakfast className="w-6.5 h-6.5 p-1" />

              <p className="ml-2">
                {totalGroceryCount} {intl.GROCERIES}
              </p>

              <Chevron className={expanded ? "rotate-180" : undefined} />
            </div>

            <Currency>{totalPrice}</Currency>
          </div>
        )}
      >
        <ul className="flex flex-col self-stretch gap-2 pt-2">
          {groceries.map(({ name, price, quantity, onRemove }) => (
            <li key={name} className="flex items-center text-sm">
              {onRemove && (
                <button onClick={onRemove}>
                  <Trash className="w-6.5 h-6.5 p-1 mr-2" />
                </button>
              )}

              <p className={clsx("grow mr-3", !onRemove && "ml-9")}>
                {quantity} × {name}
              </p>

              <Currency>{price}</Currency>
            </li>
          ))}
        </ul>
      </Disclosure>
    </div>
  );
};

const intl = (
  {
    no: {
      GROCERIES: "dagligvarer",
    },

    se: {
      GROCERIES: "livsmedel",
    },

    dk: {
      GROCERIES: "specialvarer",
    },
  } as const
)[language];
