import { Events } from "./events";
import { Event } from "./types";

export const createEvent = <K extends keyof Events>(
  eventName: K,
  payload: Events[K],
): Event => {
  return {
    type: "track",
    eventName,
    payload,
  };
};

export const sendEvent = (event: Event) => {
  if (event.type === "identify") {
    return;
  }

  window.tracking?.track(event.eventName, event.payload);
};

export const createAndSendEvent = <K extends keyof Events>(
  eventName: K,
  payload: Events[K],
) => {
  sendEvent(createEvent(eventName, payload));
};

export * from "./events";
export * from "./types";
