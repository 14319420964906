import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { billingApi, showNotification } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      ADDRESS_UPDATED: "Adressen ble oppdatert",
    },

    se: {
      ADDRESS_UPDATED: "Adressen uppdaterades",
    },

    dk: {
      ADDRESS_UPDATED: "Adressen blev opdateret",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.updateAddress.matchFulfilled,
      billingApi.endpoints.updateAddress.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({ type: "success", message: intl.ADDRESS_UPDATED });
      }
    },
  });
};
