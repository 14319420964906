export const getTierLevel = (levelId: number, currentLevel: number) => {
  const nextLevel = currentLevel + 1;

  return {
    complete: levelId < currentLevel,
    current: levelId === currentLevel,
    next: levelId === nextLevel,
    incomplete: levelId > nextLevel,
    hasTierAchieved: levelId <= currentLevel,
    hasMaxTierAchieved: levelId === 4,
  };
};
