import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Clap } from "@chef/icons/symbol";
import { Card } from "../components";

interface SignupProgressProps extends React.HTMLAttributes<typeof Card> {
  title?: string;
  subTitle?: string;
  animateFrom?: string;
  animateTo?: string;
  id?: string;
}

const SignupProgress = ({
  title = "",
  subTitle = "",
  animateFrom = "0%",
  animateTo = "90%",
  id = "",
}: SignupProgressProps) => {
  return (
    <div className="flex p-4 mb-4" id={id}>
      <div className="flex items-center justify-center flex-shrink-0 w-20 h-20 p-3 rounded-full bg-grey-2">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              type: "spring",
              delay: 0.4,
              bounce: 0.7,
              duration: 1,
            }}
            className="w-10 h-10"
          >
            <Clap className="w-full h-full" />
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="w-full ml-4">
        <strong>{title}</strong>
        <div className="relative w-full h-2 my-2 overflow-hidden rounded-full bg-grey-3">
          <motion.div
            initial={{ width: animateFrom }}
            animate={{ width: animateTo }}
            transition={{
              type: "spring",
              delay: 0.7,
              bounce: 0.3,
              duration: 1.5,
            }}
            className={clsx("bg-primary h-full")}
          />
        </div>
        <div>{subTitle}</div>
      </div>
    </div>
  );
};

export default SignupProgress;
