import * as React from "react";
import { SVGProps } from "react";
const Lactose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 75C0 33.5786 33.5786 0 75 0V0C116.421 0 150 33.5786 150 75V75C150 116.421 116.421 150 75 150V150C33.5786 150 0 116.421 0 75V75Z"
      fill="#F4F4F1"
    />
    <path
      d="M75 100.333V113M105 92.7333V113H45V52.2L60 37H90L105 52.2V62.3333V92.7333ZM75 52.2H105H75Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60 37L75 52.2V69.9333"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105 92.7325L75 100.333V69.9325L105 62.3325V92.7325Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Lactose;
