import Link from "next/link";
import { memo, useContext } from "react";
import { motion } from "framer-motion";

import { ChevronLeft, Close } from "@chef/icons/small";

import { AppShellContext } from "../AppShell";
import { Drawer } from "./Drawer";

type SimpleVariantProps = { title?: string } & (
  | ({ exitLabel: string } & (
      | {
          onClose: () => void;
        }
      | {
          onBack: () => void;
        }
      | {
          closeHref: string;
        }
      | {
          backHref: string;
        }
    ))
  | { exitLabel?: never }
);

export const SimpleVariant = (props: SimpleVariantProps) => {
  const { title } = useContext(AppShellContext);

  const ExitComponent = memo(() => {
    if ("onClose" in props) {
      return (
        <button onClick={props.onClose} className="flex items-center gap-4">
          <Close />
          {props.exitLabel}
        </button>
      );
    }

    if ("onBack" in props) {
      return (
        <div className="flex items-center gap-4">
          <ChevronLeft />
          <button onClick={props.onBack} className="text-sm">
            {props.exitLabel}
          </button>
        </div>
      );
    }

    if ("closeHref" in props) {
      return (
        <div className="flex items-center gap-4">
          <Link href={props.closeHref} passHref className="sm">
            <Close />
            <span className="hidden pl-4 md:inline">{props.exitLabel}</span>
          </Link>
        </div>
      );
    }

    if ("backHref" in props) {
      return (
        <div className="flex items-center">
          <Link href={props.backHref} passHref className="text-sm ">
            <ChevronLeft />
            <span className="hidden pl-4 md:inline">{props.exitLabel}</span>
          </Link>
        </div>
      );
    }

    return null;
  });

  ExitComponent.displayName = "ExitComponent";

  return (
    <>
      <div className="flex items-center justify-between w-full h-15">
        <div className="flex items-start w-40">
          <motion.div
            whileHover="hover"
            animate="rest"
            className="relative flex items-center justify-center h-10 px-4"
          >
            <ExitComponent />
            <motion.div
              variants={{
                rest: { right: 0, left: "100%" },
                hover: { left: 0 },
              }}
              aria-hidden="true"
              className="absolute top-0 bottom-0 rounded bg-grey-3 -z-1"
            />
          </motion.div>
        </div>
        <p>{props.title || title}</p>
        <div className="w-40" />
      </div>
      <Drawer hidden />
    </>
  );
};
