import { BRAND_NAME } from "./misc";

export const ADDON_SUBSCRIPTIONS = {
  AMK: {
    RAATASS: "a91febd9-a92a-488f-9a8b-aee55dd221c6",
    DIGITAL_RECIPES: "FA2BC3E5-C1B3-47D8-9B5A-09F19AE19192",
    BUSINESS: "ed31c587-d0f9-4650-82b3-d9fcfe476df0",
  },
  GL: {
    RAATASS: "4a2b0274-3d9e-4d70-9d34-cb2ba452bf88",
    DIGITAL_RECIPES: "05464097-B909-4A19-992D-7B4E7ADDD00B",
    BUSINESS: "ed31c587-d0f9-4650-82b3-d9fcfe476df0",
  },
  RN: {
    RAATASS: "NO_SUBSCRIPTION_ID",
    DIGITAL_RECIPES: "5E797AB8-F9EE-46CA-9EA0-0B205F73CE8A",
    BUSINESS: "ed31c587-d0f9-4650-82b3-d9fcfe476df0",
  },
  LMK: {
    RAATASS: "NO_SUBSCRIPTION_ID",
    DIGITAL_RECIPES: "F30B7DF2-595E-4B0B-989C-3EA91437B480",
    BUSINESS: "ed31c587-d0f9-4650-82b3-d9fcfe476df0",
  } as const,
}[BRAND_NAME];
