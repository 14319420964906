import React from "react";
import clsx from "clsx";

import { Switch as SwitchHeadless } from "@headlessui/react";
import { PropsOf } from "../../../types";

interface OwnSwitchProps {
  label?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  small?: boolean;
}

type SwitchProps = OwnSwitchProps & PropsOf<typeof SwitchHeadless>;

export const Switch = ({
  label,
  checked,
  onChange,
  className,
  disabled,
  isLoading,
  small,
}: SwitchProps) => {
  const sizes = {
    default: "h-8 w-14",
    small: "h-6 w-11",
  };

  const circleSizes = {
    default: "h-6.5 w-6.5",
    small: "h-5 w-5",
  };

  const circleTranslations = {
    default: "translate-x-1",
    small: "translate-x-0.5",
  };

  const checkedCircleTranslations = {
    default: "translate-x-6.5",
    small: "translate-x-5.5",
  };

  return (
    <SwitchHeadless
      checked={checked}
      onChange={disabled || isLoading ? () => null : onChange}
      className={clsx(
        "inline-flex items-center rounded-full z-10 a11y-focus:focus-ring",
        disabled && "cursor-not-allowed",
        disabled && !checked && "bg-grey-2",
        disabled && checked && "bg-information/75",
        !disabled && !checked && "bg-grey-1",
        !disabled && checked && "bg-information",
        isLoading && "animate-pulse cursor-not-allowed",
        small ? sizes.small : sizes.default,
        className,
      )}
    >
      <span className="sr-only">{label}</span>
      <span
        className={clsx(
          "inline-block transform rounded-full transition-all bg-white",
          small ? circleSizes.small : circleSizes.default,
          checked
            ? small
              ? checkedCircleTranslations.small
              : checkedCircleTranslations.default
            : small
              ? circleTranslations.small
              : circleTranslations.default,
        )}
      />
    </SwitchHeadless>
  );
};
