export const MOBILE_APP_ENDPOINTS = {
  ON_APP_CONNECT: "on-app-connect",
  ON_REFRESH_DEVICE_TOKEN: "on-refresh-device-token",
  ON_USER_LOGOUT: "on-user-logout",
  SELECT_CONTACTS: "select-contacts",
  ON_APP_TRACKING_TRANSPARENCY: "on-app-tracking-transparency",
  ON_SHARE: "on-share",
  ON_REQUEST_USER_RATE_APP: "on-request-user-rate-app",
} as const;

export type MOBILE_APP_ENDPOINT =
  (typeof MOBILE_APP_ENDPOINTS)[keyof typeof MOBILE_APP_ENDPOINTS];
