import { Ref, useState, useCallback } from "react";

import { useLazyAddressValidationQuery } from "@chef/state-management";
import { IAddressValidationModel } from "@chef/state-management";
import { AsyncSelect } from "@chef/components";

import { IInputOption, QUERY_MODE } from "./AddressForm";
import { debounce } from "./StreetNameSelectInput";

import { intl } from "./StreetNumberSelectInput.Intl";

interface Props {
  locked: boolean;
  selectedAddress: IAddressValidationModel;
  queryText: string;
  onSelected: (value: IAddressValidationModel) => void;
  onInputChanged: (value: string) => void;
  customRef?: Ref<HTMLInputElement>;
  mandatoryErrorText?: string;
  selectFromListErrorText?: string;
  className?: string;
  disabled?: boolean;
  autofocus?: boolean;
}

const StreetNumberSelectInput = ({
  locked,
  selectedAddress,
  queryText,
  onSelected,
  onInputChanged,
  customRef,
  mandatoryErrorText,
  selectFromListErrorText,
  className,
  disabled,
  autofocus,
}: Props) => {
  const [addressQuery] = useLazyAddressValidationQuery();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultOptions, setDefaultOptions] = useState<IInputOption[]>([]);
  const [lock, setLock] = useState(locked);

  const isReadyToFetchStreetNumbers = !!(
    selectedAddress.streetName && selectedAddress.postalArea
  );

  const parsedStreetNumberSearch = (value: string) => {
    const match = value.match(/^\d+/);
    return match ? match[0] : "";
  };
  const parsedLetterExtraSearch = (value: string) => {
    // If the string contains a "-", extract the last digit; otherwise, extract the last letter.
    const match = value.includes("-")
      ? value.match(/(\d)$/)
      : value.match(/([a-zA-Z])$/);
    return match ? match[0] : "";
  };

  const parseLetterExtraLabel = (letterExtra: string | undefined) => {
    if (letterExtra === "" || letterExtra === undefined) {
      return letterExtra;
    }
    if (isNaN(+letterExtra)) {
      return letterExtra;
    }
    return `-${letterExtra}`;
  };

  const getStreetNumberOptions = async (value: string) => {
    if (!isReadyToFetchStreetNumbers) {
      return [];
    }
    const { data } = await addressQuery({
      mode: QUERY_MODE.STREET_NUMBER,
      streetName: selectedAddress.streetName,
      streetNumber: parsedStreetNumberSearch(value),
      letterExtra: parsedLetterExtraSearch(value),
      postalArea: selectedAddress.postalArea,
    });

    if (data?.value.length === 1 && data.value[0].streetNumber === undefined) {
      onSelected(data.value[0]);
      setLock(true);
    }

    return (
      data?.value.map((address) => {
        const { streetNumber = "", letterExtra = "" } = address;
        return {
          label: `${streetNumber}${parseLetterExtraLabel(letterExtra)}`,
          value: `${streetNumber}${letterExtra}`,
          data: address,
        };
      }) || []
    );
  };

  const loadStreetNumberOptionsDebounced = useCallback(
    debounce(
      (inputValue: string, callback: (options: IInputOption[]) => void) => {
        getStreetNumberOptions(inputValue)
          .then((options) => callback(options))
          .catch(() => {
            console.error("Something went wrong");
            // handle what happens when the api is down
            // handle what happens when no addresses are found
          });
      },
      300,
    ),
    [],
  );

  const onFocus = () => {
    if (defaultOptions.length === 0) {
      setIsLoading(true);
      getStreetNumberOptions("").then((opts) => {
        setDefaultOptions(opts);
        setIsLoading(false);
      });
    }
  };

  return (
    <AsyncSelect
      disabled={disabled}
      locked={lock}
      autofocus={autofocus}
      className={className}
      customRef={customRef}
      name="street-number"
      value={`${selectedAddress.streetNumber}${
        parseLetterExtraLabel(selectedAddress.letterExtra) || ""
      }`}
      loadOptions={loadStreetNumberOptionsDebounced}
      placeholder={intl.STREET_NUMBER_LABEL}
      onChange={(input) => {
        const { data } = input as IInputOption;
        if (data) {
          onSelected(data as IAddressValidationModel);
        }
      }}
      formatOptionLabel={(option) => {
        const _option = option as IInputOption;
        return (
          <div id="street-number-list">
            <p>{_option.label}</p>
          </div>
        );
      }}
      onInputChange={onInputChanged}
      mandatoryErrorText={mandatoryErrorText}
      selectFromListErrorText={selectFromListErrorText}
      searchString={queryText}
      isLoading={isLoading}
      defaultOptions={defaultOptions}
      onFocus={onFocus}
    />
  );
};

export default StreetNumberSelectInput;
