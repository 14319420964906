import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";

import { Button, Card, Modal } from "@chef/components";
import {
  hideFavoriteModal,
  selectFavoriteModalIsOpen,
} from "@chef/state-management";
import { Heart } from "@chef/icons/small";
import { BRAND_PATHS } from "@chef/constants";
import { intl } from "./FavoriteActionModal.Intl";

export const FavoriteActionModal = () => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(hideFavoriteModal());
  };

  const favoriteModalOpen = useSelector(selectFavoriteModalIsOpen);

  return (
    <Modal
      outerClassName="flex items-center justify-center min-h-screen"
      innerClassName="px-4 max-w-lg mx-auto"
      show={favoriteModalOpen}
      showClose={false}
      onClose={handleCancel}
    >
      <Card
        noPadding
        className="flex flex-col items-center p-6 text-center md:p-18"
      >
        <div className="flex items-center justify-center w-20 h-20 rounded-full bg-grey-3">
          <Heart width={34} height={34} className="mt-1 ml-0.5" />
        </div>
        <h1 className="max-w-xs mt-4 mb-2 text-xl md:text-2xl">
          <strong>{intl.DO_YOU_WANT_TO_SAVE_YOUR_FAVORITE_RECIPES}</strong>
        </h1>

        <p className="mb-8">
          {intl.AS_OUR_CUSTOMER_YOU_CAN_CREATE_YOUR_OWN_COOKBOOK}
        </p>

        <Link href={BRAND_PATHS.LOG_IN_URL} className="w-full">
          <Button primary className="mb-3" full onClick={handleCancel}>
            {intl.LOG_IN_OR_CREATE_AN_ACCOUNT}
          </Button>
        </Link>

        <Button onClick={handleCancel} outlined full>
          {intl.NO_THANK_YOU}
        </Button>
      </Card>
    </Modal>
  );
};
