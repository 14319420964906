import * as React from "react";
import { SVGProps } from "react";
const Vipps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 58 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_23260_306235)">
      <path
        d="M3.35457 10.6953L5.7876 17.8475L8.17292 10.6953H10.9876L6.78944 21.3036H4.69035L0.492188 10.6953H3.35457Z"
        fill="#FF5B24"
      />
      <path
        d="M17.3329 19.6176C19.098 19.6176 20.0999 18.7536 21.054 17.5056C21.5788 16.8336 22.2467 16.6896 22.7237 17.0736C23.2008 17.4576 23.2485 18.1776 22.7237 18.8496C21.3402 20.6737 19.5751 21.7777 17.3329 21.7777C14.8999 21.7777 12.7531 20.4336 11.2742 18.0816C10.8448 17.4576 10.9402 16.7856 11.4173 16.4495C11.8944 16.1135 12.61 16.2575 13.0393 16.9296C14.0889 18.5136 15.5201 19.6176 17.3329 19.6176ZM20.6247 13.7135C20.6247 14.5775 19.9568 15.1535 19.1935 15.1535C18.4302 15.1535 17.7623 14.5775 17.7623 13.7135C17.7623 12.8495 18.4302 12.2734 19.1935 12.2734C19.9568 12.2734 20.6247 12.8975 20.6247 13.7135Z"
        fill="#FF5B24"
      />
      <path
        d="M27.3504 10.6943V12.1343C28.066 11.1263 29.1633 10.4062 30.7853 10.4062C32.8367 10.4062 35.222 12.1343 35.222 15.8304C35.222 19.7185 32.9321 21.5905 30.5467 21.5905C29.3064 21.5905 28.1614 21.1105 27.3027 19.9105V24.9986H24.7266V10.6943H27.3504ZM27.3504 15.9744C27.3504 18.1344 28.5908 19.2865 29.9743 19.2865C31.31 19.2865 32.6458 18.2305 32.6458 15.9744C32.6458 13.7663 31.31 12.7103 29.9743 12.7103C28.6385 12.7103 27.3504 13.7183 27.3504 15.9744Z"
        fill="#FF5B24"
      />
      <path
        d="M39.7567 10.6943V12.1343C40.4723 11.1263 41.5695 10.4062 43.1915 10.4062C45.2429 10.4062 47.6282 12.1343 47.6282 15.8304C47.6282 19.7185 45.3383 21.5905 42.953 21.5905C41.7126 21.5905 40.5677 21.1105 39.709 19.9105V24.9986H37.1328V10.6943H39.7567ZM39.7567 15.9744C39.7567 18.1344 40.997 19.2865 42.3805 19.2865C43.7163 19.2865 45.0521 18.2305 45.0521 15.9744C45.0521 13.7663 43.7163 12.7103 42.3805 12.7103C40.997 12.7103 39.7567 13.7183 39.7567 15.9744Z"
        fill="#FF5B24"
      />
      <path
        d="M53.1156 10.4062C55.2623 10.4062 56.7889 11.4143 57.4568 13.9103L55.1192 14.2944C55.0715 13.0463 54.3082 12.6143 53.1633 12.6143C52.3045 12.6143 51.6367 12.9983 51.6367 13.6223C51.6367 14.1023 51.9706 14.5824 52.9724 14.7744L54.7376 15.1104C56.455 15.4464 57.4091 16.5984 57.4091 18.1344C57.4091 20.4385 55.3578 21.5905 53.4018 21.5905C51.3504 21.5905 49.0605 20.5345 48.7266 17.9424L51.0642 17.5584C51.2073 18.9025 52.0183 19.3825 53.3541 19.3825C54.3559 19.3825 55.0238 18.9985 55.0238 18.3745C55.0238 17.7984 54.6899 17.3664 53.5926 17.1744L51.9706 16.8864C50.2532 16.5504 49.2036 15.3504 49.2036 13.8143C49.2513 11.4143 51.3981 10.4062 53.1156 10.4062Z"
        fill="#FF5B24"
      />
    </g>
    <defs>
      <clipPath id="clip0_23260_306235">
        <rect width={58} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Vipps;
