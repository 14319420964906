import { BillingQuery } from "../../graphql/generated";
import { IBillingAgreementArgs } from "./types";

// NOTE: not fully implemented, as not 100% sure how to handle the other data
// https://gg-developer.developer.azure-api.net/api-details#api=cms-frontend-int&operation=BillingAgreement_PostBillingAgreementChange
export const convertFromBillingToUpdateBillingAgreement = (
  billing: BillingQuery["billing"],
): IBillingAgreementArgs => {
  const basket = billing?.baskets?.find((b) => b.default);
  if (!basket && process.env.NODE_ENV === "development") {
    throw new Error("No default basket found in billing");
  }

  return {
    // status: billing.status,
    basket: {
      shippingAddress: basket?.shippingAddressId,
      timeblock: basket?.timeblockId,
      subscribedDeliveryInterval: basket?.deliveryInterval.toString(),
      isDefault: true,
      isActive: true,
      basketProducts: basket?.basketProducts.map((bp) => ({
        subscribedVariationId: bp.variationId,
        subscribedQuantity: bp.quantity,
        // missing subscribedDeliveryInterval
        // missing isExtra
      })),
    },
  };
};
