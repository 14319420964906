import React from "react";
import clsx from "clsx";
import { Language } from "../../types";

const intl = {
  no: {
    DISHES: "retter",
  },
  se: {
    DISHES: "rätter",
  },
  dk: {
    DISHES: "retter",
  },
};

const MAX_DISHES = 4;

interface Recipe {
  recipeName: string;
  recipeId: number;
  imageUrl?: string;
}

interface RecipeProps {
  recipeName: string;
  recipeId: number;
  imageUrl: string;
  onClick: (id: number) => void;
  Image: ({ src, alt }: { src: string; alt: string }) => JSX.Element;
}

const Recipe = ({
  recipeName,
  recipeId,
  imageUrl,
  onClick,
  Image,
}: RecipeProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    onClick(recipeId);
  };

  return (
    <button type="button" className="col-span-1" onClick={handleClick}>
      <Image src={imageUrl} alt={recipeName} />
    </button>
  );
};

interface RecipePlaceholderProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  language: Language;
  numTotalRecipes: number;
  children: ((props: { text: string }) => React.ReactNode) | React.ReactNode;
}

const RecipePlaceholder = ({
  language = "no",
  numTotalRecipes,
  children,
  className,
  ...props
}: RecipePlaceholderProps) => {
  const { DISHES } = intl[language];

  const numAdditionalRecipes = numTotalRecipes - MAX_DISHES + 1;
  const text = DISHES;
  const childrenIsFunc = typeof children === "function";

  if (numAdditionalRecipes < 2) {
    return null;
  }

  return (
    <div
      key="additional-dishes"
      className={clsx(
        className,
        "flex items-center justify-center w-full h-full",
      )}
      {...props}
    >
      +{numAdditionalRecipes} {childrenIsFunc ? children({ text }) : children}
    </div>
  );
};

interface RecipesPreviewProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
  recipes: Recipe[];
  isLoading: boolean;
  onClick?: (id: number) => void;
  PlaceholderImage: () => JSX.Element;
  Image: ({ src, alt }: { src: string; alt: string }) => JSX.Element;
}

const RecipesPreview = ({
  recipes,
  isLoading,
  children,
  className,
  onClick = () => null,
  PlaceholderImage,
  Image,
}: RecipesPreviewProps) => {
  const numTotalRecipes = recipes.length;
  const recipesToShow = recipes.slice(
    0,
    numTotalRecipes === MAX_DISHES ? MAX_DISHES : MAX_DISHES - 1,
  );

  return (
    <div className={clsx("grid grid-cols-4 gap-2 my-6 lg:gap-4", className)}>
      {isLoading
        ? [...Array(4)].map((_, i) => <PlaceholderImage key={i} />)
        : [
            recipesToShow.map(({ recipeName, recipeId, imageUrl }) => (
              <Recipe
                recipeName={recipeName}
                recipeId={recipeId}
                imageUrl={imageUrl || ""}
                key={recipeId}
                Image={Image}
                onClick={onClick}
              />
            )),
            children,
          ]}
    </div>
  );
};

RecipesPreview.RecipePlaceholder = RecipePlaceholder;

export default RecipesPreview;
