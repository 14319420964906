import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

interface Override {
  values: string[];
  contextName: string;
}

interface Constraint {
  values: string[];
  inverted: boolean;
  operator: string;
  contextName: string;
  caseInsensitive: boolean;
}

interface Variant {
  name: string;
  weight: number;
  overrides: Override[];
  payload: {
    type: string;
    value: string;
  };
  stickiness: string;
  weightType: string;
}

interface Strategy {
  name: string;
  constraints: Constraint[];
  variants: Variant[];
  parameters: {
    groupId: string;
    rollout: string;
    stickiness: string;
  };
  sortOrder: number;
  id: string;
  title: string;
  disabled: boolean;
}

interface Environment {
  name: string;
  lastSeenAt: string;
  enabled: boolean;
  type: string;
  sortOrder: number;
  strategies: Strategy[];
}

export interface UnleashFeature {
  name: string;
  impressionData: boolean;
  description: string | null;
  lastSeenAt: string | null;
  createdAt: string;
  variants: Variant[];
  environments: Environment[];
}

interface CreateFeatureArgs {
  name: string;
  type?: string;
  description?: string;
  impressionData?: boolean;
}

interface UpdateFeatureArgs {
  name: string;
  description?: string;
  type?: string;
  stale?: boolean;
  archived?: boolean;
  impressionData?: boolean;
}

interface UpdateFeatureVariantsArgs {
  name: string;
  variants: {
    name: string;
    weight: number;
    weightType: "variable";
    stickiness?: string;
    payload?: {
      type: string;
      value: string;
    };
    overrides?: Override[];
  }[];
}

export const unleashApi = createApi({
  reducerPath: "unleash",
  tagTypes: ["features"],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/unleash",
  }),
  endpoints: (builder) => ({
    getFeatures: builder.query<UnleashFeature[], void>({
      providesTags: ["features"],
      query: () => ({
        url: `/features`,
      }),
    }),
    getFeature: builder.query<UnleashFeature, string>({
      providesTags: ["features"],
      query: (featureName) => ({
        url: `/features/${featureName}`,
      }),
    }),
    createFeature: builder.mutation<UnleashFeature, CreateFeatureArgs>({
      query: (data) => ({
        url: `/features`,
        method: "POST",
        body: data,
      }),
    }),
    updateFeature: builder.mutation<UnleashFeature, UpdateFeatureArgs>({
      query: ({ name, ...data }) => ({
        url: `/features/${name}`,
        method: "PUT",
        body: data,
      }),
    }),
    updateFeatureVariants: builder.mutation<
      UnleashFeature,
      UpdateFeatureVariantsArgs
    >({
      query: ({ name, variants }) => ({
        url: `/features/${name}/variants`,
        method: "PUT",
        body: variants,
      }),
    }),
    enableFeature: builder.mutation<
      void,
      { featureName: string; environment: string }
    >({
      query: ({ featureName, environment }) => ({
        url: `/features/${featureName}/environments/${environment}/on`,
        method: "POST",
      }),
    }),
    disableFeature: builder.mutation<
      void,
      { featureName: string; environment: string }
    >({
      query: ({ featureName, environment }) => ({
        url: `/features/${featureName}/environments/${environment}/off`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetFeaturesQuery,
  useGetFeatureQuery,
  useCreateFeatureMutation,
  useUpdateFeatureMutation,
  useUpdateFeatureVariantsMutation,
  useEnableFeatureMutation,
  useDisableFeatureMutation,
} = unleashApi;
