import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      ADD: "Legg til",
      MAX: (amount: number) => `Maks ${amount} stk`,
      ADDED_TO_YOUR_ORDER: "Lagt til i din ordre",
      REMOVE: "Fjern",
      UPDATING_YOUR_ORDER: "Oppdaterer din ordre",
    },

    se: {
      ADD: "Lägg till",
      MAX: (amount: number) => `Max ${amount} st`,
      ADDED_TO_YOUR_ORDER: "Tillagd i din order",
      REMOVE: "Ta bort",
      UPDATING_YOUR_ORDER: "Uppdaterar din order",
    },

    dk: {
      ADD: "Tilføj",
      MAX: (amount: number) => `Max ${amount} stk`,
      ADDED_TO_YOUR_ORDER: "Tilføjet til din ordre",
      REMOVE: "Fjern",
      UPDATING_YOUR_ORDER: "Opdaterer din ordre",
    },
  } as const
)[language];
