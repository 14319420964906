import * as React from "react";
import { SVGProps } from "react";
const VisaBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.8463 0H2.15374C0.964262 0 0 0.976833 0 2.18182V21.8182C0 23.0232 0.964262 24 2.15374 24H31.8463C33.0357 24 34 23.0232 34 21.8182V2.18182C34 0.976833 33.0357 0 31.8463 0Z"
      fill="#0066B2"
    />
    <path
      d="M15.1604 16.1115H13.168L14.4138 8.53711H16.4063L15.1604 16.1115Z"
      fill="white"
    />
    <path
      d="M11.4917 8.53455L9.59147 13.7457L9.36727 12.6236L8.69711 9.21803C8.65657 9.00505 8.53605 8.81567 8.36025 8.68878C8.18446 8.56189 7.96669 8.50714 7.75178 8.53574H4.61054L4.57422 8.66422C5.30884 8.84607 6.01162 9.13826 6.65867 9.53077L8.3905 16.1114H10.4653L13.6357 8.537L11.4917 8.53455Z"
      fill="white"
    />
    <path
      d="M27.1686 16.1103H28.9986L27.4038 8.5359H25.8016C25.6082 8.52036 25.4149 8.56665 25.2496 8.66816C25.0842 8.76967 24.9554 8.92116 24.8818 9.10066L21.9102 16.1103H23.9874L24.4031 14.9857H26.9372L27.1686 16.1103ZM24.9751 13.4308L26.0222 10.5962L26.6112 13.4308H24.9751Z"
      fill="white"
    />
    <path
      d="M22.0635 10.3577L22.3483 8.73125C21.772 8.52729 21.1671 8.41562 20.5559 8.40039C19.567 8.40039 17.2183 8.82823 17.2183 10.9079C17.2183 12.8651 19.9755 12.8893 19.9755 13.917C19.9755 14.9447 17.5032 14.7654 16.6875 14.1121L16.3906 15.8088C17.1032 16.1039 17.8687 16.2495 18.6399 16.2366C19.9996 16.2366 22.0514 15.5398 22.0514 13.6432C22.0514 11.6738 19.264 11.4908 19.264 10.634C19.264 9.77714 21.2104 9.89231 22.0635 10.3577Z"
      fill="white"
    />
    <path
      d="M9.36734 12.6235L8.69718 9.21803C8.65664 9.00505 8.53605 8.81567 8.36025 8.68878C8.18446 8.56189 7.96677 8.50714 7.75186 8.53574H4.61061L4.57422 8.66422C5.65443 8.93613 6.66345 9.43745 7.53254 10.1342C8.35448 10.7862 8.98779 11.6454 9.36734 12.6235Z"
      fill="#F9A533"
    />
  </svg>
);
export default VisaBadge;
