import { OrderQuery } from "@chef/state-management";
import { Summary } from "../shared/Summary";

interface OrderSummaryProps {
  order: OrderQuery["order"];
}

export const OrderSummary = ({ order }: OrderSummaryProps) => {
  return (
    <Summary
      finalSum={order.finalPrice}
      overridePoints={order.loyaltyPoints.loyaltyPoints}
      hasBeenDelivered
    />
  );
};
