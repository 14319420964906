import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      IMAGE_MISSING: "Bilde mangler",
    },
    se: {
      IMAGE_MISSING: "Bild saknas",
    },
    dk: {
      IMAGE_MISSING: "Billede mangler",
    },
  } as const
)[language];
