import clsx from "clsx";
import React, {
  AnchorHTMLAttributes,
  createElement,
  HTMLAttributes,
} from "react";
import { Config } from "react-popper-tooltip";
import { Tooltip } from "../Tooltip";

type BannerProps = {
  highlighted?: boolean;
  href?: string;
  tooltip?: {
    element: React.ReactNode;
    popperOptions?: Config;
    className?: string;
  };

  children:
    | React.ReactNode
    | ((props: {
        tooltip: {
          enabled: boolean;
          visible: boolean;
        };
      }) => React.ReactNode);
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & (AnchorHTMLAttributes<HTMLAnchorElement> | HTMLAttributes<HTMLDivElement>);

export const Banner = ({
  highlighted = false,
  href,
  onClick,
  tooltip,
  children,
  className,
  ...props
}: BannerProps) => {
  let El = "div";
  if (href) {
    El = "a";
  } else if (onClick) {
    El = "button";
  }

  if (tooltip) {
    return (
      <Tooltip {...tooltip} wrapperClassName={className} variant="light">
        {({ visible }) =>
          createElement(
            El,
            {
              href,
              onClick,
              className: clsx(
                "w-full z-30 flex items-center h-8 text-sm justify-center whitespace-pre shadow-inner-sm",
                {
                  "bg-highlight": highlighted,
                  "bg-informationBG": !highlighted,
                },
              ),
              ...props,
            },
            typeof children === "function"
              ? children({
                  tooltip: { enabled: !!tooltip, visible },
                })
              : children,
          )
        }
      </Tooltip>
    );
  } else {
    return createElement(
      El,
      {
        href,
        onClick,
        className: clsx(
          "w-full z-30 flex items-center h-8 text-sm py-1.5 justify-center whitespace-pre shadow-inner-sm",
          {
            "bg-highlight": highlighted,
            "bg-informationBG": !highlighted,
          },
          className,
        ),
        ...props,
      },
      children,
    );
  }
};
