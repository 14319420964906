import { useState } from "react";

export const useReceipt = () => {
  const [expandedSection, setExpandedSection] = useState<
    | "temporaryDiscounts"
    | "permanentDiscounts"
    | "pickAndMixItems"
    | "standalondProduct"
    | "signupDiscounts"
    | "giftcardDiscounts"
    | "none"
  >("none");

  const createToggle = (section: typeof expandedSection) => () => {
    if (expandedSection === section) {
      return setExpandedSection("none");
    }

    setExpandedSection(section);
  };

  return {
    temporaryDiscountDisclosure: {
      temporaryDiscountDisclosureExpanded:
        expandedSection === "temporaryDiscounts",
      toggleTemporaryDiscountDisclosure: createToggle("temporaryDiscounts"),
    },
    permanentDiscountDisclosure: {
      permanentDiscountDisclosureExpanded:
        expandedSection === "permanentDiscounts",
      togglePermanentDiscountDisclosure: createToggle("permanentDiscounts"),
    },
    pickAndMixItemsDisclosure: {
      pickAndMixItemsDisclosureExpanded: expandedSection === "pickAndMixItems",
      togglePickAndMixItemsDisclosure: createToggle("pickAndMixItems"),
    },
    standalondProductDisclosure: {
      standalondProductDisclosureExpanded:
        expandedSection === "standalondProduct",
      toggleStandalondProductDisclosure: createToggle("standalondProduct"),
    },
    singupDiscountsDisclosure: {
      singupDiscountsDisclosureExpanded: expandedSection === "signupDiscounts",
      toggleSignupDiscountDisclosure: createToggle("signupDiscounts"),
    },
    giftcardDiscountsDisclosure: {
      giftcardDiscountsDisclosureExpanded:
        expandedSection === "giftcardDiscounts",
      toggleGiftcardDiscountDisclosure: createToggle("giftcardDiscounts"),
    },
  };
};
