export const yupCacheLatestTest = (
  asyncValidate: (val: string) => Promise<boolean>,
) => {
  let _valid = false;
  let _value = "";

  return async (value: string) => {
    if (value !== _value) {
      const response = await asyncValidate(value);
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};

export const yupCacheAllTests = (
  asyncValidate: (val: string) => Promise<boolean>,
) => {
  const cache: { [key: string]: boolean } = {};

  return async (value?: string) => {
    if (value === undefined) {
      value = "";
    }

    if (cache[value] === undefined) {
      const response = await asyncValidate(value);
      cache[value] = response;
      return response;
    }
    return cache[value];
  };
};
