import Image from "next/image";

interface DigitalKeyProps {
  digitalKeyProvider?: string;
  className?: string;
}
/**
 * Will render if provided DigitalKeyProvider matches
 * @param digitalKeyProvider: string | undefined
 * @returns JSX.Element | null
 */
export const DigitalKeyProviderInfo = ({
  digitalKeyProvider,
  className,
}: DigitalKeyProps) => {
  if (digitalKeyProvider?.toLowerCase() === "bekey") {
    return (
      <div className={className}>
        <div className="flex items-center p-4 bg-background">
          <div className="bg-white border rounded-lg border-grey-3 h-11 w-11">
            <Image
              src="https://ggfrontendassets.azureedge.net/publicassets/retnemt/bekey-logo.png"
              alt="Bekey logo"
              width={100}
              height={100}
            />
          </div>
          <p className="mx-4 text-sm">
            <strong>
              Vi vil bruge BeKey til din levering, for mere info kontakt
              kundeservice
            </strong>
          </p>
        </div>
      </div>
    );
  }
  return null;
};
