import * as React from "react";
import { SVGProps } from "react";
const DiscountLost = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51 12C43.2865 12 35.7463 14.2873 29.3328 18.5727C22.9193 22.8581 17.9205 28.949 14.9687 36.0753C12.0169 43.2017 11.2446 51.0433 12.7494 58.6085C14.2542 66.1738 17.9686 73.1229 23.4229 78.5771C28.8771 84.0314 35.8262 87.7458 43.3915 89.2506C50.9567 90.7554 58.7983 89.9831 65.9247 87.0313C73.051 84.0795 79.1419 79.0807 83.4273 72.6672C87.7127 66.2537 90 58.7135 90 51"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3018 45.8018C42.8916 45.8018 45.8018 42.8916 45.8018 39.3018C45.8018 35.7119 42.8916 32.8018 39.3018 32.8018C35.7119 32.8018 32.8018 35.7119 32.8018 39.3018C32.8018 42.8916 35.7119 45.8018 39.3018 45.8018Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.708 69.2075C66.2979 69.2075 69.208 66.2974 69.208 62.7075C69.208 59.1177 66.2979 56.2075 62.708 56.2075C59.1182 56.2075 56.208 59.1177 56.208 62.7075C56.208 66.2974 59.1182 69.2075 62.708 69.2075Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.8918 44.2417L32.8018 69.2017"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.9937 51L50.9937 12C45.7937 40.6 61.3937 56.2 89.9937 51Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DiscountLost;
