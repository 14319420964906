import * as React from "react";
import { SVGProps } from "react";
const Complaint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 9C1 5.22877 1 3.34315 2.17157 2.17157C3.34315 1 5.22876 1 9 1H11.5C14.7875 1 16.4313 1 17.5376 1.90796C17.7401 2.07418 17.9258 2.25989 18.0921 2.46242C19 3.56877 19 5.21252 19 8.50001V8.50001C19 11.7875 19 13.4312 18.0921 14.5376C17.9258 14.7401 17.7401 14.9258 17.5376 15.0921C16.4313 16 14.7875 16 11.5 16H7.30225C6.66763 16 6.35033 16 6.04737 16.0467C5.41251 16.1446 4.81056 16.3939 4.29243 16.7736C4.04518 16.9548 3.82081 17.1792 3.37207 17.628V17.628C2.81866 18.1814 2.54195 18.4581 2.32073 18.533C1.84595 18.6938 1.3255 18.4782 1.10346 18.0288C1 17.8194 1 17.4281 1 16.6454V9Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M10 5V9"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.5C10.5523 13.5 11 13.0523 11 12.5C11 11.9477 10.5523 11.5 10 11.5C9.44772 11.5 9 11.9477 9 12.5C9 13.0523 9.44772 13.5 10 13.5Z"
      fill="currentColor"
    />
  </svg>
);
export default Complaint;
