import * as React from "react";
import { SVGProps } from "react";
const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0767 2.21993C10.4183 1.39864 11.5817 1.39864 11.9233 2.21993L13.9395 7.06735C14.0835 7.41358 14.4091 7.65015 14.7829 7.68012L20.0161 8.09966C20.9027 8.17074 21.2623 9.27725 20.5867 9.85592L16.5996 13.2713C16.3148 13.5153 16.1904 13.8981 16.2774 14.2628L17.4956 19.3695C17.702 20.2348 16.7607 20.9186 16.0016 20.455L11.5213 17.7184C11.2012 17.5229 10.7988 17.5229 10.4787 17.7184L5.9984 20.455C5.2393 20.9186 4.29805 20.2348 4.50444 19.3695L5.72257 14.2628C5.80958 13.8981 5.68521 13.5153 5.40042 13.2713L1.41328 9.85592C0.73774 9.27725 1.09727 8.17074 1.98392 8.09966L7.21712 7.68012C7.59091 7.65015 7.91652 7.41358 8.06052 7.06735L10.0767 2.21993Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
export default Star;
