import clsx from "clsx";
import { intl, brand } from "./ChefTipBox.Intl";
interface ChefTipBoxProps {
  content: string;
  className?: string;
}

export const ChefTipBox = ({ content, className }: ChefTipBoxProps) => {
  const { ChefIcon } = brand;
  return (
    <div
      className={clsx(className, "w-full flex p-6 bg-informationBG rounded")}
    >
      <div className="flex space-x-4">
        <div className="flex my-auto text-lg">
          <ChefIcon className="w-10 h-10" />
        </div>
        <div className="flex-col space-y-2 ">
          <p>
            <strong>{intl.TITLE}</strong>
          </p>
          <p className="ml-1 text-sm">{content}</p>
        </div>
      </div>
    </div>
  );
};
