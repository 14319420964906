import * as React from "react";
import { SVGProps } from "react";
const Messenger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3632_498)">
      <path
        d="M8 0C3.494 0 0 3.302 0 7.76C0 10.092 0.956 12.108 2.512 13.5C2.642 13.616 2.722 13.78 2.726 13.956L2.77 15.38C2.784 15.834 3.252 16.13 3.668 15.946L5.256 15.246C5.39 15.186 5.542 15.176 5.684 15.214C6.414 15.414 7.19 15.522 8 15.522C12.506 15.522 16 12.22 16 7.762C16 3.304 12.506 0 8 0ZM12.804 5.97L10.454 9.698C10.08 10.292 9.28 10.438 8.718 10.018L6.848 8.616C6.676 8.488 6.44 8.488 6.27 8.618L3.746 10.534C3.41 10.79 2.97 10.386 3.194 10.028L5.544 6.3C5.918 5.706 6.718 5.56 7.28 5.98L9.15 7.382C9.322 7.51 9.558 7.51 9.728 7.38L12.252 5.464C12.59 5.21 13.03 5.612 12.804 5.97Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3632_498">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Messenger;
