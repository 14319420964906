import * as React from "react";
import { SVGProps } from "react";
const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
      fill="currentColor"
    />
    <rect x={10} y={5} width={2} height={2} rx={1} fill="white" />
    <rect x={10} y={9} width={2} height={8} rx={1} fill="white" />
  </svg>
);
export default Info;
