import * as React from "react";
import { SVGProps } from "react";
const Link = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0534 4.83231L12.6721 2.12654C13.0151 1.76956 13.4231 1.48623 13.8726 1.29289C14.322 1.09954 14.8041 1 15.2909 1C15.7778 1 16.2598 1.09954 16.7092 1.29289C17.1587 1.48623 17.5667 1.76956 17.9097 2.12654C18.2552 2.48094 18.5294 2.90247 18.7165 3.36685C18.9037 3.83123 19 4.32929 19 4.83231C19 5.33534 18.9037 5.83339 18.7165 6.29777C18.5294 6.76215 18.2552 7.18368 17.9097 7.53808L13.7137 11.8735C13.3707 12.2304 12.9627 12.5138 12.5133 12.7071C12.0638 12.9005 11.5818 13 11.0949 13C10.6081 13 10.126 12.9005 9.67657 12.7071C9.22712 12.5138 8.81914 12.2304 8.47614 11.8735C8.29919 11.6954 8.1397 11.4997 8 11.2893"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.94664 15.1677L7.32787 17.8735C6.98487 18.2304 6.57689 18.5138 6.12744 18.7071C5.67799 18.9005 5.19595 19 4.7091 19C4.22224 19 3.74021 18.9005 3.29075 18.7071C2.8413 18.5138 2.43332 18.2304 2.09032 17.8735C1.74482 17.5191 1.4706 17.0975 1.28347 16.6331C1.09634 16.1688 1 15.6707 1 15.1677C1 14.6647 1.09634 14.1666 1.28347 13.7022C1.4706 13.2378 1.74482 12.8163 2.09032 12.4619L6.28631 8.12654C6.62931 7.76956 7.03729 7.48623 7.48674 7.29288C7.93619 7.09954 8.41823 7 8.90508 7C9.39194 7 9.87397 7.09954 10.3234 7.29288C10.7729 7.48623 11.1809 7.76956 11.5239 8.12654C11.701 8.30446 11.8605 8.50016 12 8.71074"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Link;
