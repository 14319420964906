/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import clsx from "clsx";

import BlockContent from "@sanity/block-content-to-react";

// https://github.com/sanity-io/block-content-to-hyperscript/blob/master/src/mergeSerializers.js
// Recursively merge/replace default serializers with user-specified serializers
const isDefined = (val) => typeof val !== "undefined";
const mergeSerializers = (defaultSerializers, customSerializers = {}) => {
  return Object.keys(defaultSerializers).reduce((acc, key) => {
    const type = typeof defaultSerializers[key];
    if (type === "function") {
      acc[key] = isDefined(customSerializers[key])
        ? customSerializers[key]
        : defaultSerializers[key];
    } else if (type === "object") {
      acc[key] = Object.assign(
        {},
        defaultSerializers[key],
        customSerializers[key],
      );
    } else {
      acc[key] =
        typeof customSerializers[key] === "undefined"
          ? defaultSerializers[key]
          : customSerializers[key];
    }
    return acc;
  }, {});
};

const BlockSerializer = ({ isInline, fallback, prose, ...props }) => {
  const El = isInline ? "span" : "p";

  switch (props.node.style) {
    case "h1":
      return <h1>{props.children}</h1>;
    case "h2":
      return <h2>{props.children}</h2>;
    case "h3":
      return <h3>{props.children}</h3>;
    case "normal":
      return (
        <El className={clsx(prose ? "" : "text-base")}>{props.children}</El>
      );
    case "body-small":
      return <El className="text-sm">{props.children}</El>;
    case "body-tiny":
      return <El className="text-xs">{props.children}</El>;
    case "lead":
      return <El className="lead">{props.children}</El>;
    default:
      return (
        fallback?.types.block(props) || (
          <El className="text-base">{props.children}</El>
        )
      );
  }
};

const createSerializers = ({ fallback, prose }) => ({
  types: {
    heading: fallback?.types.heading,
    headline: fallback?.types.headline,
    block: (props) => {
      return <BlockSerializer fallback={fallback} {...props} prose={prose} />;
    },
  },
  marks: {
    strong(props) {
      return <strong>{props.children}</strong>;
    },
    color(props) {
      if (props.mark.color === "secondary") {
        return <span className="text-secondary">{props.children}</span>;
      }

      if (props.mark.color === "primary") {
        return <span className="text-primary">{props.children}</span>;
      }

      return <span>{props.children}</span>;
    },
  },
  list({ type, ...props }) {
    const isNumberType = type === "number";

    return React.createElement(
      isNumberType ? "ol" : "ul",
      {
        className: clsx(
          isNumberType ? "list-decimal" : "list-disc",
          "list-inside",
        ),
      },
      props.children,
    );
  },
  listItem(props) {
    const children =
      !props.node.style || prose
        ? props.children
        : BlockSerializer({
            ...props,
            isInline:
              props.node.style === "normal" ||
              props.node.style === "body-small" ||
              props.node.style === "body-tiny",
          });

    return (
      <li
        className={clsx(
          props.node.style === "body-small" && "text-sm",
          props.node.style === "body-tiny" && "text-xs",
        )}
      >
        {prose ? props.children : children}
      </li>
    );
  },
});

const SanityBlocks = ({
  blocks = [],
  customSerializers,
  fallbackSerializers,
  prose = false,
}) => {
  if (blocks === null || blocks.length < 1) {
    return null;
  }

  return (
    <BlockContent
      blocks={blocks}
      serializers={mergeSerializers(
        createSerializers({ fallback: fallbackSerializers, prose }),
        customSerializers,
      )}
      className={clsx(!prose && "space-y-4")}
    />
  );
};

export default SanityBlocks;
