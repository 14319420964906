import * as React from "react";
import { SVGProps } from "react";
const Chef = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 75C0 33.5786 33.5786 0 75 0V0C116.421 0 150 33.5786 150 75V75C150 116.421 116.421 150 75 150V150C33.5786 150 0 116.421 0 75V75Z"
      fill="#F4F4F1"
    />
    <path
      d="M56.8432 65.125V70.3125C52.9525 70.3125 52.5635 75.2406 52.5635 76.4078C52.5635 77.575 53.4713 83.2812 57.4916 83.2812C58.8078 88.7522 62.1432 93.5236 66.8291 96.6391M82.4998 96.5C87.1858 93.3845 90.2832 88.7522 91.5994 83.2812C95.49 83.2812 96.3979 77.7047 96.3979 76.4078C96.3979 75.1109 97.0463 70.4422 93.1557 70.3125V66.9406"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.625 95.0836V96.2508C64.625 98.8445 65.4031 102.605 51.1375 105.329C36.8719 108.052 36.0938 114.407 36.0938 114.407M113.906 114.407C113.906 114.407 113.906 108.701 97.825 105.329C85.375 102.735 85.375 98.8445 85.375 96.2508V94.1758"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.625 42.9482C59.4375 40.2248 51.6562 42.0404 51.6562 48.5248C51.6562 55.0091 56.8438 53.9716 56.8438 57.8623V65.1248C62.7179 63.2935 68.8481 62.4178 75 62.531C81.3305 62.4158 87.5838 63.9344 93.1562 66.9404V57.3435C93.1562 55.0091 98.3438 52.8044 98.3438 48.2654C98.3438 43.7263 93.0266 39.5763 85.375 42.9482"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.1064 99.6223L75.0002 104.032M75.0002 104.032L85.7643 99.1035M75.0002 104.032V114.407"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.3281 47.0989C63.6472 44.2335 65.0155 41.5875 67.1694 39.6709C69.3234 37.7544 72.1104 36.7028 74.9935 36.7189C77.8766 36.735 80.6517 37.8177 82.7841 39.7582C84.9165 41.6987 86.2551 44.3598 86.5422 47.2286"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Chef;
