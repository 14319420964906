import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      ERROR: "Det oppstod en feil under innlasting av siden.",
      RELOAD_BUTTON_PREFIX: "Prøv å",
      RELOAD_BUTTON_TEXT: "last på nytt",
      RELOAD_BUTTON_SUFFIX: ", eller",
      CONTACT_CUSTOMER_SERVICE: "kontakt kundeservice",
      BACK: "Tilbake",
    },

    se: {
      ERROR: "Ett fel uppstod när sidan laddades.",
      RELOAD_BUTTON_PREFIX: "Forsöka",
      RELOAD_BUTTON_TEXT: "ladda om sidan",
      RELOAD_BUTTON_SUFFIX: ", eller",
      CONTACT_CUSTOMER_SERVICE: "kontakt kundeservice",
      BACK: "Tillbaka",
    },
    dk: {
      ERROR: "Der opstod en fejl under indlæsning af siden.",
      RELOAD_BUTTON_PREFIX: "Prøv at",
      RELOAD_BUTTON_TEXT: "genindlæse siden",
      RELOAD_BUTTON_SUFFIX: ", eller",
      CONTACT_CUSTOMER_SERVICE: "kontakt kundeservice",
      BACK: "Tilbage",
    },
  } as const
)[language];
