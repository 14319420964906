import clsx from "clsx";
import { useEffect, useState } from "react";
import Image from "next/image";

import { Currency, Disclosure, Hr } from "@chef/components";
import { Dinner, Trash } from "@chef/icons/small";
import { config, language } from "@chef/constants";
import { Chevron } from "./Chevron";

interface MealsProps {
  portions: number;
  dishes: {
    name?: string;
    extraPrice?: number;
    onRemove?: () => void;
    imageUrl?: string;
  }[];
  priceOfMeals: number;
  initiallyExpanded?: boolean;
  minMeals?: number;
}

export const Meals = ({
  portions,
  dishes,
  priceOfMeals,
  initiallyExpanded,
  minMeals,
}: MealsProps) => {
  const [expanded, setExpanded] = useState<boolean>(!!initiallyExpanded);
  useEffect(() => {
    setExpanded(!!initiallyExpanded);
  }, [initiallyExpanded]);
  const minDishes = minMeals || config.order.minMeals;

  const missingDishCount = minDishes - dishes.length;

  return (
    <div className="flex flex-col">
      <Hr className="my-4" />

      <Disclosure
        open={expanded}
        onClick={() => setExpanded(!expanded)}
        hideContentTopMargin
        HeaderRow={() => (
          <div className="flex justify-between grow">
            <div className="flex items-center">
              <Dinner className="w-6.5 h-6.5 p-1" />

              <p className="ml-2">{`${portions} ${intl.PORTIONS}, ${dishes.length} ${intl.DISHES}`}</p>

              <Chevron className={expanded ? "rotate-180" : undefined} />
            </div>

            {missingDishCount <= 0 && <Currency>{priceOfMeals}</Currency>}
          </div>
        )}
      >
        <ul className="flex flex-col gap-2">
          {dishes.map(({ name, extraPrice, onRemove, imageUrl }) => (
            <li
              key={name}
              className="flex items-center justify-between mt-1 text-sm"
            >
              {onRemove && (
                <button onClick={onRemove}>
                  <Trash className="w-6.5 h-6.5 p-1 mr-2" />
                </button>
              )}

              {imageUrl && (
                <div className="relative w-12 h-12 mr-3 shrink-0">
                  <Image
                    src={imageUrl}
                    fill
                    sizes="15vw"
                    alt={name || "Dish preview"}
                    className="object-cover"
                  />
                </div>
              )}

              <p
                className={clsx(
                  "grow line-clamp-2",
                  !onRemove && !imageUrl && "ml-9",
                )}
              >
                {name}
              </p>

              <div className="w-20 shrink-0 l-2 text-end">
                {extraPrice !== undefined && extraPrice !== 0 && (
                  <Currency prefix={extraPrice > 0 ? "+" : "-"}>
                    {extraPrice}
                  </Currency>
                )}
              </div>
            </li>
          ))}

          {missingDishCount > 0 &&
            Array(missingDishCount)
              .fill(null)
              .map((_, index) => (
                <li key={index} className="flex items-center">
                  <div className="w-6.5 mr-2" />

                  <div className="flex items-center justify-center w-12 h-12 mr-3 bg-grey-2">
                    <Dinner />
                  </div>

                  <p className="text-sm">
                    <strong>{intl.DISH_NOT_CHOSEN}</strong>
                  </p>
                </li>
              ))}
        </ul>

        {missingDishCount > 0 && (
          <div className="flex">
            <div className="w-6.5 mr-2" />

            <p className="mt-2 text-sm text-error">
              {intl.PICK_AT_LEAST_X_MORE_DISHES(missingDishCount)}
            </p>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

const intl = (
  {
    no: {
      PORTIONS: "pers",
      DISHES: "retter",
      DISH_NOT_CHOSEN: "Rett ikke valgt",

      PICK_AT_LEAST_X_MORE_DISHES: (x: number) =>
        `Velg minst ${x} ${x === 1 ? "rett" : "retter"} til`,
    },

    se: {
      PORTIONS: "pers",
      DISHES: "rätter",
      DISH_NOT_CHOSEN: "Rätt inte valt",

      PICK_AT_LEAST_X_MORE_DISHES: (x: number) =>
        `Välj minst ${x} ${x === 1 ? "rätt" : "rätter"} till`,
    },

    dk: {
      PORTIONS: "portioner",
      DISHES: "retter",
      DISH_NOT_CHOSEN: "Ret ikke valgt",

      PICK_AT_LEAST_X_MORE_DISHES: (x: number) =>
        `Vælg mindst ${x} ${x === 1 ? "ret" : "retter"} til`,
    },
  } as const
)[language];
