import clsx from "clsx";
import { useUserInfoQuery } from "@chef/state-management";
import { ProgressBar, Skeleton, Tooltip } from "@chef/components";
import { useLoyalty } from "@chef/feature-flags";
import { InfoOutlined } from "@chef/icons/small";
import { intl } from "./LoyaltyInfoCard.Intl";

interface LoyaltyInfoCardProps {
  showInfo?: boolean;
  showNudge?: boolean;
  className?: string;
}

interface TooltipContentProps {
  availableLoyaltyPoints: number | null;
  currentAccruedPoints: number;
  accruedPointsToNextLevel: number;
  estimatedAccruedPointsFromNextDelivery: number;
  isMaxLevel: boolean;
}

const TooltipContent = ({
  availableLoyaltyPoints,
  currentAccruedPoints,
  accruedPointsToNextLevel,
  estimatedAccruedPointsFromNextDelivery,
  isMaxLevel,
}: TooltipContentProps) => {
  return (
    <dl className="z-50 flex flex-col gap-4 text-sm">
      {availableLoyaltyPoints !== null && (
        <div>
          <dt>
            <strong>
              {intl.POINTS_BALANCE} ({availableLoyaltyPoints})
            </strong>
          </dt>
          <dd>{intl.POINTS_BALANCE_DESCRIPTION}</dd>
        </div>
      )}

      <div>
        <dt>
          <strong>
            {intl.ACCRUED_POINTS} ({currentAccruedPoints})
          </strong>
        </dt>

        <dd>{intl.ACCRUED_POINTS_DESCRIPTION}</dd>
      </div>

      {!isMaxLevel && (
        <div>
          <dt>
            <strong>
              {intl.REMAINING_ACCRUED_POINTS} ({accruedPointsToNextLevel})
            </strong>
          </dt>
          <dd>{intl.REMAINING_ACCRUED_POINTS_DESCRIPTION}</dd>
        </div>
      )}

      <div>
        <dt>
          <strong>
            {intl.POINTS_RECEIVED_FROM_NEXT_DELIVERY} (
            {estimatedAccruedPointsFromNextDelivery})
          </strong>
        </dt>
        <dd>{intl.POINTS_RECEIVED_FROM_NEXT_DELIVERY_DESCRIPTION}</dd>
      </div>
    </dl>
  );
};

export const LoyaltyInfoCard = ({
  showInfo = true,
  className,
}: LoyaltyInfoCardProps) => {
  const { data: userInfoQuery } = useUserInfoQuery();
  const { data: loyaltyData } = useLoyalty();

  if (!userInfoQuery || !loyaltyData) {
    return (
      <div className={clsx("p-4 gap-2 flex flex-col rounded-sm", className)}>
        <Skeleton height={6} width={40} className="mb-4 bg-white/60" />
        <Skeleton height={3} width={20} className="bg-white/60" />
        <Skeleton height={3} width="100%" className="bg-white/60" />
        <div className="flex justify-between">
          <Skeleton height={2} width={20} className="bg-white/60" />
          <Skeleton height={2} width={20} className="bg-white/60" />
        </div>
      </div>
    );
  }

  const fullName = `${userInfoQuery.userInfo.firstName} ${userInfoQuery.userInfo.lastName}`;

  return (
    <div className={clsx("p-4 rounded-t", className)}>
      <div className="relative flex justify-between">
        <h3 className="text-lg">
          <strong>{fullName}</strong>
        </h3>

        {showInfo && (
          <Tooltip
            element={
              <TooltipContent
                currentAccruedPoints={loyaltyData.currentAccruedPoints}
                accruedPointsToNextLevel={loyaltyData.accruedPointsToNextLevel}
                estimatedAccruedPointsFromNextDelivery={
                  loyaltyData.estimatedAccruedPointsFromNextDelivery
                }
                availableLoyaltyPoints={loyaltyData.availableLoyaltyPoints}
                isMaxLevel={loyaltyData.progress.isMax}
              />
            }
            popperOptions={{
              placement: "left-start",
              delayHide: 100,
              interactive: true,
              trigger: "click",
            }}
          >
            <InfoOutlined className="w-4 h-4 text-white rounded-full bg-primary" />
          </Tooltip>
        )}
      </div>

      <ProgressBar className="mt-3" progress={loyaltyData.progress} />

      <div className="flex justify-between mt-2">
        <div className="flex text-xs">
          <p>
            <strong>{intl.MEMBERSHIP_LEVEL}:</strong>
          </p>
          <p className="ml-1">{loyaltyData.currentLevelLabel}</p>
        </div>

        {!loyaltyData.progress.isMax && (
          <p className="text-xs">
            {loyaltyData.currentAccruedPoints}
            {intl.POINT} / {loyaltyData.nextLevelRequirement}
            {intl.POINT}
          </p>
        )}
      </div>
    </div>
  );
};
