import * as React from "react";
import { SVGProps } from "react";
const Delivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={80}
      height={80}
    >
      <path
        d="M0 40C0 62.0914 17.9086 80 40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40Z"
        fill="#F4F4F1"
      />
    </mask>
    <g mask="url(#mask0)">
      <path d="M80 0H0V80H80V0Z" fill="#F4F4F1" />
      <path
        d="M0 61C10.4198 60.8119 23.4418 60.7799 33.3253 60.8039C43.2089 60.8279 62.4399 61.0586 77.7083 60.9495"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M52.808 50.6003H48.1825M48.1825 50.6003V23.8047H13.5078V50.6003H18.8053M48.1825 50.6003H27.474M48.1825 31.4606H59.6444L63.8825 39.1165H67.6389C68.4094 39.1165 69.3726 39.3079 69.3726 40.4563V50.6003H61.5775"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M23.5634 59.532C26.6487 59.532 29.1499 57.0327 29.1499 53.9496C29.1499 50.8665 26.6487 48.3672 23.5634 48.3672C20.4781 48.3672 17.9769 50.8665 17.9769 53.9496C17.9769 57.0327 20.4781 59.532 23.5634 59.532Z"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M57.0823 59.532C60.1676 59.532 62.6688 57.0327 62.6688 53.9496C62.6688 50.8665 60.1676 48.3672 57.0823 48.3672C53.9969 48.3672 51.4958 50.8665 51.4958 53.9496C51.4958 57.0327 53.9969 59.532 57.0823 59.532Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
export default Delivery;
