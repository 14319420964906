import { LINKS } from "@chef/constants";

type Json = Record<string, any>;

type refType = {
  _id: string;
};

export const findObjectsInObject = (
  mainObject: Json,
  key: string,
  value: string,
): Json[] => {
  const values: Json[] = [];
  for (const item of Object.values(mainObject || {})) {
    if (item[key] === value) {
      values.push(item);
    } else {
      if (typeof item === "object") {
        values.push(...findObjectsInObject(item, key, value));
      }
    }
  }

  return values;
};

export const replaceRefComponentInObject = (
  mainObject: Json,
  refDocuments = [] as refType[],
) => {
  for (const [key, item] of Object.entries(mainObject)) {
    if (typeof item === "object") {
      if (item?._type === "refComponent") {
        const refMatch = refDocuments.find((ref) => ref._id === item._ref);
        if (!refMatch) {
          console.error("Did not find ref match:", mainObject);
          continue;
        }

        mainObject[key] = refMatch;
        continue;
      }

      replaceRefComponentInObject(item, refDocuments);
    }
  }
};

export const replaceDynamicText = ({
  block,
  firstName,
  referralCode,
  allowLineBreaks = false,
  invitesRequired,
  deliveriesRequired,
  competitionDeliveryCutOffDate,
  competitionEndDate,
  prizeValue,
}: any) => {
  return block.children
    .map((child: any) => child.text || child.dynamicVariable)
    .filter((text: any) => !!text)
    .join(allowLineBreaks ? "\n" : "")
    .replace("%FIRST_NAME%", firstName)
    .replace("%REFERRAL_LINK%", LINKS.REFERRAL_LINK(referralCode))
    .replace("%TERMS_AND_CONDITIONS_LINK%", LINKS.AGREEMENT_LINK)
    .replace("%INVITES_REQUIRED%", invitesRequired)
    .replace("%DELIVERIES_REQUIRED%", deliveriesRequired)
    .replace(
      "%COMPETITION_DELIVERY_CUTOFF_DATE%",
      competitionDeliveryCutOffDate,
    )
    .replace("%COMPETITION_END_DATE%", competitionEndDate)
    .replace("%PRIZE_VALUE%", prizeValue);
};
