import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CANT_FIND_ADDRESS: "Beklager, vi finner ikke adressen din.",
      SEARCH_TIPS: [
        "Vennligst kontroller at adressen er riktig stavet.",
        "Prøv å skrive inn gatenavn , poststed",
        "Ikke skriv inn gatenummer i dette feltet",
      ],
      OR: "Eller",
      CONTACT_CUSTOMER_SERVICE: "kontakt kundeservice",
      HELP_SET_UP_ACCOUNT: "for hjelp med adressen din.",
      REGISTER_ADDRESS: "Registrering av adresse",
    },
    se: {
      CANT_FIND_ADDRESS: "Vi beklagar, vi kan inte hitta din adress",
      SEARCH_TIPS: [
        "Vänligen kontrollera att stavningen är korrekt.",
        "Prova att lägga till endast gatunamn och ort",
        "Skriv inte in gatunumret i det här fältet",
      ],
      OR: "Eller",
      CONTACT_CUSTOMER_SERVICE: "kontakta kundservice",
      HELP_SET_UP_ACCOUNT: "för hjälp med din adress.",
      REGISTER_ADDRESS: "Adress",
    },
    dk: {
      CANT_FIND_ADDRESS: "Beklager! Vi kan ikke finde din adresse",
      SEARCH_TIPS: [
        "Kontroller venligst, at adressen er stavet korrekt",
        "Prøv kun at indtaste vejnavn og by",
        "Indtast ikke vejnummer i dette felt",
      ],
      OR: "Eller",
      CONTACT_CUSTOMER_SERVICE: "kontakt kundeservice",
      HELP_SET_UP_ACCOUNT: "for hjælp med din adresse.",
      REGISTER_ADDRESS: "Adresse",
    },
  } as const
)[language];
