import {
  useController,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import Image from "next/image";

import { Help } from "@chef/icons/small";
import {
  getPreferenceCombo,
  isConceptPreference,
  isVegetarianConceptPreference,
  PreferenceCombos,
} from "@chef/helpers";
import { getAttribute } from "@chef/state-management/helpers";
import { Checkbox } from "@chef/components";
import { isEqualStrings, isNotEqualStrings } from "@chef/utils/equal";
import { stripEmojis } from "@chef/utils/string";
import {
  PREFERENCE_IDS,
  VEGETARIAN_CONCEPT_PREFERENCE_ID,
  VEGETARIAN_TASTE_PREFERENCE_ID,
} from "@chef/constants";
import {
  setPreselectedRecipesModal,
  useMeQuery,
  type Preferences,
} from "@chef/state-management";
import { removeDuplicates } from "@chef/utils/helpers/removeDuplicates";
import { equal, isNotEmptyArray } from "@chef/utils/array";
import { usePreferences } from "@chef/state-management/hooks";

interface PreferenceSelectListProps<T extends object>
  extends UseControllerProps<T> {
  preferences: Preferences[];
  className?: string;
  disabled?: boolean;
  isFixed?: boolean;
  setParams?: (params: { slugs?: string[] }) => void;
}

const deserializeLinkedPreferenceIds = (string?: string): string[] =>
  string?.toLowerCase().split(",").filter(Boolean) || [];

export const PreferenceSelectList = <T extends object>({
  control,
  preferences,
  name,
  className,
  disabled,
  isFixed,
  setParams,
}: PreferenceSelectListProps<T>) => {
  const { field } = useController({
    control,
    name,
  });

  const { data: user } = useMeQuery();

  const { conceptPreferences, fixedPreferences } = usePreferences();

  const isLoggedIn = !!user;

  const formMethods = useFormContext();

  const dispatch = useDispatch();

  const values: string[] = useMemo(() => field.value || [], [field.value]);

  const isTastePreference = name === "tastePreferenceIds";

  const [_selectedConceptPreferenceIds, _selectedTastePreferenceIds] =
    formMethods.watch([
      "conceptPreferenceIds",
      "tastePreferenceIds",
    ]) as string[][];

  const selectedConceptPreferenceIds = _selectedConceptPreferenceIds || [];
  const selectedTastePreferenceIds = _selectedTastePreferenceIds || [];

  const vegetarianConceptPreference = conceptPreferences?.find(
    isVegetarianConceptPreference,
  );

  const linkedTastePreferencesForVegetarian =
    (vegetarianConceptPreference &&
      getAttribute("linked_taste_preferences", vegetarianConceptPreference)
        ?.toLowerCase()
        .split(",")
        .filter(Boolean)) ||
    [];

  // In the sign-up flow, the url does not contain the taste preferences, only the concept preferences. So we need to handle the vegetarian concept preference and its linked taste preferences separately
  const vegetarianSelected =
    selectedConceptPreferenceIds.length === 1 &&
    selectedConceptPreferenceIds.includes(PREFERENCE_IDS.VEGETARIAN_ONESUB);

  const _currentTastePreferenceIds = vegetarianSelected
    ? linkedTastePreferencesForVegetarian
    : selectedTastePreferenceIds;

  const combo = getPreferenceCombo([
    ...selectedConceptPreferenceIds,
    ..._currentTastePreferenceIds,
  ]);

  const isVegetarianCombo = combo >= PreferenceCombos.VEGETARIAN;

  // Handle linked taste preferences for the vegetarian concept during sign-up, ensuring the linked taste preferences are selected when the user opens the URL.
  useEffect(() => {
    if (isVegetarianCombo && !isLoggedIn) {
      if (name === "conceptPreferenceIds") {
        field.onChange([...selectedConceptPreferenceIds]);
      } else {
        field.onChange([...linkedTastePreferencesForVegetarian]);
      }
    }
  }, []);

  const isVegetarianConceptPreferenceSelected =
    selectedConceptPreferenceIds.includes(VEGETARIAN_CONCEPT_PREFERENCE_ID);

  useEffect(() => {
    const newValues = values.filter((value) => {
      const shouldFilter =
        isVegetarianCombo || isVegetarianConceptPreferenceSelected;

      return !(
        shouldFilter && isEqualStrings(value, VEGETARIAN_TASTE_PREFERENCE_ID)
      );
    });

    if (!equal(values, newValues)) {
      field.onChange(newValues);
    }
  }, [values, field, isVegetarianCombo, isVegetarianConceptPreferenceSelected]);

  const onChangePreferences = (p: Preferences) => {
    let updatedValues: string[];

    const hasSelectedPreferenceId = values.includes(p.preferenceId);

    if (isFixed) {
      updatedValues = hasSelectedPreferenceId ? [] : [p.preferenceId];
    } else {
      updatedValues = hasSelectedPreferenceId
        ? values.filter((id) => isNotEqualStrings(id, p.preferenceId))
        : [...values, p.preferenceId];
    }

    // Check if updatedValues has exactly one selected concept preference
    // Check if that concept preference has linked taste preferences
    // If so, toggle them on
    if (name === "conceptPreferenceIds" && updatedValues.length === 1) {
      const selectedConceptPreference = preferences.find((preference) =>
        isEqualStrings(preference.preferenceId, updatedValues[0]),
      );

      if (selectedConceptPreference) {
        const linkedPreferenceIds = deserializeLinkedPreferenceIds(
          getAttribute("linked_taste_preferences", selectedConceptPreference),
        );

        if (isNotEmptyArray(linkedPreferenceIds)) {
          const updatedTastePreferenceIds = removeDuplicates([
            ...selectedTastePreferenceIds,
            ...linkedPreferenceIds,
          ]);

          formMethods.setValue("tastePreferenceIds", updatedTastePreferenceIds);
        }
      }
    }

    // If preference is a concept preference, check if it has linked taste preferences
    // If it was selected, toggle linked taste preferences off
    if (name === "conceptPreferenceIds") {
      const linkedPreferenceIds = deserializeLinkedPreferenceIds(
        getAttribute("linked_taste_preferences", p),
      );

      if (isNotEmptyArray(linkedPreferenceIds)) {
        if (hasSelectedPreferenceId) {
          const updatedTastePreferenceIds = selectedTastePreferenceIds.filter(
            (id) => !linkedPreferenceIds.includes(id),
          );

          formMethods.setValue("tastePreferenceIds", updatedTastePreferenceIds);
        }
      }
    }

    const selectedConceptPreferences = [
      ...conceptPreferences,
      ...fixedPreferences,
    ].filter(
      (preference) =>
        updatedValues.includes(preference.preferenceId) &&
        isConceptPreference(preference),
    );

    const preferenceSlugs = selectedConceptPreferences.map(
      (preference) => getAttribute("preference_slug", preference) || "",
    );

    if (setParams) {
      setParams({ slugs: preferenceSlugs });
    } else {
      field.onChange(removeDuplicates(updatedValues));
    }
  };

  const sortedPreferences = preferences.sort((a, b) => {
    const aPriority = +(getAttribute("sorting_priority", a) || 0);
    const bPriority = +(getAttribute("sorting_priority", b) || 0);

    return aPriority - bPriority;
  });

  return (
    <div className={className}>
      {sortedPreferences.map((p) => {
        const isConcept = isConceptPreference(p);

        const isVegetarianTastePreference = isEqualStrings(
          p.preferenceId,
          VEGETARIAN_TASTE_PREFERENCE_ID,
        );

        let isDisabled = disabled;

        if (isVegetarianTastePreference) {
          isDisabled ||=
            isVegetarianCombo || isVegetarianConceptPreferenceSelected;
        }

        const isSelected = values.includes(p.preferenceId);

        return (
          <div key={p.preferenceId}>
            <Checkbox
              variant="selector"
              state={isTastePreference ? "crossed" : "checked"}
              onChange={() => onChangePreferences(p)}
              key={p.preferenceId}
              type="checkbox"
              value={p.preferenceId}
              disabled={isDisabled}
              checked={isSelected}
              className={clsx(isDisabled && "opacity-30")}
              id={`${p.preferenceTypeName}-${p.preferenceName}`}
            >
              <div className="flex items-center justify-between text-left">
                <div className="flex items-center gap-2">
                  <Image
                    src={`https://ggfrontendassets.azureedge.net/publicassets/preference-icons/${p.preferenceId.toUpperCase()}.svg`}
                    width={24}
                    height={24}
                    alt={stripEmojis(p.preferenceName)}
                    sizes="24px"
                  />
                  <strong>{stripEmojis(p.preferenceName)}</strong>
                </div>
                {!isSelected && isConcept && (
                  <button
                    onClick={() => {
                      dispatch(
                        setPreselectedRecipesModal({
                          open: true,
                          preferenceIds: [p.preferenceId],
                        }),
                      );
                    }}
                    type="button"
                    className="flex items-center justify-center w-10 h-10"
                  >
                    <Help width={16} height={16} className="text-information" />
                  </button>
                )}
              </div>
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};
