/**
 * @module object
 * @category utils
 */

import Debug from "debug";
const debug = Debug("object");

/**
 * Check to test if object is empty
 * @param {Object} object object to validate
 * @returns {Boolean}
 * @example isEmptyObject({}); // returns true
 */
export const isEmptyObject = (object: any) => {
  if (object && object.constructor === Object) {
    return Object.keys(object).length === 0;
  }
  if (process.env["NODE_ENV"] !== "production") {
    debug(
      `[isEmptyObject]: The object being verified is not an object: ${object}`,
    );
  }
  return null;
};

/**
 * Check to test if object is not empty
 * @param {Object} object object to validate
 * @returns {Boolean}
 * @example isNotEmptyObject({foo: "bar"}); // returns true
 */
export const isNotEmptyObject = (object: any) => {
  if (object && object.constructor === Object) {
    return Object.keys(object).length > 0;
  }
  if (process.env["NODE_ENV"] !== "production") {
    debug(
      `[isNotEmptyObject]: The object being verified is not an object: ${object}`,
    );
  }
  return null;
};
