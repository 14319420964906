import React, { useState } from "react";
import clsx from "clsx";

import { GlassFull } from "@chef/icons/small";
import { RecipeAndStepsQuery } from "@chef/state-management";

import { intl } from "./BeverageSuggestion.Intl";

interface BeverageSuggestionProps {
  beverageSuggestion: RecipeAndStepsQuery["recipeAndSteps"]["beverageSuggestion"];
  className?: string;
}

export const BeverageSuggestion = ({
  beverageSuggestion,
  className,
}: BeverageSuggestionProps) => {
  const [isShowing, setIsShowing] = useState(false);

  if (!beverageSuggestion) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        "py-2 text-sm rounded-md bg-informationBG text-secondary",
      )}
    >
      <div
        className={clsx(
          isShowing ? "gap-4 px-6 pt-4" : "gap-2 items-center justify-center",
          "flex",
        )}
      >
        <GlassFull
          className={clsx(
            isShowing ? "flex-shrink-0 h-6 mt-1.5" : "h-4",
            "w-4",
          )}
        />
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: beverageSuggestion,
            }}
            className={clsx(isShowing ? "block" : "hidden")}
          />
          <button
            className={clsx(isShowing && "py-4", "underline")}
            onClick={() => setIsShowing(!isShowing)}
          >
            <strong>
              {isShowing ? intl.HIDE_WINE_TIPS : intl.SHOW_WINE_TIPS}
            </strong>
          </button>
        </div>
      </div>
    </div>
  );
};
