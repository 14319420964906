import { DependencyList, useCallback, useRef } from "react";

/**
 * It takes in *any* fn and return a callback that will call the reference to fn.
 * This means that the returned callback's reference won't update, even when fn updates.
 * @param fn any function you want to have "up to date" regardless of what changes inside of it
 * @param deps when you want the reference to the callback to change
 * @returns memoized callback that will only ever change when deps change
 */
export const useCallbackRef = <T extends (...args: any[]) => ReturnType<T>>(
  fn: T,
  deps: DependencyList = [],
): T => {
  const ref = useRef(fn);
  ref.current = fn;

  return useCallback((...args: any[]) => {
    return ref.current(...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps) as T;
};
