import Mustache from "mustache";

interface MustacheMergeProps {
  mergeField?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mergeFieldData?: object;
}

const withBraces = (field: string) => "{{" + field + "}}";

export const MustacheMerge = ({
  mergeField = "",
  mergeFieldData = {},
}: MustacheMergeProps) => {
  const keys = mergeFieldData && Object.keys(mergeFieldData);
  const pipeKeys = keys?.join("|") || "";
  const regex = new RegExp(pipeKeys, "g");
  const textWithMustache = mergeField.toString().replace(regex, (match) => {
    return withBraces(match);
  });

  return Mustache.render(textWithMustache, mergeFieldData || {});
};
