import * as React from "react";
import { SVGProps } from "react";
const LightBulb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33399 5.41675C1.48267 5.41691 1.62567 5.47384 1.73379 5.57591C1.8419 5.67798 1.90696 5.81747 1.91567 5.9659C1.92439 6.11432 1.8761 6.26047 1.78067 6.37448C1.68524 6.4885 1.54988 6.56177 1.40224 6.57933L1.33399 6.58342H0.750656C0.601976 6.58325 0.45897 6.52632 0.350858 6.42425C0.242745 6.32219 0.177685 6.18269 0.168972 6.03427C0.160259 5.88584 0.208549 5.73969 0.303977 5.62568C0.399405 5.51167 0.534767 5.43839 0.682406 5.42083L0.750656 5.41675H1.33399Z"
      fill="#FFCB2E"
    />
    <path
      d="M6.00016 0.166748C6.14304 0.166767 6.28094 0.219222 6.38771 0.314165C6.49448 0.409108 6.5627 0.539935 6.57941 0.681831L6.5835 0.750081V1.33341C6.58333 1.48209 6.5264 1.6251 6.42433 1.73321C6.32227 1.84133 6.18277 1.90639 6.03435 1.9151C5.88592 1.92381 5.73977 1.87552 5.62576 1.78009C5.51175 1.68467 5.43847 1.5493 5.42091 1.40166L5.41683 1.33341V0.750081C5.41683 0.595372 5.47829 0.446999 5.58768 0.337602C5.69708 0.228206 5.84545 0.166748 6.00016 0.166748Z"
      fill="#FFCB2E"
    />
    <path
      d="M11.2507 5.41675C11.3993 5.41691 11.5423 5.47384 11.6505 5.57591C11.7586 5.67798 11.8236 5.81747 11.8323 5.9659C11.8411 6.11432 11.7928 6.26047 11.6973 6.37448C11.6019 6.4885 11.4665 6.56177 11.3189 6.57933L11.2507 6.58342H10.6673C10.5186 6.58325 10.3756 6.52632 10.2675 6.42425C10.1594 6.32219 10.0944 6.18269 10.0856 6.03427C10.0769 5.88584 10.1252 5.73969 10.2206 5.62568C10.3161 5.51167 10.4514 5.43839 10.5991 5.42083L10.6673 5.41675H11.2507Z"
      fill="#FFCB2E"
    />
    <path
      d="M1.85406 1.85431C1.95451 1.75387 2.08815 1.69354 2.22991 1.68462C2.37168 1.67571 2.51182 1.71883 2.62406 1.80589L2.67889 1.85431L3.08723 2.26264C3.19185 2.36762 3.25259 2.50848 3.25711 2.65662C3.26163 2.80475 3.2096 2.94906 3.11158 3.06022C3.01356 3.17138 2.87691 3.24107 2.72937 3.25512C2.58183 3.26918 2.43447 3.22655 2.31723 3.13589L2.26239 3.08747L1.85406 2.67914C1.7447 2.56975 1.68327 2.4214 1.68327 2.26672C1.68327 2.11205 1.7447 1.9637 1.85406 1.85431Z"
      fill="#FFCB2E"
    />
    <path
      d="M9.32113 1.85434C9.4261 1.74972 9.56696 1.68898 9.7151 1.68446C9.86324 1.67993 10.0075 1.73196 10.1187 1.82998C10.2299 1.928 10.2996 2.06466 10.3136 2.2122C10.3277 2.35974 10.285 2.50709 10.1944 2.62434L10.146 2.67917L9.73763 3.08751C9.63265 3.19213 9.49179 3.25287 9.34365 3.25739C9.19552 3.26191 9.05121 3.20988 8.94005 3.11186C8.82888 3.01384 8.7592 2.87718 8.74514 2.72965C8.73109 2.58211 8.77372 2.43475 8.86438 2.31751L8.91279 2.26267L9.32113 1.85434Z"
      fill="#FFCB2E"
    />
    <path
      d="M7.16683 9.50008C7.32154 9.50008 7.46991 9.56154 7.57931 9.67094C7.6887 9.78033 7.75016 9.92871 7.75016 10.0834C7.75016 10.5475 7.56579 10.9927 7.2376 11.3209C6.90941 11.649 6.46429 11.8334 6.00016 11.8334C5.53603 11.8334 5.09091 11.649 4.76273 11.3209C4.43454 10.9927 4.25016 10.5475 4.25016 10.0834C4.25018 9.94054 4.30264 9.80264 4.39758 9.69587C4.49252 9.5891 4.62335 9.52088 4.76525 9.50417L4.8335 9.50008H7.16683Z"
      fill="#FFCB2E"
    />
    <path
      d="M6.00016 2.50008C6.73475 2.50008 7.45072 2.73121 8.04665 3.16073C8.64258 3.59026 9.08826 4.19639 9.32055 4.89328C9.55285 5.59018 9.55999 6.34249 9.34096 7.04367C9.12193 7.74484 8.68783 8.35933 8.10016 8.80008C8.01976 8.86047 7.92512 8.89905 7.82541 8.91208L7.75016 8.91675H4.25016C4.12395 8.91675 4.00114 8.87581 3.90016 8.80008C3.31249 8.35933 2.8784 7.74484 2.65937 7.04367C2.44033 6.34249 2.44747 5.59018 2.67977 4.89328C2.91207 4.19639 3.35775 3.59026 3.95368 3.16073C4.54961 2.73121 5.26557 2.50008 6.00016 2.50008Z"
      fill="#FFCB2E"
    />
  </svg>
);
export default LightBulb;
