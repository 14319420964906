import { BRAND_PATHS } from "@chef/constants";
import { Facebook, Instagram } from "@chef/icons/small";

export const SoMe = () => {
  return (
    <div className="flex gap-2">
      <a
        href={BRAND_PATHS.FACEBOOK_URL}
        target="_blank"
        rel="noreferrer"
        aria-label="Facebook logo"
      >
        <Facebook className="w-6 h-6" />
      </a>

      <a
        href={BRAND_PATHS.INSTAGRAM_URL}
        target="_blank"
        rel="noreferrer"
        aria-label="Instagram logo"
      >
        <Instagram className="w-6 h-6" />
      </a>
    </div>
  );
};
