import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import { BRAND_NAME_FULL } from "@chef/constants";

import { AppShellContext } from "./AppShell";

export const Meta = () => {
  const router = useRouter();
  const { title, description, canonical, index, image, brandedTitle } =
    React.useContext(AppShellContext);

  let titleString: string;

  if (title) {
    if (brandedTitle) {
      titleString = `${title} - ${BRAND_NAME_FULL}`;
    } else {
      titleString = title;
    }
  } else {
    titleString = BRAND_NAME_FULL;
  }

  const isProduction = process.env.APP_ENV === "production";
  const shouldIndex = isProduction && index;

  return (
    <Head>
      <title>{titleString}</title>
      <meta name="description" content={description} />
      <link key="canonical" rel="canonical" href={canonical || router.asPath} />
      <meta
        name="robots"
        content={shouldIndex ? "index, follow" : "noindex, nofollow"}
        key="robots"
      />
      <meta
        name="title"
        property="og:title"
        content={titleString}
        key="og:title"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="description"
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta
        name="url"
        property="og:url"
        content={canonical || router.asPath}
        key="og:url"
      />
      <meta
        name="site_name"
        property="og:site_name"
        content={BRAND_NAME_FULL}
        key="og:site_name"
      />
      <meta name="type" property="og:type" content="website" key="og:type" />
      <meta
        name="og:image"
        property="og:image"
        content={image}
        key="og:image"
      />
    </Head>
  );
};
