import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      WE_NEED_YOUR_CONSENT_TO_STORE_INFORMATION_ABOUT_YOUR_PREFERENCES:
        "Vi trenger ditt samtykke for å kunne spare informasjon om dine preferanser. For eksempel hva du liker eller ikke vil/kan spise.",
      YES_ITS_FINE: "Det går fint!",
    },
    se: {
      WE_NEED_YOUR_CONSENT_TO_STORE_INFORMATION_ABOUT_YOUR_PREFERENCES:
        "Vi behöver ditt samtycke för att få spara information om dina preferenser. Till exempel vad du tycker om och vad du vill eller inte vill/kan äta.",
      YES_ITS_FINE: "Det går bra!",
    },
    dk: {
      WE_NEED_YOUR_CONSENT_TO_STORE_INFORMATION_ABOUT_YOUR_PREFERENCES:
        "Vi har brug for dit samtykke for at gemme oplysninger om dine præferencer. Det kan for eksempel være ting, du synes godt om eller ting, du ikke kan lide eller tåle.",
      YES_ITS_FINE: "Fint med mig!",
    },
  } as const
)[language];
