import React, { useReducer } from "react";
import clsx from "clsx";

import {
  Breakfast,
  Delivery,
  Dinner,
  Download,
  Help,
  ChevronDown,
  ChevronUp,
} from "@chef/icons/small";

import { Card, Hr, Skeleton, Currency } from "../components";
import { Language, PropsOf } from "../../types";

interface OwnReceiptBaseProps {
  language: Language;
  showHeader?: boolean;
  showDelivery?: boolean;
  showTotalSum?: boolean;
  mealboxItem: {
    name: string;
    meals: number;
    portions: number;
    price: number;
  };
  pickAndMixItems: {
    id: string | number;
    name: string;
    price: number;
  }[];
  standAloneItems: {
    id: string | number;
    quantity: number;
    name: string;
    price: number;
  }[];
  discounts: {
    id: string;
    name: string;
    amount: number;
    percent: boolean;
    permanent: boolean;
    added: string;
  }[];
  accountDiscounts: {
    id: string;
    name: string;
    amount: number;
    percent: boolean;
    permanent: boolean;
    added: string;
  }[];
  deliveryFee?: number;
  standAloneSum: number;
  totalDiscount: number;
  totalSum: number;
  isLoading?: boolean;
  deliveryStatus?: string;
  deliveryInfo?: string;
  onDownload?: () => void;
  className?: string;
}

type ReceiptProps = OwnReceiptBaseProps & PropsOf<typeof Card>;

const intl = {
  no: {
    RECEIPT: "Kvittering",
    DOWNLOAD_RECEIPT: "Last ned kvittering",
    DETAILED_OVERVIEW: "Detaljert oversikt",
    STANDALONE_ITEMS: "dagligvarer",
    DELIVERY: "Levering",
    DISCOUNT: "Rabatt",
    TOTAL_SUM: "Total sum",
    SHOW_MORE: "Vis mer",
    SHOW_LESS: "Vis mindre",
    DISCOUNTS_WHEN: "Når trekkes rabatten min?",
    DISCOUNTS_ACTIVE: "Dine aktive rabatter:",
    DISCOUNTS_ADDED: "Lagt til:",
    DISCOUNTS_ALWAYS: "Vi trekker alltid i fra dine faste rabatter:",
    DISCOUNTS_ADDENDUM:
      "Rabatten trekkes fra når ordren låses, tirsdag 23:59 før levering. Vi velger den siste rabatten du har lagt til hvis produktene du har valgt oppfyller kravene for rabatten.",
    DINNERS: "middager",
  },
  se: {
    RECEIPT: "Kvitto",
    DOWNLOAD_RECEIPT: "Ladda ner kvitto",
    DETAILED_OVERVIEW: "Detaljerad översikt",
    STANDALONE_ITEMS: "livsmedel",
    DELIVERY: "Leverans",
    DISCOUNT: "Rabatt",
    TOTAL_SUM: "Totalt",
    SHOW_MORE: "Visa mer",
    SHOW_LESS: "Visa mindre",
    DISCOUNTS_WHEN: "När dras min rabatt?",
    DISCOUNTS_ACTIVE: "Dina aktiva rabatter:",
    DISCOUNTS_ADDED: "Tillagd:",
    DISCOUNTS_ALWAYS: "Vi drar alltid dina fasta rabatter:",
    DISCOUNTS_ADDENDUM:
      "Rabatten dras av när beställningen är låst, tisdag 23:59 före leverans. Vi väljer den senaste rabatten du lagt till om de produkter du valt uppfyller kraven för rabatten.",
    DINNERS: "middagar",
  },
  dk: {
    RECEIPT: "Kvittering",
    DOWNLOAD_RECEIPT: "Download kvittering",
    DETAILED_OVERVIEW: "Detaljeret oversigt",
    STANDALONE_ITEMS: "mad",
    DELIVERY: "Fragt",
    DISCOUNT: "Rabat",
    TOTAL_SUM: "I alt",
    SHOW_MORE: "Vis mere",
    SHOW_LESS: "Vis mindre",
    DISCOUNTS_WHEN: "Hvornår trækkes min rabat?",
    DISCOUNTS_ACTIVE: "Dine aktive rabatter:",
    DISCOUNTS_ADDED: "Tilføjet:",
    DISCOUNTS_ALWAYS: "Vi trækker altid dine faste rabatter fra:",
    DISCOUNTS_ADDENDUM:
      "Rabatten fratrækkes når ordren er låst, onsdag 23:59 før levering. Vi vælger den seneste rabat du har tilføjet, hvis de produkter du har valgt opfylder kravene til rabatten.",
    DINNERS: "retter",
  },
};

const Receipt = ({
  language = "no",
  showHeader = true,
  showDelivery = true,
  showTotalSum = true,
  // should not be any, but don't want to change behavior
  mealboxItem = {} as any,
  pickAndMixItems = [],
  standAloneItems = [],
  discounts = [],
  accountDiscounts = [],
  deliveryFee = 0,
  standAloneSum = 0,
  totalDiscount = 0,
  totalSum = 0,
  isLoading = false,
  deliveryStatus,
  deliveryInfo,
  onDownload,
  className,
  ...props
}: ReceiptProps) => {
  const [standAloneItemsExpanded, toggleStandAloneItemsExpanded] = useReducer(
    (state) => !state,
    false,
  );

  const [discountsExpanded, toggleDiscountsExpanded] = useReducer(
    (state) => !state,
    false,
  );
  const [discountsInfoExpanded, toggleDiscountsInfoExpanded] = useReducer(
    (state) => !state,
    false,
  );

  const {
    RECEIPT,
    DOWNLOAD_RECEIPT,
    DETAILED_OVERVIEW,
    STANDALONE_ITEMS,
    DELIVERY,
    DISCOUNT,
    TOTAL_SUM,
    SHOW_MORE,
    SHOW_LESS,
    DISCOUNTS_WHEN,
    DISCOUNTS_ACTIVE,
    DISCOUNTS_ADDED,
    DISCOUNTS_ALWAYS,
    DISCOUNTS_ADDENDUM,
    DINNERS,
  } = intl[language];

  const hasStandAloneItems = standAloneItems.length !== 0;
  const hasDiscount = discounts.length !== 0;

  const showAccountDiscounts = accountDiscounts.length !== 0;

  const disposableAccountDiscounts = accountDiscounts.filter(
    (discount) => !discount.permanent,
  );
  const permanentAccountDiscounts = accountDiscounts.filter(
    (discount) => discount.permanent,
  );

  const hasDisposableAccountDiscounts = disposableAccountDiscounts.length !== 0;
  const hasPermanentAccountDiscounts = permanentAccountDiscounts.length !== 0;

  if (isLoading) {
    return (
      <Card
        className={clsx("w-full md:max-w-md md:mx-auto text-base", className)}
        aria-busy="true"
        {...props}
      >
        <div className="space-y-3">
          <Skeleton width={48} height={8} />
          <Skeleton width={48} />
          <Skeleton width={64} height={12} />
          <Skeleton width={48} />
          <Skeleton height={0.5} />
          <div className="flex justify-between">
            <Skeleton width={36} />
            <Skeleton width={16} />
          </div>
          <div className="flex justify-between">
            <Skeleton width={64} />
            <Skeleton width={12} />
          </div>
          <div className="flex justify-between">
            <Skeleton width={64} />
            <Skeleton width={12} />
          </div>
          <div className="flex justify-between">
            <Skeleton width={64} />
            <Skeleton width={12} />
          </div>
          <Skeleton height={0.5} />
          <div className="flex justify-between">
            <Skeleton width={36} />
            <Skeleton width={16} />
          </div>
          <Skeleton height={0.5} />
          <div className="flex justify-between">
            <Skeleton width={36} />
            <Skeleton width={16} />
          </div>
          <Skeleton height={0.5} />
          <div className="flex justify-between">
            <Skeleton width={36} />
            <Skeleton width={16} />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card
      className={clsx("w-full md:max-w-md md:mx-auto text-base", className)}
      {...props}
    >
      {showHeader && (
        <h3 className="mb-5">
          <strong>{deliveryStatus ? deliveryStatus : RECEIPT}</strong>
        </h3>
      )}
      {deliveryInfo && (
        <div className="mb-5 -mt-4 first-letter:capitalize">{deliveryInfo}</div>
      )}
      {onDownload && (
        <button onClick={onDownload}>
          <div className="inline-flex items-center justify-center w-10 h-10 mb-5 mr-2 rounded-full bg-grey-2">
            <Download />
          </div>
          <span>{DOWNLOAD_RECEIPT}</span>
        </button>
      )}
      {deliveryInfo && (
        <div className="mb-2">
          <strong>{DETAILED_OVERVIEW}</strong>
        </div>
      )}
      {showHeader && <Hr className="mb-2" />}
      <div className="flex items-center mb-2">
        <Dinner
          aria-hidden="true"
          className="flex-shrink text-xl ml-1 mt-1 mr-1.5"
        />
        <div className="inline-flex justify-between flex-1">
          <span className="flex-shrink" title={mealboxItem.name}>
            {mealboxItem.meals} {DINNERS}, {mealboxItem.portions} pers
          </span>
          <span className="flex-auto w-20 text-right">
            <Currency language={language}>{mealboxItem.price}</Currency>
          </span>
        </div>
      </div>
      {(mealboxItem.name || pickAndMixItems.length !== 0) && (
        <div className="mb-4 space-y-2">
          {mealboxItem.name && <div>{mealboxItem.name}</div>}
          {pickAndMixItems.map(({ id, name, price }) => (
            <div key={id} className="flex justify-between">
              <span className="w-4/5 truncate" title={name}>
                {name}
              </span>
              <span className="w-20 text-right">
                <Currency language={language} prefix="+" hideIfZero>
                  {price}
                </Currency>
              </span>
            </div>
          ))}
        </div>
      )}
      <Hr className="mb-2" />
      {hasStandAloneItems && (
        <>
          <div className="flex items-center mb-2">
            <Breakfast
              aria-hidden="true"
              className="flex-shrink mt-1 ml-1 mr-2 text-lg"
            />
            <div className="inline-flex justify-between flex-1">
              <button
                id="toggleStandAloneItems"
                aria-label={standAloneItemsExpanded ? SHOW_LESS : SHOW_MORE}
                aria-expanded={standAloneItemsExpanded}
                aria-controls="standAloneItems"
                onClick={toggleStandAloneItemsExpanded}
              >
                {standAloneItems.reduce(
                  (acc, { quantity }) => acc + quantity,
                  0,
                )}{" "}
                {STANDALONE_ITEMS}{" "}
                {standAloneItemsExpanded ? (
                  <ChevronUp className="mb-1 ml-2 text-sm" />
                ) : (
                  <ChevronDown className="mb-1 ml-2 text-sm" />
                )}
              </button>
              <span>
                <Currency language={language}>{standAloneSum}</Currency>
              </span>
            </div>
          </div>
          <div
            role="region"
            id="standAloneItems"
            aria-labelledby="toggleStandAloneItems"
            className={clsx(
              !standAloneItemsExpanded && "hidden",
              "space-y-2 mb-4",
            )}
          >
            {standAloneItems.map(({ id, quantity, name, price }) => (
              <div key={id} className="flex justify-between ml-8">
                <span className="w-4/5 truncate">
                  {quantity} &times; {name}
                </span>
                <span className="w-20 text-right">
                  <Currency language={language}>{price}</Currency>
                </span>
              </div>
            ))}
          </div>
          <Hr className="mb-2" />
        </>
      )}
      {showDelivery && (
        <>
          <div className="flex items-center mb-2">
            <Delivery
              aria-hidden="true"
              className="flex-shrink mt-1 ml-1 mr-2 text-lg"
            />
            <div className="inline-flex justify-between flex-1">
              <span>{DELIVERY}</span>
              <span className={clsx(!deliveryFee && "text-discount")}>
                <Currency language={language}>{deliveryFee}</Currency>
              </span>
            </div>
          </div>
          <Hr className="mb-2" />
        </>
      )}
      {hasDiscount && (
        <>
          <div className="flex items-center justify-between mb-2">
            <button
              id="toggleDiscounts"
              className="flex items-center"
              aria-label={discountsExpanded ? SHOW_LESS : SHOW_MORE}
              aria-expanded={discountsExpanded}
              aria-controls="discounts"
              onClick={toggleDiscountsExpanded}
            >
              {DISCOUNT}{" "}
              {discountsExpanded ? (
                <ChevronUp className="mb-1 ml-2 text-sm" />
              ) : (
                <ChevronDown className="mb-1 ml-2 text-sm" />
              )}
            </button>
            <span className="text-discount">
              <Currency language={language}>{totalDiscount}</Currency>
            </span>
          </div>
          <div
            role="region"
            id="discounts"
            aria-labelledby="toggleDiscounts"
            className={clsx(!discountsExpanded && "hidden", "space-y-2 mb-2")}
          >
            {discounts.map(({ id, name, amount }) => (
              <div key={id} className="flex justify-between">
                <span className="w-4/5 truncate">{name}</span>
                <span className="w-20 text-right text-discount">
                  <Currency language={language}>{amount}</Currency>
                </span>
              </div>
            ))}
          </div>
          <Hr className="mb-2" />
        </>
      )}
      {showTotalSum && (
        <strong className="flex items-center justify-between mb-5">
          {TOTAL_SUM}
          <Currency language={language}>{totalSum}</Currency>
        </strong>
      )}
      {showAccountDiscounts && (
        <>
          <button
            id="toggleDiscountsInfo"
            aria-label={discountsInfoExpanded ? SHOW_LESS : SHOW_MORE}
            aria-expanded={discountsInfoExpanded}
            aria-controls="discountsInfo"
            onClick={toggleDiscountsInfoExpanded}
            className="flex items-center w-full my-5"
          >
            <Help className="mr-2 text-lg text-primary" />
            <span className="inline-flex items-center justify-between flex-grow">
              <span className="flex-grow text-sm text-left">
                {DISCOUNTS_WHEN}
              </span>
              {discountsInfoExpanded ? (
                <ChevronUp className="mb-1 ml-2 text-sm" />
              ) : (
                <ChevronDown className="mb-1 ml-2 text-sm" />
              )}
            </span>
          </button>
          <div
            role="region"
            id="discountsInfo"
            aria-labelledby="toggleDiscountsInfo"
            className={clsx(
              !discountsInfoExpanded && "hidden",
              "p-4 text-sm bg-grey-2",
            )}
          >
            {hasDisposableAccountDiscounts && (
              <>
                <div className="mb-2">{DISCOUNTS_ACTIVE}</div>
                {disposableAccountDiscounts.map((discount) => (
                  <div key={discount.id}>
                    <Hr className="mb-2" />
                    <div className="mb-2">
                      <strong className="flex justify-between">
                        <span>{discount.name}</span>
                        <span>
                          -
                          {discount.percent ? (
                            `${discount.amount}%`
                          ) : (
                            <Currency language={language}>
                              {discount.amount}
                            </Currency>
                          )}
                        </span>
                      </strong>
                      <div>
                        {DISCOUNTS_ADDED} {discount.added}
                      </div>
                    </div>
                  </div>
                ))}
                <Hr className="mb-7" />
              </>
            )}
            {hasPermanentAccountDiscounts && (
              <>
                <div className="mb-2">{DISCOUNTS_ALWAYS}</div>
                {permanentAccountDiscounts.map((discount) => (
                  <div key={discount.id}>
                    <Hr className="mb-2" />
                    <div className="mb-2">
                      <strong className="flex justify-between">
                        <span>{discount.name}</span>
                        <span>
                          -
                          {discount.percent ? (
                            `${discount.amount}%`
                          ) : (
                            <Currency language={language}>
                              {discount.amount}
                            </Currency>
                          )}
                        </span>
                      </strong>
                      <div>
                        {DISCOUNTS_ADDED} {discount.added}
                      </div>
                    </div>
                  </div>
                ))}
                <Hr className="mb-2" />
              </>
            )}
            <div>{DISCOUNTS_ADDENDUM}</div>
          </div>
        </>
      )}
    </Card>
  );
};

export default Receipt;
