import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { language } from "@chef/constants";

import { billingApi, showNotification } from "../features";
import { showErrorNotification } from "./util";

const intl = (
  {
    no: {
      DELIVERIES_RESUMED: "Leveranser gjenopptatt",
    },

    se: {
      DELIVERIES_RESUMED: "Leveranser återupptogs",
    },

    dk: {
      DELIVERIES_RESUMED: "Leverancer genoptaget",
    },
  } as const
)[language];

export default (listener: ListenerMiddlewareInstance) => {
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.createOrUpdateMultipleSchedulers.matchFulfilled,
      billingApi.endpoints.createOrUpdateMultipleSchedulers.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        showErrorNotification(action);
      }

      if (action["meta"].requestStatus === "fulfilled") {
        const originalArgs = action["meta"].arg.originalArgs;

        const anyPaused = originalArgs.schedulers?.some(
          ({ delivery }: any) => delivery === false,
        );

        if (anyPaused === false && !originalArgs._suppressNotifications) {
          showNotification({
            type: "success",
            message: intl.DELIVERIES_RESUMED,
          });
        }
      }
    },
  });
};
