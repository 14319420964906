type Precision = "s" | "m" | "h";

export const formatTimeInterval = (
  from = "",
  to = "",
  precision: Precision = "h",
  divider = " - ",
) => {
  const regex = /[0-9]{2}:[0-9]{2}:[0-9]{2}/;

  if (!regex.test(from) || !regex.test(to)) {
    return "";
  }

  const froms = from.split(":");
  const tos = to.split(":");

  const [from_h, from_m, from_s] = froms;
  const [to_h, to_m, to_s] = tos;

  if (precision === "s") {
    if (from_h === to_h && from_m === to_m && from_s === to_s) {
      return `${from_h}:${from_m}:${from_s}`;
    }

    return `${from_h}:${from_m}:${from_s}${divider}${to_h}:${to_m}:${to_s}`;
  }

  if (precision === "m") {
    if (from_h === to_h && from_m === to_m) {
      return `${from_h}:${from_m}`;
    }

    return `${from_h}:${from_m}${divider}${to_h}:${to_m}`;
  }

  if (from_h === to_h) {
    return from_h;
  }

  return `${from_h}${divider}${to_h}`;
};
