import * as React from "react";
import { SVGProps } from "react";
const Mastercard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.999999C0 0.447714 0.447715 0 1 0H39C39.5523 0 40 0.447715 40 1V39C40 39.5523 39.5523 40 39 40H0.999999C0.447714 40 0 39.5523 0 39V0.999999Z"
      fill="#F4F4F1"
    />
    <path
      d="M19.3564 21.7831C19.3564 21.6476 19.4689 21.5376 19.6073 21.5376C19.7457 21.5376 19.8582 21.6476 19.8582 21.7831C19.8582 21.9185 19.7457 22.0285 19.6073 22.0285C19.4689 22.0313 19.3564 21.9185 19.3564 21.7831ZM19.6073 21.9721C19.7111 21.9721 19.7977 21.8875 19.7977 21.7859C19.7977 21.6815 19.7111 21.5997 19.6073 21.5997C19.5006 21.5997 19.417 21.6815 19.417 21.7859C19.417 21.8875 19.5006 21.9721 19.6073 21.9721ZM19.5727 21.8903H19.5208V21.6758H19.6131L19.6708 21.6871L19.6996 21.7407L19.6621 21.7972L19.7025 21.8931H19.6448L19.6102 21.8085H19.5727V21.8903ZM19.5727 21.769H19.6016L19.6333 21.7633L19.6477 21.7379L19.6362 21.7153L19.6044 21.7125H19.5785V21.769H19.5727Z"
      fill="white"
    />
    <path
      d="M22.7476 14.5015H16.4697V25.5388H22.7476V14.5015Z"
      fill="#F26122"
    />
    <path
      d="M16.8676 20.0213C16.8647 17.8686 17.8769 15.8315 19.6071 14.5026C16.4927 12.1072 11.9825 12.6377 9.53421 15.6848C7.08591 18.7319 7.62517 23.1446 10.7425 25.54C13.3436 27.5403 17.006 27.5403 19.6071 25.54C17.874 24.2111 16.8647 22.1768 16.8676 20.0213Z"
      fill="#EA1D25"
    />
    <path
      d="M31.2145 20.0199C31.2145 23.8966 28.002 27.0396 24.0397 27.0396C22.4306 27.0396 20.8705 26.512 19.6074 25.5386C22.7219 23.1432 23.264 18.7306 20.8157 15.6834C20.4639 15.2433 20.0573 14.8483 19.6074 14.5013C22.7219 12.1059 27.232 12.6335 29.6803 15.6806C30.6752 16.9192 31.2145 18.4456 31.2145 20.0199Z"
      fill="#F69E1E"
    />
  </svg>
);
export default Mastercard;
