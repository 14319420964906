/**
 *
 * @param condition If falsy, throw an error.
 * @param message Error to throw
 * @description Throws an error if condition is falsy.
 */
export function invariant(
  condition: unknown,
  message: string,
): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
