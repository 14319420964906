import { Rating as SimpleRating } from "react-simple-star-rating";

import { PropsOf } from "../../../types";

const NUM_OPTIONS = 5;

interface RatingProps {
  readOnly?: boolean;
  value?: number;
  defaultValue?: number;
  numOptions?: number;
  size?: number;
  separator?: string;
  onChange?: PropsOf<typeof SimpleRating>["onClick"];
  className?: string;
  id?: string;
}

export const Rating = ({
  readOnly,
  value,
  size = 24,
  defaultValue = 0,
  onChange = () => null,
  numOptions = NUM_OPTIONS,
  separator = "",
  className,
  id,
}: RatingProps) => {
  return (
    <div id={id}>
      <SimpleRating
        size={size}
        onClick={onChange}
        readonly={readOnly}
        allowFraction={readOnly}
        iconsCount={numOptions}
        initialValue={value ?? defaultValue}
        titleSeparator={separator.trim()}
        className={className}
        fillClassName="text-highlight"
        emptyClassName="text-grey-2"
      />
    </div>
  );
};
