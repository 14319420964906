import { PREFERENCE_IDS, PREFERENCE_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { isNotEmptyArray } from "@chef/utils/array";

import { getAttribute } from "../helpers";
import { usePreferencesQuery } from "../graphql/generated";

export const usePreferences = () => {
  const { data: preferencesQuery, isLoading } = usePreferencesQuery();

  const conceptPreferences = preferencesQuery?.preferences
    .filter(
      (p) =>
        isEqualStrings(p.preferenceTypeId, PREFERENCE_TYPE_IDS.CONCEPT) &&
        !isEqualStrings(p.preferenceId, PREFERENCE_IDS.SINGEL_ONESUB),
    )
    .filter((p) => getAttribute("is_fixed", p) === "False")
    .filter((p) => getAttribute("linked_mealbox_id", p) === "0");

  const tastePreferences = preferencesQuery?.preferences.filter((p) =>
    isEqualStrings(p.preferenceTypeId, PREFERENCE_TYPE_IDS.TASTE),
  );

  const fixedPreferences = preferencesQuery?.preferences
    .filter(
      (p) =>
        isEqualStrings(p.preferenceTypeId, PREFERENCE_TYPE_IDS.CONCEPT) &&
        !isEqualStrings(p.preferenceId, PREFERENCE_IDS.SINGEL_ONESUB),
    )
    .filter((p) => getAttribute("is_fixed", p) === "True")
    .filter((p) => getAttribute("linked_mealbox_id", p) === "0");

  const fallbackPreferenceId = conceptPreferences?.find(
    (p) => getAttribute("is_fallback", p) === "True",
  )?.preferenceId;

  const getSelectedPreferenceDetails = (args: {
    selectedPreferenceIds: string[] | undefined;
  }) => {
    const { selectedPreferenceIds } = args;

    const selectedConceptPreferences = preferencesQuery?.preferences.filter(
      (p) => selectedPreferenceIds?.includes(p.preferenceId),
    );

    const selectedFixedPreferenceIds = selectedConceptPreferences
      ?.filter((p) =>
        fixedPreferences?.some((fp) =>
          isEqualStrings(fp.preferenceId, p.preferenceId),
        ),
      )
      .map((p) => p.preferenceId);

    const hasFixedPreferences = selectedFixedPreferenceIds
      ? isNotEmptyArray(selectedFixedPreferenceIds)
      : false;

    const disableConceptPreferences = hasFixedPreferences;

    const canSeeTastePreferences = !(
      selectedConceptPreferences?.some(
        (p) => getAttribute("can_see_taste_preferences", p) === "False",
      ) || hasFixedPreferences
    );

    return {
      selectedConceptPreferences,
      selectedFixedPreferenceIds,
      disableConceptPreferences,
      canSeeTastePreferences,
    };
  };

  return {
    conceptPreferences: conceptPreferences ?? [],
    tastePreferences: tastePreferences ?? [],
    fixedPreferences: fixedPreferences ?? [],
    fallbackPreferenceId: fallbackPreferenceId ?? "",
    isPreferencesLoading: !preferencesQuery || isLoading,
    getSelectedPreferenceDetails,
  };
};
