import * as React from "react";
import { SVGProps } from "react";
const Checked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2439_2470)">
      <path
        d="M5 9.39985L7.66667 12.1999L13 6.59985"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <circle cx={9} cy={9} r={8.25} stroke="currentColor" strokeWidth={1.5} />
    </g>
    <defs>
      <clipPath id="clip0_2439_2470">
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Checked;
