import { api, tags } from "../../graphql/api";
import {
  RecipesByProductIdListQuery,
  RecipesByProductIdListQueryVariables,
} from "../../graphql/generated";
import { getAgreementId } from "../../graphql/user";
import {
  IDeleteCommentRecipeArgs,
  IFavoriteRecipeArgs,
  IRateAndCommentRecipeArgs,
  IUnfavoriteRecipeArgs,
} from "./types";

export const recipeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    multipleRecipesByProductIdList: builder.query<
      RecipesByProductIdListQuery[],
      RecipesByProductIdListQueryVariables[]
    >({
      queryFn: async (argsArray, { dispatch }) => {
        const queries: any[] = [];
        for (const args of argsArray) {
          // dispatch and use the RTK recipesByProductIdList graphql query
          // doing it this way, allows RTK to handle caching and we don't need to worry about that.
          const result = dispatch(
            api.endpoints.recipesByProductIdList.initiate(args),
          );

          queries.push(result);

          // clean up RTK so it can be removed from cache -- will get removed once stale
          result.unsubscribe();
        }

        const data: RecipesByProductIdListQuery[] = [];
        const error: any[] = [];

        // handle the result from the queries started above
        const queryResults = await Promise.all(queries);
        for (const result of queryResults) {
          if (result.isSuccess) {
            data.push(result.data);
          }

          if (result.isError) {
            error.push(result.error);
          }
        }

        if (error.length) {
          return { error };
        }

        return { data };
      },
    }),

    rateAndCommentRecipe: builder.mutation<null, IRateAndCommentRecipeArgs>({
      invalidatesTags: [tags.recipe],
      query: (args) => ({
        url: "/recipe/rateAndCommentRecipe",
        method: "POST",
        body: args,
      }),
    }),

    deleteCommentFromRecipe: builder.mutation<null, IDeleteCommentRecipeArgs>({
      invalidatesTags: [tags.recipe],
      query: (args) => ({
        url: "/recipe/deleteCommentFromRecipe",
        method: "POST",
        body: { Agreement: getAgreementId(), ...args },
      }),
    }),

    favoriteRecipe: builder.mutation<null, IFavoriteRecipeArgs>({
      invalidatesTags: [tags.favorites],
      query: ({ mainRecipeId: _, ...args }) => ({
        url: "/recipe/saveRecipeToFavorites",
        method: "POST",
        body: args,
      }),
      // Optimistic update
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          api.util.updateQueryData(
            "recipesAgreementFavorites",
            { pageNumber: 1, resultsPerPage: 10_000 },
            (draft) => {
              const recipeFavorites =
                draft?.recipesAgreementFavorites.recipeFavorites || [];

              const isFavorited = recipeFavorites.some(
                (fav) =>
                  fav.mainRecipeId === args.mainRecipeId ||
                  fav.recipeId === args.recipeId,
              );

              if (!isFavorited) {
                recipeFavorites.push({
                  recipeId: args.recipeId,
                  mainRecipeId: args.mainRecipeId!,
                  createdAt: new Date().toISOString(),
                  typeId: args.recipeFavoriteTypeId,
                  typeName: "undefined",
                });
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          result.undo();
        }
      },
    }),

    unfavoriteRecipe: builder.mutation<null, IUnfavoriteRecipeArgs>({
      invalidatesTags: [tags.favorites],
      query: ({ mainRecipeId: _, ...args }) => ({
        url: "/recipe/deleteRecipeFromFavorites",
        method: "POST",
        body: args,
      }),
      // Optimistic update
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          api.util.updateQueryData(
            "recipesAgreementFavorites",
            { pageNumber: 1, resultsPerPage: 10_000 },
            (draft) => {
              const recipeFavorites =
                draft?.recipesAgreementFavorites.recipeFavorites || [];

              const index = recipeFavorites.findIndex(
                (fav) =>
                  fav.mainRecipeId === args.mainRecipeId ||
                  fav.recipeId === args.recipeId,
              );

              if (index === -1) {
                return;
              }

              recipeFavorites.splice(index, 1);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          result.undo();
        }
      },
    }),
  }),
});

export const {
  useMultipleRecipesByProductIdListQuery,
  useLazyMultipleRecipesByProductIdListQuery,
  useRateAndCommentRecipeMutation,
  useDeleteCommentFromRecipeMutation,
  useFavoriteRecipeMutation,
  useUnfavoriteRecipeMutation,
} = recipeApi;

export * from "./utils";
export * from "./types";
