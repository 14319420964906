import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      NUMBER_OF_PORTIONS: "Antall personer",
      PORTIONS: "pers",
      NUMBER_OF_PORTIONS_DESCRIPTION: "",
      NUMBER_OF_MEALS: "Antall retter per uke",
      MEALS: "middager",
      NUMBER_OF_MEALS_DESCRIPTION:
        "Du kan endre størrelsen på matkassen din uke for uke.",
      MEALKIT_SUBSCRIPTION: "Ditt abonnement",
      FOR: "for",
      PEOPLE_PER_WEEK: "personer per uke",
      TOTAL_PORTIONS: "porsjoner totalt",
      REGULAR_PRICE: "Ordinær pris",
      PRICE_PER_PORTION: "Pris per porsjon",
      DELIVERY: "Levering",
      TOTAL_PRICE: "Totalprisen er",
      BEFORE_DISCOUNTS: "før eventuelle rabatter",
      DEDUCTED_YOU_CAN_SEE_DISCOUNTS_ON_ACCOUNT:
        "er trukket. Du kan se dine rabatter under dine kontoinnstillinger.",
      TOTAL: "Totalt",
    },
    se: {
      NUMBER_OF_PORTIONS: "Antal personer",
      PORTIONS: "pers",
      NUMBER_OF_PORTIONS_DESCRIPTION: "",
      NUMBER_OF_MEALS: "Antal rätter per vecka",
      MEALS: "middagar",
      NUMBER_OF_MEALS_DESCRIPTION:
        "Du kan ändra storleken på din matkasse vecka för vecka.",
      MEALKIT_SUBSCRIPTION: "Din prenumeration",
      FOR: "for",
      PEOPLE_PER_WEEK: "personer per vecka",
      TOTAL_PORTIONS: "portioner totalt",
      REGULAR_PRICE: "Ordinarie pris",
      PRICE_PER_PORTION: "Pris pr. portion",
      DELIVERY: "Leverans",
      TOTAL_PRICE: "Totalpriset visas utan avdrag",
      BEFORE_DISCOUNTS: "för eventuella rabatter.",
      DEDUCTED_YOU_CAN_SEE_DISCOUNTS_ON_ACCOUNT:
        "Du kan se dina rabatter under dina kontoinställningar.",
      TOTAL: "Totalt",
    },
    dk: {
      NUMBER_OF_PORTIONS: "Antal personer",
      PORTIONS: "pers",
      NUMBER_OF_PORTIONS_DESCRIPTION: "",
      NUMBER_OF_MEALS: "Antal retter per uge",
      MEALS: "retter",
      NUMBER_OF_MEALS_DESCRIPTION:
        "Du kan ændre størrelsen på din måltidskasse uge for uge.",
      MEALKIT_SUBSCRIPTION: "Dit abonnement",
      FOR: "for",
      PEOPLE_PER_WEEK: "personer per uge",
      TOTAL_PORTIONS: "portioner i alt",
      REGULAR_PRICE: "Normalpris",
      PRICE_PER_PORTION: "Pris per portion",
      DELIVERY: "Levering",
      TOTAL_PRICE: "Totalprisen er",
      BEFORE_DISCOUNTS: "før eventuelle rabatter",
      DEDUCTED_YOU_CAN_SEE_DISCOUNTS_ON_ACCOUNT:
        "er fratrukket. Du kan se dine rabatter under dine kontoindstillinger.",
      TOTAL: "Totalt",
    },
  } as const
)[language];
