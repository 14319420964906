import { Paused } from "@chef/icons/symbol";
import { Language } from "../../types";

import { Button, Card, Modal } from "../components";

interface SubscriptionCancelModalProps {
  isShowing: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  language: Language;
}

const SubscriptionCancelModal = ({
  isShowing,
  handleCancel,
  handleConfirm,
  language,
}: SubscriptionCancelModalProps) => {
  const {
    DO_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION,
    CANCELLATION_CONFIRMATION,
    CONFIRM,
    CANCEL,
  } = intl[language];

  return (
    <Modal
      outerClassName="max-w-2xl h-screen flex items-center justify-center mx-auto p-6"
      show={isShowing}
      showClose={false}
      onClose={handleCancel}
    >
      <Card
        noPadding
        className="flex flex-col items-center p-6 text-center md:p-18"
      >
        <Paused className="w-40 h-40 mb-6 md:w-20 md:h-20" />
        <h1 className="mb-2 text-xl md:text-2xl">
          <strong>{DO_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION}</strong>
        </h1>

        <p className="mb-8">{CANCELLATION_CONFIRMATION}</p>

        <Button onClick={handleConfirm} primary className="w-64 mb-3">
          {CONFIRM}
        </Button>
        <Button onClick={handleCancel} outlined className="w-64">
          {CANCEL}
        </Button>
      </Card>
    </Modal>
  );
};

const intl = {
  no: {
    DO_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION: "Vil du si opp abonnementet ditt?",
    CANCELLATION_CONFIRMATION:
      "Det ser ut til at du ikke vil ha flere leveranser. Nedenfor kan du si opp abonnementet ditt og du er selvfølgelig velkommen tilbake når som helst!",
    CONFIRM: "Si opp abonnementet",
    CANCEL: "Nei takk",
  },
  se: {
    DO_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION: "Vill du avsluta ditt abonnemang?",
    CANCELLATION_CONFIRMATION:
      "Det verkar som att du inte vill ha flera leveranser. Nedan kan du avsluta ditt abonnemang och du är självklart välkommen åter när som helst!",
    CONFIRM: "Avsluta mitt abonnemang",
    CANCEL: "Nej tack",
  },
  dk: {
    DO_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION: "Vil du opsige dit abonnement?",
    CANCELLATION_CONFIRMATION:
      "Det ser ud til, at du ikke ønsker flere leveringer. Nedenfor kan du opsige dit abonnement. Du er altid velkommen til at genaktivere dit abonnement på et senere tidspunkt, hvis du igen ønsker at få sund og varieret hverdagsmad leveret til døren!",
    CONFIRM: "Opsig abonnement",
    CANCEL: "Nej tak",
  },
} as const;

export default SubscriptionCancelModal;
