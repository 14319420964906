/**
 * Format number to currency (generalized)
 * @param number Number to format
 * @returns Formatted currency as a string
 */

const formatCurrency = (number = 0): string => {
  let parsed = Math.round(number * 100) / 100;

  if (parsed % 1 !== 0) {
    parsed = parseFloat(parsed.toFixed(2));
  }

  return parsed.toString();
};

export { formatCurrency };
