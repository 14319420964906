import { BRAND_NAME } from "./misc";

export const LINKS = (
  {
    AMK: {
      REFERRAL_LINK: (referralCode: string | undefined) =>
        `https://adamsmatkasse.no/vervenvenn/?rk=${referralCode}`,
      AGREEMENT_LINK: "https://adamsmatkasse.no/vilkar",
    },
    GL: {
      REFERRAL_LINK: (referralCode: string | undefined) =>
        `https://godtlevert.no/verv-en-venn/?rk=${referralCode}`,
      AGREEMENT_LINK: "https://godtlevert.no/vilkar",
    },
    LMK: {
      REFERRAL_LINK: (referralCode: string | undefined) =>
        `https://linasmatkasse.se/tipsa-en-van?rk=${referralCode}`,
      AGREEMENT_LINK: "https://linasmatkasse.se/villkor",
    },
    RN: {
      REFERRAL_LINK: (referralCode: string | undefined) =>
        `https://www.retnemt.dk/anbefal-til-andre/?rk=${referralCode}`,
      AGREEMENT_LINK: "https://www.retnemt.dk/online-handelsbetingelser",
    },
  } as const
)[BRAND_NAME];
