import { useEffect, useState } from "react";

/**
 * Returns a value after a certain amount of time
 * @param initialValue Initial value.
 * @param newValue Value to set after the timeout. Can be a new value or a function that receives the current value.
 * @param ms Timeout in milliseconds.
 */
export const useTimeout = <T>(
  initialValue: T,
  newValue: T | ((v: T) => T),
  ms: number,
) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => {
      setValue(newValue instanceof Function ? newValue(value) : newValue);
    }, ms);

    return () => clearTimeout(timer);
  }, [value, ms, newValue]);

  return value;
};
