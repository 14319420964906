import {
  PaymentAction,
  PaymentMethodOptions,
  PaymentMethods,
} from "@adyen/adyen-web/dist/types/types";

import { companyId, PAYMENT_PARTNER_IDS } from "@chef/constants";

import { api, tags } from "../graphql/api";

export interface IPaymentBasket {
  taxPercentage: number;
  quantity: number;
  description: string;
  amountIncludingTax: number;
  productUrl?: string;
  imageUrl?: string;
}

export interface IStartExternalFlow {
  groups: [
    {
      name: string;
      types: [
        {
          [key: number]: string;
        },
      ];
    },
  ];
  originKey: string;
  paymentMethods: PaymentMethodOptions<keyof PaymentMethods>[];
}

export type ADYEN_API_ROUTES = "Billing" | "DirectOrder" | "Registration";

export interface IStartExternalFlowArgs {
  apiRouteType: ADYEN_API_ROUTES;

  applicantId?: string;
  paymentPartnerId: string;
  orderTypeId: string;
  data: {
    origin: string;
  };
  redirectUrl: string; // empty = backend using default for type of request
  basket?: IPaymentBasket[];
}

export interface ISubmitExternalFlow {
  resultCode: string;
  refusalReasonCode?: string;
  refusalReason?: string;

  authentication: unknown;
  details: unknown[];
  paymentData: string;
  action?: PaymentAction;
}

export interface IAdyenBrowserInfo {
  acceptHeader: string;
  screenWdith: string | number;
  screenHeight: string | number;
  colorDepth: string | number;
  userAgent: string;
  timeZoneOffset: number;
  language: string;
  javaEnabled: boolean;
}

// https://github.com/Adyen/adyen-dotnet-api-library/blob/develop/Adyen/Model/Checkout/DefaultPaymentMethodDetails.cs#L87
// https://docs.adyen.com/online-payments/web-drop-in/advanced-use-cases/update-payment-amount -- see paymentMethod
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAdyenDefaultPaymentMethodDetails {}

export interface ISubmitExternalFlowArgs {
  apiRouteType: ADYEN_API_ROUTES;

  applicantId?: string;
  paymentPartnerId: string;
  orderTypeId: string;
  data: {
    origin: string;

    // not used in direct order -- optional defaults to false
    isApp?: boolean;

    // used in direct order (SubmitDirectPaymentInfo)
    id?: string;
  } & IAdyenBrowserInfo &
    IAdyenDefaultPaymentMethodDetails;
  redirectUrl: string;
  basket?: IPaymentBasket[];
}

export interface ISubmitExternalFlow3D {
  resultCode: string;
  refusalReasonCode?: string;
  refusalReason?: string;

  // initial submit3D (3ds2)
  authentication?: unknown;
  details?: unknown[];
  paymentData?: string;
  action?: PaymentAction;

  // second submit3D (3ds2)
  additionalData?: unknown;
  merchantReference?: string;
  pspReference?: string;
}

export interface ISubmitExternalFlow3DArgs {
  apiRouteType: ADYEN_API_ROUTES;

  applicantId?: string;
  paymentPartnerId: string;
  orderTypeId: string;
  // https://github.com/Adyen/adyen-dotnet-api-library/blob/develop/Adyen/Model/Checkout/PaymentsDetailsRequest.cs
  // data = state.data from onAdditionalDetails
  data: {
    // used in direct order (SubmitDirectPaymentInfo)
    id?: string;
  };
  redirectUrl: string;
  basket?: IPaymentBasket[];
}

export const paymentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changePaymentToInvoice: builder.mutation<
      Record<string, unknown>,
      // We make ssn optional here because on LMK ssn is already stored in the backend
      { ssn?: string }
    >({
      query: ({ ssn }) => {
        return {
          url: "/payment/changePaymentToInvoice",
          method: "POST",
          body: {
            ssn: ssn ?? "198010011008",
            paymentPartnerId: PAYMENT_PARTNER_IDS.COLLECTOR,
          },
        };
      },
      invalidatesTags: [tags.billing],
    }),

    startExternalFlow: builder.mutation<
      IStartExternalFlow,
      IStartExternalFlowArgs
    >({
      query: (args) => {
        const { apiRouteType, ...rest } = args;

        return {
          url: `/payment/startExternalFlow?type=${apiRouteType}`,
          method: "POST",
          body: { companyId, ...rest },
        };
      },
    }),
    submitExternalFlow: builder.mutation<
      ISubmitExternalFlow,
      ISubmitExternalFlowArgs
    >({
      query: (args) => {
        const { apiRouteType, ...rest } = args;

        return {
          url: `/payment/submitExternalFlow?type=${apiRouteType}`,
          method: "POST",
          body: { companyId, ...rest },
        };
      },
    }),
    submitExternalFlow3D: builder.mutation<
      ISubmitExternalFlow3D,
      ISubmitExternalFlow3DArgs
    >({
      query: (args) => {
        const { apiRouteType, ...rest } = args;

        return {
          url: `/payment/submitExternalFlow3D?type=${apiRouteType}`,
          method: "POST",
          body: { companyId, ...rest },
        };
      },
      invalidatesTags: [tags.billing],
    }),
  }),
});

export const {
  useChangePaymentToInvoiceMutation,
  useStartExternalFlowMutation,
  useSubmitExternalFlowMutation,
  useSubmitExternalFlow3DMutation,
} = paymentApi;
