import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      ADDED: "Lagt til",
      WHY_DONT_I_SEE_DISCOUNT: "Hvorfor ser jeg ikke rabatten?",
      WHEN_AND_HOW_THE_DISCOUNT_WILL_BE_DEDUCTED: (
        cutoffDate: string,
      ) => `Rabatten trekkes når bestillingen er låst, ${cutoffDate} før levering. Den siste rabatten du har lagt inn trekkes først, om de produktene du har valgt oppfyller kravene for rabatten.
    `,
      DEDUCTED_ON_THE_NEXT_DELIVERY: "Trekkes på neste leveranse:",
      SHOW_MY_DISCOUNTS_AND_ADD_NEW_ONES: "Vis mine rabatter og legg til nye",
      CUT_OFF_DATE: "tirsdag 23:59",
    },
    se: {
      ADDED: "Tillagd",
      WHY_DONT_I_SEE_DISCOUNT: "Varför ser jag inte rabatten?",
      WHEN_AND_HOW_THE_DISCOUNT_WILL_BE_DEDUCTED: (cutoffDate: string) => `
    Rabatten dras när beställningen är låst, ${cutoffDate} före leverans. Den senaste rabatten du lagt till dras först, om de produkter du valt uppfyller kraven för rabatten.
    `,
      DEDUCTED_ON_THE_NEXT_DELIVERY: "Dras på nästa leverans:",
      CUT_OFF_DATE: "tisdag 23:59",
      SHOW_MY_DISCOUNTS_AND_ADD_NEW_ONES: "Visa mina rabatter & lägg till nya",
    },
    dk: {
      ADDED: "Tilføjet",
      WHY_DONT_I_SEE_DISCOUNT: "Hvorfor ser jeg ikke rabatten?",
      WHEN_AND_HOW_THE_DISCOUNT_WILL_BE_DEDUCTED: (cutoffDate: string) =>
        `Rabatten fratrækkes, når ordren er låst, ${cutoffDate} før levering. Vi vælger den seneste rabat, du har tilføjet, hvis de produkter, du har valgt, opfylder kravene til rabatten.`,
      DEDUCTED_ON_THE_NEXT_DELIVERY: "Fratrækkes ved næste levering",
      CUT_OFF_DATE: "onsdag kl. 23:59",
      SHOW_MY_DISCOUNTS_AND_ADD_NEW_ONES: "Vis mine rabatter og tilføj nye",
    },
  } as const
)[language];
