import * as React from "react";
import { SVGProps } from "react";
const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.25 8.77778L12.9125 16.5556M9.0875 16.5556L8.75 8.77778M4.25 4.33333L5.24909 19.1347C5.31996 20.1846 6.19226 21 7.24455 21H14.7554C15.8077 21 16.68 20.1846 16.7509 19.1347L17.75 4.33333M4.25 4.33333H7.625M4.25 4.33333H2M17.75 4.33333H20M17.75 4.33333H14.375M14.375 4.33333V3C14.375 1.89543 13.4796 1 12.375 1H9.625C8.52043 1 7.625 1.89543 7.625 3V4.33333M14.375 4.33333H7.625"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Trash;
