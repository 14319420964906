import * as React from "react";
import { SVGProps } from "react";
const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.76953 7.85718C6.76953 7.85718 10.6749 8.07146 13.2311 8.71432"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.76953 10.4287C6.76953 10.4287 10.6749 10.643 13.2311 11.2859"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.76953 13C6.76953 13 9.07722 13 10.9868 13.6429"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 6.14275C11.8462 4.85704 4 4.42847 4 4.42847V17.2856C4 17.2856 12.3077 18.1428 16 18.9999V6.14275Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.48966 3.87898C3.18613 4.16083 3.16855 4.63538 3.4504 4.93891C3.73226 5.24244 4.20681 5.26002 4.51034 4.97817L3.48966 3.87898ZM13.2308 1H13.9808C13.9808 0.783892 13.8876 0.578287 13.725 0.435877C13.5625 0.293467 13.3464 0.228095 13.1321 0.256513L13.2308 1ZM4 4.42857C4.51034 4.97817 4.51008 4.9784 4.50983 4.97863C4.50977 4.9787 4.50953 4.97892 4.50939 4.97904C4.50912 4.9793 4.50889 4.97951 4.5087 4.97969C4.50831 4.98004 4.5081 4.98023 4.50805 4.98028C4.50796 4.98036 4.50853 4.97984 4.50977 4.97872C4.51226 4.97648 4.51743 4.97187 4.52532 4.965C4.54108 4.95126 4.5677 4.92852 4.60538 4.89782C4.68075 4.8364 4.8004 4.7431 4.9661 4.62617C5.29744 4.39233 5.81319 4.0638 6.52772 3.70653C7.95556 2.99261 10.1834 2.16081 13.3294 1.74349L13.1321 0.256513C9.81656 0.696335 7.42906 1.57881 5.8569 2.36489C5.07142 2.75763 4.49102 3.12553 4.10121 3.40062C3.90633 3.53815 3.75915 3.65244 3.65784 3.73499C3.60718 3.77627 3.56799 3.80962 3.54004 3.83397C3.52606 3.84614 3.5149 3.85607 3.50651 3.86362C3.50232 3.86739 3.49882 3.87057 3.49601 3.87313C3.49461 3.87441 3.49338 3.87554 3.49232 3.87652C3.49179 3.87701 3.4913 3.87745 3.49086 3.87786C3.49064 3.87807 3.49034 3.87835 3.49023 3.87845C3.48994 3.87872 3.48966 3.87898 4 4.42857ZM12.4808 1V5.28571H13.9808V1H12.4808Z"
      fill="currentColor"
    />
  </svg>
);
export default Menu;
