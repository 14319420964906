import * as React from "react";
import { SVGProps } from "react";
const BrokenCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={80} height={80} rx={40} fill="#FFF6E8" />
    <path
      d="M42.0125 33.875H22.5M13.75 19.875H19L27.75 54.875H55.75L60.3 40.875"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5 65.375C31.433 65.375 33 63.808 33 61.875C33 59.942 31.433 58.375 29.5 58.375C27.567 58.375 26 59.942 26 61.875C26 63.808 27.567 65.375 29.5 65.375Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.25 65.375C54.183 65.375 55.75 63.808 55.75 61.875C55.75 59.942 54.183 58.375 52.25 58.375C50.317 58.375 48.75 59.942 48.75 61.875C48.75 63.808 50.317 65.375 52.25 65.375Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.25 25.125V30.375M38.25 40.875L52.25 14.625L66.25 40.875H38.25Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.25 35.625C52.7332 35.625 53.125 35.2332 53.125 34.75C53.125 34.2668 52.7332 33.875 52.25 33.875C51.7668 33.875 51.375 34.2668 51.375 34.75C51.375 35.2332 51.7668 35.625 52.25 35.625Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BrokenCart;
