import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { getStore, RootState } from "..";

interface Notification {
  id: string;
  message: string;
  title?: string;
  type: "default" | "success" | "warn" | "error";
  disallowClose: boolean;
  autoClose: number | boolean;
  loading: boolean;
  links?: {
    href: string;
    label: string;
    external?: boolean;
  }[];
}

const initialState = {
  queue: [] as Notification[],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    pushNotification: (state, action: PayloadAction<Notification>) => {
      state.queue.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.queue = state.queue.filter((n) => n.id !== action.payload);
    },
    popNotification: (state) => {
      state.queue.shift();
    },
    updateNotification: (state, action: PayloadAction<Notification>) => {
      const index = state.queue.findIndex((n) => n.id === action.payload.id);
      state.queue[index] = action.payload;
    },
    cleanNotifications: (state, action: PayloadAction<number>) => {
      state.queue = state.queue.slice(action.payload, -1);
    },
    cleanAllNotifications: (state) => {
      state.queue = [];
    },
  },
});

export const {
  cleanAllNotifications,
  cleanNotifications,
  popNotification,
  pushNotification,
  removeNotification,
  updateNotification,
} = notificationsSlice.actions;

export const selectNotificationsQueue = (state: RootState) =>
  state.notifications.queue;

export const showNotification = (
  payload: Partial<Notification> & ({ message: string } | { title: string }),
) => {
  const {
    message,
    title,
    type = "default",
    disallowClose = false,
    autoClose = 5000,
    loading = false,
    links,
  } = payload;

  const id = payload.id || nanoid();

  getStore().dispatch(
    pushNotification({
      id,
      message: message || "",
      title,
      type,
      disallowClose,
      autoClose,
      loading,
      links,
    }),
  );

  return id;
};

export const hideNotification = (id: string) => {
  getStore().dispatch(removeNotification(id));
};
