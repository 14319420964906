import * as React from "react";
import { SVGProps } from "react";
const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2460_1400)">
      <path
        d="M16.25 3.41979C15.6703 3.66072 15.0483 3.82412 14.3948 3.89707C15.0621 3.52226 15.5729 2.92776 15.815 2.22155C15.189 2.56864 14.4981 2.82068 13.7619 2.95731C13.1724 2.36742 12.3339 2 11.4038 2C9.61947 2 8.17271 3.35703 8.17271 5.02977C8.17271 5.26701 8.20125 5.49872 8.25637 5.72029C5.57151 5.5938 3.19076 4.38726 1.59735 2.55387C1.31882 3.00069 1.16037 3.52132 1.16037 4.07707C1.16037 5.12855 1.7312 6.05631 2.5973 6.5991C2.0678 6.58249 1.56979 6.44587 1.13381 6.21877V6.25661C1.13381 7.72442 2.24792 8.94943 3.72518 9.22823C3.45452 9.29655 3.16911 9.3344 2.87385 9.3344C2.66522 9.3344 2.46345 9.31501 2.26562 9.27808C2.677 10.4828 3.86986 11.3588 5.28317 11.3829C4.17791 12.1952 2.78429 12.678 1.27062 12.678C1.00982 12.678 0.752927 12.6633 0.5 12.6365C1.93004 13.4978 3.62777 14 5.45246 14C11.396 14 14.6448 9.38241 14.6448 5.37781L14.634 4.98547C15.2688 4.5608 15.8179 4.02722 16.25 3.41979Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2460_1400">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Twitter;
