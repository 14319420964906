import { NoDelivery } from "@chef/icons/symbol";
import { Button, Card, Modal } from "../components";
import { Language } from "../../types";

interface PauseWeekModalProps {
  isShowing: boolean;
  isLoading: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  deliveryDate: string;
  language: Language;
  onChangeDeliveryClicked: () => void;
}

const intl = {
  no: {
    DO_YOU_WANT_TO_CANCEL_THE_DELIVERY: "Vil du avbestille leveransen",
    IF_THE_DELIVERY_DAY_IS_INCONVENIENT:
      "Passer ikke leveringsdatoen kan du enkelt endre både dag og tid under.",
    CANCEL_DELIVERY: "Avbestill leveranse",
    CHANGE_DELIVERY_DAY_AND_TIME: "Endre leveringsdato og tid",
    CANCEL: "Avbryt",
  },

  se: {
    DO_YOU_WANT_TO_CANCEL_THE_DELIVERY: "Vill du avboka leveransen",
    IF_THE_DELIVERY_DAY_IS_INCONVENIENT:
      "Passar inte leveransdagen kan du enkelt ändra både dag och tid nedan.",
    CANCEL_DELIVERY: "Avboka leveransen",
    CHANGE_DELIVERY_DAY_AND_TIME: "Ändra leveransdag och tid",
    CANCEL: "Avbryt",
  },

  dk: {
    DO_YOU_WANT_TO_CANCEL_THE_DELIVERY: "Vil du afbestille leveringen d.",
    IF_THE_DELIVERY_DAY_IS_INCONVENIENT:
      "Hvis leveringsdagen ikke passer dig, kan du nemt ændre leveringsdag og -tidspunkt nedenfor.",
    CANCEL_DELIVERY: "Afbestil levering",
    CHANGE_DELIVERY_DAY_AND_TIME: "Ændr leveringsdag og -tidspunkt",
    CANCEL: "Annuller",
  },
} as const;

const PauseWeekModal = ({
  isShowing,
  isLoading,
  handleConfirm,
  handleCancel,
  deliveryDate = "",
  language = "no",
  onChangeDeliveryClicked,
}: PauseWeekModalProps) => {
  const {
    DO_YOU_WANT_TO_CANCEL_THE_DELIVERY,
    IF_THE_DELIVERY_DAY_IS_INCONVENIENT,
    CHANGE_DELIVERY_DAY_AND_TIME,
    CANCEL_DELIVERY,
    CANCEL,
  } = intl[language];
  return (
    <Modal
      outerClassName="max-w-2xl h-screen flex items-center justify-center mx-auto p-6"
      show={isShowing}
      showClose={false}
      onClose={handleCancel}
    >
      <Card
        noPadding
        className="flex flex-col items-center p-6 text-center md:p-18"
      >
        <NoDelivery className="w-40 h-40 mb-6" />

        <h1 className="mb-2 text-xl md:text-2xl">
          <strong>
            {DO_YOU_WANT_TO_CANCEL_THE_DELIVERY} {deliveryDate}?
          </strong>
        </h1>

        <p className="mb-8">{IF_THE_DELIVERY_DAY_IS_INCONVENIENT}</p>

        <Button
          loading={isLoading}
          onClick={handleConfirm}
          className="w-64 mb-3"
          primary
        >
          {CANCEL_DELIVERY}
        </Button>

        <Button onClick={onChangeDeliveryClicked} className="w-64" outlined>
          {CHANGE_DELIVERY_DAY_AND_TIME}
        </Button>

        <button onClick={handleCancel} className="mt-10 text-sm underline">
          <strong>{CANCEL}</strong>
        </button>
      </Card>
    </Modal>
  );
};

export default PauseWeekModal;
