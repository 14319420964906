import {
  LoyaltyLevelsByCompanyQuery,
  useBillingQuery,
  useLoyaltyLevelsByCompanyQuery,
  BillingQuery,
  useMeQuery,
} from "@chef/state-management";
import { getBasketFinances } from "@chef/state-management/helpers";
import { benefits } from "@chef/constants";

interface LoyaltyData {
  currentAccruedPoints: number;
  accruedPointsToNextLevel: number;

  currentLevel: number;
  currentLevelLabel: string;

  nextLevel: number | null;
  nextLevelLabel: string | null;
  nextLevelRequirement: number | null;

  estimatedAccruedPointsFromNextDelivery: number;

  availableLoyaltyPoints: number | null;
  loyaltyEntries: BillingQuery["billing"]["loyaltyEntries"];

  multiplier: number;

  progress: {
    isMax: boolean;
    current: number;
    projected: number;
  };

  levels: ReturnType<typeof createBenefits>;
}

type UseLoyalty =
  | {
      isLoading: boolean;
      data: null;
    }
  | {
      isLoading: false;
      data: LoyaltyData;
    };

const toPercent = (value: number, max: number) => {
  return Math.max(Math.min((value / max) * 100, 100), 0);
};

const findNextLevel = (
  levels: LoyaltyLevelsByCompanyQuery["loyaltyLevelsByCompany"],
  currentLevel: number,
) => {
  const nextLevel = levels.find((level) => level.level === currentLevel + 1);
  return nextLevel;
};

export const createBenefits = (
  levels: LoyaltyLevelsByCompanyQuery["loyaltyLevelsByCompany"],
) => {
  return levels.map((level) => {
    const benefitsForLevel = benefits.find((b) => b.level === level.level);

    // Deduplicate with a map
    const _benefits = new Map();

    // Hacky way of merging benefits from the backend with the ones we have in the frontend
    if (benefitsForLevel) {
      for (const b of benefitsForLevel.benefits) {
        if (b.id) {
          if (b.id === "multiplier") {
            _benefits.set(b.name, {
              name: b.name,
              value: level.multiplier,
            });
          } else if (b.id.startsWith("taf_boost")) {
            _benefits.set(b.name, {
              name: b.name,
              value: level.benefits.find((_b) => _b)?.value,
            });
          } else {
            const source = level.benefits.find((_b) => _b.id === b.id);
            _benefits.set(b.name, {
              name: b.name,
              value: source?.value || b.value,
            });
          }
        } else {
          _benefits.set(b.name, {
            ...b,
          });
        }
      }
    }

    return {
      ...level,
      benefits: [..._benefits.values()],
    };
  });
};

const calculateEstimatedProgress = (
  billingBasket: BillingQuery["billing"]["baskets"][number] | null,
  multiplier: number,
  level: number,
) => {
  const finances = getBasketFinances({
    basketProducts:
      billingBasket?.basketProducts.map((p) => ({
        price: p.variation.finalPrice,
        quantity: p.quantity,
        productId: p.variation.productId,
        productTypeId: p.variation.product.productTypeId,
        variationId: p.variationId,
      })) || [],
    customerFee: 49,
    loyaltyLevel: level,
  });

  const totalSum = finances.total;

  return +(totalSum * multiplier).toFixed(0);
};

export const useLoyalty = (): UseLoyalty => {
  const { data: isLoggedIn } = useMeQuery();

  const skip = !isLoggedIn;

  const { data: billingQuery } = useBillingQuery(undefined, {
    skip,
  });

  const { data: loyaltyLevelsByCompanyQuery } = useLoyaltyLevelsByCompanyQuery(
    undefined,
    { skip },
  );

  const loyaltylevel = billingQuery?.billing.loyaltyLevel;

  if (!isLoggedIn || !loyaltylevel) {
    return { isLoading: false, data: null };
  }

  if (!billingQuery || !loyaltyLevelsByCompanyQuery) {
    return { isLoading: true, data: null };
  }

  const availableLoyaltyPoints = billingQuery.billing.availableLoyaltyPoints;

  const levels = [...loyaltyLevelsByCompanyQuery.loyaltyLevelsByCompany].sort(
    (a, b) => a.level - b.level,
  );
  const nextLevel = findNextLevel(levels, loyaltylevel.level);

  const currentAccruedPoints = loyaltylevel.accruedPoints;

  const accruedPointsToNextLevel = nextLevel
    ? nextLevel.requirement - currentAccruedPoints
    : Infinity;

  const estimatedAccruedPointsFromNextDelivery = calculateEstimatedProgress(
    billingQuery?.billing.baskets.find((b) => b.default) || null,
    loyaltylevel.multiplier,
    loyaltylevel.level,
  );

  const isMaxLevel = !nextLevel;

  const data: LoyaltyData = {
    currentAccruedPoints,
    accruedPointsToNextLevel,

    currentLevel: loyaltylevel.level,
    currentLevelLabel: loyaltylevel.name || `[PH] (${loyaltylevel.level})`,

    nextLevel: nextLevel?.level ?? null,
    nextLevelLabel: nextLevel?.name ?? null,
    nextLevelRequirement: nextLevel?.requirement ?? null,

    estimatedAccruedPointsFromNextDelivery,

    availableLoyaltyPoints,
    loyaltyEntries: billingQuery.billing.loyaltyEntries,

    multiplier: loyaltylevel.multiplier,

    progress: {
      isMax: isMaxLevel,
      current: isMaxLevel
        ? 100
        : toPercent(
            currentAccruedPoints,
            currentAccruedPoints + accruedPointsToNextLevel,
          ),
      projected: isMaxLevel
        ? 100
        : toPercent(
            currentAccruedPoints + estimatedAccruedPointsFromNextDelivery,
            currentAccruedPoints + accruedPointsToNextLevel,
          ),
    },

    levels: createBenefits(levels),
  };

  return {
    isLoading: false,
    data,
  };
};
