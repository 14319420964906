import * as React from "react";
import { SVGProps } from "react";
const Dinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 15.899C8.27052 17.1955 10.0413 18 12 18C15.866 18 19 14.866 19 11C19 7.13401 15.866 4 12 4C11.305 4 10.6337 4.10128 10 4.28988"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M13 7.12598C14.7252 7.57002 16 9.13612 16 11C16 12.8638 14.7252 14.4299 13 14.8739"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M1 3V5.81401C1 7.58692 1.98243 8 4 8C6.01757 8 7 7.91613 7 5.81401V3"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 3V18"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Dinner;
