import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CHOOSE_BETWEEN_N_DISHES: (dishCount: number) =>
        `Velg blant ${dishCount} retter!`,
      MORE_DISHES: (dishCount: number) => `+${dishCount} retter til!`,
      SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Registrer deg, og nyt vårt store utvalg av gode middager hver uke.",
      ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Nyt vårt store utvalg av gode middager hver uke.",
      YOUR_PERSONALIZED_MENU: "Din skreddersydde meny",
      DISHES_FROM_OUR_UPCOMING_WEEKS_MENU_THAT_MATCHES_YOUR_PREFERENCES:
        "Retter fra neste ukes meny som passer til dine preferanser",
      CLOSE: "Lukk",
      YOU_CHOOSE: "Velg fritt",
      XX_DISHES_EACH_WEEK: (dishCount: number) =>
        `${dishCount} retter hver uke`,
    },
    se: {
      CHOOSE_BETWEEN_N_DISHES: (dishCount: number) =>
        `Välj bland ${dishCount} rätter!`,
      MORE_DISHES: (dishCount: number) => `+${dishCount} rätter till!`,
      SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Registrera dig och njut av vårt breda utbud av goda rätter varje vecka.",
      ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Njut av vårt breda utbud av älskade och lättlagade rätter varje vecka.",
      YOUR_PERSONALIZED_MENU: "Din personliga meny",
      DISHES_FROM_OUR_UPCOMING_WEEKS_MENU_THAT_MATCHES_YOUR_PREFERENCES:
        "Rätter från nästa veckas meny som matchar dina preferenser.",
      CLOSE: "Stäng",
      YOU_CHOOSE: "Välj fritt",
      XX_DISHES_EACH_WEEK: (dishCount: number) =>
        `${dishCount} rätter varje vecka`,
    },
    dk: {
      CHOOSE_BETWEEN_N_DISHES: (dishCount: number) =>
        `Vælg mellem ${dishCount} retter!`,
      MORE_DISHES: (dishCount: number) => `+${dishCount} retter mere!`,
      SIGN_UP_AND_ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Tilmeld dig og nyd det store udvalg af lækre opskrifter hver uge.",
      ENJOY_OUR_FULL_RANGE_OF_DELICIOUS_MEALS_EACH_WEEK:
        "Nyd det store udvalg af lækre opskrifter hver uge.",
      YOUR_PERSONALIZED_MENU: "Din skræddersyede menu",
      DISHES_FROM_OUR_UPCOMING_WEEKS_MENU_THAT_MATCHES_YOUR_PREFERENCES:
        "Retter fra næste uges menu, som matcher dine præferencer.",
      CLOSE: "Luk",
      YOU_CHOOSE: "Frit valg",
      XX_DISHES_EACH_WEEK: (dishCount: number) =>
        `${dishCount} retter hver uge`,
    },
  } as const
)[language];
