import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { PRODUCT_CATEGORY_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { getCutoffDateForWeekYear } from "@chef/helpers";

import { setDeviation } from "../features";
import {
  usePickAndMixQuery,
  useProductsByCategoriesQuery,
} from "../graphql/generated";
import { filterPickAndMix } from "../helpers";
import { getNewBasketDeviation } from "../helpers/basket";
import { usePickAndMixPrice } from "./usePickAndMixPrice";

export const useUpdateBasketPickAndMixDeviation = (args: {
  week: number;
  year: number;
  signup?: boolean;
}) => {
  const dispatch = useDispatch();

  const { week, year, signup = false } = args;
  const skip = !week || !year;

  const { getPriceOfPickAndMixProduct, ...pickAndMixPrice } =
    usePickAndMixPrice(args);

  const { data: { productsByCategories } = {}, ...productsByCategoriesQuery } =
    useProductsByCategoriesQuery(
      {
        categoryIds: [
          PRODUCT_CATEGORY_IDS.FINANCIAL,
          signup
            ? PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT
            : PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
        ],
        week,
        year,
      },
      { skip },
    );

  const { data: { pickAndMix } = {}, ...pickAndMixQuery } = usePickAndMixQuery(
    {
      productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
      week,
      year,
    },
    { skip },
  );

  const filteredPickAndMix = useMemo(() => {
    if (!pickAndMix) {
      return null;
    }

    return filterPickAndMix({
      pickAndMix,
      cutoffDate: getCutoffDateForWeekYear({ week, year }),
    });
  }, [pickAndMix]);

  const updateBasketPickAndMixDeviation = useCallback(
    (args: {
      basketProducts: Parameters<
        typeof getNewBasketDeviation
      >[0]["basketProducts"];
      portions: number;

      updateProduct?: {
        productId: string;
        variationId: string;
      };

      manualOverride?: boolean;
    }) => {
      if (!filteredPickAndMix || !productsByCategories) {
        return false;
      }

      const newDeviation = getNewBasketDeviation({
        productsByCategories,
        pickAndMix: filteredPickAndMix,
        getPriceOfPickAndMixProduct,
        ...args,
      });
      if (!newDeviation) {
        return false;
      }

      dispatch(
        setDeviation({
          basket: { products: newDeviation, week, year },
          manual:
            args.manualOverride === undefined ? true : args.manualOverride,
        }),
      );

      return true;
    },
    [
      dispatch,
      getPriceOfPickAndMixProduct,
      filteredPickAndMix,
      productsByCategories,
      week,
      year,
    ],
  );

  return {
    updateBasketPickAndMixDeviation,
    isLoading:
      pickAndMixPrice.isLoading ||
      pickAndMixQuery.isLoading ||
      productsByCategoriesQuery.isLoading,
    isFetching:
      pickAndMixPrice.isFetching ||
      pickAndMixQuery.isFetching ||
      productsByCategoriesQuery.isFetching,
    isError:
      pickAndMixPrice.isError ||
      pickAndMixQuery.isError ||
      productsByCategoriesQuery.isError,
  };
};
