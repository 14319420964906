import { CalendarQuery } from "@chef/state-management";
import { CalendarWeekMeals } from "./CalendarWeekMeals";
import { PermanentDiscount } from "../shared/PermanentDiscount";
import { TemporaryDiscount } from "../shared/TemporaryDiscount";
import { CalendarWeekGroceries } from "./CalendarWeekGroceries";
import { CalendarWeekDeliveryFee } from "./CalendarWeekDeliveryFee";
import { CalendarWeekSummary } from "./CalendarWeekSummary";
import { Credits } from "../shared/Credits";

interface CalendarWeekShoppingCartProps {
  calendarWeek: CalendarQuery["calendar"][number];
  initiallyExpandMealSection?: boolean;
  initiallyExpandGrocerySection?: boolean;
}

export const CalendarWeekShoppingCart = ({
  calendarWeek,
  initiallyExpandMealSection,
  initiallyExpandGrocerySection,
}: CalendarWeekShoppingCartProps) => {
  return (
    <div>
      <CalendarWeekMeals
        initiallyExpanded={initiallyExpandMealSection}
        calendarWeek={calendarWeek}
      />

      <CalendarWeekGroceries
        calendarWeek={calendarWeek}
        initiallyExpanded={initiallyExpandGrocerySection}
      />

      <PermanentDiscount />

      <TemporaryDiscount />

      <Credits />

      <CalendarWeekDeliveryFee calendarWeek={calendarWeek} />

      <CalendarWeekSummary calendarWeek={calendarWeek} />
    </div>
  );
};
