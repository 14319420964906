import { Ref } from "react";

import { IApartmentModel } from "@chef/state-management";
import { AsyncSelect } from "@chef/components";

import { IInputOption } from "./AddressForm";

import { intl } from "./FloorNumberSelectInput.Intl";

interface Props {
  selectedApartment: IApartmentModel;
  onSelected: (value: IApartmentModel) => void;
  customRef?: Ref<HTMLInputElement>;
  defaultOptions: IInputOption[];
  className?: string;
  autofocus?: boolean;
}

const FloorNumberSelectInput = ({
  selectedApartment,
  onSelected,
  customRef,
  defaultOptions,
  className,
  autofocus,
}: Props) => {
  return (
    <AsyncSelect
      autofocus={autofocus}
      className={className}
      customRef={customRef}
      name="floor-number"
      defaultLabel={selectedApartment?.floor}
      defaultValue={selectedApartment?.floor}
      isSearchable={false}
      value={selectedApartment?.floor}
      options={defaultOptions}
      placeholder={intl.FLOOR_LABEL}
      onChange={(input) => {
        const { data } = input as IInputOption;
        if (data) {
          onSelected(data as IApartmentModel);
        }
      }}
    />
  );
};

export default FloorNumberSelectInput;
