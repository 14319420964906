import * as React from "react";
import { SVGProps } from "react";
const SadFace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 41C32.0457 41 41 32.0457 41 21C41 9.95431 32.0457 1 21 1C9.95431 1 1 9.95431 1 21C1 32.0457 9.95431 41 21 41Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.2655 31.7993C29.1082 30.4925 27.6868 29.4462 26.0951 28.7297C24.5034 28.0131 22.7777 27.6426 21.0322 27.6426C19.2866 27.6426 17.5609 28.0131 15.9692 28.7297C14.3775 29.4462 12.9561 30.4925 11.7988 31.7993"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3333 18.3327C15.0697 18.3327 15.6667 17.7357 15.6667 16.9993C15.6667 16.263 15.0697 15.666 14.3333 15.666C13.597 15.666 13 16.263 13 16.9993C13 17.7357 13.597 18.3327 14.3333 18.3327Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6673 18.3327C28.4037 18.3327 29.0007 17.7357 29.0007 16.9993C29.0007 16.263 28.4037 15.666 27.6673 15.666C26.9309 15.666 26.334 16.263 26.334 16.9993C26.334 17.7357 26.9309 18.3327 27.6673 18.3327Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SadFace;
