import * as React from "react";
import { SVGProps } from "react";
const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.9997 9.16675C9.65504 9.16675 5.83301 15.0001 5.83301 15.0001C5.83301 15.0001 9.65504 20.8334 14.9997 20.8334C20.3443 20.8334 24.1663 15.0001 24.1663 15.0001C24.1663 15.0001 20.3443 9.16675 14.9997 9.16675Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <path
      d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15C12.5 16.3807 13.6193 17.5 15 17.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </svg>
);
export default Eye;
