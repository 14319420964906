import { BRAND_NAME } from "./misc";

export const FAQ = {
  AMK: [
    {
      question: "Question 1",
      answer: "Answer 1",
    },
  ],
  GL: [
    {
      question: "Question 1",
      answer: "Answer 1",
    },
  ],
  LMK: [
    {
      question: "Question 1",
      answer: "Answer 1",
    },
  ],
  RN: [
    {
      question: "Question 1",
      answer: "Answer 1",
    },
  ],
}[BRAND_NAME];
