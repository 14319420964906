import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      POSTAL_CODE: "Postnummer",
      FIRST_NAME: "Fornavn",
      LAST_NAME: "Etternavn",
      ADDRESS: "Adresse",
      EMAIL: "E-post",
      PHONE: "Telefon",
      EMAIL_IS_REQUIRED: "E-post må fylles ut",
      POSTAL_CODE_IS_REQUIRED: "Postnummer må fylles ut",
      MUST_BE_FILLED_IN: "Må fylles ut",
      SEND: "Send",
      FORM_SUBMITTED: "Takk for din henvendelse!",
      SOMETHING_WENT_WRONG: "Noe gikk galt. Prøv igjen senere.",
      THANK_YOU_FOR_YOUR_INTEREST: "Takk for din interesse!",
      WE_WILL_CONTACT_YOU_WHEN_WE_CAN_OFFER_DELIVERIES_IN_YOUR_AREA:
        "Vi vil kontakte deg når vi kan tilby levering i ditt område.",
      MUST_BE_A_VALID_EMAIL: "Må være en gyldig e-postadresse",
      IF_YOU_WANT_TO_BE_CONTACTED_WHEN_WE_DELIVER:
        "Hvis du vil bli kontaktet når vi leverer til ditt postnummer, fyll ut skjemaet under.",
    },
    se: {
      POSTAL_CODE: "Postnummer",
      FIRST_NAME: "Förnamn",
      LAST_NAME: "Efternamn",
      ADDRESS: "Adress",
      EMAIL: "E-post",
      PHONE: "Telefon",
      EMAIL_IS_REQUIRED: "E-post måste fyllas i",
      POSTAL_CODE_IS_REQUIRED: "Postnummer måste fyllas i",
      MUST_BE_FILLED_IN: "Måste fyllas i",
      SEND: "Skicka",
      FORM_SUBMITTED: "Tack för din förfrågan!",
      SOMETHING_WENT_WRONG: "Något gick fel. Försök igen senare.",
      THANK_YOU_FOR_YOUR_INTEREST: "Tack för ditt intresse!",
      WE_WILL_CONTACT_YOU_WHEN_WE_CAN_OFFER_DELIVERIES_IN_YOUR_AREA:
        "Vi kommer att kontakta dig när vi kan erbjuda leveranser i ditt område.",
      MUST_BE_A_VALID_EMAIL: "Måste vara en giltig e-postadress",
      IF_YOU_WANT_TO_BE_CONTACTED_WHEN_WE_DELIVER:
        "Om du vill bli kontaktad när vi levererar till ditt postnummer, fyll i formuläret nedan.",
    },
    dk: {
      POSTAL_CODE: "Postnummer",
      FIRST_NAME: "Fornavn",
      LAST_NAME: "Efternavn",
      ADDRESS: "Adresse",
      EMAIL: "E-post",
      PHONE: "Telefon",
      EMAIL_IS_REQUIRED: "E-post skal udfyldes",
      POSTAL_CODE_IS_REQUIRED: "Postnummer skal udfyldes",
      MUST_BE_FILLED_IN: "Skal udfyldes",
      SEND: "Send",
      FORM_SUBMITTED: "Tak for din henvendelse!",
      SOMETHING_WENT_WRONG: "Noget gik galt. Prøv igen senere.",
      THANK_YOU_FOR_YOUR_INTEREST: "Tak for din interesse!",
      WE_WILL_CONTACT_YOU_WHEN_WE_CAN_OFFER_DELIVERIES_IN_YOUR_AREA:
        "Vi kontakter dig, når vi kan tilbyde levering i dit område.",
      MUST_BE_A_VALID_EMAIL: "Skal være en gyldig e-mailadresse",
      IF_YOU_WANT_TO_BE_CONTACTED_WHEN_WE_DELIVER:
        "Hvis du vil blive kontaktet når vi leverer til dit postnummer, udfyld formularen nedenfor.",
    },
  } as const
)[language];
