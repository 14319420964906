import * as React from "react";
import { SVGProps } from "react";
const Instagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 5.80863 0 3.71294 1.05041 2.26718C1.38964 1.80026 1.80026 1.38964 2.26718 1.05041C3.71294 0 5.80863 0 10 0C14.1914 0 16.2871 0 17.7328 1.05041C18.1997 1.38964 18.6104 1.80026 18.9496 2.26718C20 3.71294 20 5.80863 20 10C20 14.1914 20 16.2871 18.9496 17.7328C18.6104 18.1997 18.1997 18.6104 17.7328 18.9496C16.2871 20 14.1914 20 10 20C5.80863 20 3.71294 20 2.26718 18.9496C1.80026 18.6104 1.38964 18.1997 1.05041 17.7328C0 16.2871 0 14.1914 0 10ZM10.0002 4.70581C7.0765 4.70581 4.70605 7.07625 4.70605 9.99993C4.70605 12.9236 7.0765 15.294 10.0002 15.294C12.9238 15.294 15.2943 12.9236 15.2943 9.99993C15.2943 7.07625 12.9238 4.70581 10.0002 4.70581ZM10.0002 13.3088C8.17635 13.3088 6.69135 11.8238 6.69135 9.99993C6.69135 8.17478 8.17635 6.6911 10.0002 6.6911C11.824 6.6911 13.309 8.17478 13.309 9.99993C13.309 11.8238 11.824 13.3088 10.0002 13.3088ZM16.3962 4.30871C16.3962 4.69832 16.0804 5.01415 15.6908 5.01415C15.3012 5.01415 14.9854 4.69832 14.9854 4.30871C14.9854 3.91911 15.3012 3.60327 15.6908 3.60327C16.0804 3.60327 16.3962 3.91911 16.3962 4.30871Z"
      fill="currentColor"
    />
  </svg>
);
export default Instagram;
