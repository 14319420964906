import { PropsWithChildren } from "react";

const ProseContainer = ({ children }: PropsWithChildren) => {
  return (
    <article className="px-4 pt-5 mx-auto prose-sm prose md:pt-10 md:px-0 md:prose-base lg:prose-lg xl:prose-xl">
      {children}
    </article>
  );
};

export default ProseContainer;
