import { api } from "./baseApi";
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Address = {
  addressCode: Maybe<Scalars["String"]["output"]>;
  addressDigitalKeyProviderId: Maybe<Scalars["ID"]["output"]>;
  addressUUID: Maybe<Scalars["ID"]["output"]>;
  agreementId: Scalars["Int"]["output"];
  alias: Scalars["String"]["output"];
  apartmentName: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  companyId: Scalars["ID"]["output"];
  country: Scalars["String"]["output"];
  digitalKeyProviderName: Maybe<Scalars["String"]["output"]>;
  doorCode: Maybe<Scalars["String"]["output"]>;
  flatNo: Maybe<Scalars["String"]["output"]>;
  floorNumber: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  instructionsToDriver: Maybe<Scalars["String"]["output"]>;
  isBilling: Scalars["Boolean"]["output"];
  isShipping: Maybe<Scalars["Boolean"]["output"]>;
  latitude: Maybe<Scalars["Float"]["output"]>;
  letterExtra: Maybe<Scalars["String"]["output"]>;
  longitude: Maybe<Scalars["Float"]["output"]>;
  postalCode: Scalars["Int"]["output"];
  street: Scalars["String"]["output"];
  streetName: Maybe<Scalars["String"]["output"]>;
  streetNumber: Maybe<Scalars["Int"]["output"]>;
  timeblocks: Maybe<Array<Maybe<Timeblock>>>;
  validated: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type AddressTuple = {
  addressData: Maybe<Address>;
  addressId: Maybe<Scalars["ID"]["output"]>;
  addressVersion: Maybe<Scalars["Int"]["output"]>;
};

export type AgreementLoyaltyLevel = {
  accruedPoints: Scalars["Int"]["output"];
  benefits: Array<Maybe<LoyaltyBenefit>>;
  companyId: Scalars["ID"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  level: Scalars["Int"]["output"];
  levelId: Scalars["ID"]["output"];
  multiplier: Scalars["Float"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  requirement: Scalars["Int"]["output"];
  retainableLevel: LoyaltyLevel;
  retainableLevelId: Scalars["ID"]["output"];
  retainablePoints: Scalars["Int"]["output"];
};

export type Allergy = {
  hasTraceOf: Maybe<Scalars["Boolean"]["output"]>;
  id: Maybe<Scalars["ID"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  parentAllergy: Maybe<Allergy>;
  showAllergy: Maybe<Scalars["Boolean"]["output"]>;
};

export type AllergyInformation = {
  hasTraceOff: Maybe<Scalars["Boolean"]["output"]>;
  isActive: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
};

export type AppliedDiscountModel = {
  amountApplied: Maybe<Scalars["Float"]["output"]>;
  couponCode: Maybe<Scalars["String"]["output"]>;
  discountId: Maybe<Scalars["ID"]["output"]>;
};

export type Attribute = {
  name: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type BasicRecipe = {
  cookingTimeMax: Maybe<Scalars["Float"]["output"]>;
  cookingTimeMin: Maybe<Scalars["Float"]["output"]>;
  images: Maybe<Image>;
  mainRecipeId: Maybe<Scalars["Int"]["output"]>;
  recipeDescription: Maybe<Scalars["String"]["output"]>;
  recipeId: Maybe<Scalars["Int"]["output"]>;
  recipeName: Maybe<Scalars["String"]["output"]>;
  recipeNameHeadline: Maybe<Scalars["String"]["output"]>;
  recipeNameSubheadline: Maybe<Scalars["String"]["output"]>;
  shelfLife: Maybe<Scalars["Int"]["output"]>;
  taxonomies: Maybe<Array<Maybe<Taxonomy>>>;
};

export type Basket = {
  active: Maybe<Scalars["Boolean"]["output"]>;
  basketId: Maybe<Scalars["ID"]["output"]>;
  basketProducts: Maybe<Array<Maybe<BasketProduct>>>;
  default: Maybe<Scalars["Boolean"]["output"]>;
  deliveryInterval: Maybe<Scalars["Int"]["output"]>;
  shippingAddressId: Maybe<Scalars["ID"]["output"]>;
  timeblockId: Maybe<Scalars["Int"]["output"]>;
};

export type BasketProduct = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  deliveryInterval: Maybe<Scalars["Int"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  variation: Maybe<Variation>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type BillingAgreement = {
  addonSubscriptions: Maybe<Array<Maybe<Subscription>>>;
  addresses: Maybe<Array<Maybe<Address>>>;
  agreementId: Maybe<Scalars["Int"]["output"]>;
  availableLoyaltyPoints: Maybe<Scalars["Int"]["output"]>;
  baskets: Maybe<Array<Maybe<Basket>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  consents: Maybe<Array<Maybe<UserConsent>>>;
  contacts: Maybe<ContactPreferences>;
  creditStatus: Maybe<CreditStatus>;
  currentPaymentCard: Maybe<PaymentCard>;
  defaultPrice: Maybe<Scalars["Float"]["output"]>;
  deviations: Maybe<Array<Maybe<Deviation>>>;
  discounts: Maybe<Array<Maybe<UserDiscount>>>;
  externalIdentifiers: Array<Maybe<ExternalIdentifier>>;
  giftCards: Maybe<Array<Maybe<GiftCard>>>;
  loyaltyEntries: Array<Maybe<LoyaltyEntry>>;
  loyaltyLevel: Maybe<AgreementLoyaltyLevel>;
  paymentCards: Maybe<Array<Maybe<PaymentCard>>>;
  paymentPartnerId: Maybe<Scalars["ID"]["output"]>;
  preferences: Maybe<Array<Maybe<UserPreferences>>>;
  referralCode: Maybe<Scalars["String"]["output"]>;
  schedulers: Maybe<Array<Maybe<Scheduler>>>;
  status: Maybe<Scalars["Int"]["output"]>;
  subscribedStartDate: Maybe<Scalars["String"]["output"]>;
  userCredits: Array<Maybe<UserCredits>>;
};

export type BundleVariation = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  mainVariationId: Maybe<Scalars["ID"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  variation: Maybe<Variation>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type ChildProductCategories = {
  productCategoryId: Maybe<Scalars["ID"]["output"]>;
  productCategoryName: Maybe<Scalars["String"]["output"]>;
};

export type ColliLine = {
  colliId: Maybe<Scalars["ID"]["output"]>;
  colliLabel: Maybe<Scalars["String"]["output"]>;
  colliNumber: Maybe<Scalars["Int"]["output"]>;
  productId: Maybe<Scalars["ID"]["output"]>;
  productName: Maybe<Scalars["String"]["output"]>;
};

export type Consents = {
  categoryConsentId: Maybe<Scalars["ID"]["output"]>;
  categoryConsentName: Maybe<Scalars["String"]["output"]>;
  consentId: Maybe<Scalars["ID"]["output"]>;
  consentName: Maybe<Scalars["String"]["output"]>;
  isMandatory: Maybe<Scalars["Boolean"]["output"]>;
};

export type ContactPreferences = {
  cutOffNotice: Maybe<Scalars["Boolean"]["output"]>;
  door: Maybe<Scalars["Boolean"]["output"]>;
  email: Maybe<Scalars["Boolean"]["output"]>;
  phone: Maybe<Scalars["Boolean"]["output"]>;
  print: Maybe<Scalars["Boolean"]["output"]>;
  sms: Maybe<Scalars["Boolean"]["output"]>;
  socialMedia: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreditStatus = {
  hasValidCreditCheck: Maybe<Scalars["Boolean"]["output"]>;
  hasValidReservationAmount: Maybe<Scalars["Boolean"]["output"]>;
};

export type CustomerIngredient = {
  ingredientInternalReference: Maybe<Scalars["String"]["output"]>;
  ingredientName: Maybe<Scalars["String"]["output"]>;
  ingredientQuantity: Maybe<Scalars["Int"]["output"]>;
  salesPrice: Maybe<Scalars["Float"]["output"]>;
  supplierName: Maybe<Scalars["String"]["output"]>;
};

export type CustomerIngredients = {
  ingredients: Maybe<Array<Maybe<CustomerIngredient>>>;
  productType: Maybe<Scalars["String"]["output"]>;
  variationId: Maybe<Scalars["String"]["output"]>;
};

export type CustomerRating = {
  comments: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  rating: Maybe<Scalars["String"]["output"]>;
};

export type DefaultCoupon = {
  amount: Scalars["Float"]["output"];
  chainedDiscounts: Maybe<Array<Maybe<UserDiscountMinimalData>>>;
  couponCode: Scalars["String"]["output"];
  createdAt: Maybe<Scalars["String"]["output"]>;
  criterias: Array<Maybe<DiscountCriteria>>;
  description: Maybe<Scalars["String"]["output"]>;
  discountId: Scalars["ID"]["output"];
  giftProducts: Array<Maybe<GiftProduct>>;
  hasChain: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  registrationOnly: Scalars["Boolean"]["output"];
  type: Scalars["Int"]["output"];
  usesLeft: Maybe<Scalars["Int"]["output"]>;
  validEndDate: Maybe<Scalars["String"]["output"]>;
};

export type Deviation = {
  active: Maybe<Scalars["Boolean"]["output"]>;
  basketId: Maybe<Scalars["ID"]["output"]>;
  products: Maybe<Array<Maybe<DeviationProduct>>>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type DeviationProduct = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type DeviationProducts = {
  deviationId: Maybe<Scalars["ID"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type Deviations = {
  basketId: Maybe<Scalars["ID"]["output"]>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["ID"]["output"]>;
  isActive: Maybe<Scalars["Boolean"]["output"]>;
  orderDeliveryType: Maybe<Scalars["Int"]["output"]>;
  products: Maybe<Array<Maybe<DeviationProducts>>>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type DiscountCriteria = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  criteriaId: Maybe<Scalars["ID"]["output"]>;
  criteriaTitle: Maybe<Scalars["String"]["output"]>;
  customDiscountAmount: Maybe<Scalars["Float"]["output"]>;
  productId: Maybe<Scalars["ID"]["output"]>;
};

export type DiscountGiftProduct = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  discountUsage: Maybe<Scalars["Int"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  usage: Maybe<Scalars["Int"]["output"]>;
  variation: Maybe<Variation>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type ExternalIdentifier = {
  agreementId: Maybe<Scalars["Int"]["output"]>;
  externalId: Maybe<Scalars["ID"]["output"]>;
  externalPartnerId: Maybe<Scalars["ID"]["output"]>;
};

export type ExternalPartner = {
  externalPartnerId: Maybe<Scalars["ID"]["output"]>;
  externalPartnerName: Maybe<Scalars["String"]["output"]>;
};

export type FavoriteRecipes = {
  createdAt: Maybe<Scalars["String"]["output"]>;
  mainRecipeId: Maybe<Scalars["Int"]["output"]>;
  recipeId: Scalars["Int"]["output"];
  typeId: Maybe<Scalars["Int"]["output"]>;
  typeName: Maybe<Scalars["String"]["output"]>;
};

export type FlexiblePrice = {
  campaignPrice: Maybe<Scalars["String"]["output"]>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  price: Maybe<Scalars["String"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
  week: Maybe<Scalars["String"]["output"]>;
  year: Maybe<Scalars["String"]["output"]>;
};

export type FlexiblePriceV2 = {
  campaignDiscount: Maybe<Scalars["Float"]["output"]>;
  finalPrice: Maybe<Scalars["Float"]["output"]>;
  priceNoVat: Maybe<Scalars["Float"]["output"]>;
  regularPrice: Maybe<Scalars["Float"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
  vat: Maybe<Scalars["Float"]["output"]>;
};

export type GiftCard = {
  expirationDate: Maybe<Scalars["String"]["output"]>;
  giftCode: Maybe<Scalars["String"]["output"]>;
  redeemedAt: Maybe<Scalars["String"]["output"]>;
  remainingAmount: Maybe<Scalars["String"]["output"]>;
  totalAmount: Maybe<Scalars["String"]["output"]>;
};

export type GiftProduct = {
  discountUsage: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  usage: Scalars["Int"]["output"];
  variationId: Scalars["ID"]["output"];
};

export type Image = {
  isFeatured: Maybe<Scalars["Boolean"]["output"]>;
  priority: Maybe<Scalars["Int"]["output"]>;
  urls: Maybe<Array<Maybe<ImageUrl>>>;
};

export type ImageUrl = {
  size: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type Info = {
  birthdate: Maybe<Scalars["String"]["output"]>;
  email: Maybe<Scalars["String"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  lastName: Maybe<Scalars["String"]["output"]>;
  telephone: Maybe<Scalars["String"]["output"]>;
  telephoneAlt: Maybe<Scalars["String"]["output"]>;
};

export type Ingredient = {
  allergies: Maybe<Array<Maybe<Allergy>>>;
  amount: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Image>;
  imagesExtra: Maybe<Image>;
  ingredientAmountType: Maybe<Scalars["String"]["output"]>;
  isBasis: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  order: Maybe<Scalars["String"]["output"]>;
};

export type IngredientByCountryCode = {
  categoryId: Maybe<Scalars["Int"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
};

export type IngredientCategory = {
  ancestorIds: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  ingredientCategoryId: Maybe<Scalars["Int"]["output"]>;
  ingredientCategoryName: Maybe<Scalars["String"]["output"]>;
  parentIngredientCategoryId: Maybe<Scalars["Int"]["output"]>;
};

export type IngredientSection = {
  ingredients: Maybe<Array<Maybe<Ingredient>>>;
  sectionId: Maybe<Scalars["String"]["output"]>;
  sectionTitle: Maybe<Scalars["String"]["output"]>;
};

export type LogisticInfo = {
  colliLines: Maybe<Array<Maybe<ColliLine>>>;
  /** @deprecated Deprecated */
  estimatedDeliveryTime: Maybe<Scalars["String"]["output"]>;
  orderId: Maybe<Scalars["Int"]["output"]>;
};

export type LoyaltyBenefit = {
  id: Scalars["ID"]["output"];
  levelCompanyId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  value: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyEntry = {
  agreementId: Scalars["ID"]["output"];
  expirationDate: Scalars["String"]["output"];
  points: Scalars["Int"]["output"];
};

export type LoyaltyLevel = {
  benefits: Array<Maybe<LoyaltyBenefit>>;
  companyId: Scalars["ID"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  level: Scalars["Int"]["output"];
  levelId: Scalars["ID"]["output"];
  multiplier: Scalars["Float"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  requirement: Scalars["Int"]["output"];
  retainableLevel: LoyaltyLevel;
  retainableLevelId: Scalars["ID"]["output"];
  retainablePoints: Scalars["Int"]["output"];
};

export type LoyaltyPoints = {
  loyaltyPoints: Scalars["Int"]["output"];
  orderId: Scalars["ID"]["output"];
};

export type MinimalRecipeData = {
  averageRating: Maybe<Scalars["Float"]["output"]>;
  beverageSuggestion: Maybe<Scalars["String"]["output"]>;
  cookingTimeMax: Maybe<Scalars["String"]["output"]>;
  cookingTimeMin: Maybe<Scalars["String"]["output"]>;
  customerRating: Maybe<CustomerRating>;
  generalText: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Image>;
  imagesExtra: Maybe<Image>;
  ingredientIds: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  language: Maybe<Scalars["String"]["output"]>;
  mainIngredient: Maybe<Scalars["String"]["output"]>;
  mainRecipeId: Maybe<Scalars["Int"]["output"]>;
  numberOfRatings: Maybe<Scalars["Int"]["output"]>;
  portions: Array<Maybe<PortionPm>>;
  rating: Maybe<Scalars["Int"]["output"]>;
  recipeDescription: Maybe<Scalars["String"]["output"]>;
  recipeId: Maybe<Scalars["Int"]["output"]>;
  recipeName: Maybe<Scalars["String"]["output"]>;
  recipeNameHeadline: Maybe<Scalars["String"]["output"]>;
  recipeNameSubheadline: Maybe<Scalars["String"]["output"]>;
  shelfLife: Maybe<Scalars["Int"]["output"]>;
  taxonomies: Array<Maybe<Taxonomy>>;
};

export type NutritionFact = {
  ingredientName: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  unit: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["Float"]["output"]>;
};

export type NutritionFacts = {
  carbsKcal: Maybe<Scalars["Float"]["output"]>;
  fatKcal: Maybe<Scalars["Float"]["output"]>;
  kcal: Maybe<Scalars["Float"]["output"]>;
  kcalPerPortion: Maybe<Scalars["Float"]["output"]>;
  proteinKcal: Maybe<Scalars["Float"]["output"]>;
  recipeNutritionPer100g: Maybe<RecipeNutritionInfo>;
  recipeNutritionPerPortion: Maybe<RecipeNutritionInfo>;
  totalWeight: Maybe<Scalars["Float"]["output"]>;
};

export type Order = {
  /** @deprecated Will be removed: Fetch discount information from orderLines */
  appliedDiscounts: Maybe<Array<Maybe<AppliedDiscountModel>>>;
  deliveryAddress: Maybe<AddressTuple>;
  deliveryDate: Maybe<Scalars["String"]["output"]>;
  finalPrice: Maybe<Scalars["Float"]["output"]>;
  hasRecipeLeaflets: Maybe<Scalars["Boolean"]["output"]>;
  id: Maybe<Scalars["ID"]["output"]>;
  logisticsInfo: Maybe<LogisticInfo>;
  loyaltyPoints: Maybe<LoyaltyPoints>;
  orderCampaign: Maybe<Scalars["String"]["output"]>;
  orderCampaignId: Maybe<Scalars["ID"]["output"]>;
  orderId: Maybe<Scalars["Int"]["output"]>;
  orderLines: Maybe<Array<Maybe<OrderLine>>>;
  orderStatus: Maybe<Scalars["String"]["output"]>;
  orderTypeId: Maybe<Scalars["ID"]["output"]>;
  paymentData: Maybe<PaymentData>;
  realtimeEstimate: Maybe<RealtimeEstimate>;
  sentToPaymentPartner: Maybe<Scalars["Boolean"]["output"]>;
  timeblockId: Maybe<Scalars["Int"]["output"]>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type OrderDeliveryInformation = {
  deviationId: Maybe<Scalars["ID"]["output"]>;
  deviationName: Maybe<Scalars["String"]["output"]>;
  expectedDeliveryDate: Maybe<Scalars["String"]["output"]>;
  expectedDeliveryInterval: Maybe<Scalars["String"]["output"]>;
  expectedDeliveryTime: Maybe<Scalars["String"]["output"]>;
  orderId: Maybe<Scalars["ID"]["output"]>;
  orderTypeId: Maybe<Scalars["ID"]["output"]>;
  statusId: Maybe<Scalars["ID"]["output"]>;
  statusName: Maybe<Scalars["String"]["output"]>;
};

export type OrderLine = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  discountCoupon: Maybe<Scalars["String"]["output"]>;
  discountId: Maybe<Scalars["ID"]["output"]>;
  fullPrice: Maybe<Scalars["Float"]["output"]>;
  orderId: Maybe<Scalars["ID"]["output"]>;
  orderLineId: Maybe<Scalars["ID"]["output"]>;
  pricePerQuantity: Maybe<Scalars["Float"]["output"]>;
  quantity: Maybe<Scalars["Int"]["output"]>;
  recipes: Maybe<Array<Maybe<Recipe>>>;
  type: Maybe<Scalars["String"]["output"]>;
  variation: Maybe<Variation>;
  variationId: Maybe<Scalars["ID"]["output"]>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type PaginatedRecipeUniverse = {
  pagination: Maybe<Pagination>;
  recipes: Maybe<Array<Maybe<RecipeUniverse>>>;
};

export type PaginatedRecipes = {
  pageNumber: Maybe<Scalars["Int"]["output"]>;
  recipes: Maybe<Array<Maybe<Recipe>>>;
  resultsPerPage: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
};

export type Pagination = {
  pageNumber: Maybe<Scalars["Int"]["output"]>;
  resultsPerPage: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
  totalResults: Maybe<Scalars["Int"]["output"]>;
};

export type PaymentCard = {
  default: Maybe<Scalars["Boolean"]["output"]>;
  expMonth: Maybe<Scalars["Int"]["output"]>;
  expYear: Maybe<Scalars["Int"]["output"]>;
  last4: Maybe<Scalars["String"]["output"]>;
  paymentCardId: Maybe<Scalars["ID"]["output"]>;
  tokenType: Maybe<Scalars["String"]["output"]>;
};

export type PaymentData = {
  invoiceNumber: Maybe<Scalars["Int"]["output"]>;
  invoiceProcessStatusId: Maybe<Scalars["ID"]["output"]>;
  orderId: Maybe<Scalars["ID"]["output"]>;
  paymentPartnerId: Maybe<Scalars["ID"]["output"]>;
  paymentPartnerInfo: Maybe<PaymentPartnerInfo>;
  paymentStatus: Maybe<Scalars["String"]["output"]>;
  paymentStatusId: Maybe<Scalars["ID"]["output"]>;
  sentToInvoicePartner: Maybe<Scalars["Boolean"]["output"]>;
  tokenType: Maybe<Scalars["String"]["output"]>;
};

export type PaymentPartnerData = {
  header: Maybe<Scalars["String"]["output"]>;
  klarna_paynow: Maybe<Scalars["String"]["output"]>;
  paymentMethodCode: Maybe<Scalars["String"]["output"]>;
  scheme: Maybe<Scalars["String"]["output"]>;
  subtitle: Maybe<Scalars["String"]["output"]>;
  vipps: Maybe<Scalars["String"]["output"]>;
};

export type PaymentPartnerInfo = {
  paymentPartnerId: Maybe<Scalars["ID"]["output"]>;
  paymentPartnerMethodCode: Maybe<Scalars["String"]["output"]>;
  paymentPartnerName: Maybe<Scalars["String"]["output"]>;
};

export type PaymentPartners = {
  allowsAnonymousAccess: Maybe<Scalars["Boolean"]["output"]>;
  orderTypeId: Maybe<Scalars["ID"]["output"]>;
  orderTypeName: Maybe<Scalars["String"]["output"]>;
  paymentPartnerData: Maybe<PaymentPartnerData>;
  paymentPartnerId: Maybe<Scalars["ID"]["output"]>;
  paymentPartnerName: Maybe<Scalars["String"]["output"]>;
  paymentPartnerType: Maybe<Scalars["String"]["output"]>;
  requiresExternalPaymentFlow: Maybe<Scalars["Boolean"]["output"]>;
};

export type PickAndMixWeeklyProducts = {
  companyId: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  product: Maybe<Product>;
  productId: Scalars["ID"]["output"];
  productTypeId: Scalars["ID"]["output"];
  recipeData: Maybe<Array<Maybe<MinimalRecipeData>>>;
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type PlannedDeliveryInformation = {
  actualTimeblockId: Maybe<Scalars["Int"]["output"]>;
  city: Maybe<Scalars["String"]["output"]>;
  companyId: Scalars["ID"]["output"];
  customerFee: Maybe<Scalars["Float"]["output"]>;
  cutOffDateTime: Maybe<Scalars["String"]["output"]>;
  deliveryDate: Maybe<Scalars["String"]["output"]>;
  deliveryWeekType: Maybe<Scalars["Int"]["output"]>;
  from: Maybe<Scalars["String"]["output"]>;
  isAvailableForDelivery: Scalars["Boolean"]["output"];
  isFallback: Scalars["Boolean"]["output"];
  normalTimeblockId: Scalars["Int"]["output"];
  postalCode: Scalars["String"]["output"];
  startDate: Maybe<Scalars["String"]["output"]>;
  to: Maybe<Scalars["String"]["output"]>;
  week: Scalars["Int"]["output"];
  weekDay: Maybe<Scalars["Int"]["output"]>;
  year: Scalars["Int"]["output"];
  zoneId: Maybe<Scalars["Int"]["output"]>;
};

export type PortionPm = {
  chefPartnerScore: Maybe<Scalars["String"]["output"]>;
  companyId: Scalars["ID"]["output"];
  energyKcal: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["Int"]["output"];
  isPlus: Maybe<Scalars["Boolean"]["output"]>;
  keyHole: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  organicPerc: Maybe<Scalars["Float"]["output"]>;
  pricing: Maybe<PortionPmPricing>;
  size: Scalars["Int"]["output"];
  stock: Maybe<Scalars["Int"]["output"]>;
  variationId: Scalars["ID"]["output"];
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type PortionPmPricing = {
  campaignDiscount: Maybe<Scalars["Float"]["output"]>;
  finalPrice: Maybe<Scalars["Float"]["output"]>;
  priceNoVat: Maybe<Scalars["Float"]["output"]>;
  regularPrice: Maybe<Scalars["Float"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
  vat: Maybe<Scalars["Float"]["output"]>;
};

export type Portions = {
  allergies: Maybe<Array<Maybe<Allergy>>>;
  chefPartnerScore: Maybe<Scalars["String"]["output"]>;
  ingredientSections: Maybe<Array<Maybe<IngredientSection>>>;
  keyHole: Maybe<Scalars["Boolean"]["output"]>;
  nutritionFacts: Maybe<NutritionFacts>;
  organicPerc: Maybe<Scalars["Float"]["output"]>;
  pdfUrl: Maybe<Scalars["String"]["output"]>;
  size: Maybe<Scalars["String"]["output"]>;
  stepSections: Maybe<Array<Maybe<StepSection>>>;
};

export type PostalCodeInfo = {
  city: Maybe<Scalars["String"]["output"]>;
  timeblocks: Array<Maybe<Timeblock>>;
};

export type PreferenceAttributes = {
  name: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type Preferences = {
  attributes: Maybe<Array<Maybe<PreferenceAttributes>>>;
  description: Maybe<Scalars["String"]["output"]>;
  isAllergen: Maybe<Scalars["Boolean"]["output"]>;
  preferenceId: Maybe<Scalars["ID"]["output"]>;
  preferenceName: Maybe<Scalars["String"]["output"]>;
  preferenceTypeId: Maybe<Scalars["ID"]["output"]>;
  preferenceTypeName: Maybe<Scalars["String"]["output"]>;
};

export type PreselectedWeeklyProducts = {
  companyId: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  product: Maybe<Product>;
  productId: Scalars["ID"]["output"];
  productTypeId: Scalars["ID"]["output"];
  recipeData: Maybe<Array<Maybe<MinimalRecipeData>>>;
  recipeOrder: Scalars["Int"]["output"];
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type PreselectorPreview = {
  recipeIds: Array<Maybe<Scalars["Int"]["output"]>>;
};

export type Product = {
  attributes: Maybe<Array<Maybe<Attribute>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Array<Maybe<ProductImage>>>;
  name: Maybe<Scalars["String"]["output"]>;
  productGroup: Maybe<Product>;
  productGroupId: Maybe<Scalars["ID"]["output"]>;
  productId: Maybe<Scalars["ID"]["output"]>;
  productTypeId: Maybe<Scalars["ID"]["output"]>;
  productTypeName: Maybe<Scalars["String"]["output"]>;
  variations: Maybe<Array<Maybe<Variation>>>;
};

export type ProductCategory = {
  childCategories: Maybe<Array<Maybe<ChildProductCategories>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  companyName: Maybe<Scalars["String"]["output"]>;
  completeSlug: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  hasProducts: Maybe<Scalars["Boolean"]["output"]>;
  metaDescription: Maybe<Scalars["String"]["output"]>;
  metaTitle: Maybe<Scalars["String"]["output"]>;
  parentCategoryId: Maybe<Scalars["ID"]["output"]>;
  parentCategoryName: Maybe<Scalars["String"]["output"]>;
  productCategoryId: Maybe<Scalars["ID"]["output"]>;
  productCategoryName: Maybe<Scalars["String"]["output"]>;
  shortName: Maybe<Scalars["String"]["output"]>;
  slug: Maybe<Scalars["String"]["output"]>;
  sortingValue: Maybe<Scalars["Int"]["output"]>;
};

export type ProductCycle = {
  stock: Maybe<Scalars["String"]["output"]>;
  week: Maybe<Scalars["String"]["output"]>;
  year: Maybe<Scalars["String"]["output"]>;
};

export type ProductImage = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  filename: Maybe<Scalars["String"]["output"]>;
  isFeatured: Maybe<Scalars["Boolean"]["output"]>;
  priority: Maybe<Scalars["Int"]["output"]>;
  productId: Maybe<Scalars["ID"]["output"]>;
  urls: Array<Maybe<ImageUrl>>;
};

export type ProductsByCategory = {
  childCategories: Maybe<Array<Maybe<ChildProductCategories>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  companyName: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  hasProducts: Maybe<Scalars["Boolean"]["output"]>;
  metaDescription: Maybe<Scalars["String"]["output"]>;
  metaTitle: Maybe<Scalars["String"]["output"]>;
  parentCategoryId: Maybe<Scalars["ID"]["output"]>;
  parentCategoryName: Maybe<Scalars["String"]["output"]>;
  productCategoryId: Maybe<Scalars["ID"]["output"]>;
  productCategoryName: Maybe<Scalars["String"]["output"]>;
  products: Maybe<Array<Maybe<Product>>>;
  shortName: Maybe<Scalars["String"]["output"]>;
  slug: Maybe<Scalars["String"]["output"]>;
};

export type ProductsWithPreselected = {
  preselectedProducts: Maybe<Array<Maybe<PreselectedWeeklyProducts>>>;
  productId: Scalars["ID"]["output"];
};

export type Query = {
  availableExternalPartners: Array<Maybe<ExternalPartner>>;
  billing: Maybe<BillingAgreement>;
  calendar: Maybe<Array<Maybe<WeeklyCalendar>>>;
  defaultCoupon: Maybe<DefaultCoupon>;
  deviationProducts: Maybe<Array<Maybe<DeviationProducts>>>;
  deviations: Maybe<Array<Maybe<Deviations>>>;
  externalBillingIdentifiers: Array<Maybe<ExternalIdentifier>>;
  getAvailablePaymentPartners: Maybe<Array<Maybe<PaymentPartners>>>;
  getConsents: Maybe<Array<Maybe<Consents>>>;
  getCustomerIngredientsForOrder: Maybe<Array<Maybe<CustomerIngredients>>>;
  getIngredientCategoriesByCompany: Maybe<Array<Maybe<IngredientCategory>>>;
  getPostalCodeAndTimeblocks: Maybe<PostalCodeInfo>;
  getPreferences: Maybe<Array<Maybe<Preferences>>>;
  getPreselectedProductsForWeek: Maybe<Array<Maybe<ProductsWithPreselected>>>;
  getPreselectorPreview: Maybe<PreselectorPreview>;
  getProductCategories: Maybe<Array<Maybe<ProductCategory>>>;
  getProductsByCategories: Maybe<Array<Maybe<ProductsByCategory>>>;
  getRecipesByIngredientCategories: Maybe<PaginatedRecipes>;
  getRecipesByTaxonomyIdList: Maybe<Array<Maybe<RecipeByTaxonomyId>>>;
  getTaxonomyRelations: Maybe<Array<Maybe<TaxonomyHierarchy>>>;
  getTimeblocks: Maybe<Array<Maybe<Timeblock>>>;
  ingredientsByCountryCode: Maybe<Array<Maybe<IngredientByCountryCode>>>;
  loyaltyEntries: Array<Maybe<LoyaltyEntry>>;
  loyaltyLevelByUser: Maybe<AgreementLoyaltyLevel>;
  loyaltyLevelsByCompany: Array<Maybe<LoyaltyLevel>>;
  order: Maybe<Order>;
  orders: Maybe<Array<Maybe<Order>>>;
  ordersDeliveryInformation: Maybe<Array<Maybe<OrderDeliveryInformation>>>;
  pickAndMix: Maybe<Array<Maybe<PickAndMixWeeklyProducts>>>;
  products: Maybe<Array<Maybe<Product>>>;
  recipeAndSteps: Maybe<Recipe>;
  recipeUniverse: Maybe<PaginatedRecipeUniverse>;
  recipesAgreementFavorites: Maybe<RecipesAgreementFavorites>;
  recipesAgreementRatings: Maybe<RecipesAgreementRatings>;
  recipesByIds: Maybe<Array<Maybe<BasicRecipe>>>;
  recipesByProductIdList: Maybe<Array<Maybe<WeekYearProduct>>>;
  recruitmentData: Maybe<RecruitmentData>;
  timeblocksByRange: Maybe<Array<Maybe<TimeblocksByRange>>>;
  userAddresses: Maybe<Array<Maybe<Address>>>;
  userCredits: Maybe<Array<Maybe<UserCredits>>>;
  userDiscounts: Maybe<Array<Maybe<UserDiscount>>>;
  userGiftCards: Maybe<Array<Maybe<GiftCard>>>;
  userInfo: Maybe<Info>;
};

export type QueryAvailableExternalPartnersArgs = {
  companyId: Scalars["ID"]["input"];
};

export type QueryCalendarArgs = {
  range?: InputMaybe<Scalars["Int"]["input"]>;
  week?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryDefaultCouponArgs = {
  companyId: Scalars["ID"]["input"];
};

export type QueryDeviationProductsArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryDeviationsArgs = {
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type QueryGetAvailablePaymentPartnersArgs = {
  companyId: Scalars["ID"]["input"];
  orderTypeId: Scalars["ID"]["input"];
};

export type QueryGetConsentsArgs = {
  companyId: Scalars["ID"]["input"];
};

export type QueryGetCustomerIngredientsForOrderArgs = {
  orderId: Scalars["ID"]["input"];
};

export type QueryGetIngredientCategoriesByCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  language: Scalars["String"]["input"];
};

export type QueryGetPostalCodeAndTimeblocksArgs = {
  companyId: Scalars["ID"]["input"];
  postalCode: Scalars["String"]["input"];
  timeblockTypeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetPreferencesArgs = {
  companyId: Scalars["ID"]["input"];
};

export type QueryGetPreselectedProductsForWeekArgs = {
  companyId: Scalars["ID"]["input"];
  language: Scalars["String"]["input"];
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type QueryGetPreselectorPreviewArgs = {
  attributeIds: Array<InputMaybe<Scalars["String"]["input"]>>;
  companyId: Scalars["ID"]["input"];
  portions: Scalars["Int"]["input"];
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type QueryGetProductCategoriesArgs = {
  companyId: Scalars["ID"]["input"];
};

export type QueryGetProductsByCategoriesArgs = {
  categoryIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  companyId: Scalars["ID"]["input"];
};

export type QueryGetRecipesByIngredientCategoriesArgs = {
  companyId: Scalars["ID"]["input"];
  ingredientCategoryIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  language: Scalars["String"]["input"];
  pageNumber: Scalars["Int"]["input"];
  resultsPerPage: Scalars["Int"]["input"];
};

export type QueryGetRecipesByTaxonomyIdListArgs = {
  companyId: Scalars["ID"]["input"];
  language: Scalars["String"]["input"];
  range?: InputMaybe<Scalars["Int"]["input"]>;
  start_num?: InputMaybe<Scalars["Int"]["input"]>;
  taxonomyIds: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type QueryGetTaxonomyRelationsArgs = {
  companyId: Scalars["ID"]["input"];
  language: Scalars["String"]["input"];
};

export type QueryGetTimeblocksArgs = {
  companyId: Scalars["ID"]["input"];
  postalCode: Scalars["String"]["input"];
  timeblockTypeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryIngredientsByCountryCodeArgs = {
  language: Scalars["String"]["input"];
};

export type QueryLoyaltyLevelsByCompanyArgs = {
  companyId: Scalars["ID"]["input"];
};

export type QueryOrderArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  orderId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrdersArgs = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  orderStatuses?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  orderTypes?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrdersDeliveryInformationArgs = {
  orderTypes: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type QueryPickAndMixArgs = {
  companyId: Scalars["ID"]["input"];
  language: Scalars["String"]["input"];
  productTypeId: Scalars["ID"]["input"];
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type QueryProductsArgs = {
  companyId: Scalars["ID"]["input"];
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  week?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryRecipeAndStepsArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
  recipeId: Scalars["Int"]["input"];
};

export type QueryRecipeUniverseArgs = {
  companyId: Scalars["ID"]["input"];
  pageNumber: Scalars["Int"]["input"];
  resultsPerPage: Scalars["Int"]["input"];
};

export type QueryRecipesAgreementFavoritesArgs = {
  pageNumber: Scalars["Int"]["input"];
  resultsPerPage: Scalars["Int"]["input"];
  week?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryRecipesByIdsArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
  recipeIds: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type QueryRecipesByProductIdListArgs = {
  companyId?: InputMaybe<Scalars["ID"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  productIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  range: Scalars["Int"]["input"];
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type QueryRecruitmentDataArgs = {
  from?: InputMaybe<Scalars["String"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTimeblocksByRangeArgs = {
  companyId: Scalars["ID"]["input"];
  postalCode: Scalars["String"]["input"];
  range: Scalars["Int"]["input"];
  timeblockTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  variationIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type RealtimeEstimate = {
  confirmedDeliveryTime: Maybe<Scalars["String"]["output"]>;
  estimatedTimeFrom: Maybe<Scalars["String"]["output"]>;
  estimatedTimeTo: Maybe<Scalars["String"]["output"]>;
  orderId: Scalars["ID"]["output"];
  trackingLink: Maybe<Scalars["String"]["output"]>;
};

export type Recipe = {
  /** @deprecated Use averageRating instead */
  average: Maybe<Scalars["String"]["output"]>;
  averageRating: Maybe<Scalars["Float"]["output"]>;
  beverageSuggestion: Maybe<Scalars["String"]["output"]>;
  chefTip: Maybe<Scalars["String"]["output"]>;
  cookingTimeMax: Maybe<Scalars["String"]["output"]>;
  cookingTimeMin: Maybe<Scalars["String"]["output"]>;
  customerRating: Maybe<CustomerRating>;
  energyKcal: Maybe<Scalars["String"]["output"]>;
  generalText: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Image>;
  imagesExtra: Maybe<Image>;
  ingredientCategoryId: Maybe<Scalars["Int"]["output"]>;
  instructions: Maybe<RecipeInfo>;
  language: Maybe<Scalars["String"]["output"]>;
  mainIngredient: Maybe<Scalars["String"]["output"]>;
  mainRecipeId: Maybe<Scalars["Int"]["output"]>;
  numberOfRatings: Maybe<Scalars["Int"]["output"]>;
  portionId: Maybe<Scalars["Int"]["output"]>;
  recipeDescription: Maybe<Scalars["String"]["output"]>;
  recipeId: Maybe<Scalars["Int"]["output"]>;
  recipeName: Maybe<Scalars["String"]["output"]>;
  recipeNameHeadline: Maybe<Scalars["String"]["output"]>;
  recipeNameSubheadline: Maybe<Scalars["String"]["output"]>;
  shelfLife: Maybe<Scalars["Int"]["output"]>;
  taxonomies: Maybe<Array<Maybe<Taxonomy>>>;
};

export type RecipeByTaxonomyId = {
  companyId: Maybe<Scalars["String"]["output"]>;
  languageId: Maybe<Scalars["Int"]["output"]>;
  recipes: Maybe<Array<Maybe<Recipe>>>;
  rowNumber: Maybe<Scalars["Int"]["output"]>;
  taxonomyCount: Maybe<Scalars["Int"]["output"]>;
  taxonomyId: Maybe<Scalars["Int"]["output"]>;
  taxonomyName: Maybe<Scalars["String"]["output"]>;
  taxonomyType: Maybe<Scalars["Int"]["output"]>;
};

export type RecipeInfo = {
  portions: Maybe<Array<Maybe<Portions>>>;
};

export type RecipeInfoOld = {
  portions: Maybe<Array<Maybe<Portions>>>;
  recipeId: Maybe<Scalars["ID"]["output"]>;
};

export type RecipeNutritionInfo = {
  carbs: Maybe<Scalars["Float"]["output"]>;
  carbsFiber: Maybe<Scalars["Float"]["output"]>;
  carbsSugar: Maybe<Scalars["Float"]["output"]>;
  energyKcal: Maybe<Scalars["Float"]["output"]>;
  energyKj: Maybe<Scalars["Float"]["output"]>;
  fat: Maybe<Scalars["Float"]["output"]>;
  fatSaturated: Maybe<Scalars["Float"]["output"]>;
  protein: Maybe<Scalars["Float"]["output"]>;
  salt: Maybe<Scalars["Float"]["output"]>;
};

export type RecipeOld = {
  average: Maybe<Scalars["String"]["output"]>;
  beverageSuggestion: Maybe<Scalars["String"]["output"]>;
  chefTip: Maybe<Scalars["String"]["output"]>;
  comments: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  cookingTimeMax: Maybe<Scalars["String"]["output"]>;
  cookingTimeMin: Maybe<Scalars["String"]["output"]>;
  generalText: Maybe<Scalars["String"]["output"]>;
  images: Maybe<Image>;
  imagesExtra: Maybe<Image>;
  instructions: Maybe<RecipeInfoOld>;
  language: Maybe<Scalars["String"]["output"]>;
  mainIngredient: Maybe<Scalars["String"]["output"]>;
  mainRecipeId: Maybe<Scalars["Int"]["output"]>;
  numberOfRatings: Maybe<Scalars["Int"]["output"]>;
  rating: Maybe<Scalars["String"]["output"]>;
  recipeDescription: Maybe<Scalars["String"]["output"]>;
  recipeId: Maybe<Scalars["Int"]["output"]>;
  recipeName: Maybe<Scalars["String"]["output"]>;
  recipeNameHeadline: Maybe<Scalars["String"]["output"]>;
  recipeNameSubheadline: Maybe<Scalars["String"]["output"]>;
  shelfLife: Maybe<Scalars["Int"]["output"]>;
  taxonomies: Maybe<Array<Maybe<Taxonomy>>>;
};

export type RecipeProduct = {
  productId: Maybe<Scalars["ID"]["output"]>;
  recipes: Maybe<Array<Maybe<RecipeOld>>>;
};

export type RecipeRating = {
  comment: Maybe<Scalars["String"]["output"]>;
  rating: Maybe<Scalars["Int"]["output"]>;
  recipeId: Maybe<Scalars["Int"]["output"]>;
};

export type RecipeUniverse = {
  beverageSuggestion: Maybe<Scalars["String"]["output"]>;
  cookingTimeMin: Maybe<Scalars["Float"]["output"]>;
  cookintTimeMax: Maybe<Scalars["Float"]["output"]>;
  images: Maybe<Image>;
  imagesExtra: Maybe<Image>;
  language: Maybe<Scalars["String"]["output"]>;
  mainIngredient: Maybe<Scalars["String"]["output"]>;
  recipeDescription: Maybe<Scalars["String"]["output"]>;
  recipeId: Maybe<Scalars["Int"]["output"]>;
  recipeName: Maybe<Scalars["String"]["output"]>;
  recipeNameHeadline: Maybe<Scalars["String"]["output"]>;
  recipeNameSubheadline: Maybe<Scalars["String"]["output"]>;
  taxonomies: Maybe<Array<Maybe<TaxonomyUniverse>>>;
};

export type RecipesAgreementFavorites = {
  agreementId: Scalars["Int"]["output"];
  recipeFavorites: Maybe<Array<Maybe<FavoriteRecipes>>>;
};

export type RecipesAgreementRatings = {
  agreementId: Maybe<Scalars["Int"]["output"]>;
  recipeRatings: Maybe<Array<Maybe<RecipeRating>>>;
};

export type RecruitmentData = {
  recruitedCustomers: Maybe<Scalars["Int"]["output"]>;
  recruitmentInvitations: Maybe<Scalars["Int"]["output"]>;
  redeemedDiscounts: Maybe<Scalars["Int"]["output"]>;
};

export type Scheduler = {
  deliveryType: Maybe<Scalars["Int"]["output"]>;
  hasDelivery: Maybe<Scalars["Boolean"]["output"]>;
  schedulerId: Maybe<Scalars["ID"]["output"]>;
  shippingAddressId: Maybe<Scalars["ID"]["output"]>;
  timeblock: Maybe<Scalars["Int"]["output"]>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type StandaloneGroceryPartialInformation = {
  allergies: Maybe<Array<Maybe<AllergyInformation>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  ingredient: Maybe<Scalars["String"]["output"]>;
  ingredientId: Maybe<Scalars["ID"]["output"]>;
  menuVariationExtId: Maybe<Scalars["ID"]["output"]>;
  nettoWeight: Maybe<Scalars["Float"]["output"]>;
  nutritionFacts: Maybe<Array<Maybe<NutritionFact>>>;
  supplierName: Maybe<Scalars["String"]["output"]>;
  unitLabelName: Maybe<Scalars["String"]["output"]>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type Step = {
  order: Maybe<Scalars["Int"]["output"]>;
  step: Maybe<Scalars["String"]["output"]>;
};

export type StepSection = {
  sectionId: Maybe<Scalars["String"]["output"]>;
  sectionTitle: Maybe<Scalars["String"]["output"]>;
  steps: Maybe<Array<Maybe<Step>>>;
};

export type SubTaxonomy = {
  subTaxonomyDescription: Maybe<Scalars["String"]["output"]>;
  subTaxonomyId: Maybe<Scalars["Int"]["output"]>;
  subTaxonomyName: Maybe<Scalars["String"]["output"]>;
};

export type Subscription = {
  active: Maybe<Scalars["Boolean"]["output"]>;
  endDate: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  startDate: Maybe<Scalars["String"]["output"]>;
  subscriptionId: Maybe<Scalars["ID"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type Taxonomy = {
  description: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  order: Maybe<Scalars["Int"]["output"]>;
  taxonomyId: Maybe<Scalars["Int"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type TaxonomyHierarchy = {
  subTaxonomies: Maybe<Array<Maybe<SubTaxonomy>>>;
  topTaxonomyDescription: Maybe<Scalars["String"]["output"]>;
  topTaxonomyId: Maybe<Scalars["Int"]["output"]>;
  topTaxonomyName: Maybe<Scalars["String"]["output"]>;
};

export type TaxonomyUniverse = {
  description: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  order: Maybe<Scalars["Int"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
};

export type Timeblock = {
  companyId: Scalars["ID"]["output"];
  customerFee: Scalars["String"]["output"];
  deliveryWeekType: Scalars["String"]["output"];
  firstDeliveryDate: Scalars["String"]["output"];
  from: Scalars["String"]["output"];
  startDate: Maybe<Scalars["String"]["output"]>;
  /** @deprecated should be called timeblockId */
  timeBlockID: Maybe<Scalars["Int"]["output"]>;
  timeblockId: Scalars["Int"]["output"];
  timeblockTypeId: Scalars["ID"]["output"];
  to: Scalars["String"]["output"];
  weekday: Scalars["Int"]["output"];
};

export type TimeblockExtended = {
  city: Scalars["String"]["output"];
  customerFee: Maybe<Scalars["String"]["output"]>;
  cutOffDateTime: Maybe<Scalars["String"]["output"]>;
  deliveryDate: Maybe<Scalars["String"]["output"]>;
  deliveryWeekType: Scalars["String"]["output"];
  /** @deprecated should be called fallbackTimeblockId, lol js */
  fallBackTimeblockId: Maybe<Scalars["Int"]["output"]>;
  fallbackTimeblockId: Maybe<Scalars["Int"]["output"]>;
  firstDeliveryDate: Maybe<Scalars["String"]["output"]>;
  from: Scalars["String"]["output"];
  noDelivery: Scalars["Boolean"]["output"];
  postalCode: Scalars["String"]["output"];
  registrationTypeId: Maybe<Scalars["ID"]["output"]>;
  startDate: Maybe<Scalars["String"]["output"]>;
  /** @deprecated should be called timeblockId */
  timeBlockID: Maybe<Scalars["Int"]["output"]>;
  timeblockId: Scalars["Int"]["output"];
  timeblockTypeId: Scalars["ID"]["output"];
  to: Scalars["String"]["output"];
  week: Scalars["Int"]["output"];
  weekday: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type TimeblocksByRange = {
  city: Maybe<Scalars["String"]["output"]>;
  postalCode: Maybe<Scalars["String"]["output"]>;
  timeblocks: Maybe<Array<Maybe<TimeblockExtended>>>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type UserConsent = {
  categoryConsentId: Maybe<Scalars["ID"]["output"]>;
  categoryConsentName: Maybe<Scalars["String"]["output"]>;
  consentId: Maybe<Scalars["ID"]["output"]>;
  consentName: Maybe<Scalars["String"]["output"]>;
  isAccepted: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserCredits = {
  creditTypeId: Scalars["ID"]["output"];
  details: Maybe<Scalars["String"]["output"]>;
  expirationDate: Maybe<Scalars["String"]["output"]>;
  remainingAmount: Scalars["Float"]["output"];
};

export type UserDiscount = {
  agreementDiscountId: Maybe<Scalars["ID"]["output"]>;
  amount: Maybe<Scalars["String"]["output"]>;
  chainedDiscounts: Array<Maybe<UserDiscountMinimalData>>;
  createdAt: Maybe<Scalars["String"]["output"]>;
  criterias: Maybe<Array<Maybe<DiscountCriteria>>>;
  description: Maybe<Scalars["String"]["output"]>;
  disableOnFreeze: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated replaced by chainedDiscounts */
  discountChain: Maybe<Array<Maybe<UserDiscountMinimalData>>>;
  discountId: Maybe<Scalars["ID"]["output"]>;
  giftProducts: Maybe<Array<Maybe<DiscountGiftProduct>>>;
  hasChain: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated replaced by hasChain */
  isChain: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  usesLeft: Maybe<Scalars["Int"]["output"]>;
  validEndDate: Maybe<Scalars["String"]["output"]>;
};

export type UserDiscountMinimalData = {
  amount: Scalars["Float"]["output"];
  companyId: Scalars["ID"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  disableOnFreeze: Scalars["Boolean"]["output"];
  discountId: Scalars["ID"]["output"];
  initialUsage: Maybe<Scalars["Int"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  order: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
};

export type UserPreferenceAttributes = {
  name: Maybe<Scalars["String"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type UserPreferences = {
  attributes: Maybe<Array<Maybe<UserPreferenceAttributes>>>;
  id: Maybe<Scalars["ID"]["output"]>;
  isAllergen: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  /** @deprecated replaced by id */
  preferenceId: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated replaced by isAllergen */
  preferenceIsAllergen: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated replaced by name */
  preferenceName: Maybe<Scalars["String"]["output"]>;
  /** @deprecated replaced by priority */
  preferencePriority: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated replaced by typeId */
  preferenceTypeId: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated replaced by typeName */
  preferenceTypeName: Maybe<Scalars["String"]["output"]>;
  priority: Maybe<Scalars["Int"]["output"]>;
  typeId: Maybe<Scalars["ID"]["output"]>;
  typeName: Maybe<Scalars["String"]["output"]>;
};

export type Variation = {
  attributes: Maybe<Array<Maybe<Attribute>>>;
  bundledVariations: Maybe<Array<Maybe<BundleVariation>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  contents: Maybe<StandaloneGroceryPartialInformation>;
  description: Maybe<Scalars["String"]["output"]>;
  finalPrice: Maybe<Scalars["Float"]["output"]>;
  flexiblePrice: Maybe<FlexiblePriceV2>;
  /** @deprecated replaced by flexiblePriceV2, more efficient and cacheable */
  flexiblePriceRange: Maybe<Array<Maybe<FlexiblePrice>>>;
  images: Array<Maybe<VariationImage>>;
  isBundle: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  product: Maybe<Product>;
  productCycleRange: Maybe<Array<Maybe<ProductCycle>>>;
  productId: Maybe<Scalars["ID"]["output"]>;
  sendFrontend: Maybe<Scalars["Boolean"]["output"]>;
  sendPIM: Maybe<Scalars["Boolean"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
  stock: Maybe<Scalars["Int"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type VariationContentsArgs = {
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type VariationFlexiblePriceArgs = {
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type VariationFlexiblePriceRangeArgs = {
  range?: InputMaybe<Scalars["Int"]["input"]>;
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type VariationProductCycleRangeArgs = {
  range?: InputMaybe<Scalars["Int"]["input"]>;
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type VariationImage = {
  companyId: Maybe<Scalars["ID"]["output"]>;
  filename: Maybe<Scalars["String"]["output"]>;
  isFeatured: Maybe<Scalars["Boolean"]["output"]>;
  priority: Maybe<Scalars["Int"]["output"]>;
  productId: Maybe<Scalars["ID"]["output"]>;
  urls: Array<Maybe<ImageUrl>>;
  variationId: Maybe<Scalars["ID"]["output"]>;
};

export type WeekYearProduct = {
  products: Maybe<Array<Maybe<RecipeProduct>>>;
  week: Maybe<Scalars["String"]["output"]>;
  year: Maybe<Scalars["String"]["output"]>;
};

export type WeeklyAddress = {
  addressCode: Maybe<Scalars["String"]["output"]>;
  addressDigitalKeyProviderId: Maybe<Scalars["ID"]["output"]>;
  addressUUID: Maybe<Scalars["ID"]["output"]>;
  agreementId: Scalars["Int"]["output"];
  alias: Scalars["String"]["output"];
  apartmentName: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  companyId: Scalars["ID"]["output"];
  country: Scalars["String"]["output"];
  digitalKeyProviderName: Maybe<Scalars["String"]["output"]>;
  doorCode: Maybe<Scalars["String"]["output"]>;
  flatNo: Maybe<Scalars["String"]["output"]>;
  floorNumber: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  instructionsToDriver: Maybe<Scalars["String"]["output"]>;
  isBilling: Scalars["Boolean"]["output"];
  isShipping: Maybe<Scalars["Boolean"]["output"]>;
  latitude: Maybe<Scalars["Float"]["output"]>;
  letterExtra: Maybe<Scalars["String"]["output"]>;
  longitude: Maybe<Scalars["Float"]["output"]>;
  postalCode: Scalars["Int"]["output"];
  street: Scalars["String"]["output"];
  streetName: Maybe<Scalars["String"]["output"]>;
  streetNumber: Maybe<Scalars["Int"]["output"]>;
  validated: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
  week: Scalars["Int"]["output"];
  weeklyTimeblocks: Maybe<Array<Maybe<TimeblockExtended>>>;
  year: Scalars["Int"]["output"];
};

export type WeeklyAddressWeeklyTimeblocksArgs = {
  timeblockTypeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type WeeklyCalendar = {
  agreementId: Scalars["Int"]["output"];
  baskets: Array<Maybe<WeeklyCalendarBasket>>;
  /** @deprecated doesnt work for stardates on sunday use 'isBeforeStartDateWeek' instead. */
  isBeforeStartDate: Scalars["Boolean"]["output"];
  isBeforeStartDateWeek: Scalars["Boolean"]["output"];
  preselectorBasket: Maybe<WeeklyCalendarPreselectorBasket>;
  startDate: Scalars["String"]["output"];
  status: Scalars["Int"]["output"];
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type WeeklyCalendarBasket = {
  basketDetails: WeeklyCalendarBasketDetails;
  basketId: Scalars["ID"]["output"];
  deliveryWeekType: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  shippingDetails: WeeklyCalendarBasketShippingDetails;
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type WeeklyCalendarBasketDetails = {
  isDeviation: Scalars["Boolean"]["output"];
  originId: Maybe<Scalars["ID"]["output"]>;
  originName: Maybe<Scalars["String"]["output"]>;
  products: Array<Maybe<WeeklyCalendarBasketProduct>>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type WeeklyCalendarBasketProduct = {
  companyId: Scalars["ID"]["output"];
  quantity: Scalars["Int"]["output"];
  variation: Maybe<WeeklyVariation>;
  variationId: Scalars["ID"]["output"];
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type WeeklyCalendarBasketShippingDetails = {
  addressId: Scalars["ID"]["output"];
  addressInfo: WeeklyAddress;
  companyId: Scalars["ID"]["output"];
  /** @deprecated No longer supported */
  dooCode: Maybe<Scalars["String"]["output"]>;
  doorCode: Maybe<Scalars["String"]["output"]>;
  firstDateOfWeek: Scalars["String"]["output"];
  hasDelivery: Scalars["Boolean"]["output"];
  isScheduler: Scalars["Boolean"]["output"];
  plannedDeliveryInformation: PlannedDeliveryInformation;
  postalCode: Scalars["String"]["output"];
  schedulerIsNoDelivery: Scalars["Boolean"]["output"];
  timeblockId: Scalars["Int"]["output"];
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type WeeklyCalendarPreselectorBasket = {
  variationIds: Array<Maybe<Scalars["ID"]["output"]>>;
  week: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type WeeklyVariation = {
  attributes: Maybe<Array<Maybe<Attribute>>>;
  bundledVariations: Maybe<Array<Maybe<BundleVariation>>>;
  companyId: Maybe<Scalars["ID"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  /** @deprecated replaced by flexiblePriceV2 more efficient and cacheable */
  flexiblePrice: Maybe<FlexiblePrice>;
  flexiblePriceV2: Maybe<FlexiblePriceV2>;
  images: Array<Maybe<VariationImage>>;
  isBundle: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  product: Maybe<Product>;
  productId: Maybe<Scalars["ID"]["output"]>;
  sendFrontend: Maybe<Scalars["Boolean"]["output"]>;
  sendPIM: Maybe<Scalars["Boolean"]["output"]>;
  sku: Maybe<Scalars["String"]["output"]>;
  stock: Maybe<Scalars["Int"]["output"]>;
  variationId: Maybe<Scalars["ID"]["output"]>;
  week: Maybe<Scalars["Int"]["output"]>;
  year: Maybe<Scalars["Int"]["output"]>;
};

export type BillingQueryVariables = Exact<{ [key: string]: never }>;

export type BillingQuery = {
  billing: {
    agreementId: number;
    availableLoyaltyPoints: number;
    status: number;
    subscribedStartDate: string;
    paymentPartnerId: string;
    couponCode: string;
    addonSubscriptions: Array<{
      subscriptionId: string;
      active: boolean;
      name: string;
    }>;
    baskets: Array<{
      default: boolean;
      shippingAddressId: string;
      basketId: string;
      timeblockId: number;
      deliveryInterval: number;
      basketProducts: Array<{
        variationId: string;
        quantity: number;
        variation: {
          finalPrice: number;
          productId: string;
          variationId: string;
          name: string;
          sku: string;
          description: string;
          images: Array<{
            productId: string;
            companyId: string;
            filename: string;
            isFeatured: boolean;
            priority: number;
            urls: Array<{ size: string; url: string }>;
          }>;
          attributes: Array<{ name: string; value: string }>;
          product: {
            productId: string;
            name: string;
            productTypeId: string;
            productTypeName: string;
            attributes: Array<{ name: string; value: string }>;
          };
        };
      }>;
    }>;
    addresses: Array<{
      isShipping: boolean;
      postalCode: number;
      city: string;
      doorCode: string;
      instructionsToDriver: string;
      version: number;
      streetName: string;
      streetNumber: number;
      letterExtra: string;
      floorNumber: string;
      flatNo: string;
      apartmentName: string;
      digitalKeyProviderName: string;
      longitude: number;
      latitude: number;
      validated: string;
      addressCode: string;
      addressDigitalKeyProviderId: string;
      addressUUID: string;
      addressId: string;
      name: string;
      address: string;
      timeblocks: Array<{
        firstDeliveryDate: string;
        deliveryWeekType: string;
        timeblockId: number;
        weekday: number;
        from: string;
        to: string;
        customerFee: string;
      }>;
    }>;
    contacts: {
      socialMedia: boolean;
      email: boolean;
      sms: boolean;
      phone: boolean;
      print: boolean;
      door: boolean;
      cutOffNotice: boolean;
    };
    currentPaymentCard: {
      paymentCardId: string;
      last4: string;
      expMonth: number;
      expYear: number;
      default: boolean;
      tokenType: string;
    };
    discounts: Array<{
      discountId: string;
      type: string;
      amount: string;
      name: string;
      usesLeft: number;
      createdAt: string;
      validEndDate: string;
      disableOnFreeze: boolean;
    }>;
    giftCards: Array<{
      giftCode: string;
      redeemedAt: string;
      totalAmount: string;
      remainingAmount: string;
      expirationDate: string;
    }>;
    creditStatus: {
      hasValidCreditCheck: boolean;
      hasValidReservationAmount: boolean;
    };
    preferences: Array<{
      preferenceTypeId: string;
      preferenceId: string;
      preferenceTypeName: string;
      isAllergen: boolean;
      preferenceName: string;
      attributes: Array<{ name: string; value: string }>;
    }>;
    consents: Array<{
      consentId: string;
      categoryConsentId: string;
      categoryConsentName: string;
      consentName: string;
      isAccepted: boolean;
    }>;
    loyaltyLevel: {
      accruedPoints: number;
      id: string;
      levelId: string;
      level: number;
      multiplier: number;
      name: string;
      description: string;
      requirement: number;
      companyId: string;
      benefits: Array<{
        id: string;
        levelCompanyId: string;
        name: string;
        value: string;
      }>;
    };
    loyaltyEntries: Array<{ expirationDate: string; points: number }>;
  };
};

export type CalendarQueryVariables = Exact<{
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
  range?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CalendarQuery = {
  calendar: Array<{
    week: number;
    year: number;
    status: number;
    isBeforeStartDateWeek: boolean;
    startDate: string;
    preselectorBasket: {
      week: number;
      year: number;
      variationIds: Array<string>;
    };
    baskets: Array<{
      week: number;
      year: number;
      isDefault: boolean;
      shippingDetails: {
        hasDelivery: boolean;
        firstDateOfWeek: string;
        timeblockId: number;
        plannedDeliveryInformation: {
          actualTimeblockId: number;
          customerFee: number;
          cutOffDateTime: string;
          deliveryDate: string;
          from: string;
          isAvailableForDelivery: boolean;
          normalTimeblockId: number;
          to: string;
          weekDay: number;
          isFallback: boolean;
        };
        addressInfo: {
          alias: string;
          addressId: string;
          weeklyTimeblocks: Array<{
            customerFee: string;
            noDelivery: boolean;
            fallbackTimeblockId: number;
            timeblockId: number;
            cutOffDateTime: string;
            from: string;
            to: string;
            deliveryDate: string;
          }>;
        };
      };
      basketDetails: {
        originId: string;
        originName: string;
        week: number;
        year: number;
        products: Array<{
          variationId: string;
          quantity: number;
          week: number;
          year: number;
          variation: {
            week: number;
            year: number;
            productId: string;
            variationId: string;
            name: string;
            description: string;
            images: Array<{
              isFeatured: boolean;
              urls: Array<{ url: string; size: string }>;
            }>;
            flexiblePrice: {
              price: string;
              year: string;
              week: string;
              campaignPrice: string;
            };
            attributes: Array<{ name: string; value: string }>;
            product: {
              productId: string;
              name: string;
              description: string;
              productTypeId: string;
              productTypeName: string;
              attributes: Array<{ name: string; value: string }>;
            };
          };
        }>;
      };
    }>;
  }>;
};

export type DefaultCouponQueryVariables = void;

export type DefaultCouponQuery = {
  defaultCoupon: {
    couponCode: string;
    type: number;
    registrationOnly: boolean;
    usesLeft: number;
    amount: number;
    name: string;
    discountId: string;
    validEndDate: string;
    hasChain: boolean;
    description: string;
    giftProducts: Array<{
      variationId: string;
      quantity: number;
      discountUsage: number;
      usage: number;
    }>;
    chainedDiscounts: Array<{
      amount: number;
      type: number;
      order: number;
      name: string;
      usesLeft: number;
    }>;
  };
};

export type IngredientCategoriesByCompanyQueryVariables = void;

export type IngredientCategoriesByCompanyQuery = {
  getIngredientCategoriesByCompany: Array<{
    ancestorIds: Array<number>;
    ingredientCategoryId: number;
    ingredientCategoryName: string;
    parentIngredientCategoryId: number;
  }>;
};

export type LoyaltyLevelsByCompanyQueryVariables = void;

export type LoyaltyLevelsByCompanyQuery = {
  loyaltyLevelsByCompany: Array<{
    companyId: string;
    description: string;
    id: string;
    level: number;
    levelId: string;
    multiplier: number;
    name: string;
    requirement: number;
    benefits: Array<{
      id: string;
      levelCompanyId: string;
      name: string;
      value: string;
    }>;
  }>;
};

export type BillingNorwegianQueryVariables = Exact<{ [key: string]: never }>;

export type BillingNorwegianQuery = {
  externalBillingIdentifiers: Array<{
    externalId: string;
    externalPartnerId: string;
  }>;
};

export type OrderQueryVariables = Exact<{
  orderId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type OrderQuery = {
  order: {
    id: string;
    orderId: number;
    week: number;
    year: number;
    deliveryDate: string;
    orderStatus: string;
    orderTypeId: string;
    finalPrice: number;
    timeblockId: number;
    hasRecipeLeaflets: boolean;
    orderCampaign: string;
    orderCampaignId: string;
    sentToPaymentPartner: boolean;
    realtimeEstimate: {
      estimatedTimeFrom: string;
      estimatedTimeTo: string;
      confirmedDeliveryTime: string;
      trackingLink: string;
    };
    deliveryAddress: {
      addressId: string;
      addressVersion: number;
      addressData: {
        id: string;
        street: string;
        doorCode: string;
        instructionsToDriver: string;
        postalCode: number;
        city: string;
        timeblocks: Array<{ timeblockId: number; from: string; to: string }>;
      };
    };
    orderLines: Array<{
      quantity: number;
      variationId: string;
      type: string;
      fullPrice: number;
      pricePerQuantity: number;
      discountId: string;
      discountCoupon: string;
      variation: {
        name: string;
        variationId: string;
        productId: string;
        images: Array<{
          priority: number;
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        }>;
        attributes: Array<{ name: string; value: string }>;
        product: {
          name: string;
          productId: string;
          productTypeId: string;
          productTypeName: string;
          attributes: Array<{ name: string; value: string }>;
          variations: Array<{
            variationId: string;
            name: string;
            description: string;
            images: Array<{
              isFeatured: boolean;
              urls: Array<{ size: string; url: string }>;
            }>;
            attributes: Array<{ name: string; value: string }>;
          }>;
        };
      };
      recipes: Array<{
        mainRecipeId: number;
        recipeId: number;
        recipeName: string;
        cookingTimeMin: string;
        cookingTimeMax: string;
        shelfLife: number;
        images: {
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        };
        instructions: {
          portions: Array<{
            size: string;
            ingredientSections: Array<{
              ingredients: Array<{ name: string; isBasis: boolean }>;
            }>;
          }>;
        };
        taxonomies: Array<{
          taxonomyId: number;
          name: string;
          description: string;
          type: string;
          order: number;
        }>;
      }>;
    }>;
    paymentData: {
      orderId: string;
      paymentStatus: string;
      paymentStatusId: string;
      tokenType: string;
      sentToInvoicePartner: boolean;
      invoiceNumber: number;
      paymentPartnerInfo: {
        paymentPartnerId: string;
        paymentPartnerName: string;
        paymentPartnerMethodCode: string;
      };
    };
    loyaltyPoints: { loyaltyPoints: number };
  };
};

export type OrdersQueryVariables = Exact<{
  orderStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  orderTypes?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type OrdersQuery = {
  orders: Array<{
    id: string;
    orderId: number;
    week: number;
    year: number;
    deliveryDate: string;
    orderStatus: string;
    orderTypeId: string;
    finalPrice: number;
    timeblockId: number;
    hasRecipeLeaflets: boolean;
    orderCampaign: string;
    orderCampaignId: string;
    sentToPaymentPartner: boolean;
    realtimeEstimate: {
      estimatedTimeFrom: string;
      estimatedTimeTo: string;
      confirmedDeliveryTime: string;
      trackingLink: string;
    };
    deliveryAddress: {
      addressId: string;
      addressVersion: number;
      addressData: {
        id: string;
        street: string;
        doorCode: string;
        instructionsToDriver: string;
        postalCode: number;
        city: string;
        timeblocks: Array<{ timeblockId: number; from: string; to: string }>;
      };
    };
    orderLines: Array<{
      quantity: number;
      variationId: string;
      type: string;
      fullPrice: number;
      pricePerQuantity: number;
      discountId: string;
      discountCoupon: string;
      variation: {
        name: string;
        variationId: string;
        productId: string;
        images: Array<{
          priority: number;
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        }>;
        attributes: Array<{ name: string; value: string }>;
        product: {
          name: string;
          productId: string;
          productTypeId: string;
          productTypeName: string;
          attributes: Array<{ name: string; value: string }>;
          variations: Array<{
            variationId: string;
            name: string;
            description: string;
            images: Array<{
              isFeatured: boolean;
              urls: Array<{ size: string; url: string }>;
            }>;
            attributes: Array<{ name: string; value: string }>;
          }>;
        };
      };
    }>;
    paymentData: {
      orderId: string;
      paymentStatus: string;
      paymentStatusId: string;
      tokenType: string;
      sentToInvoicePartner: boolean;
      invoiceNumber: number;
      paymentPartnerInfo: {
        paymentPartnerId: string;
        paymentPartnerName: string;
        paymentPartnerMethodCode: string;
      };
    };
    loyaltyPoints: { loyaltyPoints: number };
  }>;
};

export type MinimalOrdersHistoryQueryVariables = Exact<{
  orderStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  orderTypes?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type MinimalOrdersHistoryQuery = {
  orders: Array<{
    year: number;
    week: number;
    id: string;
    orderId: number;
    deliveryDate: string;
  }>;
};

export type OrderFragmentFragment = {
  id: string;
  orderId: number;
  week: number;
  year: number;
  deliveryDate: string;
  orderStatus: string;
  orderTypeId: string;
  finalPrice: number;
  timeblockId: number;
  hasRecipeLeaflets: boolean;
  orderCampaign: string;
  orderCampaignId: string;
  sentToPaymentPartner: boolean;
  realtimeEstimate: {
    estimatedTimeFrom: string;
    estimatedTimeTo: string;
    confirmedDeliveryTime: string;
    trackingLink: string;
  };
  deliveryAddress: {
    addressId: string;
    addressVersion: number;
    addressData: {
      id: string;
      street: string;
      doorCode: string;
      instructionsToDriver: string;
      postalCode: number;
      city: string;
      timeblocks: Array<{ timeblockId: number; from: string; to: string }>;
    };
  };
  orderLines: Array<{
    quantity: number;
    variationId: string;
    type: string;
    fullPrice: number;
    pricePerQuantity: number;
    discountId: string;
    discountCoupon: string;
    variation: {
      name: string;
      variationId: string;
      productId: string;
      images: Array<{
        priority: number;
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      }>;
      attributes: Array<{ name: string; value: string }>;
      product: {
        name: string;
        productId: string;
        productTypeId: string;
        productTypeName: string;
        attributes: Array<{ name: string; value: string }>;
        variations: Array<{
          variationId: string;
          name: string;
          description: string;
          images: Array<{
            isFeatured: boolean;
            urls: Array<{ size: string; url: string }>;
          }>;
          attributes: Array<{ name: string; value: string }>;
        }>;
      };
    };
    recipes: Array<{
      mainRecipeId: number;
      recipeId: number;
      recipeName: string;
      cookingTimeMin: string;
      cookingTimeMax: string;
      shelfLife: number;
      images: {
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      };
      instructions: {
        portions: Array<{
          size: string;
          ingredientSections: Array<{
            ingredients: Array<{ name: string; isBasis: boolean }>;
          }>;
        }>;
      };
      taxonomies: Array<{
        taxonomyId: number;
        name: string;
        description: string;
        type: string;
        order: number;
      }>;
    }>;
  }>;
  paymentData: {
    orderId: string;
    paymentStatus: string;
    paymentStatusId: string;
    tokenType: string;
    sentToInvoicePartner: boolean;
    invoiceNumber: number;
    paymentPartnerInfo: {
      paymentPartnerId: string;
      paymentPartnerName: string;
      paymentPartnerMethodCode: string;
    };
  };
  loyaltyPoints: { loyaltyPoints: number };
};

export type AvailablePaymentPartnersQueryVariables = Exact<{
  orderTypeId: Scalars["ID"]["input"];
}>;

export type AvailablePaymentPartnersQuery = {
  availablePaymentPartners: Array<{
    paymentPartnerId: string;
    paymentPartnerName: string;
    orderTypeName: string;
    orderTypeId: string;
    allowsAnonymousAccess: boolean;
    requiresExternalPaymentFlow: boolean;
    paymentPartnerType: string;
    paymentPartnerData: {
      header: string;
      subtitle: string;
      paymentMethodCode: string;
      scheme: string;
      vipps: string;
      klarna_paynow: string;
    };
  }>;
};

export type PreferencesQueryVariables = void;

export type PreferencesQuery = {
  preferences: Array<{
    preferenceTypeId: string;
    preferenceTypeName: string;
    preferenceName: string;
    preferenceId: string;
    description: string;
    isAllergen: boolean;
    attributes: Array<{ name: string; value: string }>;
  }>;
};

export type PreselectedProductsForWeekQueryVariables = Exact<{
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];

  productIds?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
}>;

export type PreselectedProductsForWeekQuery = {
  preselectedProductsForWeek: Array<{
    productId: string;
    preselectedProducts: Array<{
      week: number;
      year: number;
      productId: string;
      recipeOrder: number;
    }>;
  }>;
};

export type PreselectorPreviewQueryVariables = Exact<{
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
  portions: Scalars["Int"]["input"];
  attributeIds:
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>;
}>;

export type PreselectorPreviewQuery = {
  preselectorPreview: { recipeIds: Array<number> };
};

export type ProductsByIdsQueryVariables = Exact<{
  productIds:
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>;
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
}>;

export type ProductsByIdsQuery = {
  products: Array<{
    productId: string;
    name: string;
    description: string;
    productTypeId: string;
    productTypeName: string;
    images: Array<{
      isFeatured: boolean;
      urls: Array<{ size: string; url: string }>;
    }>;
    attributes: Array<{ name: string; value: string }>;
    variations: Array<{
      variationId: string;
      name: string;
      description: string;
      sku: string;
      stock: number;
      finalPrice: number;
      contents: {
        nettoWeight: number;
        unitLabelName: string;
        ingredient: string;
        supplierName: string;
        allergies: Array<{
          name: string;
          isActive: boolean;
          hasTraceOff: boolean;
        }>;
        nutritionFacts: Array<{
          name: string;
          value: number;
          unit: string;
          ingredientName: string;
        }>;
      };
      attributes: Array<{ name: string; value: string }>;
      flexiblePriceRange: Array<{
        variationId: string;
        week: string;
        year: string;
        price: string;
        campaignPrice: string;
      }>;
      images: Array<{
        priority: number;
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      }>;
      product: {
        name: string;
        productId: string;
        description: string;
        productTypeId: string;
        productTypeName: string;
        attributes: Array<{ name: string; value: string }>;
      };
    }>;
  }>;
};

export type ProductsByCategoriesQueryVariables = Exact<{
  categoryIds:
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>;
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
}>;

export type ProductsByCategoriesQuery = {
  productsByCategories: Array<{
    productCategoryId: string;
    productCategoryName: string;
    slug: string;
    products: Array<{
      productId: string;
      name: string;
      description: string;
      productTypeId: string;
      productTypeName: string;
      images: Array<{
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      }>;
      attributes: Array<{ name: string; value: string }>;
      variations: Array<{
        variationId: string;
        name: string;
        description: string;
        sku: string;
        stock: number;
        finalPrice: number;
        contents: {
          nettoWeight: number;
          unitLabelName: string;
          ingredient: string;
          supplierName: string;
          allergies: Array<{
            name: string;
            isActive: boolean;
            hasTraceOff: boolean;
          }>;
          nutritionFacts: Array<{
            name: string;
            value: number;
            unit: string;
            ingredientName: string;
          }>;
        };
        attributes: Array<{ name: string; value: string }>;
        flexiblePriceRange: Array<{
          variationId: string;
          week: string;
          year: string;
          price: string;
          campaignPrice: string;
        }>;
        images: Array<{
          priority: number;
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        }>;
        product: {
          name: string;
          productId: string;
          description: string;
          productTypeId: string;
          productTypeName: string;
          attributes: Array<{ name: string; value: string }>;
        };
      }>;
    }>;
  }>;
};

export type PickAndMixQueryVariables = Exact<{
  productTypeId: Scalars["ID"]["input"];
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
}>;

export type PickAndMixQuery = {
  pickAndMix: Array<{
    week: number;
    year: number;
    productId: string;
    productTypeId: string;
    order: number;
    recipes: Array<{
      recipeId: number;
      mainRecipeId: number;
      language: string;
      recipeName: string;
      cookingTimeMin: string;
      shelfLife: number;
      cookingTimeMax: string;
      mainIngredient: string;
      averageRating: number;
      numberOfRatings: number;
      images: {
        priority: number;
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      };
      imagesExtra: {
        priority: number;
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      };
      taxonomies: Array<{
        taxonomyId: number;
        name: string;
        description: string;
        type: string;
        order: number;
      }>;
      portions: Array<{
        week: number;
        year: number;
        variationId: string;
        size: number;
        stock: number;
        chefPartnerScore: string;
        pricing: {
          variationId: string;
          finalPrice: number;
          regularPrice: number;
          priceNoVat: number;
          vat: number;
          campaignDiscount: number;
        };
      }>;
    }>;
    product: {
      productId: string;
      name: string;
      description: string;
      productTypeId: string;
      productTypeName: string;
      images: Array<{
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      }>;
      attributes: Array<{ name: string; value: string }>;
      variations: Array<{
        variationId: string;
        name: string;
        description: string;
        sku: string;
        stock: number;
        finalPrice: number;
        contents: {
          nettoWeight: number;
          unitLabelName: string;
          ingredient: string;
          supplierName: string;
          allergies: Array<{
            name: string;
            isActive: boolean;
            hasTraceOff: boolean;
          }>;
          nutritionFacts: Array<{
            name: string;
            value: number;
            unit: string;
            ingredientName: string;
          }>;
        };
        attributes: Array<{ name: string; value: string }>;
        flexiblePriceRange: Array<{
          variationId: string;
          week: string;
          year: string;
          price: string;
          campaignPrice: string;
        }>;
        images: Array<{
          priority: number;
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        }>;
        product: {
          name: string;
          productId: string;
          description: string;
          productTypeId: string;
          productTypeName: string;
          attributes: Array<{ name: string; value: string }>;
        };
      }>;
    };
  }>;
};

export type ProductFragmentFragment = {
  productId: string;
  name: string;
  description: string;
  productTypeId: string;
  productTypeName: string;
  images: Array<{
    isFeatured: boolean;
    urls: Array<{ size: string; url: string }>;
  }>;
  attributes: Array<{ name: string; value: string }>;
  variations: Array<{
    variationId: string;
    name: string;
    description: string;
    sku: string;
    stock: number;
    finalPrice: number;
    contents: {
      nettoWeight: number;
      unitLabelName: string;
      ingredient: string;
      supplierName: string;
      allergies: Array<{
        name: string;
        isActive: boolean;
        hasTraceOff: boolean;
      }>;
      nutritionFacts: Array<{
        name: string;
        value: number;
        unit: string;
        ingredientName: string;
      }>;
    };
    attributes: Array<{ name: string; value: string }>;
    flexiblePriceRange: Array<{
      variationId: string;
      week: string;
      year: string;
      price: string;
      campaignPrice: string;
    }>;
    images: Array<{
      priority: number;
      isFeatured: boolean;
      urls: Array<{ size: string; url: string }>;
    }>;
    product: {
      name: string;
      productId: string;
      description: string;
      productTypeId: string;
      productTypeName: string;
      attributes: Array<{ name: string; value: string }>;
    };
  }>;
};

export type ProductCategoriesQueryVariables = void;

export type ProductCategoriesQuery = {
  productCategories: Array<{
    companyName: string;
    productCategoryName: string;
    shortName: string;
    description: string;
    slug: string;
    completeSlug: string;
    parentCategoryName: string;
    hasProducts: boolean;
    productCategoryId: string;
    parentCategoryId: string;
    sortingValue: number;
  }>;
};

export type RecipesByProductIdListQueryVariables = Exact<{
  productIds:
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>;
  week: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];

  range: Scalars["Int"]["input"];
}>;

export type RecipesByProductIdListQuery = {
  recipesByProductIdList: Array<{
    week: string;
    year: string;
    products: Array<{
      productId: string;
      recipes: Array<{
        language: string;
        recipeId: number;
        mainRecipeId: number;
        recipeName: string;
        chefTip: string;
        cookingTimeMin: string;
        cookingTimeMax: string;
        shelfLife: number;
        average: string;
        instructions: {
          recipeId: string;
          portions: Array<{
            size: string;
            chefPartnerScore: string;
            ingredientSections: Array<{
              ingredients: Array<{
                amount: string;
                name: string;
                isBasis: boolean;
                ingredientAmountType: string;
                images: { urls: Array<{ size: string; url: string }> };
                imagesExtra: { urls: Array<{ size: string; url: string }> };
              }>;
            }>;
            allergies: Array<{
              hasTraceOf: boolean;
              showAllergy: boolean;
              name: string;
            }>;
          }>;
        };
        images: {
          priority: number;
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        };
        imagesExtra: {
          priority: number;
          isFeatured: boolean;
          urls: Array<{ size: string; url: string }>;
        };
        taxonomies: Array<{
          taxonomyId: number;
          name: string;
          description: string;
          type: string;
          order: number;
        }>;
      }>;
    }>;
  }>;
};

export type RecipesByTaxonomyIdListQueryVariables = Exact<{
  taxonomyIds:
    | Array<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
  start_num?: InputMaybe<Scalars["Int"]["input"]>;
  range?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RecipesByTaxonomyIdListQuery = {
  recipesByTaxonomyIdList: Array<{
    taxonomyId: number;
    taxonomyCount: number;
    rowNumber: number;
    name: string;
    recipes: Array<{
      recipeId: number;
      recipeName: string;
      recipeDescription: string;
      cookingTimeMin: string;
      cookingTimeMax: string;
      average: string;
      shelfLife: number;
      images: {
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      };
    }>;
  }>;
};

export type RecipeAndStepsQueryVariables = Exact<{
  recipeId: Scalars["Int"]["input"];
}>;

export type RecipeAndStepsQuery = {
  recipeAndSteps: {
    language: string;
    recipeId: number;
    mainRecipeId: number;
    recipeName: string;
    beverageSuggestion: string;
    recipeNameHeadline: string;
    recipeNameSubheadline: string;
    mainIngredient: string;
    recipeDescription: string;
    chefTip: string;
    cookingTimeMin: string;
    cookingTimeMax: string;
    shelfLife: number;
    averageRating: number;
    numberOfRatings: number;
    images: {
      priority: number;
      isFeatured: boolean;
      urls: Array<{ size: string; url: string }>;
    };
    imagesExtra: {
      priority: number;
      isFeatured: boolean;
      urls: Array<{ size: string; url: string }>;
    };
    taxonomies: Array<{
      name: string;
      type: string;
      order: number;
      taxonomyId: number;
    }>;
    instructions: {
      portions: Array<{
        chefPartnerScore: string;
        size: string;
        pdfUrl: string;
        allergies: Array<{
          id: string;
          name: string;
          hasTraceOf: boolean;
          showAllergy: boolean;
          parentAllergy: { id: string };
        }>;
        stepSections: Array<{
          sectionId: string;
          sectionTitle: string;
          steps: Array<{ order: number; step: string }>;
        }>;
        ingredientSections: Array<{
          sectionId: string;
          sectionTitle: string;
          ingredients: Array<{
            order: string;
            name: string;
            amount: string;
            ingredientAmountType: string;
            isBasis: boolean;
            images: {
              priority: number;
              isFeatured: boolean;
              urls: Array<{ size: string; url: string }>;
            };
            imagesExtra: {
              priority: number;
              isFeatured: boolean;
              urls: Array<{ size: string; url: string }>;
            };
            allergies: Array<{
              id: string;
              name: string;
              hasTraceOf: boolean;
              showAllergy: boolean;
              parentAllergy: { id: string };
            }>;
          }>;
        }>;
        nutritionFacts: {
          totalWeight: number;
          kcal: number;
          kcalPerPortion: number;
          proteinKcal: number;
          carbsKcal: number;
          fatKcal: number;
          recipeNutritionPerPortion: {
            carbs: number;
            energyKcal: number;
            fat: number;
            protein: number;
          };
          recipeNutritionPer100g: {
            carbs: number;
            energyKcal: number;
            fat: number;
            protein: number;
          };
        };
      }>;
    };
  };
};

export type RecipesAgreementRatingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecipesAgreementRatingsQuery = {
  recipesAgreementRatings: {
    agreementId: number;
    recipeRatings: Array<{ recipeId: number; rating: number; comment: string }>;
  };
};

export type RecipesAgreementFavoritesQueryVariables = Exact<{
  pageNumber: Scalars["Int"]["input"];
  resultsPerPage: Scalars["Int"]["input"];
  week?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RecipesAgreementFavoritesQuery = {
  recipesAgreementFavorites: {
    agreementId: number;
    recipeFavorites: Array<{
      recipeId: number;
      mainRecipeId: number;
      typeId: number;
      typeName: string;
      createdAt: string;
    }>;
  };
};

export type RecipesByIngredientCategoriesQueryVariables = Exact<{
  pageNumber: Scalars["Int"]["input"];
  resultsPerPage: Scalars["Int"]["input"];
  ingredientCategoryIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
}>;

export type RecipesByIngredientCategoriesQuery = {
  getRecipesByIngredientCategories: {
    resultsPerPage: number;
    totalPages: number;
    pageNumber: number;
    recipes: Array<{
      language: string;
      recipeId: number;
      recipeName: string;
      chefTip: string;
      cookingTimeMax: string;
      cookingTimeMin: string;
      averageRating: number;
      numberOfRatings: number;
      shelfLife: number;
      mainIngredient: string;
      recipeDescription: string;
      generalText: string;
      energyKcal: string;
      portionId: number;
      mainRecipeId: number;
      recipeNameHeadline: string;
      recipeNameSubheadline: string;
      ingredientCategoryId: number;
      images: {
        priority: number;
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      };
      taxonomies: Array<{
        taxonomyId: number;
        description: string;
        name: string;
        type: string;
        order: number;
      }>;
      customerRating: { rating: string };
      instructions: {
        portions: Array<{
          size: string;
          organicPerc: number;
          chefPartnerScore: string;
          pdfUrl: string;
          stepSections: Array<{
            sectionId: string;
            sectionTitle: string;
            steps: Array<{ order: number; step: string }>;
          }>;
          ingredientSections: Array<{ ingredients: Array<{ name: string }> }>;
          nutritionFacts: {
            totalWeight: number;
            kcal: number;
            kcalPerPortion: number;
            proteinKcal: number;
            carbsKcal: number;
            fatKcal: number;
            recipeNutritionPerPortion: {
              carbs: number;
              energyKcal: number;
              fat: number;
              protein: number;
            };
            recipeNutritionPer100g: {
              carbs: number;
              energyKcal: number;
              fat: number;
              protein: number;
            };
          };
          allergies: Array<{ name: string; id: string; showAllergy: boolean }>;
        }>;
      };
      imagesExtra: {
        priority: number;
        isFeatured: boolean;
        urls: Array<{ size: string; url: string }>;
      };
    }>;
  };
};

export type RecipesByIdsQueryVariables = Exact<{
  recipeIds:
    | Array<InputMaybe<Scalars["Int"]["input"]>>
    | InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RecipesByIdsQuery = {
  recipesByIds: Array<{
    cookingTimeMax: number;
    cookingTimeMin: number;
    recipeId: number;
    mainRecipeId: number;
    recipeName: string;
    shelfLife: number;
    images: { urls: Array<{ url: string }> };
    taxonomies: Array<{
      taxonomyId: number;
      description: string;
      name: string;
      type: string;
      order: number;
    }>;
  }>;
};

export type RecruitmentDataQueryVariables = Exact<{
  from?: InputMaybe<Scalars["String"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RecruitmentDataQuery = {
  recruitmentData: {
    recruitedCustomers: number;
    recruitmentInvitations: number;
    redeemedDiscounts: number;
  };
};

export type TaxonomyRelationsQueryVariables = void;

export type TaxonomyRelationsQuery = {
  taxonomyRelations: Array<{
    taxonomyId: number;
    name: string;
    description: string;
    subTaxonomies: Array<{
      taxonomyId: number;
      name: string;
      description: string;
    }>;
  }>;
};

export type TimeblocksQueryVariables = Exact<{
  postalCode: Scalars["String"]["input"];
  timeblockTypeId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type TimeblocksQuery = {
  timeblocksData: {
    city: string;
    timeblocks: Array<{
      timeblockId: number;
      weekday: number;
      from: string;
      to: string;
      deliveryWeekType: string;
      timeblockTypeId: string;
      firstDeliveryDate: string;
    }>;
  };
};

export type TimeblocksByRangeQueryVariables = Exact<{
  postalCode: Scalars["String"]["input"];
  variationIds:
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>;
  range: Scalars["Int"]["input"];

  timeblockTypeId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type TimeblocksByRangeQuery = {
  timeblocksByRange: Array<{
    week: number;
    year: number;
    timeblocks: Array<{
      week: number;
      year: number;
      postalCode: string;
      cutOffDateTime: string;
      timeblockId: number;
      city: string;
      weekday: number;
      from: string;
      to: string;
      customerFee: string;
      startDate: string;
      deliveryWeekType: string;
      firstDeliveryDate: string;
      timeblockTypeId: string;
      deliveryDate: string;
      noDelivery: boolean;
      fallbackTimeblockId: number;
      registrationTypeId: string;
    }>;
  }>;
};

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = {
  userInfo: {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
  };
};

export type UserCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type UserCreditsQuery = {
  userCredits: Array<{
    remainingAmount: number;
    expirationDate: string;
    details: string;
    creditTypeId: string;
  }>;
};

export const OrderFragmentFragmentDoc = `
    fragment OrderFragment on Order {
  id
  orderId
  week
  year
  deliveryDate
  orderStatus
  orderTypeId
  finalPrice
  timeblockId
  hasRecipeLeaflets
  realtimeEstimate {
    estimatedTimeFrom
    estimatedTimeTo
    confirmedDeliveryTime
    trackingLink
  }
  deliveryAddress {
    addressId
    addressVersion
    addressData {
      id
      street
      doorCode
      instructionsToDriver
      street
      postalCode
      city
      timeblocks {
        timeblockId
        from
        to
      }
    }
  }
  orderCampaign
  orderCampaignId
  orderLines {
    quantity
    variationId
    type
    fullPrice
    pricePerQuantity
    discountId
    discountCoupon
    variation {
      name
      images {
        priority
        isFeatured
        urls {
          size
          url
        }
      }
      variationId
      productId
      attributes {
        name
        value
      }
      product {
        name
        productId
        productTypeId
        productTypeName
        attributes {
          name
          value
        }
        variations {
          variationId
          name
          description
          images {
            isFeatured
            urls {
              size
              url
            }
          }
          attributes {
            name
            value
          }
        }
      }
    }
    recipes {
      mainRecipeId
      recipeId
      recipeName
      cookingTimeMin
      cookingTimeMax
      shelfLife
      images {
        isFeatured
        urls {
          size
          url
        }
      }
      instructions {
        portions {
          size
          ingredientSections {
            ingredients {
              name
              isBasis
            }
          }
        }
      }
      taxonomies {
        taxonomyId
        name
        description
        type
        order
      }
    }
  }
  sentToPaymentPartner
  paymentData {
    orderId
    paymentStatus
    paymentStatusId
    tokenType
    sentToInvoicePartner
    invoiceNumber
    paymentPartnerInfo {
      paymentPartnerId
      paymentPartnerName
      paymentPartnerMethodCode
    }
  }
  loyaltyPoints {
    loyaltyPoints
  }
}
    `;
export const ProductFragmentFragmentDoc = `
    fragment ProductFragment on Product {
  productId
  name
  description
  productTypeId
  productTypeName
  images {
    urls {
      size
      url
    }
    isFeatured
  }
  attributes {
    name
    value
  }
  variations {
    variationId
    name
    description
    sku
    stock
    finalPrice
    contents(week: $week, year: $year) {
      nettoWeight
      unitLabelName
      ingredient
      allergies {
        name
        isActive
        hasTraceOff
      }
      supplierName
      nutritionFacts {
        name
        value
        unit
        ingredientName
      }
    }
    attributes {
      name
      value
    }
    flexiblePriceRange(week: $week, year: $year, range: 1) {
      variationId
      week
      year
      price
      campaignPrice
    }
    images {
      priority
      isFeatured
      urls {
        size
        url
      }
    }
    product {
      name
      productId
      description
      productTypeId
      productTypeName
      attributes {
        name
        value
      }
    }
  }
}
    `;
export const BillingDocument = `
    query billing {
  billing {
    agreementId
    availableLoyaltyPoints
    status
    couponCode: referralCode
    subscribedStartDate
    paymentPartnerId
    addonSubscriptions {
      subscriptionId
      active
      name
    }
    baskets {
      default
      shippingAddressId
      basketId
      timeblockId
      deliveryInterval
      basketProducts {
        variationId
        quantity
        variation {
          finalPrice
          productId
          variationId
          name
          sku
          description
          images {
            productId
            companyId
            filename
            isFeatured
            priority
            urls {
              size
              url
            }
          }
          attributes {
            name
            value
          }
          product {
            productId
            name
            productTypeId
            productTypeName
            attributes {
              name
              value
            }
          }
        }
      }
    }
    addresses {
      addressId: id
      isShipping
      name: alias
      postalCode
      city
      address: street
      doorCode
      instructionsToDriver
      version
      streetName
      streetNumber
      letterExtra
      floorNumber
      flatNo
      apartmentName
      digitalKeyProviderName
      longitude
      latitude
      validated
      addressCode
      addressDigitalKeyProviderId
      addressUUID
      timeblocks {
        firstDeliveryDate
        deliveryWeekType
        timeblockId
        weekday
        from
        to
        customerFee
        firstDeliveryDate
      }
    }
    contacts {
      socialMedia
      email
      sms
      phone
      print
      door
      cutOffNotice
    }
    currentPaymentCard {
      paymentCardId
      last4
      expMonth
      expYear
      default
      tokenType
    }
    discounts {
      discountId
      type
      amount
      name
      usesLeft
      createdAt
      validEndDate
      disableOnFreeze
    }
    giftCards {
      giftCode
      redeemedAt
      totalAmount
      remainingAmount
      expirationDate
    }
    creditStatus {
      hasValidCreditCheck
      hasValidReservationAmount
    }
    preferences {
      preferenceTypeId
      preferenceId
      preferenceTypeName
      isAllergen
      attributes {
        name
        value
      }
      preferenceName
    }
    consents {
      consentId
      categoryConsentId
      categoryConsentName
      consentName
      isAccepted
    }
    loyaltyLevel {
      accruedPoints
      id
      levelId
      level
      multiplier
      name
      description
      requirement
      companyId
      benefits {
        id
        levelCompanyId
        name
        value
      }
    }
    loyaltyEntries {
      expirationDate
      points
    }
  }
}
    `;
export const CalendarDocument = `
    query calendar($week: Int!, $year: Int!, $range: Int) {
  calendar(week: $week, year: $year, range: $range) {
    week
    year
    status
    isBeforeStartDateWeek
    startDate
    preselectorBasket {
      week
      year
      variationIds
    }
    baskets {
      week
      year
      shippingDetails {
        hasDelivery
        firstDateOfWeek
        timeblockId
        plannedDeliveryInformation {
          actualTimeblockId
          customerFee
          cutOffDateTime
          deliveryDate
          from
          isAvailableForDelivery
          normalTimeblockId
          to
          weekDay
        }
        addressInfo {
          alias
          addressId: id
          weeklyTimeblocks {
            customerFee
            noDelivery
            fallbackTimeblockId
            timeblockId
            cutOffDateTime
            from
            to
            deliveryDate
          }
        }
        plannedDeliveryInformation {
          isFallback
          to
          from
          actualTimeblockId
          customerFee
          cutOffDateTime
          isAvailableForDelivery
          deliveryDate
        }
      }
      basketDetails {
        originId
        originName
        week
        year
        products {
          variationId
          quantity
          week
          year
          variation {
            week
            year
            productId
            variationId
            name
            images {
              isFeatured
              urls {
                url
                size
              }
            }
            flexiblePrice {
              price
              year
              week
              campaignPrice
            }
            attributes {
              name
              value
            }
            product {
              productId
              name
              description
              productTypeId
              productTypeName
              attributes {
                name
                value
              }
            }
            description
          }
        }
      }
      isDefault
    }
  }
}
    `;
export const DefaultCouponDocument = `
    query defaultCoupon($companyId: ID!) {
  defaultCoupon(companyId: $companyId) {
    couponCode
    type
    registrationOnly
    usesLeft
    amount
    name
    discountId
    validEndDate
    hasChain
    description
    giftProducts {
      variationId
      quantity
      discountUsage
      usage
    }
    chainedDiscounts {
      amount
      type
      order
      name
      usesLeft: initialUsage
    }
  }
}
    `;
export const IngredientCategoriesByCompanyDocument = `
    query ingredientCategoriesByCompany($companyId: ID!, $language: String!) {
  getIngredientCategoriesByCompany(companyId: $companyId, language: $language) {
    ancestorIds
    ingredientCategoryId
    ingredientCategoryName
    parentIngredientCategoryId
  }
}
    `;
export const LoyaltyLevelsByCompanyDocument = `
    query loyaltyLevelsByCompany($companyId: ID!) {
  loyaltyLevelsByCompany(companyId: $companyId) {
    benefits {
      id
      levelCompanyId
      name
      value
    }
    companyId
    description
    id
    level
    levelId
    multiplier
    name
    requirement
  }
}
    `;
export const BillingNorwegianDocument = `
    query billingNorwegian {
  externalBillingIdentifiers {
    externalId
    externalPartnerId
  }
}
    `;
export const OrderDocument = `
    query order($orderId: Int, $id: ID) {
  order(orderId: $orderId, id: $id) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;
export const OrdersDocument = `
    query orders($orderStatuses: [ID], $orderTypes: [ID], $orderId: ID, $skip: Int, $count: Int) {
  orders(
    orderStatuses: $orderStatuses
    orderTypes: $orderTypes
    orderId: $orderId
    skip: $skip
    count: $count
  ) {
    id
    orderId
    week
    year
    deliveryDate
    orderStatus
    orderTypeId
    finalPrice
    timeblockId
    hasRecipeLeaflets
    realtimeEstimate {
      estimatedTimeFrom
      estimatedTimeTo
      confirmedDeliveryTime
      trackingLink
    }
    deliveryAddress {
      addressId
      addressVersion
      addressData {
        id
        street
        doorCode
        instructionsToDriver
        street
        postalCode
        city
        timeblocks {
          timeblockId
          from
          to
        }
      }
    }
    orderCampaign
    orderCampaignId
    orderLines {
      quantity
      variationId
      type
      fullPrice
      pricePerQuantity
      discountId
      discountCoupon
      variation {
        name
        images {
          priority
          isFeatured
          urls {
            size
            url
          }
        }
        variationId
        productId
        attributes {
          name
          value
        }
        product {
          name
          productId
          productTypeId
          productTypeName
          attributes {
            name
            value
          }
          variations {
            variationId
            name
            description
            images {
              isFeatured
              urls {
                size
                url
              }
            }
            attributes {
              name
              value
            }
          }
        }
      }
    }
    sentToPaymentPartner
    paymentData {
      orderId
      paymentStatus
      paymentStatusId
      tokenType
      sentToInvoicePartner
      invoiceNumber
      paymentPartnerInfo {
        paymentPartnerId
        paymentPartnerName
        paymentPartnerMethodCode
      }
    }
    loyaltyPoints {
      loyaltyPoints
    }
  }
}
    `;
export const MinimalOrdersHistoryDocument = `
    query minimalOrdersHistory($orderStatuses: [ID], $orderTypes: [ID], $orderId: ID, $skip: Int, $count: Int) {
  orders(
    orderStatuses: $orderStatuses
    orderTypes: $orderTypes
    orderId: $orderId
    skip: $skip
    count: $count
  ) {
    year
    week
    id
    orderId
    deliveryDate
  }
}
    `;
export const AvailablePaymentPartnersDocument = `
    query availablePaymentPartners($companyId: ID!, $orderTypeId: ID!) {
  availablePaymentPartners: getAvailablePaymentPartners(
    companyId: $companyId
    orderTypeId: $orderTypeId
  ) {
    paymentPartnerId
    paymentPartnerName
    orderTypeName
    orderTypeId
    allowsAnonymousAccess
    requiresExternalPaymentFlow
    paymentPartnerType
    paymentPartnerData {
      header
      subtitle
      paymentMethodCode
      scheme
      vipps
      klarna_paynow
    }
  }
}
    `;
export const PreferencesDocument = `
    query preferences($companyId: ID!) {
  preferences: getPreferences(companyId: $companyId) {
    preferenceTypeId
    attributes {
      name
      value
    }
    preferenceTypeName
    preferenceName
    preferenceId
    description
    isAllergen
  }
}
    `;
export const PreselectedProductsForWeekDocument = `
    query preselectedProductsForWeek($week: Int!, $year: Int!, $companyId: ID!, $language: String!, $productIds: [ID]) {
  preselectedProductsForWeek: getPreselectedProductsForWeek(
    week: $week
    year: $year
    companyId: $companyId
    language: $language
    productIds: $productIds
  ) {
    productId
    preselectedProducts {
      week
      year
      productId
      recipeOrder
    }
  }
}
    `;
export const PreselectorPreviewDocument = `
    query preselectorPreview($companyId: ID!, $week: Int!, $year: Int!, $portions: Int!, $attributeIds: [String]!) {
  preselectorPreview: getPreselectorPreview(
    companyId: $companyId
    week: $week
    year: $year
    portions: $portions
    attributeIds: $attributeIds
  ) {
    recipeIds
  }
}
    `;
export const ProductsByIdsDocument = `
    query productsByIds($companyId: ID!, $productIds: [ID]!, $week: Int!, $year: Int!) {
  products: products(
    companyId: $companyId
    productIds: $productIds
    week: $week
    year: $year
  ) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;
export const ProductsByCategoriesDocument = `
    query productsByCategories($companyId: ID!, $categoryIds: [ID]!, $week: Int!, $year: Int!) {
  productsByCategories: getProductsByCategories(
    companyId: $companyId
    categoryIds: $categoryIds
  ) {
    productCategoryId
    productCategoryName
    slug
    products {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}`;
export const PickAndMixDocument = `
    query pickAndMix($companyId: ID!, $productTypeId: ID!, $week: Int!, $year: Int!, $language: String!) {
  pickAndMix(
    week: $week
    year: $year
    companyId: $companyId
    productTypeId: $productTypeId
    language: $language
  ) {
    week
    year
    productId
    productTypeId
    order
    recipes: recipeData {
      recipeId
      mainRecipeId
      language
      recipeName
      cookingTimeMin
      shelfLife
      cookingTimeMax
      mainIngredient
      averageRating
      numberOfRatings
      images {
        priority
        isFeatured
        urls {
          size
          url
        }
      }
      imagesExtra {
        priority
        isFeatured
        urls {
          size
          url
        }
      }
      taxonomies {
        taxonomyId
        name
        description
        type
        order
      }
      portions {
        week
        year
        variationId
        size
        stock
        chefPartnerScore
        pricing {
          variationId
          finalPrice
          regularPrice
          priceNoVat
          vat
          campaignDiscount
        }
      }
    }
    product {
      ...ProductFragment
    }
  }
}
    ${ProductFragmentFragmentDoc}`;
export const ProductCategoriesDocument = `
    query productCategories($companyId: ID!) {
  productCategories: getProductCategories(companyId: $companyId) {
    companyName
    productCategoryName
    shortName
    description
    slug
    completeSlug
    parentCategoryName
    hasProducts
    productCategoryId
    parentCategoryId
    sortingValue
  }
}
    `;
export const RecipesByProductIdListDocument = `
    query recipesByProductIdList($productIds: [ID]!, $week: Int!, $year: Int!, $companyId: ID, $language: String, $range: Int!) {
  recipesByProductIdList(
    productIds: $productIds
    week: $week
    year: $year
    companyId: $companyId
    language: $language
    range: $range
  ) {
    week
    year
    products {
      productId
      recipes {
        language
        recipeId
        mainRecipeId
        recipeName
        chefTip
        cookingTimeMin
        cookingTimeMax
        shelfLife
        average
        instructions {
          recipeId
          portions {
            size
            chefPartnerScore
            ingredientSections {
              ingredients {
                images {
                  urls {
                    size
                    url
                  }
                }
                amount
                name
                isBasis
                ingredientAmountType
                imagesExtra {
                  urls {
                    size
                    url
                  }
                }
              }
            }
            allergies {
              hasTraceOf
              showAllergy
              name
            }
          }
        }
        images {
          priority
          isFeatured
          urls {
            size
            url
          }
        }
        imagesExtra {
          priority
          isFeatured
          urls {
            size
            url
          }
        }
        taxonomies {
          taxonomyId
          name
          description
          type
          order
        }
      }
    }
  }
}
    `;
export const RecipesByTaxonomyIdListDocument = `
    query recipesByTaxonomyIdList($companyId: ID!, $language: String!, $taxonomyIds: [Int]!, $start_num: Int, $range: Int) {
  recipesByTaxonomyIdList: getRecipesByTaxonomyIdList(
    taxonomyIds: $taxonomyIds
    companyId: $companyId
    language: $language
    start_num: $start_num
    range: $range
  ) {
    taxonomyId
    taxonomyCount
    rowNumber
    name: taxonomyName
    recipes {
      recipeId
      recipeName
      recipeDescription
      cookingTimeMin
      cookingTimeMax
      average
      shelfLife
      images {
        isFeatured
        urls {
          size
          url
        }
      }
    }
  }
}
    `;
export const RecipeAndStepsDocument = `
    query recipeAndSteps($recipeId: Int!, $language: String) {
  recipeAndSteps(recipeId: $recipeId, language: $language) {
    language
    recipeId
    mainRecipeId
    recipeName
    beverageSuggestion
    recipeNameHeadline
    recipeNameSubheadline
    mainIngredient
    recipeDescription
    chefTip
    cookingTimeMin
    cookingTimeMax
    shelfLife
    averageRating
    numberOfRatings
    images {
      urls {
        size
        url
      }
      priority
      isFeatured
    }
    imagesExtra {
      urls {
        size
        url
      }
      priority
      isFeatured
    }
    taxonomies {
      name
      type
      order
      taxonomyId
    }
    instructions {
      portions {
        chefPartnerScore
        size
        allergies {
          id
          name
          hasTraceOf
          showAllergy
          parentAllergy {
            id
          }
        }
        stepSections {
          sectionId
          sectionTitle
          steps {
            order
            step
          }
        }
        ingredientSections {
          sectionId
          sectionTitle
          ingredients {
            order
            name
            amount
            ingredientAmountType
            isBasis
            images {
              priority
              isFeatured
              urls {
                size
                url
              }
            }
            imagesExtra {
              urls {
                size
                url
              }
              priority
              isFeatured
            }
            allergies {
              id
              name
              hasTraceOf
              showAllergy
              parentAllergy {
                id
              }
            }
          }
        }
        nutritionFacts {
          totalWeight
          kcal
          kcalPerPortion
          proteinKcal
          carbsKcal
          fatKcal
          recipeNutritionPerPortion {
            carbs
            energyKcal
            fat
            protein
          }
          recipeNutritionPer100g {
            carbs
            energyKcal
            fat
            protein
          }
        }
        pdfUrl
      }
    }
  }
}
    `;
export const RecipesAgreementRatingsDocument = `
    query recipesAgreementRatings {
  recipesAgreementRatings {
    agreementId
    recipeRatings {
      recipeId
      rating
      comment
    }
  }
}
    `;
export const RecipesAgreementFavoritesDocument = `
    query recipesAgreementFavorites($pageNumber: Int!, $resultsPerPage: Int!, $week: Int, $year: Int) {
  recipesAgreementFavorites(
    pageNumber: $pageNumber
    resultsPerPage: $resultsPerPage
    week: $week
    year: $year
  ) {
    agreementId
    recipeFavorites {
      recipeId
      mainRecipeId
      typeId
      typeName
      createdAt
    }
  }
}
    `;
export const RecipesByIngredientCategoriesDocument = `
    query recipesByIngredientCategories($companyId: ID!, $language: String!, $pageNumber: Int!, $resultsPerPage: Int!, $ingredientCategoryIds: [Int!]) {
  getRecipesByIngredientCategories(
    companyId: $companyId
    language: $language
    pageNumber: $pageNumber
    resultsPerPage: $resultsPerPage
    ingredientCategoryIds: $ingredientCategoryIds
  ) {
    resultsPerPage
    totalPages
    pageNumber
    recipes {
      language
      recipeId
      recipeName
      chefTip
      cookingTimeMax
      cookingTimeMin
      averageRating
      numberOfRatings
      images {
        priority
        isFeatured
        urls {
          size
          url
        }
      }
      shelfLife
      taxonomies {
        taxonomyId
        description
        name
        type
        order
      }
      customerRating {
        rating
      }
      instructions {
        portions {
          size
          organicPerc
          stepSections {
            sectionId
            sectionTitle
            steps {
              order
              step
            }
          }
          ingredientSections {
            ingredients {
              name
            }
          }
          chefPartnerScore
          nutritionFacts {
            totalWeight
            kcal
            kcalPerPortion
            proteinKcal
            carbsKcal
            fatKcal
            recipeNutritionPerPortion {
              carbs
              energyKcal
              fat
              protein
            }
            recipeNutritionPer100g {
              carbs
              energyKcal
              fat
              protein
            }
          }
          allergies {
            name
            id
            showAllergy
          }
          pdfUrl
        }
      }
      mainIngredient
      recipeDescription
      generalText
      energyKcal
      portionId
      mainRecipeId
      recipeNameHeadline
      recipeNameSubheadline
      ingredientCategoryId
      imagesExtra {
        priority
        isFeatured
        urls {
          size
          url
        }
      }
    }
  }
}
    `;
export const RecipesByIdsDocument = `
    query recipesByIds($recipeIds: [Int]!, $language: String) {
  recipesByIds(recipeIds: $recipeIds, language: $language) {
    cookingTimeMax
    cookingTimeMin
    images {
      urls {
        url
      }
    }
    recipeId
    mainRecipeId
    recipeName
    shelfLife
    taxonomies {
      taxonomyId
      description
      name
      type
      order
    }
  }
}
    `;
export const RecruitmentDataDocument = `
    query RecruitmentData($from: String, $to: String) {
  recruitmentData(from: $from, to: $to) {
    recruitedCustomers
    recruitmentInvitations
    redeemedDiscounts
  }
}
    `;
export const TaxonomyRelationsDocument = `
    query taxonomyRelations($companyId: ID!, $language: String!) {
  taxonomyRelations: getTaxonomyRelations(
    companyId: $companyId
    language: $language
  ) {
    taxonomyId: topTaxonomyId
    name: topTaxonomyName
    description: topTaxonomyDescription
    subTaxonomies {
      taxonomyId: subTaxonomyId
      name: subTaxonomyName
      description: subTaxonomyDescription
    }
  }
}
    `;
export const TimeblocksDocument = `
    query timeblocks($postalCode: String!, $timeblockTypeId: ID, $companyId: ID!) {
  timeblocksData: getPostalCodeAndTimeblocks(
    postalCode: $postalCode
    timeblockTypeId: $timeblockTypeId
    companyId: $companyId
  ) {
    city
    timeblocks {
      timeblockId
      weekday
      from
      to
      deliveryWeekType
      timeblockTypeId
      firstDeliveryDate
    }
  }
}
    `;
export const TimeblocksByRangeDocument = `
    query timeblocksByRange($postalCode: String!, $variationIds: [ID]!, $range: Int!, $companyId: ID!, $timeblockTypeId: ID) {
  timeblocksByRange(
    postalCode: $postalCode
    variationIds: $variationIds
    companyId: $companyId
    range: $range
    timeblockTypeId: $timeblockTypeId
  ) {
    week
    year
    timeblocks {
      week
      year
      postalCode
      cutOffDateTime
      timeblockId
      city
      weekday
      from
      to
      customerFee
      startDate
      deliveryWeekType
      firstDeliveryDate
      timeblockTypeId
      deliveryDate
      noDelivery
      fallbackTimeblockId
      registrationTypeId
    }
  }
}
    `;
export const UserInfoDocument = `
    query userInfo {
  userInfo {
    firstName
    lastName
    email
    telephone
  }
}
    `;
export const UserCreditsDocument = `
    query userCredits {
  userCredits {
    remainingAmount
    expirationDate
    details
    creditTypeId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    billing: build.query<BillingQuery, BillingQueryVariables | void>({
      query: (variables) => ({ document: BillingDocument, variables }),
    }),
    calendar: build.query<CalendarQuery, CalendarQueryVariables>({
      query: (variables) => ({ document: CalendarDocument, variables }),
    }),
    defaultCoupon: build.query<DefaultCouponQuery, DefaultCouponQueryVariables>(
      {
        query: (variables) => ({ document: DefaultCouponDocument, variables }),
      },
    ),
    ingredientCategoriesByCompany: build.query<
      IngredientCategoriesByCompanyQuery,
      IngredientCategoriesByCompanyQueryVariables
    >({
      query: (variables) => ({
        document: IngredientCategoriesByCompanyDocument,
        variables,
      }),
    }),
    loyaltyLevelsByCompany: build.query<
      LoyaltyLevelsByCompanyQuery,
      LoyaltyLevelsByCompanyQueryVariables
    >({
      query: (variables) => ({
        document: LoyaltyLevelsByCompanyDocument,
        variables,
      }),
    }),
    billingNorwegian: build.query<
      BillingNorwegianQuery,
      BillingNorwegianQueryVariables | void
    >({
      query: (variables) => ({ document: BillingNorwegianDocument, variables }),
    }),
    order: build.query<OrderQuery, OrderQueryVariables | void>({
      query: (variables) => ({ document: OrderDocument, variables }),
    }),
    orders: build.query<OrdersQuery, OrdersQueryVariables | void>({
      query: (variables) => ({ document: OrdersDocument, variables }),
    }),
    minimalOrdersHistory: build.query<
      MinimalOrdersHistoryQuery,
      MinimalOrdersHistoryQueryVariables | void
    >({
      query: (variables) => ({
        document: MinimalOrdersHistoryDocument,
        variables,
      }),
    }),
    availablePaymentPartners: build.query<
      AvailablePaymentPartnersQuery,
      AvailablePaymentPartnersQueryVariables
    >({
      query: (variables) => ({
        document: AvailablePaymentPartnersDocument,
        variables,
      }),
    }),
    preferences: build.query<PreferencesQuery, PreferencesQueryVariables>({
      query: (variables) => ({ document: PreferencesDocument, variables }),
    }),
    preselectedProductsForWeek: build.query<
      PreselectedProductsForWeekQuery,
      PreselectedProductsForWeekQueryVariables
    >({
      query: (variables) => ({
        document: PreselectedProductsForWeekDocument,
        variables,
      }),
    }),
    preselectorPreview: build.query<
      PreselectorPreviewQuery,
      PreselectorPreviewQueryVariables
    >({
      query: (variables) => ({
        document: PreselectorPreviewDocument,
        variables,
      }),
    }),
    productsByIds: build.query<ProductsByIdsQuery, ProductsByIdsQueryVariables>(
      {
        query: (variables) => ({ document: ProductsByIdsDocument, variables }),
      },
    ),
    productsByCategories: build.query<
      ProductsByCategoriesQuery,
      ProductsByCategoriesQueryVariables
    >({
      query: (variables) => ({
        document: ProductsByCategoriesDocument,
        variables,
      }),
    }),
    pickAndMix: build.query<PickAndMixQuery, PickAndMixQueryVariables>({
      query: (variables) => ({ document: PickAndMixDocument, variables }),
    }),
    productCategories: build.query<
      ProductCategoriesQuery,
      ProductCategoriesQueryVariables
    >({
      query: (variables) => ({
        document: ProductCategoriesDocument,
        variables,
      }),
    }),
    recipesByProductIdList: build.query<
      RecipesByProductIdListQuery,
      RecipesByProductIdListQueryVariables
    >({
      query: (variables) => ({
        document: RecipesByProductIdListDocument,
        variables,
      }),
    }),
    recipesByTaxonomyIdList: build.query<
      RecipesByTaxonomyIdListQuery,
      RecipesByTaxonomyIdListQueryVariables
    >({
      query: (variables) => ({
        document: RecipesByTaxonomyIdListDocument,
        variables,
      }),
    }),
    recipeAndSteps: build.query<
      RecipeAndStepsQuery,
      RecipeAndStepsQueryVariables
    >({
      query: (variables) => ({ document: RecipeAndStepsDocument, variables }),
    }),
    recipesAgreementRatings: build.query<
      RecipesAgreementRatingsQuery,
      RecipesAgreementRatingsQueryVariables | void
    >({
      query: (variables) => ({
        document: RecipesAgreementRatingsDocument,
        variables,
      }),
    }),
    recipesAgreementFavorites: build.query<
      RecipesAgreementFavoritesQuery,
      RecipesAgreementFavoritesQueryVariables
    >({
      query: (variables) => ({
        document: RecipesAgreementFavoritesDocument,
        variables,
      }),
    }),
    recipesByIngredientCategories: build.query<
      RecipesByIngredientCategoriesQuery,
      RecipesByIngredientCategoriesQueryVariables
    >({
      query: (variables) => ({
        document: RecipesByIngredientCategoriesDocument,
        variables,
      }),
    }),
    recipesByIds: build.query<RecipesByIdsQuery, RecipesByIdsQueryVariables>({
      query: (variables) => ({ document: RecipesByIdsDocument, variables }),
    }),
    RecruitmentData: build.query<
      RecruitmentDataQuery,
      RecruitmentDataQueryVariables | void
    >({
      query: (variables) => ({ document: RecruitmentDataDocument, variables }),
    }),
    taxonomyRelations: build.query<
      TaxonomyRelationsQuery,
      TaxonomyRelationsQueryVariables
    >({
      query: (variables) => ({
        document: TaxonomyRelationsDocument,
        variables,
      }),
    }),
    timeblocks: build.query<TimeblocksQuery, TimeblocksQueryVariables>({
      query: (variables) => ({ document: TimeblocksDocument, variables }),
    }),
    timeblocksByRange: build.query<
      TimeblocksByRangeQuery,
      TimeblocksByRangeQueryVariables
    >({
      query: (variables) => ({
        document: TimeblocksByRangeDocument,
        variables,
      }),
    }),
    userInfo: build.query<UserInfoQuery, UserInfoQueryVariables | void>({
      query: (variables) => ({ document: UserInfoDocument, variables }),
    }),
    userCredits: build.query<
      UserCreditsQuery,
      UserCreditsQueryVariables | void
    >({
      query: (variables) => ({ document: UserCreditsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useBillingQuery,
  useLazyBillingQuery,
  useCalendarQuery,
  useLazyCalendarQuery,
  useDefaultCouponQuery,
  useLazyDefaultCouponQuery,
  useIngredientCategoriesByCompanyQuery,
  useLazyIngredientCategoriesByCompanyQuery,
  useLoyaltyLevelsByCompanyQuery,
  useLazyLoyaltyLevelsByCompanyQuery,
  useBillingNorwegianQuery,
  useLazyBillingNorwegianQuery,
  useOrderQuery,
  useLazyOrderQuery,
  useOrdersQuery,
  useLazyOrdersQuery,
  useMinimalOrdersHistoryQuery,
  useLazyMinimalOrdersHistoryQuery,
  useAvailablePaymentPartnersQuery,
  useLazyAvailablePaymentPartnersQuery,
  usePreferencesQuery,
  useLazyPreferencesQuery,
  usePreselectedProductsForWeekQuery,
  useLazyPreselectedProductsForWeekQuery,
  usePreselectorPreviewQuery,
  useLazyPreselectorPreviewQuery,
  useProductsByIdsQuery,
  useLazyProductsByIdsQuery,
  useProductsByCategoriesQuery,
  useLazyProductsByCategoriesQuery,
  usePickAndMixQuery,
  useLazyPickAndMixQuery,
  useProductCategoriesQuery,
  useLazyProductCategoriesQuery,
  useRecipesByProductIdListQuery,
  useLazyRecipesByProductIdListQuery,
  useRecipesByTaxonomyIdListQuery,
  useLazyRecipesByTaxonomyIdListQuery,
  useRecipeAndStepsQuery,
  useLazyRecipeAndStepsQuery,
  useRecipesAgreementRatingsQuery,
  useLazyRecipesAgreementRatingsQuery,
  useRecipesAgreementFavoritesQuery,
  useLazyRecipesAgreementFavoritesQuery,
  useRecipesByIngredientCategoriesQuery,
  useLazyRecipesByIngredientCategoriesQuery,
  useRecipesByIdsQuery,
  useLazyRecipesByIdsQuery,
  useRecruitmentDataQuery,
  useLazyRecruitmentDataQuery,
  useTaxonomyRelationsQuery,
  useLazyTaxonomyRelationsQuery,
  useTimeblocksQuery,
  useLazyTimeblocksQuery,
  useTimeblocksByRangeQuery,
  useLazyTimeblocksByRangeQuery,
  useUserInfoQuery,
  useLazyUserInfoQuery,
  useUserCreditsQuery,
  useLazyUserCreditsQuery,
} = injectedRtkApi;
