import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import * as Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

import { config } from "@chef/constants";
import { isNode } from "@chef/utils/runtime";

export const fieldsToPersistInSessionState = [
  "signup", // old redux signup state -- should be removed in the future
  "directOrder", // old redux directOrder state -- should be removed in the future

  // new RTK state
  "basket", // this basket contains both logged in and logged out state
  "signupRtk",
  "directOrderSlice",
  "registration", // new LMK signup
];

export const fieldsToPersistInLocalState = [
  // Tracking
  "tracking",
  "loyalty",
];

const censorAnalyticData = (analytic: any = {}) => ({
  ...analytic,
  eventPayload: {
    ...analytic.eventPayload,
    options: {
      ...analytic.eventPayload.options,
      ip: "0.0.0.0",
    },
  },
});

export const analyticsMiddleware =
  () => (next: Dispatch<AnyAction>) => (action: any) => {
    if (!action?.meta?.analytics) {
      return next(action);
    }

    // censor analytics data
    if (Array.isArray(action.meta.analytics)) {
      action.meta.analytics = action.meta.analytics.map(censorAnalyticData);
    } else {
      action.meta.analytics = censorAnalyticData(action.meta.analytics);
    }

    return next(action);
  };

export const loadState = () => {
  return {
    ...loadLocalState(),
    ...loadSessionState(),
  };
};

export const loadSessionState = () => {
  try {
    const serializedState = sessionStorage.getItem("reduxStore_session_v1");
    if (!serializedState) {
      return;
    }

    const state = JSON.parse(serializedState);

    for (const key of Object.keys(state)) {
      if (!fieldsToPersistInSessionState.includes(key)) {
        delete state[key];
      }
    }

    return state;
  } catch (err) {
    if (
      // we don't want to log if this is on the server
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "development"
    ) {
      console.error(err);
    }
    return;
  }
};

export const loadLocalState = () => {
  try {
    const serializedState = localStorage.getItem("reduxStore");
    if (!serializedState) {
      return;
    }

    const state = JSON.parse(serializedState);

    for (const key of Object.keys(state)) {
      if (!fieldsToPersistInLocalState.includes(key)) {
        delete state[key];
      }
    }

    return state;
  } catch (err) {
    if (
      // we don't want to log if this is on the server
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "development"
    ) {
      console.error(err);
    }
    return;
  }
};

export const saveSessionState = (state: object) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("reduxStore_session_v1", serializedState);
  } catch (err) {
    if (
      // we don't want to log if this is on the server
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "development"
    ) {
      console.error(err);
    }
  }
};

export const saveLocalState = (state: object) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxStore", serializedState);
  } catch (err) {
    if (
      // we don't want to log if this is on the server
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "development"
    ) {
      console.error(err);
    }
  }
};

export const getLoginCookieToken = () => {
  const token = Cookies.get(config.auth.cookieName);
  if (!token) {
    return null;
  }
  return token;
};

export const getLoginCookieData = () => {
  const token = Cookies.get(config.auth.cookieName);
  if (!token) {
    return null;
  }
  try {
    const decoded = jwtDecode(token) as {
      TimeStamp: string;
      UserId: string;
      AgreementId: number;
      CustomerId: string;
      CompanyId: string;
    };

    return {
      token,
      timestamp: decoded.TimeStamp,
      userId: decoded.UserId,
      agreementId: decoded.AgreementId,
      customerId: decoded.CustomerId,
      companyId: decoded.CompanyId,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Todo remove after AMK and GL RTK migration
export const getLoginLocalStorageTokenData = () => {
  const token = getLoginLocalStorageToken();
  if (!token) {
    return null;
  }

  try {
    const decoded = jwtDecode(token) as {
      TimeStamp: string;
      UserId: string;
      AgreementId: number;
      CustomerId: string;
      CompanyId: string;
    };

    return {
      token,
      timestamp: decoded.TimeStamp,
      userId: decoded.UserId,
      agreementId: decoded.AgreementId,
      customerId: decoded.CustomerId,
      companyId: decoded.CompanyId,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getLoginLocalStorageToken = () => {
  if (isNode()) {
    return null;
  }
  return localStorage.getItem("token") || null;
};
