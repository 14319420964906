import * as React from "react";
import { SVGProps } from "react";
const Edit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 3H2.55556C2.143 3 1.74733 3.16389 1.45561 3.45561C1.16389 3.74733 1 4.143 1 4.55556V15.4444C1 15.857 1.16389 16.2527 1.45561 16.5444C1.74733 16.8361 2.143 17 2.55556 17H13.4444C13.857 17 14.2527 16.8361 14.5444 16.5444C14.8361 16.2527 15 15.857 15 15.4444V10"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1791 1.48399C14.489 1.1741 14.9093 1 15.3476 1C15.7858 1 16.2061 1.1741 16.516 1.48399C16.8259 1.79388 17 2.21418 17 2.65243C17 3.09068 16.8259 3.51099 16.516 3.82088L9.11586 11.221L6 12L6.77896 8.88414L14.1791 1.48399Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Edit;
