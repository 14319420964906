import { ACCOUNT_STATUSES } from "@chef/constants";
import { isOneSubProduct } from "./product";

type BillingLike = {
  baskets: {
    default: boolean;
    basketProducts: {
      variation: {
        product: {
          productTypeId: string;
          productId: string;
        };
      };
    }[];
  }[];
};

const isAccountActive = (status: number) => status === ACCOUNT_STATUSES.ACTIVE;

const isAccountPaused = (status: number) => status === ACCOUNT_STATUSES.PAUSED;

const isAccountPending = (status: number) =>
  status === ACCOUNT_STATUSES.PENDING;

const isAccountBadPayer = (status: number) =>
  status === ACCOUNT_STATUSES.BAD_PAYER;

const isAccountPostalAreaClosed = (status: number) =>
  status === ACCOUNT_STATUSES.POSTAL_AREA_CLOSED;

const isAccountLocked = (status: number) => status === ACCOUNT_STATUSES.LOCKED;

const isAccountMissingKey = (status: number) =>
  status === ACCOUNT_STATUSES.MISSING_KEY;

const isAccountDeleted = (status: number) =>
  status === ACCOUNT_STATUSES.DELETED;

// TODO: Remove after onesub full release
const isOneSubAccount = (billing?: BillingLike) => {
  return billing?.baskets
    .find((b) => b.default)
    ?.basketProducts.some((p) => isOneSubProduct(p.variation.product));
};

export {
  isAccountActive,
  isAccountPaused,
  isAccountPending,
  isAccountBadPayer,
  isAccountPostalAreaClosed,
  isAccountLocked,
  isAccountMissingKey,
  isAccountDeleted,
  isOneSubAccount,
};
