import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { createAndSendEvent } from "@chef/events";
import { invariant } from "@chef/utils/invariant";

import { updateAddonToDeviation } from "../features/basket";
import { api } from "../graphql/api";
import {
  deriveProductPropertiesFromState,
  deriveVariationPropertyFromState,
} from "./util";
import type { RootState } from "..";
import { getStandaloneProductCategoryIdsFromProductCategories } from "../helpers";

const listener = (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: updateAddonToDeviation,
    effect: async (action, listenerApi) => {
      const previousState = listenerApi.getOriginalState() as RootState;

      const previousDeviation = previousState.basket.deviations.find(
        (d) => d.week === action.payload.week && d.year === action.payload.year,
      );

      invariant(previousDeviation, "Could not find deviation");

      const previousProduct = previousDeviation.products.find(
        (p) => p.productId === action.payload.product.productId,
      );

      let addedOrRemoved;

      if (action.payload.product.quantity === 0) {
        addedOrRemoved = "removed";
      } else if (previousProduct) {
        addedOrRemoved =
          previousProduct.quantity < action.payload.product.quantity
            ? "added"
            : "removed";
      } else {
        addedOrRemoved = "added";
      }

      const categoriesData = await listenerApi
        .dispatch(api.endpoints.productCategories.initiate())
        .unwrap();

      const standaloneProductsData = await listenerApi
        .dispatch(
          api.endpoints.productsByCategories.initiate({
            categoryIds: getStandaloneProductCategoryIdsFromProductCategories(
              categoriesData.productCategories,
            ),
            week: action.payload.week,
            year: action.payload.year,
          }),
        )
        .unwrap();

      const [category, name, productTypeId] = deriveProductPropertiesFromState(
        ["productTypeName", "name", "productTypeId"],
        action.payload.product.productId,
        standaloneProductsData,
      );

      const variant = deriveVariationPropertyFromState(
        "name",
        action.payload.product.productId,
        action.payload.product.variationId,
        standaloneProductsData,
      );

      const eventPayload = {
        affiliation: "Frontend process",
        category,
        name,
        category_id: productTypeId,
        product_id: action.payload.product.productId,
        quantity: action.payload.product.quantity,
        variant,
        variant_id: action.payload.product.variationId,
        price: action.payload.product.price,
      } as const;

      if (addedOrRemoved === "removed") {
        createAndSendEvent("Product Removed", eventPayload);
      } else {
        createAndSendEvent("Product Added", eventPayload);
      }
    },
  });

export default listener;
