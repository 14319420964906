import * as React from "react";
import { SVGProps } from "react";
const Time = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={9} cy={9} r={8.25} stroke="currentColor" strokeWidth={1.5} />
    <path
      d="M9 8.99981H8.25V9.31046L8.46965 9.53012L9 8.99981ZM12.5303 11.4697L9.53035 8.4695L8.46965 9.53012L11.4697 12.5303L12.5303 11.4697ZM9.75 8.99981V4H8.25V8.99981H9.75Z"
      fill="currentColor"
    />
  </svg>
);
export default Time;
