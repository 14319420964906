import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      LIST: "Liste",
      PICTURES: "Bilder",
    },
    se: {
      LIST: "Lista",
      PICTURES: "Bilder",
    },
    dk: {
      LIST: "Liste",
      PICTURES: "Billeder",
    },
  } as const
)[language];
