import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMenuDrawerIsOpen,
  setMenuDrawerOpen,
  toggleMenuDrawer,
} from "../features";

export const useDrawer = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectMenuDrawerIsOpen);

  const toggleDrawer = useCallback(() => {
    dispatch(toggleMenuDrawer());
  }, [dispatch]);

  const showDrawer = useCallback(() => {
    dispatch(setMenuDrawerOpen(true));
  }, [dispatch]);

  const hideDrawer = useCallback(() => {
    dispatch(setMenuDrawerOpen(false));
  }, [dispatch]);

  return { isOpen, toggleDrawer, showDrawer, hideDrawer };
};
