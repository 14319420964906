import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      ADDRESS_LABEL: "Adresse",
      WRITE_STREET_NAME: "Skriv gatenavn",
      AND: "og",
      CITY: "poststed",
      TIP: "Tips: ",
    },

    se: {
      ADDRESS_LABEL: "Adress",
      WRITE_STREET_NAME: "Skriv gatunamn",
      AND: "och",
      CITY: "ort",
      TIP: "Tips: ",
    },

    dk: {
      ADDRESS_LABEL: "Adresse",
      WRITE_STREET_NAME: "Skriv vejnavn",
      AND: "og",
      CITY: "by",
      TIP: "Tip: ",
    },
  } as const
)[language];
