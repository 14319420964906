import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CUSTOMIZE_DELIVERY_SCHEDULE: "Tilpass leveringsdetaljer for uke",
      DEADLINE_FOR_DELIVERY_CHANGE: "Frist for å endre denne leveransen:",
      BOOK_DELIVERY_FOR_WEEK: "Velg levering for uke",
      CONFIRM_DELIVERY_DETAILS: "Bekreft leveringsdetaljer",
      DELIVERY_ADDRESS: "Leveringsadresse",
      DELIVERY_ADDRESS_PLACEHOLDER: "Laster inn...",
      ADD_NEW_ADDRESS: "Legg til ny adresse",
      DELIVERYDATE_AND_TIME: "Leveringsdag og tidspunkt",
      SAVE_CHANGES: "Lagre endring",
      CANCEL: "Avbryt",
      NO_DELIVERY_ON_SELECTED_ADDRESS_FOR_THIS_WEEK:
        "Dessverre er det ingen leveringstider til denne adressen i den valgte uken.",
      TIME_MARKER: "kl",

      MANAGE_DELIVERY_WEEK: "Endre levering for uke",
      SAVE_AND_CLOSE: "Lagre og lukk",
      ORDER_DELIVERY: "Bestill levering",
      DO_YOU_WANT_TO_CANCEL_THE_DELIVERY_FOR_WEEK:
        "Vil du heller avbestille din levering for uke",
      CANCEL_DELIVERY: "Avbestill leveringen",

      DATE_FORMAT: "d. MMM",
      TO_BOOK_ON_WEEK: "For å bestille middager til leveringsuke",
      CUSTOMER_SERVICE_WILL_HELP:
        "vil kundeservice hjelpe deg. En chat åpnes nå :)",
      PREPOPULATED_MESSAGE:
        "Hei! Jeg vil gjerne endre startdato og flytte frem min første levering",
      SUBJECT_OF_INTERCOME_MESSAGE: "Endre startdato for levering",
    },
    se: {
      CUSTOMIZE_DELIVERY_SCHEDULE: "Ändra leveransdetaljer för vecka",
      DEADLINE_FOR_DELIVERY_CHANGE:
        "Sista datum för ändring av denna leverans:",
      BOOK_DELIVERY_FOR_WEEK: "Boka leverans vecka",
      CONFIRM_DELIVERY_DETAILS: "Bekräfta leveransdetaljer",
      DELIVERY_ADDRESS: "Leveransadress",
      DELIVERY_ADDRESS_PLACEHOLDER: "Läser in...",
      ADD_NEW_ADDRESS: "Lägg till ny adress",
      DELIVERYDATE_AND_TIME: "Leveransdag och tid",
      SAVE_CHANGES: "Spara ändring",
      CANCEL: "Avbryt",
      NO_DELIVERY_ON_SELECTED_ADDRESS_FOR_THIS_WEEK:
        "Tyvärr finns inga leveranstider till ditt område under vald vecka.",
      TIME_MARKER: "kl",

      MANAGE_DELIVERY_WEEK: "Hantera leverans vecka",
      SAVE_AND_CLOSE: "Spara och stäng",
      ORDER_DELIVERY: "Beställ leverans",
      DO_YOU_WANT_TO_CANCEL_THE_DELIVERY_FOR_WEEK:
        "Vill du hellre avboka din leverans för vecka",
      CANCEL_DELIVERY: "Avboka leveransen",

      DATE_FORMAT: "d MMM",
      TO_BOOK_ON_WEEK: "För att boka på leverans v.",
      CUSTOMER_SERVICE_WILL_HELP:
        "hjälper kundtjänst dig, en chattruta öppnas nu :)",
      PREPOPULATED_MESSAGE:
        "Hej, jag skulle vilja ändra mitt startdatum och flytta fram min första leverans.",
      SUBJECT_OF_INTERCOME_MESSAGE: "Ändra startdatum for leverans",
    },
    dk: {
      CUSTOMIZE_DELIVERY_SCHEDULE: "Tilpas leveringsdetaljer for uge",
      BOOK_DELIVERY_FOR_WEEK: "Vælg levering til uge",
      CONFIRM_DELIVERY_DETAILS: "Bekræft leveringsdetaljer",
      DEADLINE_FOR_DELIVERY_CHANGE: "Fristen for ændring af denne levering:",
      DELIVERY_ADDRESS: "Leveringsadresse",
      DELIVERY_ADDRESS_PLACEHOLDER: "Indlæser...",
      ADD_NEW_ADDRESS: "Tilføj ny adresse",
      DELIVERYDATE_AND_TIME: "Leveringsdag og tidspunkt",

      SAVE_CHANGES: "Gem ændring",
      CANCEL: "Annuller",
      NO_DELIVERY_ON_SELECTED_ADDRESS_FOR_THIS_WEEK:
        "Desværre er der ingen leveringstider til denne adresse i den valgte uge.",
      TIME_MARKER: "kl.",

      MANAGE_DELIVERY_WEEK: "Tilpas levering til uge",
      SAVE_AND_CLOSE: "Gem og luk",
      ORDER_DELIVERY: "Bestil levering",
      DO_YOU_WANT_TO_CANCEL_THE_DELIVERY_FOR_WEEK:
        "Vil du hellere annullere din levering til uge",
      CANCEL_DELIVERY: "Annuller levering",

      DATE_FORMAT: "d. MMM",
      TO_BOOK_ON_WEEK: "For at bestille en måltidskasse til leveringsuge",
      CUSTOMER_SERVICE_WILL_HELP:
        "vil kundeservice hjælpe dig. En chat åbnes nu :)",
      PREPOPULATED_MESSAGE:
        "Hej. Jeg vil gerne ændre min startdato og flytte min første levering frem.",
      SUBJECT_OF_INTERCOME_MESSAGE: "Ændr startdato for levering",
    },
  } as const
)[language];
