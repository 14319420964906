import { useLoyalty } from "@chef/feature-flags";
import { IDeviationBasket, useCalendarQuery } from "@chef/state-management";
import {
  getWeeklyTimeblockDataFromCalendar,
  getBasketFinances,
} from "@chef/state-management/helpers";

export const useFinancesForDeviation = ({
  deviation,
}: {
  deviation: IDeviationBasket;
}) => {
  const { week, year, products } = deviation;

  const { data: calendarData } = useCalendarQuery({ week, year, range: 1 });
  const timeblock =
    calendarData &&
    getWeeklyTimeblockDataFromCalendar({
      calendar: calendarData.calendar,
      week,
      year,
    });

  const { data: loyaltyData } = useLoyalty();

  const finances = getBasketFinances({
    basketProducts: products,
    customerFee: timeblock?.customerFee,
    loyaltyLevel: loyaltyData?.currentLevel,
  });

  return {
    finances,
  };
};
