import React, { Key } from "react";
import clsx from "clsx";

import { User } from "@chef/icons/large";
import { RadioButtonBar, RadioButtonOption } from "../components";
import { Language } from "../../types";

const intl = {
  no: {
    PEOPLE: "Personer",
    AMOUNT: "st",
  },
  se: {
    PEOPLE: "Personer",
    AMOUNT: "st",
  },
  dk: {
    PEOPLE: "Personer",
    AMOUNT: "pers",
  },
};

interface PortionSizeSelectorProps<T> {
  name: string;
  options: RadioButtonOption<T>[];
  onChange: (value: T) => void;
  value: T;
  language: Language;
  className?: string;
}

const PortionSizeSelector = <T extends Key | null | undefined>({
  name,
  options,
  onChange,
  value,
  language = "no",
  className,
}: PortionSizeSelectorProps<T>) => {
  const { PEOPLE, AMOUNT } = intl[language];

  const selected = options.find((o) => o.value === value);

  return (
    <div
      className={clsx("flex gap-2 justify-between md:justify-start", className)}
    >
      <div className="flex items-center">
        <div className="mr-3">
          <User className="w-8 h-8" />
        </div>

        <div className="md:mr-4">
          <div>{PEOPLE}</div>
          <div>{selected && `${selected.name} ${AMOUNT}`}</div>
        </div>
      </div>

      <div>
        <RadioButtonBar
          name={`${name}-portions-size-selector`}
          options={options}
          onChange={onChange}
          value={value}
          small
          hasCheckmark
        />
      </div>
    </div>
  );
};

export default PortionSizeSelector;
