import * as React from "react";
import { SVGProps } from "react";
const Messenger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3632_489)">
      <path
        d="M10 0C4.3675 0 0 4.1275 0 9.7C0 12.615 1.195 15.135 3.14 16.875C3.3025 17.02 3.4025 17.225 3.4075 17.445L3.4625 19.225C3.48 19.7925 4.065 20.1625 4.585 19.9325L6.57 19.0575C6.7375 18.9825 6.9275 18.97 7.105 19.0175C8.0175 19.2675 8.9875 19.4025 10 19.4025C15.6325 19.4025 20 15.275 20 9.7025C20 4.13 15.6325 0 10 0ZM16.005 7.4625L13.0675 12.1225C12.6 12.865 11.6 13.0475 10.8975 12.5225L8.56 10.77C8.345 10.61 8.05 10.61 7.8375 10.7725L4.6825 13.1675C4.2625 13.4875 3.7125 12.9825 3.9925 12.535L6.93 7.875C7.3975 7.1325 8.3975 6.95 9.1 7.475L11.4375 9.2275C11.6525 9.3875 11.9475 9.3875 12.16 9.225L15.315 6.83C15.7375 6.5125 16.2875 7.015 16.005 7.4625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3632_489">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Messenger;
