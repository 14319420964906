import { isAnyOf, ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { language } from "@chef/constants";
import { billingApi, showNotification } from "../features";

const intl = {
  se: {
    DISCOUNT_ACTIVATED: `Rabatten är aktiverad`,
    ERROR_MESSAGE: "En fel har uppstått. Kontakta kundtjänst.",
    DISCOUNT_CAN_NOT_BE_APPLIED_MORE_THAN_ONCE:
      "Denna rabatt kan endast användas en gång",
    DISCOUNT_ONLY_FOR_NEW_CUSTOMERS:
      "Denna rabatt gäller tyvärr bara för nya kunder",
  },
  no: {
    DISCOUNT_ACTIVATED: `Rabatten er aktivert`,
    ERROR_MESSAGE: "En feil oppstod. Prøv igjen eller kontakt kundeservice.",
    DISCOUNT_CAN_NOT_BE_APPLIED_MORE_THAN_ONCE:
      "Denne rabatten kan kun benyttes en gang",
    DISCOUNT_ONLY_FOR_NEW_CUSTOMERS:
      "Denne rabatten gjelder dessverre bare for nye kunder",
  },
  dk: {
    DISCOUNT_ACTIVATED: `Rabat tilføjet`,
    ERROR_MESSAGE: "Kunne ikke tilføje rabat",
    DISCOUNT_CAN_NOT_BE_APPLIED_MORE_THAN_ONCE:
      "Denne rabat kan kun bruges én gang",
    DISCOUNT_ONLY_FOR_NEW_CUSTOMERS: "Rabatten gælder desværre kun nye kunder",
  },
}[language];

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    matcher: isAnyOf(
      billingApi.endpoints.submitCoupon.matchFulfilled,
      billingApi.endpoints.submitCoupon.matchRejected,
    ),
    effect: async (action) => {
      if (action["meta"].requestStatus === "rejected") {
        const errorCode = action["payload"].data.ErrorCode;

        let message = intl.ERROR_MESSAGE;

        switch (errorCode) {
          case 360:
            message = intl.DISCOUNT_CAN_NOT_BE_APPLIED_MORE_THAN_ONCE;
            break;
          case 316:
            message = intl.DISCOUNT_ONLY_FOR_NEW_CUSTOMERS;
            break;
        }

        showNotification({
          type: "error",
          message,
          autoClose: false,
        });
      }

      if (action["meta"].requestStatus === "fulfilled") {
        showNotification({
          type: "success",
          message: intl.DISCOUNT_ACTIVATED,
          autoClose: false,
        });
      }
    },
  });
